import { Box, Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PATH_DASHBOARD } from 'routes/paths';
import AddTimeClockDrawer from './AddTimeClock/AddTimeClockDrawer';

TimeClockHeaders.propTypes = {
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
};

function TimeClockHeaders({ setStartDate, setEndDate, startDate, endDate }) {
  const [openAddModal, setOpenAddModal] = useState(false);

  const OpenAddTimeModalHandler = () => {
    setOpenAddModal(true);
  };

  const handleClose = () => {
    setOpenAddModal(false);
  };

  return (
    <>
      <HeaderBreadcrumbs
        heading="Time Clock"
        links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Time Clock' }]}
        action={
          <Box className="invoiceListHeadingButtons">
            <Button
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} />}
              onClick={OpenAddTimeModalHandler}
            >
              Add Approved
            </Button>
          </Box>
        }
      />
      {openAddModal && (
        <AddTimeClockDrawer
          open={openAddModal}
          handleClose={handleClose}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </>
  );
}

export default TimeClockHeaders;
