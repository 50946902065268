import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createFoodCategories } from 'redux/slices/FoodCategoriesAndSizeSlice';
import FoodCategoriesForm from 'sections/@dashboard/foodCategory/foodCategories/FoodCategoriesForm';

function FoodCategoriesCreate({ open, handleClose }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const FoodCategoriesCreateHandler = (data) => {
    const finalData = {
      departmentId,
      ...data,
      addedSizes: data?.addedSizes.map((obj) => obj.id),
    };
    dispatch(createFoodCategories({ finalData, handleClose }));
  };
  return (
    <CustomOutletModal open={open} zIndex="1600" title="Create Food Categories">
      <FoodCategoriesForm handleClose={handleClose} onSubmit={FoodCategoriesCreateHandler} />
    </CustomOutletModal>
  );
}

export default FoodCategoriesCreate;
