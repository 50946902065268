import PropTypes from 'prop-types';
import { Stack, InputAdornment, TextField, MenuItem, Button, Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
// components
import { RHFTextField } from 'components/hook-form';
import { Controller, useFormContext } from 'react-hook-form';

import { getInvoiceFilter } from 'redux/slices/InvoiceSlice';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { useDispatch } from 'react-redux';
import Iconify from '../../../../components/Iconify';
// ----------------------------------------------------------------------

const INPUT_WIDTH = 160;

InvoiceTableToolbar.propTypes = {
  filterName: PropTypes.string,
  filterService: PropTypes.string,
  filterEndDate: PropTypes.instanceOf(Date),
  filterStartDate: PropTypes.instanceOf(Date),
  onFilterName: PropTypes.func,
  onFilterEndDate: PropTypes.func,
  onFilterService: PropTypes.func,
  onFilterStartDate: PropTypes.func,
  optionsService: PropTypes.arrayOf(PropTypes.string),
};

export default function InvoiceTableToolbar() {
  const { reset, handleSubmit, watch } = useFormContext();
  const departmentId = useGetDepartmentId();

  const watchStartDate = watch('fromDate');

  const dispatch = useDispatch();
  const InvoiceFilterHandler = (data) => {
    dispatch(getInvoiceFilter({ data: { departmentId, statusList: data?.statusList }, pages: 1 }));
    reset({
      departmentId,
      statusList: data?.statusList,
      searchTxt: '',
    });
  };
  return (
    <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} sx={{ py: 2.5, px: 3 }}>
      <Controller
        name="fromDate"
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            label="Start Date"
            disableFuture
            {...field}
            renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} />}
            sx={{
              maxWidth: { md: INPUT_WIDTH },
            }}
          />
        )}
      />

      <Controller
        name="toDate"
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            label="End Date"
            minDate={watchStartDate}
            disableFuture
            {...field}
            renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} />}
            sx={{
              maxWidth: { md: INPUT_WIDTH },
            }}
          />
        )}
      />

      <RHFTextField
        name={`searchTxt`}
        label="Search Invoice"
        InputLabelProps={{ shrink: true }}
        placeholder="Search Suppliers, Invoice No"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          ),
        }}
      />

      <Box sx={{ marginLeft: 'auto', display: 'flex', gap: '1rem', paddingLeft: '2rem' }}>
        <Button variant="contained" size="large" color="secondary" type="submit">
          Filter
        </Button>
        <Button color="error" onClick={handleSubmit(InvoiceFilterHandler)}>
          Clear
        </Button>
      </Box>
    </Stack>
  );
}
