import PropTypes from 'prop-types';
import { Box, Button, MenuItem, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import { RHFSelect, RHFTextField } from 'components/hook-form';
import Iconify from 'components/Iconify';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { fCurrency } from 'utils/formatNumber';
import { useSelector } from 'react-redux';
import { findFloatAmount } from 'utils/salesUtils';
import { sum } from 'lodash';

SalesCashDrawersForm.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  totalOnRow: PropTypes.array,
  mainIndex: PropTypes.number,
  // cashDrawersList: PropTypes.array,
  // storeCashDrawerLoading: PropTypes.bool,
  // handleRemoveItem: PropTypes.func,
};
export default function SalesCashDrawersForm({ item, index, mainIndex, totalOnRow }) {
  const { storeCashDrawer } = useSelector((state) => state.store);
  const { setValue, watch } = useFormContext();
  useEffect(() => {
    // if (!totalOnRow[mainIndex]) return;
    setValue(`cashDrawers.${mainIndex}.tillAmount`, totalOnRow[mainIndex] / 100);
  }, [setValue, totalOnRow[mainIndex]]);
  const values = watch();

  const selectedDrawer = watch(`cashDrawers[${mainIndex}].storeCashDrawerId`);
  const floatAmt = storeCashDrawer?.storeCashDrawers?.find((item) => item?.id === selectedDrawer)?.floatAmt;

  return (
    <>
      <TableBody>
        <TableRow>
          <TableCell style={{ marginLeft: 3, padding: 5 }}>
            <span style={{ marginLeft: '20px' }}> 5 c.</span>
          </TableCell>
          <TableCell style={{ padding: 2 }}>
            <RHFTextField size="small" name={`cashDrawers.${mainIndex}.fiveCents`} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ marginLeft: 3, padding: 5 }}>
            <span style={{ marginLeft: '20px' }}> 10 c.</span>
          </TableCell>
          <TableCell style={{ padding: 2 }}>
            <RHFTextField size="small" name={`cashDrawers.${mainIndex}.tenCents`} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ marginLeft: 3, padding: 5 }}>
            <span style={{ marginLeft: '20px' }}> 20 c.</span>
          </TableCell>
          <TableCell style={{ padding: 2 }}>
            <RHFTextField size="small" name={`cashDrawers.${mainIndex}.twentyCents`} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ marginLeft: 3, padding: 5 }}>
            <span style={{ marginLeft: '20px' }}> 50 c.</span>
          </TableCell>
          <TableCell style={{ padding: 2 }}>
            <RHFTextField size="small" name={`cashDrawers.${mainIndex}.fiftyCents`} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ marginLeft: 3, padding: 5 }}>
            <span style={{ marginLeft: '20px' }}> $1 </span>
          </TableCell>
          <TableCell style={{ padding: 2 }}>
            <RHFTextField size="small" name={`cashDrawers.${mainIndex}.oneDollors`} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ marginLeft: 3, padding: 5 }}>
            <span style={{ marginLeft: '20px' }}> $2 </span>
          </TableCell>
          <TableCell style={{ padding: 2 }}>
            <RHFTextField size="small" name={`cashDrawers.${mainIndex}.twoDollors`} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ marginLeft: 3, padding: 5 }}>
            <span style={{ marginLeft: '20px' }}> $5 </span>
          </TableCell>
          <TableCell style={{ padding: 2 }}>
            <RHFTextField size="small" name={`cashDrawers.${mainIndex}.fiveDollors`} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ marginLeft: 3, padding: 5 }}>
            <span style={{ marginLeft: '20px' }}> $10 </span>
          </TableCell>
          <TableCell style={{ padding: 2 }}>
            <RHFTextField size="small" name={`cashDrawers.${mainIndex}.tenDollors`} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ marginLeft: 3, padding: 5 }}>
            <span style={{ marginLeft: '20px' }}> $20 </span>
          </TableCell>
          <TableCell style={{ padding: 2 }}>
            <RHFTextField size="small" name={`cashDrawers.${mainIndex}.twentyDollors`} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ marginLeft: 3, padding: 5 }}>
            <span style={{ marginLeft: '20px' }}> $50 </span>
          </TableCell>
          <TableCell style={{ padding: 2 }}>
            <RHFTextField type="number" size="small" name={`cashDrawers.${mainIndex}.fiftyDollors`} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ marginLeft: 3, padding: 5 }}>
            <span style={{ marginLeft: '20px' }}> $100 </span>
          </TableCell>
          <TableCell style={{ padding: 2 }}>
            <RHFTextField size="small" name={`cashDrawers.${mainIndex}.hundredDollors`} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ marginLeft: 3, padding: 5 }}>
            <span style={{ marginLeft: '20px' }}> Float Amt:</span>
          </TableCell>
          <TableCell style={{ padding: 2 }}>{fCurrency(floatAmt)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ marginLeft: 3, padding: 5 }}>
            <span style={{ marginLeft: '20px' }}> Till Amt:</span>
          </TableCell>
          <TableCell style={{ padding: 2 }}>{values?.cashDrawers?.[mainIndex]?.tillAmount}</TableCell>
        </TableRow>
      </TableBody>
    </>
  );
}
