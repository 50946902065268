import { Button, Popover, Stack, Tab, Tabs, Typography } from '@mui/material';
import CustomDatePicker from 'components/CustomComponents/CustomDatePicker';
import { FormProvider } from 'components/hook-form';
import { errorToastTime } from 'config';
import PropTypes from 'prop-types';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { changeTimeClockStatus, getAllUserClockEvents } from 'redux/slices/timeSlice';
import { convertDatetoString } from 'utils/customDateAndTimeFormat';

TimeClockFilter.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  setEndDate: PropTypes.func,
  setStartDate: PropTypes.func,
};

function TimeClockFilter({ startDate, endDate, setStartDate, setEndDate }) {
  const { timeClockStatus, internalLoading } = useSelector((state) => state.time);
  const [openImportDatePop, setOpenImportDatePop] = useState(null);

  const open = Boolean(openImportDatePop);
  const ids = open ? 'simple-popover' : undefined;

  const defaultValue = useMemo(
    () => ({
      startDate: new Date(),
      endDate: new Date(),
    }),
    []
  );

  const methods = useForm({ defaultValues: defaultValue });
  const { handleSubmit, watch } = methods;
  const watchStartDate = watch('startDate');
  const dispatch = useDispatch();
  const [choosenDate, setChoosenDate] = useState('DAYS');

  const departmentId = useGetDepartmentId();

  const userReportFilter = (status) => {
    let startDate;
    let endDate;
    setChoosenDate(status);
    switch (status) {
      case 'DAYS':
        startDate = moment().toDate();
        endDate = startDate;
        break;
      // case 'YESTERDAY':
      //   startDate = moment().subtract(1, 'days').toDate();
      //   endDate = startDate;
      //   break;
      case 'WEEK':
        startDate = moment().startOf('isoWeek').toDate();
        endDate = new Date();
        break;
      case 'LAST_WEEK':
        startDate = moment().subtract(1, 'weeks').startOf('isoWeek').toDate();
        endDate = moment().subtract(1, 'weeks').endOf('isoWeek').toDate();
        break;
      case 'FORTNIGHT':
        startDate = moment().subtract(14, 'days').startOf('isoWeek').toDate();
        endDate = moment(startDate).add(10, 'days').endOf('isoWeek').toDate();
        break;
      case 'MONTH':
        startDate = moment(new Date()).subtract(30, 'days').toDate();
        endDate = new Date();
        break;
      case 'LAST_MONTH':
        startDate = moment().subtract(1, 'months').startOf('month').toDate();
        endDate = moment().subtract(1, 'months').endOf('month').toDate();
        break;
      default:
        break;
    }
    setStartDate(convertDatetoString(startDate));
    setEndDate(convertDatetoString(endDate));
  };

  const ReportFilterHandler = (data) => {
    setChoosenDate('CUSTOM');
    setStartDate(convertDatetoString(data?.startDate));
    setEndDate(convertDatetoString(data?.endDate));
    handleCloseImportPopOver();
  };

  const handleOpenImportPopOver = (event) => {
    setOpenImportDatePop(event.currentTarget);
  };

  const handleCloseImportPopOver = () => {
    setOpenImportDatePop(null);
  };

  // const TimeDurationFilterHandle = handleSubmit((data) => {
  //   handleCloseImportPopOver();
  //   const finalData = {
  //     ...data,
  //     approvalStatusList: [filterStatus],
  //     startDate: convertDatetoString(data?.startDate),
  //     endDate: convertDatetoString(data?.endDate),
  //   };
  //   dispatch(getAllUserClockEvents({ departmentId, data: finalData }));
  // });

  // useEffect(() => {
  //   if (!departmentId || !timeDT) return;
  //   dispatch(
  //     getAllUserClockEvents({
  //       departmentId,
  //       data: {
  //         startDate: convertDatetoString(new Date()),
  //         endDate: convertDatetoString(new Date()),
  //         approvalStatusList: [filterStatus],
  //       },
  //     })
  //   );
  // }, [departmentId, dispatch, filterStatus, timeDT]);

  // useEffect(() => {
  //   if (!timeClockStatus) return;
  //   onFilterStatus(null, timeClockStatus);
  // }, [methods, onFilterStatus, timeClockStatus]);

  useEffect(() => {
    if (!startDate || !endDate || !departmentId) return;
    dispatch(
      getAllUserClockEvents({
        departmentId,
        data: {
          startDate,
          endDate,
          approvalStatusList: [timeClockStatus],
        },
      })
    );
  }, [startDate, endDate, timeClockStatus, dispatch, departmentId]);

  useEffect(() => {
    if (!internalLoading) return;
    setChoosenDate('CUSTOM');
  }, [internalLoading]);

  const prevDayHandler = () => {
    setChoosenDate('PREV');
    const begin = moment(startDate).subtract(1, 'days').toDate();
    const end = begin;
    setStartDate(convertDatetoString(begin));
    setEndDate(convertDatetoString(end));
  };
  // eslint-disable-next-line consistent-return
  const nextDayHandler = () => {
    setChoosenDate('NEXT');
    if (moment(startDate).isSame(new Date(), 'day'))
      return toast.error('You can not go to future date', { autoClose: errorToastTime });
    const begin = moment(startDate).add(1, 'days').toDate();
    const end = begin;
    setStartDate(convertDatetoString(begin));
    setEndDate(convertDatetoString(end));
  };
  return (
    <>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={timeClockStatus}
        onChange={(e, value) => {
          dispatch(changeTimeClockStatus(value));
          // setTabValue(value);
        }}
        sx={{ px: 2, bgcolor: 'background.neutral' }}
      >
        <Tab label="Pending" value="PENDING" />
        <Tab label="Approved" value="APPROVED" />
      </Tabs>
      <Stack m={'1rem'}>
        <FormProvider methods={methods}>
          <Stack
            alignItems={'center'}
            justifyContent={'center'}
            gap="1rem"
            display={'flex'}
            flexDirection={'row'}
            flexWrap={'wrap'}
          >
            <Button
              variant={choosenDate === 'PREV' ? 'contained' : 'outlined'}
              // onClick={() => userReportFilter('PREV')}
              onClick={prevDayHandler}
            >
              Prev. Day
            </Button>
            <Button
              variant={choosenDate === 'DAYS' ? 'contained' : 'outlined'}
              onClick={() => userReportFilter('DAYS')}
            >
              Today
            </Button>
            <Button
              variant={choosenDate === 'NEXT' ? 'contained' : 'outlined'}
              // onClick={() => userReportFilter('NEXT')}
              onClick={nextDayHandler}
            >
              Next Day
            </Button>
            {/* <Button
              variant={choosenDate === 'YESTERDAY' ? 'contained' : 'outlined'}
              onClick={() => userReportFilter('YESTERDAY')}
            >
              Yesterday
            </Button> */}
            <Button
              variant={choosenDate === 'WEEK' ? 'contained' : 'outlined'}
              onClick={() => userReportFilter('WEEK')}
            >
              This Week
            </Button>
            <Button
              variant={choosenDate === 'LAST_WEEK' ? 'contained' : 'outlined'}
              onClick={() => userReportFilter('LAST_WEEK')}
            >
              Last Week
            </Button>
            <Button
              variant={choosenDate === 'FORTNIGHT' ? 'contained' : 'outlined'}
              onClick={() => userReportFilter('FORTNIGHT')}
            >
              Fortnight
            </Button>
            <Button
              variant={choosenDate === 'MONTH' ? 'contained' : 'outlined'}
              onClick={() => userReportFilter('MONTH')}
            >
              30 Days
            </Button>
            <Button
              variant={choosenDate === 'LAST_MONTH' ? 'contained' : 'outlined'}
              onClick={() => userReportFilter('LAST_MONTH')}
            >
              Prev Month
            </Button>
            <Button
              onClick={(event) => {
                handleOpenImportPopOver(event);
              }}
            >
              Custom
            </Button>
          </Stack>
          <Popover
            id={ids}
            open={open}
            anchorEl={openImportDatePop}
            onClose={handleCloseImportPopOver}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Stack alignItems={'center'} direction="column" gap="1rem" m="1rem">
              <CustomDatePicker
                name="startDate"
                fieldWidth={'max-content'}
                inputFormat="dd/MMM/yyyy"
                label="Start Date"
                disableFuture
              />
              <CustomDatePicker
                name="endDate"
                fullWidth={false}
                fieldWidth="max-content"
                inputFormat="dd/MMM/yyyy"
                label="End Date"
                disableFuture
                minDate={watchStartDate}
              />
              <Stack width="100%">
                <Button variant="contained" type="button" onClick={handleSubmit(ReportFilterHandler)}>
                  Filter
                </Button>
              </Stack>
            </Stack>
          </Popover>
        </FormProvider>
      </Stack>
      {moment(startDate).isSame(moment(endDate)) ? (
        <Stack alignItems={'center'} direction="row" gap="1rem" m={2} justifyContent={'center'}>
          <Typography variant="h6">{moment(startDate).format('ddd, DD MMM YYYY')}</Typography>
        </Stack>
      ) : (
        <Stack alignItems={'center'} direction="row" gap="1rem" m={2} justifyContent={'center'}>
          <Typography variant="h6">
            {moment(startDate).format('ddd, DD MMM YYYY')} - {moment(endDate).format('ddd, DD MMM YYYY')}
          </Typography>
        </Stack>
      )}
    </>
    // <FormProvider methods={methods}>
    //   <Tabs
    //     allowScrollButtonsMobile
    //     variant="scrollable"
    //     scrollButtons="auto"
    //     value={filterStatus}
    //     onChange={(event, newValue) => {
    //       onFilterStatus(event, newValue);
    //       methods.resetField('statusList');
    //       methods.setValue('approvalStatusList', [newValue]);
    //       dispatch(changeTimeClockStatus(newValue));
    //       TimeDurationFilterHandle();
    //     }}
    //     sx={{ px: 2, borderBottom: '1px solid rgba(145, 158, 171, 0.24)' }}
    //   >
    //     {TABS.map((tab) => (
    //       <Tab disableRipple key={tab.value} value={tab.value} label={tab.label} />
    //     ))}
    //   </Tabs>
    //   <Stack alignItems={'center'} direction="row" gap="1rem" m={2} justifyContent={'center'}>
    //     <Button variant={choosenDate === 'DAYS' ? 'contained' : 'outlined'} onClick={() => userReportFilter('DAYS')}>
    //       Today
    //     </Button>
    //     <Button
    //       variant={choosenDate === 'YESTERDAY' ? 'contained' : 'outlined'}
    //       onClick={() => userReportFilter('YESTERDAY')}
    //     >
    //       Yesterday
    //     </Button>
    //     <Button variant={choosenDate === 'WEEK' ? 'contained' : 'outlined'} onClick={() => userReportFilter('WEEK')}>
    //       This Week
    //     </Button>
    //     <Button
    //       variant={choosenDate === 'LAST_WEEK' ? 'contained' : 'outlined'}
    //       onClick={() => userReportFilter('LAST_WEEK')}
    //     >
    //       Last Week
    //     </Button>
    //     <Button
    //       variant={choosenDate === 'FORTNIGHT' ? 'contained' : 'outlined'}
    //       onClick={() => userReportFilter('FORTNIGHT')}
    //     >
    //       Fortnight
    //     </Button>
    //     <Button variant={choosenDate === 'MONTH' ? 'contained' : 'outlined'} onClick={() => userReportFilter('MONTH')}>
    //       30 Days
    //     </Button>
    //     <Button
    //       variant={choosenDate === 'LAST_MONTH' ? 'contained' : 'outlined'}
    //       onClick={() => userReportFilter('LAST_MONTH')}
    //     >
    //       Prev Month
    //     </Button>
    //     <Button onClick={handleOpenImportPopOver}>Custom</Button>
    //   </Stack>
    //   <Divider />
    //   <Popover
    //     id={ids}
    //     open={open}
    //     anchorEl={openImportDatePop}
    //     onClose={handleCloseImportPopOver}
    //     anchorOrigin={{
    //       vertical: 'top',
    //       horizontal: 'right',
    //     }}
    //     transformOrigin={{
    //       vertical: 'top',
    //       horizontal: 'left',
    //     }}
    //   >
    //     <Stack alignItems={'center'} direction="column" gap="1rem" m="1rem">
    //       <CustomDatePicker
    //         name="startDate"
    //         inputFormat="dd/MMM/yyyy"
    //         fieldWidth={'max-content'}
    //         label="Start Date"
    //         disableFuture
    //       />
    //       <CustomDatePicker
    //         name="endDate"
    //         fullWidth={false}
    //         fieldWidth="max-content"
    //         inputFormat="dd/MMM/yyyy"
    //         label="End Date"
    //         disableFuture
    //         minDate={watchStartDate}
    //       />
    //       <Stack width="100%">
    //         <Button
    //           variant="contained"
    //           type="button"
    //           onClick={() => {
    //             TimeDurationFilterHandle();
    //             setChoosenDate('CUSTOM');
    //           }}
    //         >
    //           Filter
    //         </Button>
    //       </Stack>
    //     </Stack>
    //   </Popover>
    // </FormProvider>
  );
}

export default TimeClockFilter;
