import { Box, Tooltip } from '@mui/material';
import Iconify from 'components/Iconify';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { findTotalofEachDays } from 'utils/roasterFeatureUtils';

function QuickSummaryClockInAllUsersTotalInfo({ today }) {
  const [getTotal, setGetTotal] = useState();

  const { control } = useFormContext();
  const values = useWatch({
    control,
    name: 'users',
  });

  useEffect(() => {
    if (!values && !values.length) return;
    //  const getTotalofEverything = findTotalofEachWeeks(today, values);
    const getTotal = findTotalofEachDays(today, values);
    setGetTotal(getTotal);
  }, [values, today]);
  return (
    <Box className="roasterTotalData">
      <div style={{ padding: '0', display: 'flex', justifyContent: 'center' }}>
        <Tooltip title="Total Cost">
          <Box style={{ display: 'inline-flex' }}>
            <Iconify icon="ic:baseline-attach-money" width={17} />
          </Box>
        </Tooltip>
        <p style={{ display: 'inline-flex', fontWeight: '600', marginTop: '-5px' }}>
          {getTotal?.totalPrice ? (getTotal?.totalPrice).toFixed(2) : 0}
        </p>
        <Tooltip title="Total Hours">
          <Box style={{ display: 'inline-flex', marginLeft: '7px' }}>
            <Iconify icon="material-symbols:nest-clock-farsight-analog-outline" width={17} />
          </Box>
        </Tooltip>
        <p style={{ display: 'inline-flex', fontWeight: '600', marginTop: '-5px' }}>
          {(getTotal?.totalTime ? getTotal?.totalTime / 60 : 0).toFixed(2)} Hrs.
        </p>
      </div>
    </Box>
  );
}

export default QuickSummaryClockInAllUsersTotalInfo;
