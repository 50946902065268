import { Button, Stack, Typography } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { FormProvider, RHFCheckbox, RHFSwitch, RHFTextField } from 'components/hook-form';
import * as Yup from 'yup';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { createFoodCategory, updateFoodCategory } from 'redux/slices/menuEngineeringSlice';

function FoodCategoryForm({ handleClose, selectedRow, isEdit }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const { postLoading } = useSelector((state) => state.menuEngineering);
  const { store } = useSelector((state) => state.store);
  const defaultValues = {
    name: '',
    hasHalfAndHalf: false,
  };
  const categorySchema = Yup.object().shape({
    name: Yup.string().required(''),
  });

  const methods = useForm({ defaultValues, resolver: yupResolver(categorySchema), mode: 'onBlur' });
  const { handleSubmit, control, reset } = methods;

  const OnCategoryCreate = (data) => {
    if (isEdit) {
      const finalData = { ...data, departmentId, storeId: store?.id };
      dispatch(updateFoodCategory({ finalData, handleClose }));
    } else {
      const finalData = { ...data, departmentId, storeId: store?.id };
      dispatch(createFoodCategory({ finalData, handleClose }));
    }
  };

  useEffect(() => {
    if (!selectedRow) return;
    reset({ ...selectedRow });
  }, [dispatch, reset, selectedRow]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(OnCategoryCreate)}>
      <Stack my="1rem" gap="1rem">
        <RHFTextField name="name" label="Name*" />
        <Stack spacing="1rem" display={'flex'} flexDirection={'row'} style={{ marginTop: '-5px' }}>
          <RHFCheckbox name="hasHalfAndHalf" style={{ marginTop: '4px' }} />
          <Typography style={{ fontSize: '0.8rem', marginLeft: '-15px' }}>Half And Half</Typography>
        </Stack>
        {/* <RHFSwitch name="hasHalfAndHalf" label="Has Half And Half" /> */}
      </Stack>
      <Stack alignItems="flex-end" justifyContent={'flex-end'} mt="1rem" direction="row" gap="1rem">
        <Button
          variant="contained"
          type="submit"
          color="secondary"
          // disabled={!isChanged}
          // onClick={handleSubmit(ImageSubmitHandler)}
        >
          {postLoading ? <CustomLoadingScreen circularWidth={'20px'} color="secondary" /> : 'Submit'}
          {/* Submit */}
        </Button>
        <Button variant="contained" onClick={handleClose} color="error">
          Cancel
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default FoodCategoryForm;
