import { Box, Button, Card, Grid, Stack, TextField } from '@mui/material';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { FormProvider, RHFTextField } from 'components/hook-form';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import { createRunnigCost, updateRunningCost } from 'redux/slices/operationalSlice';

RunningCostAddEditForm.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  // updateData: PropTypes.object,
  edit: PropTypes.bool,
};
export default function RunningCostAddEditForm({ open, setOpen, edit, updateData, choosenDate, setChoosenDate }) {
  // const date = new Date();
  const { break: storeDetail } = useSelector((state) => state.store);
  // const [selectedDate, setSelectedDate] = useState(date);
  const departmentId = useGetDepartmentId();

  const closeModal = () => {
    setOpen(false);
  };

  const defaultValues = useMemo(
    () => ({
      departmentId,
      storeId: storeDetail?.id,
      marketingCalendar: updateData?.marketingCalendar || 0,
      bankCharge: updateData?.bankCharge || 0,
      eftRentalFee: updateData?.eftRentalFee || 0,
      donations: updateData?.donations || 0,
      asicsFee: updateData?.asicsFee || 0,
      lateFeesPaid: updateData?.lateFeesPaid || 0,
      plantEquipment: updateData?.plantEquipment || 0,
      printingStationary: updateData?.printingStationary || 0,
      security: updateData?.security || 0,
      uniforms: updateData?.uniforms || 0,
      crockery: updateData?.crockery || 0,
      chemicals: updateData?.chemicals || 0,
      lighting: updateData?.lighting || 0,
      fireEquipment: updateData?.fireEquipment || 0,
      pestControls: updateData?.pestControls || 0,
      decorations: updateData?.decorations || 0,
      maintenance: updateData?.maintenance || 0,
      pettyCost: updateData?.pettyCost || 0,
      fuel: updateData?.fuel || 0,
      vehiclesRego: updateData?.vehiclesRego || 0,
      vehiclesMaintenance: updateData?.vehiclesMaintenance || 0,
      miscellaneous: updateData?.miscellaneous || 0,
    }),
    [storeDetail, updateData]
  );

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit } = methods;

  const dispatch = useDispatch();

  const runningCostSubmitHandler = (data) => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        setChoosenDate(choosenDate);
        closeModal();
      }
    };
    if (edit) {
      const finalData = {
        ...data,
        month: moment(choosenDate).format('MM'),
        year: moment(choosenDate).format('YYYY'),
        id: updateData?.id,
      };
      dispatch(updateRunningCost(finalData));
      closeModal();
    } else {
      const finalData = {
        ...data,
        month: moment(choosenDate).format('MM'),
        year: moment(choosenDate).format('YYYY'),
      };

      dispatch(createRunnigCost({ finalData, isSuccess: isSuccessful }));
      closeModal();
    }
  };

  return (
    <>
      <CustomOutletModal
        width="60rem"
        open={open}
        zIndex="1600"
        title={edit ? 'Edit Running Cost Details' : 'Add Running Cost Details'}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(runningCostSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card sx={{ py: 3, px: 3 }}>
                <Box>
                  <Grid container spacing={2} sx={{ p: 1 }}>
                    <Grid item xs={12}>
                      <DatePicker
                        disabled={edit}
                        disablePast
                        views={['year', 'month']}
                        label="Select Date"
                        value={choosenDate}
                        onChange={(newValue) => {
                          setChoosenDate(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} error={false} />}
                      />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`marketingCalendar`} label="marketingCalendar cost" />
                    </Grid>

                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`bankCharge`} label="bankCharge" />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`eftRentalFee`} label="eftRentalFee Cost" />
                    </Grid>

                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`donations`} label="donations Cost" />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`asicsFee`} label="asicsFee cost" />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`lateFeesPaid`} label="lateFeesPaid" />
                    </Grid>

                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`plantEquipment`} label="Equipment plant Cost" />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`printingStationary`} label="Stationary Printing Cost" />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`security`} label="security Cost" />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`uniforms`} label="uniforms Cost" />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`crockery`} label="crockery Cost" />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`chemicals`} label="chemicals Cost" />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`lighting`} label="lighting Cost" />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`fireEquipment`} label="fire Equipment Cost" />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`pestControls`} label="pest Controls Cost" />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`decorations`} label="decorations Cost" />
                    </Grid>

                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`maintenance`} label="maintenance Cost" />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`pettyCost`} label="petty Cost Cost" />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`fuel`} label="fuel Cost" />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`vehiclesRego`} label="vehicles Rego Cost" />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`vehiclesMaintenance`} label="vehicles Maintenance Cost" />
                    </Grid>
                    <Grid item xs={4} sm={3}>
                      <RHFTextField name={`miscellaneous`} label="miscellaneous Cost" />
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Stack alignItems={'flex-end'}>
                <div>
                  <Button type="submit" variant="contained" style={{ marginRight: '5px' }}>
                    {edit ? 'Update' : 'Create'}
                  </Button>
                  <Button variant="text" style={{ color: 'red' }} onClick={closeModal}>
                    Cancel
                  </Button>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </CustomOutletModal>
    </>
  );
}
