import { Button, Stack } from '@mui/material';
import CustomMultipleAutoCompleteField from 'components/CustomComponents/CustomAutoCompleteField';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import * as yup from 'yup';
import Iconify from 'components/Iconify';
import { RHFTextField } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createUpdateDealOffer, getSizesInCategories } from 'redux/slices/dealSlice';

const schema = yup.object().shape({
  qty: yup.number().required('').typeError(''),
});

function AddNewDealCategory({ open, handleClose, dealData }) {
  console.log('🚀 => AddNewDealCategory => dealData=>', dealData);

  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const { foodCategories } = useSelector((state) => state.menuEngineering);
  const { sizesListInCategory } = useSelector((state) => state.deal);
  const defaultValues = {};
  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onChange' });

  const { control, watch, handleSubmit } = methods;

  const createNewDealCategory = (data) => {
    const category = { qty: data?.qty, categoryId: data?.categoryId?.id, sizeId: data?.sizeId?.id };
    const formData = new FormData();
    const finalData = {
      ...dealData,
      departmentId,
      dealCategories: [category],
    };

    formData.append('deal', new Blob([JSON.stringify(finalData)], { type: 'application/json' }));

    dispatch(createUpdateDealOffer({ formData, departmentId, handleClose, isUpdate: true, id: dealData?.id }));
  };

  const watchCategoryId = watch(`categoryId`);

  useEffect(() => {
    if (!watchCategoryId) return;
    if (!departmentId) return;
    dispatch(getSizesInCategories({ departmentId, categoryId: watchCategoryId?.id }));
  }, [departmentId, dispatch, watchCategoryId]);
  return (
    <CustomOutletModal open={open} zIndex="1600" title="Add New Deal Category" onClose={handleClose}>
      <FormProvider {...methods}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1}>
          <RHFTextField name={`qty`} label="Qty" />
          <CustomMultipleAutoCompleteField
            name={`categoryId`}
            label="Select Category*"
            options={foodCategories}
            getOptionLabel={(option) => option?.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            sx={{ width: '13rem' }}
          />
          <CustomMultipleAutoCompleteField
            name={`sizeId`}
            label="Select Size*"
            options={sizesListInCategory}
            disabled={!watchCategoryId}
            getOptionLabel={(option) => option?.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            sx={{ width: '13rem' }}
          />
        </Stack>
        <Stack justifyContent={'flex-end'} spacing={1} direction={'row'} marginTop={1}>
          <Button variant="contained" color="secondary" onClick={handleSubmit(createNewDealCategory)}>
            Save
          </Button>
          <Button
            variant="outlined"
            color="error"
            //  startIcon={<Iconify icon="material-symbols:add" />}
            onClick={handleClose}
          >
            close
          </Button>
        </Stack>
      </FormProvider>
    </CustomOutletModal>
  );
}

export default AddNewDealCategory;
