import { Box, Button, Grid, MenuItem, Popover, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import Iconify from 'components/Iconify';
import { TableMoreMenu } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  cleanPastMonthRunningCost,
  // createRunnigCost,
  getPreviousMonthRunningCost,
  getRunningCostList,
} from 'redux/slices/operationalSlice';

RunningCostFilter.propTypes = {
  choosenDate: PropTypes.object,
  setChoosenDate: PropTypes.func,
};

export default function RunningCostFilter({ choosenDate, setChoosenDate }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const [openMenu, setOpenMenuActions] = useState(null);
  const [openImportDatePop, setOpenImportDatePop] = useState(null);
  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleOpenImportPopOver = (event) => {
    setOpenImportDatePop(event.currentTarget);
  };
  const handleCloseImportPopOver = () => {
    handleCloseMenu();
    setOpenImportDatePop(null);
  };

  const LastMonthCostImportHandler = () => {
    const lastMonth = moment(choosenDate).subtract('1', 'month');
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        dispatch(
          getRunningCostList({
            departmentId,
            month: moment(choosenDate).format('MM'),
            year: moment(choosenDate).format('YYYY'),
          })
        );
        dispatch(cleanPastMonthRunningCost());
      }
    };

    dispatch(
      getPreviousMonthRunningCost({
        departmentId,
        month: moment(lastMonth).format('MM'),
        year: moment(lastMonth).format('YYYY'),
        isSuccess: isSuccessful,
        currentMonth: moment(choosenDate).format('MM'),
        currentYear: moment(choosenDate).format('YYYY'),
      })
    );
    handleCloseImportPopOver();
    handleCloseMenu();
  };

  const ImportDateChangeCostHandler = (selectedDate) => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        dispatch(
          getRunningCostList({
            departmentId,
            month: moment(choosenDate).format('MM'),
            year: moment(choosenDate).format('YYYY'),
          })
        );
        dispatch(cleanPastMonthRunningCost());
      }
    };

    dispatch(
      getPreviousMonthRunningCost({
        departmentId,
        month: moment(selectedDate).format('MM'),
        year: moment(selectedDate).format('YYYY'),
        isSuccess: isSuccessful,
        currentMonth: moment(choosenDate).format('MM'),
        currentYear: moment(choosenDate).format('YYYY'),
      })
    );
    handleCloseImportPopOver();
    handleCloseMenu();
  };

  const open = Boolean(openImportDatePop);
  const ids = open ? 'simple-popover' : undefined;
  const { runningCost } = useSelector((state) => state.operational);
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6} md={4}>
        <DatePicker
          label={'Select Date'}
          views={['month', 'year']}
          value={choosenDate}
          renderInput={(params) => <TextField {...params} error={false} />}
          onChange={(newValue) => setChoosenDate(newValue)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={8}>
        <Box style={{ textAlign: 'right' }}>
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <MenuItem>
                  <Button
                    onClick={handleOpenImportPopOver}
                    sx={{ width: '100%', padding: '0.2rem' }}
                    variant="text"
                    disabled={runningCost?.id}
                  >
                    <Iconify icon={'tabler:file-import'} />
                    Import
                  </Button>
                </MenuItem>
              </>
            }
          />

          <Popover
            id={ids}
            open={open}
            anchorEl={openImportDatePop}
            onClose={handleCloseImportPopOver}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Stack p={2} gap={2}>
              Select Import Date
              <Stack alignItems="flex-start">
                <Button variant="contained" onClick={LastMonthCostImportHandler}>
                  Last Month
                </Button>
              </Stack>
              or
              <DatePicker
                label={'Select Date'}
                openTo="year"
                views={['year', 'month']}
                value={choosenDate}
                renderInput={(params) => <TextField {...params} error={false} />}
                onChange={(newValue) => ImportDateChangeCostHandler(newValue)}
              />
            </Stack>
          </Popover>
          <Button
            variant="outlined"
            style={{ marginRight: '5px' }}
            onClick={() => {
              setChoosenDate(moment(choosenDate).subtract('30', 'days'));
            }}
          >
            Previous Month
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              setChoosenDate(moment(choosenDate).add('30', 'days'));
            }}
          >
            Next Month
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
