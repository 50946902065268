import { Button, TableCell, TableRow } from '@mui/material';

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Iconify from 'components/Iconify';

function IndividualChecklistDeleted({ item, parentCategory, index }) {
  return (
    <TableRow hover>
      <TableCell>{index + 1}.</TableCell>
      <TableCell>{item?.title}</TableCell>
      <TableCell>{item?.type}</TableCell>
      <TableCell>{item?.taskDate}</TableCell>
      <TableCell>{item?.isRecurring ? 'Yes' : 'No'}</TableCell>
      <TableCell align="left">{item?.note}</TableCell>
    </TableRow>
  );
}

export default IndividualChecklistDeleted;
