import { Icon } from '@iconify/react';
import {
  Autocomplete,
  Button,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CustomMultipleAutoCompleteField from 'components/CustomComponents/CustomAutoCompleteField';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { RHFSelect } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { createStockOrder } from 'redux/slices/stockSlice';
import { dispatch, useSelector } from 'redux/store';
import { convertDatetoString } from 'utils/customDateAndTimeFormat';

const TABLE_HEAD = [
  { id: 1, name: 'UID' },
  { id: 2, name: 'Brand (Description)' },
  { id: 3, name: 'Order Qty' },
  { id: 4, name: 'Unit Price' },
  { id: 5, name: 'Total' },
  { id: 6, name: 'Actions' },
];

function StockOrderConfirmModal({ open, setOpen, currentValue = {} }) {
  const { storeSuppliers, store } = useSelector((state) => state.store);
  const { postLoading } = useSelector((state) => state.stocks);

  const { timeDT } = useSelector((state) => state.user);
  const departmentId = useGetDepartmentId();
  const [totalPrice, setTotalPrice] = useState(0);

  const { getValues, handleSubmit, setValue, watch } = useFormContext();
  const watchAll = watch();

  const checkGetValues = !!Object.values(watchAll).filter((el) => el.orderQty).length;

  const createOrderHandler = (data) => {
    const filterData = Object.values(data)
      .filter((obj) => obj.orderQty)
      .map((el) => ({
        orderQty: Number(el?.orderQty),
        stockItemId: el?.stockItemId,
        supplierId: el?.supplierId,
        changedSupplierId: el?.changedSupplierId,
        amount: Number((Number(el?.orderQty) * Number(el?.unitPrice)).toFixed(2)),
      }));

    const finalData = {
      orderDate: convertDatetoString(new Date()),
      departmentId,
      orderItems: filterData,
      totalExGst: filterData?.reduce((acc, current) => acc + current?.amount, 0),
    };

    dispatch(createStockOrder({ finalData }));
  };

  const mapWatchAll = Object.values(watchAll).reduce((acc, current) => {
    if (!current?.orderQty) return [...acc];
    const currentSupplierId = current?.supplierId;
    const findSupplierId = acc.find((obj) => obj.supplierId === currentSupplierId);
    if (findSupplierId) {
      findSupplierId.data.push(current);
    } else {
      acc.push({ supplierId: currentSupplierId, changedSupplierId: current?.changedSupplierId, data: [current] });
    }
    return [...acc];
  }, []);

  const totalPrices = mapWatchAll
    ?.reduce((acc, current) => {
      const calc = current?.data.reduce((ac, el) => el.orderQty * el?.unitPrice + ac, 0);
      return calc + acc;
    }, 0)
    .toFixed(2);

  const DeleteStockOrder = (obj) => {
    setValue(`${obj.stockItemId}.orderQty`, 0);
  };

  const onChangeSupplierHandler = (newSupplier, data) => {
    data?.map((stock) => {
      return setValue(`${stock.stockItemId}.changedSupplierId`, newSupplier?.id);
    });
  };

  return (
    <CustomDrawer
      open={open}
      setOpen={setOpen}
      title="Confirm Stock Orders"
      PaperProps={{ sx: { width: { md: '50vw', xs: '100%' } } }}
    >
      <form onSubmit={handleSubmit(createOrderHandler)}>
        <Stack gap="1.5rem" className="stockOrderConfirmModalContainer">
          <Stack
            flexDirection={{ md: 'row', xs: 'column' }}
            gap="1rem"
            alignItems="flex-start"
            className="stackOrderModalContent"
            justifyContent={'space-between'}
          >
            <Stack>
              <p>
                Store: <span>{store?.name}</span>
              </p>
              <p>
                Email : <span> {store?.email}</span>
              </p>
              <p>
                Phone Number : <span> {store?.phone}</span>
              </p>
              <p>
                Date : <span>{convertDatetoString(new Date(timeDT))}</span>
              </p>
            </Stack>
            <Stack gap="1rem">
              <p> Total Amount : $ {totalPrices} </p>
              {checkGetValues && (
                <Button variant="contained" type="submit" disabled={postLoading}>
                  {postLoading && <CustomLoadingScreen circularWidth={'1rem'} color="error" width="auto" />}
                  <Typography ml="0.5rem">Confirm and Send</Typography>
                </Button>
              )}
            </Stack>
          </Stack>

          {mapWatchAll?.map((el, index) => {
            const supplier = storeSuppliers.find((obj) => obj.id === Number(el?.supplierId));
            const changeSupplier = storeSuppliers.find((obj) => obj.id === Number(el?.changedSupplierId));

            return (
              <Stack key={index} className="stockOrderConfirmModalContent">
                <Stack
                  className="stackOrderModalContent"
                  flexDirection={{ md: 'row', xs: 'column' }}
                  gap="1rem"
                  justifyContent="space-between"
                >
                  <Stack my={'0.8rem'}>
                    <Typography component={'p'}>
                      Suppliers : <span> {supplier?.name}</span>
                    </Typography>

                    <Typography component={'p'} mb="0.7rem">
                      Email : <span> {supplier?.email}</span>
                    </Typography>
                    <Autocomplete
                      name={`Supplier${changeSupplier?.id}`}
                      defaultValue={changeSupplier}
                      size="small"
                      options={storeSuppliers}
                      onChange={(event, data) => {
                        onChangeSupplierHandler(data, el?.data);
                      }}
                      getOptionLabel={(option) => option?.name}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      renderInput={(params) => <TextField label="Change Supplier" {...params} />}
                    />
                  </Stack>
                  <Stack my={'0.8rem'}>
                    <p>
                      Payment Due : <span> {supplier?.dueDays}</span>
                    </p>
                    <p>
                      ABN : <span> {supplier?.abn}</span>
                    </p>
                  </Stack>
                </Stack>

                <Stack className="stackOrderModalContent2">
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {TABLE_HEAD.map((obj) => (
                            <TableCell key={obj.id}>{obj?.name}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {el?.data?.map((obj, index) => {
                          const total = Number(obj?.orderQty) * Number(obj?.unitPrice);
                          return (
                            <TableRow key={index} sx={{ borderBottom: '1px solid #F4F6F8' }}>
                              <TableCell>{obj?.externalUID}</TableCell>
                              <TableCell>
                                <p>{obj?.brand}</p>
                                <span>{obj?.productDescription}</span>
                              </TableCell>
                              <TableCell>{obj?.orderQty}</TableCell>
                              <TableCell>{obj?.unitPrice}</TableCell>
                              <TableCell>$ {total}</TableCell>
                              <TableCell>
                                <Button variant="text" color="error" onClick={() => DeleteStockOrder(obj)}>
                                  <Icon icon="material-symbols:delete-rounded" width="20px" />
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Stack>
              </Stack>
            );
          })}
        </Stack>
      </form>
    </CustomDrawer>
  );
}

export default StockOrderConfirmModal;
