import { TableCell } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

function FoodMenuTopinsWithQtySummaryRow({ menuSize, index }) {
  const { control } = useFormContext();
  const watchData = useWatch({
    control,
    name: 'menuOptions',
  });

  const findSummary = () => {
    let total = 0;
    watchData?.forEach((item) => {
      total += Number(item?.menuSizes[index]?.cost ?? 0);
      return total;
    });
    return total.toFixed(2);
  };

  const findTotalWeight = () => {
    let total = 0;
    watchData?.forEach((item) => {
      total += Number(item?.menuSizes[index]?.qtyUsed ?? 0);
      return total;
    });
    return total.toFixed(2);
  };

  return (
    <TableCell style={{ fontWeight: 'bold' }} align="center">
      {findTotalWeight()} gm / $ {findSummary()}
    </TableCell>
  );
}

export default FoodMenuTopinsWithQtySummaryRow;
