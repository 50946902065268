import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import React from 'react';
import { PATH_DASHBOARD } from 'routes/paths';

function InsightReportHeader() {
  return (
    <HeaderBreadcrumbs
      heading="Insights Report"
      links={[
        { name: 'Dashboard', href: PATH_DASHBOARD.root },
        { name: 'Report', href: PATH_DASHBOARD.report.root },
        { name: 'Insights' },
      ]}
    />
  );
}

export default InsightReportHeader;
