/* eslint-disable no-nested-ternary */
import { Avatar, Box, Card, Stack } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteDealOffer, setSelectedDeal } from 'redux/slices/dealSlice';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';
import PropTypes from 'prop-types';
import Iconify from 'components/Iconify';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import ViewDealDetails from './ViewDealDetails';

DealOfferBodyTableRow.propTypes = {
  el: PropTypes.object,
};

function DealOfferBodyTableRow({ el, index }) {
  const [openDealDelete, setOpenDealDelete] = useState({ status: false, dealId: null });
  const [openViewDeal, setOpenViewDeal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const departmentId = useGetDepartmentId();

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: el?.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: isDragging ? '#cccccc' : '',
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.5 : 1,
  };

  const deleteDeal = () => {
    dispatch(deleteDealOffer({ id: openDealDelete?.dealId, departmentId }));
    setOpenDealDelete({ status: false, dealId: null });
  };

  const EditDeal = (el) => {
    dispatch(setSelectedDeal(el));
    navigate(PATH_DASHBOARD.food.editDeal(el?.id));
  };
  return (
    <>
      <Card sx={{ p: 2 }} style={{ ...style, borderBottom: '0.5px solid rgba(145, 158, 171, 0.24)' }} ref={setNodeRef}>
        <Stack style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Title and description */}
          <Stack style={{ width: '320px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div {...listeners} {...attributes} style={{ marginRight: '10px', marginTop: '10px', cursor: 'move' }}>
                <Iconify icon="system-uicons:drag" height={20} width={20} />
              </div>
              <div>
                {el?.thumbnailUrl ? (
                  <Avatar
                    src={el?.thumbnailUrl}
                    alt=""
                    style={{
                      height: '60px',
                      width: '60px',
                      borderRadius: '100%',
                      marginRight: '10px',
                    }}
                  />
                ) : (
                  <Avatar
                    alt=""
                    src=""
                    style={{ height: '60px', width: '60px', borderRadius: '100%', marginRight: '10px' }}
                  />
                )}
              </div>
              <div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: '600', marginRight: '10px' }}>{el?.title}</p>
                  <p style={{ fontWeight: '800' }}>{`($ ${el?.sellingPrice ?? 0})`}</p>
                </div>
                <div>
                  <p>{el?.description}</p>
                </div>
              </div>
            </div>
          </Stack>
          {/* Date time */}
          <Stack direction="column" justifyContent="flex-start" spacing={'1px'}>
            <div style={{ display: 'flex' }}>
              <Box style={{ marginTop: '2px' }}>
                <Iconify icon="material-symbols:timer-outline" style={{ marginRight: '10px' }} />
              </Box>{' '}
              {moment(el?.startDateTime).format('ddd, DD MMM, YYYY')}
            </div>
            <div style={{ display: 'flex' }}>
              <Box style={{ marginTop: '2px' }}>
                <Iconify icon="material-symbols:timer-off-outline" style={{ marginRight: '10px' }} />
              </Box>
              {moment(el?.endDateTime).format('ddd, DD MMM, YYYY')}
            </div>
          </Stack>
          {/* Occurance type */}
          <Stack
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}
          >
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <Box style={{ marginTop: '2px' }}>
                <Iconify icon="wpf:recurring-appointment" height={20} width={20} style={{ marginRight: '10px' }} />
              </Box>
              {el?.occuranceType === 'DAILY'
                ? 'Daily'
                : el?.occuranceType === 'WEEKLY'
                ? 'Weekly'
                : el?.occuranceType === 'MONTHLY'
                ? 'Monthly'
                : el?.occuranceType === 'YEARLY'
                ? 'Yearly'
                : el?.occuranceType === 'FORTNIGHTLY'
                ? 'Fortnightly'
                : 'Once'}
            </div>
          </Stack>
          {/* Action Buttons */}
          <Stack direction="row" alignItems="center">
            <Box style={{ margin: '3px', padding: '3px', cursor: 'pointer' }} onClick={() => EditDeal(el)}>
              <Iconify icon="bx:edit" color="blue" height={20} width={20} />
            </Box>
            <Box
              style={{ margin: '3px', padding: '3px', cursor: 'pointer' }}
              onClick={() => {
                setOpenDealDelete({ status: true, dealId: el?.id });
              }}
            >
              <Iconify icon="bx:trash" color="red" height={20} width={20} />
            </Box>
          </Stack>
        </Stack>
      </Card>

      {openDealDelete?.status && (
        <CustomDeleteDialog
          open={openDealDelete?.status}
          handleClose={() => setOpenDealDelete({ status: false, dealId: null })}
          onAccept={deleteDeal}
        />
      )}
      {openViewDeal && <ViewDealDetails open={openViewDeal} handleClose={() => setOpenViewDeal(false)} deal={el} />}
    </>
  );
}

export default DealOfferBodyTableRow;
