import { Card } from '@mui/material';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getReport } from 'redux/slices/reportSlice';
import ReportFilter from './ReportFilter';
import ReportCardBodyChart from './ReportCardBodyChart';

function ReportCardBody() {
  return (
    <Card>
      <ReportFilter />
      <ReportCardBodyChart />
    </Card>
  );
}

export default ReportCardBody;
