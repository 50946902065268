import { Box, Card, Divider, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import QuickSummaryClockInDetails from 'sections/@dashboard/quickSummary/clockInDetails/QuickSummaryClockInDetails';
import QuickSummaryDifferenceInfo from 'sections/@dashboard/quickSummary/difference/QuickSummaryDifferenceInfo';
import QuickSummaryFilter from 'sections/@dashboard/quickSummary/QuickSummaryFilter';
import QuickSummaryRoasterInfo from 'sections/@dashboard/quickSummary/roasterInfo/QuickSummaryRoasterInfo';

function QuickSummaryBody() {
  const [tabValue, setTabValue] = useState('clockInDetails');
  const [choosenDate, setChoosenDate] = useState(new Date());

  return (
    <>
      <QuickSummaryFilter choosenDate={choosenDate} setChoosenDate={setChoosenDate} />
      <Card>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingLeft: '0.7rem' }}>
          <Tabs
            value={tabValue}
            onChange={(e, value) => {
              setTabValue(value);
            }}
            aria-label="Quick Summary Tabs"
            // sx={{ px: 2, bgcolor: 'background.neutral' }}
          >
            <Tab label="Clock In Details" value="clockInDetails" />
            <Tab label="Roster Info" value="roasterInfo" />
            <Tab label="Difference" value="difference" />
          </Tabs>
          <Divider />
        </Box>
        {tabValue === 'clockInDetails' && <QuickSummaryClockInDetails choosenDate={choosenDate} />}

        {tabValue === 'roasterInfo' && <QuickSummaryRoasterInfo choosenDate={choosenDate} />}

        {tabValue === 'difference' && <QuickSummaryDifferenceInfo choosenDate={choosenDate} />}
      </Card>
    </>
  );
}

export default QuickSummaryBody;
