import { Box, Card } from '@mui/material';
import React from 'react';
import TimeDurationClockFilter from 'sections/@dashboard/time/TimeDurationClockFilter';
import TimeDurationClockTable from './TimeDurationClockTable';

function TimeDurationClockBody() {
  return (
    <Card>
      <TimeDurationClockFilter />
      <TimeDurationClockTable />
    </Card>
  );
}

export default TimeDurationClockBody;
