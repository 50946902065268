import { Box, Button, Modal, TableCell, TableRow, Typography } from '@mui/material';
import Iconify from 'components/Iconify';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { deleteChecklistCategory } from 'redux/slices/storeDocumentSlice';
import ChecklistCategoriesForm from './ChecklistCategoriesForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function ChecklistIndividualItem({ item, selected, index }) {
  const [openAdd, setOpenAdd] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  return (
    <>
      <Modal
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure want to Delete this Category?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, ml: 8 }}>
            <Button
              variant="contained"
              color="success"
              sx={{ mr: 4 }}
              onClick={() => {
                dispatch(deleteChecklistCategory({ id: item?.id, departmentId }));
                setOpenDelete(false);
              }}
            >
              Yes
            </Button>
            <Button variant="outlined" color="error" onClick={() => setOpenDelete(false)}>
              No
            </Button>
          </Typography>
        </Box>
      </Modal>
      <TableRow hover selected={selected}>
        <TableCell>{index + 1}.</TableCell>
        <TableCell style={{ width: '500px', color: `${item?.colorCode}` }} align="left">
          {item.name}
        </TableCell>
        <TableCell align="center">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Button size="small" onClick={() => setOpenAdd(true)}>
              <Iconify icon={'eva:edit-fill'} height={20} width={20} />
            </Button>
            <Button size="small" onClick={() => setOpenDelete(true)} color="error">
              <Iconify icon={'ph:trash-bold'} height={20} width={20} />
            </Button>
          </div>
        </TableCell>
      </TableRow>
      {/* <Grid item xs={12} sm={6} md={4} lg={3} key={item.id} selected={selected}>
        <Card sx={{ width: '100%', padding: '10px', margin: '5px' }}>
          <Stack direction={'row'}>
            <div style={{ color: `${item?.colorCode}`, marginRight: '30px' }}>{item.name}</div>

            <div>
              <Button onClick={() => setOpenAdd(true)}>
                <Iconify icon={'eva:edit-fill'} height={20} width={20} />
              </Button>
            </div>
            <div>
              <Button onClick={() => setOpenDelete(true)} color="error">
                <Iconify icon={'ph:trash-bold'} height={20} width={20} />
              </Button>
            </div>
          </Stack>
        </Card>
      </Grid> */}
      {openAdd && <ChecklistCategoriesForm setOpen={setOpenAdd} open={openAdd} updateData={item} edit />}
    </>
  );
}

ChecklistIndividualItem.propTypes = {
  item: PropTypes.object,
  selected: PropTypes.bool,
  index: PropTypes.number,
};

export default ChecklistIndividualItem;
