import { Button, Card, CardActionArea, CardContent, CardMedia, Divider, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import formatStringLength from 'utils/formatStringLength';
import ForkSpoon from 'assets/ForkSpoon.jpg';
import FoodRecipeMenuSizes from './FoodRecipeMenuSizes';
import FoodRecipeManageDrawer from './FoodRecipeManageDrawer';

function FoodRecipeMenus({ menu }) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Card>
        <CardMedia component="img" height="140" image={menu?.imageUrl || ForkSpoon} alt="Menu Image" />
        <CardContent>
          <h3 style={{ fontSize: '1rem', fontWeight: '700' }}>{formatStringLength(menu?.name, 25)}</h3>
          <Stack bgcolor={'rgba(145, 158, 171, 0.12)'} p="0.4rem" borderRadius={'10px'}>
            <Typography component={'p'} fontSize="0.9rem" fontWeight="600">
              Recipe
            </Typography>
            <Stack
              flexWrap={'wrap'}
              flexDirection={'row'}
              gap="0.4rem"
              divider={<Divider orie ntation="vertical" flexItem />}
            >
              {menu?.sizes?.map((size, index) => (
                <FoodRecipeMenuSizes key={index} size={size} />
              ))}
            </Stack>
          </Stack>
          <Stack alignItems={'flex-end'} my="0.4rem">
            <Button variant="text" onClick={() => setOpen(true)}>
              Manage
            </Button>
          </Stack>
        </CardContent>
      </Card>

      {open && <FoodRecipeManageDrawer open={setOpen} setOpen={setOpen} menu={menu} />}
    </>
  );
}

export default FoodRecipeMenus;
