import { useCallback } from 'react';

// form
import { Controller, useFormContext } from 'react-hook-form';

// @mui
import {
  Box,
  Card,
  Grid,
  Typography,
  FormLabel,
  Divider,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { fData } from 'utils/formatNumber';

// components
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import ReactInputMask from 'react-input-mask';

import { useSelector } from 'react-redux';
import { RHFTextField, RHFUploadAvatar } from '../../../components/hook-form';

// ----------------------------------------------------------------------

const genderOptions = [
  {
    value: 'MALE',
    label: 'MALE',
  },
  {
    value: 'FEMALE',
    label: 'FEMALE',
  },
  {
    value: 'OTHER',
    label: 'OTHER',
  },
];

export default function StaffNewEditForm({ isEdit }) {
  const { setValue, control, watch } = useFormContext();
  const { uuidData } = useSelector((state) => state.auth);

  const watchImage = watch('imgURL');

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'profileImage',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const validateDateOfBirth = moment().subtract(13, 'years').toDate();

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3.2}>
          <Card sx={{ py: 10, px: 3 }}>
            <Box sx={{ mb: 5 }}>
              <Box sx={{ mb: 5 }}>
                <RHFUploadAvatar
                  name="profileImage"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  isEditImage={isEdit ? { isEdit, imgUrl: watchImage } : null}
                  helperText={
                    <Typography
                      variant="caption"
                      sx={{
                        mt: 2,
                        mx: 'auto',
                        display: 'block',
                        textAlign: 'center',
                        color: 'text.secondary',
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png
                      <br /> max size of {fData(3145728)}
                    </Typography>
                  }
                />
              </Box>
            </Box>
          </Card>
        </Grid>

        <Grid item xs={12} md={8.8}>
          <Card sx={{ py: 3, px: 3 }}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <RHFTextField name={`firstName`} label="First Name *" InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item xs={4}>
                  <RHFTextField name={`middleName`} label="Middle Name" InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item xs={4}>
                  <RHFTextField name={`lastName`} label="Last Name *" InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <ReactInputMask
                        maskChar=""
                        mask="9999 999 999"
                        label="Phone *"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        {...field}
                      >
                        {(inputProps) => (
                          <TextField {...inputProps} fullWidth error={!!error} helperText={error?.message} />
                        )}
                      </ReactInputMask>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  {isEdit ? (
                    <RHFTextField name={`email`} label="Email *" InputLabelProps={{ shrink: true }} disabled />
                  ) : (
                    <RHFTextField
                      name={'email'}
                      label="Email *"
                      InputLabelProps={{ shrink: true }}
                      disabled
                      value={uuidData?.email}
                    />
                  )}
                  {/* <RHFTextField name={`email`} label="Email *" InputLabelProps={{ shrink: true }} /> */}
                  {/* <RHFTextField
                    name={'email'}
                    label="Email *"
                    InputLabelProps={{ shrink: true }}
                    disabled
                    value={uuidData?.email || }
                  /> */}
                </Grid>

                <Grid item xs={6}>
                  <Box>
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <div>
                          <FormLabel sx={{ color: error ? 'red' : '' }}> Gender * </FormLabel>

                          <RadioGroup {...field} row>
                            {genderOptions.map((option) => (
                              <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio />}
                                label={option.label}
                              />
                            ))}
                          </RadioGroup>
                        </div>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name={'dob'}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <DatePicker
                        name="dob"
                        maxDate={validateDateOfBirth}
                        views={['year', 'month', 'day']}
                        inputFormat="dd-MM-yyyy"
                        {...field}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="DOB *"
                            onKeyDown={(e) => e.preventDefault()}
                            helperText={error?.message}
                            fullWidth
                            error={!!error}
                            InputLabelProps={{ shrink: true }}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={6}>
                  <RHFTextField name={`state`} label="state *" InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item xs={6}>
                  <RHFTextField name={`suburb`} label="suburb *" InputLabelProps={{ shrink: true }} />
                </Grid>

                <Grid item xs={6}>
                  <RHFTextField name={`address`} label="Address *" InputLabelProps={{ shrink: true }} />
                </Grid>
                <Grid item xs={6}>
                  <RHFTextField name={`zipcode`} label="zipcode *" InputLabelProps={{ shrink: true }} />
                </Grid>

                <Grid item xs={6}>
                  <RHFTextField
                    name={`emergencyContactName`}
                    label="Emergency Contact Name"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="emergencyContactNo"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <ReactInputMask
                        maskChar=""
                        mask="9999 999 999"
                        alwaysShowMask={false}
                        label="Emergency Contact No"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        {...field}
                      >
                        {(inputProps) => (
                          <TextField {...inputProps} fullWidth error={!!error} helperText={error?.message} />
                        )}
                      </ReactInputMask>
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
