import Page from 'components/Page';
import React from 'react';
import PayTypeHeader from './PayTypeHeader';
import PayTypeBody from './PayTypeBody';

function PayTypeIndex() {
  return (
    <Page title="Store: pay-types">
      <PayTypeHeader />
      <PayTypeBody />
    </Page>
  );
}

export default PayTypeIndex;
