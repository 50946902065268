import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedDepartment } from 'redux/slices/userDepartmentSlice';
import LoadingScreen from 'components/LoadingScreen';
import { Box, Card, CardContent, Grid } from '@mui/material';
import CustomOutletModal from './CustomOutletModal';
import CustomLoadingScreen from './CustomLoadingScreen';

function CheckUserDepartment({ children }) {
  const [open, setOpen] = useState(true);
  const { department, loading, selectedDepartment } = useSelector((state) => state.userDepartment);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!department) return;
    if (department?.length === 1) {
      const getdepartment = department?.[0];
      dispatch(updateSelectedDepartment(getdepartment));
      setOpen(false);
    }
  }, [department, dispatch]);

  const departmentHandler = (obj) => {
    dispatch(updateSelectedDepartment(obj));
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        {loading ? (
          <CustomLoadingScreen height="100vh" />
        ) : (
          <>
            {!Object.keys(selectedDepartment)?.length >= 1 && (
              <>
                {department?.length > 1 && (
                  <>
                    <CustomOutletModal
                      open={open}
                      setOpen={setOpen}
                      title={'Please Select Your Preferred Department'}
                      height={'70%'}
                      width={'auto'}
                    >
                      <p>Please Select the Department You want to Proceed</p>

                      <Box style={{ paddingTop: '3rem' }}>
                        <Grid container spacing={3}>
                          {department?.map((obj) => (
                            <Grid item xs={6} key={obj.id}>
                              <Card
                                style={{
                                  cursor: 'pointer',
                                  background: `${obj.colorCode}`,
                                  minHeight: '10rem',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                                onClick={() => departmentHandler(obj)}
                              >
                                <CardContent>{obj.name}</CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    </CustomOutletModal>
                  </>
                )}
              </>
            )}
            {children}
          </>
        )}
      </div>
    </>
  );
}

export default CheckUserDepartment;
