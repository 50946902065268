import { useState } from 'react';
import PropTypes from 'prop-types';

import { Stack, TextField, Button, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
// components
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'redux/store';
import { clearAllRoaster, downloadRoaster, getRoaster } from 'redux/slices/roasterSlice';
import { convertDatetoString } from 'utils/customDateAndTimeFormat';
import moment from 'moment';
import Iconify from 'components/Iconify';

import RoasterExtraFilterFeatures from './RoasterExtraFilterFeatures';
import RoasterZones from './RoasterZones';

// ----------------------------------------------------------------------

const INPUT_WIDTH = 120;

RoasterFilterForm.propTypes = {
  filterName: PropTypes.string,
  filterService: PropTypes.string,
  filterEndDate: PropTypes.instanceOf(Date),
  filterStartDate: PropTypes.instanceOf(Date),
  onFilterName: PropTypes.func,
  onFilterEndDate: PropTypes.func,
  onFilterService: PropTypes.func,
  onFilterStartDate: PropTypes.func,
  optionsService: PropTypes.arrayOf(PropTypes.string),
};

export default function RoasterFilterForm() {
  const { selectedDepartment } = useSelector((state) => state.userDepartment);
  const dispatch = useDispatch();
  const [choosenDate, setChoosenDate] = useState(new Date());

  const { setValue } = useFormContext();

  const onChangeDateHandler = (newValue) => {
    setChoosenDate(newValue);
    dispatch(getRoaster({ anyDateOfWeek: convertDatetoString(newValue), departmentId: selectedDepartment.id }));
  };

  const LastWeekRoasterFilter = () => {
    // const currentDate = new Date();
    const lastWeekDate = moment(choosenDate).subtract(7, 'days');
    setChoosenDate(lastWeekDate);
    setValue('anyDateofWeek', lastWeekDate.toDate());

    dispatch(getRoaster({ anyDateOfWeek: convertDatetoString(lastWeekDate), departmentId: selectedDepartment.id }));
  };

  // const FortNightRoasterFilter = () => {
  //   // const currentDate = new Date();
  //   const fortNightDate = moment(choosenDate).subtract(14, 'days');
  //   setChoosenDate(fortNightDate);
  //   setValue('anyDateofWeek', fortNightDate.toDate());
  //   dispatch(clearAllRoaster());
  //   dispatch(getRoaster({ anyDateOfWeek: convertDatetoString(fortNightDate), departmentId: selectedDepartment.id }));
  // };
  const NextWeekRoasterFilter = () => {
    // const currentDate = choosenDate;
    const nextWeekDate = moment(choosenDate).add(7, 'days');
    setChoosenDate(nextWeekDate);
    setValue('anyDateofWeek', nextWeekDate.toDate());
    dispatch(clearAllRoaster());
    dispatch(getRoaster({ anyDateOfWeek: convertDatetoString(nextWeekDate), departmentId: selectedDepartment.id }));
  };

  const ResetRoasterFilter = () => {
    const currentDate = new Date();
    setChoosenDate(currentDate);
    setValue('anyDateofWeek', new Date());

    dispatch(getRoaster({ anyDateOfWeek: convertDatetoString(currentDate), departmentId: selectedDepartment.id }));
  };

  const getMaxDate = moment(new Date()).add(3, 'months').toDate();
  return (
    <Stack
      spacing={2}
      alignItems="center"
      justifyContent={'space-between'}
      direction={{ xs: 'column', md: 'row' }}
      sx={{ py: 2.5, px: 3 }}
      className="roasterFilterForm"
      style={{ paddingBottom: '3px' }}
    >
      <Controller
        name="anyDateofWeek"
        defaultValue={new Date()}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            defaultValue={new Date()}
            {...field}
            inputFormat="dd/MMM/yyyy"
            onChange={(newValue) => {
              field.onChange(newValue);
              onChangeDateHandler(newValue);
            }}
            maxDate={getMaxDate}
            label="Select Date"
            renderInput={(params) => (
              <TextField
                {...params}
                // fullWidth
                style={{ width: 'min(10rem, 50%)' }}
                error={!!error}
                helperText={error?.message}
                sx={{ width: 'min(20rem, 70%)' }}
                className="roasterFilterDatePicker"
              />
            )}
            sx={{
              maxWidth: { md: INPUT_WIDTH },
            }}
          />
        )}
      />
      <RoasterZones />
      <div style={{ display: 'flex' }}>
        {/* Date Picker filter */}
        <RoasterExtraFilterFeatures choosenDate={choosenDate} />
        {/* <Button variant="contained" color="secondary" onClick={FortNightRoasterFilter} className="roasterFilterButton">
          Fortnight
        </Button> */}
        <Button variant="contained" color="secondary" onClick={LastWeekRoasterFilter} className="roasterFilterButton">
          Last Week
        </Button>
        {/* </div> */}
        {/* <div style={{ textAlign: 'center' }}> */}
        <Button
          color="error"
          onClick={ResetRoasterFilter}
          style={{ marginRight: '5px' }}
          className="roasterFilterButton"
        >
          Today
        </Button>
        <Button color="primary" onClick={NextWeekRoasterFilter} className="roasterFilterButton">
          Next Week
        </Button>
      </div>
    </Stack>
  );
}
