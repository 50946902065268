import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React from 'react';
import { createIngredients, updateIngredients } from 'redux/slices/ingredientSlice';
import { dispatch } from 'redux/store';
import IngredientsAddForm from 'sections/@dashboard/ingredients/IngredientsAddForm';

function IngredientEdit({ currentData, cancelIngredientForm = false }) {
  const departmentId = useGetDepartmentId();

  const UpdateIngredientsHandler = (data) => {
    const formData = new FormData();
    if (data?.file) {
      formData.append('file', data?.file);
    }
    const finalData = {
      id: data?.id || null,
      type: data?.type,
      name: data?.name,
      shelfLife: data?.shelfLife,
      storageInstruction: data?.storageInstruction,
      isAllergic: data?.isAllergic,
      departmentId,
    };
    formData.append('ingredient', new Blob([JSON.stringify({ ...finalData })], { type: 'application/json' }));
    dispatch(updateIngredients({ finalData: formData, departmentId }));
  };

  return (
    // <CustomDrawer title="Edit Ingredients" open={open} setOpen={setOpen}>
    <>
      <IngredientsAddForm
        onSubmit={UpdateIngredientsHandler}
        isEdit
        currentData={currentData}
        cancelIngredientForm={cancelIngredientForm}
      />
    </>
    // </CustomDrawer>
  );
}

export default IngredientEdit;
