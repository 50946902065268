import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiURL from 'api';
import { errorToastTime, successToastTime } from 'config';
import { toast } from 'react-toastify';

export const getDealOffer = createAsyncThunk('dealOffer/getDealOffer', async (props) => {
  try {
    const response = await apiURL.get(`/dealOffer/deals/${props?.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getSizesInCategories = createAsyncThunk('dealOffer/getSizesInCategories', async (props) => {
  try {
    const response = await apiURL.get(`/dealOffer/sizesInCategory/${props?.departmentId}/${props?.categoryId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createUpdateDealOffer = createAsyncThunk('dealOffer/createDealOffer', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post('/dealOffer/deal', props?.formData);
    if (response.status === 200) {
      toast.success(response?.data?.message, { autoClose: successToastTime });
      thunkAPI.dispatch(getDealOffer({ departmentId: props?.departmentId }));
      props?.isSuccess(true);
      // eslint-disable-next-line no-unused-expressions
      // props?.isUpdate
      //   ? thunkAPI.dispatch(getIndividualDealDetails({ departmentId: props?.departmentId, id: props?.id }))
      //   : thunkAPI.dispatch(getDealOffer({ departmentId: props?.departmentId }));
      // props?.handleClose();
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getIndividualDealDetails = createAsyncThunk('dealOffer/getIndividualDealDetails', async (props) => {
  try {
    const response = await apiURL.get(`/dealOffer/dealCategoriesWithMenus/${props?.departmentId}/${props?.id}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getMenusOfSelectedSize = createAsyncThunk('dealOffer/getMenusOfSelectedSize', async (props) => {
  try {
    const response = await apiURL.get(
      `/dealOffer/dealMenusForSize/${props?.departmentId}/${props?.categoryId}/${props?.sizeId}`
    );
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteDealOffer = createAsyncThunk('dealOffer/deleteDealOffer', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/dealOffer/deal/${props?.departmentId}/${props?.id}`);
    if (response.status === 200) {
      toast.success(response?.data?.message, { autoClose: successToastTime });
      thunkAPI.dispatch(getDealOffer({ departmentId: props?.departmentId }));
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteDealCategory = createAsyncThunk('dealOffer/deleteDealCategory', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(
      `/dealOffer/dealCategory/${props?.departmentId}/${props?.dealId}/${props?.dealCatId}`
    );
    if (response.status === 200) {
      props?.isSuccess(true);
      toast.success(response?.data?.message, { autoClose: successToastTime });
      // thunkAPI.dispatch(getIndividualDealDetails({ departmentId: props?.departmentId, id: props?.dealId }));
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const menusOfCategories = createAsyncThunk('dealOffer/menusOfCategories', async (props) => {
  try {
    const response = await apiURL.get(`/dealOffer/dealCategoryMenus/${props?.departmentId}/${props?.id}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const reorderDeal = createAsyncThunk('dealOffer/reorderDeal', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put(`/dealOffer/orderDeals/${props?.departmentId}`, props?.finalData);
    if (response.status === 200) {
      toast.success(response?.data?.message, { autoClose: successToastTime });
      thunkAPI.dispatch(getDealOffer({ departmentId: props?.departmentId }));
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

const dealSlice = createSlice({
  name: 'deal',
  initialState: {
    loading: false,
    postLoading: false,

    dealOfferList: [],
    sizesListInCategory: [],

    singleDeal: [],
    singleDealLoading: false,

    menusOfSelectedSize: [],
    menusOfSelectedSizeLoading: false,

    selectedDeal: {},
  },
  reducers: {
    setSelectedDeal(state, action) {
      state.selectedDeal = action.payload;
    },
  },

  extraReducers: {
    [getDealOffer.pending]: (state) => {
      state.loading = true;
      state.dealOfferList = [];
    },
    [getDealOffer.fulfilled]: (state, action) => {
      state.loading = false;
      state.dealOfferList = action.payload;
    },
    [getDealOffer.rejected]: (state) => {
      state.loading = false;
      state.dealOfferList = [];
    },
    // ---------------------------------
    [getSizesInCategories.pending]: (state) => {
      state.sizeListLoading = true;
      state.sizesListInCategory = [];
    },
    [getSizesInCategories.fulfilled]: (state, action) => {
      state.sizeListLoading = false;
      state.sizesListInCategory = action.payload;
    },
    [getSizesInCategories.rejected]: (state) => {
      state.sizeListLoading = false;
      state.sizesListInCategory = [];
    },

    // ---------------------------------
    [createUpdateDealOffer.pending]: (state) => {
      state.postLoading = true;
    },
    [createUpdateDealOffer.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [createUpdateDealOffer.rejected]: (state) => {
      state.postLoading = false;
    },

    // ---------------------------------
    [getIndividualDealDetails.pending]: (state) => {
      state.singleDealLoading = true;
      state.singleDeal = [];
    },
    [getIndividualDealDetails.fulfilled]: (state, action) => {
      state.singleDealLoading = false;
      state.singleDeal = action.payload;
    },
    [getIndividualDealDetails.rejected]: (state) => {
      state.singleDealLoading = false;
      state.singleDeal = [];
    },

    // ---------------------------------
    [getMenusOfSelectedSize.pending]: (state) => {
      state.menusOfSelectedSizeLoading = true;
      // state.menusOfSelectedSize = [];
    },
    [getMenusOfSelectedSize.fulfilled]: (state, action) => {
      state.menusOfSelectedSizeLoading = false;
      state.menusOfSelectedSize = action.payload;
    },
    [getMenusOfSelectedSize.rejected]: (state) => {
      state.menusOfSelectedSizeLoading = false;
      // state.menusOfSelectedSize = [];
    },
  },
});

export const { setSelectedDeal } = dealSlice.actions;

export default dealSlice.reducer;
