import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Card, CardHeader, Stack, Typography } from '@mui/material';
import { BaseOptionChart } from 'components/chart';
import { fNumber } from 'utils/formatNumber';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

ReportPieChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

export default function ReportPieChart({ title, chartColors, chartData, size = 250, ...other }) {
  const theme = useTheme();
  const chartLabels = chartData?.map((i) => ` ${i.label.split(' ')[0]}`);

  const chartSeries = chartData?.map((i) => i.value);

  const chartOptions = merge(BaseOptionChart(), {
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper], width: 1 },
    legend: { floating: true, horizontalAlign: 'center', fontSize: '13px', position: 'bottom' },
    dataLabels: { enabled: true, dropShadow: { enabled: true } },
    tooltip: {
      fillSeriesColor: true,
      enabled: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {},
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  const CHART_HEIGHT = size + 25;
  const LEGEND_HEIGHT = 52;

  const ChartWrapperStyle = styled('div')(({ theme }) => ({
    height: CHART_HEIGHT,
    marginTop: theme.spacing(1),
    '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
    '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
      overflow: 'visible',
    },
    '& .apexcharts-legend': {
      height: LEGEND_HEIGHT,
      position: 'relative !important',
      // borderTop: `solid 1px ${theme.palette.divider}`,
      top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT - 20}px) !important`,
    },
  }));

  return (
    <Stack flexDirection={'column'}>
      {/* <Card {...other} sx={{ padding: '1rem' }}> */}
      <Box
        style={{
          textAlign: 'center',
          fontSize: '1rem',
          marginTop: '.3rem',
          fontWeight: 'bold',
          marginBottom: '-8px',
        }}
      >
        {title}
      </Box>

      <ChartWrapperStyle dir="ltr">
        <ReactApexChart type="pie" series={chartSeries} options={chartOptions} height={size} />
      </ChartWrapperStyle>
      <Stack alignItems="center">
        {chartData?.map((el, index) => (
          <Stack key={index}>
            <Typography component="p" fontSize="0.8rem">
              {el.label} :{' '}
              {el.value > 0 ? (
                <Typography component="span" fontSize="0.8rem">
                  {el?.value?.toFixed(2)}
                </Typography>
              ) : (
                <Typography component="span" color="red" fontSize="0.8rem">
                  {el?.value?.toFixed(2)}
                </Typography>
              )}
            </Typography>
          </Stack>
        ))}
      </Stack>
      {/* </Card> */}
    </Stack>
  );
}
