import { Box, Stack, TableCell, Tooltip } from '@mui/material';
import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Icon } from '@iconify/react';
import RoasterAddForm from 'sections/@dashboard/roaster/RoasterAddForm';

import { useDispatch, useSelector } from 'redux/store';
import { roasterModalControlAction } from 'redux/slices/roasterSlice';
import { findSelectedTimeRoaster } from 'utils/roasterFeatureUtils';
import moment from 'moment';

import Iconify from 'components/Iconify';
import { useDrop } from 'react-dnd';
import IndividualRoasterCard from './IndividualRoasterCard';

IndividualRoasterTableCell.propTypes = {
  roasterKey: PropTypes.string,
  userInformation: PropTypes.object,
  mainIndex: PropTypes.number,
  nextdayHandler: PropTypes.bool,
  setNextDayHandler: PropTypes.func,
};

function IndividualRoasterTableCell({
  roasterKey,
  mainIndex,
  userInformation,
  nextdayHandler,
  setNextDayHandler,
  setStartTime,
  startTime,
}) {
  const { control } = useFormContext();
  const { selectedRoaster, modalControl, isPreviousDate } = useSelector((state) => state.roaster);
  const dispatch = useDispatch();

  const { fields, remove, append, update } = useFieldArray({
    name: `users[${mainIndex}].userShiftsInDates[${roasterKey}]`,
    keyName: 'roasterFieldID',
    control,
  });

  const notesInDates = useWatch({
    control,
    name: `users[${mainIndex}].notesInDates[${roasterKey}]`,
  });

  const checkLeaveDate = () => {
    const userLeave = userInformation?.leaveApprovedDates;
    if (!userLeave) return false;
    return userLeave?.includes(roasterKey);
  };

  const checkIsNextDayLeave = () => {
    const userLeave = userInformation?.leaveApprovedDates;
    if (!userLeave) return false;
    return userLeave?.includes(moment(roasterKey).add(1, 'days').format('YYYY-MM-DD'));
  };

  const [{ isOver }, dropRef] = useDrop({
    accept: 'individualRoasterCard',
    drop: (item) => {
      const getCheckLeaveDate = checkLeaveDate();
      if (getCheckLeaveDate) return;
      if (userInformation?.roleIds.includes(item?.roleId)) {
        append({ ...item?.data, roleId: item?.roleId });
      } else {
        // append({ ...item?.data });
        openUpdateRoasterModal(item?.cardFieldIndex, item?.data);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const openCreateRoasterModal = (data) => {
    dispatch(roasterModalControlAction({ mainIndex, roasterKey }));
  };

  const openUpdateRoasterModal = (cardFieldIndexID, cardData) => {
    dispatch(
      roasterModalControlAction({
        mainIndex,
        roasterKey,
        isUpdate: true,
        updateCardIndexDetails: { cardIndex: cardFieldIndexID, data: cardData },
      })
    );
  };

  // const findSelectedTime = findSelectedTimeRoaster(fields);
  const findSelectedTime = [];

  const NextDayShiftHandler = (data) => {
    setNextDayHandler(true);
    setStartTime(data);
  };

  // this useEffect is run when shift needs to be split
  useEffect(() => {
    if (!nextdayHandler || !startTime) return;
    if (startTime?.date === roasterKey) {
      append({
        ...startTime,
      });
    }
    setStartTime(null);
    setNextDayHandler(false);
  }, [append, nextdayHandler, roasterKey, setNextDayHandler, setStartTime, startTime]);

  return (
    <>
      {!fields.length ? (
        <>
          <TableCell
            className={!isPreviousDate && !checkLeaveDate() ? 'roasternullTableCell' : ''}
            onClick={!isPreviousDate && !checkLeaveDate() ? openCreateRoasterModal : () => {}}
            ref={dropRef}
            style={{ width: '120px' }}
          >
            {!isPreviousDate && !checkLeaveDate() && (
              <Box style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {isOver && <Box>Drop Here</Box>}
                <Icon
                  icon="material-symbols:add-card-outline-sharp"
                  style={{ position: 'absolute', left: '50%', top: '50%', transform: `translate(-50%, -50%)` }}
                />
                <span style={{ fontSize: '.7rem' }}>
                  {/* {notesInDates} */}
                  {notesInDates?.length > 30 ? (
                    <Tooltip title={notesInDates}>{notesInDates?.slice(0, 30)}...</Tooltip>
                  ) : (
                    notesInDates
                  )}
                </span>
                {/* {notesInDates ? (
                  <span>
                    {notesInDates?.length > 10 ? (
                      <Tooltip title={notesInDates}>{notesInDates?.slice(0, 10)}...</Tooltip>
                    ) : (
                      notesInDates
                    )}
                  </span>
                ) : (
                  <Icon
                    icon="material-symbols:add-card-outline-sharp"
                    style={{ position: 'absolute', left: '50%', top: '50%', transform: `translate(-50%, -50%)` }}
                  />
                )} */}
              </Box>
            )}
            {checkLeaveDate() && (
              <p style={{ color: 'red', fontWeight: '600' }}>
                LEAVE <br />{' '}
                <span style={{ color: 'gray', fontSize: '.7rem' }}>
                  {/* {notesInDates} */}
                  {notesInDates?.length > 15 ? (
                    <Tooltip title={notesInDates}>{notesInDates?.slice(0, 15)}...</Tooltip>
                  ) : (
                    notesInDates
                  )}
                </span>
              </p>
            )}
          </TableCell>
        </>
      ) : (
        <>
          <TableCell style={{ padding: '3px' }} ref={dropRef}>
            {fields?.map((individualRoaster, index) => (
              <>
                <IndividualRoasterCard
                  key={individualRoaster?.roasterFieldID}
                  data={individualRoaster}
                  mainIndex={mainIndex}
                  roasterKey={roasterKey}
                  cardFieldIndex={index}
                  remove={remove}
                  onUpdate={openUpdateRoasterModal}
                  userInformation={userInformation}
                  openCreateRoasterModal={openCreateRoasterModal}
                />
                {isOver && (
                  <Stack px="0.3rem" py="0.3rem">
                    Drop Here
                  </Stack>
                )}
              </>
            ))}
          </TableCell>
        </>
      )}
      {!isPreviousDate &&
        !checkLeaveDate() &&
        modalControl.open &&
        selectedRoaster.mainIndex === mainIndex &&
        selectedRoaster.roasterKey === roasterKey && (
          <RoasterAddForm
            append={append}
            NextDayShiftHandler={NextDayShiftHandler}
            userInformation={userInformation}
            roasterDate={roasterKey}
            update={update}
            disableTime={findSelectedTime}
            nextDayLeave={checkIsNextDayLeave()}
          />
        )}
    </>
  );
}

export default IndividualRoasterTableCell;
