import { yupResolver } from '@hookform/resolvers/yup';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { FormProvider, RHFTextField } from 'components/hook-form';
import { Button, Stack } from '@mui/material';
import { createPaymentType } from 'redux/slices/salesSlice';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';

const EftSchema = Yup.object({
  title: Yup.string().required(''),
});

function PayTypeAddForm({ open, setOpen, edit, updateData }) {
  const { postLoading } = useSelector((state) => state.sales);

  const closeModal = () => {
    if (edit) {
      setOpen({ status: false, data: null });
    } else {
      setOpen(false);
    }
  };
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const defaultValues = {
    title: '',
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(EftSchema),
  });

  const { handleSubmit, reset } = methods;

  const createHandler = (data) => {
    const finalData = {
      ...data,
      departmentId,
    };
    dispatch(createPaymentType({ finalData, closeModal, edit }));
  };

  useEffect(() => {
    if (!edit) return;
    reset({ title: updateData?.title, id: updateData?.id });
  }, [edit, reset, updateData]);

  return (
    <CustomOutletModal
      width="30rem"
      open={open}
      zIndex="1600"
      title={edit ? 'Update Payment Type' : 'Add new Payment Type'}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(createHandler)}>
        <Stack spacing={2}>
          <RHFTextField name="title" label="Title*" />
        </Stack>
        <Stack alignItems={'flex-end'} mt={2}>
          <div>
            <Button type="submit" variant="contained" style={{ marginRight: '5px' }}>
              {/* eslint-disable-next-line no-nested-ternary */}
              {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : edit ? 'Update' : 'Create'}
            </Button>
            <Button variant="text" style={{ color: 'red' }} onClick={closeModal}>
              Cancel
            </Button>
          </div>
        </Stack>
      </FormProvider>
    </CustomOutletModal>
  );
}

export default PayTypeAddForm;
