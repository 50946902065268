import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';

import apiURL from 'api';
import { PATH_DASHBOARD } from 'routes/paths';
import { errorToastTime, successToastTime } from 'config';

export const createUserAcc = createAsyncThunk('user/createUser', async ({ formData }) => {
  try {
    const response = await apiURL.post(`/user`, {
      formData,
    });
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getAllUsers = createAsyncThunk(
  'user/getAllUsers',
  async ({ departmentId, pageNo, searchUser, statusList }) => {
    try {
      const response = await apiURL.post(`/user/getUsers?pageNo=${pageNo - 1}&pageSize=15`, {
        departmentId,
        searchText: searchUser,
        statusList,
      });
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const getUserRoles = createAsyncThunk(
  'user/getUserRoles',
  async ({ userId, departmentId, isSuccess = () => {} }) => {
    const response = await apiURL.get(`user/getUserRoles/${userId}/${departmentId}`);

    if (response.status === 200) {
      isSuccess();
    }
    return response.data;
  }
);

// for getting all users wihtout pageno and page sizes
export const getAllUserss = createAsyncThunk('user/getAllUserss', async (props) => {
  try {
    const response = await apiURL.post(`/user/getUsers`, props);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const userCreate = createAsyncThunk('user/userCreate', async ({ formData, isSuccess }) => {
  try {
    const response = await apiURL.post(`user/createUser`, formData);
    toast.success('User created successfully', { autoClose: successToastTime });
    isSuccess(true);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message);
    throw error;
  }
});

// this is created for custom user create by manager because of requirement change.. if testing is good than user/userCreate can be removed
export const userInfoRequestCreate = createAsyncThunk(
  'user/userInfoRequest',
  async ({ formData, isSuccess }, thunkAPI) => {
    try {
      const response = await apiURL.post(`user/userInfoRequest`, formData);

      if (response.status === 200) {
        toast.success('User created successfully', { autoClose: successToastTime });
        isSuccess(true);
      }

      if (response.status === 208) {
        toast.error(response?.data?.message || 'User with Phone Number Already Exist');
        thunkAPI.rejectWithValue();
      }

      if (response.status === 207) {
        toast.error(response?.data?.message || 'User with Email Already Exist');
        thunkAPI.rejectWithValue();
      }

      return response.data;
    } catch (error) {
      toast.error(error.response.data.message);
      throw error;
    }
  }
);

export const userUpdate = createAsyncThunk('user/userUpdate', async ({ formData, isSuccess }, thunkAPI) => {
  try {
    const response = await apiURL.put(`/user/updateApprovedUser`, formData);
    if (response.status === 200) {
      toast.success('User update successfully', { autoClose: successToastTime });
      isSuccess(true);
    }

    if (response.status === 208) {
      toast.error(response?.data?.message || 'User with Phone Number Already Exist', { autoClose: errorToastTime });
      thunkAPI.rejectWithValue();
    }

    if (response.status === 207) {
      toast.error(response?.data?.message || 'User with Email Already Exist', { autoClose: errorToastTime });
      thunkAPI.rejectWithValue();
    }

    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const inActive = createAsyncThunk('user/inActive', async (data, thunkAPI) => {
  try {
    const response = await apiURL.put(`/user/inactive/${data.id}`);
    toast.success('User inactivated successfully', { autoClose: successToastTime });
    thunkAPI.dispatch(
      getAllUsers({
        departmentId: data.departmentId,
        pageNo: data.pageNo,
        searchUser: data.searchUser,
        statusList: [data.tabValue],
      })
    );
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const active = createAsyncThunk('user/active', async (data, thunkAPI) => {
  try {
    const response = await apiURL.put(`/user/reactivate/${data.id}`);
    toast.success('User activated successfully', { autoClose: successToastTime });
    thunkAPI.dispatch(
      getAllUsers({
        departmentId: data.departmentId,
        pageNo: data.pageNo,
        searchUser: data.searchUser,
        statusList: [data.tabValue],
      })
    );
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const userDetail = createAsyncThunk('user/userDetail', async ({ userId }) => {
  try {
    const response = await apiURL.get(`user/${userId}`);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getUserStoreDT = createAsyncThunk('user/storeDT', async () => {
  try {
    const response = await apiURL.get(`user/storeDT`);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getFeatureBasedUser = createAsyncThunk('user/roleBasedUser', async ({ featureId, departmentId }) => {
  try {
    const response = await apiURL.get(`/user/getUsersByFeatureId/${departmentId}/${featureId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getSingleUser = createAsyncThunk('user/singleUser', async ({ userId }) => {
  const response = await apiURL.get(`user/${userId}`);
  return response.data;
});

export const getPendingRequest = createAsyncThunk('user/pendingRequest', async (data) => {
  let response;
  if (data?.tabValue === 'pending') {
    response = await apiURL.get(`user/userInfoRequest/pending?searchTxt=${data?.searchUser}`);
  } else if (data?.tabValue === 'approved') {
    response = await apiURL.get(`user/userInfoRequest/approved?searchTxt=${data?.searchUser}`);
  } else {
    response = await apiURL.get(`user/userInfoRequest/rejected?searchTxt=${data?.searchUser}`);
  }
  return response?.data;
});

export const approvePendingRequest = createAsyncThunk('user/approvePending', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`user/approveUser`, props.data);
    if (response.status === 200) {
      toast.success('User request approved successfully', { autoClose: successToastTime });
      thunkAPI.dispatch(getPendingRequest({ tabValue: 'pending' }));
      props.navigate(PATH_DASHBOARD.user.requestList);
    }

    if (response.status === 208) {
      props.manageAlreadyExist('phone');
      return thunkAPI.rejectWithValue();
    }

    if (response.status === 207) {
      toast.error('User with this Email Already Exist. Please Provide Another Email', { autoClose: errorToastTime });
      return thunkAPI.rejectWithValue();
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const rejectPendingRequest = createAsyncThunk('user/rejectPending', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`user/reject`, props);
    if (response.status === 200) {
      toast.success('User request rejected successfully', { autoClose: successToastTime });
      thunkAPI.dispatch(getPendingRequest({ tabValue: 'pending' }));
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const userInfoRequestDetails = createAsyncThunk('user/userInfoRequestDetails', async (props) => {
  const response = await apiURL.get(`user/userInfoRequest/${props.id}`);
  return response?.data;
});

export const userSendLink = createAsyncThunk('user/userSendLink', async (props) => {
  try {
    const response = await apiURL.post(`user/sendLink`, props.finalData);
    if (response.status === 200) {
      toast.success('Link sent Successfully', { autoClose: successToastTime });
      props.setOpen(false);
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getAgreementDocument = createAsyncThunk('store/getAgreement', async () => {
  const response = await apiURL.get('/storeConf/agreementDocs');
  return response.data;
});

export const uploadAgreementDocument = createAsyncThunk('store/aggrementDocs', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post('/storeConf/agreementDocs', props?.formData);
    if (response.status === 200) {
      toast.success(`${props?.type} uploaded successfully`, { autoClose: successToastTime });
      thunkAPI.dispatch(getAgreementDocument());
    }
    return response.data;
  } catch (error) {
    toast.error('upload failed', { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteAgreementDocument = createAsyncThunk('store/deleteAgreement', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/storeConf/agreementDocs/delete/${props.type}`);
    if (response.status === 200) {
      toast.success(`${props.type} deleted successfully`, { autoClose: successToastTime });
      thunkAPI.dispatch(getAgreementDocument());
    }
    return response.data;
  } catch (error) {
    toast.error('delete failed', { autoClose: errorToastTime });
    throw error;
  }
});

export const sendAgreementDocument = createAsyncThunk('store/sendAgreement', async (props) => {
  try {
    const response = await apiURL.post('/user/sendLink/docs', props?.finalData);
    if (response.status === 200) {
      props?.isSuccess(true);
      toast.success(`${props?.finalData?.type} send successfully`, { autoClose: successToastTime });
    }
    return response.data;
  } catch (error) {
    toast.error('send failed', { autoClose: errorToastTime });
    throw error;
  }
});

export const getSignedAgreementOfUser = createAsyncThunk('store/getSignedAgreementOfUser', async (props) => {
  const response = await apiURL.get(`user/staffAgreements/${props?.userId}`);
  return response.data;
});

export const ApproveSignedDocument = createAsyncThunk('store/approveSignedDocument', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`user/approve/docs`, props?.finalData);
    if (response.status === 200) {
      toast.success(`${props?.finalData?.type}  approved`, { autoClose: successToastTime });
      thunkAPI.dispatch(getSignedAgreementOfUser({ userId: props?.finalData?.userId }));
    }
    return response.data;
  } catch (error) {
    toast.error('failed to approve', { autoClose: errorToastTime });
    throw error;
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState: {
    createUser: [],
    loading: false,
    postLoading: false,
    rolesLoading: false,
    detailsLoading: false,
    userInfoRequestDetail: {},
    users: null,
    inactiveUser: {},
    reactive: {},
    singleUser: {},
    error: '',
    timeDT: '',
    allUsers: [],
    userRoles: [],
    featureBasedUser: [],
    singleUserLoading: false,
    requestListLoading: false,
    requestList: [],
    agreementLoading: false,
    agreementList: {},
    signedDocumentList: {},
    signedDocumentLoading: false,
  },
  reducers: {
    clearSingleUser(state) {
      state.singleUser = {};
    },
    clearUserRoles(state) {
      state.userRoles = [];
    },
    clearSignedDocumentList(state) {
      state.signedDocumentList = {};
    },
  },

  extraReducers: {
    [userCreate.pending]: (state) => {
      state.postLoading = true;
    },
    [userCreate.fulfilled]: (state, action) => {
      state.postLoading = false;
      state.user = [action.payload];
    },
    [userCreate.rejected]: (state, action) => {
      state.postLoading = false;
      state.error = action.payload.message;
    },

    [userInfoRequestCreate.pending]: (state) => {
      state.postLoading = true;
    },
    [userInfoRequestCreate.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [userInfoRequestCreate.rejected]: (state) => {
      state.postLoading = false;
    },

    [getUserStoreDT.pending]: (state) => {
      state.loading = true;
    },
    [getUserStoreDT.fulfilled]: (state, action) => {
      state.loading = false;
      state.timeDT = action.payload;
      state.userStoreDT = action.payload;
    },
    [getUserStoreDT.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getAllUsers.pending]: (state) => {
      state.loading = true;
    },
    [getAllUsers.fulfilled]: (state, action) => {
      state.loading = false;
      state.users = action.payload;
    },
    [getAllUsers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getAllUserss.pending]: (state) => {
      state.loading = true;
    },
    [getAllUserss.fulfilled]: (state, action) => {
      state.loading = false;
      state.allUsers = action.payload;
    },
    [getAllUserss.rejected]: (state) => {
      state.loading = false;
    },

    [inActive.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [inActive.fulfilled]: (state, action) => {
      state.loading = false;
      state.inactiveUser = action.payload;
      state.success = true;
    },
    [inActive.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [active.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [active.fulfilled]: (state, action) => {
      state.loading = false;
      state.reactive = action.payload;
      state.success = true;
    },
    [active.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [userDetail.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [userDetail.fulfilled]: (state, action) => {
      state.loading = false;
      state.singleUser = action.payload;
      state.success = true;
    },
    [userDetail.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getSingleUser.pending]: (state) => {
      state.singleUserLoading = true;
      state.singleUser = {};
      // state.success = false;
    },
    [getSingleUser.fulfilled]: (state, action) => {
      state.singleUserLoading = false;
      state.singleUser = action.payload;
      // state.success = true;
    },
    [getSingleUser.rejected]: (state) => {
      state.singleUserLoading = false;
      // state.error = action.payload.message;
    },

    [userUpdate.pending]: (state) => {
      state.postLoading = true;
      state.success = false;
    },
    [userUpdate.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [userUpdate.rejected]: (state) => {
      state.postLoading = false;
    },

    [getUserRoles.pending]: (state) => {
      // state.loading = true;
      state.rolesLoading = true;
    },
    [getUserRoles.fulfilled]: (state, action) => {
      // state.loading = false;
      state.rolesLoading = false;
      state.userRoles = action.payload;
    },
    [getUserRoles.rejected]: (state) => {
      // state.loading = false;
      state.rolesLoading = false;
    },

    [getFeatureBasedUser.pending]: (state) => {
      state.loading = true;
    },
    [getFeatureBasedUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.featureBasedUser = action.payload;
    },
    [getFeatureBasedUser.rejected]: (state) => {
      state.loading = false;
    },
    [getPendingRequest.pending]: (state) => {
      state.requestListLoading = true;
      state.requestList = [];
    },
    [getPendingRequest.fulfilled]: (state, action) => {
      state.requestListLoading = false;
      state.requestList = action.payload;
    },
    [getPendingRequest.rejected]: (state) => {
      state.requestListLoading = false;
    },

    [userSendLink.pending]: (state) => {
      state.postLoading = true;
    },
    [userSendLink.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [userSendLink.rejected]: (state) => {
      state.postLoading = false;
    },
    [approvePendingRequest.pending]: (state) => {
      state.loading = true;
    },
    [approvePendingRequest.fulfilled]: (state) => {
      state.loading = false;
    },
    [approvePendingRequest.rejected]: (state) => {
      state.loading = false;
    },
    [rejectPendingRequest.pending]: (state) => {
      state.loading = true;
    },
    [rejectPendingRequest.fulfilled]: (state) => {
      state.loading = false;
    },
    [rejectPendingRequest.rejected]: (state) => {
      state.loading = false;
    },

    [userInfoRequestDetails.pending]: (state) => {
      state.detailsLoading = true;
    },
    [userInfoRequestDetails.fulfilled]: (state, action) => {
      state.detailsLoading = false;
      state.userInfoRequestDetail = action.payload;
    },
    [userInfoRequestDetails.rejected]: (state) => {
      state.detailsLoading = false;
    },

    [uploadAgreementDocument.pending]: (state) => {
      state.loading = true;
    },
    [uploadAgreementDocument.fulfilled]: (state) => {
      state.loading = false;
      // state.userInfoRequestDetail = action.payload;
    },
    [uploadAgreementDocument.rejected]: (state) => {
      state.loading = false;
    },

    [getAgreementDocument.pending]: (state) => {
      state.agreementLoading = true;
    },
    [getAgreementDocument.fulfilled]: (state, action) => {
      state.agreementLoading = false;
      state.agreementList = action.payload;
      // state.userInfoRequestDetail = action.payload;
    },
    [getAgreementDocument.rejected]: (state) => {
      state.agreementLoading = false;
    },

    [getSignedAgreementOfUser.pending]: (state) => {
      state.signedDocumentLoading = true;
      state.signedDocumentList = {};
    },
    [getSignedAgreementOfUser.fulfilled]: (state, action) => {
      state.signedDocumentLoading = false;
      state.signedDocumentList = action.payload;
      // state.userInfoRequestDetail = action.payload;
    },
    [getSignedAgreementOfUser.rejected]: (state) => {
      state.signedDocumentLoading = false;
    },
  },
});
export const { clearSingleUser, clearUserRoles, clearSignedDocumentList } = userSlice.actions;
export default userSlice.reducer;
