import React from 'react';
import { Divider } from '@mui/material';
import { FormProvider } from 'components/hook-form';
import { useForm } from 'react-hook-form';
import RoasterFilterForm from './RoasterFilterForm';

// const TABS = [
//   { value: 'APPROVED', label: 'approved', color: 'info' },
//   { value: 'PENDING', label: 'Pending', color: 'success' },
// ];

function RoasterFilter() {
  const methods = useForm();
  const filterSubmitHandler = (data) => {};
  return (
    <>
      <Divider />

      <FormProvider methods={methods} onSubmit={methods.handleSubmit(filterSubmitHandler)}>
        <RoasterFilterForm />
      </FormProvider>
    </>
  );
}

export default RoasterFilter;
