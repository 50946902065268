import PropTypes from 'prop-types';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
  Button,
  Tooltip,
} from '@mui/material';
// utils
import { Icon } from '@iconify/react';
import { useSelector } from 'react-redux';

import moment from 'moment';
import { fDate } from '../../../../utils/formatTime';
import { fCurrency } from '../../../../utils/formatNumber';
// components

import Label from '../../../../components/Label';
import Image from '../../../../components/Image';
import Scrollbar from '../../../../components/Scrollbar';
//

// ----------------------------------------------------------------------

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

InvoiceDetailsShowModal.propTypes = {
  invoice: PropTypes.object.isRequired,
};

export default function InvoiceDetailsShowModal({ invoice }) {
  const theme = useTheme();
  const { storeSuppliers } = useSelector((state) => state.store);

  if (!invoice) {
    return '';
  }

  const suppliers = storeSuppliers.find((obj) => obj.id === invoice?.supplierId);

  let totalPrices = 0;
  let totalPreviousPrices = 0;
  let totalPreviousGST = 0;

  return (
    <>
      <Card sx={{ pt: 5, px: 5 }}>
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Image
              disabledEffect
              visibleByDefault
              alt="logo"
              src="/logo/logo_full.svg"
              sx={{ maxWidth: 70, borderRadius: '50%' }}
            />
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'right' } }}>
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={
                  (invoice?.status === 'APPROVED' && 'success') ||
                  (invoice?.status === 'PENDING' && 'warning') ||
                  // (invoice?.status === 'RETURN' && 'error') ||
                  'default'
                }
                sx={{ textTransform: 'uppercase', mb: 1 }}
              >
                {invoice?.status}
              </Label>

              <Typography variant="h6">{`INV-${invoice?.invoiceNumber}`}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Invoice from
            </Typography>
            <Typography variant="body2">{suppliers?.name}</Typography>
            <Typography variant="body2">{suppliers?.abn}</Typography>
            <Typography variant="body2">{suppliers?.email}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              date create
            </Typography>
            <Typography variant="body2">{invoice?.invoiceDate && fDate(invoice?.invoiceDate)}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Due date
            </Typography>
            <Typography variant="body2">{invoice?.dueDate && fDate(invoice?.dueDate)}</Typography>
          </Grid>
        </Grid>

        <Scrollbar>
          <Table>
            <TableHead
              sx={{
                borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                '& th': { backgroundColor: 'transparent' },
              }}
            >
              <TableRow>
                <TableCell width={40}>#</TableCell>
                <TableCell align="left">External UUID</TableCell>

                <TableCell align="left">Description</TableCell>
                <TableCell align="left">uom</TableCell>

                <TableCell align="left">Weight</TableCell>

                <TableCell align="left" width={30}>
                  Qty
                </TableCell>
                <TableCell align="right">Pack Size</TableCell>
                <TableCell align="right">Unit price</TableCell>
                <TableCell align="right">GST</TableCell>
                <TableCell align="right">Levy</TableCell>

                <TableCell align="right">Total</TableCell>
                <TableCell align="right">Return Quantity</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {invoice?.invoiceItems?.map((row, index) => {
                const rowTotal =
                  Number(row?.unitPrice) * Number(row.suppliedQty) + Number(row?.gst) + Number(row?.levy);
                totalPrices += rowTotal;
                let findReturnInvoices = '';

                if (invoice?.returnInvoiceItems) {
                  findReturnInvoices = invoice?.returnInvoiceItems.find((el) => el.externalUID === row.externalUID);
                }

                const previousInvoices = invoice?.previousInvoiceItems?.find(
                  (el) => el.externalUID === row?.externalUID
                );

                let comparePreviousWithCurrent = 0;

                if (previousInvoices) {
                  comparePreviousWithCurrent = row?.unitPrice - previousInvoices?.unitPrice;
                }

                return (
                  <TableRow
                    key={index}
                    sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell align="left" sx={{ maxWidth: 500 }}>
                      {row?.externalUID}
                    </TableCell>

                    <TableCell align="left">
                      <Box sx={{ maxWidth: 750 }}>
                        <Typography variant="subtitle2">{row?.brand}</Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                          {row?.productDesc}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="left">{row?.uom}</TableCell>

                    <TableCell align="left">
                      {row?.weight} ({row?.weightType})
                    </TableCell>

                    <TableCell align="left">{row?.suppliedQty}</TableCell>
                    <TableCell align="center">{row?.packSize}</TableCell>
                    <TableCell align="right">
                      <Typography
                        component={'p'}
                        fontWeight="500"
                        fontSize={'0.85rem'}
                        color={comparePreviousWithCurrent <= 0 ? '' : 'red'}
                      >
                        {fCurrency(row?.unitPrice)} ({comparePreviousWithCurrent.toFixed(2)})
                      </Typography>
                    </TableCell>
                    <TableCell align="right">{fCurrency(row?.gst)}</TableCell>
                    <TableCell align="right">{fCurrency(row?.levy)}</TableCell>

                    <TableCell align="right">{fCurrency(rowTotal)}</TableCell>
                    <TableCell align="center">{row?.returnQuantity?.suppliedQty}</TableCell>
                  </TableRow>
                );
              })}

              <RowResultStyle>
                <TableCell colSpan={7} />
                <TableCell align="right" colSpan={2}>
                  <Typography component="p" fontWeight="600">
                    T.GST
                  </Typography>
                  <Typography component="p" fontWeight="700">
                    Total
                  </Typography>
                </TableCell>
                <TableCell align="right" colSpan={2}>
                  <Typography component="p" fontWeight="600">
                    $ {Number(invoice?.totalGst) || 0.0}
                  </Typography>
                  <Typography component="p" fontWeight="600">
                    $ {Number(invoice?.totalInclGst) || 0.0}
                  </Typography>
                </TableCell>
              </RowResultStyle>
            </TableBody>
          </Table>
        </Scrollbar>

        <Divider sx={{ mt: 5 }} />

        {/* previous Invoice Items */}
        {invoice?.previousInvoiceItems && (
          <Scrollbar>
            <Box mt="1rem">
              <h2>Previous Invoice Items</h2>
            </Box>
            <Table>
              <TableHead
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  '& th': { backgroundColor: 'transparent' },
                }}
              >
                <TableRow>
                  <TableCell width={40}>#</TableCell>
                  <TableCell align="left">External UUID</TableCell>

                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">uom</TableCell>

                  <TableCell align="left">Weight</TableCell>

                  <TableCell align="left" width={30}>
                    Qty
                  </TableCell>
                  <TableCell align="right">Unit price</TableCell>
                  <TableCell align="right">GST</TableCell>
                  <TableCell align="right">Levy</TableCell>

                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {invoice?.previousInvoiceItems?.map((row, index) => {
                  const rowTotal =
                    Number(row?.unitPrice) * Number(row?.suppliedQty) + Number(row?.gst) + Number(row?.levy);
                  totalPreviousGST = Number(totalPreviousGST) + Number(row?.gst);
                  totalPreviousPrices += rowTotal;
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell align="left" sx={{ maxWidth: 500 }}>
                        {row?.externalUID}
                      </TableCell>

                      <TableCell align="left">
                        <Box sx={{ maxWidth: 750 }}>
                          <Typography variant="subtitle2">{row?.brand}</Typography>
                          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                            {row?.productDesc}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="left">{row?.uom}</TableCell>

                      <TableCell align="left">
                        {row?.weight} ({row?.weightType})
                      </TableCell>

                      <TableCell align="left">{row?.suppliedQty}</TableCell>
                      <TableCell align="right">{fCurrency(row?.unitPrice)}</TableCell>
                      <TableCell align="right">{fCurrency(row?.gst)}</TableCell>
                      <TableCell align="right">{fCurrency(row?.levy)}</TableCell>

                      <TableCell align="right">{fCurrency(rowTotal)}</TableCell>
                    </TableRow>
                  );
                })}

                <RowResultStyle>
                  <TableCell colSpan={6} />
                  <TableCell align="right" colSpan={2}>
                    <Typography component="p" fontWeight="600">
                      T.GST
                    </Typography>
                    <Typography component="p" fontWeight="700">
                      Total
                    </Typography>
                  </TableCell>
                  <TableCell align="right" colSpan={2}>
                    <Typography component="p" fontWeight="600">
                      $ {Number(totalPreviousGST.toFixed(2)) || 0}
                    </Typography>
                    <Typography component="p" fontWeight="600">
                      $ {Number(totalPreviousPrices.toFixed(2)) || 0}
                    </Typography>
                  </TableCell>
                </RowResultStyle>
              </TableBody>
            </Table>
          </Scrollbar>
        )}
        <Divider sx={{ mt: 5 }} />
      </Card>
    </>
  );
}
