import { Stack, Typography } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getSignedAgreementOfUser } from 'redux/slices/userSlice';

function ViewPaperworkDetailsDocumentsInfo() {
  const { signedDocumentLoading, signedDocumentList } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { watch } = useFormContext();

  const getIdValues = watch('id') || '';

  useEffect(() => {
    if (!getIdValues) return;
    dispatch(getSignedAgreementOfUser({ userId: getIdValues }));
  }, [getIdValues, dispatch]);

  return (
    <Stack>
      {signedDocumentLoading ? (
        <CustomLoadingScreen height={'30vh'} />
      ) : (
        <Stack>
          {!Object.keys(signedDocumentList).length ? (
            <Typography component="h5">No Documents Available</Typography>
          ) : (
            <>
              {Object.keys(signedDocumentList).map((documents, index) => (
                <Stack key={index}>
                  {!signedDocumentList[documents]?.length ? (
                    <h4>No {documents.substring(0, documents.length - 4)} Provided</h4>
                  ) : (
                    <>
                      {signedDocumentList[documents]?.map((document, index) => (
                        <Stack key={index} my={2}>
                          <Typography component={'p'} fontWeight={600}>
                            {documents.substring(0, documents.length - 1)}
                          </Typography>
                          <img src={document?.url} alt={documents} />
                        </Stack>
                      ))}
                    </>
                  )}
                </Stack>
              ))}
            </>
          )}
        </Stack>
      )}
    </Stack>
  );
}

export default ViewPaperworkDetailsDocumentsInfo;
