import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import React, { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Box, Button, Card, Grid, Stack } from '@mui/material';
import { FormProvider, RHFTextField } from 'components/hook-form';
import { createZone } from 'redux/slices/storeSlice';

ZonesAddEditForm.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.object,
  edit: PropTypes.bool,
};
export default function ZonesAddEditForm({ open, setOpen, updateData, edit }) {
  const { selectedDepartment } = useSelector((state) => state.userDepartment);
  const closeModal = () => {
    setOpen(false);
  };
  const ZoneFormSchema = Yup.object({
    low: Yup.number()
      .required('Low is required')
      .min(0, 'Low must be greater than 0')
      .max(100, 'Low must be less than 100'),
    excellent: Yup.number()
      .required('Excellent is required')
      .moreThan(Yup.ref('low'), 'Excellent must be greater than low')
      .max(100, 'Excellent must be less than 100'),
    healthy: Yup.number()
      .required('Healthy is required')
      .moreThan(Yup.ref('excellent'), 'Healthy must be greater than excellent')
      .max(100, 'Healthy must be less than 100'),
    danger: Yup.number()
      .required('Danger is required')
      .moreThan(Yup.ref('healthy'), 'Danger must be greater than healthy')
      .max(100, 'Danger must be less than 100'),
  });

  const defaultValues = useMemo(
    () => ({
      low: updateData?.low || '',
      excellent: updateData?.excellent || '',
      healthy: updateData?.healthy || '',
      danger: updateData?.danger || '',
    }),
    [updateData]
  );

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(ZoneFormSchema),
  });

  const { watch, handleSubmit, control } = methods;

  const dispatch = useDispatch();

  const createHandler = (data) => {
    const finalData = {
      ...data,
      departmentId: selectedDepartment?.id,
    };
    dispatch(createZone(finalData));
    closeModal();
  };

  const healthyZone = watch('healthy');

  useEffect(() => {
    methods.setValue('danger', Number(healthyZone) + 1);
  }, [healthyZone, methods]);
  return (
    <>
      <CustomOutletModal open={open} zIndex="1600" title={edit ? 'Update Labour Zones Data' : 'Add Labour Zones Data'}>
        <FormProvider methods={methods} onSubmit={handleSubmit(createHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card sx={{ py: 3, px: 3 }}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <RHFTextField name={`low`} label="Enter Low zone value*" />
                    </Grid>
                    <Grid item xs={6}>
                      <RHFTextField name={`excellent`} label="Enter Excellent zone value*" />
                    </Grid>
                    <Grid item xs={6}>
                      <RHFTextField name={`healthy`} label="Enter Healthy zone value*" />
                    </Grid>
                    <Grid item xs={6}>
                      <RHFTextField name={`danger`} label="Enter Danger zone value*" disabled />
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Stack alignItems={'flex-end'}>
                <div>
                  <Button type="submit" variant="contained" style={{ marginRight: '5px' }}>
                    {edit ? 'Update' : 'Create'}
                  </Button>
                  <Button variant="text" style={{ color: 'red' }} onClick={closeModal}>
                    Cancel
                  </Button>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </CustomOutletModal>
    </>
  );
}
