import React, { useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Divider,
  Grid,
  MenuItem,
  Modal,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import { Icon } from '@iconify/react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'redux/store';
import { deleteTimeClockByAdmin, updateTimeClockByAdmin } from 'redux/slices/timeSlice';
import TimeClockDrawerBreakForm from './TimeClockDrawerBreakForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function IndividualTimeClockDrawerEdit({ obj, index, remove }) {
  const { store } = useSelector((state) => state.store);

  const { control, getValues, setValue } = useFormContext();
  const { selectedDepartment } = useSelector((state) => state.userDepartment);
  const { user } = useSelector((state) => state.auth);
  const { postLoading } = useSelector((state) => state.time);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleDeleteModalClose = () => setOpenDeleteModal(false);

  const dispatch = useDispatch();
  const watchTimeClock = useWatch({
    control,
    name: `timeclocks.${index}`,
  });

  const EditTimeClockHandler = () => {
    const finalData = {
      ...watchTimeClock,
      departmentId: selectedDepartment?.id,
      userId: getValues('userId'),
      timeclockId: watchTimeClock?.id,
      approverId: user?.userDTO?.id,
    };

    const isSuccess = (succeed) => {
      if (succeed) {
        setValue(`timeclocks.${index}.isApproved`, true);
      }
    };

    dispatch(updateTimeClockByAdmin({ finalData, isSuccess }));
  };
  const deleteTimeClockHandler = () => {
    setOpenDeleteModal(true);
  };
  const deleteTimeClockFromModal = () => {
    const finalData = {
      ...watchTimeClock,
      departmentId: selectedDepartment?.id,
      id: watchTimeClock?.id,
    };
    const isSuccess = (succ) => {
      if (succ) {
        remove(index);
      }
    };
    dispatch(deleteTimeClockByAdmin({ finalData, isSuccess }));
    setOpenDeleteModal(false);
  };

  const checkIsApproved = watchTimeClock?.isApproved;

  const colorCode = [];
  obj?.flags?.forEach((el) => {
    if (!colorCode.includes(el.flagType)) {
      colorCode.push(el.flagType);
    }
  });

  const inColor = store?.maxClockInDistance > obj?.inDistance ? 'black' : 'red';
  const outColor = store?.maxClockInDistance > obj?.outDistance ? 'black' : 'red';

  return (
    <>
      {/* TimeClock Delete Modal */}
      <Modal
        open={openDeleteModal}
        onClose={handleDeleteModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to delete the Time Clock?
          </Typography>
          <Typography id="modal-modal-description" align="center" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="success"
              style={{ marginRight: '5px' }}
              onClick={() => deleteTimeClockFromModal()}
            >
              Delete
            </Button>
            <Button variant="outlined" color="error" onClick={handleDeleteModalClose}>
              Cancel
            </Button>
          </Typography>
        </Box>
      </Modal>

      <Grid
        container
        item
        xs={12}
        key={obj.fieldId}
        spacing={2.5}
        direction={{ xs: 'column', md: 'row' }}
        className="timeclockUpdateDrawer"
      >
        <Grid item xs={3}>
          <RHFTextField
            size="small"
            type="time"
            name={`timeclocks.${index}.inTime`}
            label="Start Time"
            disabled={checkIsApproved}
          />
        </Grid>
        <Grid item xs={3}>
          <RHFTextField
            size="small"
            type="time"
            name={`timeclocks.${index}.outTime`}
            label="End Time"
            disabled={checkIsApproved}
          />
        </Grid>

        <Grid item xs={5}>
          <RHFTextField name="note" label="Note" disabled={checkIsApproved} size="small" />
        </Grid>

        {obj.breaks && (
          <Grid item xs={9}>
            <Box display="flex" height="100%" alignItems="center" justifyContent={'flex-start'} gap="1rem">
              <Accordion sx={{ width: '100%' }}>
                <AccordionSummary expandIcon={<Icon icon="material-symbols:expand-more" />}>
                  <Typography>Breaks</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={1}>
                    {obj?.breaks?.map((el, indexs) => (
                      <TimeClockDrawerBreakForm
                        key={indexs}
                        mainIndex={index}
                        obj={el}
                        index={indexs}
                        timeClockId={obj?.id}
                        checkIsApproved={checkIsApproved}
                      />
                    ))}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
            <Divider />
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack>
            <TableRow>
              <TableCell>
                <Box style={{ display: 'flex' }}>
                  <Typography variant="subtitle2" style={{ marginRight: '5px' }}>
                    Clock In Distance:
                  </Typography>
                  <Typography variant="subtitle2" style={{ color: inColor }}>
                    {obj?.inDistance} M
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                <Box style={{ display: 'flex' }}>
                  <Typography variant="subtitle2" style={{ marginRight: '5px' }}>
                    Clock Out Distance:
                  </Typography>
                  <Typography variant="subtitle2" style={{ color: outColor }}>
                    {obj?.outDistance} M
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            mr="2rem"
            mb="2.5rem"
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Stack>
              <TableRow>
                {colorCode?.map((el, index) => (
                  <TableCell key={index}>
                    {/* <Tooltip title={el}> */}
                    <Box style={{ display: 'flex' }}>
                      <Box sx={{ width: 10, height: 10, borderRadius: '50%' }} className={el} />
                      <Typography style={{ fontSize: '0.6rem', marginTop: '-1.5px', marginLeft: '3px' }}>
                        {el.replace('_', ' ').replace('_', ' ')}
                      </Typography>
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </Stack>
            <Stack style={{ display: 'flex', flexDirection: 'row' }}>
              <Button
                style={{ marginRight: '1rem' }}
                color="secondary"
                variant="contained"
                size="small"
                onClick={EditTimeClockHandler}
                disabled={checkIsApproved || postLoading}
              >
                {/* {postLoading && <CustomLoadingScreen circularWidth="15px" />} */}
                Approve
              </Button>
              <Button
                color="error"
                variant="text"
                size="small"
                onClick={deleteTimeClockHandler}
                disabled={checkIsApproved || postLoading}
                style={{ cursor: 'pointer' }}
              >
                <Icon icon="material-symbols:delete" width="24" height="24" />
              </Button>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}

export default IndividualTimeClockDrawerEdit;
