import { Button, Card, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Iconify from 'components/Iconify';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deletePaymentType, getPaymentType } from 'redux/slices/salesSlice';
import EftAddForm from 'sections/@dashboard/sales/payType/PayTypeAddForm';

function PayTypeBody() {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const [openEdit, setOpenEdit] = useState({ status: false, data: null });
  const [openDelete, setOpenDelete] = useState({ status: false, id: null });

  const { paymentType, loading } = useSelector((state) => state.sales);

  const deleteEft = () => {
    dispatch(deletePaymentType({ id: openDelete.id, departmentId }));
    setOpenDelete({ status: false, id: null });
  };

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getPaymentType({ departmentId }));
  }, [departmentId, dispatch]);

  return (
    <>
      {loading ? (
        <CustomLoadingScreen height="20vh" />
      ) : (
        <Card sx={{ width: '600px', p: 2 }}>
          <Typography variant="h6" gutterBottom>
            Store EFT List
            <Divider />
          </Typography>
          <Table size="small" style={{ width: '600px' }}>
            <TableHead>
              <TableRow>
                <TableCell>S.N.</TableCell>
                <TableCell>Name</TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentType?.map((item, index) => (
                <TableRow hover key={item.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{item?.title}</TableCell>
                  <TableCell align="right">
                    <Button
                      variant={'text'}
                      color={'primary'}
                      onClick={() => setOpenEdit({ status: true, data: item })}
                    >
                      <Iconify icon={'eva:edit-2-outline'} width={20} height={20} />
                    </Button>
                    <Button
                      variant={'text'}
                      color={'error'}
                      onClick={() => setOpenDelete({ status: true, id: item?.id })}
                    >
                      <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      )}
      {openEdit.status && <EftAddForm open={openEdit.status} setOpen={setOpenEdit} updateData={openEdit.data} edit />}
      {openDelete.status && (
        <CustomDeleteDialog
          open={openDelete.status}
          handleClose={() => setOpenDelete({ status: false, id: null })}
          onAccept={deleteEft}
        />
      )}
    </>
  );
}

export default PayTypeBody;
