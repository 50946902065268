import Page from 'components/Page';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getStoreGuideline } from 'redux/slices/storeDocumentSlice';
import StoreGuidelinesContent from 'sections/@dashboard/storeDocuments/StoreGuidelinesContent';
import StoreGuidelinesHeader from 'sections/@dashboard/storeDocuments/StoreGuidelinesHeader';

function StoreGuidelines() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStoreGuideline());
  }, [dispatch]);
  return (
    <Page title="Store Guidelines">
      <StoreGuidelinesHeader />
      <StoreGuidelinesContent />
    </Page>
  );
}

export default StoreGuidelines;
