import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFTextField } from 'components/hook-form';
import { Button, Card, Stack } from '@mui/material';
import CustomDatePicker from 'components/CustomComponents/CustomDatePicker';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { UploadEquipmentLog } from 'redux/slices/storeDocumentSlice';
import { toast } from 'react-toastify';

function EquipmentAddEditForm({ data, isEdit, setOpen }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const EquipmentSchema = Yup.object().shape({
    equipmentName: Yup.string().required(''),
    makeModel: Yup.string().required(''),
    comment: Yup.string().required(''),
    quantity: Yup.number().required('').typeError(''),
    lastService: Yup.date().required('').typeError(''),
    dueService: Yup.date().required('').typeError(''),
  });
  const defaultValues = useMemo(
    () => ({
      equipmentName: data?.equipmentName || '',
      makeModel: data?.makeModel || '',
      comment: data?.comment || '',
      quantity: data?.quantity || '',
      lastService: data?.lastService || null,
      dueService: data?.dueService || null,
    }),
    [data]
  );

  const methods = useForm({ resolver: yupResolver(EquipmentSchema), defaultValues, mode: 'onChange' });

  const { handleSubmit, reset } = methods;

  const EquipmentSubmitHandler = (data) => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        reset();
        if (isEdit) {
          toast.success('equipment log updated', { autoClose: 1500 });
        } else {
          toast.success('New equipment log added', { autoClose: 1500 });
        }
        // setOpen(false)
      }
    };
    const finalData = {
      ...data,
      departmentId,
      lastService: moment(data.lastService).format('YYYY-MM-DD'),
      dueService: moment(data.dueService).format('YYYY-MM-DD'),
    };
    dispatch(UploadEquipmentLog({ finalData, isSuccess: isSuccessful }));
  };

  useEffect(() => {
    if (!data) return;
    reset({ ...data });
  }, [reset, data]);

  return (
    <FormProvider {...methods}>
      <Card sx={{ p: 2 }}>
        <Stack spacing={3}>
          <Stack direction={'row'} spacing={2}>
            <RHFTextField name="equipmentName" label="Equipment Name" InputLabelProps={{ shrink: true }} />
            <RHFTextField name="makeModel" label="Make/Model" InputLabelProps={{ shrink: true }} />
            <RHFTextField name="quantity" label="Quantity" InputLabelProps={{ shrink: true }} />
          </Stack>
          <Stack direction={'row'} spacing={2}>
            <CustomDatePicker name="lastService" label="Last Service" InputLabelProps={{ shrink: true }} />
            <CustomDatePicker name="dueService" label="Due Service" InputLabelProps={{ shrink: true }} />
          </Stack>
          <Stack direction={'row'} spacing={2}>
            <RHFTextField name={`comment`} label="Comment" InputLabelProps={{ shrink: true }} multiline minRows={3} />
          </Stack>
          <Stack justifyContent={'flex-end'} direction={'row'} spacing={2}>
            <Button variant="contained" color="primary" onClick={handleSubmit(EquipmentSubmitHandler)}>
              {isEdit ? 'Update' : 'Add'}
            </Button>
          </Stack>
        </Stack>
      </Card>
    </FormProvider>
  );
}

EquipmentAddEditForm.propTypes = {
  data: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default EquipmentAddEditForm;
