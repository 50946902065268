import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import * as yup from 'yup';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import HalfAndHalfBodySection from 'sections/@dashboard/halfAndHalfConfig/HalfAndHalfBodySection';
import { yupResolver } from '@hookform/resolvers/yup';
import { postHalfAndHalfConfig } from 'redux/slices/halfAndHalfSlice';

const schema = yup.object().shape({
  data: yup.array().of(
    yup.object().shape({
      sizes: yup.array().of(
        yup.object().shape({
          additionalPrice: yup
            .number()
            .typeError('')
            .transform((curr, orig) => (orig === '' ? 0 : curr)),
        })
      ),
    })
  ),
});

function HalfAndHalfBody() {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const { loading, halfAndHalfData } = useSelector((state) => state.halfAndHalf);

  const defaultValues = {};
  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onChange' });

  const { reset } = methods;

  useEffect(() => {
    if (!halfAndHalfData?.length) return;
    reset({ data: halfAndHalfData });
  }, [reset, halfAndHalfData]);

  const HalfAndHalfPublishHandler = (data) => {
    const finalData = data?.data?.map((sizeGroup) => ({
      ...sizeGroup,
    }));
    dispatch(postHalfAndHalfConfig({ departmentId, finalData }));
  };

  return (
    <>
      {loading ? (
        <CustomLoadingScreen height="40vh" />
      ) : (
        <FormProvider {...methods}>
          <form id="halfAndHalfPublishForm" onReset={methods.handleSubmit(HalfAndHalfPublishHandler)}>
            <HalfAndHalfBodySection />
          </form>
        </FormProvider>
      )}
    </>
  );
}

export default HalfAndHalfBody;
