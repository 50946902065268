import { Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import React from 'react';
import { PATH_DASHBOARD } from 'routes/paths';

function QuickSummaryHeader() {
  return (
    <HeaderBreadcrumbs
      heading="Quick Summary"
      links={[
        <Button
          key={1}
          variant="contained"
          color="primary"
          // startIcon={<Iconify icon={'mdi:eye'} />}
          // onClick={() => setOpenEventsView(true)}
          // className="roasterViewEventButton"
        >
          Events
        </Button>,
      ]}
      // links={[
      //   { name: 'Dashboard', href: PATH_DASHBOARD.root },
      //   //   { name: 'Roster', href: PATH_DASHBOARD.roaster.root },
      //   { name: 'Quick Summary' },
      // ]}
    />
  );
}

export default QuickSummaryHeader;
