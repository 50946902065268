import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, Divider, Grid, Stack, Typography } from '@mui/material';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { FormProvider, RHFTextField } from 'components/hook-form';
import React, { useMemo, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  createOnlineSalesPlatform,
  deleteOnlineSalesPlatform,
  updateOnlineSalesPlatform,
} from 'redux/slices/storeSlice';
import * as Yup from 'yup';

SalesPlatformCreateForm.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.object,
  edit: PropTypes.bool,
};

export default function SalesPlatformCreateForm({ open, setOpen, updateData, edit }) {
  const { selectedDepartment } = useSelector((state) => state.userDepartment);
  const closeModal = () => {
    setOpen(false);
  };
  const SalesPlatformSchema = Yup.object({
    platformName: Yup.string().required('Platform Name is required'),
    commissionPercent: Yup.number().lessThan(100),
  });

  const defaultValues = useMemo(
    () => ({
      platformName: updateData?.platformName || '',
      commissionPercent: updateData?.commissionPercent || 0,
    }),
    [updateData]
  );

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(SalesPlatformSchema),
  });

  const { watch, handleSubmit } = methods;

  const values = watch();

  const dispatch = useDispatch();

  const createOnlinePlatformHandler = (data) => {
    if (edit) {
      dispatch(
        updateOnlineSalesPlatform({
          platformName: data?.platformName,
          commissionPercent: data?.commissionPercent,
          departmentId: selectedDepartment?.id,
          id: updateData?.id,
        })
      );
      closeModal();
    } else {
      dispatch(
        createOnlineSalesPlatform({
          platformName: data?.platformName,
          commissionPercent: data?.commissionPercent,
          departmentId: selectedDepartment?.id,
        })
      );
      closeModal();
    }
  };

  return (
    <>
      <CustomOutletModal
        open={open}
        zIndex="1600"
        title={edit ? 'Edit Online Sales Platform' : 'Add New Online Sales Platform'}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(createOnlinePlatformHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card sx={{ py: 3, px: 3 }}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <RHFTextField name={`platformName`} label="Platform Name*" />
                    </Grid>
                    <Grid item xs={6}>
                      <RHFTextField name={`commissionPercent`} label="Commission Percentage*" />
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Stack alignItems={'flex-end'}>
                <div>
                  <Button type="submit" variant="contained" style={{ marginRight: '5px' }}>
                    {edit ? 'Update' : 'Create'}
                  </Button>
                  <Button variant="text" style={{ color: 'red' }} onClick={closeModal}>
                    Cancel
                  </Button>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </CustomOutletModal>
    </>
  );
}
