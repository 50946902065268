import { Button, Stack } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import * as Yup from 'yup';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFoodCategory, getFoodMenu, updateSizePricesOfFoodMenu } from 'redux/slices/menuEngineeringSlice';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider } from 'components/hook-form';
import { getMenuSizeGroup } from 'redux/slices/menuERSizeAndOptionSlice';
import FoodMenuBody from './FoodMenuBody';
import FoodMenuSearch from './FoodMenuSearch';

const FoodMenuSizeAndPriceSchema = Yup.object().shape({
  data: Yup.array().of(
    Yup.object().shape({
      menus: Yup.array().of(
        Yup.object().shape({
          basePrice: Yup.number()
            .required('')
            .typeError('')
            .moreThan(-1, '')
            .nullable('')
            .transform((curr, orig) => (orig === '' ? 0 : curr)),
          sizes: Yup.array().of(
            Yup.object().shape({
              additionalPrice: Yup.number()
                .typeError('')
                .moreThan(-1, '')
                .nullable('')
                .transform((curr, orig) => (orig === '' ? 0 : curr)),
            })
          ),
        })
      ),
    })
  ),
});

function FoodMenuIndex() {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const { foodMenus, foodMenuLoading, postLoading } = useSelector((state) => state.menuEngineering);

  const defaultValues = {
    data: [],
  };

  const methods = useForm({ defaultValues, resolver: yupResolver(FoodMenuSizeAndPriceSchema), mode: 'onChange' });

  const { reset, handleSubmit } = methods;

  const FoodMenuSizeAndPriceSubmit = (data) => {
    const finalData = data?.data?.filter((item) => item?.id);
    // console.log('🚀 => FoodMenuSizeAndPriceSubmit => finalData=>', finalData);

    dispatch(updateSizePricesOfFoodMenu({ finalData, departmentId }));
  };

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getFoodMenu({ departmentId }));
    dispatch(getMenuSizeGroup({ departmentId }));
    dispatch(getFoodCategory({ departmentId }));
  }, [departmentId, dispatch]);

  useEffect(() => {
    if (!foodMenus) return;
    reset({ data: foodMenus });
  }, [foodMenus, reset]);
  return (
    // <DndProvider backend={HTML5Backend}>
    <FormProvider methods={methods} onSubmit={handleSubmit(FoodMenuSizeAndPriceSubmit)}>
      <Stack mx={'1rem'} my="1rem" direction={'row'} alignItems="center" justifyContent={'space-between'} gap="2rem">
        <FoodMenuSearch />
        <Stack alignItems={'flex-end'}>
          <Button variant="contained" type="submit">
            {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : 'Save'}
          </Button>
        </Stack>
      </Stack>
      <Stack my="1rem">
        {foodMenuLoading ? (
          <CustomLoadingScreen height="30vh" />
        ) : (
          <>
            <FoodMenuBody />
          </>
        )}
      </Stack>
    </FormProvider>
    // </DndProvider>
  );
}

export default FoodMenuIndex;
