import {
  Box,
  Button,
  MenuItem,
  Modal,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Iconify from 'components/Iconify';
import { TableHeadCustom, TableMoreMenu } from 'components/table';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteStoreSupplier, storeSuppliers } from 'redux/slices/storeSlice';
import StoreSuppliersAddEditForm from './StoreSuppliersAddEditForm';

const TABLE_HEAD = [
  { id: 'id', label: 'SN', align: 'center' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'abn', label: 'ABN', align: 'center' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'dueDays', label: 'Due Days', align: 'center' },
  { id: 'address', label: 'Address', align: 'left' },
  { id: 'website', label: 'Website', align: 'center' },
  { id: 'phone', label: 'Phone', align: 'left' },
  { id: 'action', label: 'Action', align: 'center' },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  textAlign: 'center',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// ActiveStoreSuppliers.propTypes = {
//   open: PropTypes.bool,
//   setOpen: PropTypes.func,
//   deleteModalHandleClose: PropTypes.func,
//   setSupplierId: PropTypes.func,
// };

export default function ActiveStoreSuppliers() {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = (e) => {
    setOpenMenu(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  const deleteModalHandleClose = () => setOpenDeleteModal(false);
  const [supplierId, setSupplierId] = useState();
  const [updateData, setUpdateData] = useState();
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = useState(1);
  const { suppliers, loading: suppliersLoading } = useSelector((state) => state.store);
  // useEffect(() => {
  //   dispatch(storeSuppliers());
  // }, [dispatch]);

  const deleteSupplier = () => {
    dispatch(deleteStoreSupplier(supplierId));
    setOpenDeleteModal(false);
  };
  return (
    <>
      {/* Delete Modal */}
      <Modal open={openDeleteModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to delete this Supplier?
          </Typography>
          <Typography id="modal-modal-description" align="center" sx={{ mt: 2 }}>
            <Button variant="contained" color="success" style={{ marginRight: '5px' }} onClick={() => deleteSupplier()}>
              Delete
            </Button>
            <Button variant="outlined" color="error" onClick={deleteModalHandleClose}>
              Cancel
            </Button>
          </Typography>
        </Box>
      </Modal>

      <Table size="small">
        <TableHeadCustom headLabel={TABLE_HEAD} />
        <TableBody>
          {suppliersLoading ? (
            <TableRow>
              <TableCell colSpan={9}>
                <CustomLoadingScreen height="20vh" />
              </TableCell>
            </TableRow>
          ) : (
            suppliers?.suppliers?.map(
              (elm, index) =>
                elm?.status === 'ACTIVE' && (
                  <TableRow hover key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell>{elm?.name}</TableCell>
                    <TableCell align="center"> {elm?.abn}</TableCell>
                    <TableCell> {elm?.email}</TableCell>
                    <TableCell align="center"> {elm?.dueDays}</TableCell>
                    <TableCell align="left"> {elm?.address ? elm?.address : '---'}</TableCell>
                    <TableCell align="left"> {elm?.websiteURL ? elm?.websiteURL : '---'}</TableCell>
                    <TableCell align="left">
                      {!elm?.phone1 && !elm?.phone2 && '---'}
                      {elm?.phone1 ? `${elm?.phone1}` : ''}
                      {'   '}
                      {elm?.phone2 ? `//${elm?.phone1}` : ''}
                    </TableCell>
                    {/* <TableCell align="center">
                      <Button
                        onClick={() => {
                          setOpenUpdateModal(true);
                          setUpdateData(elm);
                        }}
                      >
                        <Iconify icon={'eva:edit-2-outline'} width={20} height={20} />
                      </Button>
                      <Button
                        variant="text"
                        color="error"
                        onClick={() => {
                          setOpenDeleteModal(true);
                          setSupplierId(elm?.id);
                        }}
                      >
                        <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
                      </Button>
                    </TableCell> */}
                    <TableCell align="right">
                      <TableMoreMenu
                        open={openMenu}
                        onOpen={handleOpenMenu}
                        onClose={handleCloseMenu}
                        actions={
                          <>
                            <MenuItem
                              onClick={() => {
                                setOpenUpdateModal(true);
                                setUpdateData(elm);
                                setOpenMenu(null);
                              }}
                            >
                              <Iconify icon={'eva:edit-2-outline'} width={20} height={20} />
                              Edit
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setOpenDeleteModal(true);
                                setSupplierId(elm?.id);
                                setOpenMenu(null);
                              }}
                            >
                              <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
                              Delete
                            </MenuItem>
                          </>
                        }
                      />
                    </TableCell>
                  </TableRow>
                )
            )
          )}
        </TableBody>
      </Table>
      <Box
        sx={{ position: 'relative', marginTop: '20px' }}
        justifyContent={'end'}
        alignContent="center"
        display={'flex'}
      >
        <Pagination
          count={suppliers?.paginationInfo?.totalPages}
          page={pageNo}
          onChange={(e, value) => setPageNo(value)}
        />
      </Box>
      {openUpdateModal && (
        <StoreSuppliersAddEditForm open={openUpdateModal} setOpen={setOpenUpdateModal} updateData={updateData} edit />
      )}
    </>
  );
}
