import { Card, Stack } from '@mui/material';
import CustomMultipleAutoCompleteField from 'components/CustomComponents/CustomAutoCompleteField';
import { FormProvider, RHFSelect } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import React, { useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setTradingHoursWeek, updateTradingHours } from 'redux/slices/storeConfigSlice';
import { findAllDaysFromWeeks } from 'utils/roasterFeatureUtils';
import TradingHoursCardBody from './TradingHoursCardBody';

function TradingHoursCard({ choosenType, setChoosenType }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const { tradingHours, tradingHoursLoading } = useSelector((state) => state.storeConfig);
  const [allWeeks, setAllWeeks] = useState([]);

  const defaultValues = {
    choosenType: 'MON',
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, reset } = methods;

  const findFinalTradingHours = (obj) => {
    const finalTradingHours = [];
    allWeeks?.forEach((week) => {
      let weekDay = moment(week?.label).format('ddd');
      weekDay = weekDay.toUpperCase();
      if (!obj?.tradingHours[weekDay]) return;
      finalTradingHours.push(obj?.tradingHours[weekDay]?.[0]);
    });

    let filteredArray = finalTradingHours.filter((item) => item);
    const today = moment().format('YYYY-MM-DD');

    filteredArray = filteredArray.map((item) => ({
      ...item,

      endTime: item?.isClosed ? '' : `${today}T${moment(item?.endTime).format('HH:mm:ss')}`,
      startTime: item?.isClosed ? '' : `${today}T${moment(item?.startTime).format('HH:mm:ss')}`,
    }));

    //  console.log('🚀 => findFinalTradingHours => filteredArray=>', filteredArray);

    return filteredArray;
  };

  const TradingHoursSubmitHandler = (data) => {
    const finalData = data?.data?.map((obj) => ({
      ...obj,
      tradingHours: findFinalTradingHours(obj),
    }));
    dispatch(updateTradingHours({ finalData, departmentId }));
  };

  const fromDate = moment(new Date()).startOf('isoWeek').format('YYYY-MM-DD');
  const toDate = moment(new Date()).endOf('isoWeek').format('YYYY-MM-DD');

  //   get weeks for table headings
  useEffect(() => {
    const getAllDates = findAllDaysFromWeeks(fromDate, toDate).map((obj, index) => ({
      id: index,
      label: moment(obj).format('YYYY-MM-DD'),
      align: 'left',
    }));
    setAllWeeks(getAllDates);
    dispatch(setTradingHoursWeek(getAllDates));
  }, [dispatch, fromDate, toDate]);

  const findWeeklyTradingHours = useCallback(
    (obj) => {
      const tradingHours = {};
      if (!obj?.tradingHours?.length) {
        allWeeks?.forEach((week) => {
          let weekDay = moment(week?.label).format('ddd');
          weekDay = weekDay.toUpperCase();
          tradingHours[weekDay] = [];
        });
      } else {
        allWeeks?.forEach((week) => {
          let weekDay = moment(week?.label).format('ddd');
          weekDay = weekDay.toUpperCase();
          const matchingDay = obj?.tradingHours?.find((item) => item?.day === weekDay);
          tradingHours[weekDay] = matchingDay ? [matchingDay] : [];
        });
      }
      return tradingHours;
    },
    [allWeeks]
  );

  useEffect(() => {
    if (!tradingHours?.length) return;
    const resetData = tradingHours?.map((obj) => ({
      ...obj,
      tradingHours: findWeeklyTradingHours(obj),
    }));
    reset({ data: resetData, choosenType });
  }, [findWeeklyTradingHours, reset, tradingHours, choosenType]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(TradingHoursSubmitHandler)}>
      <Card sx={{ p: 3 }}>
        <TradingHoursCardBody choosenType={choosenType} setChoosenType={setChoosenType} />
      </Card>
    </FormProvider>
  );
}

export default TradingHoursCard;
