import { Box, Tooltip } from '@mui/material';
import Iconify from 'components/Iconify';
import React, { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { findTotalDifferenceOfEachDays } from 'utils/roasterFeatureUtils';

function QuickSummaryDifferenceAllUserTotalInfo({ today }) {
  const [getTotal, setGetTotal] = useState();
  console.log('🚀 => QuickSummaryDifferenceAllUserTotalInfo => getTotal=>', getTotal);

  const { control } = useFormContext();
  const values = useWatch({
    control,
    name: 'users',
  });

  useEffect(() => {
    if (!values && !values.length) return;
    const getTotal = findTotalDifferenceOfEachDays(today, values);
    setGetTotal(getTotal);
  }, [values, today]);

  const findTimeDifference = (time) => {
    const absTime = Math.abs(time);
    const hours = Math.floor(absTime / 60);
    const minutes = Math.ceil(absTime % 60);
    console.log('🚀 => findTimeDifference => time=>', minutes);
    if (minutes === 60) return `${hours + 1}H 0M`;
    return `${hours}H ${minutes}M`;
  };

  return (
    <Box className="roasterTotalData">
      <div style={{ padding: '0', display: 'flex', justifyContent: 'center' }}>
        <Tooltip title="Total Hour Difference">
          <Box style={{ display: 'inline-flex', marginLeft: '7px' }}>
            <Iconify
              icon="material-symbols:nest-clock-farsight-analog-outline"
              width={17}
              style={{ color: `${getTotal?.getTotalTimeDifference < 0 ? 'red' : 'black'}` }}
            />
          </Box>
        </Tooltip>
        <p
          style={{
            display: 'inline-flex',
            fontWeight: '600',
            marginTop: '-6px',
            color: `${getTotal?.getTotalTimeDifference < 0 ? 'red' : 'black'}`,
          }}
        >
          {getTotal?.getTotalTimeDifference ? findTimeDifference(getTotal?.getTotalTimeDifference) : '0H 0M'}
        </p>
        <Tooltip title="Total Pay Difference">
          <Box style={{ display: 'inline-flex' }}>
            <Iconify
              icon="ic:baseline-attach-money"
              width={17}
              style={{ color: `${getTotal?.getTotalPriceDifference < 0 ? 'red' : 'black'}` }}
            />
          </Box>
        </Tooltip>
        <p
          style={{
            display: 'inline-flex',
            fontWeight: '600',
            marginTop: '-6px',
            color: `${getTotal?.getTotalPriceDifference < 0 ? 'red' : 'black'}`,
          }}
        >
          {Math.abs(getTotal?.getTotalPriceDifference).toFixed(2) ?? 0}
        </p>
      </div>
    </Box>
  );
}

export default QuickSummaryDifferenceAllUserTotalInfo;
