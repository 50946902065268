// import { useEffect, useMemo } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import 'css/bimal.scss';
import 'css/shadow.scss';
import 'css/customComponent.scss';
import 'css/darkday.scss';

// import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
// routes
// import { Navigate, useNavigate } from 'react-router';
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
// import { getUserDetails, setUser } from './redux/slices/authSlice';
// ----------------------------------------------------------------------

export default function App() {
  // const dispatch = useDispatch();
  // const { loading: authLoading } = useSelector((state) => state.auth);
  // useEffect(() => {
  //   const getToken = JSON.parse(localStorage.getItem('profiles'));
  //   if (!getToken?.tokenDTO?.acessToken) {
  //     return;
  //   }
  //   dispatch(getUserDetails());
  // }, [dispatch]);
  return (
    <MotionLazyContainer>
      <ToastContainer />
      <ThemeProvider>
        <ThemeSettings>
          {/* {!authLoading && ( */}
          <NotistackProvider>
            <ProgressBarStyle />
            <ChartStyle />
            <ScrollToTop />
            <Router />
          </NotistackProvider>
          {/* )} */}
        </ThemeSettings>
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
