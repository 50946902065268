import {
  Box,
  Divider,
  Modal,
  Popover,
  Stack,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import moment from 'moment';
import { useDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { getOnlinePlatforms, getSalesForecast } from 'redux/slices/salesSlice';

import { TableNoData } from 'components/table';
import Iconify from 'components/Iconify';
import RoasterAllUserIndividualDateInfo from './RoasterAllUserIndividualDateInfo';
import RoasterSumData from './RoasterSumData';
import ViewIndividualEvent from './ViewIndividualEvent';

function RoasterTableHead() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [eventData, setEventData] = React.useState([]);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [openEvent, setOpenEvent] = React.useState({ status: false, data: [] });
  const { control } = useFormContext();
  const dispatch = useDispatch();
  const { selectedDepartment } = useSelector((state) => state.userDepartment);

  const { fields } = useFieldArray({
    control,
    name: 'salesForecasts',
  });

  // watch events of roaster in form data
  const watchSpecialDates =
    useWatch({
      control,
      name: 'events',
    }) || [];

  useEffect(() => {
    if (!Object.keys(selectedDepartment).length) return;
    dispatch(getOnlinePlatforms(selectedDepartment?.id));
    dispatch(getSalesForecast(selectedDepartment?.id));
  }, [dispatch, selectedDepartment]);

  const findTitle = (findSpecialDate) => {
    let title = '';
    findSpecialDate?.map((item) => {
      title += `<p style="margin: 0; padding: 0; font-size: 0.8rem; font-style: italic; font-family: arial;">${item?.title}</p>`;
      return title;
    });
    return title;
  };
  return (
    <>
      <TableHead>
        {/* Showing roaster date of week with special event as exclamation */}
        {
          fields?.length > 0 && (
            <>
              <TableRow>
                <TableCell
                  style={{ width: '180px', minWidth: '170px', padding: '10px', margin: '10px', borderRadius: '0px' }}
                />

                {/* for showing dates */}
                {fields?.map((obj, index) => {
                  const findSpecialDate = watchSpecialDates?.filter(
                    (el) => moment(el?.start).format('YYYY-MM-DD') === obj?.date
                  );
                  return (
                    <TableCell
                      key={index}
                      style={{ textAlign: 'center', margin: '10px', padding: '10px', borderRadius: '0px' }}
                    >
                      {findSpecialDate?.length > 0 ? (
                        <div style={{ display: ' flex', flexDirection: 'row' }}>
                          {/* eslint-disable-next-line react/no-danger */}
                          <Box
                            style={{ marginTop: '2px', cursor: 'pointer', marginRight: '2px' }}
                            onClick={() => setOpenEvent({ status: true, data: findSpecialDate })}
                          >
                            <Typography
                              aria-owns={open ? 'mouse-over-popover' : undefined}
                              aria-haspopup="true"
                              onMouseEnter={(event) => {
                                handlePopoverOpen(event);
                                setEventData(findSpecialDate);
                              }}
                              onMouseLeave={handlePopoverClose}
                            >
                              <Iconify
                                className="roasterHolidayBlinker"
                                icon="simple-line-icons:event"
                                color="#1808d9"
                                // height={20}
                                width={20}
                              />
                            </Typography>
                          </Box>
                          {/* </Tooltip> */}
                          <span>{moment(obj?.date).format('ddd, DD MMM')}</span>
                          <Popover
                            id="mouse-over-popover"
                            sx={{
                              pointerEvents: 'none',
                            }}
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            onClose={handlePopoverClose}
                            disableRestoreFocus
                          >
                            <Box sx={{ p: 1, height: 'auto', width: '20rem' }}>
                              {eventData?.map((item, index) => (
                                <>
                                  <Stack spacing={1} mx="1rem" my="1rem" key={index}>
                                    <Stack direction="row" spacing={2}>
                                      <Box style={{ marginRight: '7px' }}>
                                        <Iconify icon="subway:title" color="#1f19d2db" width={20} height={20} />
                                      </Box>
                                      {item?.title}
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                      <Box style={{ marginRight: '7px' }}>
                                        <Iconify
                                          icon="simple-line-icons:note"
                                          color="#1f19d2db"
                                          width={20}
                                          height={20}
                                        />
                                      </Box>
                                      {/* eslint-disable-next-line no-nested-ternary */}
                                      {item?.description
                                        ? item?.description?.length > 50
                                          ? `${item?.description?.slice(0, 50)}...`
                                          : item?.description
                                        : 'No description Provided'}
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                      <Box style={{ marginRight: '7px' }}>
                                        <Iconify
                                          icon="icon-park-solid:category-management"
                                          color="#1f19d2db"
                                          width={20}
                                          height={20}
                                        />
                                      </Box>
                                      {item?.type === 'HOLIDAY' ? 'Public Holiday' : 'Store Event'}
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                      <Box style={{ marginRight: '7px' }}>
                                        <Iconify
                                          icon="material-symbols:timer-off-outline"
                                          color="#1f19d2db"
                                          width={20}
                                          height={20}
                                        />
                                      </Box>
                                      {moment(item?.start).format('ddd, DD MMM hh:mm A')}
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                      <Box style={{ marginRight: '7px' }}>
                                        <Iconify
                                          icon="material-symbols:timer-outline"
                                          color="#1f19d2db"
                                          width={20}
                                          height={20}
                                        />
                                      </Box>
                                      {moment(item?.end).format('ddd, DD MMM hh:mm A')}
                                    </Stack>
                                  </Stack>
                                  <Divider />
                                </>
                              ))}
                            </Box>
                          </Popover>
                        </div>
                      ) : (
                        moment(obj?.date).format('ddd, DD MMM')
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>

              {/* summary data for table head first cell */}
              <TableRow className="roasterTableTotalInformation">
                {fields?.length > 0 && (
                  <TableCell style={{ textAlign: 'center', padding: '0', borderRadius: '0px' }}>
                    <RoasterSumData fields={fields} />
                  </TableCell>
                )}

                {/* summary of each day of week */}
                {fields?.map((obj, index) => (
                  <TableCell key={index} style={{ margin: '5px', padding: '3px' }}>
                    <RoasterAllUserIndividualDateInfo
                      currentWeek={moment(obj?.date).format('ddd, DD MMM')}
                      salesForeCast={obj}
                      mainIndex={index}
                    />
                  </TableCell>
                ))}
              </TableRow>
            </>
          )
          // : (
          //   <TableNoData isNotFound={fields?.length <= 0} />
          // )
        }
      </TableHead>
      {openEvent?.status && (
        <ViewIndividualEvent
          open={openEvent?.status}
          setOpen={() => setOpenEvent({ status: false, data: [] })}
          data={openEvent?.data}
        />
      )}
    </>
  );
}

export default RoasterTableHead;
