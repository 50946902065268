import React, { useState } from 'react';
import { Button, Grid, Stack } from '@mui/material';

import { useFieldArray, useFormContext } from 'react-hook-form';
import Iconify from 'components/Iconify';
import { RHFTextField } from 'components/hook-form';
import AddNewDealCategory from './AddNewDealCategory';

function ViewDealDetailsDealCategories() {
  const [openAddNewDealCategory, setOpenAddNewDealCategory] = useState({ status: false, data: null });
  const { control, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dealCategories',
    keyName: 'dealCategoriesId',
  });

  const dealData = watch();
  console.log('🚀 => ViewDealDetailsDealCategories => dealData=>', fields);

  return (
    <>
      <Stack gap="1rem">
        {fields?.map((el, index) => (
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} key={index}>
            <RHFTextField disabled name={`dealCategories.${index}.qty`} label="Qty" />
            <RHFTextField disabled name={`dealCategories.${index}.categoryId.name`} label="Category" />
            <RHFTextField disabled name={`dealCategories.${index}.sizeId.name`} label="Size" />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Iconify
                icon="tabler:edit"
                height={20}
                width={20}
                style={{ cursor: 'pointer', marginLeft: '5px' }}
                //  onClick={() => RemoveSingleDeal(el, index)}
              />
              <Iconify
                icon="solar:trash-bin-minimalistic-2-bold"
                height={20}
                width={20}
                style={{ color: 'red', cursor: 'pointer' }}
                //  onClick={() => RemoveSingleDeal(el, index)}
              />
            </div>
          </Stack>
        ))}
        <Stack alignItems={'flex-end'} marginTop={1}>
          <Button
            variant="contained"
            color="secondary"
            style={{ backgroundColor: '#4caf50' }}
            startIcon={<Iconify icon="material-symbols:add" />}
            onClick={() => setOpenAddNewDealCategory({ status: true, data: dealData })}
          >
            Category
          </Button>
        </Stack>
      </Stack>
      {openAddNewDealCategory?.status && (
        <AddNewDealCategory
          open={openAddNewDealCategory?.status}
          handleClose={() => setOpenAddNewDealCategory({ status: false, data: null })}
          dealData={openAddNewDealCategory?.data}
        />
      )}
    </>
  );
}

export default ViewDealDetailsDealCategories;
