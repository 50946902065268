import { TableBody, TableRow } from '@mui/material';
import { TableNoData } from 'components/table';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import QuickSummaryDifferenceTableCellWithAvatar from './QuickSummaryDifferenceTableCellWithAvatar';
import QuickSummaryDifferenceIndividualTableRow from './QuickSummaryDifferenceIndividualTableRow';

function QuickSummaryDifferenceTableBody() {
  const { control } = useFormContext();
  const { differenceDetailsLoading } = useSelector((state) => state.quickSummary);

  const { fields } = useFieldArray({
    name: `users`,
    keyName: 'usersID',
    control,
  });
  return (
    <>
      {differenceDetailsLoading ? (
        ''
      ) : (
        <>
          {fields?.length > 0 ? (
            <>
              {fields?.map((user, mainIndex) => (
                <TableRow key={mainIndex}>
                  <QuickSummaryDifferenceTableCellWithAvatar user={user} mainIndex={mainIndex} />
                  <QuickSummaryDifferenceIndividualTableRow user={user} mainIndex={mainIndex} />
                </TableRow>
              ))}
            </>
          ) : (
            <TableNoData isNotFound={fields?.length <= 0} />
          )}
        </>
      )}
    </>
  );
}

export default QuickSummaryDifferenceTableBody;
