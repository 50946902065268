import CheckUserDepartment from 'components/CustomComponents/CheckUserDepartment';
import Page from 'components/Page';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { storeDetails } from 'redux/slices/storeSlice';
import StoreSuppliersBody from 'sections/@dashboard/store/StoreSuppliersBody';
import StoreSuppliersHeader from 'sections/@dashboard/store/StoreSuppliersHeader';

export default function StoreSuppliers() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(storeDetails());
  }, [dispatch]);
  return (
    <CheckUserDepartment>
      <Page title="Store: suppliers">
        <StoreSuppliersHeader />
        <StoreSuppliersBody />
      </Page>
    </CheckUserDepartment>
  );
}
