import { Button, Card, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Iconify from 'components/Iconify';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApproveSignedDocument } from 'redux/slices/userSlice';
import HrSignedDocumentViewDrawer from './HrSignedDocumentViewDrawer';

function HrSignedDocumentCard(props) {
  const { signedDocumentList, signedDocumentLoading } = useSelector((state) => state.user);
  const [openView, setOpenView] = useState(false);
  const [viewData, setViewData] = useState(null);
  const dispatch = useDispatch();

  const ApproveHRDocument = (item) => {
    const finalData = { userId: props?.userId, type: 'HR', id: item?.id };
    dispatch(ApproveSignedDocument({ finalData }));
  };
  const ApproveOHNSDocument = (item) => {
    const finalData = { userId: props?.userId, type: 'OHNS', id: item?.id };
    dispatch(ApproveSignedDocument({ finalData }));
  };
  const ApproveSOPDocument = (item) => {
    const finalData = { userId: props?.userId, type: 'SOP', id: item?.id };
    dispatch(ApproveSignedDocument({ finalData }));
  };
  return (
    <>
      <Card style={{ marginTop: '10px' }}>
        {signedDocumentLoading ? (
          <CustomLoadingScreen height="20vh" />
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Document Name</TableCell>
                <TableCell>Document Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {signedDocumentList?.hrAgreementsURLs?.length > 0 &&
                signedDocumentList?.hrAgreementsURLs?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>HR agreement {index === 0 ? '' : index}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell style={{ display: 'flex' }}>
                      <Button
                        variant="text"
                        onClick={() => ApproveHRDocument(item)}
                        disabled={item?.status === 'APPROVED'}
                      >
                        <Iconify icon="mdi:tick-all" width={20} height={20} />
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => {
                          setOpenView(true);
                          setViewData(item);
                        }}
                      >
                        <Iconify icon="ic:baseline-remove-red-eye" width={20} height={20} />
                      </Button>
                    </TableCell>
                    {/* {item?.status === 'PENDING' && <TableCell>approve</TableCell>} */}
                  </TableRow>
                ))}
              {signedDocumentList?.ohnsAgreementsURLs?.length > 0 &&
                signedDocumentList?.ohnsAgreementsURLs?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>OHNS agreement {index === 0 ? '' : index}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell style={{ display: 'flex' }}>
                      <Button
                        variant="text"
                        onClick={() => ApproveOHNSDocument(item)}
                        disabled={item?.status === 'APPROVED'}
                      >
                        <Iconify icon="mdi:tick-all" width={20} height={20} />
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => {
                          setOpenView(true);
                          setViewData(item);
                        }}
                      >
                        <Iconify icon="ic:baseline-remove-red-eye" width={20} height={20} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              {signedDocumentList?.sopAgreementsURLs?.length > 0 &&
                signedDocumentList?.sopAgreementsURLs?.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>SOP agreement {index === 0 ? '' : index}</TableCell>
                    <TableCell>{item.status}</TableCell>
                    <TableCell style={{ display: 'flex' }}>
                      <Button
                        variant="text"
                        onClick={() => ApproveSOPDocument(item)}
                        disabled={item?.status === 'APPROVED'}
                      >
                        <Iconify icon="mdi:tick-all" width={20} height={20} />
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => {
                          setOpenView(true);
                          setViewData(item);
                        }}
                      >
                        <Iconify icon="ic:baseline-remove-red-eye" width={20} height={20} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              {signedDocumentList?.hrAgreementsURLs?.length === 0 &&
                signedDocumentList?.ohnsAgreementsURLs?.length === 0 &&
                signedDocumentList?.sopAgreementsURLs?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={3} align="center" style={{ color: 'red' }}>
                      No Signed Documents
                    </TableCell>
                  </TableRow>
                )}
            </TableBody>
          </Table>
        )}
      </Card>
      {openView && <HrSignedDocumentViewDrawer open={openView} setOpen={setOpenView} data={viewData} />}
    </>
  );
}

export default HrSignedDocumentCard;
