import { Button, Link } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
// import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';
import ChecklistAddForm from './ChecklistAddForm';
import CategoryManageDrawer from './CategoryManageDrawer';

function CheckListHeader() {
  // const navigate = useNavigate();
  const [addChecklist, setAddChecklist] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);

  const { checklistTabValue } = useSelector((state) => state.storeDocument);
  return (
    <HeaderBreadcrumbs
      heading="Checklist"
      links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Checklist' }]}
      action={
        checklistTabValue === 0 && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Button
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} />}
              onClick={() => setAddChecklist(true)}
            >
              New Checklist
            </Button>
            <Link
              style={{ cursor: 'pointer', marginTop: '10px', marginLeft: '6px' }}
              onClick={() => setOpenCategory(true)}
            >
              Manage Category
            </Link>
            {addChecklist && (
              <ChecklistAddForm
                // item={category}
                open={addChecklist}
                setOpen={setAddChecklist}
                // categoryId={category?.id}
              />
            )}
            {openCategory && <CategoryManageDrawer open={openCategory} setOpen={setOpenCategory} />}
          </div>
        )
      }
    />
  );
}

export default CheckListHeader;
