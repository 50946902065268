import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormLabel, Stack } from '@mui/material';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { ColorSinglePicker } from 'components/color-utils';
import PropTypes from 'prop-types';
import { FormProvider, RHFTextField } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createChecklistCategory } from 'redux/slices/storeDocumentSlice';
import * as Yup from 'yup';

const COLOR_OPTIONS = [
  '#00AB55', // theme.palette.primary.main,
  '#1890FF', // theme.palette.info.main,
  '#54D62C', // theme.palette.success.main,
  '#FFC107', // theme.palette.warning.main,
  '#FF4842', // theme.palette.error.main
  '#04297A', // theme.palette.info.darker
  '#7A0C2E', // theme.palette.error.darker
];

function ChecklistCategoriesForm({ open, setOpen, updateData, edit }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const { store } = useSelector((state) => state.store);
  const closeModal = () => {
    setOpen(false);
  };

  const checklistCategorySchema = Yup.object({
    name: Yup.string().required(''),
    colorCode: Yup.string().required(''),
  });

  const defaultValues = useMemo(
    () => ({
      name: updateData?.name || '',
      colorCode: updateData?.colorCode || '',
    }),
    [updateData]
  );

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(checklistCategorySchema),
  });

  const { handleSubmit, control } = methods;

  const CategorySubmitHandler = (data) => {
    if (edit) {
      const finalData = { ...data, id: updateData.id, storeId: store.id, departmentId };
      dispatch(createChecklistCategory({ finalData, setOpen }));
    } else {
      const finalData = { ...data, storeId: store.id, departmentId };
      dispatch(createChecklistCategory({ finalData, setOpen }));
    }
  };

  return (
    <>
      <CustomOutletModal
        //   width="60rem"
        open={open}
        zIndex="1600"
        onClose={closeModal}
        title={edit ? 'Edit Checklist Category' : 'Add New Checklist Category'}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(CategorySubmitHandler)}>
          <Stack spacing={2} sx={{ p: 3 }}>
            <RHFTextField name="name" label="Category Name" />
            <Controller
              name="colorCode"
              lable="Color Code"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div>
                  <ColorSinglePicker value={field.value} onChange={field.onChange} colors={COLOR_OPTIONS} />
                  {error && <FormLabel sx={{ color: error ? 'red' : '' }}>Color *</FormLabel>}
                </div>
              )}
            />
          </Stack>
          <Stack direction="row" justifyContent="flex-end">
            <Button variant="contained" type="submit" style={{ marginRight: '10px' }}>
              {edit ? 'Update' : 'Create'}
            </Button>
            <Button variant="contained" onClick={closeModal} color="error">
              Cancel
            </Button>
          </Stack>
        </FormProvider>
      </CustomOutletModal>
    </>
  );
}

ChecklistCategoriesForm.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  updateData: PropTypes.object,
  edit: PropTypes.bool,
};

export default ChecklistCategoriesForm;
