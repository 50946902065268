// import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PATH_DASHBOARD } from 'routes/paths';
import FixedCostAddEditForm from './FixedCostAddEditForm';

const FixedCostHeader = ({ choosenDate, setChoosenDate }) => {
  const [openAdd, setOpenAdd] = useState(false);
  const { fixedCost } = useSelector((state) => state.operational);

  return (
    <>
      <HeaderBreadcrumbs
        heading="Fixed Cost"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Operational-Cost', href: PATH_DASHBOARD.operationalCost.root },
          // { name: 'Fixed-Cost' },
        ]}
        action={
          <Box className="invoiceListHeadingButtons">
            <>
              <Button
                variant="contained"
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                onClick={() => setOpenAdd(true)}
                disabled={fixedCost?.id}
              >
                Create
              </Button>
            </>
          </Box>
        }
      />
      {openAdd && (
        <FixedCostAddEditForm
          edit={false}
          open={openAdd}
          setOpen={setOpenAdd}
          choosenDate={choosenDate}
          setChoosenDate={setChoosenDate}
        />
      )}
    </>
  );
};

export default FixedCostHeader;
