import { MenuItem, TableCell, TableRow, Tooltip } from '@mui/material';
import Label from 'components/Label';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import React, { useState } from 'react';
import Iconify from 'components/Iconify';
import { TableMoreMenu } from 'components/table';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import { deleteAnnouncement } from 'redux/slices/announcementSlice';
import { useDispatch } from 'react-redux';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import PublicAnnouncementEdit from './PublicAnnouncementEdit';
import PublicAnnouncementView from './PublicAnnouncementView';

function PublicAnnouncementTableBody({ item, index }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const theme = useTheme();
  const [openView, setOpenView] = useState({ status: false, id: null });
  const [openDelete, setOpenDelete] = useState({ status: false, id: null });
  const [openMenu, setOpenMenuActions] = useState(null);
  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };
  const [openEdit, setOpenEdit] = useState({ status: false, data: {} });

  function title(item) {
    const text = <span dangerouslySetInnerHTML={{ __html: `${item?.title}` }} />;
    return text;
  }
  const HandleDelete = () => {
    dispatch(deleteAnnouncement({ id: openDelete.id, departmentId }));
    setOpenDelete({ status: false, id: null });
  };
  return (
    <TableRow hover>
      <TableCell>{index + 1}.</TableCell>
      <TableCell style={{ width: '150px' }}>{title(item)}</TableCell>
      <TableCell style={{ width: '400px' }}>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: `${item?.description}` }} />
      </TableCell>
      <TableCell align="center">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(item?.isPermanent && 'success') || 'error'}
          sx={{ textTransform: 'capitalize' }}
        >
          {item?.isPermanent ? 'Yes' : 'No'}
        </Label>
      </TableCell>
      <TableCell align="center">{item?.startDate ? moment(item?.startDate).format('ddd, DD MMM') : '---'}</TableCell>
      <TableCell align="center">{item?.endDate ? moment(item?.endDate).format('ddd, DD MMM') : '---'}</TableCell>
      <TableCell>
        <TableMoreMenu
          open={openMenu}
          onOpen={(event) => setOpenMenuActions(event.currentTarget)}
          onClose={() => setOpenMenuActions(null)}
          className="tablemore"
          actions={
            <>
              <MenuItem
                onClick={() => {
                  setOpenView({ status: true, id: item?.id });
                  handleCloseMenu();
                }}
              >
                <Iconify icon="mdi:eye" />
                View
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenEdit({ status: true, data: item });
                  handleCloseMenu();
                }}
              >
                <Iconify icon="mdi:pencil" />
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenDelete({ status: true, id: item?.id });
                  handleCloseMenu();
                }}
              >
                <Iconify icon="mdi:delete" />
                Delete
              </MenuItem>
            </>
          }
        />
      </TableCell>

      {openView?.status && (
        <PublicAnnouncementView
          open={openView?.status}
          handleClose={() => setOpenView({ status: false, id: null })}
          id={openView?.id}
        />
      )}
      {openEdit.status && (
        <PublicAnnouncementEdit
          open={openEdit.status}
          handleClose={() => setOpenEdit({ status: false, data: {} })}
          data={openEdit.data}
        />
      )}
      {openDelete.status && (
        <CustomDeleteDialog
          open={openDelete.status}
          handleClose={() => setOpenDelete({ status: false, id: null })}
          onAccept={HandleDelete}
        />
      )}
    </TableRow>
  );
}

export default PublicAnnouncementTableBody;
