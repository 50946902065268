import { Box, Card, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeAnnouncementCurrentTab } from 'redux/slices/announcementSlice';
import PrivateAnnouncmentIndex from 'sections/@dashboard/announcement/PrivateAnnouncmentIndex';
import PublicAnnouncementIndex from 'sections/@dashboard/announcement/PublicAnnouncementIndex';

function AnnouncementBody() {
  const dispatch = useDispatch();
  const { currentTab } = useSelector((state) => state.announcement);
  const handleChange = (event, newValue) => {
    dispatch(changeAnnouncementCurrentTab(newValue));
  };
  return (
    <Card>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingLeft: '0.7rem' }}>
        <Tabs value={currentTab} onChange={handleChange} aria-label="Stock and Order Tabs">
          <Tab label="Public" id="0" />
          <Tab label="Private" id="1" />
        </Tabs>
      </Box>

      {currentTab === 0 && <PublicAnnouncementIndex />}
      {currentTab === 1 && <PrivateAnnouncmentIndex />}
    </Card>
  );
}

export default AnnouncementBody;
