import Page from 'components/Page';
import React from 'react';
import MostLikedMenusHeader from './MostLikedMenusHeader';
import MostLikedMenusBody from './MostLikedMenusBody';

function MostLikedMenusIndex() {
  return (
    <>
      <Page title="Most Liked Menus ">
        <MostLikedMenusHeader />
        <MostLikedMenusBody />
      </Page>
    </>
  );
}

export default MostLikedMenusIndex;
