import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import { errorToastTime, successToastTime } from 'config';

export const getStock = createAsyncThunk('stock/getStock', async (props, thunkAPI) => {
  try {
    let pageNoString;
    if (props?.pageNo) {
      pageNoString = `?pageNo=${props.pageNo - 1}&pageSize=${props.pageSize || 50}`;
    } else {
      pageNoString = '';
    }

    if (props.searchTxt) {
      const searchText = `?searchText=${props.searchTxt}`;
      const response = await apiURL.get(`/stock/getStocks/${props.departmentId}${searchText}`, {
        signal: thunkAPI.signal,
      });

      return response.data;
    }

    const response = await apiURL.get(`/stock/getStocks/${props.departmentId}${pageNoString}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getStockOrderList = createAsyncThunk('stock/getStockOrderList', async (props) => {
  try {
    let pageNoString;
    if (props?.pageNo) {
      pageNoString = `?pageNo=${props.pageNo - 1}&pageSize=${10}`;
    } else {
      pageNoString = '';
    }
    const response = await apiURL.get(`/stock/orderList/${props.departmentId}/${pageNoString}`);
    // thunkAPI.dispatch(getStock())
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getStockOrderDetails = createAsyncThunk('stock/getStockOrderDetails', async (props) => {
  try {
    const response = await apiURL.get(`/stock/orderDetails/${props.departmentId}/${props.orderId}`);
    if (response.status === 200) {
      props?.isSuccess();
    }
    const mapWatchAll = response?.data?.orderItems?.reduce((acc, current) => {
      if (!current?.orderQty) return [...acc];
      const currentSupplierId = current?.supplierId;
      const findSupplierId = acc.find((obj) => obj.supplierId === currentSupplierId);
      if (findSupplierId) {
        findSupplierId.data.push(current);
      } else {
        acc.push({ supplierId: currentSupplierId, changedSupplierId: current?.supplierId, data: [current] });
      }
      return [...acc];
    }, []);
    return { ...response.data, orderItems: [...mapWatchAll] };
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createStock = createAsyncThunk('stock/createStock', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/stock`, props.finalData);
    if (response.status === 200) {
      toast.success('Stock created Successfully.', { autoClose: successToastTime });
      props.setOpen(false);
    }
    thunkAPI.dispatch(getStock({ departmentId: props.finalData?.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateStock = createAsyncThunk('stock/updateStock', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put(`/stock`, props.finalData);
    if (response.status === 200) {
      toast.success('Stock Updated Successfully.', { autoClose: successToastTime });
      props.setOpen(false);
    }
    thunkAPI.dispatch(getStock({ departmentId: props.finalData?.departmentId }));

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createStockOrder = createAsyncThunk('stock/createStockOrder', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/stock/order`, props.finalData);
    if (response.status === 200) {
      toast.success('Stock Order Created Successfully.', { autoClose: successToastTime });
      thunkAPI.dispatch(isCreateStockOrderSwitchAction());
      thunkAPI.dispatch(isOpenCreateOrderModalAction(false));
    }

    // thunkAPI.dispatch(getStock())
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteStockItems = createAsyncThunk('stock/deleteStockItems', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/stock/${props.departmentId}/${props.stockId}`);
    if (response.status === 200) {
      toast.success('Stock Deleted Successfully.', { autoClose: successToastTime });
    }
    thunkAPI.dispatch(getStock({ departmentId: props?.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteOrderItems = createAsyncThunk('stock/deleteStockOrder', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/stock/order/${props.departmentId}/${props.orderId}`);
    if (response.status === 200) {
      toast.success('Order Item Deleted Successfully.', { autoClose: successToastTime });
      thunkAPI.dispatch(isCreateStockOrderSwitchAction());
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const addStockToIngredient = createAsyncThunk('stock/addStockToIngredient', async (props) => {
  try {
    const response = await apiURL.put(`/ingredient/addStockToIngredient`, props.finalData);
    if (response.status === 200) {
      props.isSuccess(true);
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});
export const removeStockFromIngredient = createAsyncThunk('stock/addStockToIngredient', async (props) => {
  try {
    const response = await apiURL.put(`/ingredient/removeStockFromIngredient`, props.finalData);
    if (response.status === 200) {
      props.isSuccess(true);
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const increaseStockPriority = createAsyncThunk('stock/increaseStockPriority', async (props) => {
  try {
    const response = await apiURL.put(`/ingredient/increasePriority`, props.finalData);
    if (response.status === 200) {
      props.isSuccess(true);
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const decreaseStockPriority = createAsyncThunk('stock/decreaseStockPriority', async (props) => {
  try {
    const response = await apiURL.put(`/ingredient/decreasePriority`, props.finalData);
    if (response.status === 200) {
      props.isSuccess(true);
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

const stockSlice = createSlice({
  name: 'stocks',
  initialState: {
    loading: false,
    postLoading: false,
    allStocks: {},
    stockOrderList: [],
    stockOrderDetails: {},
    isCreateStockOrderSwitch: {
      isCreateOrder: false,
      isCreateOrderModal: false,
    },
    stockCurrentTab: 0,
  },

  reducers: {
    isCreateStockOrderSwitchAction(state) {
      state.isCreateStockOrderSwitch.isCreateOrder = !state.isCreateStockOrderSwitch.isCreateOrder;
    },
    isCreateStockOrderAction(state, action) {
      state.isCreateStockOrderSwitch.isCreateOrder = action.payload;
    },

    isOpenCreateOrderModalAction(state, action) {
      state.isCreateStockOrderSwitch.isCreateOrderModal = action.payload;
    },
    changeStockCurrentTab(state, action) {
      state.stockCurrentTab = action.payload;
    },
  },
  extraReducers: {
    [getStock.pending]: (state) => {
      state.loading = true;
    },
    [getStock.fulfilled]: (state, action) => {
      state.loading = false;
      state.allStocks = action.payload;
    },
    [getStock.rejected]: (state) => {
      state.loading = false;
    },

    [getStockOrderList.pending]: (state) => {
      state.loading = true;
    },
    [getStockOrderList.fulfilled]: (state, action) => {
      state.loading = false;
      state.stockOrderList = action.payload;
    },
    [getStockOrderList.rejected]: (state) => {
      state.loading = false;
    },

    [createStock.pending]: (state) => {
      state.postLoading = true;
    },
    [createStock.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [createStock.rejected]: (state) => {
      state.postLoading = false;
    },

    [updateStock.pending]: (state) => {
      state.postLoading = true;
    },
    [updateStock.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateStock.rejected]: (state) => {
      state.postLoading = false;
    },

    [createStockOrder.pending]: (state) => {
      state.postLoading = true;
    },
    [createStockOrder.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [createStockOrder.rejected]: (state) => {
      state.postLoading = false;
    },

    [getStockOrderDetails.pending]: (state) => {
      state.postLoading = true;
    },
    [getStockOrderDetails.fulfilled]: (state, action) => {
      state.postLoading = false;
      state.stockOrderDetails = action.payload;
    },
    [getStockOrderDetails.rejected]: (state) => {
      state.postLoading = false;
    },
  },
});

export const {
  isCreateStockOrderSwitchAction,
  isOpenCreateOrderModalAction,
  isCreateStockOrderAction,
  changeStockCurrentTab,
} = stockSlice.actions;
export default stockSlice.reducer;
