import { DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { Button, Stack } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getMostLikedMenus, reorderMostLikedMenus } from 'redux/slices/mostLikedMenusSlice';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import MostLikedMenusBodyTableRow from './MostLikedMenusBodyTableRow';

function MostLikedMenusBody() {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const { loading, mostLikedMenus } = useSelector((state) => state.mostLikedMenus);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { reset, control, handleSubmit } = methods;

  const { fields, move } = useFieldArray({
    control,
    name: 'data',
    keyName: 'menuId',
  });

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;
    if (active.id !== over.id) {
      move(
        fields.findIndex((item) => item?.id === active.id),
        fields.findIndex((item) => item?.id === over.id)
      );
    }
  };

  const reOrderManage = (data) => {
    const finalData = {
      menus: data?.data?.map((item) => ({ ...item })),
      departmentId,
    };

    dispatch(reorderMostLikedMenus({ finalData, departmentId }));
  };

  useEffect(() => {
    if (!mostLikedMenus?.length) return;
    reset({ data: mostLikedMenus });
  }, [mostLikedMenus, reset]);

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getMostLikedMenus({ departmentId }));
  }, [dispatch, departmentId]);

  return (
    <FormProvider {...methods}>
      {loading ? (
        <CustomLoadingScreen height="20vh" />
      ) : (
        <form id="mostLikedMenusReOrder" onSubmit={handleSubmit(reOrderManage)}>
          <Stack spacing={1}>
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToParentElement]}
            >
              <SortableContext items={fields} strategy={verticalListSortingStrategy}>
                {fields?.map((item, index) => (
                  <MostLikedMenusBodyTableRow key={item?.menuId} index={index} item={item} />
                ))}
              </SortableContext>
            </DndContext>
          </Stack>
          {fields?.length > 0 && (
            <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ mt: 2 }}>
              <Button variant="contained" onClick={handleSubmit(reOrderManage)}>
                Save
              </Button>
            </Stack>
          )}
        </form>
      )}
    </FormProvider>
  );
}

export default MostLikedMenusBody;
