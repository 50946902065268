import { Box, Tab, Tabs } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import FoodMenuEdit from 'pages/dashboard/FoodMenuAndRecipe/FoodMenu/FoodMenuEdit';
import FoodMenuPriceEdit from 'pages/dashboard/FoodMenuAndRecipe/FoodMenu/FoodMenuPriceEdit';
import FoodMenuSettings from 'pages/dashboard/FoodMenuAndRecipe/FoodMenu/FoodMenuSettings';
import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { clearAllRecipeofMenu } from 'redux/slices/FoodRecipeSlice';
import { clearMenuDetails } from 'redux/slices/FoodMenuSlice';
import FoodMenuImageProcess from './FoodMenuImageProcess';
import FoodMenuRecipeTab from './FoodMenuRecipeTab';

function FoodMenuEditDrawer({ open, setOpen, menu, indexList }) {
  const [currentTab, setCurrentTab] = useState(0);
  const { setValue } = useFormContext();
  const dispatch = useDispatch();

  const isSuccessAfterUpdate = (data) => {
    if (data?.file) {
      const createURL = URL.createObjectURL(data?.file);
      setValue(`data.${indexList?.mainIndex}.menus.${indexList?.menuIndex}.imageUrl`, createURL);
    }
    setValue(`data.${indexList?.mainIndex}.menus.${indexList?.menuIndex}.name`, data?.name);
    setOpen(false);
  };

  const handleClose = () => {
    dispatch(clearMenuDetails());
    dispatch(clearAllRecipeofMenu());
  };

  useEffect(() => {
    dispatch(clearAllRecipeofMenu());
  }, [dispatch, currentTab]);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  return (
    <CustomDrawer
      open={open}
      setOpen={setOpen}
      title={`Manage Menu_${menu?.name}`}
      PaperProps={{ sx: { minWidth: '60%' } }}
      handleClose={handleClose}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={handleChange} aria-label="Menu Edit ">
          <Tab label="Edit" id="0" />
          <Tab label="Availability" id="1" />
          <Tab label="Process" id="2" />
          <Tab label="Recipe" id="3" />
          <Tab label="Settings" id="4" />
        </Tabs>
      </Box>

      {currentTab === 0 && <FoodMenuEdit menuId={menu?.id} isSuccess={isSuccessAfterUpdate} />}

      {currentTab === 1 && <FoodMenuPriceEdit menu={menu} indexList={indexList} />}

      {currentTab === 2 && <FoodMenuImageProcess menuId={menu?.id} indexList={indexList} />}
      {currentTab === 3 && <FoodMenuRecipeTab menu={menu} />}

      {currentTab === 4 && <FoodMenuSettings menu={menu} />}
    </CustomDrawer>
  );
}

export default FoodMenuEditDrawer;
