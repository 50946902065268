import { Box } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import React from 'react';
import { PATH_DASHBOARD } from 'routes/paths';

function FoodCogsReportHeader() {
  return (
    <Box>
      <HeaderBreadcrumbs
        heading="Cogs Summary Report"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Cogs Report' },
          // { name: 'Report' }
        ]}
      />
    </Box>
  );
}

export default FoodCogsReportHeader;
