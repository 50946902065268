import React, { useEffect, useState } from 'react';
import { PATH_DASHBOARD } from 'routes/paths';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import { Box, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { getTimeClockDay } from 'redux/slices/timeSlice';
import { convertDatetoString } from 'utils/customDateAndTimeFormat';
import AddTimeDuration from './AddTimeDuration';

function TimeDurationClockHeaders() {
  const [openAddModal, setOpenAddModal] = useState(false);
  const { choosenDate } = useSelector((state) => state.time);
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const OpenAddTimeModalHandler = () => {
    setOpenAddModal(true);
  };

  useEffect(() => {
    if (!choosenDate || !departmentId || openAddModal) return;
    dispatch(getTimeClockDay({ departmentId, date: convertDatetoString(choosenDate) }));
  }, [choosenDate, departmentId, dispatch, openAddModal]);
  return (
    <>
      <HeaderBreadcrumbs
        heading="Time Clock"
        links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Time Clock' }]}
        action={
          <Box className="invoiceListHeadingButtons">
            <Button
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} />}
              onClick={OpenAddTimeModalHandler}
            >
              Create
            </Button>
          </Box>
        }
      />
      {openAddModal && <AddTimeDuration open={openAddModal} setOpen={setOpenAddModal} />}
    </>
  );
}

export default TimeDurationClockHeaders;
