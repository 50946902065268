import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Login from 'pages/auth/Login';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router';
import { getUserDetails, setLogout } from 'redux/slices/authSlice';
import { PATH_AUTH } from 'routes/paths';

function AuthProvider({ children }) {
  const dispatch = useDispatch();
  //   const navigate = useNavigate();
  const { isAuth, loading } = useSelector((state) => state.auth);
  useEffect(() => {
    const accessToken = JSON.parse(localStorage.getItem('profiles'));
    if (!accessToken?.tokenDTO?.acessToken) {
      dispatch(setLogout());
      dispatch({ type: 'USER_LOGOUT' });
      <Navigate to="/auth/login" />;
    } else {
      dispatch(getUserDetails());
      <Navigate to="/dashboard" />;
    }
  }, [dispatch]);

  return <>{!loading && <>{children}</>}</>;
}

export default AuthProvider;
