import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import { errorToastTime, successToastTime } from 'config';
// import axios from '../../utils/axios';
// import { dispatch } from '../store';

export const getCalendarEvents = createAsyncThunk('calendar/getEvents', async (props) => {
  try {
    const response = await apiURL.post('/store/getEvent', props.finalData);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createCalendarEvent = createAsyncThunk('calendar/createEvent', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post('/store/handleEvent', props.finalData);
    if (response.status === 200) {
      if (props?.finalData?.id) {
        toast.success('Event updated successfully', { autoClose: successToastTime });
      } else {
        toast.success('Event created successfully', { autoClose: successToastTime });
      }
      props?.isSuccess(true);
      // thunkAPI.dispatch(getCalendarEvents(props.finalData));
    }
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteCalendarEvent = createAsyncThunk('calendar/deleteEvent', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/store/event/${props?.departmentId}/${props?.id}`);
    if (response.status === 200) {
      toast.success('Event deleted successfully', { autoClose: successToastTime });
      props?.isSuccess(true); // thunkAPI.dispatch(getCalendarEvents(props.finalData));
    }
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    events: [],
    loading: false,
    selectedRange: null,
    isOpenModal: false,
    selectedEventId: null,
  },
  reducers: {
    // START LOADING
    openModal(state) {
      state.isOpenModal = true;
    },

    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },

    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    },

    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },
  },
  extraReducers: {
    [getCalendarEvents.pending]: (state) => {
      state.loading = true;
      state.events = [];
    },
    [getCalendarEvents.fulfilled]: (state, action) => {
      state.loading = false;
      state.events = action.payload;
    },
    [getCalendarEvents.rejected]: (state) => {
      state.loading = false;
      state.events = [];
    },
  },
});

export default calendarSlice.reducer;

export const { openModal, closeModal, selectEvent, selectRange } = calendarSlice.actions;
