import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import PropTypes from 'prop-types';
import { Avatar, Card, Stack } from '@mui/material';
import Iconify from 'components/Iconify';
import React from 'react';

RecommendedMenusBodyTableRow.propTypes = {
  item: PropTypes.object,
};

function RecommendedMenusBodyTableRow({ item }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: item?.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    backgroundColor: isDragging ? '#cccccc' : '',
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <>
      <Card sx={{ p: 2 }} style={{ ...style, borderBottom: '0.5px solid rgba(145, 158, 171, 0.24)' }} ref={setNodeRef}>
        <Stack style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* Title and description */}
          <Stack style={{ width: '400px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div {...listeners} {...attributes} style={{ marginRight: '10px', marginTop: '10px', cursor: 'move' }}>
                <Iconify icon="system-uicons:drag" height={20} width={20} />
              </div>
              <div>
                {item?.thumbnailUrl ? (
                  <Avatar
                    src={item?.thumbnailUrl}
                    alt=""
                    style={{
                      height: '50px',
                      width: '50px',
                      borderRadius: '100%',
                      marginRight: '10px',
                    }}
                  />
                ) : (
                  <Avatar
                    alt=""
                    src=""
                    style={{ height: '50px', width: '50px', borderRadius: '100%', marginRight: '10px' }}
                  />
                )}
              </div>
              <div>
                <div style={{ display: 'flex' }}>
                  <p style={{ fontWeight: '600', marginRight: '10px' }}>{item?.name}</p>
                  <p style={{ fontWeight: '800' }}>{`($ ${item?.basePrice ?? 0})`}</p>
                </div>
              </div>
            </div>
          </Stack>
          <Stack direction="column" justifyContent="flex-start" spacing={'1px'} style={{ width: '600px' }}>
            <div>
              <p>{item?.description?.length > 100 ? item?.description?.slice(0, 100) : item?.description}</p>
            </div>
          </Stack>
        </Stack>
      </Card>
    </>
  );
}

export default RecommendedMenusBodyTableRow;
