import { Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import React from 'react';
import { PATH_DASHBOARD } from 'routes/paths';
import AddNewLogs from 'sections/@dashboard/storeDocuments/logs/AddNewLogs';

function LogHeader() {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <HeaderBreadcrumbs
        heading="Log"
        links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Log list' }]}
        action={
          <Button
            variant="contained"
            onClick={() => {
              setOpen(true);
            }}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            New Log
          </Button>
        }
      />
      {open && <AddNewLogs open={open} setOpen={setOpen} />}
    </>
  );
}

export default LogHeader;
