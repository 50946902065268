import { Stack, TextField, Typography, useTheme } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { convertDateintoTimeString } from 'utils/customDateAndTimeFormat';
import { meterToKm } from 'utils/distanceMeasure';

function TimeClockAccordionDetailsClockOut({ clockOut, index, timeDifference, maxAceptedMins }) {
  const theme = useTheme();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  if (!clockOut) {
    return <Typography component="p">Not Yet Clock Out</Typography>;
  }

  const address = clockOut?.address || 'No Address';
  const distanceInKm = meterToKm(clockOut?.distanceInMeters || 0);
  const clockOutTime = convertDateintoTimeString(clockOut?.clockTime, 'hh:mm a');
  const clockOutDate = moment(clockOut?.clockTime).format('ddd, DD-MMM');

  const findColor = () => {
    let color = '';
    if (timeDifference > 960) {
      color = 'red';
    } else if (maxAceptedMins > 0) {
      if (timeDifference > maxAceptedMins) {
        color = '#f6be00';
      } else {
        color = '';
      }
    }
    return color;
  };

  return (
    <Stack direction="row" gap={1.5} width={'45%'} alignItems={'center'}>
      {/* <Stack sx={{ width: '0.5rem', height: '0.5rem', bgcolor: theme.palette.error.main, borderRadius: '50%' }} /> */}
      <Stack gap={1}>
        <Stack flexDirection={'row'} gap={1}>
          <Typography component="p" fontWeight={'500'}>
            {address} |
          </Typography>
          <Typography component="p" fontWeight={'500'}>
            {distanceInKm.toFixed(2)} KM
          </Typography>
        </Stack>
        <Stack>
          {/* <Controller
            name={`clockList.${index}.clockOut.clockTime`}
            control={control}
            errors={errors}
            render={({ field }) => (
              <TimePicker
                label={'Clock Out'}
                closeOnSelect={false}
                PopperProps={{
                  sx: { zIndex: '1600' },
                }}
                {...field}
                renderInput={(params) => (
                  <TextField {...params} sx={{ maxWidth: '10rem' }} size="small" error={false} />
                )}
              />
            )}
          /> */}
          <Typography
            component="p"
            fontWeight={600}
            style={{
              color: findColor(),
            }}
          >
            {`${clockOutDate}, ${clockOutTime}`}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default TimeClockAccordionDetailsClockOut;
