import { Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import PropTypes from 'prop-types';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFoodMenuRecipeSummary } from 'redux/slices/menuEngineeringSlice';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { TableNoData } from 'components/table';
import RecipeSummaryTableBody from './RecipeSummaryTableBody';

function RecipeSummaryIndex({ menu }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const { foodMenuRecipeSummary, foodMenuRecipeSummaryLoading } = useSelector((state) => state.menuEngineering);

  useEffect(() => {
    if (!departmentId || !menu) return;
    dispatch(getFoodMenuRecipeSummary({ departmentId, menuId: menu.id }));
  }, [dispatch, departmentId, menu]);

  const optionLength = foodMenuRecipeSummary?.menuSizeOptions?.menuOptions?.length;

  const comboLength = foodMenuRecipeSummary?.menuCombos?.length;

  const ingredientLength = foodMenuRecipeSummary?.menuSizeIngredient?.ingredients?.length;
  const emptyData = !optionLength && !comboLength && !ingredientLength;

  const calculateTotal = (size) => {
    let totalCogs = 0;
    let totalWeight = 0;
    foodMenuRecipeSummary?.menuSizeOptions?.menuOptions?.forEach((option) => {
      const qtyUsed = option?.menuSizes?.find((menuSize) => menuSize?.id === size?.id)?.qtyUsed ?? 0;
      const unitPrice = option?.ingredient?.unitPrice ?? 0;
      const unitWeight = option?.ingredient?.unitWeight ?? 0;
      totalWeight += qtyUsed ?? 0;
      if (!unitPrice || !unitWeight || !qtyUsed) return;
      totalCogs += (unitPrice / unitWeight) * (qtyUsed / 1000);
    });
    foodMenuRecipeSummary?.menuSizeIngredient?.ingredients?.forEach((ingredient) => {
      const qtyUsed = ingredient?.menuSizes?.find((menuSize) => menuSize?.id === size?.id)?.qtyUsed ?? 0;
      const unitPrice = ingredient?.unitPrice ?? 0;
      const unitWeight = ingredient?.unitWeight ?? 0;
      totalWeight += qtyUsed ?? 0;
      if (!unitPrice || !unitWeight || !qtyUsed) return;
      totalCogs += (unitPrice / unitWeight) * (qtyUsed / 1000);
    });

    foodMenuRecipeSummary?.menuCombos?.forEach((combo) => {
      const qtyUsed = combo?.comboItems?.map(
        (item) => item?.comboItemSizes?.find((itemSize) => itemSize?.menuSizeId === size?.id)?.qtyUsed ?? 0
      );
      const averageQtyUsed = qtyUsed.reduce((a, b) => a + b, 0) / qtyUsed.length || 0;

      const unitPrice = combo?.comboItems?.map(
        (item) =>
          item?.comboItemSizes?.find((itemSize) => itemSize?.menuSizeId === size?.id)?.ingredient?.unitPrice ?? 0
      );
      const averageUnitPrice = unitPrice.reduce((a, b) => a + b, 0) / unitPrice.length;

      const unitWeight = combo?.comboItems?.map(
        (item) =>
          item?.comboItemSizes?.find((itemSize) => itemSize?.menuSizeId === size?.id)?.ingredient?.unitWeight ?? 0
      );

      const averageUnitWeight = unitWeight.reduce((a, b) => a + b, 0) / unitWeight.length;

      totalWeight += averageQtyUsed ?? 0;

      if (!averageUnitPrice || !averageUnitWeight || !averageQtyUsed) return 0;
      totalCogs += (averageUnitPrice / averageUnitWeight) * (averageQtyUsed / 1000);
    });

    return { totalCogs: totalCogs ?? 0, totalWeight: totalWeight ?? 0 };
  };

  return (
    <Scrollbar>
      <TableContainer>
        <Table size="small">
          {foodMenuRecipeSummaryLoading ? (
            <CustomLoadingScreen height="20vh" />
          ) : (
            <>
              {emptyData ? (
                <TableNoData isNotFound={emptyData} />
              ) : (
                <TableBody>
                  <TableRow style={{ backgroundColor: `rgba(41,42,45,0.2)` }}>
                    <TableCell align="left">S.N.</TableCell>
                    <TableCell align="left">Name</TableCell>
                    {foodMenuRecipeSummary?.menuSizeIngredient?.menuSizes?.map((size) => (
                      <TableCell align="center" key={size?.id}>
                        {size?.sizeName} <br /> qtyUsed / Cost
                      </TableCell>
                    ))}
                  </TableRow>
                  <RecipeSummaryTableBody />
                  <TableRow>
                    <TableCell />
                    <TableCell style={{ fontWeight: 'bold' }}>Total:</TableCell>
                    {foodMenuRecipeSummary?.menuSizeIngredient?.menuSizes?.map((size) => (
                      <TableCell align="center" key={size?.id} style={{ fontWeight: 'bold' }}>
                        {calculateTotal(size)?.totalWeight?.toFixed(2)} gm / ${' '}
                        {calculateTotal(size)?.totalCogs?.toFixed(2)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              )}
            </>
          )}
        </Table>
      </TableContainer>
      {emptyData ? (
        ''
      ) : (
        <Stack mt={2}>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              role="presentation"
              style={{
                width: '15px',
                height: '15px',
                borderRadius: '100%',
                backgroundColor: 'rgba(255,0,0, .2)',
                marginLeft: '20px',
              }}
            />
            <p style={{ marginTop: '-5px', marginLeft: '5px' }}>Menu Options</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              role="presentation"
              style={{
                width: '15px',
                height: '15px',
                borderRadius: '100%',
                backgroundColor: 'rgba(0,255,0,0.2)',
                marginLeft: '20px',
              }}
            />
            <p style={{ marginTop: '-5px', marginLeft: '5px' }}>Menu Ingredients</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div
              role="presentation"
              style={{
                width: '15px',
                height: '15px',
                borderRadius: '100%',
                backgroundColor: 'rgba(0,0,255,0.2)',
                marginLeft: '20px',
              }}
            />
            <p style={{ marginTop: '-5px', marginLeft: '5px' }}>Menu Combo</p>
          </div>
        </Stack>
      )}
    </Scrollbar>
  );
}

RecipeSummaryIndex.propTypes = {
  menu: PropTypes.object,
};

export default RecipeSummaryIndex;
