import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import { errorToastTime, successToastTime } from 'config';

export const getIngredientList = createAsyncThunk('stock/getIngredientList', async (props, thunkAPI) => {
  try {
    let pageNoString;
    if (props?.pageNo) {
      pageNoString = `?pageNo=${props.pageNo - 1}&pageSize=${props.pageSize || 10}`;
    } else {
      pageNoString = '';
    }
    if (props.searchText) {
      const searchText = `?name=${props.searchText}`;
      const response = await apiURL.get(`/ingredient/searchIngredient/${props.departmentId}${searchText}`, {
        signal: thunkAPI.signal,
      });
      return response.data;
    }
    const response = await apiURL.get(`/ingredient/searchIngredient/${props.departmentId}${pageNoString}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getSearchIngredientList = createAsyncThunk(
  'ingredients/getSearchIngredientList',
  async (props, thunkAPI) => {
    try {
      let pageNoString;
      if (props?.pageNo) {
        pageNoString = `?pageNo=${props.pageNo - 1}&pageSize=${props.pageSize || 50}`;
      } else {
        pageNoString = `?pageNo=0&pageSize=50`;
      }
      if (props.searchText) {
        const searchText = `?name=${props.searchText}`;
        const response = await apiURL.get(`/ingredient/searchIngredientsLikeStock/${props.departmentId}${searchText}`, {
          signal: thunkAPI.signal,
        });
        return response.data;
      }
      const response = await apiURL.get(`/ingredient/searchIngredientsLikeStock/${props.departmentId}${pageNoString}`);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const getIngredientListWithPrice = createAsyncThunk('stock/getIngredientListWithPrice', async (props) => {
  const response = await apiURL.get(`/ingredient/getIngredientsWithPricePerKg/${props.departmentId}`);
  return response.data;
});

export const getIngredientDetails = createAsyncThunk('stock/getIngredientDetails', async (props) => {
  const response = await apiURL.get(`/ingredient/stocks/${props.departmentId}/${props.ingredientId}`);

  return response.data;
});

export const createIngredients = createAsyncThunk('stock/createIngredients', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/ingredient/createIngredient`, props.finalData);
    if (response.status === 200) {
      toast.success('Ingredient Created Successfully.', { autoClose: successToastTime });
      props.isSuccess(response?.data);
    }
    thunkAPI.dispatch(getIngredientList({ departmentId: props.departmentId, pageNo: 1 }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateIngredients = createAsyncThunk('stock/updateIngredients', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put(`/ingredient/updateIngredient`, props.finalData);
    if (response.status === 200) {
      toast.success('Ingredient Updated Successfully.', { autoClose: successToastTime });
    }
    // thunkAPI.dispatch(getIngredientList({ departmentId: props.departmentId, pageNo: 1 }));
    thunkAPI.dispatch(getSearchIngredientList({ departmentId: props.departmentId, pageNo: 1 }));

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteIngredients = createAsyncThunk('stock/deleteIngredients', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/ingredient/${props.departmentId}/${props.ingredientId}`);
    if (response.status === 200) {
      toast.success('Ingredients Deleted Successfully.', { autoClose: successToastTime });
    }
    // thunkAPI.dispatch(getIngredientList({ departmentId: props?.departmentId, pageNo: 1 }));
    thunkAPI.dispatch(getSearchIngredientList({ departmentId: props?.departmentId, pageNo: 1 }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getIngredientMenusList = createAsyncThunk('stock/ingredientMenusList', async (props) => {
  const response = await apiURL.get(`/ingredient/menus/${props.departmentId}/${props.ingredientId}`);
  return response.data;
});

export const updateIngredientMenus = createAsyncThunk('stock/createIngredientMenus', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put(`/ingredient/updateIngredient`, props.formData);
    if (response.status === 200) {
      props.isSuccess(true);
      thunkAPI.dispatch(
        getIngredientMenusList({
          departmentId: props.formData.departmentId,
          ingredientId: props.formData.ingredientId,
        })
      );
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getRecipeIngredientList = createAsyncThunk('stock/recipeIngredientList', async (props) => {
  const response = await apiURL.get(`/ingredient/recipe/${props.departmentId}`);
  return response.data;
});

export const createNewRecipeIngredient = createAsyncThunk(
  'stock/createNewRecipeIngredient',
  async (props, thunkAPI) => {
    try {
      const response = await apiURL.post(`/ingredient/recipe`, props.finalData);
      if (response.status === 200) {
        toast.success('Recipe Created Successfully.', { autoClose: successToastTime });
        props.handleClose();
      }
      thunkAPI.dispatch(getRecipeIngredientList({ departmentId: props.finalData.departmentId }));
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const deleteRecipeIngredient = createAsyncThunk('stock/deleteRecipeIngredient', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/ingredient/recipe/${props.departmentId}/${props.id}`);
    if (response.status === 200) {
      toast.success('Recipe Deleted Successfully.', { autoClose: successToastTime });
    }
    thunkAPI.dispatch(getRecipeIngredientList({ departmentId: props?.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getIndividualRecipeDetails = createAsyncThunk('stock/individualRecipeDetails', async (props) => {
  const response = await apiURL.get(`/ingredient/recipeIngredients/${props.departmentId}/${props.id}`);
  return response.data;
});

export const deleteIndividualRecipeIngredient = createAsyncThunk(
  'stock/deleteIndividualRecipeIngredient',
  async (props, thunkAPI) => {
    try {
      const response = await apiURL.delete(`/ingredient/recipeIngredient/${props.departmentId}/${props.ingredientId}`);
      if (response.status === 200) {
        toast.success('Recipe Ingredient Deleted Successfully.', { autoClose: successToastTime });
      }
      thunkAPI.dispatch(getIndividualRecipeDetails({ departmentId: props?.departmentId, id: props?.id }));
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const getRecipeIngredientListWithPrice = createAsyncThunk(
  'stock/recipeIngredientListWithPrice',
  async (props) => {
    const response = await apiURL.get(`/ingredient/getRecipesWithPricePerKg/${props.departmentId}`);
    return response.data;
  }
);

const ingredientSlice = createSlice({
  name: 'stocks',
  initialState: {
    loading: false,
    postLoading: false,
    menuLoading: false,
    recipeLoading: false,
    allIngredients: {},
    ingredientDetails: {},
    ingredientMenus: {},

    recipeIngredientList: {},
    individualRecipeDetails: [],

    allIngredientsWithPrice: {},
    allRecipeIngredientsWithPrice: {},
  },

  reducers: {
    clearIngredientDetails(state) {
      state.ingredientDetails = {};
    },
  },

  extraReducers: {
    [getIngredientListWithPrice.pending]: (state) => {
      state.loading = true;
    },
    [getIngredientListWithPrice.fulfilled]: (state, action) => {
      state.loading = false;
      state.allIngredientsWithPrice = action.payload;
    },
    [getIngredientListWithPrice.rejected]: (state) => {
      state.loading = false;
    },
    [getRecipeIngredientListWithPrice.pending]: (state) => {
      state.loading = true;
    },
    [getRecipeIngredientListWithPrice.fulfilled]: (state, action) => {
      state.loading = false;
      state.allRecipeIngredientsWithPrice = action.payload;
    },
    [getRecipeIngredientListWithPrice.rejected]: (state) => {
      state.loading = false;
    },

    [getIngredientList.pending]: (state) => {
      state.loading = true;
    },
    [getIngredientList.fulfilled]: (state, action) => {
      state.loading = false;
      state.allIngredients = action.payload;
    },
    [getIngredientList.rejected]: (state) => {
      state.loading = false;
    },

    [getSearchIngredientList.pending]: (state) => {
      state.loading = true;
    },
    [getSearchIngredientList.fulfilled]: (state, action) => {
      state.loading = false;
      state.allIngredients = action.payload;
    },
    [getSearchIngredientList.rejected]: (state) => {
      state.loading = false;
    },

    [createIngredients.pending]: (state) => {
      state.postLoading = true;
    },
    [createIngredients.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [createIngredients.rejected]: (state) => {
      state.postLoading = false;
    },

    [updateIngredients.pending]: (state) => {
      state.postLoading = true;
    },
    [updateIngredients.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateIngredients.rejected]: (state) => {
      state.postLoading = false;
    },

    [getIngredientDetails.pending]: (state) => {
      state.postLoading = true;
    },
    [getIngredientDetails.fulfilled]: (state, action) => {
      state.postLoading = false;
      state.ingredientDetails = action.payload;
    },
    [getIngredientDetails.rejected]: (state) => {
      state.postLoading = false;
    },

    [getIngredientMenusList.pending]: (state) => {
      state.menuLoading = true;
    },
    [getIngredientMenusList.fulfilled]: (state, action) => {
      state.menuLoading = false;
      state.ingredientMenus = action.payload;
    },
    [getIngredientMenusList.rejected]: (state) => {
      state.menuLoading = false;
    },

    [updateIngredientMenus.pending]: (state) => {
      state.postLoading = true;
    },
    [updateIngredientMenus.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateIngredientMenus.rejected]: (state) => {
      state.postLoading = false;
    },

    [getRecipeIngredientList.pending]: (state) => {
      state.recipeLoading = true;
      state.recipeIngredientList = {};
    },
    [getRecipeIngredientList.fulfilled]: (state, action) => {
      state.recipeLoading = false;
      state.recipeIngredientList = action.payload;
    },
    [getRecipeIngredientList.rejected]: (state) => {
      state.recipeLoading = false;
    },

    [createNewRecipeIngredient.pending]: (state) => {
      state.postLoading = true;
    },
    [createNewRecipeIngredient.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [createNewRecipeIngredient.rejected]: (state) => {
      state.postLoading = false;
    },

    [getIndividualRecipeDetails.pending]: (state) => {
      state.individualRecipeDetails = [];
    },
    [getIndividualRecipeDetails.fulfilled]: (state, action) => {
      state.individualRecipeDetails = action.payload;
    },
    [getIndividualRecipeDetails.rejected]: (state) => {
      state.individualRecipeDetails = [];
    },
  },
});

export const { clearIngredientDetails } = ingredientSlice.actions;
export default ingredientSlice.reducer;
