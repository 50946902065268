import { Box, Button } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteFoodMenu } from 'redux/slices/FoodMenuSlice';
// import FoodRecipeCreateUpdate from 'sections/@dashboard/foodMenuAndRecipe/FoodRecipe/FoodRecipeCreateUpdate';

function FoodMenuSettings({ menu }) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const handleClose = () => {
    setOpen(false);
  };
  const DeleteMenuHandler = () => {
    dispatch(deleteFoodMenu({ departmentId, menuId: menu?.id }));
  };

  return (
    <Box sx={{ mt: '1rem' }}>
      <Button onClick={() => setOpen(true)} variant="contained">
        Delete {menu?.name} Menu
      </Button>

      {open && (
        <CustomDeleteDialog
          title={`Are you sure you want to delete "${menu?.name}" Menu`}
          handleClose={handleClose}
          open={open}
          onAccept={DeleteMenuHandler}
        />
      )}
    </Box>
  );
}

export default FoodMenuSettings;
