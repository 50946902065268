import { Button, Stack, Tab, Tabs, TextField } from '@mui/material';
import CustomDatePicker from 'components/CustomComponents/CustomDatePicker';
import Label from 'components/Label';
import { FormProvider } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import useTabs from 'hooks/useTabs';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  changeTimeUserFilterStatus,
  getTimeClockDay,
  setChoosenTimeDate,
  updateTimeUserFilterData,
} from 'redux/slices/timeSlice';
import { useDispatch, useSelector } from 'redux/store';
import { convertDatetoString } from 'utils/customDateAndTimeFormat';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const TABS = [
  { value: 'ALL', label: 'all', color: 'info' },
  { value: 'STARTED', label: 'started', color: 'info' },
  { value: 'COMPLETED', label: 'completed', color: 'success' },
];

function TimeDurationClockFilter() {
  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs('ALL');
  const { timeUser } = useSelector((state) => state.time);
  const departmentId = useGetDepartmentId();
  const { timeDT } = useSelector((state) => state.user);
  const [choosenDate, setChoosenDate] = useState(moment(timeDT).format('YYYY-MM-DD'));
  const dispatch = useDispatch();
  const defaultValues = {
    statusList: ['ALL'],
    date: convertDatetoString(new Date()),
  };
  const methods = useForm({ defaultValues });
  const { handleSubmit, getValues } = methods;

  const TimeDurationFilterHandle = (data) => {
    if (data?.statusList.includes('ALL')) {
      dispatch(getTimeClockDay({ departmentId, date: convertDatetoString(data?.date) }));
      return;
    }
    dispatch(getTimeClockDay({ ...data, departmentId, date: convertDatetoString(data?.date) }));
  };

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getTimeClockDay({ departmentId, date: convertDatetoString(choosenDate) }));
  }, [departmentId, dispatch, choosenDate]);

  useEffect(() => {
    if (!timeUser) return;
    dispatch(changeTimeUserFilterStatus(filterStatus));

    if (filterStatus === 'ALL') {
      dispatch(updateTimeUserFilterData([...timeUser]));
      return;
    }
    const filterTimeStatus = timeUser.reduce((acc, current) => {
      const currentTimeClock = current?.timeclocks;
      const findingStatus = currentTimeClock?.find((obj) => obj?.status === 'STARTED');
      if (findingStatus && filterStatus === 'STARTED') {
        return [...acc, current];
      }
      if (!findingStatus && filterStatus === 'COMPLETED') return [...acc, current];

      return [...acc];
    }, []);
    dispatch(updateTimeUserFilterData([...filterTimeStatus]));
  }, [filterStatus, dispatch, timeUser]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(TimeDurationFilterHandle)}>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={filterStatus}
        onChange={(event, newValue) => {
          onFilterStatus(event, newValue);
          methods.resetField('statusList');
          methods.setValue('statusList', [newValue]);
        }}
        sx={{ px: 2, bgcolor: 'background.neutral' }}
      >
        {TABS.map((tab) => (
          <Tab
            disableRipple
            key={tab.value}
            value={tab.value}
            icon={<Label color={tab.color}> {tab.count} </Label>}
            label={tab.label}
          />
        ))}
      </Tabs>

      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} sx={{ py: 2.5, px: 3 }}>
        <DatePicker
          disableFuture
          label="Select Date"
          value={choosenDate}
          onChange={(date) => {
            setChoosenDate(moment(date).format('YYYY-MM-DD'));
            dispatch(setChoosenTimeDate(moment(date).format('YYYY-MM-DD')));
          }}
          renderInput={(params) => <TextField {...params} error={false} />}
        />
      </Stack>
    </FormProvider>
  );
}

export default TimeDurationClockFilter;
