import Page from 'components/Page';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getFoodCategory } from 'redux/slices/menuEngineeringSlice';
import FoodCogsReportHeader from './FoodCogsReportHeader';
import FoodCogsReportBody from './FoodCogsReportBody';

function FoodCogsReport() {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getFoodCategory({ departmentId }));
  }, [departmentId, dispatch]);

  return (
    <Page title="Food Cogs Report">
      <FoodCogsReportHeader />
      <FoodCogsReportBody />
    </Page>
  );
}

export default FoodCogsReport;
