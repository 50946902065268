import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import Iconify from 'components/Iconify';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  addStockToIngredient,
  decreaseStockPriority,
  getStock,
  increaseStockPriority,
  removeStockFromIngredient,
} from 'redux/slices/stockSlice';
import { toast } from 'react-toastify';
import Scrollbar from 'components/Scrollbar';

const TABLE_HEAD = [
  { id: 1, name: 'UID' },
  { id: 2, name: 'Brand (Description)' },
  { id: 3, name: 'Current Qty' },
  { id: 4, name: 'Weight' },
  { id: 5, name: 'Unit Price' },
  { id: 6, name: 'Total' },
  { id: 7, name: 'Priority' },
];

IngredientAddStockForm.propTypes = {
  currentData: PropTypes.number,
};

function IngredientAddStockForm({ currentData }) {
  const { control } = useFormContext();
  const getDepartment = useGetDepartmentId();
  const [first, setFirst] = useState(null);
  const { allStocks } = useSelector((state) => state.stocks);

  const dispatch = useDispatch();

  const { fields, append, remove, swap } = useFieldArray({
    control,
    name: 'stockItems',
    keyName: 'stockFieldId',
  });

  // stock add to ingredient
  const StockAddedHandler = (data) => {
    const findStock = fields?.find((el) => el.id === data.id);
    if (findStock) return toast.warning('Stock already added');

    const finalData = {
      id: currentData,
      stockItemId: data.id,
      departmentId: getDepartment,
    };
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        append(data);
        setFirst('');
      }
    };
    dispatch(addStockToIngredient({ finalData, isSuccess: isSuccessful }));
  };

  // stock remove from ingredient
  const StockRemoveHandler = (index) => {
    const finalData = {
      id: currentData,
      stockItemId: fields[index].id,
      departmentId: getDepartment,
    };
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        remove(index);
      }
    };
    dispatch(removeStockFromIngredient({ finalData, isSuccess: isSuccessful }));
  };

  // stock priority decrease
  const StockPriorityDownHandler = (index) => {
    const finalData = {
      id: currentData,
      stockItemId: fields[index].id,
      departmentId: getDepartment,
    };
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        swap(index, index + 1);
      }
    };
    dispatch(decreaseStockPriority({ finalData, isSuccess: isSuccessful }));
  };

  // stock priority increase
  const StockPriorityUpHandler = (index) => {
    const finalData = {
      id: currentData,
      stockItemId: fields[index].id,
      departmentId: getDepartment,
    };
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        swap(index, index - 1);
      }
    };
    dispatch(increaseStockPriority({ finalData, isSuccess: isSuccessful }));
  };

  useEffect(() => {
    if (!getDepartment) return;
    const promise = dispatch(getStock({ departmentId: getDepartment, searchTxt: first, pageNo: 1, pageSize: 3 }));
    return () => promise.abort();
  }, [dispatch, getDepartment, first]);

  return (
    <Stack mt="1rem" mx=".5rem">
      {/* <h3>Stock Items</h3> */}
      <Stack my="1rem">
        <TextField fullWidth label="Search Stock" value={first} onChange={(event) => setFirst(event.target.value)} />
        {/* <RHFTextField
          name="search"
          placeholder={'Search Stock'}
          onChange={(event) => setFirst(event.target.value)}
          label="Search Stock"
        /> */}
        <Stack py="0.5rem" bgcolor={'rgba(145, 158, 171, 0.12)'} px="0.5rem">
          {allStocks?.stockItems?.map((obj) => (
            <Stack
              // style={{ width: '60%' }}
              key={obj?.id}
              py={'0.3rem'}
              px={'0.3rem'}
              my={'0.1rem'}
              divider={<Divider />}
              direction="row"
              alignItems={'center'}
              gap="0.5rem"
              borderRadius={'10px'}
              sx={{ cursor: 'pointer', ':hover': { background: 'white' } }}
              onClick={() => StockAddedHandler(obj)}
            >
              {obj?.productDescription}
              <Divider />
            </Stack>
          ))}
        </Stack>
      </Stack>
      <Stack className="stackOrderModalContent2">
        <Scrollbar>
          <TableContainer sx={{ minWidth: 1100 }}>
            <Table stickyHeader style={{ maxWidth: '800px' }} size="small">
              <TableHead>
                <TableRow>
                  {TABLE_HEAD.map((obj) => (
                    <TableCell key={obj.id}>{obj?.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {fields?.map((obj, index) => {
                  const total = Number(obj?.currentQty) * Number(obj?.unitPrice);
                  return (
                    <TableRow key={index} sx={{ borderBottom: '1px solid #F4F6F8' }}>
                      <TableCell>{obj?.externalUID}</TableCell>
                      <TableCell>
                        <p>{obj?.brand}</p>
                        <span>{obj?.productDescription}</span>
                      </TableCell>
                      <TableCell>{obj?.currentQty}</TableCell>
                      <TableCell>
                        {obj?.weight} {obj?.weightType}
                      </TableCell>

                      <TableCell>{obj?.unitPrice}</TableCell>
                      <TableCell>$ {total}</TableCell>
                      <TableCell>
                        {index === 0 && fields?.length === 1 && ''}
                        {index === 0 && fields?.length > 1 && (
                          <Icon
                            icon="raphael:arrowdown"
                            width="20"
                            height="20"
                            color="red"
                            onClick={() => StockPriorityDownHandler(index)}
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                        {index === fields?.length - 1 && fields?.length > 1 && (
                          <Icon
                            icon="raphael:arrowup"
                            width="20"
                            height="20"
                            color="green"
                            onClick={() => StockPriorityUpHandler(index)}
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                        {index !== 0 && index !== fields?.length - 1 && fields?.length > 1 && (
                          <>
                            <Icon
                              icon="raphael:arrowdown"
                              width="20"
                              height="20"
                              color="red"
                              onClick={() => StockPriorityDownHandler(index)}
                              style={{ cursor: 'pointer' }}
                            />
                            <Icon
                              icon="raphael:arrowup"
                              width="20"
                              height="20"
                              color="green"
                              onClick={() => StockPriorityUpHandler(index)}
                              style={{ cursor: 'pointer' }}
                            />
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          variant="text"
                          color="error"
                          onClick={() => StockRemoveHandler(index)}
                          startIcon={<Iconify icon="material-symbols:delete-rounded" />}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Stack>
    </Stack>
  );
}

export default IngredientAddStockForm;
