import { Button, Card, Divider, Grid, InputAdornment, Stack } from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import React, { memo } from 'react';

import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';

import CashDrawerSection from './CashDrawerSection';
import SalesCashCalculationSection from './SalesCashCalculationSection';
import SalesPayoutSection from './SalesPayoutSection';
import PlatformSalesSection from './PlatformSalesSection';
import SalesAdditionalInfoSection from './SalesAdditionalInfoSection';

import DeliveryEntrysSection from './DeliveryEntrysSection';
import CashDrawerStoreSalesInformation from './CashDrawerStoreSalesInformation';

function AddEditSales() {
  const { dailySales, loading: salesListLoading } = useSelector((state) => state.sales);
  const { loading } = useSelector((state) => state.store);
  const isEmpty = dailySales?.id === undefined;

  const {
    watch,
    formState: { isSubmitting },
  } = useFormContext();
  const noteText = watch('note');

  // useEffect(() => {
  //   // if (!dailySales?.id) reset({ ...defaultValues });
  //   reset({ ...dailySales, note: dailySales?.note || '' });
  // }, [dailySales, reset, defaultValues]);

  return (
    <>
      {salesListLoading || loading ? (
        <CustomLoadingScreen height="40vh" />
      ) : (
        <Card>
          <Stack spacing={1}>
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} style={{ marginTop: '20px', marginLeft: '20px' }}>
                  <Stack>
                    <RHFTextField
                      name="storeSales"
                      label="Store Sales"
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                    <CashDrawerStoreSalesInformation />
                  </Stack>
                </Grid>
              </Grid>
              <CashDrawerSection />
              <Divider sx={{ my: 3, borderStyle: 'dashed' }} />
              <SalesAdditionalInfoSection />

              <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

              <SalesPayoutSection />

              <PlatformSalesSection />
              <SalesCashCalculationSection />
              {/* <Grid container spacing={2}>
                <Grid item xs={12} sm={4} style={{ marginTop: '20px', marginLeft: '20px' }}>
                  <RHFTextField
                    name="totalDeliveryCount"
                    label="Total Delivery Count"
                    InputLabelProps={{ shrink: true }}
                    disabled
                  />
                </Grid>
              </Grid> */}
              <DeliveryEntrysSection />
            </>
          </Stack>
          <Divider sx={{ my: 3, borderStyle: 'dashed' }} />
          <Stack style={{ marginLeft: '20px', marginRight: '20px' }}>
            <RHFTextField
              name="note"
              label="Note"
              multiline
              minRows={3}
              placeholder="Note with maximum 256 character"
              helperText={`${noteText?.length}/256`}
            />
          </Stack>
          <Divider sx={{ my: 3, borderStyle: 'dashed' }} />
          <Stack alignItems={'flex-end'}>
            <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
              {isEmpty ? 'Create Sales' : 'Update Sales'}
            </Button>
          </Stack>
        </Card>
      )}
    </>
  );
}

export default memo(AddEditSales);
