import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIndividualMenuSize } from 'redux/slices/menuERSizeAndOptionSlice';
import MenuSizeForm from 'sections/@dashboard/menuEngineering/menuSize/MenuSizeForm';

function MenuSizeEdit({ openEdit, setOpenEdit, selectedRow }) {
  const { singleMenuSizeLoading } = useSelector((state) => state.menuERSizeAndOption);

  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  useEffect(() => {
    if (!selectedRow) return;
    if (!departmentId) return;
    dispatch(getIndividualMenuSize({ sizeGroupId: selectedRow?.id, departmentId }));
  }, [selectedRow, dispatch, departmentId]);

  return (
    <CustomDrawer
      open={openEdit}
      setOpen={setOpenEdit}
      title={`Update Menu Size`}
      PaperProps={{ sx: { innerWidth: '50%' } }}
    >
      <Box style={{ width: '600px' }}>
        {singleMenuSizeLoading ? (
          <CustomLoadingScreen height="30vh" />
        ) : (
          <MenuSizeForm handleClose={setOpenEdit} selectedRow={selectedRow} isEdit />
        )}
      </Box>
    </CustomDrawer>
  );
}

MenuSizeEdit.propTypes = {
  openEdit: PropTypes.bool,
  setOpenEdit: PropTypes.func,
  selectedRow: PropTypes.object,
};

export default MenuSizeEdit;
