import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import React from 'react';
import ChecklistCategories from './ChecklistCategories';

function CategoryManageDrawer({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <CustomDrawer
      open={open}
      setOpen={setOpen}
      title={`Manage Category`}
      PaperProps={{ sx: { width: '500px' } }}
      handleClose={handleClose}
    >
      <ChecklistCategories />
    </CustomDrawer>
  );
}

export default CategoryManageDrawer;
