import { Button, Grid, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getSignedAgreementOfUser } from 'redux/slices/userSlice';
import ViewPaperworkDetailsDocumentsInfo from './ViewPaperworkDetailsDocumentsInfo';

function ViewPaperworkDetails() {
  const { getValues, watch } = useFormContext();
  const values = getValues();

  const getSignUrl = getValues('signURL') || null;

  const getVisaValues = getValues('visaURL') || '';
  const getWorkingRightsValues = getValues('workingRightsURL') || '';
  const getInsuranceValues = getValues('insuranceURL') || '';
  const getRezoPaperworkValues = getValues('rezoPaperWorkURL') || '';

  return (
    <Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} mb={2}>
        <Stack gap="0.5rem" my={1} bgcolor={'rgba(145, 158, 171, 0.12)'} borderRadius={'10px'} p="1rem">
          <Typography component="p" fontWeight="600">
            International Student:
            <Typography component="span"> {values?.isInternationalStudent ? 'Yes' : 'No'}</Typography>
          </Typography>
          {values?.isInternationalStudent && (
            <>
              <Stack
                gap="1rem"
                width="20rem"
                height="20rem"
                // borderRadius={'50%'}
                padding={'8px'}
                border="1px dashed rgba(145, 158, 171, 0.32)"
                alignItems="center"
                justifyContent={'center'}
              >
                {!values?.passportURL ? (
                  <p>No Passport Image Currently</p>
                ) : (
                  <figure>
                    <figcaption>Passport</figcaption>
                    <img src={values?.passportURL || ''} alt="passport" />
                  </figure>
                )}
              </Stack>
              <Stack direction="row" mt={2} gap={1} alignItems={'center'}>
                <Typography component="p" fontWeight="600">
                  Visa :
                </Typography>
                {getVisaValues && (
                  <a href={getVisaValues} download target="_blank" rel="noreferrer">
                    <Button variant="contained" size="small">
                      View
                    </Button>
                  </a>
                )}
              </Stack>

              <Stack direction="row" gap={1} alignItems={'center'}>
                <Typography component="p" fontWeight="600">
                  Working Right Permission :
                </Typography>
                {getWorkingRightsValues && (
                  <a href={getWorkingRightsValues} download target="_blank" rel="noreferrer">
                    <Button variant="contained" size="small">
                      View
                    </Button>
                  </a>
                )}
              </Stack>
            </>
          )}
        </Stack>
        <Stack gap="0.5rem" my={1} bgcolor={'rgba(145, 158, 171, 0.12)'} borderRadius={'10px'} p="1rem">
          <Typography component="p" fontWeight="600">
            Delivery Driver :<Typography component="span"> {values?.isDeliveryDriver ? 'Yes' : 'No'}</Typography>
          </Typography>
          {values?.isDeliveryDriver && (
            <>
              <Stack
                gap="1rem"
                width="20rem"
                height="20rem"
                // borderRadius={'50%'}
                padding={'8px'}
                border="1px dashed rgba(145, 158, 171, 0.32)"
                alignItems="center"
                justifyContent={'center'}
              >
                {!values?.drivingLicenseURL ? (
                  <p>No Driving License Image Currently</p>
                ) : (
                  <figure>
                    <figcaption>Driving License</figcaption>
                    <img src={values?.drivingLicenseURL || ''} alt="Driving License" />
                  </figure>
                )}
              </Stack>
              <Stack mt={2} direction="row" gap={1} alignItems={'center'}>
                <Typography component="p" fontWeight="600">
                  Insurance :
                </Typography>
                {getInsuranceValues && (
                  <a href={getInsuranceValues} download target="_blank" rel="noreferrer">
                    <Button variant="contained" size="small">
                      View
                    </Button>
                  </a>
                )}
              </Stack>
              <Stack direction="row" gap={1} alignItems={'center'}>
                <Typography component="p" fontWeight="600">
                  Rezo Paperwork :
                </Typography>
                {getRezoPaperworkValues && (
                  <a href={getRezoPaperworkValues} download target="_blank" rel="noreferrer">
                    <Button variant="contained" size="small">
                      View
                    </Button>
                  </a>
                )}
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      {getSignUrl && (
        <Grid item xs={12} md={12}>
          <Stack m={1} p={1} bgcolor={'rgba(145, 158, 171, 0.05)'} borderRadius={'10px'}>
            <Typography py={1}>User Aggrement</Typography>
            <img src={getSignUrl} alt="Get Sign URL" />
          </Stack>
        </Grid>
      )}
      <ViewPaperworkDetailsDocumentsInfo />
    </Stack>
  );
}

export default ViewPaperworkDetails;
