import { Box, Pagination, Stack, TableCell, TableRow } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import PropTypes from 'prop-types';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIndividualUserWorkReport } from 'redux/slices/reportSlice';
import UserWorkReportDetailsTable from './UserWorkReportDetailsTable';

UserWorkReportDetailsViewDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.object,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  departmentId: PropTypes.string,
};

function UserWorkReportDetailsViewDrawer({ open, setOpen, data, startDate, endDate, departmentId }) {
  const { singleUserReport, singleReportLoading } = useSelector((state) => state.reports);

  const dispatch = useDispatch();
  const [pageNo, setPageNo] = React.useState(1);

  useEffect(() => {
    if (departmentId !== 'All') {
      dispatch(getIndividualUserWorkReport({ id: data?.id, startDate, endDate, pageNo, departmentId }));
    } else {
      dispatch(getIndividualUserWorkReport({ id: data?.id, startDate, endDate, pageNo, departmentId: null }));
    }
  }, [data?.id, dispatch, endDate, startDate, pageNo, departmentId]);

  return (
    <>
      <CustomDrawer
        headerIcon="bxs:user-detail"
        iconColor="#1976d2"
        iconWidth={30}
        iconHeight={30}
        open={open}
        setOpen={setOpen}
        title={` ${data?.firstName} ${data?.middleName ?? ''} ${data?.lastName}`}
      >
        {singleReportLoading ? (
          <Stack spacing={2} justifyContent={'center'} alignItems={'center'}>
            <CustomLoadingScreen height="10vh" />
          </Stack>
        ) : (
          <>
            {singleUserReport?.departments?.map((department, departmentIndex) => (
              <>
                <UserWorkReportDetailsTable
                  department={department}
                  departmentIndex={departmentIndex}
                  key={departmentIndex}
                />
              </>
            ))}
            {singleUserReport?.departments?.length !== 0 && (
              <>
                <Stack mt={2} direction={'row'}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      role="presentation"
                      style={{
                        width: '15px',
                        height: '15px',
                        borderRadius: '100%',
                        backgroundColor: '#FFFFFF',
                        marginLeft: '20px',
                      }}
                    />
                    <p style={{ marginTop: '-5px', marginLeft: '5px' }}>Monday - Friday</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      role="presentation"
                      style={{
                        width: '15px',
                        height: '15px',
                        borderRadius: '100%',
                        backgroundColor: '#FFFFE0',
                        marginLeft: '20px',
                      }}
                    />
                    <p style={{ marginTop: '-5px', marginLeft: '5px' }}>Sunday</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      role="presentation"
                      style={{
                        width: '15px',
                        height: '15px',
                        borderRadius: '100%',
                        backgroundColor: '#D8BFD8',
                        marginLeft: '20px',
                      }}
                    />
                    <p style={{ marginTop: '-5px', marginLeft: '5px' }}>Saturday</p>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div
                      role="presentation"
                      style={{
                        width: '15px',
                        height: '15px',
                        borderRadius: '100%',
                        backgroundColor: '#F08080',
                        marginLeft: '20px',
                      }}
                    />
                    <p style={{ marginTop: '-5px', marginLeft: '5px' }}>Holiday</p>
                  </div>
                </Stack>
                <Box
                  sx={{ position: 'relative', marginTop: '30px' }}
                  justifyContent={'end'}
                  alignContent="center"
                  display={'flex'}
                >
                  <Pagination
                    count={singleUserReport?.paginationInfo?.totalPages}
                    page={pageNo}
                    onChange={(e, value) => setPageNo(value)}
                  />
                </Box>
              </>
            )}

            <TableRow>
              <TableCell />
              <TableCell colSpan={2} align="left" style={{ fontWeight: 'bold', width: '200px' }}>
                Total Work Time:{' '}
              </TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>
                {data?.workTimeInMinutes ? (data?.workTimeInMinutes / 60).toFixed(2) : 0} Hrs.
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell colSpan={2} align="left" style={{ fontWeight: 'bold', width: '200px' }}>
                Driver Incentive:{' '}
              </TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>
                {data?.totalDriverIncentive ? `$ ${data?.totalDriverIncentive}` : `$ 0`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell colSpan={2} align="left" style={{ fontWeight: 'bold', width: '200px' }}>
                Total Pay:
              </TableCell>
              <TableCell align="left" style={{ fontWeight: 'bold' }}>
                {data?.totalActualPay ? `$ ${data?.totalActualPay}` : `$ 0`}
              </TableCell>
            </TableRow>
          </>
        )}
      </CustomDrawer>
    </>
  );
}

export default UserWorkReportDetailsViewDrawer;
