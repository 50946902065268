import { Button, InputAdornment, TableCell, TableRow } from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

function FoodMenuBaseIngredientFormSingleSize({ index, field }) {
  const { control, register } = useFormContext();
  const watchIngredients = useWatch({ control, name: 'ingredients.id' });

  const watchQty = useWatch({ control, name: `ingredients.menuSizes[${index}].qtyUsed` });

  const calculateCost = () => {
    const unitPrice = Number(watchIngredients?.unitPrice);

    const unitWeight = Number(watchIngredients?.unitWeight);

    const qtyUsed = Number(watchQty);

    if (!unitPrice || !unitWeight || !qtyUsed) return 0;
    return ((unitPrice / unitWeight) * (qtyUsed / 1000)).toFixed(3);
  };
  return (
    <TableRow key={index} hover>
      <TableCell>{field?.name}</TableCell>
      <TableCell align="center">
        <input type="hidden" {...register(`ingredients.menuSizes[${index}].id`)} value={field?.id} />
        <RHFTextField
          control={control}
          name={`ingredients.menuSizes[${index}].qtyUsed`}
          disabled={!watchIngredients}
          size="small"
          InputProps={{
            endAdornment: <InputAdornment position="end">gm</InputAdornment>,
          }}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ width: { md: 100 } }}
        />
      </TableCell>
      <TableCell align="center">
        <Button variant="text" disabled size="small">
          $ {calculateCost()}
        </Button>
      </TableCell>
    </TableRow>
  );
}

export default FoodMenuBaseIngredientFormSingleSize;
