import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { sum } from 'lodash';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { fCurrency } from 'utils/formatNumber';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { findFloatAmount } from 'utils/salesUtils';
import SalesCashDrawersForm from './SalesCashDrawersForm';

const MyDivider = styled(Divider)(({ thiccness, orientation }) => ({
  ...(thiccness !== undefined &&
    (orientation === 'vertical' ? { borderRightWidth: thiccness } : { borderBottomWidth: thiccness })),
}));

export default function CashDrawerSection() {
  const { storeCashDrawer, loading: storeCashDrawerLoading } = useSelector((state) => state.store);
  const findName = (item) => {
    const drawerName = storeCashDrawer?.storeCashDrawers?.find((drawer) => drawer?.id === item?.storeCashDrawerId);
    return drawerName?.title;
  };

  const { control, setValue } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: 'cashDrawers',
    keyName: 'fieldID',
  });

  const watchCashDrawer = useWatch({
    control,
    name: 'cashDrawers',
  });

  const totalOnRow = watchCashDrawer?.map(
    (item) =>
      Number(item.fiveCents * 5) +
      Number(item.tenCents * 10) +
      Number(item.twentyCents * 20) +
      Number(item.fiftyCents * 50) +
      Number(item.oneDollors * 100) +
      Number(item.twoDollors * 200) +
      Number(item.fiveDollors * 500) +
      Number(item.tenDollors * 1000) +
      Number(item.twentyDollors * 2000) +
      Number(item.fiftyDollors * 5000) +
      Number(item.hundredDollors * 10000)
  );

  const totalFloatAmount = (CashDrawersList) => {
    let floatTotal = 0;
    watchCashDrawer?.forEach((item) => {
      floatTotal += findFloatAmount(item, CashDrawersList);
    });
    return floatTotal;
  };

  const totalPrice = sum(totalOnRow);
  const actualCash = totalPrice / 100 - totalFloatAmount(storeCashDrawer?.storeCashDrawers) || 0;

  useEffect(() => {
    setValue('actualCash', actualCash);
  }, [setValue, actualCash]);

  return (
    <Box sx={{ p: 3 }}>
      {storeCashDrawerLoading ? (
        <CustomLoadingScreen />
      ) : (
        <>
          <Typography variant="h6" sx={{ color: 'text.disabled', mb: 1 }}>
            Cash Drawers:
          </Typography>
          <Stack spacing={2}>
            <Grid container spacing={2}>
              {fields?.map((item, mainIndex) => (
                <React.Fragment key={mainIndex}>
                  <Grid item xs={12} sm={5.8} md={3.9} lg={2.9} key={mainIndex}>
                    {/* <TableContainer> */}
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ minWidth: '60px', width: '80px' }}> {findName(item)}</TableCell>
                          <TableCell style={{ minWidth: '60px', width: '80px' }} align="right">
                            Qty:
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <SalesCashDrawersForm mainIndex={mainIndex} totalOnRow={totalOnRow} />
                    </Table>
                    {/* </TableContainer> */}
                  </Grid>
                  <MyDivider style={{ marginLeft: '8px' }} thiccness={4} orientation="vertical" flexItem />
                </React.Fragment>
              ))}
            </Grid>
          </Stack>
          <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

          <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'flex-start', md: 'center' }}>
            {totalPrice > 0 && (
              <>
                <Button size="small" disabled style={{ color: 'black' }}>
                  Total Amount: {fCurrency(totalPrice / 100)}
                </Button>
                <Tooltip title="Total Amount - Total Float of Drawers">
                  <Button size="small" style={{ color: `${actualCash > 0 ? 'black' : 'red'}` }}>
                    Actual Cash: {fCurrency(actualCash)}
                  </Button>
                </Tooltip>
              </>
            )}
          </Stack>
        </>
      )}
    </Box>
  );
}
