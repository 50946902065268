import { Box, Card, Grid } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import RunningCostCard from './RunningCostCard';
import RunningCostFilter from './RunningCostFilter';

const RunningCostBody = ({ choosenDate, setChoosenDate }) => {
  const { loading: runningCostLoading } = useSelector((state) => state.operational);
  return (
    <Card sx={{ p: 2 }}>
      <RunningCostFilter choosenDate={choosenDate} setChoosenDate={setChoosenDate} />

      <Box sx={{ p: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box style={{ padding: '5px 10px' }}>
              <b>
                Running Cost Details of: {moment(choosenDate).format('YYYY')}, {moment(choosenDate).format('MMM')}
              </b>
              <hr />
            </Box>
          </Grid>
        </Grid>
        {runningCostLoading ? <CustomLoadingScreen /> : <RunningCostCard choosenDate={choosenDate} />}
      </Box>
    </Card>
  );
};

export default RunningCostBody;
