import { Button, Popover, Stack } from '@mui/material';
import CustomDatePicker from 'components/CustomComponents/CustomDatePicker';
import { FormProvider } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getInsights } from 'redux/slices/reportSlice';
import { convertDatetoString } from 'utils/customDateAndTimeFormat';

function InsightReportFilter() {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const [openImportDatePop, setOpenImportDatePop] = useState(null);
  const [choosenDate, setChoosenDate] = useState('FORTNIGHT');

  const handleOpenImportPopOver = (event) => {
    setOpenImportDatePop(event.currentTarget);
  };

  const handleCloseImportPopOver = () => {
    setOpenImportDatePop(null);
  };

  const open = Boolean(openImportDatePop);
  const ids = open ? 'simple-popover' : undefined;
  const defaultValues = {
    startDate: moment(new Date()).subtract(15, 'days').toDate(),
    endDate: new Date(),
  };
  const methods = useForm({ defaultValues });
  const { handleSubmit, watch } = methods;
  const watchStartDate = watch('startDate');

  const ReportFilterHandler = (data) => {
    const finalData = {
      departmentId,
      startDate: convertDatetoString(data?.startDate),
      endDate: convertDatetoString(data?.endDate),
    };
    dispatch(getInsights(finalData));
    handleCloseImportPopOver();
  };
  useEffect(() => {
    if (!departmentId) return;
    dispatch(
      getInsights({
        departmentId,
        startDate: moment(new Date()).subtract(15, 'days').format('YYYY-MM-DD'),
        endDate: moment(new Date()).format('YYYY-MM-DD'),
      })
    );
  }, [dispatch, departmentId]);

  const insightReportFilter = (status) => {
    let startDate;
    let endDate;
    setChoosenDate(status);
    switch (status) {
      case 'DAYS':
        startDate = new Date();
        endDate = startDate;
        break;
      case 'YESTERDAY':
        startDate = moment().subtract(1, 'days').toDate();
        endDate = startDate;
        break;
      case 'WEEK':
        startDate = moment().startOf('isoWeek').toDate();
        endDate = new Date();
        break;
      case 'LAST_WEEK':
        startDate = moment().subtract(1, 'weeks').startOf('isoWeek').toDate();
        endDate = moment().subtract(1, 'weeks').endOf('isoWeek').toDate();
        break;
      case 'FORTNIGHT':
        startDate = moment().subtract(14, 'days');
        endDate = new Date();
        break;
      case 'MONTH':
        startDate = moment(new Date()).subtract(30, 'days');
        endDate = new Date();
        break;
      case 'LAST_MONTH':
        startDate = moment().subtract(1, 'months').startOf('month').toDate();
        endDate = moment().subtract(1, 'months').endOf('month').toDate();
        break;

      default:
        break;
    }
    dispatch(
      getInsights({
        departmentId,
        startDate: convertDatetoString(startDate),
        endDate: convertDatetoString(endDate),
      })
    );
  };
  return (
    <Stack m="1rem">
      <FormProvider methods={methods} onSubmit={handleSubmit(ReportFilterHandler)}>
        <Stack justifyContent={'center'} direction="row" gap="1rem">
          <Button
            variant={choosenDate === 'DAYS' ? 'contained' : 'outlined'}
            onClick={() => insightReportFilter('DAYS')}
          >
            Today
          </Button>
          <Button
            variant={choosenDate === 'YESTERDAY' ? 'contained' : 'outlined'}
            onClick={() => insightReportFilter('YESTERDAY')}
          >
            Yesterday
          </Button>
          <Button
            variant={choosenDate === 'WEEK' ? 'contained' : 'outlined'}
            onClick={() => insightReportFilter('WEEK')}
          >
            This Week
          </Button>
          <Button
            variant={choosenDate === 'LAST_WEEK' ? 'contained' : 'outlined'}
            onClick={() => insightReportFilter('LAST_WEEK')}
          >
            Last Week
          </Button>
          <Button
            variant={choosenDate === 'FORTNIGHT' ? 'contained' : 'outlined'}
            onClick={() => insightReportFilter('FORTNIGHT')}
          >
            Fortnight
          </Button>
          <Button
            variant={choosenDate === 'MONTH' ? 'contained' : 'outlined'}
            onClick={() => insightReportFilter('MONTH')}
          >
            Last 30 Days
          </Button>
          <Button
            variant={choosenDate === 'LAST_MONTH' ? 'contained' : 'outlined'}
            onClick={() => insightReportFilter('LAST_MONTH')}
          >
            Prev Month
          </Button>
          <Button onClick={handleOpenImportPopOver}>Custom</Button>
        </Stack>
        <Popover
          id={ids}
          open={open}
          anchorEl={openImportDatePop}
          onClose={handleCloseImportPopOver}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Stack alignItems={'center'} direction="column" gap="1rem" m="1rem">
            <CustomDatePicker
              name="startDate"
              inputFormat="dd/MMM/yyyy"
              fieldWidth={'max-content'}
              label="Start Date"
              disableFuture
            />
            <CustomDatePicker
              name="endDate"
              inputFormat="dd/MMM/yyyy"
              fullWidth={false}
              fieldWidth="max-content"
              label="End Date"
              disableFuture
              minDate={watchStartDate}
            />
            <Stack width="100%">
              <Button variant="contained" type="button" onClick={handleSubmit(ReportFilterHandler)}>
                Filter
              </Button>
            </Stack>
          </Stack>
        </Popover>
      </FormProvider>
    </Stack>
  );
}

export default InsightReportFilter;
