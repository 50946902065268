import {
  Autocomplete,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import Scrollbar from 'components/Scrollbar';
import { RHFCheckbox } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { getIngredientListWithPrice, getRecipeIngredientListWithPrice } from 'redux/slices/ingredientSlice';
import { updateFoodMenuBaseIngredients } from 'redux/slices/menuEngineeringSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import FoodMenuBaseIngredientFormSingleSize from './FoodMenuBaseIngredientFormSingleSize';

function FoodMenuBaseIngredientAddForm({ open, setOpen, menu }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const defaultValues = {};

  const schema = yup.object().shape({
    ingredients: yup.object().shape({
      menuSizes: yup.array().of(
        yup.object().shape({
          qtyUsed: yup.number().transform((curr, orig) => (orig === '' ? 0 : curr)),
        })
      ),
    }),
  });
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { reset, handleSubmit, control, setValue } = methods;
  const { allIngredientsWithPrice, allRecipeIngredientsWithPrice } = useSelector((state) => state.ingredients);
  const { foodMenuSizes, foodMenuSizesLoading } = useSelector((state) => state.menuEngineering);

  const CreateBaseIngredient = (data) => {
    const finalData = {
      departmentId,
      menuId: menu?.id,
      ingredients: [{ id: data?.ingredients?.id?.id, menuSizes: data?.ingredients?.menuSizes }],
    };
    dispatch(updateFoodMenuBaseIngredients({ finalData, menuId: menu?.id, setOpen }));
  };

  const { fields } = useFieldArray({
    control,
    name: 'ingredients.menuSizes',
  });

  // const watchIngredients = methods.watch('ingredients.id');

  useEffect(() => {
    if (!departmentId || !menu) return;
    dispatch(getIngredientListWithPrice({ departmentId }));
    dispatch(getRecipeIngredientListWithPrice({ departmentId }));
  }, [departmentId, dispatch, menu]);

  useEffect(() => {
    if (!foodMenuSizes) return;
    reset({
      ingredients: { menuSizes: foodMenuSizes?.map((size) => ({ id: size?.id, name: size?.sizeName, qtyUsed: 0 })) },
    });
  }, [foodMenuSizes, menu, reset]);

  const watchRecipe = useWatch({ control, name: 'isRecipe' });

  useEffect(() => {
    setValue('ingredients.id', null);
  }, [setValue, watchRecipe]);

  console.log(methods.watch());
  return (
    <CustomOutletModal open={open} onClose={() => setOpen(false)} zIndex="1600" title="Add Base Ingredient">
      <FormProvider {...methods}>
        <Stack spacing={2}>
          <Stack display={'flex'} flexDirection={'row'}>
            <Stack>
              {watchRecipe ? (
                <Controller
                  control={control}
                  name={`ingredients.id`}
                  render={({ field: { onChange, ...field } }) => (
                    <Autocomplete
                      label="Select Recipe"
                      disableClearable
                      onChange={(event, data, reason, details) => {
                        onChange(data);
                      }}
                      getOptionLabel={(option) => option?.name}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      {...field}
                      options={allRecipeIngredientsWithPrice?.ingredients || []}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Recipe" style={{ width: '15rem' }} />
                      )}
                    />
                  )}
                />
              ) : (
                <Controller
                  control={control}
                  name={`ingredients.id`}
                  render={({ field: { onChange, ...field } }) => (
                    <Autocomplete
                      label="Select Ingredient"
                      disableClearable
                      onChange={(event, data, reason, details) => {
                        onChange(data);
                      }}
                      getOptionLabel={(option) => option?.name}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      {...field}
                      options={allIngredientsWithPrice?.ingredients || []}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Ingredient" style={{ width: '15rem' }} />
                      )}
                    />
                  )}
                />
              )}
            </Stack>
            <Stack flexDirection={'row'} alignItems="center" justifyContent={'flex-end'} style={{ marginLeft: '20px' }}>
              <RHFCheckbox name={`isRecipe`} style={{ marginRight: '-15px' }} />
              Recipe
            </Stack>
          </Stack>

          <Scrollbar>
            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Sizes</TableCell>
                  <TableCell align="center">Qty</TableCell>
                  <TableCell align="center">Cost </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {foodMenuSizesLoading ? (
                  <CustomLoadingScreen height="20vh" />
                ) : (
                  <>
                    {fields?.map((field, index) => (
                      <FoodMenuBaseIngredientFormSingleSize key={index} field={field} index={index} />
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </Scrollbar>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button color="error" variant="outlined" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit(CreateBaseIngredient)}>
              Save
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </CustomOutletModal>
  );
}

export default FoodMenuBaseIngredientAddForm;
