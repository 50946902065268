import { Card, Grid, Stack } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { RHFTextField } from 'components/hook-form';
import React, { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import RHFText from 'sections/@dashboard/user/viewDetails/RHFText';
import MenuOptionImageItem from './MenuOptionImageItem';

function MenuOptionImage({ selectedRow }) {
  const { individualMenuOption, singleMenuOptionLoading } = useSelector((state) => state.menuERSizeAndOption);
  console.log('🚀 => MenuOptionImage => individualMenuOption=>', individualMenuOption);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { control, reset } = methods;

  const { fields } = useFieldArray({
    control,
    name: 'options',
    keyName: 'optionsId',
  });

  useEffect(() => {
    if (!individualMenuOption.length) return;
    const menuOptions = individualMenuOption?.map((item) => ({
      ...item,
      ingredientId: item?.ingredient,
      isRecipe: item?.ingredient ? item?.ingredient?.type !== 'SINGLE' : false,
    }));
    reset({ name: selectedRow?.name, options: menuOptions });
  }, [individualMenuOption, reset, selectedRow]);

  console.log(methods.watch());

  return (
    <>
      <FormProvider {...methods}>
        {singleMenuOptionLoading ? (
          <CustomLoadingScreen height="20vh" />
        ) : (
          <>
            <Stack my="1rem" gap="1rem">
              <RHFText disabled name="name" />
              <Grid container spacing={2}>
                {fields?.map((item, index) => (
                  <MenuOptionImageItem key={item.id} item={item} index={index} selectedRow={selectedRow} />
                ))}
              </Grid>
            </Stack>
          </>
        )}
      </FormProvider>
    </>
  );
}

export default MenuOptionImage;
