import { InputAdornment, TextField } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

function FoodCategoryIndividualSizeTargetPercent() {
  const { control, setValue, getValues } = useFormContext();
  const onChangeTargetPercent = () => {
    const cogs = getValues(`menuSizes.cogs`);
    const targetPercent = getValues(`menuSizes.targetPercent`);
    const sellingPrice = getValues(`menuSizes.sellingPrice`);
    const target = targetPercent ? (Number(cogs) * 100) / ((Number(targetPercent) * 10) / 11) : 0;
    const difference = target - sellingPrice;
    const differencePercent = (difference * 100) / sellingPrice || 0;

    const targetGp = target > 0 ? (target * 10) / 11 - cogs : 0;
    const targetGpPercent = target > 0 ? (cogs * 100) / ((target * 10) / 11) : 0;

    setValue(`menuSizes.targetGp`, targetGp);
    setValue(`menuSizes.targetGpPercent`, targetGpPercent);

    setValue(`menuSizes.differencePercent`, differencePercent);
    setValue(`menuSizes.difference`, difference);

    setValue(`menuSizes.target`, target);
  };
  return (
    <Controller
      name={`menuSizes.targetPercent`}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          onChange={(event) => {
            setValue(`menuSizes.targetPercent`, event.target.value);
            onChangeTargetPercent();
          }}
          InputProps={{
            style: { textAlign: 'center', fontWeight: 'normal', fontSize: '14px' },
            endAdornment: (
              <InputAdornment position="end">
                <span style={{ fontSize: '15px' }}>%</span>
              </InputAdornment>
            ),
          }}
          id="filled-basic"
          variant={'standard'}
          error={!!error}
          helperText={error?.message}
          sx={{ width: '60px' }}
        />
      )}
    />
  );
}

export default FoodCategoryIndividualSizeTargetPercent;
