import { Box, Tooltip } from '@mui/material';
import Iconify from 'components/Iconify';
import React, { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { findTotalofEachWeeks, findTotalofEachWeeksforQuickSummary } from 'utils/roasterFeatureUtils';

function QuickSummaryRoasterAllUsersTotalInfo({ today, index }) {
  const [getTotal, setGetTotal] = useState();
  console.log('🚀 => QuickSummaryRoasterAllUsersTotalInfo => getTotal=>', getTotal);

  const { control, watch } = useFormContext();
  const values = useWatch({
    control,
    name: 'users',
  });
  useEffect(() => {
    if (!values?.length) return;
    const getTotalofEverything = findTotalofEachWeeksforQuickSummary(today, values);
    setGetTotal(getTotalofEverything);
  }, [values, today]);

  const findTotalWorkTime = (totalDate) => {
    if (!totalDate) return '0H 0M';
    const hours = Math.floor(totalDate / 1000 / 60 / 60);
    const minutes = Math.ceil((totalDate / 1000 / 60) % 60);
    if (minutes > 59) return `${hours + 1}H 0M`;
    return `${hours}H ${minutes}M`;
  };

  return (
    <Box className="roasterTotalData">
      <div style={{ padding: '0', display: 'flex', justifyContent: 'center' }}>
        <Tooltip title="Total Cost">
          <Box style={{ display: 'inline-flex', marginTop: '1px' }}>
            <Box>
              <Iconify icon="ic:baseline-attach-money" width={15} />
            </Box>
          </Box>
        </Tooltip>
        <p style={{ display: 'inline-flex', fontWeight: '600' }}>{Number(getTotal?.totalPrice ?? 0).toFixed(2)}</p>
        <Tooltip title="Total Hours">
          <Box style={{ display: 'inline-flex', marginLeft: '7px', marginTop: '1px' }}>
            <Box>
              <Iconify icon="material-symbols:nest-clock-farsight-analog-outline" width={15} />
            </Box>
          </Box>
        </Tooltip>
        <p style={{ display: 'inline-flex', fontWeight: '600' }}>{findTotalWorkTime(getTotal?.totalDate)}</p>
      </div>
    </Box>
  );
}

export default QuickSummaryRoasterAllUsersTotalInfo;
