import { Box, Card, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePosConfigCurrentTab } from 'redux/slices/posConfigSlice';
import PosDeliveryZoneIndex from './deliveryZone';
import PosSuburbIndex from './suburb';

function PosConfigBody() {
  const { currentTab } = useSelector((state) => state.posConfig);
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    dispatch(changePosConfigCurrentTab(newValue));
  };
  return (
    <Card>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingLeft: '0.7rem' }}>
        <Tabs value={currentTab} onChange={handleChange} aria-label="Stock and Order Tabs">
          <Tab label="Delivery Zone" id="0" />
          <Tab label="Suburbs" id="1" />
          {/* <Tab label="Street" id="2" /> */}
        </Tabs>
      </Box>
      {currentTab === 0 && <PosDeliveryZoneIndex />}
      {currentTab === 1 && <PosSuburbIndex />}
      {/* {currentTab === 2 && <PosStreetIndex />} */}
    </Card>
  );
}

export default PosConfigBody;
