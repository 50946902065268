import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import TimeClockAccordion from './TimeClockAccordion/TimeClockAccordion';

function TimeClockTableList() {
  const { allStaffClockEvents } = useSelector((state) => state.time);
  const methods = useForm();
  const { reset, control } = methods;

  const fields = useWatch({
    control,
    name: 'data',
  });

  useEffect(() => {
    if (!allStaffClockEvents?.length) return;
    reset({ data: allStaffClockEvents });
  }, [allStaffClockEvents, reset]);
  return (
    <FormProvider {...methods}>
      {fields?.map((user, index) => (
        <>
          <TimeClockAccordion key={index} user={user} userIndex={index} />
        </>
      ))}
    </FormProvider>
  );
}

export default TimeClockTableList;
