import { TableBody, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import IndividualSizeTarget from './IndividualSizeTarget';
import IndividualSizeTargetPercent from './IndividualSizeTargetPercent';

function FoodMenuCogsTableBody({ menu }) {
  const { foodCogsReport, postLoading } = useSelector((state) => state.menuEngineering);
  const { control } = useFormContext();
  const basePrice = menu?.basePrice;

  const watchValues = useWatch({ control, name: 'menuSizes' });

  return (
    <TableBody>
      <TableRow hover>
        <TableCell>Menu Topins:</TableCell>
        {watchValues?.map((row, index) => (
          <TableCell key={index} align="center">
            $ {Number(row?.cogsMenuTopping)?.toFixed(2) ?? '0.00'}
          </TableCell>
        ))}
      </TableRow>
      <TableRow hover>
        <TableCell>Core Ingredients:</TableCell>
        {watchValues?.map((row, index) => (
          <TableCell key={index} align="center">
            $ {Number(row?.cogsCoreIngredient)?.toFixed(2) ?? '0.00'}
          </TableCell>
        ))}
      </TableRow>
      <TableRow hover>
        <TableCell>Menu Combo:</TableCell>
        {watchValues?.map((row, index) => (
          <TableCell key={index} align="center">
            $ {Number(row?.cogsCombo)?.toFixed(2) ?? '0.00'}
          </TableCell>
        ))}
      </TableRow>
      <TableRow hover>
        <TableCell>Total Cogs: </TableCell>
        {watchValues?.map((row, index) => (
          <TableCell key={index} align="center">
            $ {Number(row?.totalCogs)?.toFixed(2) ?? '0.00'}
          </TableCell>
        ))}
      </TableRow>

      <TableRow hover>
        <TableCell>Selling Price:</TableCell>
        {watchValues?.map((row, index) => (
          <TableCell key={index} align="center">
            $ {Number(row?.sp)?.toFixed(2) ?? '0.00'}
          </TableCell>
        ))}
      </TableRow>

      <TableRow hover>
        <TableCell>% Cogs:</TableCell>
        {watchValues?.map((row, index) => (
          <TableCell key={index} align="center">
            {Number(row?.cogsPercent)?.toFixed(2) ?? '0.00'} %
          </TableCell>
        ))}
      </TableRow>

      <TableRow hover>
        <TableCell>Gross Profit:</TableCell>
        {watchValues?.map((row, index) => (
          <TableCell key={index} align="center">
            $ {Number(row?.grossProfit)?.toFixed(2) ?? '0.00'}
          </TableCell>
        ))}
      </TableRow>

      <TableRow hover>
        <TableCell>% Gross Profit:</TableCell>
        {watchValues?.map((row, index) => (
          <TableCell key={index} align="center">
            {Number(row?.grossProfitPercent)?.toFixed(2) ?? '0.00'} %
          </TableCell>
        ))}
      </TableRow>

      <TableRow hover>
        <TableCell>Target:</TableCell>

        {foodCogsReport?.menuSizes?.map((size, index) => (
          <IndividualSizeTarget key={index} size={size} index={index} basePrice={basePrice} />
        ))}
      </TableRow>
      <TableRow hover>
        <TableCell>% Target:</TableCell>
        {foodCogsReport?.menuSizes?.map((size, index) => (
          <IndividualSizeTargetPercent key={index} size={size} index={index} basePrice={basePrice} />
        ))}
      </TableRow>
      <TableRow hover>
        <TableCell>GP Target</TableCell>
        {watchValues?.map((row, index) => (
          <TableCell key={index} align="center">
            $ {Number(row?.targetGp)?.toFixed(2) ?? '0.00'}
          </TableCell>
        ))}
      </TableRow>
      <TableRow hover>
        <TableCell>% GP Target</TableCell>
        {watchValues?.map((row, index) => (
          <TableCell key={index} align="center">
            {Number(row?.targetGpPercent)?.toFixed(2) ?? '0.00'} %
          </TableCell>
        ))}
      </TableRow>

      <TableRow hover>
        <TableCell>Difference:</TableCell>
        {watchValues?.map((row, index) => (
          <TableCell key={index} align="center">
            $ {Number(row?.difference)?.toFixed(2) ?? '0.00'}
          </TableCell>
        ))}
      </TableRow>

      <TableRow hover>
        <TableCell>% Difference:</TableCell>
        {watchValues?.map((row, index) => (
          <TableCell key={index} align="center">
            {Number(row?.differencePercent)?.toFixed(2) ?? '0.00'} %
          </TableCell>
        ))}
      </TableRow>
    </TableBody>
  );
}

export default FoodMenuCogsTableBody;
