import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import { FormProvider } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React from 'react';
import { useForm } from 'react-hook-form';
import { createStock } from 'redux/slices/stockSlice';
import { dispatch } from 'redux/store';
import StockAddForm from 'sections/@dashboard/stocks/StockAddForm';

function StockCreate({ open, setOpen }) {
  const departmentId = useGetDepartmentId();

  const createStockHandler = (data) => {
    const weight = data?.unitWeight || 0 * data?.currentQty || 0;
    const finalData = { ...data, departmentId, weight };
    dispatch(createStock({ finalData, setOpen }));
  };
  return (
    <CustomDrawer title="Create Stock" open={open} setOpen={setOpen}>
      <StockAddForm onSubmit={createStockHandler} />
    </CustomDrawer>
  );
}

export default StockCreate;
