import { Box } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import PropTypes from 'prop-types';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getIndividualRecipeDetails } from 'redux/slices/ingredientSlice';
import RecipeCreateForm from 'sections/@dashboard/recipe/RecipeCreateForm';

function EditRecipe({ open, setOpen, data }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!departmentId || !data) return;
    dispatch(getIndividualRecipeDetails({ departmentId, id: data?.id }));
  }, [data, departmentId, dispatch]);

  return (
    <CustomDrawer open={open} setOpen={handleClose} title={`Edit receipe`} PaperProps={{ sx: { innerWidth: '50%' } }}>
      <Box style={{ width: '500px' }}>
        <RecipeCreateForm handleClose={handleClose} updateData={data} isEdit />
      </Box>
    </CustomDrawer>
  );
}

EditRecipe.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.object,
};

export default EditRecipe;
