import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { citiesInStore, getDeliveryZone } from 'redux/slices/posConfigSlice';
import SuburbAddForm from './SuburbAddForm';

function SuburbCreate({ open, handleClose }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeliveryZone());
    dispatch(citiesInStore());
  }, [dispatch]);
  return (
    <CustomOutletModal open={open} setOpen={handleClose} zIndex="1600" title="Add New Suburb">
      <SuburbAddForm handleClose={handleClose} />
    </CustomOutletModal>
  );
}

export default SuburbCreate;
