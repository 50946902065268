import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import { AccordionSummary, Avatar, Stack, Typography } from '@mui/material';
import React from 'react';

TimeClockAccordionSummary.propTypes = {
  user: PropTypes.object,
};

function TimeClockAccordionSummary({ user }) {
  return (
    <AccordionSummary
      expandIcon={<Icon icon="material-symbols:expand-more" width="25px" />}
      aria-controls="user Accordion"
      id="panel1a-header"
      style={{ borderBottom: '0.5px solid rgba(145, 158, 171, 0.24)' }}
    >
      <Stack direction="row" justifyContent={'center'} alignItems={'center'} gap={2}>
        {user?.thumbnailURL ? (
          <Avatar
            src={user?.thumbnailURL}
            alt=""
            style={{
              height: '30px',
              width: '30px',
              borderRadius: '100%',
              marginRight: '10px',
            }}
          />
        ) : (
          <Avatar alt="" src="" style={{ height: '30px', width: '30px', borderRadius: '100%', marginRight: '10px' }} />
        )}
        <Typography component="h3" fontWeight={600}>
          {user?.firstName} {user?.lastName}
        </Typography>
      </Stack>
    </AccordionSummary>
  );
}

export default TimeClockAccordionSummary;
