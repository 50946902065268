import { Box, Card } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import React from 'react';
import VehiclesAddEditForm from './VehiclesAddEditForm';

function VehiclesEditDrawer({ open, setOpen, data }) {
  return (
    <CustomDrawer
      open={open}
      setOpen={setOpen}
      title={`Edit details of ${data?.vehicleDetails}`}
      PaperProps={{ sx: { innerWidth: '50%' } }}
    >
      <Box style={{ maxWidth: '700px' }}>
        {/* <Card sx={{ p: 3 }}> */}
        <VehiclesAddEditForm data={data} isEdit />
        {/* </Card> */}
      </Box>
    </CustomDrawer>
  );
}

export default VehiclesEditDrawer;
