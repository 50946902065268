import { Icon } from '@iconify/react';
import { Box, Button, Stack, Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'redux/store';
import { updateSalesForecast } from 'redux/slices/salesSlice';
import moment from 'moment';

import RoasterSalesForecastUpdate from './RoasterSalesForecastUpdate';

function RoasterViewSalesForeCast({ salesForeCast, getTotal, currentWeek }) {
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();
  const [salesForecasting, setSalesForecasting] = useState(0);

  const { allRoaster } = useSelector((state) => state.roaster);
  const currentDates = moment(new Date()).isoWeekday(1).format('YYYY-MM-DD');
  const isSameOrAfterDay = moment(currentDates).isSameOrBefore(allRoaster?.toDate);

  const salesModalClose = () => {
    setModalOpen(false);
  };
  const salesModalOpen = () => {
    setModalOpen(true);
  };

  // const salesForecasting = salesForeCast?.storeSales + salesForeCast?.thirdPartySales || 0;

  let salesForecastPercentage;
  if (salesForecasting === 0) {
    salesForecastPercentage = 0;
  } else {
    salesForecastPercentage = (getTotal?.totalPrice * 100) / salesForecasting;
  }

  const getUpdatedValue = (data) => {
    const isSuccess = () => {
      const addAmount =
        data?.platformForecasts?.reduce((acc, current) => current?.amount + acc, 0) + Number(data?.storeSales);
      setSalesForecasting(addAmount);
      salesModalClose();
    };
    dispatch(updateSalesForecast({ data, isSuccess }));
  };

  useEffect(() => {
    setSalesForecasting(salesForeCast?.forecastSales);
  }, [salesForeCast]);

  return (
    <>
      <Box
        style={{
          background: 'lightgrey',
          padding: '0.3rem',
          borderRadius: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack justifyContent={'center'} alignItems={'center'} flexDirection={'row'}>
          <Button
            onClick={salesModalOpen}
            variant="text"
            disabled={!isSameOrAfterDay}
            className="sales-forecast-button"
          >
            <Tooltip title="Sales" style={{ marginRight: '3px' }}>
              <Icon icon="flat-color-icons:sales-performance" className="sales-icon" />
            </Tooltip>
            <span style={{ marginRight: '5px' }}>{salesForecasting}</span>
            <Tooltip title="Sales Forecast Percentage">
              <Icon icon="iwwa:percentage" color="#f07205" className="sales-icon" />
              {/* <Icon icon="flat-color-icons:sales-performance" /> */}
            </Tooltip>
            <span>{salesForecastPercentage.toFixed(2)}</span>
          </Button>
        </Stack>
      </Box>
      {modalOpen && isSameOrAfterDay && (
        <RoasterSalesForecastUpdate
          currentWeek={currentWeek}
          open={modalOpen}
          onClose={salesModalClose}
          getUpdatedValue={getUpdatedValue}
        />
      )}
    </>
  );
}

export default RoasterViewSalesForeCast;
