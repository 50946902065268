import {
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CustomMultipleAutoCompleteField from 'components/CustomComponents/CustomAutoCompleteField';
import CustomMobileTimePicker from 'components/CustomComponents/CustomMobileTimePicker';
import { RHFCheckbox } from 'components/hook-form';
import React, { useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import TradingHoursCardField from './TradingHoursCardField';

const DAY_OPTION = [
  {
    label: 'Monday',
    value: 'MON',
  },
  {
    label: 'Tuesday',
    value: 'TUE',
  },
  {
    label: 'Wednesday',
    value: 'WED',
  },
  {
    label: 'Thursday',
    value: 'THU',
  },
  {
    label: 'Friday',
    value: 'FRI',
  },
  {
    label: 'Saturday',
    value: 'SAT',
  },
  {
    label: 'Sunday',
    value: 'SUN',
  },
];

function TradingHoursCardBody({ choosenType, setChoosenType }) {
  const { tradingHoursLoading } = useSelector((state) => state.storeConfig);
  const { control, watch, setValue } = useFormContext();
  const fields = useWatch({
    control,
    name: 'data',
  });

  //   const watchtradingDay = watch('tradingDay');

  return (
    <Stack>
      <Stack alignItems="center" gap="1rem" justifyContent={'center'} direction={'row'}>
        <Button
          variant={choosenType === 'MON' ? 'contained' : 'outlined'}
          onClick={() => {
            setChoosenType('MON');
            setValue('choosenType', 'MON');
          }}
        >
          Monday
        </Button>
        <Button
          variant={choosenType === 'TUE' ? 'contained' : 'outlined'}
          onClick={() => {
            setChoosenType('TUE');
            setValue('choosenType', 'TUE');
          }}
        >
          Tuesday
        </Button>
        <Button
          variant={choosenType === 'WED' ? 'contained' : 'outlined'}
          onClick={() => {
            setChoosenType('WED');
            setValue('choosenType', 'WED');
          }}
        >
          Wednesday
        </Button>
        <Button
          variant={choosenType === 'THU' ? 'contained' : 'outlined'}
          onClick={() => {
            setChoosenType('THU');
            setValue('choosenType', 'THU');
          }}
        >
          Thursday
        </Button>
        <Button
          variant={choosenType === 'FRI' ? 'contained' : 'outlined'}
          onClick={() => {
            setChoosenType('FRI');
            setValue('choosenType', 'FRI');
          }}
        >
          Friday
        </Button>
        <Button
          variant={choosenType === 'SAT' ? 'contained' : 'outlined'}
          onClick={() => {
            setChoosenType('SAT');
            setValue('choosenType', 'SAT');
          }}
        >
          Saturday
        </Button>
        <Button
          variant={choosenType === 'SUN' ? 'contained' : 'outlined'}
          onClick={() => {
            setChoosenType('SUN');
            setValue('choosenType', 'SUN');
          }}
        >
          Sunday
        </Button>
      </Stack>
      {/* <Stack>
        <CustomMultipleAutoCompleteField
          name="tradingDay"
          label="Select Day"
          options={DAY_OPTION}
          getOptionLabel={(option) => option.label}
          isOptionEqualToValue={(option, value) => option === value}
          sx={{ width: '12rem' }}
          size="small"
        />
      </Stack> */}
      {/* {watchtradingDay && (
        <> */}
      {tradingHoursLoading ? (
        <CustomLoadingScreen height="20vh" />
      ) : (
        <Stack alignItems={'center'}>
          <TableContainer style={{ marginTop: '10px' }} sx={{ width: 700 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Order Type</TableCell>
                  <TableCell>Start Time</TableCell>
                  <TableCell>End Time</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields?.map((obj, mainIndex) => (
                  <TradingHoursCardField key={mainIndex} obj={obj} mainIndex={mainIndex} choosenType={choosenType} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}

      <Stack alignItems={'center'}>
        <Stack style={{ width: '10rem' }}>
          <Button variant="contained" type="submit" disabled={false}>
            Save
          </Button>
        </Stack>
      </Stack>
      {/* </>
      )} */}
    </Stack>
  );
}

export default TradingHoursCardBody;
