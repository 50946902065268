import { Button, Card, MenuItem, Stack } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import CustomSelectField from 'components/CustomComponents/CustomSelectField';
import { FormProvider } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { sizeGroupOptionGroupBinding } from 'redux/slices/menuERSizeAndOptionSlice';

function SizeGroupOptionGroupBindingForm({ handleClose }) {
  const { postLoading, menuSizeGroup, menuOptionGroup } = useSelector((state) => state.menuERSizeAndOption);

  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const defaultValues = {
    sizeGroupId: '',
    optionGroupId: '',
  };

  const methods = useForm({ defaultValues });

  const { handleSubmit, watch } = methods;

  const SizeGroupOptionGroupBindingSubmitHandler = (data) => {
    const finalData = { ...data, departmentId };
    dispatch(sizeGroupOptionGroupBinding({ finalData, handleClose }));
  };

  const watchSizeGroupId = watch('sizeGroupId');
  const watchOptionGroupId = watch('optionGroupId');

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(SizeGroupOptionGroupBindingSubmitHandler)}>
        <Card sx={{ p: 2 }}>
          <Stack spacing={2}>
            <CustomSelectField name="sizeGroupId" label="Select Size Group" sx={{ width: '15rem' }}>
              {menuSizeGroup?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </CustomSelectField>
            {watchSizeGroupId && (
              <CustomSelectField name="optionGroupId" label="Select Option Group" sx={{ width: '15rem' }}>
                {menuOptionGroup?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </CustomSelectField>
            )}
          </Stack>
          {watchSizeGroupId && (
            <Stack direction="row" justifyContent="flex-start" style={{ marginTop: '20px' }}>
              <Button variant="contained" type="submit" disabled={!watchOptionGroupId}>
                {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : 'Save'}
              </Button>
            </Stack>
          )}
        </Card>
      </FormProvider>
    </>
  );
}

export default SizeGroupOptionGroupBindingForm;
