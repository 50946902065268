import { Box, Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PATH_DASHBOARD } from 'routes/paths';
import FoodMenuCreate from './FoodMenu/FoodMenuCreate';

function FoodCategoryHeaders() {
  const { currentTab } = useSelector((state) => state.foodMenus);
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const createCategoriesModalOpenHandler = () => {
    setOpen(true);
  };

  const createSizesModalOpenHandler = () => {
    setOpen(true);
  };

  return (
    <>
      <HeaderBreadcrumbs
        heading="Food Menu"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'menu', href: PATH_DASHBOARD.food.list },
          { name: 'List' },
        ]}
        action={
          <Box className="invoiceListHeadingButtons">
            {currentTab === 0 && (
              <Button
                variant="contained"
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                onClick={createCategoriesModalOpenHandler}
              >
                Create Menu
              </Button>
            )}

            {open && currentTab === 0 && <FoodMenuCreate open={open} setOpen={setOpen} />}
          </Box>
        }
      />
    </>
  );
}

export default FoodCategoryHeaders;
