import { TableCell, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';
import QuickSummaryDifferenceAllUserTotalInfo from './QuickSummaryDifferenceAllUserTotalInfo';

QuickSummaryDifferenceTableHead.propTypes = {
  allWeeks: PropTypes.array,
};

function QuickSummaryDifferenceTableHead({ allWeeks }) {
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: '#F4F6F8' }}>
        <TableCell style={{ borderRadius: '0px', width: '120px' }} />
        {/* for showing weeks */}
        {allWeeks?.map((obj) => (
          <TableCell style={{ padding: '2px', textAlign: 'center', borderRadius: '0px' }} key={obj?.id}>
            <p style={{ padding: '2px' }}>{moment(obj?.label).format('ddd, DD MMM')}</p>
          </TableCell>
        ))}
      </TableRow>
      <TableRow sx={{ backgroundColor: '#F4F6F8' }}>
        <TableCell style={{ borderRadius: '0px', width: '120px' }} />
        {allWeeks?.map((obj, index) => (
          <TableCell key={index} style={{ margin: '2px', padding: '2px' }}>
            <QuickSummaryDifferenceAllUserTotalInfo today={obj?.label} index={index} />
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default QuickSummaryDifferenceTableHead;
