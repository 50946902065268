import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import UserWorkReportDetailsTableRow from './UserWorkReportDetailsTableRow';

UserWorkReportDetailsTable.propTypes = {
  departmentIndex: PropTypes.number,
  department: PropTypes.object,
};

function UserWorkReportDetailsTable({ departmentIndex, department }) {
  const totalLength = department?.clockReport?.length;

  const getISOWeekNumber = (date) => {
    // Copy the date to avoid modifying the original date object
    const copiedDate = new Date(date);

    // Set the day of the week to Monday to ensure consistency
    copiedDate.setHours(0, 0, 0, 0);
    copiedDate.setDate(copiedDate.getDate() + 4 - (copiedDate.getDay() || 7));

    // Get the year and day of the year for the specified date
    const yearStart = new Date(copiedDate.getFullYear(), 0, 1);
    const weekNumber = Math.ceil(((copiedDate - yearStart) / 86400000 + 1) / 7);

    return weekNumber;
  };

  const weeklyData = {};

  department?.clockReport?.forEach((item) => {
    const weekNumber = getISOWeekNumber(item.reportDate);
    if (!weeklyData[weekNumber]) {
      weeklyData[weekNumber] = [];
    }
    weeklyData[weekNumber].push({
      reportDate: item.reportDate,
      data: item.clockReports,
      isHoliday: item.isHoliday,
    });
  });

  let weekWiseClockReports = Object.values(weeklyData);
  weekWiseClockReports = weekWiseClockReports.map((item) =>
    item?.sort((a, b) => new Date(b.reportDate) - new Date(a.reportDate))
  );
  weekWiseClockReports = weekWiseClockReports?.reverse();

  return (
    <TableContainer key={departmentIndex}>
      <Button
        disableRipple
        style={{
          padding: '5px',
          color: 'white',
          backgroundColor: `${department?.colorCode}`,
          borderRadius: '5px',
          marginLeft: '8px',
          marginBottom: '5px',
        }}
      >
        {department?.name}
      </Button>

      <Table style={{ minWidth: '580px' }} size="small">
        <TableBody>
          <TableRow style={{ backgroundColor: 'rgba(145, 145, 145, 0.33)' }}>
            <TableCell style={{ margin: 0, padding: 0 }}>
              <TableRow>
                <TableCell style={{ width: '150px' }} align="center">
                  Date
                </TableCell>
                <TableCell colSpan={2} align="center" style={{ width: '290px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-around', marginLeft: '30px' }}>
                    <div style={{ alignItems: 'center' }}>In-Time</div>
                    <div>Out-Time</div>
                  </div>
                </TableCell>
                <TableCell>Daily Work Time</TableCell>
                <TableCell>Daily Pay</TableCell>
              </TableRow>
            </TableCell>
            <TableCell>
              Weekly Total <br /> (ex. holiday)
            </TableCell>
          </TableRow>

          {weekWiseClockReports?.map((weekWiseClockReport, weekWiseClockReportIndex) => (
            <UserWorkReportDetailsTableRow
              key={weekWiseClockReportIndex}
              weekWiseClockReport={weekWiseClockReport}
              weekWiseClockReportIndex={weekWiseClockReportIndex}
              totalLength={totalLength}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default UserWorkReportDetailsTable;
