import { Box } from '@mui/material';
import React from 'react';
import Page from 'components/Page';
import FoodRecipeFilter from './FoodRecipeFilter';
import FoodRecipeBody from './FoodRecipeBody';

function FoodRecipeIndex() {
  return (
    // <Page title="Recipe">
    <Box>
      <FoodRecipeFilter />
      <FoodRecipeBody />
    </Box>
    // </Page>
  );
}

export default FoodRecipeIndex;
