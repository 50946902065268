import PropTypes from 'prop-types';
import { Box, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Scrollbar from 'components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'components/table';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from 'redux/slices/userSlice';
import UserTableRow from './UserTableRow';

InactiveUserList.propTypes = {
  departmentId: PropTypes.string,
  searchUser: PropTypes.string,
};

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'phone', label: 'Phone', align: 'left' },
  { id: 'email', label: 'Email', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'action', label: 'Action', align: 'right' },
];
export default function InactiveUserList({ departmentId, searchUser }) {
  const [pageNo, setPageNo] = useState(1);

  const dispatch = useDispatch();

  const { users, loading: usersLoading } = useSelector((state) => state.user);
  useEffect(() => {
    setPageNo(1);
  }, [departmentId]);
  useEffect(() => {
    dispatch(getAllUsers({ departmentId, pageNo, searchUser, statusList: ['INACTIVE'] }));
  }, [searchUser, departmentId, pageNo, dispatch]);
  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ maxHeight: 800, position: 'relative' }}>
          <Table size="small">
            <TableHeadCustom headLabel={TABLE_HEAD} />
            <TableBody>
              {usersLoading ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <CustomLoadingScreen height="50vh" />
                  </TableCell>
                </TableRow>
              ) : (
                users?.users?.map((row) => (
                  <UserTableRow
                    key={row.id}
                    row={row}
                    filterDep={departmentId}
                    tabValue={'INACTIVE'}
                    pageNo={pageNo}
                    searchUser={searchUser}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      {!users?.users?.length > 0 ? (
        <Stack spacing={2} direction="row" sx={{ mt: 2 }} justifyContent="center">
          <TableNoData isNotFound={users?.users?.length === 0} />
        </Stack>
      ) : (
        <Box
          sx={{ position: 'relative', marginTop: '30px' }}
          justifyContent={'end'}
          alignContent="center"
          display={'flex'}
        >
          <Pagination
            count={users?.paginationInfo?.totalPages}
            page={pageNo}
            onChange={(e, value) => setPageNo(value)}
          />
        </Box>
      )}
    </>
  );
}
