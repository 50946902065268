import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, InputAdornment, Stack } from '@mui/material';
import { FormProvider, RHFTextField } from 'components/hook-form';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createDeliveryZone } from 'redux/slices/posConfigSlice';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required(''),
});

function DeliveryZoneForm({ handleClose, updateData, isEdit }) {
  const { postLoading } = useSelector((state) => state.posConfig);
  const dispatch = useDispatch();
  const defaultValues = {
    name: '',
    price: 0,
  };

  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onChange' });
  const { reset, handleSubmit } = methods;

  const DeliveryZoneSubmitHandler = (data) => {
    console.log('🚀 => DeliveryZoneForm => data=>', data);
    dispatch(createDeliveryZone({ data, handleClose, isEdit }));
  };

  useEffect(() => {
    if (!isEdit) return;
    reset({ ...updateData });
  }, [isEdit, reset, updateData]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(DeliveryZoneSubmitHandler)}>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <RHFTextField name="name" label="Name" />
        </Grid>
        <Grid item xs={4}>
          <RHFTextField
            name="price"
            label="Price"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
      <Stack alignItems="flex-end" justifyContent={'flex-end'} mt="1rem" direction="row" gap="1rem">
        <Button variant="contained" type="submit" color="secondary" disabled={postLoading}>
          Save
        </Button>
        <Button variant="outlined" onClick={handleClose} color="error">
          Cancel
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default DeliveryZoneForm;
