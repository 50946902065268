import React, { useEffect, useState } from 'react';
import {
  Box,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Scrollbar from 'components/Scrollbar';
import { useSelector, useDispatch } from 'react-redux';
import { TableHeadCustom, TableNoData } from 'components/table';
import { getIngredientList, getSearchIngredientList } from 'redux/slices/ingredientSlice';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import IngredientTableRow from './IngredientTableRow';

const TABLE_HEAD = [
  { id: 'S.N', label: 'S.N', align: 'left' },
  { id: 'type', label: 'Category', align: 'left' },
  { id: 'externalUID', label: 'External UID', align: 'left' },
  { id: 'productDescription', label: 'Description', align: 'left' },
  { id: 'uom', label: 'uom', align: 'left' },
  { id: 'weight', label: 'weight', align: 'left' },
  { id: 'unitPrice', label: 'Unit Price', align: 'left' },
  { id: 'gst', label: 'gst', align: 'left' },
  { id: 'action', label: 'Action', align: 'left' },
];

const PAGINATION_PAGESIZE = [
  { id: 2, label: 25 },
  { id: 3, label: 50 },
  { id: 3, label: 100 },
];

function IngredientCardBodyTable() {
  const dispatch = useDispatch();
  const [pages, setPages] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const { loading, allIngredients } = useSelector((state) => state.ingredients);
  const { paginationInfo } = allIngredients;
  const departmentId = useGetDepartmentId();

  const handlePaginationChange = (event, value) => {
    setPages(value);
    dispatch(getSearchIngredientList({ departmentId, pageNo: value, pageSize }));
  };
  const PageSizeChangeHandler = (event, value) => {
    setPageSize(event.target.value);
    dispatch(getSearchIngredientList({ departmentId, pageNo: 1, pageSize: event.target.value }));
  };

  return (
    <>
      <Scrollbar>
        <TableContainer>
          <Table stickyHeader>
            <TableHeadCustom headLabel={TABLE_HEAD} />

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={11}>
                    <CustomLoadingScreen height="30vh" />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {allIngredients?.ingredients?.length <= 0 ? (
                    <TableNoData isNotFound={allIngredients?.ingredients?.length <= 0} />
                  ) : (
                    <>
                      {allIngredients?.ingredients?.map((row, index) => (
                        <IngredientTableRow row={row} key={index} index={index} />
                      ))}
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Stack direction={'row'} gap="1rem" alignItems={'center'} justifyContent={'flex-end'}>
        <Select size="small" label="Select" value={pageSize} onChange={PageSizeChangeHandler}>
          {PAGINATION_PAGESIZE.map((el) => (
            <MenuItem value={el?.label} key={el.id}>
              {el?.label}
            </MenuItem>
          ))}
        </Select>
        <Pagination
          count={paginationInfo?.totalPages}
          page={paginationInfo?.pageNo + 1}
          onChange={handlePaginationChange}
          className="pagination-list-container"
          sx={{ px: 3, py: 1.5, justifyContent: 'flex-end', borderTop: 'solid 1px rgba(145, 158, 171, 0.24)' }}
        />
      </Stack>
    </>
  );
}

export default IngredientCardBodyTable;
