import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Card, CardHeader } from '@mui/material';
import { BaseOptionChart } from 'components/chart';
import { fNumber } from 'utils/formatNumber';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 212;
const LEGEND_HEIGHT = 52;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(1),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    position: 'relative !important',
    // borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT - 20}px) !important`,
  },
}));

// ----------------------------------------------------------------------

QuickSummaryPieChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

export default function QuickSummaryPieChart({ title, subheader, chartColors, chartData, cost, ...other }) {
  const theme = useTheme();
  const difference = chartData[1].value - chartData[0].value;
  const color = () => (difference > 0 ? 'black' : 'red');
  const chartLabels = chartData.map((i) => ` ${i.label.split(' ')[0]}`);

  const chartSeries = chartData.map((i) => i.value);

  const chartOptions = merge(BaseOptionChart(), {
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper], width: 2 },
    legend: { floating: true, horizontalAlign: 'center', fontSize: '10px', position: 'bottom' },
    dataLabels: { enabled: true, dropShadow: { enabled: true } },
    tooltip: {
      fillSeriesColor: true,
      enabled: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {},
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card {...other}>
      <Box
        style={{ textAlign: 'center', fontSize: '1rem', marginTop: '.3rem', fontWeight: 'bold', marginBottom: '-8px' }}
      >
        {title}
      </Box>
      {/* <CardHeader
        title={title}
        subheader={subheader}
        style={{ marginBottom: '-10px', color: 'red', fontSize: '.8rem' }}
      /> */}

      <ChartWrapperStyle dir="ltr">
        <ReactApexChart type="pie" series={chartSeries} options={chartOptions} height={190} />
      </ChartWrapperStyle>
      <Box style={{ marginTop: '-35px', textAlign: 'center' }}>
        {cost
          ? chartData?.map((i) => (
              <Box
                style={{ padding: '0 3px', fontSize: '.7rem', textAlign: 'center', display: 'inline-flex' }}
                key={i.label}
              >
                {i.label}: {i.value.toFixed(2)}$
              </Box>
            ))
          : chartData?.map((i) => (
              <Box
                style={{ padding: '0 3px', fontSize: '.7rem', textAlign: 'center', display: 'inline-flex' }}
                key={i.label}
              >
                {i.label}: {`${Math.floor(i.value / 60)}H ${i.value % 60}M`}
              </Box>
            ))}
      </Box>
      <Box style={{ padding: '0 3px', fontSize: '.7rem', textAlign: 'center', color: color() }}>
        Difference:{' '}
        {cost
          ? `${Math.abs(difference.toFixed(2))}$`
          : `${Math.floor(Math.abs(difference) / 60)}H ${Math.abs(difference) % 60}M`}
      </Box>
    </Card>
  );
}
