import { Table, TableContainer } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Scrollbar from 'components/Scrollbar';
// import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { findAllDaysFromWeeks } from 'utils/roasterFeatureUtils';
import { publishUserNote, setUserNoteWeek } from 'redux/slices/userNoteSlice';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import UserNotesTableHead from './UserNotesTableHead';
import UserNotesTableBody from './UserNotesTableBody';

function UserNoteBody() {
  const departmentId = useGetDepartmentId();
  const { userNotes, userNotesLoading } = useSelector((state) => state.userNote);

  const [allWeeks, setAllWeeks] = useState([]);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { reset } = methods;
  const dispatch = useDispatch();

  const fromDate = moment(new Date()).startOf('isoWeek').format('YYYY-MM-DD');
  const toDate = moment(new Date()).endOf('isoWeek').format('YYYY-MM-DD');

  //   get weeks for table headings
  useEffect(() => {
    const getAllDates = findAllDaysFromWeeks(fromDate, toDate).map((obj, index) => ({
      id: index,
      label: moment(obj).format('YYYY-MM-DD'),
      align: 'left',
    }));
    setAllWeeks(getAllDates);
    dispatch(setUserNoteWeek(getAllDates));
  }, [dispatch, fromDate, toDate]);

  const findWeeklyNotes = useCallback(
    (obj) => {
      const weeklyNotesInDates = {};
      if (!Object.keys(obj?.weeklyNotesInDates).length) {
        allWeeks?.forEach((week) => {
          weeklyNotesInDates[week?.label] = [];
        });
      } else {
        allWeeks?.forEach((week) => {
          let weekDay = moment(week?.label).format('ddd');
          weekDay = weekDay.toUpperCase();
          if (obj?.weeklyNotesInDates[weekDay]) {
            weeklyNotesInDates[week?.label] = [obj?.weeklyNotesInDates[weekDay]];
          } else {
            weeklyNotesInDates[week?.label] = [];
          }
        });
      }
      return weeklyNotesInDates;
    },
    [allWeeks]
  );

  useEffect(() => {
    if (!userNotes?.length) return;
    const resetData = userNotes?.map((obj) => ({
      ...obj,
      weeklyNote: findWeeklyNotes(obj),
    }));
    reset({ users: resetData });
  }, [userNotes, reset, allWeeks, findWeeklyNotes]);

  const findFinalNotes = (obj) => {
    const weeklyNotesInDates = {};

    allWeeks?.forEach((week) => {
      if (!obj?.weeklyNote[week?.label]) return;
      let weekDay = moment(week?.label).format('ddd');
      weekDay = weekDay.toUpperCase();
      weeklyNotesInDates[weekDay] = obj?.weeklyNote[week?.label][0];
    });
    Object.keys(weeklyNotesInDates).forEach((key) => {
      if (!weeklyNotesInDates[key]) delete weeklyNotesInDates[key];
    });
    return weeklyNotesInDates;
  };

  const UserNotePublishHandler = (data) => {
    const finalData = data?.users?.map((obj) => ({
      ...obj,
      weeklyNotesInDates: findFinalNotes(obj),
    }));
    console.log('🚀 => finalData => finalData=>', finalData);

    dispatch(publishUserNote({ finalData, departmentId }));
  };

  return (
    <>
      {userNotesLoading ? (
        <CustomLoadingScreen height="40vh" />
      ) : (
        <Scrollbar>
          <FormProvider {...methods}>
            <form id="userNotePublishForm" onReset={methods.handleSubmit(UserNotePublishHandler)}>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table stickyHeader>
                  <UserNotesTableHead allWeeks={allWeeks} />
                  <UserNotesTableBody />
                </Table>
              </TableContainer>
            </form>
          </FormProvider>
        </Scrollbar>
      )}
    </>
  );
}

export default UserNoteBody;
