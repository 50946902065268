import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import { errorToastTime, successToastTime } from 'config';

// for categories
export const getFoodMenuList = createAsyncThunk('foodConf/getFoodMenuList', async (props) => {
  try {
    let pageNoString;
    if (props?.pageNo) {
      pageNoString = `?pageNo=${props.pageNo - 1}&pageSize=${props.pageSize || 25}`;
    } else {
      pageNoString = '';
    }

    const response = await apiURL.get(`/foodConf/menu/${props.departmentId}${pageNoString}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getAllMenusWithCategories = createAsyncThunk('foodConf/getAllMenusWithCategories', async (props) => {
  try {
    const response = await apiURL.get(`/foodConf/menusWithCategories/${props.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateAllMenusWithCategories = createAsyncThunk(
  'foodConf/updateAllMenusWithCategories',
  async (props, thunkAPI) => {
    try {
      const response = await apiURL.put(`/foodConf/menusWithCategories`, props.finalData);
      if (response.status === 200) {
        toast.success('Food Menu Prices Updated Successfully.', { autoClose: successToastTime });
        thunkAPI.dispatch(getAllMenusWithCategories({ departmentId: props.finalData?.departmentId }));
      }
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const createFoodMenu = createAsyncThunk('foodConf/createFoodMenu', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/foodConf/menu`, props.finalData);
    if (response.status === 200) {
      toast.success('Food Menu created Successfully.', { autoClose: successToastTime });
      props.setOpen(false);
    }
    thunkAPI.dispatch(getAllMenusWithCategories({ departmentId: props.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getFoodMenuDetails = createAsyncThunk('foodConf/getFoodMenuDetails', async (props, thunkAPI) => {
  try {
    const response = await apiURL.get(`/foodConf/menu/${props.menuId}/${props.departmentId}`);
    // thunkAPI.dispatch(getAllMenusWithCategories({ departmentId: props.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getMenuProcess = createAsyncThunk('foodConf/getMenuProcess', async (props, thunkAPI) => {
  try {
    const response = await apiURL.get(`/foodConf/images/${props.menuId}/${props.departmentId}`);
    // thunkAPI.dispatch(getAllMenusWithCategories({ departmentId: props.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateMenuProcess = createAsyncThunk('foodConf/updateMenuProcess', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/foodConf/addImages`, props.formData);
    if (response.status === 200) {
      props.isSuccess(true);
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteMenuProcess = createAsyncThunk('foodConf/deleteMenuProcess', async (props, thunkAPI) => {
  const response = await apiURL.delete(`/foodConf/imageNote/${props.departmentId}/${props.id}`);
  if (response.status === 200) {
    props.isSuccess(true);
  }
  return response.data;
});

export const updateMenuProcessImage = createAsyncThunk('foodConf/updateMenuProcessImage', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put(`/foodConf/updateImage`, props.formData);
    if (response.status === 200) {
      toast.success('menu process image updated', { autoClose: successToastTime });
    }
    return response.data;
  } catch (error) {
    props?.isSuccess(false);
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateMenuProcessNotes = createAsyncThunk('foodConf/updateMenuProcessNotes', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put(`/foodConf/imageNotes`, props.finalData);
    if (response.status === 200) {
      props.isSuccess(true);
    }
    return response.data;
  } catch (error) {
    props.isSuccess(false);
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateFoodMenu = createAsyncThunk('foodConf/updateFoodMenu', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put(`/foodConf/menu`, props.finalData);
    if (response.status === 200) {
      toast.success('Food Menu Updated Successfully.', { autoClose: successToastTime });
      props.checkSuccess();
      props.handleClose();
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteFoodMenu = createAsyncThunk('foodConf/deleteFoodMenu', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/foodConf/menu/${props.departmentId}/${props.menuId}`);
    if (response.status === 200) {
      toast.success('Food Menu Deleted Successfully.', { autoClose: successToastTime });
    }
    thunkAPI.dispatch(getAllMenusWithCategories({ departmentId: props?.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getAllMenusList = createAsyncThunk('foodConf/getAllMenusList', async (props, thunkAPI) => {
  try {
    let pageNoString;
    if (props?.pageNo) {
      pageNoString = `?pageNo=${props.pageNo - 1}&pageSize=${props?.pageSize || 25}`;
    }
    if (props?.searchTxt) {
      const searchTxt = `?searchTxt=${props.searchTxt}`;
      const response = await apiURL.get(`/foodConf/menus/${props.departmentId}${searchTxt}`, {
        signal: thunkAPI.signal,
      });
      return response.data;
    }
    const response = await apiURL.get(`/foodConf/menus/${props?.departmentId}${pageNoString}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getMenuSalesDetailsList = createAsyncThunk('foodConf/getMenuSalesDetailsList', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/foodConf/menuSold`, props);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateMenuSales = createAsyncThunk('foodConf/updateMenusSalesDetailsList', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put(`/foodConf/menuSold`, props.finalData);
    if (response.status === 200) {
      toast.success('Menu Sales List Updated Successfully.', { autoClose: successToastTime });
      thunkAPI.dispatch(
        getMenuSalesDetailsList({
          departmentId: props.finalData?.departmentId,
          dateForMenusSold: props.finalData?.dateForMenusSold,
        })
      );
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getFoodZones = createAsyncThunk('foodConf/getFoodZones', async (props) => {
  const response = await apiURL.get(`/storeConf/foodCostZone/${props.departmentId}`);
  return response.data;
});

export const updateFoodZones = createAsyncThunk('foodConf/updateFoodZones', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put(`/storeConf/foodCostZone`, props);
    if (response.status === 200) {
      toast.success('Food Cost Zone Updated Successfully.', { autoClose: successToastTime });
      thunkAPI.dispatch(getFoodZones({ departmentId: props.departmentId }));
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const menuSalesReport = createAsyncThunk('foodConf/menuSalesReport', async (props) => {
  const response = await apiURL.post(`/report/menusSales`, props);
  return response.data;
});

const FoodMenuSlice = createSlice({
  name: 'foodMenu',
  initialState: {
    loading: false,
    postLoading: false,
    detailsLoading: false,
    menuSalesLoading: false,
    currentTab: 0,
    allMenu: {},
    menuDetails: {},
    allMenuWithCategories: [],
    menuProcess: [],
    allMenuList: {},
    menuSalesDetailsList: [],
    foodCostZones: null,
    setMenuManageOpen: false,
    menuSalesReport: [],
  },

  reducers: {
    changeFoodMenuCurrentTab(state, action) {
      state.currentTab = action.payload;
    },

    manageSetMenuOpen(state, action) {
      state.setMenuManageOpen = action.payload;
    },

    clearMenuDetails(state) {
      state.menuDetails = {};
    },
  },

  extraReducers: {
    [getFoodMenuList.pending]: (state) => {
      state.loading = true;
    },
    [getFoodMenuList.fulfilled]: (state, action) => {
      state.loading = false;
      state.allMenu = action.payload;
    },
    [getFoodMenuList.rejected]: (state) => {
      state.loading = false;
    },

    [getFoodMenuDetails.pending]: (state) => {
      state.detailsLoading = true;
    },
    [getFoodMenuDetails.fulfilled]: (state, action) => {
      state.detailsLoading = false;
      state.menuDetails = action.payload;
    },
    [getFoodMenuDetails.rejected]: (state) => {
      state.detailsLoading = false;
    },

    [getMenuProcess.pending]: (state) => {
      state.detailsLoading = true;
    },
    [getMenuProcess.fulfilled]: (state, action) => {
      state.detailsLoading = false;
      state.menuProcess = action.payload;
    },
    [getMenuProcess.rejected]: (state) => {
      state.detailsLoading = false;
    },

    [getAllMenusWithCategories.pending]: (state) => {
      state.loading = true;
    },
    [getAllMenusWithCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.allMenuWithCategories = action.payload;
    },
    [getAllMenusWithCategories.rejected]: (state) => {
      state.loading = false;
    },

    [createFoodMenu.pending]: (state) => {
      state.postLoading = true;
    },
    [createFoodMenu.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [createFoodMenu.rejected]: (state) => {
      state.postLoading = false;
    },

    [updateFoodMenu.pending]: (state) => {
      state.postLoading = true;
    },
    [updateFoodMenu.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateFoodMenu.rejected]: (state) => {
      state.postLoading = false;
    },

    [getAllMenusList.pending]: (state) => {
      state.loading = true;
    },
    [getAllMenusList.fulfilled]: (state, action) => {
      state.loading = false;
      state.allMenuList = action.payload;
    },
    [getAllMenusList.rejected]: (state) => {
      state.loading = false;
    },

    [updateMenuProcess.pending]: (state) => {
      state.postLoading = true;
    },
    [updateMenuProcess.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateMenuProcess.rejected]: (state) => {
      state.postLoading = false;
    },

    [getMenuSalesDetailsList.pending]: (state) => {
      state.menuSalesLoading = true;
    },
    [getMenuSalesDetailsList.fulfilled]: (state, action) => {
      state.menuSalesLoading = false;
      state.menuSalesDetailsList = action.payload;
    },
    [getMenuSalesDetailsList.rejected]: (state) => {
      state.menuSalesLoading = false;
    },

    [getFoodZones.pending]: (state) => {
      state.loading = true;
    },
    [getFoodZones.fulfilled]: (state, action) => {
      state.loading = false;
      state.foodCostZones = action.payload;
    },
    [getFoodZones.rejected]: (state) => {
      state.loading = false;
      state.foodCostZones = null;
    },

    [menuSalesReport.pending]: (state) => {
      state.loading = true;
      state.menuSalesReport = [];
    },
    [menuSalesReport.fulfilled]: (state, action) => {
      state.menuSalesReport = action.payload;
      state.loading = false;
    },
    [menuSalesReport.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { changeFoodMenuCurrentTab, clearMenuDetails, manageSetMenuOpen } = FoodMenuSlice.actions;
export default FoodMenuSlice.reducer;
