import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFSelect.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
};

// in this component, if values are not seen than pass
//   SelectProps = {{native : false}}
// default native is true which wont render MenuItem

// update : Native has been made false in this component... might encounter some bugs in select field..

// loopting through selection like this

// {userBreak?.map((option) => (
//   <MenuItem
//     key={option.id}
//     value={option.minutes}
//     // onClick={() => handleSelectService(index, option.name)}
//   >
//     {option.minutes} minutes
//   </MenuItem>
// ))}

export default function RHFSelect({ name, children, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          SelectProps={{ native: false }}
          error={!!error}
          helperText={error?.message}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}
