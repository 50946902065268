import { Stack } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import EmptyContent from 'components/EmptyContent';
import TimeClockTableList from './TimeClockTableList';

function TimeClockTable() {
  const { allStaffClockEvents, loading } = useSelector((state) => state.time);

  return (
    <Stack gap={1}>
      {loading ? (
        <CustomLoadingScreen height={'15rem'} />
      ) : (
        <>
          {!allStaffClockEvents?.length ? (
            <Stack alignItems={'center'} justifyContent={'center'}>
              <EmptyContent title="No Data" />
            </Stack>
          ) : (
            <>
              <TimeClockTableList />
            </>
          )}
        </>
      )}
    </Stack>
  );
}

export default TimeClockTable;
