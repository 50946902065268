import {
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import { sum } from 'lodash';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { fCurrency } from 'utils/formatNumber';

export default function PlatformSalesSection() {
  const { onlineSalesPlatform } = useSelector((state) => state.store);

  const { control, setValue } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: 'platformSales',
    keyName: 'fieldID',
  });

  const values = useWatch({
    control,
    name: 'platformSales',
  });

  const totalOnRow = values?.map((item) => Number(item?.amount));
  const totalOnlineSales = sum(totalOnRow);
  const totalDelivery = values?.map((item) => Number(item?.qty));
  const totalDeliveryCount = sum(totalDelivery);

  const totalOnRowExcludingCommission = values?.map((item) => {
    let cash = 0;
    onlineSalesPlatform?.onlinePlatforms?.forEach((el) => {
      if (el.id === item?.onlinePlatformId) {
        cash = Number(item?.amount) - (Number(item?.amount) * Number(el?.commissionPercent)) / 100;
      }
    });
    return cash;
  });
  const totalOnlineExCommission = sum(totalOnRowExcludingCommission);

  useEffect(() => {
    setValue('online', totalOnlineSales);
    setValue('onlineExcludingCommission', totalOnlineExCommission);
    setValue('totalDeliveryCount', totalDeliveryCount);
  }, [setValue, totalOnlineExCommission, totalOnlineSales, totalDeliveryCount]);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ color: 'text.disabled', mb: 3 }}>
        Platform Sales Details:
      </Typography>
      <Stack spacing={2}>
        <Grid container spacing={2}>
          {/* {!salesPlatformLoading && ( */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: '100px' }}>Name</TableCell>
                  {onlineSalesPlatform?.onlinePlatforms?.map((item, index) => (
                    <TableCell align="center" key={index} style={{ minWidth: '80px' }}>
                      {item?.platformName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow hover>
                  <TableCell style={{ padding: 1 }}>
                    <span style={{ marginLeft: '20px' }}>Amount:</span>
                  </TableCell>
                  {fields?.map((item, index) => (
                    <TableCell style={{ padding: 3 }} key={index}>
                      <RHFTextField
                        size="small"
                        name={`platformSales.${index}.amount`}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                      />
                    </TableCell>
                  ))}
                </TableRow>
                <TableRow hover>
                  <TableCell style={{ padding: 1 }}>
                    <span style={{ marginLeft: '20px' }}>Qunatity:</span>
                  </TableCell>
                  {fields?.map((item, index) => (
                    <TableCell style={{ padding: 3 }} key={index}>
                      <RHFTextField size="small" name={`platformSales.${index}.qty`} />
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Stack>

      <Divider sx={{ my: 3, borderStyle: 'dashed' }} />
      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'flex-start', md: 'center' }}>
        {totalDeliveryCount > 0 && (
          // <Typography style={{ fontSize: '.9rem', fontWeight: '700', color: 'grey' }} color={'black'}>
          //   Total Delivery Count: {totalDeliveryCount}
          // </Typography>
          <Button size="small" disabled>
            Total No. of Deliveries: {totalDeliveryCount}
          </Button>
        )}
        {totalOnlineSales > 0 && (
          <>
            <Button size="small" disabled>
              Total Online Sales: {fCurrency(totalOnlineSales)} (Ex.Commission: {fCurrency(totalOnlineExCommission)})
            </Button>
            {/* <Button size="small" disabled>
              Total Online Sales ExCommission: {fCurrency(totalOnlineExCommission)}
            </Button> */}
          </>
        )}
      </Stack>
    </Box>
  );
}
