import { Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteMenuProcess,
  getMenuProcess,
  updateMenuProcess,
  updateMenuProcessImage,
  updateMenuProcessNotes,
} from 'redux/slices/FoodMenuSlice';
import { RHFTextField, RHFUploadAvatar } from 'components/hook-form';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Iconify from 'components/Iconify';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';

FoodMenuImageProcess.propTypes = {
  menuId: PropTypes.number,
};

function FoodMenuImageProcess({ menuId }) {
  const departmentId = useGetDepartmentId();
  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, id: null });

  const dispatch = useDispatch();
  const { menuProcess, detailsLoading, postLoading } = useSelector((state) => state.foodMenus);

  const defaultValues = { data: [] };
  const methods = useForm({ defaultValues });
  const { control, reset, setValue, formState, handleSubmit } = methods;

  useEffect(() => {
    if (!menuProcess) return;
    reset({ data: [...menuProcess] });
  }, [menuProcess, reset]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'data',
    keyName: 'menuFieldId',
  });

  const AddNewImageField = () => {
    append({ note: '' });
  };

  const handleDeleteDialog = () => {
    setOpenDeleteModal({ id: null, status: false });
  };

  const RemoveImageField = (el, index) => {
    if (!el?.id) {
      remove(index);
    } else {
      setOpenDeleteModal({ status: true, id: el?.id });
    }
  };

  const MenuProcessDeleteHandler = () => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        toast.success('Menu Process Deleted successfully!');
        dispatch(getMenuProcess({ departmentId, menuId }));
      }
    };
    dispatch(deleteMenuProcess({ departmentId, id: openDeleteModal?.id, isSuccess: isSuccessful }));
    handleDeleteDialog();
  };
  const isChanged = formState.isDirty;

  const ImageSubmitHandler = (data) => {
    const fileArray = [];
    const noteArray = [];
    const updateNotesArray = [];
    // const fileArray =
    // data?.data?.map((el) => el?.imageUrl);
    data?.data?.forEach((el) => {
      if (!el?.id) {
        fileArray.push(el?.imageUrl);
        noteArray.push(el?.note);
      }
    });
    data?.data?.forEach((el) => {
      if (el?.id) {
        updateNotesArray.push({ id: el?.id, note: el?.note });
      }
    });
    // const isSuccessful = (isNoteSuccess || isUpdateSuccess) => {
    //   if(isNoteSuccess && isUpdateSuccess){
    //     toast.success('Notes and Menu Process Updated');
    //   }
    //   if(!isNoteSuccess){
    //     toast.error('note update failed');
    //   }
    //   if(!isUpdateSuccess){
    //     toast.error('new process create failed');
    //   }
    // }

    // const noteArray = data?.data?.map((el) => el?.note);
    // const fileArray = data?.data?.map((el) => el?.imageUrl);
    if (fileArray.length && noteArray.length) {
      if (fileArray.length !== noteArray.length) return toast.error('Please add image and note for each field');
      const formData = new FormData();
      if (Object.keys(fileArray).length) {
        fileArray.forEach((file) => {
          formData.append('file', file);
        });
        formData.append(
          'menu',
          new Blob([JSON.stringify({ notes: noteArray, departmentId, menuId })], { type: 'application/json' })
        );
        const isSuccessful = (isSuccess) => {
          if (isSuccess) {
            toast.success('Updated Menu Process successfully!');
            dispatch(getMenuProcess({ departmentId, menuId }));
          }
        };
        dispatch(updateMenuProcess({ formData, isSuccess: isSuccessful }));
      }
    }
    if (updateNotesArray.length) {
      const isSuccessful = (isSuccess) => {
        if (!isSuccess) {
          dispatch(getMenuProcess({ departmentId, menuId }));
        }
        if (isSuccess) {
          toast.success('Notes Updated');
          dispatch(getMenuProcess({ departmentId, menuId }));
        }
      };
      const finalData = { departmentId, notes: updateNotesArray };
      dispatch(updateMenuProcessNotes({ finalData, isSuccess: isSuccessful }));
    }
  };

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getMenuProcess({ departmentId, menuId }));
  }, [dispatch, departmentId, menuId]);

  const updateImage = (id, file) => {
    const formData = new FormData();
    formData.append('file', file);
    const data = { id, departmentId };
    formData.append('id', new Blob([JSON.stringify(data)], { type: 'application/json' }));

    const isSuccessful = (isSuccess) => {
      if (!isSuccess) {
        dispatch(getMenuProcess({ departmentId, menuId }));
      }
    };
    dispatch(updateMenuProcessImage({ formData, isSuccess: isSuccessful }));
  };

  return (
    <>
      <Stack my="1rem">
        {detailsLoading ? (
          <CustomLoadingScreen height="20vh" />
        ) : (
          <FormProvider {...methods}>
            <Stack spacing={2} flexWrap={'wrap'}>
              {fields?.map((el, index) => (
                <Stack key={el?.menuFieldId} spacing={1}>
                  <RHFUploadAvatar
                    name={`data.${index}.imageUrl`}
                    maxSize={3145728}
                    onDrop={(acceptedFiles) => {
                      const file = acceptedFiles[0];

                      if (file) {
                        if (el?.id) {
                          updateImage(el?.id, file);
                        }
                        setValue(
                          `data.${index}.imageUrl`,
                          Object.assign(file, {
                            preview: URL.createObjectURL(file),
                          })
                        );
                      }
                    }}
                  />
                  <Stack direction={'row'} spacing={1}>
                    <RHFTextField name={`data.${index}.note`} label="Note" size="small" />
                    <Button
                      color="error"
                      variant="contained"
                      startIcon={<Iconify icon="solar:trash-bin-minimalistic-2-bold" />}
                      onClick={() => RemoveImageField(el, index)}
                    >
                      Remove
                    </Button>
                  </Stack>
                </Stack>
              ))}
            </Stack>

            <Stack alignItems="flex-end" marginTop={1}>
              <Button
                variant="contained"
                startIcon={<Iconify icon="material-symbols:add" />}
                onClick={AddNewImageField}
              >
                Add New
              </Button>
            </Stack>

            <Stack alignItems="flex-start" marginTop={1}>
              {fields?.length !== 0 && (
                <Button
                  variant="contained"
                  type="submit"
                  color="secondary"
                  disabled={!isChanged}
                  onClick={handleSubmit(ImageSubmitHandler)}
                >
                  {postLoading ? <CustomLoadingScreen circularWidth={'20px'} color="secondary" /> : 'Submit'}
                </Button>
              )}
            </Stack>
          </FormProvider>
        )}
      </Stack>
      {openDeleteModal?.status && (
        <CustomDeleteDialog
          open={openDeleteModal}
          handleClose={handleDeleteDialog}
          onAccept={MenuProcessDeleteHandler}
        />
      )}
    </>
  );
}

export default FoodMenuImageProcess;
