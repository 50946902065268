import { Box, Card, Tab, Tabs } from '@mui/material';
import StockFilter from 'sections/@dashboard/stocks/StockFilter';
import { dispatch, useSelector } from 'redux/store';

import { changeStockCurrentTab } from 'redux/slices/stockSlice';
import StockCardBodyTable from '../../../sections/@dashboard/stocks/StockCardBodyTable';
import StockOrderList from './StockOrderList';

function StockCardBody() {
  const { stockCurrentTab } = useSelector((state) => state.stocks);

  const handleChange = (event, newValue) => {
    dispatch(changeStockCurrentTab(newValue));
  };

  return (
    <Card>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingLeft: '0.7rem' }}>
        <Tabs value={stockCurrentTab} onChange={handleChange} aria-label="Stock and Order Tabs">
          <Tab label="Stocks" id="0" />
          <Tab label="Order" id="1" />
        </Tabs>
      </Box>

      {stockCurrentTab === 0 && (
        <>
          <StockFilter />
          <StockCardBodyTable />
        </>
      )}

      {stockCurrentTab === 1 && <StockOrderList />}
    </Card>
  );
}

export default StockCardBody;
