import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import React from 'react';
import PropTypes from 'prop-types';
import PublicAnnouncementCreateForm from './PublicAnnouncementCreateForm';

function PublicAnnouncementCreate({ open, handleClose }) {
  return (
    <CustomOutletModal open={open} onClose={handleClose} zIndex="1600" title="Create Public Announcement">
      <PublicAnnouncementCreateForm handleClose={handleClose} />
    </CustomOutletModal>
  );
}

PublicAnnouncementCreate.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default PublicAnnouncementCreate;
