import { Icon } from '@iconify/react';
import { Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { setWeeklyPercentage } from 'redux/slices/roasterSlice';
import { findTotalofEachWeeks } from 'utils/roasterFeatureUtils';

export default function RoasterSumData({ fields, average }) {
  const dispatch = useDispatch();
  const { control, watch } = useFormContext();

  const values = useWatch({
    control,
    name: 'users',
  });

  let totalForecastSales = 0;
  fields?.forEach((element) => {
    totalForecastSales += element?.forecastSales;
  });
  const weeklyAverageForecastSales = totalForecastSales / 7;

  let totalPriceOfWeek = 0;
  let totalTimeOfWeek = 0;

  // eslint-disable-next-line react/prop-types
  fields?.forEach((element) => {
    const currentWeek = moment(element?.date).format('ddd, DD MMM');
    const getTotalofEverything = findTotalofEachWeeks(currentWeek, values);
    totalPriceOfWeek += getTotalofEverything?.totalPrice ?? 0;
    totalTimeOfWeek += getTotalofEverything?.totalDate ?? 0;
  });
  const weeklyAverageCost = totalPriceOfWeek / 7;
  // const weeklyAverageTime = totalTimeOfWeek / 7 / 1000 / 60 / 60;

  let weeklyAverageForecastSalesPercentage = 0;
  if (weeklyAverageForecastSales === 0) {
    weeklyAverageForecastSalesPercentage = 0;
  } else {
    weeklyAverageForecastSalesPercentage = (weeklyAverageCost / weeklyAverageForecastSales) * 100;
    dispatch(setWeeklyPercentage(weeklyAverageForecastSalesPercentage.toFixed(2)));
  }

  return (
    <>
      {/* {average ? ( */}
      {/* <Box
          style={{
            fontSize: '.7rem',
            display: 'flex',
            flexDirection: 'column',
            color: 'black',
            fontWeight: '900',
            backgroundColor: 'rgba(0, 171, 85, 0.08)',
            borderRadius: '10px',
          }}
        > */}
      {/* <p style={{ textAlign: 'center' }}>
            L.C {totalPriceOfWeek.toFixed(1)}$ || L.H {(totalTimeOfWeek / 1000 / 60 / 60).toFixed(1)}H
          </p>
          <p
            style={{
              textAlign: 'center',
              marginTop: '-7px',
            }}
          >
            F.S.: {totalForecastSales.toFixed(1)} $
          </p> */}
      {/* </Box> */}
      {/* ) : ( */}
      <Box className="roasterWeeklyAverageBox">
        <div
          style={{ fontSize: '.7rem', padding: '0', display: 'flex', justifyContent: 'center', marginBottom: '-2px' }}
        >
          <Tooltip title="Weekly Labour Cost">
            <Box style={{ display: 'inline-flex', marginTop: '4px' }}>
              <Icon icon="ic:baseline-attach-money" width={15} />
            </Box>
          </Tooltip>
          <p style={{ display: 'inline-flex', fontWeight: '600' }}>{totalPriceOfWeek.toFixed(1)}</p>
          <Tooltip title="Weekly Labour Hours">
            <Box style={{ display: 'inline-flex', marginLeft: '7px', marginTop: '4px' }}>
              <Icon icon="material-symbols:nest-clock-farsight-analog-outline" width={15} />
            </Box>
          </Tooltip>
          <p style={{ display: 'inline-flex', fontWeight: '600' }}>{(totalTimeOfWeek / 1000 / 60 / 60).toFixed(2)}H</p>
        </div>
        <div
          style={{ padding: '0', display: 'flex', justifyContent: 'center', fontSize: '.7rem', marginBottom: '-5px' }}
        >
          <Tooltip title="Weekly Forecast Sales">
            <Box style={{ display: 'inline-flex', marginTop: '4px' }}>
              {/* <Icon icon="carbon:chart-average" width={15} color="red" /> */}
              {/* <Icon icon="icon-park-twotone:sales-report" color="red" width={15} /> */}
              <Icon icon="icon-park-outline:sales-report" color="red" width={15} />
            </Box>
          </Tooltip>
          <p style={{ display: 'inline-flex', fontWeight: '600', marginRight: '5px', marginLeft: '3px' }}>
            {totalForecastSales.toFixed(1)}
          </p>
          <Tooltip title="Average Forecast Sales Percentage">
            <Box style={{ display: 'inline-flex', marginTop: '4px' }}>
              <Icon icon="iwwa:percentage" width={15} color="red" />
            </Box>
          </Tooltip>
          <p style={{ display: 'inline-flex', fontWeight: '600', marginLeft: '2px' }}>
            {weeklyAverageForecastSalesPercentage.toFixed(2)}
          </p>
        </div>
      </Box>
      {/* )} */}
    </>
  );
}
