import { Container } from '@mui/material';
import Page from 'components/Page';
import React from 'react';
import TimeDurationClockHeaders from './TimeDurationClockHeaders';
import TimeDurationClockBody from './TimeDurationClockBody';

function TimeClockIndex() {
  return (
    <Page>
      {/* <Container> */}
      <TimeDurationClockHeaders />
      <TimeDurationClockBody />
      {/* </Container> */}
    </Page>
  );
}

export default TimeClockIndex;
