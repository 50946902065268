import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import React from 'react';
import MenuOptionForm from 'sections/@dashboard/menuEngineering/menuOption/MenuOptionForm';

function MenuOptionCreate({ open, handleClose }) {
  return (
    <CustomDrawer
      open={open}
      setOpen={handleClose}
      title={`Create Menu Option`}
      PaperProps={{ sx: { innerWidth: '50%' } }}
    >
      <Box style={{ width: '600px' }}>
        <MenuOptionForm handleClose={handleClose} />
      </Box>
    </CustomDrawer>
  );
}

MenuOptionCreate.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default MenuOptionCreate;
