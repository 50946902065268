import { Button, Card, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { fCurrency } from 'utils/formatNumber';

export default function SalesCashCalculationSection() {
  const { setValue, control } = useFormContext();

  const totalPayouts = useWatch({ control, name: 'totalPayouts' }) || 0;
  const online = useWatch({ control, name: 'online' }) || 0;
  // const eft = useWatch({ control, name: 'eft' }) || 0;
  const paymentType = useWatch({ control, name: 'paymentTypeSales' });

  // const voucher = useWatch({ control, name: 'voucher' }) || 0;
  // const other = useWatch({ control, name: 'other' }) || 0;
  const expectedCash = useWatch({ control, name: 'expectedCash' }) || 0;
  const varianceAmount = useWatch({ control, name: 'varianceAmount' }) || 0;
  const actualCash = useWatch({ control, name: 'actualCash' }) || 0;
  const storeSales = useWatch({ control, name: 'storeSales' }) || 0;
  const pcEft = useWatch({ control, name: 'pcEft' }) || 0;

  // const storeOnline = useWatch({ control, name: 'storeOnline' }) || 0;
  // const totalOnlineOrder = useWatch({ control, name: 'totalOnlineOrder' }) || 0;

  // const unpaid = useWatch({ control, name: 'totalUnpaid' }) || 0;

  const findTotalOFPayment = (paymentType) => {
    let total = 0;
    paymentType?.forEach((element) => {
      total += Number(element?.amount);
    });
    return total;
  };

  useEffect(() => {
    setValue(
      'varianceAmount',
      Number(totalPayouts) +
        Number(findTotalOFPayment(paymentType)) +
        Number(pcEft) +
        Number(actualCash) -
        Number(storeSales)
    );
    // setValue(
    //   'expectedCash',
    //   Number(totalPayouts) +
    //     Number(storeOnline) +
    //     Number(findTotalOFPayment(paymentType)) +
    //     Number(voucher) +
    //     Number(other) +
    //     Number(actualCash) +
    //     Number(unpaid) +
    //     Number(totalOnlineOrder) -
    //     Number(storeSales)
    // );
    setValue(
      'expectedCash',
      Number(actualCash) -
        (Number(storeSales) - (Number(totalPayouts) + Number(findTotalOFPayment(paymentType) + Number(pcEft))))
      // Number(actualCash) -
      //   (Number(storeSales)
      //     // Number(storeOnline) -
      //     -
      //     (Number(totalPayouts) +
      //       // Number(online) +
      //       Number(findTotalOFPayment(paymentType)) +
      //       // Number(voucher) +
      //       // Number(other) +
      //       // Number(totalOnlineOrder)
      //       // )
      //       )
    );
    // setValue(
    //   'varianceAmount',
    //   Number(totalPayouts) +
    //     Number(storeOnline) +
    //     Number(findTotalOfEft(eft)) +
    //     Number(voucher) +
    //     Number(other) +
    //     Number(actualCash) +
    //     Number(unpaid) +
    //     Number(totalOnlineOrder) -
    //     Number(storeSales)
    // );
  }, [totalPayouts, online, expectedCash, actualCash, storeSales, setValue, paymentType, pcEft]);
  return (
    <div style={{ textAlign: 'center' }}>
      <Card>
        {/* <Tooltip> */}
        <Button variant="h6" gutterBottom style={{ color: `${varianceAmount < 0 ? 'red' : 'black'}` }} disableRipple>
          Varinace Amount:
          {fCurrency(varianceAmount)}
        </Button>
        {/* </Tooltip> */}
        {/* <Tooltip title="Actual Cash - Expected Cash">
          <Button variant="h6" gutterBottom style={{ color: `${varianceAmount < 0 ? 'red' : 'black'}` }}>
            Variance Amount:
            {fCurrency(varianceAmount)}
          </Button>
        </Tooltip> */}
      </Card>
    </div>
  );
}
