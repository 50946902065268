import { Button, Card, Divider, Tab, Tabs, Typography } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { setChecklistCategoryTabValue } from 'redux/slices/storeDocumentSlice';
import ChecklistIndividualTab from './ChecklistIndividualTab';
import CategoryManageDrawer from './CategoryManageDrawer';

function ChecklistTab() {
  const [openCategory, setOpenCategory] = useState(false);
  const dispatch = useDispatch();
  const { checklistCategories, checklistCategoryTabValue } = useSelector((state) => state.storeDocument);

  // const sortedChecklistCategories = checklistCategories?.sort((a, b) => a?.name.localeCompare(b?.name));

  return (
    <Card>
      {!checklistCategories?.length ? (
        <>
          <Typography variant="h6" sx={{ px: 2, py: 1, bgcolor: 'Background.neutral' }}>
            Please configure the categories first. Then you can add checklist items.
            <Button variant="text" onClick={() => setOpenCategory(true)}>
              Go to Categories
            </Button>
          </Typography>
          {openCategory && <CategoryManageDrawer open={openCategory} setOpen={setOpenCategory} />}
        </>
      ) : (
        <Tabs
          // indicatorColor="secondary"
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={checklistCategoryTabValue}
          onChange={(e, value) => {
            dispatch(setChecklistCategoryTabValue(value));
            // setChecklistTab(value);
          }}
          sx={{ px: 2, bgcolor: 'Background.neutral' }}
        >
          {checklistCategories?.map((item, index) => (
            <Tab label={item?.name} value={Number(index)} key={index} />
          ))}
        </Tabs>
      )}
      <Divider />
      {checklistCategories?.map((item, index) => (
        <React.Fragment key={index}>
          {checklistCategoryTabValue === index && <ChecklistIndividualTab category={item} />}
        </React.Fragment>
      ))}
    </Card>
  );
}

ChecklistTab.propTypes = {
  setParentTab: PropTypes.func,
};

export default ChecklistTab;
