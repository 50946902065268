import { Autocomplete, Box, Card, Grid, Stack, TextField, Typography } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { FormProvider } from 'components/hook-form';
import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Iconify from 'components/Iconify';
import { useDispatch, useSelector } from 'react-redux';
import { clearSignedDocumentList, getSignedAgreementOfUser } from 'redux/slices/userSlice';
import HrSignedDocumentCard from './HrSignedDocumentCard';

function HrBody() {
  const { allUsers, loading, signedDocumentList, signedDocumentLoading, agreementList } = useSelector(
    (state) => state.user
  );
  const [selectedUser, setSelectedUser] = useState(null);

  const dispatch = useDispatch();
  const methods = useForm();
  const { control, handleSubmit } = methods;

  const clickableLink = (link, title) => (
    <a style={{ textDecoration: 'none', color: '#103996' }} href={link} target="_blank" rel="noopener noreferrer">
      <div style={{ display: 'flex' }}>
        <div>
          <span style={{ marginRight: '5px' }}>{title}</span>
        </div>
        <div style={{ marginTop: '3.5px' }}>
          <Iconify icon="ic:baseline-remove-red-eye" />
        </div>
      </div>
    </a>
  );

  useEffect(() => {
    if (!selectedUser) {
      dispatch(clearSignedDocumentList());
    } else {
      dispatch(getSignedAgreementOfUser({ userId: selectedUser }));
    }
  }, [dispatch, selectedUser]);
  return (
    <>
      <Card sx={{ p: 2 }}>
        <Stack spacing={1} direction="column">
          <Stack>
            <Typography variant="h6">View Documents:</Typography>
          </Stack>
          <Stack spacing={3} direction="row">
            <Stack direction={'row'} style={{ marginTop: '3px' }}>
              {clickableLink(agreementList?.SOP, 'SOP')}
              {/* SOP:
              <a
                href={agreementList?.SOP}
                target="_blank"
                rel="noreferrer"
                style={{ marginLeft: '8px', marginTop: '3px' }}
              >
                <Iconify icon="ic:baseline-remove-red-eye" />
              </a> */}
            </Stack>
            <Stack direction={'row'} style={{ marginTop: '3px' }}>
              {clickableLink(agreementList?.HR, 'HR')}
              {/* HR:
              <a
                href={agreementList?.HR}
                target="_blank"
                rel="noreferrer"
                style={{ marginLeft: '8px', marginTop: '3px' }}
              >
                <Iconify icon="ic:baseline-remove-red-eye" />
              </a> */}
            </Stack>
            <Stack direction={'row'} style={{ marginTop: '3px' }}>
              {clickableLink(agreementList?.OHNS, 'OHNS')}
              {/* OHNS:
              <a
                href={agreementList?.OHNS}
                target="_blank"
                rel="noreferrer"
                style={{ marginLeft: '8px', marginTop: '3px' }}
              >
                <Iconify icon="ic:baseline-remove-red-eye" />
              </a> */}
            </Stack>
          </Stack>
        </Stack>
      </Card>
      <Stack style={{ marginTop: '15px' }}>
        <FormProvider methods={methods} onSubmit={handleSubmit()}>
          {loading ? (
            <CustomLoadingScreen height="20vh" />
          ) : (
            <Grid container spacing={1}>
              <Grid item xs={12} sm={8}>
                <Controller
                  control={control}
                  name="userId"
                  render={({ field: { onChange, ...field } }) => (
                    <Autocomplete
                      disableClearable
                      onChange={(event, data, reason, details) => {
                        setSelectedUser(data?.id);
                      }}
                      getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                      {...field}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      options={allUsers?.users || []}
                      renderInput={(params) => (
                        <TextField {...params} label="Select User*" sx={{ maxWidth: '20rem' }} />
                      )}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          <img width="20" src={option.thumbnailURL} alt="" />
                          {option.firstName} {option.lastName} + {option.email}
                        </Box>
                      )}
                    />
                  )}
                />
              </Grid>
            </Grid>
          )}
        </FormProvider>

        {Object.keys(signedDocumentList)?.length > 0 &&
          (signedDocumentLoading ? (
            <CustomLoadingScreen height="10vh" />
          ) : (
            <HrSignedDocumentCard userId={selectedUser} />
          ))}
      </Stack>
    </>
  );
}

export default HrBody;
