import {
  Box,
  Button,
  Divider,
  Modal,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Iconify from 'components/Iconify';
import { TableHeadCustom } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCashDrawer, getStoreCashDrawer } from 'redux/slices/storeSlice';
import StoreCashDrawerAddForm from './StoreCashDrawerAddForm';

const TABLE_HEAD = [
  { id: 'id', label: 'SN', align: 'center' },
  { id: 'name', label: 'CashDrawer Title', align: 'left' },
  { id: 'commissionPercent', label: 'Float Amount', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  textAlign: 'center',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function StoreCashDrawerBody() {
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const deleteModalHandleClose = () => setOpenDeleteModal(false);
  const [pageNo, setPageNo] = useState(1);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const handleUpdateClose = () => setOpenUpdateModal(false);
  const [updateData, setUpdateData] = useState();
  const [cashDrawerId, setCashDrawerId] = useState();

  const departmentId = useGetDepartmentId();
  const { storeCashDrawer, loading: storeCashDrawerLoading } = useSelector((state) => state.store);

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getStoreCashDrawer({ departmentId }));
  }, [dispatch, departmentId]);

  const handleDelete = (id) => {
    setCashDrawerId(id);
    setOpenDeleteModal(true);
  };

  const handleEdit = (data) => {
    setUpdateData(data);
    setOpenUpdateModal(true);
  };

  const deleteCashDrawerFromModal = () => {
    const finalData = { storeCashDrawerId: cashDrawerId, departmentId };
    dispatch(deleteCashDrawer(finalData));
    setOpenDeleteModal(false);
  };
  return (
    <Box style={{ marginTop: '2.5rem' }}>
      {/* Delete Modal */}
      <Modal
        open={openDeleteModal}
        //   onClose={deleteModalHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to delete this CashDrawer?
          </Typography>
          <Typography id="modal-modal-description" align="center" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="success"
              style={{ marginRight: '5px' }}
              onClick={() => deleteCashDrawerFromModal()}
            >
              Delete
            </Button>
            <Button variant="outlined" color="error" onClick={deleteModalHandleClose}>
              Cancel
            </Button>
          </Typography>
        </Box>
      </Modal>

      <Typography variant="h6" gutterBottom>
        Store Cash Drawers List
        <Divider />
      </Typography>
      <TableContainer>
        <Table size="small">
          <TableHeadCustom headLabel={TABLE_HEAD} />
          <TableBody>
            {storeCashDrawerLoading ? (
              <TableRow>
                <TableCell colSpan={4}>
                  <CustomLoadingScreen height="20vh" />
                </TableCell>
              </TableRow>
            ) : (
              storeCashDrawer?.storeCashDrawers?.map((elm, index) => (
                <TableRow hover key={index}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell>{elm?.title}</TableCell>
                  <TableCell align="center"> $ {elm?.floatAmt}</TableCell>
                  <TableCell align="center">
                    <Button onClick={() => handleEdit(elm)}>
                      <Iconify icon={'eva:edit-2-outline'} width={20} height={20} />
                    </Button>
                    <Button variant="text" color="error" onClick={() => handleDelete(elm?.id)}>
                      <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{ position: 'relative', marginTop: '20px' }}
        justifyContent={'end'}
        alignContent="center"
        display={'flex'}
      >
        <Pagination
          count={storeCashDrawer?.paginationInfo?.totalPages}
          page={pageNo}
          onChange={(e, value) => setPageNo(value)}
        />
      </Box>
      {openUpdateModal && (
        <StoreCashDrawerAddForm open={openUpdateModal} setOpen={setOpenUpdateModal} updateData={updateData} edit />
      )}
    </Box>
  );
}
