import { Box, Card, Grid } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import FixedCostCard from './FixedCostCard';
import FixedCostFilter from './FixedCostFilter';

const FixedCostBody = ({ choosenDate, setChoosenDate }) => {
  const { loading: fixedCostLoading } = useSelector((state) => state.operational);

  return (
    <Card sx={{ p: 2 }}>
      <FixedCostFilter choosenDate={choosenDate} setChoosenDate={setChoosenDate} />

      <Box sx={{ p: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box style={{ padding: '5px 10px' }}>
              <b>
                Fixed Cost Details of: {moment(choosenDate).format('YYYY')}, {moment(choosenDate).format('MMM')}
              </b>
              <hr />
            </Box>
          </Grid>
        </Grid>
        {fixedCostLoading ? <CustomLoadingScreen /> : <FixedCostCard choosenDate={choosenDate} />}
      </Box>
    </Card>
  );
};

export default FixedCostBody;
