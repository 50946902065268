import { Button, InputAdornment, Stack } from '@mui/material';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { RHFCheckbox, RHFTextField } from 'components/hook-form';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

function FoodMenuSizeGeneralPriceSingleSizeEdit({ open, setOpen, index }) {
  const { control } = useFormContext();
  const data = useWatch({
    control,
    name: 'menuSizes',
  });

  return (
    <CustomOutletModal open={open} zIndex="1800" title="Edit Food Menu Size" width="30rem">
      <Stack gap={'1rem'}>
        <Stack>
          <RHFTextField
            control={control}
            label="Additional Price"
            name={`menuSizes[${index}].additionalPrice`}
            size="small"
            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
            InputLabelProps={{ shrink: true }}
            sx={{ width: '10rem' }}
          />
        </Stack>
        <Stack style={{ display: 'flex', flexDirection: 'row' }}>
          Is Publised:
          <RHFCheckbox name={`menuSizes[${index}].isPublished`} style={{ marginTop: '-8px', marginLeft: '5px' }} />
        </Stack>
      </Stack>
      <Stack justifyContent={'flex-end'} mt={1} direction={'row'} gap="1rem" alignItems="center">
        {/* <Button variant="contained" onClick={() => setOpen({ status: false, index: null })}>
          Save
        </Button> */}
        <Button variant="contained" onClick={() => setOpen({ status: false, index: null })} color="error">
          Close
        </Button>
      </Stack>
    </CustomOutletModal>
  );
}

export default FoodMenuSizeGeneralPriceSingleSizeEdit;
