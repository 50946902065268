import { Box, Button, Popover, Stack } from '@mui/material';
import CustomDatePicker from 'components/CustomComponents/CustomDatePicker';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getSalesSummary } from 'redux/slices/salesSlice';
import { convertDatetoString } from 'utils/customDateAndTimeFormat';

SalesSummaryFilter.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
};

export default function SalesSummaryFilter({ startDate, endDate, setStartDate, setEndDate }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const [choosenDate, setChoosenDate] = useState('THISWEEK');
  const [openImportDatePop, setOpenImportDatePop] = useState(null);

  const handleOpenImportPopOver = (event) => {
    setOpenImportDatePop(event.currentTarget);
  };

  const handleCloseImportPopOver = () => {
    setOpenImportDatePop(null);
  };
  const open = Boolean(openImportDatePop);
  const ids = open ? 'simple-popover' : undefined;

  const defaultValues = {
    startDate,
    endDate,
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, watch } = methods;

  const watchStartDate = watch('startDate');

  const salesReportFilter = (status) => {
    setChoosenDate(status);

    switch (status) {
      case 'THISWEEK':
        setStartDate(moment(new Date()).startOf('isoWeek').format('YYYY-MM-DD'));
        setEndDate(moment(new Date()).format('YYYY-MM-DD'));
        break;

      case 'LASTWEEK':
        setStartDate(moment(new Date()).subtract(1, 'weeks').startOf('isoWeek').format('YYYY-MM-DD'));
        setEndDate(moment(new Date()).subtract(1, 'weeks').endOf('isoWeek').format('YYYY-MM-DD'));
        break;

      case 'THISMONTH':
        setStartDate(moment(new Date()).startOf('month').format('YYYY-MM-DD'));
        setEndDate(moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'));
        break;

      case '30DAYS':
        setStartDate(moment(new Date()).subtract(30, 'days').format('YYYY-MM-DD'));
        setEndDate(moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'));
        break;

      default:
        setStartDate(moment(new Date()).startOf('isoWeek').format('YYYY-MM-DD'));
        setEndDate(moment(new Date()).subtract(1, 'days').format('YYYY-MM-DD'));
    }
  };

  const handleCustomSummary = (data) => {
    setChoosenDate('');
    if (moment(data?.endDate).diff(moment(data?.startDate), 'days') > 370)
      return toast.warning('Date range should not be more than 370 days');
    setStartDate(convertDatetoString(data?.startDate));
    setEndDate(convertDatetoString(data?.endDate));
    handleCloseImportPopOver();
  };

  useEffect(() => {
    if (!startDate) return;
    if (!endDate) return;
    if (!departmentId) return;

    // if (moment(endDate).diff(moment(startDate), 'days') > 370)
    //   return toast.warning('Date range should be less than 370 days');
    dispatch(
      getSalesSummary({
        startDate,
        endDate,
        departmentId,
      })
    );
  }, [dispatch, startDate, endDate, departmentId]);

  // const myMaxDate = moment(watchStartDate).add(370, 'days').format('YYYY-MM-DD');

  return (
    <Box style={{ paddingTop: '5px', marginTop: '10px' }}>
      <FormProvider {...methods}>
        <Stack direction="row" spacing={2} justifyContent="center">
          <Button
            variant={choosenDate === 'THISWEEK' ? 'contained' : 'outlined'}
            onClick={() => salesReportFilter('THISWEEK')}
          >
            This Week
          </Button>
          <Button
            variant={choosenDate === 'LASTWEEK' ? 'contained' : 'outlined'}
            onClick={() => salesReportFilter('LASTWEEK')}
          >
            Last Week
          </Button>
          <Button
            variant={choosenDate === 'THISMONTH' ? 'contained' : 'outlined'}
            onClick={() => salesReportFilter('THISMONTH')}
          >
            This Month
          </Button>
          <Button
            variant={choosenDate === '30DAYS' ? 'contained' : 'outlined'}
            onClick={() => salesReportFilter('30DAYS')}
          >
            Last 30 Days
          </Button>

          <Button onClick={handleOpenImportPopOver}>Custom</Button>

          <Popover
            id={ids}
            open={open}
            anchorEl={openImportDatePop}
            onClose={handleCloseImportPopOver}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Stack p={2} gap={2}>
              Select Custom Range
              <CustomDatePicker name="startDate" fieldWidth={'max-content'} label="Start" disableFuture />
              <CustomDatePicker
                name="endDate"
                fullWidth={false}
                fieldWidth="max-content"
                label="End"
                disableFuture
                minDate={watchStartDate}
                // maxDate={myMaxDate}
              />
              <Button variant="contained" type="button" onClick={handleSubmit(handleCustomSummary)}>
                Filter
              </Button>
            </Stack>
          </Popover>
        </Stack>
      </FormProvider>
    </Box>
  );
}
