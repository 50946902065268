import React from 'react';
import { Stack } from '@mui/material';
import StaffNewEditForm from '../staffCreate/StaffNewEditForm';
import StaffCreateActiveStepperDepartment from '../staffCreate/StaffCreateActiveStepperDepartment';
import StaffCreateActiveStepperPaperwork from '../staffCreate/StaffCreateActiveStepperPaperwork';
import StaffCreateActiveStepperConfirm from '../staffCreate/StaffCreateActiveStepperConfirm';

function StaffEditActiveStepper({ activeStep, isEdit, ackFormRef }) {
  // eslint-disable-next-line no-nested-ternary
  return (
    <Stack my={4}>
      {activeStep === 0 && <StaffNewEditForm isEdit={isEdit} />}
      {activeStep === 1 && <StaffCreateActiveStepperDepartment isEdit={isEdit} />}
      {activeStep === 2 && <StaffCreateActiveStepperPaperwork isEdit={isEdit} />}
      {/* {activeStep === 2 && <StaffCreateActiveStepperPaperwork />} */}

      {/* {activeStep === 4 && <StaffCreateActiveStepperDeclartion ackFormRef={ackFormRef} />} */}

      {activeStep === 3 && <StaffCreateActiveStepperConfirm />}
    </Stack>
  );
}

export default StaffEditActiveStepper;
