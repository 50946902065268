import { Button, Card, Grid, Stack, Tooltip } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import Iconify from 'components/Iconify';
import { RHFTextField, RHFUploadAvatar } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { deleteIndividualOptionImage, updateIndividualOptionImage } from 'redux/slices/menuERSizeAndOptionSlice';

function MenuOptionImageItem({ item, index, selectedRow }) {
  const { setValue } = useFormContext();
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const [openImageDelete, setOpenImageDelete] = React.useState({ status: false, id: null });

  const updateOptionImage = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const data = { departmentId, id: item?.id, optionGroupId: selectedRow?.id };
    formData.append('option', new Blob([JSON.stringify(data)], { type: 'application/json' }));

    dispatch(updateIndividualOptionImage({ formData, departmentId, id: selectedRow?.id }));
  };
  const deleteOptionImage = () => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        setValue(`options.${index}.imageUrl`, null);
      }
    };
    dispatch(
      deleteIndividualOptionImage({
        departmentId,
        optionGroupId: selectedRow?.id,
        optionId: item?.id,
        isSuccess: isSuccessful,
      })
    );
    setOpenImageDelete({ status: false, id: null });
  };
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Card style={{ padding: '10px' }}>
          <Stack>
            <Stack style={{ position: 'relative' }}>
              <RHFUploadAvatar
                name={`options.${index}.imageUrl`}
                maxSize={3145728}
                onDrop={(acceptedFiles) => {
                  const file = acceptedFiles[0];

                  if (file) {
                    updateOptionImage(file);
                    // setValue(
                    //   `options.${index}.imageUrl`,
                    //   Object.assign(file, {
                    //     preview: URL.createObjectURL(file),
                    //   })
                    // );
                  }
                }}
              />
              <Stack style={{ position: 'absolute', top: 0, right: '50px', cursor: 'pointer' }}>
                <Tooltip title="Delete Image" placement="top">
                  <div>
                    <Iconify
                      icon="maki:cross"
                      height={18}
                      width={18}
                      color={'red'}
                      onClick={() => setOpenImageDelete({ status: true, id: item?.id })}
                    />
                  </div>
                </Tooltip>
              </Stack>
            </Stack>
            <Stack direction={'row'} spacing={1} style={{ marginTop: '5px' }}>
              <RHFTextField disabled name={`options.${index}.name`} label="Option Name" size="small" />
            </Stack>
          </Stack>
        </Card>
        {/* <RHFUploadAvatar
          name={`options.${index}.imageUrl`}
          maxSize={3145728}
          onDrop={(acceptedFiles) => {
            const file = acceptedFiles[0];

            if (file) {
              updateOptionImage(file);
              // setValue(
              //   `options.${index}.imageUrl`,
              //   Object.assign(file, {
              //     preview: URL.createObjectURL(file),
              //   })
              // );
            }
          }}
        /> */}
        {/* <Stack justifyContent={'center'} alignItems={'center'} my={1}>
          <Button
            color="error"
            variant="contained"
            // disabled={!item?.imageUrl}
            onClick={() => setOpenImageDelete({ status: true, id: item?.id })}
          >
            <Iconify icon="solar:trash-bin-minimalistic-2-bold" /> Image
          </Button>
        </Stack>
        <Stack direction={'row'} spacing={1} style={{ marginTop: '5px' }}>
          <RHFTextField disabled name={`options.${index}.name`} label="Option Name" size="small" /> */}
        {/* <Stack direction={'column'} spacing={1}>
          <Button color="error" variant="contained" onClick={() => RemoveImageField(el, index)}>
            <Iconify icon="solar:trash-bin-minimalistic-2-bold" /> Remove
          </Button>
          {el?.id ? (
            <Button
              color="primary"
              variant="contained"
              //   disabled={!isFieldEdited}
              onClick={() => updateSingleField()}
            >
              Update
            </Button>
          ) : null}
        </Stack> */}
        {/* </Stack> */}
      </Grid>
      {openImageDelete?.status && (
        <CustomDeleteDialog
          open={openImageDelete?.status}
          handleClose={() => setOpenImageDelete({ status: false, id: null })}
          onAccept={deleteOptionImage}
        />
      )}
    </>
  );
}

export default MenuOptionImageItem;
