import React from 'react';
import { useSelector } from 'react-redux';
import RecipeSummaryIngredientDetails from './RecipeSummaryIngredientDetails';
import RecipeSummaryOptionDetails from './RecipeSummaryOptionDetails';
import RecipeSummaryComboDetails from './RecipeSummaryComboDetails';

function RecipeSummaryTableBody() {
  const { foodMenuRecipeSummary } = useSelector((state) => state.menuEngineering);

  return (
    <>
      {foodMenuRecipeSummary?.menuSizeOptions?.menuOptions?.map((option, index) => (
        <RecipeSummaryOptionDetails option={option} index={index} key={index} />
      ))}
      {foodMenuRecipeSummary?.menuSizeIngredient?.ingredients?.map((ingredient, index) => (
        <RecipeSummaryIngredientDetails ingredient={ingredient} index={index} key={index} />
      ))}
      {foodMenuRecipeSummary?.menuCombos?.map((combo, index) => (
        <RecipeSummaryComboDetails combo={combo} index={index} key={index} />
      ))}
    </>
  );
}

export default RecipeSummaryTableBody;
