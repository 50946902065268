import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

// Components
import RoasterPageIndex from 'pages/dashboard/roaster/Index';
import DepartmentRoleFeatures from 'pages/dashboard/Department/DepartmentRoleFeatures';
import LeaveRequest from 'pages/dashboard/roaster/LeaveRequest';
import ShiftRequest from 'pages/dashboard/roaster/ShiftRequest';

import UserListPage from 'pages/dashboard/UserListPage';
import TimeClockIndex from 'pages/dashboard/Time';
import ApprovedReport from 'pages/dashboard/Time/ApprovedReport';
// import QuickSummary from 'pages/dashboard/roaster/QuickSummary';

import StockHomePageIndex from 'pages/dashboard/stock';
import ShiftDropRequests from 'pages/dashboard/roaster/ShiftDropRequests';
import StockIngredientsIndex from 'pages/dashboard/Ingredients';

import SalesPlatform from 'pages/dashboard/Store/SalesPlatform';
import StoreCashDrawer from 'pages/dashboard/Store/StoreCashDrawer';
import DriverIncentive from 'pages/dashboard/Store/DriverIncentive';
// import Zones from 'pages/dashboard/Store/Zones';
import StoreSuppliers from 'pages/dashboard/Store/StoreSuppliers';
import Zones from 'pages/dashboard/Store/Zones';

import SalesPageIndex from 'pages/dashboard/sales';
import AddSales from 'pages/dashboard/sales/AddSales';
import EditSales from 'pages/dashboard/sales/EditSales';
import MenuSalesDetails from 'pages/dashboard/sales/MenuSales';

import RunningCost from 'pages/dashboard/Operational-Cost/RunningCost';
import FixedCost from 'pages/dashboard/Operational-Cost/FixedCost';

import ReportIndex from 'pages/dashboard/report';
import InsightReport from 'pages/dashboard/report/insights';
import UserWorkInfo from 'pages/dashboard/report/userWorkInfo';

import InvoiceEdit from 'pages/dashboard/Invoice/InvoiceEdit';
import FoodMenuAndReceipIndex from 'pages/dashboard/FoodMenuAndRecipe';
import FoodCategoryIndex from 'pages/dashboard/FoodCategoryAndSizes';
import InvoiceCreate from 'pages/dashboard/Invoice/InvoiceCreate';
import StaffEditIndex from 'pages/dashboard/staffCreate/staffEditIndex';
import HrAndOhns from 'pages/dashboard/user/hr';

import SendHR from 'pages/auth/SendHR';
import SendSOP from 'pages/auth/sendSOP';
import SendOHNS from 'pages/auth/sendOHNS';
import InvoiceDetails from 'pages/dashboard/Invoice/InvoiceDetails';
import AuthGuard from 'guards/AuthGuard';
import StoreGuidelines from 'pages/dashboard/storeDocument';
import LogIndex from 'pages/dashboard/storeDocument/log';
import ChecklistIndex from 'pages/dashboard/checklist';
import MenuEngineeringIndex from 'pages/dashboard/menuEngineering';
import FoodMenuAndCategoryIndex from 'pages/dashboard/FoodMenuAndCategory';
import TimeClocksIndex from 'pages/dashboard/TimeClock';
import RecipeIndex from 'pages/dashboard/recipe';
import FoodCogsReport from 'pages/dashboard/FoodCogsReport';
import AnnouncementIndex from 'pages/dashboard/Announcement';
import PayTypeIndex from 'pages/dashboard/sales/payType';
import UserWorkReportIndex from 'pages/dashboard/report/userWorkReport';
import DealOfferIndex from 'pages/dashboard/DealOffer';
import QuickSummaryIndex from 'pages/dashboard/quickSummary';
import CreateDealOffer from 'sections/@dashboard/DealOffer/CreateDealOffer';
import EditDealOffer from 'sections/@dashboard/DealOffer/EditDealOffer';
import UserNoteIndex from 'pages/dashboard/roaster/userNotes';
import HalfAndHalfConfig from 'pages/dashboard/halfAndHlafConfig';
import TradingHoursIndex from 'pages/dashboard/tradingHours';
import PosConfigIndex from 'pages/dashboard/posConfig';
import StoreConfigureIndex from 'pages/dashboard/storeConfigure';
import MostLikedMenusIndex from 'pages/dashboard/mostLikedMenus';
import RecommendedMenuIndex from 'pages/dashboard/recommendedMenus';
import InvoiceList from '../pages/dashboard/Invoice/InvoiceList';

// layouts
// import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';

// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config

import { PATH_AFTER_LOGIN } from '../config';
import LoadingScreen from '../components/LoadingScreen';
import { PATH_AUTH } from './paths';
// components

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        { element: <Navigate to={PATH_AUTH.login} replace />, index: true },

        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'request/:token',
          element: (
            <GuestGuard>
              <StaffCreate />
            </GuestGuard>
          ),
        },
        {
          path: 'SOP/:token',
          element: (
            <GuestGuard>
              <SendSOP />
            </GuestGuard>
          ),
        },
        {
          path: 'HR/:token',
          element: (
            <GuestGuard>
              <SendHR />
            </GuestGuard>
          ),
        },
        {
          path: 'OHNS/:token',
          element: (
            <GuestGuard>
              <SendOHNS />
            </GuestGuard>
          ),
        },

        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        { path: 'calendar', element: <Calendar /> },
        { path: 'announcement', element: <AnnouncementIndex /> },

        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'list', element: <UserListPage /> },
            { path: 'requestList', element: <UserRequests /> },
            { path: 'hrOhns', element: <HrAndOhns /> },
            { path: 'requestList/edit/:id', element: <StaffEditIndex /> },

            { path: 'account', element: <UserAccount /> },
            { path: 'new', element: <UserCreate /> },
            { path: 'editUser/:id', element: <UserEdit /> },
            // { path: 'sendHr', element: <SendHR /> },
            // { path: 'sendOHNS', element: <SendOHNS /> },
            // { path: 'sendSOP', element: <SendSOP /> },
            // { path: 'staff', element: <StaffCreate /> },
          ],
        },

        {
          path: 'department',
          children: [
            { element: <Navigate to="/dashboard/department/depUserRole" replace />, index: true },
            { path: 'departmentRole', element: <DepartmentRoles /> },
            { path: 'depUserRole', element: <UserDepartmentRole /> },
            { path: 'depRoleFeature', element: <DepartmentRoleFeatures /> },
          ],
        },
        {
          path: 'store',
          children: [
            { element: <Navigate to="/dashboard/store/specialday" replace />, index: true },
            { path: 'specialday', element: <SpecialDay /> },
            { path: 'break', element: <Break /> },
            { path: 'storedetail', element: <StoreDetail /> },
            // { path: 'onlineSalesPlatform', element: <SalesPlatform /> },
            // { path: 'storeCashdrawer', element: <StoreCashDrawer /> },
            // { path: 'driverIncentive', element: <DriverIncentive /> },
            { path: 'zones', element: <Zones /> },
            { path: 'storeSuppliers', element: <StoreSuppliers /> },
            { path: 'halfAndHalf', element: <HalfAndHalfConfig /> },
            // { path: 'storeConfig', element: <StoreConfigIndex /> },
            { path: 'storeConfig', element: <StoreConfigureIndex /> },
            { path: 'tradingHours', element: <TradingHoursIndex /> },
            { path: 'posConfig', element: <PosConfigIndex /> },
          ],
        },
        {
          path: 'time',
          children: [
            { element: <Navigate to="/dashboard/store/timeduration" replace />, index: true },
            { path: 'timeduration', element: <TimeClockIndex /> },
            { path: 'timeclock', element: <TimeClocksIndex /> },

            { path: 'approvedReports', element: <ApprovedReport /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: 'new', element: <InvoiceCreate /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: ':id', element: <InvoiceDetails /> },
          ],
        },
        {
          path: 'roaster',
          children: [
            { element: <Navigate to="/dashboard/roaster/list" replace />, index: true },
            { path: 'list', element: <RoasterPageIndex /> },
            { path: 'userNote', element: <UserNoteIndex /> },
            { path: 'leaveRequest', element: <LeaveRequest /> },
            { path: 'shiftPickupRequest', element: <ShiftRequest /> },
            { path: 'shiftDropRequest', element: <ShiftDropRequests /> },
            { path: 'quickSummary', element: <QuickSummaryIndex /> },
          ],
        },
        {
          path: 'stock',
          children: [
            { element: <Navigate to="/dashboard/stock/list" replace />, index: true },
            { path: 'list', element: <StockHomePageIndex /> },

            {
              path: 'ingredients',
              children: [
                { element: <Navigate to="/dashboard/stock/ingredients/list" replace />, index: true },
                { path: 'list', element: <StockIngredientsIndex /> },
              ],
            },
            { path: 'receipe', element: <RecipeIndex /> },
          ],
        },

        {
          path: 'food',
          children: [
            { element: <Navigate to="/dashboard/food/list" replace />, index: true },
            { path: 'list', element: <FoodMenuAndReceipIndex /> },

            {
              path: 'category',
              children: [
                { element: <Navigate to="/dashboard/food/category/list" replace />, index: true },
                { path: 'list', element: <FoodCategoryIndex /> },
              ],
            },
            { path: 'menuEngineering', element: <MenuEngineeringIndex /> },
            { path: 'menuCategory', element: <FoodMenuAndCategoryIndex /> },
            { path: 'cogsReport', element: <FoodCogsReport /> },
            { path: 'dealOffer', element: <DealOfferIndex /> },
            { path: 'createDealOffer', element: <CreateDealOffer /> },
            { path: 'editDeal/:id', element: <EditDealOffer /> },
            { path: 'mostLikedMenus', element: <MostLikedMenusIndex /> },
            { path: 'recommendedMenus', element: <RecommendedMenuIndex /> },
          ],
        },

        {
          path: 'operationalCost',
          children: [
            { element: <Navigate to="/dashboard/operationalCost/runningCost" replace />, index: true },
            { path: 'runningCost', element: <RunningCost /> },
            { path: 'fixedCost', element: <FixedCost /> },
            // { path: 'additionalRunningCost', element: <AdditionalRunningCost /> },
          ],
        },
        {
          path: 'report',
          children: [
            { element: <Navigate to="/dashboard/report/list" replace />, index: true },
            { path: 'list', element: <ReportIndex /> },
            { path: 'insights', element: <InsightReport /> },
            { path: 'userWork', element: <UserWorkInfo /> },
            { path: 'userWorkReport', element: <UserWorkReportIndex /> },
          ],
        },
        {
          path: 'sales',
          children: [
            { element: <Navigate to="/dashboard/sales/list" replace />, index: true },
            { path: 'list', element: <SalesPageIndex /> },
            { path: 'itemSummary', element: <MenuSalesDetails /> },
            { path: 'storeCashdrawer', element: <StoreCashDrawer /> },
            { path: 'driverIncentive', element: <DriverIncentive /> },
            { path: 'payType', element: <PayTypeIndex /> },

            { path: 'onlineSalesPlatform', element: <SalesPlatform /> },
            { path: 'editSales/:id', element: <EditSales /> },
            { path: 'addSales', element: <AddSales /> },
          ],
        },

        {
          path: 'documents',
          children: [
            { element: <Navigate to="/dashboard/documents/guidelines" replace />, index: true },
            { path: 'guidelines', element: <StoreGuidelines /> },
            { path: 'logs', element: <LogIndex /> },
            { path: 'checklist', element: <ChecklistIndex /> },
            // { path: 'checklist', element: <CheckList /> },
            // { path: 'createChecklist', element: <CreateChecklist /> },
            // { path: 'editChecklist/:id', element: <EditChecklist /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '/', element: <Navigate to="/auth/login" replace /> },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));

// USER
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));

const UserRequests = Loadable(lazy(() => import('pages/dashboard/user')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const StaffCreate = Loadable(lazy(() => import('pages/dashboard/staffCreate')));
const UserEdit = Loadable(lazy(() => import('../pages/dashboard/UserEdit')));

// Departments
const UserDepartmentRole = Loadable(lazy(() => import('../pages/dashboard/Department/UserDepartmentRole')));
const DepartmentRoles = Loadable(lazy(() => import('../pages/dashboard/Department/DepartmentRoles')));

// Store
const SpecialDay = Loadable(lazy(() => import('../pages/dashboard/Store/SpecialDay')));
const Break = Loadable(lazy(() => import('../pages/dashboard/Store/Break')));
const StoreDetail = Loadable(lazy(() => import('../pages/dashboard/Store/StoreDetail')));

// Time
// const TimeDuration = Loadable(lazy(() => import('../pages/dashboard/Time/TimeDuration')));
// const EditApprovalTime = Loadable(lazy(() => import('../pages/dashboard/Time/EditTimeApproval')));

// MAIN

const Page404 = Loadable(lazy(() => import('../pages/Page404')));
