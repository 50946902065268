import { Box, Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { Link as RouterLink } from 'react-router-dom';
import Iconify from 'components/Iconify';
import React, { useState } from 'react';
import { PATH_DASHBOARD } from 'routes/paths';
import CreateDealOffer from 'sections/@dashboard/DealOffer/CreateDealOffer';

function DealOfferHeader() {
  const [openAdd, setOpenAdd] = useState(false);
  return (
    <>
      <HeaderBreadcrumbs
        heading="Deals & Offers"
        links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Food' }]}
        action={
          <Box className="invoiceListHeadingButtons">
            <Button
              variant="contained"
              color="warning"
              startIcon={<Iconify icon={'gg:reorder'} />}
              type="submit"
              form="dealReOrder"
              id="reOrderDeal"
            >
              Reorder
            </Button>
            <Button
              variant="contained"
              color="primary"
              component={RouterLink}
              startIcon={<Iconify icon="ic:outline-add" />}
              // onClick={() => setOpenAdd(true)}
              to={PATH_DASHBOARD.food.createDealOffer}
            >
              Deal & Offer
            </Button>
          </Box>
        }
      />
      {openAdd && <CreateDealOffer open={openAdd} handleClose={() => setOpenAdd(false)} />}
    </>
  );
}

export default DealOfferHeader;
