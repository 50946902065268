// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components

import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  users: getIcon('ic_users'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  food: getIcon('ic_foods'),
  stocks: getIcon('ic_stocks'),
  reports: getIcon('ic_reports'),
  announcement: getIcon('ic_announcement'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
      { title: 'calender', path: PATH_DASHBOARD.general.calendar, icon: ICONS.calendar },
      { title: 'announcement', path: PATH_DASHBOARD.general.announcement, icon: ICONS.announcement },
      {
        title: 'document',
        path: PATH_DASHBOARD.documents.root,
        icon: ICONS.kanban,
        children: [
          { title: 'guidelines', path: PATH_DASHBOARD.documents.guidelines },
          { title: 'logs', path: PATH_DASHBOARD.documents.logs },
          { title: 'checklist', path: PATH_DASHBOARD.documents.checklist },
        ],
      },
    ],
  },

  // Department
  {
    subheader: 'Department',
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // Department
      {
        title: 'department',
        path: PATH_DASHBOARD.department.root,
        icon: ICONS.users,
        children: [
          { title: 'Departments & Roles', path: PATH_DASHBOARD.department.departmentRole },
          { title: 'Department-User-role', path: PATH_DASHBOARD.department.depUserRole },
          { title: 'Department-Role-Feature', path: PATH_DASHBOARD.department.depRoleFeature },
        ],
      },
      // USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: ' User list', path: PATH_DASHBOARD.user.list },
          { title: 'Account', path: PATH_DASHBOARD.user.account },
          { title: 'Request List', path: PATH_DASHBOARD.user.requestList },
          { title: 'HR & OHNS', path: PATH_DASHBOARD.user.hrOhns },
        ],
      },

      // Store
      {
        title: 'store',
        path: PATH_DASHBOARD.store.root,
        icon: ICONS.cart,
        children: [
          { title: 'Special Days', path: PATH_DASHBOARD.store.specialday },
          { title: 'Break', path: PATH_DASHBOARD.store.break },
          { title: 'Store Details', path: PATH_DASHBOARD.store.storedetail },
          { title: 'Zones', path: PATH_DASHBOARD.store.zones },
          { title: 'Store Suppliers', path: PATH_DASHBOARD.store.storeSuppliers },
          // { title: 'Half/Half config', path: PATH_DASHBOARD.store.halfAndHalf },
          { title: 'Store Configure', path: PATH_DASHBOARD.store.storeConfig },
          // { title: 'Trading Hours', path: PATH_DASHBOARD.store.tradingHours },
          { title: 'pos Config', path: PATH_DASHBOARD.store.posConfig },
        ],
      },
      {
        title: 'Roster',
        path: PATH_DASHBOARD.roaster.root,
        icon: ICONS.booking,
        children: [
          { title: 'list', path: PATH_DASHBOARD.roaster.list },
          { title: 'Quick Summary', path: PATH_DASHBOARD.roaster.quickSummary },
          { title: 'User Notes', path: PATH_DASHBOARD.roaster.userNote },
          { title: 'Leave Requests', path: PATH_DASHBOARD.roaster.leaveRequest },
          { title: 'Dropped Shift', path: PATH_DASHBOARD.roaster.shiftDropRequest },
          { title: 'Pickup Request', path: PATH_DASHBOARD.roaster.shiftPickupRequest },
        ],
      },

      // Time
      {
        title: 'Time',
        path: PATH_DASHBOARD.time.root,
        icon: ICONS.calendar,
        children: [
          // { title: 'Time Clock', path: PATH_DASHBOARD.time.timeduration },
          { title: 'Time Clock', path: PATH_DASHBOARD.time.timeclock },
          // { title: 'Approved Reports', path: PATH_DASHBOARD.time.approvedReports },
        ],
      },

      {
        title: 'invoice',
        path: PATH_DASHBOARD.invoice.root,
        icon: ICONS.invoice,
        children: [
          { title: 'list', path: PATH_DASHBOARD.invoice.list },
          { title: 'create', path: PATH_DASHBOARD.invoice.new },
        ],
      },

      {
        title: 'stock',
        path: PATH_DASHBOARD.stock.root,
        icon: ICONS.stocks,
        children: [
          { title: 'Stocks', path: PATH_DASHBOARD.stock.list },
          { title: 'Ingredients', path: PATH_DASHBOARD.stock.ingredients },
          { title: 'Receipe Inside Recipe', path: PATH_DASHBOARD.stock.receipe },
        ],
      },

      {
        title: 'food',
        path: PATH_DASHBOARD.food.root,
        icon: ICONS.food,
        children: [
          // { title: 'Category & Sizes', path: PATH_DASHBOARD.food.categories },
          // { title: 'Menu & Receipe', path: PATH_DASHBOARD.food.list },
          { title: 'Modifier Group', path: PATH_DASHBOARD.food.menuEngineering },
          { title: 'Menu & Category', path: PATH_DASHBOARD.food.menuCategory },
          { title: 'Cogs Report', path: PATH_DASHBOARD.food.cogsReport },
          { title: 'Deals ', path: PATH_DASHBOARD.food.dealOffer },
          { title: 'Most Liked Menus', path: PATH_DASHBOARD.food.mostLikedMenus },
          { title: 'Recommended Menus', path: PATH_DASHBOARD.food.recommendedMenus },
        ],
      },
      {
        title: 'Sales',
        path: PATH_DASHBOARD.sales.root,
        icon: ICONS.ecommerce,
        children: [
          { title: 'Sales Report', path: PATH_DASHBOARD.sales.list },
          // { title: 'Menu Item Summary', path: PATH_DASHBOARD.sales.itemSummary },
          { title: 'Sales Platform', path: PATH_DASHBOARD.sales.onlineSalesPlatform },
          { title: 'Cash Drawer', path: PATH_DASHBOARD.sales.storeCashDrawer },
          // { title: 'Pay Type', path: PATH_DASHBOARD.sales.payType, icon: ICONS.menuItem },
          { title: 'Pay Type', path: PATH_DASHBOARD.sales.payType },
          { title: 'Driver Incentive', path: PATH_DASHBOARD.sales.driverIncentive },
        ],
      },

      {
        title: 'Operational Cost',
        path: PATH_DASHBOARD.operationalCost.root,
        // icon: ICONS.banking,
        // icon: ICONS.ecommerce,
        icon: ICONS.analytics,
        // icon: ICONS.menuItem,
        children: [
          { title: 'Running Cost', path: PATH_DASHBOARD.operationalCost.runningCost },
          { title: 'Fixed Cost', path: PATH_DASHBOARD.operationalCost.fixedCost },
          // { title: 'Additional Running Cost', path: PATH_DASHBOARD.operationalCost.additionalRunningCost },
        ],
      },

      {
        title: 'Report',
        path: PATH_DASHBOARD.report.root,
        // icon: ICONS.banking,
        // icon: ICONS.ecommerce,
        icon: ICONS.reports,
        // icon: ICONS.menuItem,
        children: [
          { title: 'List', path: PATH_DASHBOARD.report.list },
          { title: 'Insights', path: PATH_DASHBOARD.report.insights },
          // { title: 'User Work Report', path: PATH_DASHBOARD.report.userWork },+
          { title: 'User Work Report', path: PATH_DASHBOARD.report.userworkReport },
          // { title: 'Additional Running Cost', path: PATH_DASHBOARD.operationalCost.additionalRunningCost },
        ],
      },
    ],
  },
];

export default navConfig;
