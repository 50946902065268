import { Box, Card, Divider, Grid, InputAdornment, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { RHFTextField } from 'components/hook-form';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import moment from 'moment';
import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import SalesSummaryFilter from './SalesSummaryFilter';
import PlatformSummary from './PlatformSummary';
import DeliverySummary from './DeliverySummary';

SalesSummary.propTypes = {
  date: PropTypes.array,
};
export default function SalesSummary({ date }) {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const { salesSummary, salesSummaryLoading } = useSelector((state) => state.sales);

  const defaultValues = useMemo(
    () => ({
      totalStoreSales: 0,
      totalPcEft: 0,
      // totalVoucher: 0,
      // totalStoreOnline: 0,
      totalPayouts: 0,
      // totalUnpaid: 0,
      // totalOther: 0,
      totalOnlinePlatformSales: 0,
      totalActualCash: 0,
      totalVarianceAmount: 0,
      totalDeliveryCount: 0,
      platformSales: [],
      DeliveryEntries: [],
      totalPaymentTypes: [],
      // deliveryEntries: salesSummary?.deliveryEntries || [],
    }),
    []
  );

  const methods = useForm({
    defaultValues,
  });

  const { reset, control } = methods;

  useEffect(() => {
    if (!date.length) return;
    if (moment(date[6].label).diff(moment(new Date()), 'days') < 0) {
      setStartDate(moment(date[0].label).format('YYYY-MM-DD'));
      setEndDate(moment(date[6].label).format('YYYY-MM-DD'));
    } else {
      setStartDate(date[0].label);
      setEndDate(moment(new Date()).format('YYYY-MM-DD'));
    }
  }, [date]);

  useEffect(() => {
    if (!Object.keys(salesSummary)?.length) reset({ ...defaultValues });
    reset({ ...salesSummary });
  }, [reset, salesSummary, defaultValues]);

  const totalVarianceAmount = methods.watch('totalVarianceAmount');

  const watchPaymentTypes = useWatch({
    control,
    name: 'totalPaymentTypes',
  });

  return (
    <Card>
      <SalesSummaryFilter startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
      <Divider sx={{ my: 1, borderStyle: 'dashed' }} />

      <FormProvider {...methods}>
        {salesSummaryLoading ? (
          <CustomLoadingScreen height="30vh" />
        ) : (
          <Box style={{ paddingLeft: '5px', paddingRight: '5px', marginLeft: '10px', marginBottom: '40px' }}>
            <Typography variant="h6" fontWeight={'600'} textAlign={'center'}>
              Sales Summary Report starting from {moment(startDate).format('dddd, YYYY-MMM-DD')} to{' '}
              {moment(endDate).format('dddd, YYYY-MMM-DD')}
            </Typography>
            <Divider sx={{ my: 1, borderStyle: 'dashed' }} />
            <Stack spacing={1}>
              <Grid container spacing={1} style={{ marginTop: '10px', marginLeft: '10px' }}>
                <Grid item xs={12} sm={6} md={3}>
                  <RHFTextField
                    disabled
                    name={`totalStoreSales`}
                    label="Total Store Sales"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <RHFTextField
                    disabled
                    name={`totalActualCash`}
                    label="Total Actual Cash"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <RHFTextField
                    disabled
                    name="totalPayouts"
                    label="Total Payouts"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
            </Stack>
            <Divider sx={{ my: 1, borderStyle: 'dashed' }} />

            <Box sx={{ p: 2 }}>
              <Typography variant="h6" sx={{ color: 'text.disabled', mb: 2 }}>
                Payment Types:
              </Typography>
              <Grid container spacing={2}>
                {watchPaymentTypes?.map((paymentType, index) => (
                  <Grid item xs={12} sm={4} md={2} key={index}>
                    <RHFTextField
                      disabled
                      name={`totalPaymentTypes[${index}].amount`}
                      label={paymentType.title}
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Grid>
                ))}
                <Grid item xs={12} sm={4} md={2}>
                  <RHFTextField
                    disabled
                    name="totalPcEft"
                    label="Total Pc Eft"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ my: 1, borderStyle: 'dashed' }} />
            <Stack spacing={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} style={{ marginTop: '20px', marginLeft: '20px' }}>
                  <RHFTextField
                    style={{ color: totalVarianceAmount < 0 ? 'red' : 'green' }}
                    disabled
                    name={`totalVarianceAmount`}
                    label="Total Variance Amount"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      '& .MuiInputBase-root.Mui-disabled': {
                        '& > fieldset': {
                          borderColor: totalVarianceAmount < 0 ? 'red' : 'green',
                        },
                      },
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
            </Stack>
            <Divider sx={{ my: 1, borderStyle: 'dashed' }} />
            <PlatformSummary />
            <Stack spacing={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3} style={{ marginLeft: '20px' }}>
                  <RHFTextField
                    disabled
                    name="totalOnlinePlatformSales"
                    label="Total Platform Sales"
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                  />
                </Grid>
              </Grid>
            </Stack>
            <Divider sx={{ my: 1, borderStyle: 'dashed' }} />
            <DeliverySummary />
          </Box>
        )}
      </FormProvider>
    </Card>
  );
}
