import { yupResolver } from '@hookform/resolvers/yup';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isBefore } from 'date-fns';
import { FormProvider, RHFCheckbox } from 'components/hook-form';
import { Box, Button, FormLabel, Stack, TextField } from '@mui/material';
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import CustomMultipleAutoCompleteField from 'components/CustomComponents/CustomAutoCompleteField';
import { createAnnouncement, updateAnnouncement } from 'redux/slices/announcementSlice';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import moment from 'moment';

const schema = yup.object().shape({
  title: yup.string().required(''),
  description: yup.string().required(''),
  isTemporary: yup.boolean().default(false),
});

function PrivateAnnouncementCreateForm({ handleClose, isEdit, updateData }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const { allUsers } = useSelector((state) => state.user);

  const { postLoading } = useSelector((state) => state.announcement);
  const today = new Date();
  const defaultValues = {
    title: '',
    description: '',
    isTemporary: false,
    type: 'PRIVATE',
    startDate: moment(today).startOf('day'),
    endDate: moment(today).add(2, 'days').endOf('day'),
  };
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { handleSubmit, control, watch, reset } = methods;

  const CreateAnnouncementHandler = (data) => {
    if (!isEdit) {
      const finalData = {
        ...data,
        isPermanent: !data.isTemporary,
        startDate: data?.isTemporary ? moment(data?.startDate).format('YYYY-MM-DDTHH:mm:ss') : null,
        endDate: data?.isTemporary ? moment(data?.endDate).format('YYYY-MM-DDTHH:mm:ss') : null,
        departmentId,
        employeeIds: data?.employeeIds?.map((item) => item.id),
      };
      dispatch(createAnnouncement({ finalData, handleClose, isPrivate: true }));
    } else {
      const finalData = {
        ...data,
        departmentId,
        id: updateData?.id,
      };
      dispatch(updateAnnouncement({ finalData, handleClose, isPrivate: true }));
    }
  };

  const values = watch();

  const isDateError = isBefore(new Date(values.endDate), new Date(values.startDate));
  const { isTemporary } = values;

  useEffect(() => {
    if (!isEdit) return;
    reset({
      title: updateData?.title,
      description: updateData?.description,
    });
  }, [isEdit, reset, updateData]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(CreateAnnouncementHandler)}>
      <Stack my="1rem" gap="1rem">
        Title:
      </Stack>
      <Stack my="1rem" gap="1rem">
        <Controller
          name="title"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <CKEditor
                editor={Editor}
                data={field.value}
                onChange={(event, editor) => {
                  const content = editor.getData();
                  field.onChange(content);
                }}
              />
              {error && (
                <FormLabel style={{ marginLeft: '10px', marginTop: '5px', fontSize: '.8rem' }} sx={{ color: 'red' }}>
                  Title *
                </FormLabel>
              )}
            </div>
          )}
        />
      </Stack>
      <Stack my="1rem" gap="1rem">
        Description:
      </Stack>

      <Stack my="1rem" gap="1rem">
        <Controller
          name="description"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <CKEditor
                editor={Editor}
                data={field.value}
                onChange={(event, editor) => {
                  const content = editor.getData();
                  field.onChange(content);
                }}
              />
              {error && (
                <FormLabel style={{ marginLeft: '10px', marginTop: '5px', fontSize: '.8rem' }} sx={{ color: 'red' }}>
                  Description *
                </FormLabel>
              )}
            </div>
          )}
        />
      </Stack>
      {!isEdit && (
        <Stack>
          <CustomMultipleAutoCompleteField
            name="employeeIds"
            label="Select Users"
            multiple
            options={allUsers?.users}
            getOptionLabel={(option) => option.firstName}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <img width="20" src={option.thumbnailURL} alt="" />
                {option.firstName} {option.lastName} + {option.phone}
              </Box>
            )}
          />
        </Stack>
      )}

      {!isEdit && (
        <>
          <Stack>
            <div>
              <RHFCheckbox name="isTemporary" disabled={isEdit} />
              <span style={{ marginLeft: '-15px' }}>Is Temporary</span>
            </div>
          </Stack>
          {isTemporary && (
            <Stack spacing={2} direction={'row'}>
              <Controller
                name="startDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <MobileDateTimePicker
                      {...field}
                      disabled={isEdit}
                      disablePast
                      label="Start date"
                      inputFormat="dd/MMM/yyyy hh:mm a"
                      DialogProps={{ sx: { zIndex: 10000 } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!isDateError}
                          helperText={isDateError && 'Start date should be before end date'}
                        />
                      )}
                    />
                    {error && (
                      <FormLabel
                        style={{ marginLeft: '10px', marginTop: '5px', fontSize: '.8rem' }}
                        sx={{ color: 'red' }}
                      >
                        {error?.message}
                      </FormLabel>
                    )}
                  </div>
                )}
              />
              <Controller
                name="endDate"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <MobileDateTimePicker
                      {...field}
                      disabled={isEdit}
                      label="End date"
                      disablePast
                      // minDate={start.setHours(00, 30, 00, 000)}
                      inputFormat="dd/MMM/yyyy hh:mm a"
                      DialogProps={{ sx: { zIndex: 10000 } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={!!isDateError}
                          helperText={isDateError && 'End date must be later than start date'}
                        />
                      )}
                    />
                    {error && (
                      <FormLabel
                        style={{ marginLeft: '10px', marginTop: '5px', fontSize: '.8rem' }}
                        sx={{ color: 'red' }}
                      >
                        {error?.message}
                      </FormLabel>
                    )}
                  </div>
                )}
              />
            </Stack>
          )}
        </>
      )}
      <Stack my="1rem" gap="1rem">
        <Button variant="contained" color="primary" type="submit">
          {/* eslint-disable-next-line no-nested-ternary */}
          {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : isEdit ? 'Update' : 'Create'}
        </Button>
      </Stack>
    </FormProvider>
  );
}

PrivateAnnouncementCreateForm.propTypes = {
  handleClose: PropTypes.func,
  isEdit: PropTypes.bool,
  updateData: PropTypes.object,
};

export default PrivateAnnouncementCreateForm;
