import CheckUserDepartment from 'components/CustomComponents/CheckUserDepartment';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getDriverIncentive, storeDetails } from 'redux/slices/storeSlice';
import { PATH_DASHBOARD } from 'routes/paths';
import DriverIncentivesCard from 'sections/@dashboard/store/DriverIncentivesCard';

export default function DriverIncentive() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(storeDetails());
    dispatch(getDriverIncentive());
  }, [dispatch]);
  return (
    <CheckUserDepartment>
      <Page title="Driver Incentive">
        <HeaderBreadcrumbs
          heading="Driver Incentive"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Store' },
            // { name: 'Driver-Incentive' }
          ]}
        />
        <DriverIncentivesCard />
      </Page>
    </CheckUserDepartment>
  );
}
