import { Box, Card, Divider, TableContainer, Typography } from '@mui/material';

import Scrollbar from 'components/Scrollbar';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { storeSuppliers } from 'redux/slices/storeSlice';
import ActiveStoreSuppliers from './ActiveStoreSuppliers';

export default function StoreSuppliersBody() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(storeSuppliers());
  }, [dispatch]);

  return (
    <Box style={{ marginTop: '2.5rem' }}>
      <Typography variant="h6" gutterBottom>
        Store Suppliers List
        <Divider />
      </Typography>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 900, position: 'relative' }}>
            <ActiveStoreSuppliers />
          </TableContainer>
        </Scrollbar>
      </Card>
    </Box>
  );
}
