import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { toast } from 'react-toastify';
import apiURL from 'api';

export const getReport = createAsyncThunk('report/getReport', async (props) => {
  // try {
  const response = await apiURL.post(`/report`, props);
  return response.data;
  // } catch (error) {
  // return toast.error(error?.response?.data?.message);
  // }
});

export const getInsights = createAsyncThunk('report/getInsights', async (props) => {
  const response = await apiURL.post(`/report/insights`, props);
  return response.data;
});

export const getUserWorkReport = createAsyncThunk('report/getUserWorkReport', async (props) => {
  const response = await apiURL.post(`/report/usersWorkInfo`, props);
  return response.data;
});

export const getIndividualUserWorkReport = createAsyncThunk('report/getIndividualUserWorkReport', async (props) => {
  const response = await apiURL.post(`/report/usersWorkInfo/${props?.id}?pageNo=${props?.pageNo - 1}&pageSize=30`, {
    startDate: props?.startDate,
    endDate: props?.endDate,
    departmentId: props?.departmentId,
  });
  return response.data;
});

export const getAllUserWorkReport = createAsyncThunk('report/getAllUserWorkReport', async (props) => {
  const response = await apiURL.post(
    `/report/allUsersWorkInfo/${props?.status}?pageNo=${props?.pageNo - 1 || 0}&pageSize=25`,
    props
  );
  return response.data;
});

const reportSlice = createSlice({
  name: 'report',
  initialState: {
    insights: {},
    report: {},
    userWorkReport: [],
    singleUserReport: [],
    loading: false,
    singleReportLoading: false,

    alluserWorkReport: {},
  },

  extraReducers: {
    [getAllUserWorkReport.pending]: (state) => {
      state.loading = true;
      state.alluserWorkReport = {};
    },
    [getAllUserWorkReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.alluserWorkReport = action.payload;
    },
    [getAllUserWorkReport.rejected]: (state) => {
      state.loading = false;
      state.alluserWorkReport = {};
    },

    [getReport.pending]: (state) => {
      state.loading = true;
    },
    [getReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.report = action.payload;
    },
    [getReport.rejected]: (state) => {
      state.loading = false;
    },
    [getInsights.pending]: (state) => {
      state.loading = true;
    },
    [getInsights.fulfilled]: (state, action) => {
      state.loading = false;
      state.insights = action.payload;
    },
    [getInsights.rejected]: (state) => {
      state.loading = false;
    },
    [getUserWorkReport.pending]: (state) => {
      state.userWorkReport = [];
      state.loading = true;
    },
    [getUserWorkReport.fulfilled]: (state, action) => {
      state.loading = false;
      state.userWorkReport = action.payload;
    },
    [getUserWorkReport.rejected]: (state) => {
      state.loading = false;
    },
    [getIndividualUserWorkReport.pending]: (state) => {
      state.singleUserReport = [];
      state.singleReportLoading = true;
    },
    [getIndividualUserWorkReport.fulfilled]: (state, action) => {
      state.singleReportLoading = false;
      state.singleUserReport = action.payload;
    },
    [getIndividualUserWorkReport.rejected]: (state) => {
      state.singleReportLoading = false;
    },
  },
});

export default reportSlice.reducer;
