import { Icon } from '@iconify/react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'redux/store';

function DepartmentNavList({ group }) {
  const { selectedDepartment, department } = useSelector((state) => state.userDepartment);

  return (
    <Box className="departmentNavListClass">
      <Accordion>
        <AccordionSummary expandIcon={<Icon icon="material-symbols:expand-more" />} aria-controls="panel1a-content">
          <p>{selectedDepartment?.name}</p>
        </AccordionSummary>
        <AccordionDetails>
          <ul>
            {department?.map((obj) => (
              <li key={obj.id}>{obj?.name}</li>
            ))}
          </ul>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

export default DepartmentNavList;
