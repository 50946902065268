import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { successToastTime, errorToastTime } from 'config';
import apiURL from '../../api';

export const getOnlinePlatforms = createAsyncThunk('sales/getOnlinePlatforms', async (departmentId) => {
  const response = await apiURL.get(`/sales/onlinePlatform/${departmentId}`);
  return response.data;
});

export const getSalesForecast = createAsyncThunk('sales/getSalesForecast', async (departmentId) => {
  const response = await apiURL.get(`/sales/forecast/${departmentId}`);
  return response.data;
});

export const updateSalesForecast = createAsyncThunk('sales/updateSalesForecast', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put(`/sales/forecast`, props.data);
    if (response.status === 200) {
      thunkAPI.dispatch(getSalesForecast(props.data?.departmentId));
      props.isSuccess();
    }
    toast.success('Forecast Updated Successfully', { autoClose: successToastTime });
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getSalesList = createAsyncThunk('sales/getSalesList', async (props) => {
  const response = await apiURL.post(`/sales/get`, props);
  return response.data;
});

export const postSales = createAsyncThunk('sales/postSales', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/sales/sales`, props.finalData);
    if (response.status === 200) {
      props.isSuccess(true);
      toast.success('Sales Updated Successfully', { autoClose: successToastTime });
      thunkAPI.dispatch(
        getSalesList({
          departmentId: props.finalData.departmentId,
          storeId: props.finalData.storeId,
          date: props.finalData.date,
        })
      );
    }
    // toast.success('Sales Added Successfully');
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getSalesSummary = createAsyncThunk('sales/getSalesSummary', async (props) => {
  // try {
  const response = await apiURL.post(`/report/sales`, props);
  return response.data;
  // } catch (error) {
  // return toast.error(error?.response?.data?.message);
  // }
});

export const getPaymentType = createAsyncThunk('sales/getPaymentType', async (props) => {
  const response = await apiURL.get(`/sales/paymentType/${props?.departmentId}`);
  return response.data;
});

export const createPaymentType = createAsyncThunk('sales/createPaymentType', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/sales/paymentType`, props?.finalData);
    if (response.status === 200) {
      thunkAPI.dispatch(getPaymentType({ departmentId: props?.finalData?.departmentId }));
      props?.closeModal();
      // eslint-disable-next-line no-unused-expressions
      props?.edit
        ? toast.success('Payment Type Updated Successfully', { autoClose: successToastTime })
        : toast.success('Payment Type Added Successfully', { autoClose: successToastTime });
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deletePaymentType = createAsyncThunk('sales/deletePaymentType', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/sales/paymentType/${props?.departmentId}/${props?.id}`);
    if (response.status === 200) {
      thunkAPI.dispatch(getPaymentType({ departmentId: props?.departmentId }));
      toast.success('Payment Type Deleted Successfully', { autoClose: successToastTime });
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteSalesPayout = createAsyncThunk('sales/deleteSalesPayout', async (props) => {
  try {
    const response = await apiURL.delete(`/sales/payout/${props?.departmentId}/${props?.id}`);
    if (response.status === 200) {
      props?.isSuccess(true);
      toast.success('Payout Deleted Successfully', { autoClose: successToastTime });
    }
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

const salesSlice = createSlice({
  name: 'sales',
  initialState: {
    loading: false,
    postLoading: false,
    salesSummaryLoading: false,
    onlinePlatforms: [],
    salesForecast: [],
    dailySales: {},
    salesSummary: {},

    paymentType: [],
  },
  extraReducers: {
    [getPaymentType.pending]: (state) => {
      state.loading = true;
      state.paymentType = [];
    },
    [getPaymentType.fulfilled]: (state, action) => {
      state.loading = false;
      state.paymentType = action.payload;
    },
    [getPaymentType.rejected]: (state) => {
      state.loading = false;
      state.paymentType = [];
    },

    [createPaymentType.pending]: (state) => {
      state.postLoading = true;
    },
    [createPaymentType.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [createPaymentType.rejected]: (state) => {
      state.postLoading = false;
    },

    [getOnlinePlatforms.pending]: (state) => {
      state.loading = true;
    },
    [getOnlinePlatforms.fulfilled]: (state, action) => {
      state.loading = false;
      state.onlinePlatforms = action.payload.onlinePlatforms;
    },
    [getOnlinePlatforms.rejected]: (state) => {
      state.loading = false;
    },

    [getSalesForecast.pending]: (state) => {
      state.loading = true;
    },
    [getSalesForecast.fulfilled]: (state, action) => {
      state.loading = false;
      state.salesForecast = action.payload;
    },
    [getSalesForecast.rejected]: (state) => {
      state.loading = false;
    },

    [updateSalesForecast.pending]: (state) => {
      state.postLoading = true;
    },
    [updateSalesForecast.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateSalesForecast.rejected]: (state) => {
      state.postLoading = false;
    },

    [getSalesList.pending]: (state) => {
      state.loading = true;
      state.dailySales = {};
    },
    [getSalesList.fulfilled]: (state, action) => {
      state.loading = false;
      state.dailySales = action.payload;
    },
    [getSalesList.rejected]: (state) => {
      state.loading = false;
      state.dailySales = {};
    },
    [getSalesSummary.pending]: (state) => {
      state.salesSummary = {};
      state.salesSummaryLoading = true;
    },
    [getSalesSummary.fulfilled]: (state, action) => {
      state.salesSummaryLoading = false;
      state.salesSummary = action.payload;
    },
    [getSalesSummary.rejected]: (state) => {
      state.salesSummaryLoading = false;
    },
  },
});

export default salesSlice.reducer;
