import {
  Box,
  Button,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Scrollbar from 'components/Scrollbar';
import { TableNoData } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDatedTaskList, getTaskLists } from 'redux/slices/storeDocumentSlice';

function DeletedTasklist({ choosenDate }) {
  const [pageNo, setPageNo] = React.useState(1);
  const selectedDate = moment(choosenDate).format('YYYY-MM-DD');

  const today = moment(new Date()).format('YYYY-MM-DD');

  const isNotToday = selectedDate < today || selectedDate > today;
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const { taskLists, loading } = useSelector((state) => state.storeDocument);

  useEffect(() => {
    if (!departmentId) return;
    if (isNotToday) {
      dispatch(getDatedTaskList({ departmentId, status: 'DELETED', taskDate: choosenDate }));
    } else {
      dispatch(getTaskLists({ departmentId, status: 'DELETED', pageNo }));
    }
  }, [dispatch, departmentId, isNotToday, choosenDate, pageNo]);
  return (
    <>
      <Stack spacing={2} style={{ marginTop: '20px' }}>
        {loading ? (
          <CustomLoadingScreen height="20vh" />
        ) : (
          <Scrollbar>
            {taskLists?.checkListCategories?.map((category, index) => (
              <>
                <Button
                  disableRipple
                  style={{
                    padding: '5px',
                    color: 'white',
                    backgroundColor: `${category?.colorCode}`,
                    borderRadius: '5px',
                    marginLeft: '8px',
                    marginBottom: '5px',
                  }}
                >
                  {category?.name}
                </Button>

                <TableContainer key={index}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ width: '30px' }}>S.N.</TableCell>
                        <TableCell style={{ width: '250px' }}>Name</TableCell>
                        <TableCell style={{ width: '300px' }}>Note</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {category?.tasks?.map((item, index) => (
                        <TableRow key={index} hover>
                          <TableCell>{index + 1}.</TableCell>
                          <TableCell style={{ maxWidth: '250px' }}>
                            {item.title?.length > 40 ? (
                              <Tooltip title={item?.title} placement="right">
                                <Typography noWrap variant="body2">
                                  {`${item.title.slice(0, 40)}..`}
                                </Typography>
                              </Tooltip>
                            ) : (
                              item.title
                            )}
                          </TableCell>
                          <TableCell>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {item?.note ? (
                              item?.note?.length > 25 ? (
                                <Tooltip title={item?.note} placement="right">
                                  <Typography noWrap variant="body2">
                                    {item.note?.length > 25 ? `${item.note.slice(0, 25)}..` : item.note}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                item?.note
                              )
                            ) : (
                              '---'
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ))}
          </Scrollbar>
        )}
        {taskLists?.checkListCategories?.length === 0 ? (
          <Stack display={'flex'} alignItems={'center'}>
            <TableNoData isNotFound={taskLists?.checkListCategories?.length === 0} />
          </Stack>
        ) : (
          <Box
            sx={{ position: 'relative', marginTop: '30px' }}
            justifyContent={'end'}
            alignContent="center"
            display={'flex'}
          >
            <Pagination
              count={taskLists?.paginationInfo?.totalPages}
              page={pageNo}
              onChange={(e, value) => setPageNo(value)}
            />
          </Box>
        )}
      </Stack>
    </>
  );
}

export default DeletedTasklist;
