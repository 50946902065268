import React, { useEffect } from 'react';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import { useDispatch } from 'react-redux';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { getFoodCategory } from 'redux/slices/menuEngineeringSlice';
import { getMenuSizeGroup } from 'redux/slices/menuERSizeAndOptionSlice';
import FoodMenuForm from './FoodMenuForm';

function FoodMenuCreate({ open, handleClose }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getMenuSizeGroup({ departmentId }));
    dispatch(getFoodCategory({ departmentId }));
  }, [dispatch, departmentId]);
  return (
    <CustomDrawer open={open} setOpen={handleClose} title="Create Food Menu" PaperProps={{ sx: { minWidth: '40%' } }}>
      <FoodMenuForm handleClose={handleClose} />
    </CustomDrawer>
  );
}

export default FoodMenuCreate;
