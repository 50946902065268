import Page from 'components/Page';
import React from 'react';
import ReportHeaders from './ReportHeaders';
import ReportCardBody from './ReportCardBody';

function ReportIndex() {
  return (
    <Page title="Report">
      <ReportHeaders />
      <ReportCardBody />
    </Page>
  );
}

export default ReportIndex;
