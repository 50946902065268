import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, TableCell, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { findTotalofEachWeeks } from 'utils/roasterFeatureUtils';
import { useFormContext, useWatch } from 'react-hook-form';
import QuickSummaryRoasterUserWeeklyData from './QuickSummaryRoasterUserWeeklyData';

QuickSummaryRoasterTableCellWithAvatar.propTypes = {
  user: PropTypes.object,
  mainIndex: PropTypes.number,
};

function QuickSummaryRoasterTableCellWithAvatar({ user, mainIndex }) {
  const { quickSummaryWeek } = useSelector((state) => state.quickSummary);

  const { control } = useFormContext();

  const values = useWatch({
    control,
    name: 'users',
  });

  let totalPriceOfWeek = 0;
  quickSummaryWeek?.forEach((element) => {
    const currentWeek = moment(element?.date).format('ddd, DD MMM');
    const getTotalofEverything = findTotalofEachWeeks(currentWeek, values);
    totalPriceOfWeek += getTotalofEverything?.totalPrice;
  });
  return (
    <TableCell style={{ position: 'relative', width: '80px' }}>
      <Box
        sx={{
          display: 'flex',
          gap: '0.5rem',
          flexDirection: 'row',
          alignItems: 'center',
          marginRight: '10px',
        }}
      >
        {user?.thumbnailURL ? (
          <Avatar
            src={user.thumbnailURL}
            style={{
              height: '30px',
              width: '30px',
              borderRadius: '100px',
              marginRight: '2px',
            }}
          />
        ) : (
          <Avatar alt="" src="" style={{ height: '30px', width: '30px', marginRight: '2px' }} />
        )}
        <Tooltip title={`${user?.firstName} ${user?.middleName ?? ''} ${user?.lastName}`} placement="top">
          <p className="quickSummaryUserNames">
            {user?.firstName.split(' ')[0].length > 8
              ? `${user?.firstName.split(' ')[0].slice(0, 8)}..`
              : user?.firstName.split(' ')[0]}
          </p>
        </Tooltip>
      </Box>
      <QuickSummaryRoasterUserWeeklyData totalPriceOfWeek={totalPriceOfWeek} mainIndex={mainIndex} />
    </TableCell>
  );
}

export default QuickSummaryRoasterTableCellWithAvatar;
