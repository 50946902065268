import { Box, Button, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Iconify from 'components/Iconify';
import Label from 'components/Label';
import Scrollbar from 'components/Scrollbar';
import { TableHeadCustom } from 'components/table';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSingleStreet, getStreetList } from 'redux/slices/posConfigSlice';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import CreateNewStreet from './CreateNewStreet';
import EditStreet from './EditStreet';

const TABLE_HEAD = [
  { id: 1, label: 'S.N', align: 'left' },
  { id: 2, label: 'Name', align: 'left' },
  { id: 3, label: 'Do-Delivery', align: 'center' },
  { id: 4, label: 'Actions', align: 'center' },
];

function ManageSuburbDetails({ open, handleClose, suburbId }) {
  const { streetList, streetLoading } = useSelector((state) => state.posConfig);

  const [openNewStreet, setOpenNewStreet] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState({ status: false, id: null });
  const [openEdit, setOpenEdit] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStreetList({ suburbId }));
  }, [suburbId, dispatch]);

  const deleteStreet = () => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        setOpenDeleteModal({ id: null, status: false });
      }
    };
    dispatch(deleteSingleStreet({ id: openDeleteModal?.id, isSuccess: isSuccessful, suburbId }));
  };

  return (
    <CustomDrawer
      open={open}
      setOpen={handleClose}
      title="Manage Suburb Streets "
      PaperProps={{ sx: { minWidth: '60%' } }}
    >
      <Scrollbar>
        <TableContainer>
          <Table stickyHeader size="small">
            <TableHeadCustom headLabel={TABLE_HEAD} />
            <TableBody>
              {streetLoading ? (
                <TableRow>
                  <TableCell colSpan={4}>
                    <CustomLoadingScreen height="20vh" />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {streetList?.map((row, index) => (
                    <TableRow hover key={row.id}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{row?.name}</TableCell>
                      <TableCell align="center">
                        <Label variant="ghost" color={row?.doDelivery ? 'success' : 'error'}>
                          {row?.doDelivery ? 'yes' : 'no'}
                        </Label>
                      </TableCell>
                      <TableCell align="center">
                        <Button
                          style={{ color: '#4caf50', marginLeft: '10px', marginRight: '10px' }}
                          variant="text"
                          startIcon={<Iconify icon="material-symbols:edit-outline" />}
                          onClick={() => {
                            setOpenEdit(true);
                            setSelectedRow(row);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="text"
                          color="error"
                          startIcon={<Iconify icon="mdi:trash-can" />}
                          onClick={() => setOpenDeleteModal({ status: true, id: row?.id })}
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <Stack direction="row" justifyContent="flex-start" sx={{ mt: 3 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={() => setOpenNewStreet(true)}
        >
          New Street
        </Button>
        {openNewStreet && (
          <CreateNewStreet open={openNewStreet} handleClose={() => setOpenNewStreet(false)} suburbId={suburbId} />
        )}
        {openDeleteModal?.status && (
          <CustomDeleteDialog
            open={openDeleteModal?.status}
            handleClose={() => setOpenDeleteModal({ status: false, id: null })}
            onAccept={deleteStreet}
          />
        )}
        {openEdit && (
          <EditStreet
            open={openEdit}
            handleClose={() => setOpenEdit(false)}
            updateData={selectedRow}
            suburbId={suburbId}
          />
        )}
      </Stack>
    </CustomDrawer>
  );
}

export default ManageSuburbDetails;
