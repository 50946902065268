import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { getAllUserss } from 'redux/slices/userSlice';
import PrivateAnnouncementCreateForm from './PrivateAnnouncementCreateForm';

function PrivateAnnouncementCreate({ open, handleClose }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  useEffect(() => {
    if (!departmentId) return;
    dispatch(getAllUserss({ departmentId }));
  }, [departmentId, dispatch]);
  return (
    <CustomOutletModal open={open} onClose={handleClose} zIndex="1600" title="Create Private Announcement">
      <PrivateAnnouncementCreateForm handleClose={handleClose} />
    </CustomOutletModal>
  );
}

export default PrivateAnnouncementCreate;
