import { Stack, Typography } from '@mui/material';
import React from 'react';

function FoodRecipeMenuSizes({ size }) {
  return (
    <Stack>
      <Typography component="p" fontSize={'0.75rem'} fontWeight={'500'}>
        {size?.name}
      </Typography>
    </Stack>
  );
}

export default FoodRecipeMenuSizes;
