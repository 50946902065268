import { Card, Divider, Tab, TableContainer, Tabs } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import { useState } from 'react';
import EquipmentLogs from 'sections/@dashboard/storeDocuments/logs/equipment/EquipmentLogs';
import FirstAidLogs from 'sections/@dashboard/storeDocuments/logs/firstAid/FirstAidLogs';
import VehicleLogs from 'sections/@dashboard/storeDocuments/logs/vehicles/VehicleLogs';

function LogBody() {
  const [tabValue, setTabValue] = useState('0');

  return (
    <Card>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={tabValue}
        onChange={(e, value) => {
          setTabValue(value);
        }}
        sx={{ px: 2, bgcolor: 'Background.neutral' }}
      >
        <Tab label="Vehicles Log" value="0" />
        <Tab label="FirstAid Log" value="1" />
        <Tab label="Equipment Log" value="2" />
      </Tabs>
      <Divider />

      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          {tabValue === '0' && <VehicleLogs />}
          {tabValue === '1' && <FirstAidLogs />}
          {tabValue === '2' && <EquipmentLogs />}
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}

export default LogBody;
