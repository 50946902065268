import { Box, Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import React, { useState } from 'react';
import { PATH_DASHBOARD } from 'routes/paths';
import CreateReceipe from './CreateReceipe';

function ReceipeHeader() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <HeaderBreadcrumbs
        heading="Receipe"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Stocks', href: PATH_DASHBOARD.stock.root },
          { name: 'Receipe' },
        ]}
        action={
          <Box className="invoiceListHeadingButtons">
            <Button
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} />}
              type="submit"
              onClick={() => setOpen(true)}
            >
              Create
            </Button>
          </Box>
        }
      />
      {open && <CreateReceipe open={open} setOpen={setOpen} />}
    </>
  );
}

export default ReceipeHeader;
