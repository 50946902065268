import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getMenuOptionGroup, getMenuSizeGroup } from 'redux/slices/menuERSizeAndOptionSlice';
import SizeGroupOptionGroupBindingForm from 'sections/@dashboard/menuEngineering/menuSizeOption/SizeGroupOptionGroupBindingForm';

function MenuSizeOptionBinding({ open, handleClose }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!departmentId) return;
    dispatch(getMenuSizeGroup({ departmentId }));
    dispatch(getMenuOptionGroup({ departmentId }));
  }, [departmentId, dispatch]);

  return (
    <CustomDrawer
      open={open}
      setOpen={handleClose}
      title={`Bind Menu Size and Option`}
      PaperProps={{ sx: { innerWidth: '50%' } }}
    >
      <Box style={{ width: '400px' }}>
        <SizeGroupOptionGroupBindingForm handleClose={handleClose} />
      </Box>
    </CustomDrawer>
  );
}

MenuSizeOptionBinding.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default MenuSizeOptionBinding;
