import Page from 'components/Page';
import React, { useEffect } from 'react';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { useDispatch } from 'react-redux';
import { getStoreConfigDetails } from 'redux/slices/storeConfigSlice';
import StoreConfigBody from './StoreConfigBody';

function StoreConfigIndex() {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getStoreConfigDetails());
  }, [departmentId, dispatch]);
  return (
    <Page title="Store Config">
      <StoreConfigBody />
    </Page>
  );
}

export default StoreConfigIndex;
