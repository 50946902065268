import { InputAdornment, TableCell, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

function IndividualSizeTargetPercent({ index, size, basePrice }) {
  const { control, setValue, getValues } = useFormContext();

  const onChangeTargetPercent = (index) => {
    const totalCogs = getValues(`menuSizes.${index}.totalCogs`);
    const targetPercent = getValues(`menuSizes.${index}.targetPercent`);
    const calcTarget = targetPercent ? (Number(totalCogs) * 100) / ((Number(targetPercent) * 10) / 11) : 0;
    const difference = calcTarget - getValues(`menuSizes.${index}.spExGst`) || 0;
    const differencePercent = (difference * 100) / getValues(`menuSizes.${index}.sp`) || 0;

    setValue(`menuSizes.${index}.difference`, difference);
    setValue(`menuSizes.${index}.target`, calcTarget.toFixed(2));
    setValue(`menuSizes.${index}.differencePercent`, differencePercent);
  };

  return (
    <TableCell key={index} align="center">
      <Controller
        name={`menuSizes.${index}.targetPercent`}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            fullWidth
            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
            onChange={(event) => {
              setValue(`menuSizes.${index}.targetPercent`, event.target.value);
              onChangeTargetPercent(index);
            }}
            InputProps={{
              style: { textAlign: 'center', fontWeight: 'normal', fontSize: '14px' },
              endAdornment: (
                <InputAdornment position="end">
                  <span style={{ fontSize: '15px' }}>%</span>
                </InputAdornment>
              ),
            }}
            id="filled-basic"
            variant={'standard'}
            size="small"
            error={!!error}
            helperText={error?.message}
            sx={{ width: '60px' }}
          />
        )}
      />
    </TableCell>
  );
}

export default IndividualSizeTargetPercent;
