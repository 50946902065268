import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import * as yup from 'yup';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Divider, Stack } from '@mui/material';
import { createUpdateDealOffer, getSizesInCategories } from 'redux/slices/dealSlice';
import { useNavigate } from 'react-router';
import { PATH_DASHBOARD } from 'routes/paths';
import PropTypes from 'prop-types';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { findAddedMenus, findDealCategoryMenus } from 'utils/dealCategoryUtils';
import DealOfferDealCategories from './DealOfferDealCategories';
import DealOfferDealDetails from './DealOfferDealDetails';

const schema = yup.object().shape({
  title: yup.string().required(''),
  description: yup.string().required(''),
  sellingPrice: yup
    .number()
    .required('')
    .typeError('')
    .transform((curr, orig) => (orig === '' ? 0 : curr)),
  occuranceType: yup.string().required(''),
  startDateTime: yup.date(),
  endDateTime: yup.date(),

  dealCategories: yup.array().of(
    yup.object().shape({
      qty: yup.number().required('').typeError(''),
      addedMenus: yup.array().of(
        yup.object().shape({
          specialPrice: yup
            .number()
            // .required('')
            .typeError('')
            .transform((curr, orig) => (orig === '' ? 0 : curr)),
        })
      ),
    })
  ),
});

DealOfferAddForm.propTypes = {
  isEdit: PropTypes.bool,
};

function DealOfferAddForm({ isEdit }) {
  const { selectedDeal, postLoading, singleDeal } = useSelector((state) => state.deal);
  const { foodCategories } = useSelector((state) => state.menuEngineering);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const defaultValues = {
    title: '',
    description: '',
    sellingPrice: 0,
    startDateTime: null,
    endDateTime: null,
    occuranceType: 'ONCE',
    isPrivate: false,
    hasTimeLimit: false,
    dealCategories: [],
  };

  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onChange' });

  const { handleSubmit, reset } = methods;

  const HandleDealCreate = (data) => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        navigate(PATH_DASHBOARD.food.dealOffer);
      }
    };
    const formData = new FormData();
    if (data?.file) {
      formData.append('file', data?.file);
    }
    const finalData = {
      ...data,
      departmentId,
      startDateTime: moment(data?.startDateTime).format('YYYY-MM-DDTHH:mm:ss'),
      endDateTime: moment(data?.endDateTime).format('YYYY-MM-DDTHH:mm:ss'),
      dealCategories: data?.dealCategories?.map((item, index) => ({
        ...item,
        categoryId: item?.categoryId?.id,
        sizeId: item?.sizeId?.id,
        addedMenus: findAddedMenus(item, index, singleDeal),
        dealCategoryMenus: findDealCategoryMenus({ item, index }),
        removedMenuIds: item?.removedMenuIds?.map((el) => el?.id),
      })),
    };

    formData.append('deal', new Blob([JSON.stringify(finalData)], { type: 'application/json' }));

    dispatch(createUpdateDealOffer({ formData, departmentId, isSuccess: isSuccessful }));
  };

  const findItemSizeId = async (item) => {
    const res = await dispatch(getSizesInCategories({ departmentId, categoryId: item?.categoryId }));
    const sizeId = res?.payload?.find((el) => el?.id === item?.sizeId);
    return sizeId;
  };

  useEffect(() => {
    if (!isEdit) return;
    if (!singleDeal) {
      reset({
        id: selectedDeal?.id || '',
        title: selectedDeal?.title || '',
        description: selectedDeal?.description || '',
        sellingPrice: selectedDeal?.sellingPrice || 0,
        startDateTime: selectedDeal?.startDateTime || null,
        endDateTime: selectedDeal?.endDateTime || null,
        occuranceType: selectedDeal?.occuranceType || '',
        hasTimeLimit: selectedDeal?.hasTimeLimit || false,
        isPrivate: selectedDeal?.isPrivate || false,
      });
    } else {
      const resetDealCategories = async () =>
        Promise.all(
          singleDeal?.map(async (item) => {
            const sizeId = await findItemSizeId(item);
            return {
              dealId: item?.dealId,
              id: item?.id,
              qty: item?.qty,
              categoryId: foodCategories?.find((el) => el?.id === item?.categoryId),
              sizeId,
              dealCategoryMenus: item?.dealCategoryMenus?.map((el) => ({
                menuId: el?.menuId,
                dealCategoryId: el?.dealCategoryId,
                specialPrice: el?.specialPrice ?? 0,
                name: el?.menu?.name,
              })),
              addedMenus: item?.dealCategoryMenus?.map((el) => ({
                id: el?.menuId,
                dealCategoryId: el?.dealCategoryId,
                specialPrice: el?.specialPrice ?? 0,
                name: el?.menu?.name,
              })),
              menusOfCategories: item?.dealCategoryMenus?.map((el) => el?.menu?.name),
            };
          })
        ).then((res) => {
          reset({
            id: selectedDeal?.id || '',
            title: selectedDeal?.title || '',
            description: selectedDeal?.description || '',
            sellingPrice: selectedDeal?.sellingPrice || 0,
            file: selectedDeal?.imageUrl || null,
            startDateTime: selectedDeal?.startDateTime || null,
            endDateTime: selectedDeal?.endDateTime || null,
            occuranceType: selectedDeal?.occuranceType || '',
            hasTimeLimit: selectedDeal?.hasTimeLimit || false,
            isPrivate: selectedDeal?.isPrivate || false,
            dealCategories: res || [],
          });
        });

      resetDealCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, reset, selectedDeal, singleDeal]);

  return (
    <>
      <FormProvider {...methods}>
        <div>
          <DealOfferDealDetails />

          <Divider sx={{ my: 2 }} textAlign="center">
            Deal Categories
          </Divider>
          <DealOfferDealCategories edit={isEdit} />
        </div>
        <Stack alignItems="flex-end" justifyContent={'flex-end'} mt="1rem" direction="row" gap="1rem">
          <Button variant="contained" onClick={handleSubmit(HandleDealCreate)} color="secondary">
            {/* eslint-disable-next-line no-nested-ternary */}
            {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : isEdit ? 'Update' : 'Save'}
          </Button>
        </Stack>
      </FormProvider>
    </>
  );
}

export default DealOfferAddForm;
