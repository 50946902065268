import { Button, FormControlLabel, Stack } from '@mui/material';
import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { RHFCheckbox } from 'components/hook-form';
import { useFormContext, useWatch } from 'react-hook-form';
import StaffCreateActiveStepperSignaturePad from './StaffCreateActiveStepperSignaturePad';

const NEW_DATA = [
  {
    id: 1,
    label:
      'Workers must perform all duties in a manner that ensures the health and safety of themselves and others in the workplace.',
  },
  {
    id: 2,
    label:
      " Being affected by illegal drugs whilst at work or smoking on the premises may result in the termination of a worker's engagement.",
  },
  {
    id: 3,
    label: `Breaches of the health and safety policies (such as physical or verbal assaults, bullying or harassing) will not be tolerated from any workers, and may result in the termination of a worker's engagement.`,
  },
  {
    id: 4,
    label: 'You must wear PPE including protective gloves and face masks when at work if directed by management.',
  },
  {
    id: 5,
    label: "If you identify a hazard in your workplace, you don't have a responsibility to do anything.",
  },
  {
    id: 6,
    label: `
      ALL accidents/incidents or near hits/misses must be reported to management.
      `,
  },
  {
    id: 7,
    label: `
    You don't have to follow workplace rules if you think they are unnecessary.
    `,
  },
  {
    id: 8,
    label: `
    Poor housekeeping (untidy workplace) does not have an impact on health and safety.
    
    `,
  },
];

function StaffCreateActiveStepperTermsConditionSignature({ newRef }) {
  const [openModel, setOpenModal] = useState(false);
  const { control, register, setValue } = useFormContext();

  const watchSignatures = useWatch({
    control,
    name: 'declaration.signature',
  });

  const watchDeclarations = useWatch({
    control,
    name: 'declaration.acknowledgement',
  });

  const currentDate = new Date().toDateString();
  return (
    <>
      <Stack className="termsAndConditionsCSSOnlyForm" ref={newRef} p={4}>
        <div>
          <p className="c5">
            <span className="c4" />
          </p>
        </div>
        <p className="c5">
          <span className="c4" />
        </p>
        <ol className="c36 lst-kix_list_7-0 start" start="1">
          <li className="c23 li-bullet-0">
            <h1 id="h.2dlolyb" style={{ display: 'inline' }}>
              <span className="c11">Health and safety handbook checklist and acknowledgement form</span>
            </h1>
          </li>
        </ol>
        <p className="c6" style={{ marginTop: '1rem' }}>
          <span className="c4">
            Please complete this Checklist for Understanding, along with the acknowledgement form and provide this to
            the Organisation.
          </span>
        </p>
        <p className="c25">
          <span className="c4">For each statement below please circle whether it is true or false.</span>
        </p>
        <table className="c28">
          <tr className="c10">
            <td className="c12" colSpan="1" rowSpan="1">
              <p className="c17 c18">
                <span className="c22" />
              </p>
            </td>
            <td className="c27" colSpan="1" rowSpan="1">
              <p className="c17">
                <span className="c29">Yes</span>
              </p>
            </td>
            <td className="c19" colSpan="1" rowSpan="1">
              <p className="c17">
                <span className="c29">No</span>
              </p>
            </td>
            <td className="c30" colSpan="1" rowSpan="1">
              <p className="c17">
                <span className="c24">Office use</span>
              </p>
            </td>
          </tr>
          {NEW_DATA?.map((data, index) => (
            <tr className="c35" key={index}>
              <td className="c0 " colSpan="1" rowSpan="1">
                <p className="c21" style={{ padding: '0.3rem', lineHeight: '1.2' }}>
                  <span className="c2">{data?.label}</span>
                </p>
              </td>

              <td className="c1 hoverEffect" colSpan="1" rowSpan="1" style={{ '&hover': { background: 'red' } }}>
                <label
                  htmlFor={`${index}name`}
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'inline-table',
                    textAlign: 'center',
                  }}
                >
                  <input
                    type="radio"
                    name={`${index}name`}
                    {...register(`declaration.acknowledgement.${index}`)}
                    id={`${index}name`}
                    value
                    hidden
                  />
                  {watchDeclarations?.[index] === 'true' && <Icon icon="material-symbols:check" width={'25px'} />}
                </label>
              </td>
              <td className="c16 hoverEffect" colSpan="1" rowSpan="1">
                <label
                  htmlFor={`${index}names`}
                  style={{
                    width: '100%',
                    height: '100%',
                    display: 'inline-table',
                    textAlign: 'center',
                  }}
                >
                  <input
                    type="radio"
                    name={`${index}name`}
                    {...register(`declaration.acknowledgement.${index}`)}
                    id={`${index}names`}
                    value={false}
                    hidden
                  />
                  {watchDeclarations?.[index] === 'false' && <Icon icon="material-symbols:check" width="25px" />}
                </label>
              </td>
              <td className="c20" colSpan="1" rowSpan="1">
                <p className="c8 c18">
                  <span className="c3" />
                </p>
              </td>
            </tr>
          ))}
        </table>
        <p className="c6 c18">
          <span className="c4" />
        </p>
        <p className="c6" style={{ paddingTop: '1rem' }}>
          I
          <input
            type="text"
            {...register('declaration.fullName')}
            style={{ fontSize: '9pt', marginInline: '0.4rem' }}
          />
          acknowledge that I received a copy of Health and Safety Handbook and that I have read and understood it.
        </p>

        <Stack px={2} py={1}>
          <FormControlLabel
            label={`  I agree to comply with the policies and procedures applicable to me contained within the Health and Safety
                      Handbook to the best of my ability and to comply with all policies and procedures when attending other
                      workplaces.`}
            control={
              <>
                <RHFCheckbox name="acceptTermsAndConditions" size="small" />
              </>
            }
          />
        </Stack>

        <Stack direction={'row'} gap="0.2rem" alignItems={'center'}>
          <Stack direction={'row'} gap="0.2rem" alignItems={'center'} my={2}>
            <p className="c6">
              <span className="c4">Signed:</span>
            </p>
            {watchSignatures && (
              <>
                <img src={watchSignatures} alt="signature" style={{ width: '10rem' }} />
              </>
            )}
          </Stack>

          <Button
            className="createSignatureButtonDeclarationForm"
            onClick={() => setOpenModal(true)}
            size="small"
            variant="outlined"
            sx={{ fontSize: '0.7rem' }}
          >
            Create Signature
          </Button>
        </Stack>
        <p className="c6 c18">
          <span className="c4" />
        </p>
        <Stack direction={'row'} gap="0.2rem" alignItems={'center'} mt="0.1rem">
          <p className="c6">
            <span className="c4">Dated:</span>
          </p>
          <p className="c6">{currentDate}</p>
        </Stack>
        <p className="c5">
          <span className="c4" />
        </p>
        <div>
          <p className="c18 c33">
            <span className="c32" />
          </p>
        </div>
        <Stack px={2} mb={2}>
          <FormControlLabel
            label={`By signing this declaration, I acknowledge that I have voluntarily agreed to the terms and conditions of the agreement or document and that I am legally bound by them.
                      `}
            control={<RHFCheckbox name="certifyBySigning" disabled={!watchSignatures} size="small" />}
          />
        </Stack>
      </Stack>

      {openModel && (
        <StaffCreateActiveStepperSignaturePad
          open={openModel}
          setOpen={setOpenModal}
          formValue={(URL) => setValue('declaration.signature', URL)}
        />
      )}
    </>
  );
}

export default StaffCreateActiveStepperTermsConditionSignature;
