import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFTextField } from 'components/hook-form';
import { Box, Button, Card, FormLabel, Stack, TextField } from '@mui/material';
import CustomDatePicker from 'components/CustomComponents/CustomDatePicker';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { UploadVehiclesLog } from 'redux/slices/storeDocumentSlice';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { toast } from 'react-toastify';

function VehiclesAddEditForm({ data, isEdit, setOpen }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const VehiclesSchema = Yup.object().shape({
    vehicleDetails: Yup.string().required(''),
    plate: Yup.string().required(''),
    comment: Yup.string().required(''),
    dueService: Yup.date().required('').typeError(''),
    inspectionDue: Yup.date().required('').typeError(''),
    lastService: Yup.date().required('').typeError(''),
    regoDue: Yup.date().required('').typeError(''),
  });
  const defaultValues = useMemo(
    () => ({
      vehicleDetails: data?.vehicleDetails || '',
      plate: data?.plate || '',
      comment: data?.comment || '',
      dueService: data?.dueService || null,
      inspectionDue: data?.inspectionDue || null,
      lastService: data?.lastService || null,
      regoDue: data?.regoDue || null,
    }),
    [data]
  );

  const methods = useForm({ resolver: yupResolver(VehiclesSchema), defaultValues, mode: 'onChange' });

  const { handleSubmit, reset } = methods;

  const FirstAidSubmitHandler = (data) => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        // setOpen(false);
        if (isEdit) {
          toast.success('Vehicle log Updated', { autoClose: 1500 });
        } else {
          toast.success('New Vehicle log added', { autoClose: 1500 });
        }

        reset();
      }
    };
    const finalData = {
      ...data,
      departmentId,
      dueService: moment(data.dueService).format('YYYY-MM-DD'),
      inspectionDue: moment(data.inspectionDue).format('YYYY-MM-DD'),
      lastService: moment(data.lastService).format('YYYY-MM-DD'),
      regoDue: moment(data.regoDue).format('YYYY-MM-DD'),
    };
    dispatch(UploadVehiclesLog({ finalData, isSuccess: isSuccessful }));
  };

  useEffect(() => {
    if (!data) return;
    reset({ ...data });
  }, [reset, data]);

  return (
    <FormProvider {...methods}>
      <Card sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <RHFTextField name="vehicleDetails" label="Vehicle Details *" InputLabelProps={{ shrink: true }} />
            <RHFTextField name="plate" label="Plate *" InputLabelProps={{ shrink: true }} />
          </Stack>
          <RHFTextField name={`comment`} label="Comment" InputLabelProps={{ shrink: true }} multiline minRows={2} />

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <CustomDatePicker name="lastService" label="Last Service" />
            <CustomDatePicker name="dueService" label="Due Service" />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <CustomDatePicker name="regoDue" label="Rego Due" />
            <CustomDatePicker name="inspectionDue" label="Inspection Due" />
          </Stack>
          <Stack justifyContent={'flex-end'} direction={'row'} spacing={2}>
            <Button variant="contained" color="primary" onClick={handleSubmit(FirstAidSubmitHandler)}>
              {isEdit ? 'Update' : 'Add'}
            </Button>
          </Stack>
        </Stack>
      </Card>
    </FormProvider>
  );
}

VehiclesAddEditForm.propTypes = {
  data: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default VehiclesAddEditForm;
