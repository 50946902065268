import Page from 'components/Page';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getDepartOfStore } from 'redux/slices/storeSlice';
import UserWorkReportHeader from './UserWorkReportHeader';
import UserWorkReportBody from './UserWorkReportBody';

function UserWorkReportIndex() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDepartOfStore());
  }, [dispatch]);
  return (
    <Page title="User Work Report">
      <UserWorkReportHeader />
      <UserWorkReportBody />
    </Page>
  );
}

export default UserWorkReportIndex;
