import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import { TableCell } from '@mui/material';
import QuickSummaryDifferenceIndividualCard from './QuickSummaryDifferenceIndividualCard';

QuickSummaryDifferenceIndividualTableCell.propTypes = {
  differenceReportKey: PropTypes.string,
  mainIndex: PropTypes.number,
  userInfo: PropTypes.object,
};

function QuickSummaryDifferenceIndividualTableCell({ differenceReportKey, mainIndex, userInfo }) {
  const { control } = useFormContext();
  const watch = useWatch({ control, name: `users.${mainIndex}.difference.${differenceReportKey}` });

  return (
    <>
      {watch?.timeDifference === 0 && watch?.totalRosterTime === 0 && watch?.totalClockedTime === 0 ? (
        <TableCell />
      ) : (
        <>
          <TableCell style={{ padding: '5px' }}>
            <QuickSummaryDifferenceIndividualCard
              data={watch}
              mainIndex={mainIndex}
              differenceReportKey={differenceReportKey}
              userInfo={userInfo}
            />
          </TableCell>
        </>
      )}
    </>
  );
}

export default QuickSummaryDifferenceIndividualTableCell;
