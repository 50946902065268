import { Card, Divider, Stack, Tab, TableContainer, Tabs, TextField } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import PendingTasklist from './PendingTasklist';
import CompletedTasklist from './CompletedTasklist';
import DeletedTasklist from './DeletedTasklist';

function ChecklistTasklist() {
  const [tabValue, setTabValue] = useState('0');
  const [choosenDate, setChoosenDate] = useState(new Date());

  return (
    <Card>
      <Stack direction="row" spacing={2} sx={{ p: 2 }}>
        <DatePicker
          label="Select Date"
          value={choosenDate}
          inputFormat="dd/MMM/yyyy"
          onChange={(date) => {
            setChoosenDate(moment(date).format('YYYY-MM-DD'));
          }}
          renderInput={(params) => <TextField {...params} error={false} />}
        />
      </Stack>
      <Tabs
        allowScrollButtonsMobile
        inkBarStyle={{ background: 'blue' }}
        scrollButtons="auto"
        value={tabValue}
        aria-label="scrollable prevent tabs example"
        onChange={(e, value) => {
          setTabValue(value);
        }}
        sx={{ px: 2, bgcolor: 'Background.neutral', margin: 'auto' }}
      >
        <Tab label="Pending" value="0" />
        <Tab label="Completed" value="1" />
        <Tab label="Deleted" value="2" />
      </Tabs>
      <Divider />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          {tabValue === '0' && <PendingTasklist choosenDate={choosenDate} />}
          {tabValue === '1' && <CompletedTasklist choosenDate={choosenDate} />}
          {tabValue === '2' && <DeletedTasklist choosenDate={choosenDate} />}
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}

export default ChecklistTasklist;
