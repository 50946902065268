import Page from 'components/Page';
import React from 'react';
import LogBody from './LogBody';
import LogHeader from './LogHeader';

function LogIndex() {
  return (
    <Page title="Log">
      <LogHeader />
      <LogBody />
    </Page>
  );
}

export default LogIndex;
