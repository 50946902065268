const calculateRecipeInformation = (recipe) => {
  if (!recipe) return null;
  let target = Number(recipe?.target) || 0;
  const sp = Number(recipe?.sp);

  const cogs = recipe?.ingredients?.reduce((acc, current) => Number(current?.pricePerGram) + acc, 0) || 0;

  const spExstGst = (Number(sp) * 10) / 11;

  const cogsPercent = (cogs * 100) / spExstGst;

  const targetExstGst = (target * 10) / 11;
  const targetPercent = (cogs * 100) / targetExstGst;
  target = (cogs * 100) / ((targetPercent * 10) / 11);

  const grossProfit = spExstGst - cogs;
  const grossProfitPercent = 100 - cogsPercent;

  const diff = target - sp || 0;
  const diffPercent = ((target - sp) * 100) / sp || 0;

  return {
    cogs,
    target,
    spExstGst,
    cogsPercent: cogsPercent === Infinity || Number.isNaN(Number(cogsPercent)) ? 0 : cogsPercent,
    targetExstGst,
    targetPercent: targetPercent === Infinity ? 0 : targetPercent,
    grossProfit,
    grossProfitPercent:
      Number(grossProfitPercent) === Infinity ||
      Number(grossProfitPercent) === -Infinity ||
      Number.isNaN(Number(grossProfitPercent))
        ? 0
        : grossProfitPercent,
    diff: diff === Infinity || Number.isNaN(Number(diff)) ? 0 : diff,
    diffPercent: diffPercent === Infinity || Number.isNaN(Number(diffPercent)) ? 0 : diffPercent,
    sp,
  };
};

export const calculateTargetChanges = (row) => {
  let target = Number(row?.target) || 0;
  const cogs = Number(row?.cogs);

  const sp = Number(row?.sp);

  const targetExstGst = (target * 10) / 11;
  const targetPercent = (cogs * 100) / targetExstGst;
  target = (cogs * 100) / ((targetPercent * 10) / 11);

  const diff = target - sp || 0;
  const diffPercent = ((target - sp) * 100) / sp || 0;

  return {
    diff,
    diffPercent,
    target: target === Infinity ? 0 : target,
    targetPercent: targetPercent === Infinity ? 0 : targetPercent,
  };
};

export default calculateRecipeInformation;
