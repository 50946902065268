import { Stack, Typography } from '@mui/material';
import { FormProvider } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useSelector, useDispatch } from 'react-redux';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { clearAllRecipeofMenu, createFoodRecipeMenu, getFoodRecipeDetails } from 'redux/slices/FoodRecipeSlice';
import FoodRecipeForm from './FoodRecipeForm';

function FoodRecipeCreateUpdate({ menu, setOpen }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const { allRecipeofMenu, loading } = useSelector((state) => state.foodRecipe);

  const MenuRecipeSchema = Yup.object().shape({
    ingredients: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(''),
        pricePerKg: Yup.number().required(''),
      })
    ),
    receipeDTOs: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(''),
        target: Yup.number()
          .nullable()
          .typeError('')
          .transform((curr, orig) => (orig === '' || orig === null ? 0 : curr)),
        targetPercent: Yup.number()
          .nullable()
          .typeError('')
          .transform((curr, orig) => (orig === '' || orig === null ? 0 : curr)),

        ingredients: Yup.array().of(
          Yup.object().shape({
            qtyUsed: Yup.string().typeError('').nullable(),
          })
        ),
      })
    ),
  });

  const defaultValues = {
    receipeDTOs: [
      {
        ingredients: [
          {
            qtyUsed: 0,
          },
        ],
        targetPercent: 0,
      },
    ],
    ingredients: [],
  };

  const methods = useForm({ defaultValues, resolver: yupResolver(MenuRecipeSchema), mode: 'onChange' });
  const {
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = methods;

  const CreateRecipeHandler = (data) => {
    const finalData = {
      menuId: menu?.id,
      departmentId,
      receipes: [...data.receipeDTOs],
    };

    dispatch(createFoodRecipeMenu({ finalData }));
  };
  useEffect(() => {
    if (!departmentId) return;
    dispatch(getFoodRecipeDetails({ departmentId, menuId: menu?.id }));
  }, [departmentId, menu?.id, dispatch]);

  useEffect(() => {
    if (!Object.keys(allRecipeofMenu).length) return;
    reset({ ...allRecipeofMenu });
  }, [allRecipeofMenu, reset]);

  const isSpTrue = allRecipeofMenu?.receipeDTOs?.reduce((acc, curr) => acc + Number(curr?.sp), 0) > 0;
  return (
    <Stack>
      {loading ? (
        <CustomLoadingScreen height="70vh" />
      ) : (
        <>
          {allRecipeofMenu?.receipeDTOs?.length === 0 || allRecipeofMenu?.ingredients?.length === 0 ? (
            <>
              {allRecipeofMenu?.receipeDTOs?.length === 0 && (
                <Typography component="p" my={2}>
                  Please Provide Menu Price to Create Receipe
                </Typography>
              )}
              {allRecipeofMenu?.ingredients?.length === 0 && (
                <Typography component="p" my={2}>
                  Please Provide Ingredients to Create Recipe.
                </Typography>
              )}
            </>
          ) : (
            <FormProvider methods={methods}>
              <FoodRecipeForm menu={menu} onSubmit={CreateRecipeHandler} />
            </FormProvider>
          )}
        </>
      )}
    </Stack>
  );
}

export default FoodRecipeCreateUpdate;
