import {
  Autocomplete,
  Card,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CustomMultipleAutoCompleteField from 'components/CustomComponents/CustomAutoCompleteField';
import { RHFCheckbox, RHFSelect, RHFTextField } from 'components/hook-form';
import React, { memo } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

const RHF_SELECT = [
  { id: 1, label: 'Employee has tax file number', status: 'HAS_TFN' },
  { id: 2, label: 'Waiting on TFN Declaration', status: 'WAITING' },
  { id: 3, label: 'Employee is under 18', status: 'UNDER_EIGHTEEN' },
  { id: 4, label: 'Employee has no TFN or other reason', status: 'DOESNOT_HAVE_TFN' },
];

function StaffCreateActiveStepperDepartment({ isEdit }) {
  const { depOfStore } = useSelector((state) => state.store);
  const { role } = useSelector((state) => state.userRole);
  const { id } = useParams();

  const { clearErrors, setValue, control, resetField } = useFormContext();

  const watchtfnStatus = useWatch({
    control,
    name: 'tfnStatus',
  });

  const watchIsSuperannuationFund = useWatch({
    control,
    name: 'hasSuperFundAccount',
  });

  const isRHFValid = watchtfnStatus === 'HAS_TFN' ? true : false || false;

  const manageSuperannuationFund = (event) => {
    setValue('hasSuperFundAccount', event.target.checked);
    if (!event.target.checked) {
      resetField('superName');
      resetField('superNumber');
    }
  };

  const manageTfnStatus = (event) => {
    setValue('tfnStatus', event.target.value);
    clearErrors('tfnStatus');

    if (event.target.value !== 'HAS_TFN') {
      resetField('tfnNo');
    }
  };

  const {
    fields: addFields,
    append: addAppend,
    remove: addRemove,
  } = useFieldArray({
    control,
    name: 'addedRoleIds',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'role',
  });

  const watchDepartment = useWatch({ name: 'departmentId', control }) || {};

  const isDepartmentId = !!Object.keys(watchDepartment).length || false;

  const roleOnChangeHandler = (event, data, reason, details) => {
    if (!isEdit) return;
    if (reason === 'selectOption') {
      addAppend(details?.option?.id);
    }
    if (reason === 'removeOption') {
      const findAddIndex = addFields.findIndex((item) => item === id);
      addRemove(findAddIndex);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <Card sx={{ py: 3, px: 3 }}>
          <Stack direction="column" gap="1rem">
            <Stack direction={{ xs: 'column', md: 'row' }} gap="1rem">
              <RHFTextField name={`accountName`} label="Account Name *" InputLabelProps={{ shrink: true }} />
            </Stack>
            <Stack direction={{ xs: 'column', md: 'row' }} gap="1rem">
              <RHFTextField name={`bsb`} label="bsb *" InputLabelProps={{ shrink: true }} />
              <RHFTextField name={`accountNo`} label="Account Number *" InputLabelProps={{ shrink: true }} />
            </Stack>

            <Stack direction={{ xs: 'column', md: 'row' }} gap="1rem">
              <RHFTextField name={`bankName`} label="Bank Name *" InputLabelProps={{ shrink: true }} />
            </Stack>
            <Divider />
          </Stack>
        </Card>
        {isEdit && (
          <Card sx={{ py: 3, px: 3, mt: 2 }}>
            <Stack gap="1rem">
              <CustomMultipleAutoCompleteField
                name="departmentId"
                onChange={(event, value, reason) => {
                  setValue('departmentId', value);
                  if (reason === 'clear') {
                    resetField('departmentId');
                    resetField('role');
                    resetField('addedRoleIds');
                    // resetField('remvoedRoleIds');
                  }
                }}
                label="Select Department"
                options={depOfStore || []}
                getOptionLabel={(option) => option?.name}
              />
              <Controller
                control={control}
                name="role"
                render={({ field: { onChange, ...field } }) => (
                  <Autocomplete
                    disabled={!isDepartmentId}
                    label="Select Roles"
                    multiple
                    disableClearable
                    onChange={(event, data, reason, details) => {
                      onChange(data);
                      roleOnChangeHandler(event, data, reason, details);
                    }}
                    getOptionLabel={(option) => option?.title}
                    isOptionEqualToValue={(option, value) => option?.id === value?.id}
                    {...field}
                    options={role || []}
                    renderInput={(params) => <TextField {...params} label="Select Roles" />}
                  />
                )}
              />
            </Stack>
          </Card>
        )}
      </Grid>

      <Grid item xs={12} md={6}>
        <Card sx={{ py: 3, px: 3 }}>
          <Stack direction="column" gap="1rem" mb="1rem">
            <Stack direction={{ xs: 'column', md: 'row' }} gap="1rem">
              <RHFSelect name="tfnStatus" label="Please Select TFN Status *" onChange={manageTfnStatus}>
                {RHF_SELECT?.map((option) => (
                  <MenuItem key={option?.id} value={option?.status}>
                    {option?.label}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Stack>
            <Stack>
              <Controller
                name="tfnNo"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <ReactInputMask
                    maskChar=""
                    mask="*** *** ***"
                    label="tfn No"
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    disabled={!isRHFValid}
                    {...field}
                  >
                    {(inputProps) => <TextField disabled={!isRHFValid} {...inputProps} fullWidth error={!!error} />}
                  </ReactInputMask>
                )}
              />
            </Stack>
          </Stack>
          <Stack>
            <Stack px={2}>
              <FormControlLabel
                label="Do you have Superannuation Fund?"
                control={<RHFCheckbox name="hasSuperFundAccount" onChange={manageSuperannuationFund} />}
              />
            </Stack>
            {!watchIsSuperannuationFund && (
              <Typography component="p" color="red" fontSize="0.8rem" ml="0.4rem">
                * Your account will send to ATO Default
              </Typography>
            )}
            {watchIsSuperannuationFund && (
              <Stack direction={'column'} gap="1rem" mt="1rem">
                <Stack direction={{ xs: 'column', md: 'row' }} gap="1rem">
                  <RHFTextField name={`superName`} label="superName *" InputLabelProps={{ shrink: true }} />
                </Stack>
                <Stack direction={{ xs: 'column', md: 'row' }} gap="1rem">
                  <RHFTextField name="superNumber" label="Membership *" InputLabelProps={{ shrink: true }} />
                </Stack>
              </Stack>
            )}
          </Stack>
        </Card>
        {isEdit && (
          <Card sx={{ py: 3, px: 3, my: 2 }}>
            <Stack gap={2}>
              <Stack direction={{ xs: 'column', md: 'row' }} gap="1rem">
                <RHFTextField
                  name={`weekDayPay`}
                  label="weekDayPay *"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />

                <RHFTextField
                  name={`saturdayPay`}
                  label="saturdayPay *"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Stack>
              <Stack direction={{ xs: 'column', md: 'row' }} gap="1rem">
                <RHFTextField
                  name={`sundayPay`}
                  label="sundayPay *"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
                <RHFTextField
                  name={`holidayPay`}
                  label="holidayPay *"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Stack>

              <Stack direction={{ xs: 'column', md: 'row' }} gap="1rem">
                <RHFTextField
                  name={`overtimePay`}
                  label="overtimePay *"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
                <RHFTextField name={`weeklyHrs`} label="weeklyHrs *" />
              </Stack>
            </Stack>
          </Card>
        )}
      </Grid>
    </Grid>
  );
}

export default memo(StaffCreateActiveStepperDepartment);
