import { Button, Stack } from '@mui/material';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import RecommendedFoodMenuAccordion from './RecommendedFoodMenuAccordion';

function ManageRecommendedMenusBody() {
  const { control } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: 'data',
  });
  const {
    fields: addFields,
    append: addAppend,
    remove: addRemove,
  } = useFieldArray({
    control,
    name: `addMenus`,
    keyName: 'addedMenusId',
  });
  const {
    fields: removeFields,
    append: removeAppend,
    remove: removeRemove,
  } = useFieldArray({
    control,
    name: `removedMenu`,
    keyName: 'removedMenusId',
  });
  return (
    <>
      {fields?.map((category, mainIndex) => (
        <RecommendedFoodMenuAccordion
          key={category?.id}
          category={category}
          mainIndex={mainIndex}
          addAppend={addAppend}
          addRemove={addRemove}
          removeAppend={removeAppend}
          removeRemove={removeRemove}
          addFields={addFields}
          removeFields={removeFields}
        />
      ))}
      <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
        <Button variant="contained" type="submit">
          Save
        </Button>
      </Stack>
    </>
  );
}

export default ManageRecommendedMenusBody;
