import PropTypes from 'prop-types';
import { useMemo, useEffect } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { LoadingButton } from '@mui/lab';
import { Button, Card, Stack } from '@mui/material';

// routes
import InvoiceEditStatusSection from 'pages/dashboard/Invoice/InvoiceEditStatusSection';
import { useDispatch, useSelector } from 'redux/store';
import { isopenInvoiceModal } from 'redux/slices/InvoiceSlice';

// paths
import ViewInvoiceDataModal from 'pages/dashboard/Invoice/ViewInvoiceDataModal';
// components
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import InvoiceYupSchema from 'pages/dashboard/Invoice/InvoiceYupSchema';

import moment from 'moment';
import { FormProvider } from '../../../../components/hook-form';
//

import InvoiceNewEditDetails from './InvoiceNewEditDetails';
// ----------------------------------------------------------------------

InvoiceNewEditForm.propTypes = {
  isEdit: PropTypes.bool,
  currentInvoice: PropTypes.object,
  onSubmit: PropTypes.func,
};
export default function InvoiceNewEditForm({ isEdit = false, currentInvoice = {}, onSubmit }) {
  const { loading } = useSelector((state) => state.invoice);
  const { openInvoiceModal, postLoading } = useSelector((state) => state.invoice);

  const dispatch = useDispatch();

  const defaultValues = useMemo(
    () => ({
      invoiceNumber: currentInvoice?.invoiceNumber || '',
      invoiceDate: moment(currentInvoice?.invoiceDate).toDate() || new Date(),
      totalInclGst: currentInvoice?.totalInclGst || 0,
      totalGst: currentInvoice?.totalGst || 0,
      status: currentInvoice?.status || 'APPROVED',
      invoiceItems: currentInvoice?.invoiceItems || [],
      supplierId: currentInvoice?.supplierId || null,
      departmentId: currentInvoice?.departmentId || null,
      dueDate: currentInvoice?.dueDate || new Date(),
    }),
    [currentInvoice]
  );

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(InvoiceYupSchema),
    mode: 'onChanged',
  });

  const {
    reset,
    watch,
    handleSubmit,
    formState: { errors },
  } = methods;

  const values = watch();
  useEffect(() => {
    if (!Object.keys(currentInvoice).length) return;
    reset({ ...currentInvoice });
  }, [currentInvoice, isEdit, reset]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card>
        {loading ? (
          <CustomLoadingScreen width="100%" height="80vh" />
        ) : (
          <>
            <InvoiceEditStatusSection />

            <InvoiceNewEditDetails isEdit={isEdit} />
          </>
        )}
      </Card>

      <Stack justifyContent="flex-end" direction="row" spacing={2} sx={{ mt: 3 }}>
        <Button
          size="large"
          variant="contained"
          type="button"
          disabled={postLoading}
          onClick={handleSubmit(() => {
            dispatch(isopenInvoiceModal());
          })}
        >
          Confirm & {isEdit ? 'Update' : 'Create'}
        </Button>
      </Stack>
      {openInvoiceModal && <ViewInvoiceDataModal open zIndex={'2000'} invoice={values} isEdit={isEdit} />}
    </FormProvider>
  );
}
