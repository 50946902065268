import { Button, InputAdornment, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { RHFTextField } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useMemo } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateFoodMenuCombo } from 'redux/slices/menuEngineeringSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import FoodMenuComboIngredients from './FoodMenuComboIngredients';

function FoodMenuComboForm({ item, isEdit, menu, setOpen, parent }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const { foodMenuSizes, postLoading, foodMenuSizesLoading } = useSelector((state) => state.menuEngineering);
  const { loading } = useSelector((state) => state.ingredients);

  const schema = yup.object().shape({
    itemName: yup.string().required(''),
    menuSizes: yup.array().of(
      yup.object().shape({
        qtyUsed: yup
          .number()
          .typeError('')
          .transform((curr, orig) => (orig === '' ? 0 : curr)),
        additionalPrice: yup
          .number()
          .typeError('')
          .transform((curr, orig) => (orig === '' ? 0 : curr)),
      })
    ),
  });

  const defaultValues = useMemo(
    () => ({
      itemName: '',
      ingredientId: isEdit && item?.ingredient,
      // eslint-disable-next-line no-nested-ternary
      isRecipe: isEdit ? (item?.ingredient?.type ? item?.ingredient?.type !== 'SINGLE' : false) : false,
    }),
    [isEdit, item]
  );

  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onChange' });
  const { reset, handleSubmit, control } = methods;

  const { fields } = useFieldArray({
    control,
    name: 'menuSizes',
  });

  const CreateMenuCombo = (data) => {
    const finalData = {
      id: item?.id,
      departmentId,
      menuId: menu?.id,
      ingredientId: data?.ingredientId?.id,
      itemName: data?.itemName,
      menuComboId: parent?.id,
      menuSizes: data?.menuSizes,
    };
    dispatch(updateFoodMenuCombo({ finalData, setOpen }));
  };

  useEffect(() => {
    if (!isEdit) return;
    reset({
      id: item?.id,
      itemName: item?.itemName,
      ingredientId: item?.ingredient,
      // eslint-disable-next-line no-nested-ternary
      isRecipe: item?.ingredient ? (item?.ingredient?.type ? item?.ingredient?.type !== 'SINGLE' : true) : false,
      //  item?.ingredient?.type ? item?.ingredient?.type !== 'SINGLE' : true,
      menuSizes: item?.comboItemSizes?.map((size) => ({
        comboItemSizeId: size?.id,
        id: size?.menuSizeId,
        additionalPrice: size?.additionalPrice,
        qtyUsed: size?.qtyUsed,
      })),
    });
  }, [isEdit, item, reset]);

  useEffect(() => {
    if (isEdit) return;
    reset({
      menuSizes: foodMenuSizes.map((size) => ({
        id: size?.id,
        name: size?.sizeName,
        qtyUsed: 0,
        additionalPrice: 0,
      })),
    });
  }, [foodMenuSizes, isEdit, reset]);

  console.log(methods.watch(), 'datas');

  return (
    <FormProvider {...methods}>
      {loading ? (
        <CustomLoadingScreen height="20vh" />
      ) : (
        <Stack spacing={1}>
          <Stack spacing={3} direction={'row'}>
            <Stack spacing={2}>
              <Stack>
                <RHFTextField
                  name="itemName"
                  label="Title*"
                  control={control}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="e.g. Chicken"
                />
              </Stack>
              <Stack>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell>Size</TableCell>
                      <TableCell align="center">Price</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {foodMenuSizesLoading ? (
                      <TableRow>
                        <TableCell colSpan={2}>
                          <CustomLoadingScreen height="20vh" />
                        </TableCell>
                      </TableRow>
                    ) : (
                      <>
                        {fields.map((field, index) => (
                          <TableRow key={field.id}>
                            {foodMenuSizes?.[index]?.sizeName && (
                              <TableCell>{foodMenuSizes?.[index]?.sizeName}</TableCell>
                            )}
                            {/* <TableCell>{field?.name}</TableCell> */}
                            <TableCell>
                              <RHFTextField
                                control={control}
                                name={`menuSizes.${index}.additionalPrice`}
                                size="small"
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                sx={{ width: { md: 100 } }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </TableBody>
                </Table>
              </Stack>
            </Stack>
            <Stack spacing={2}>
              <FoodMenuComboIngredients isEdit={isEdit} />
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button color="error" variant="outlined" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit(CreateMenuCombo)}>
              {/* eslint-disable-next-line no-nested-ternary */}
              {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : isEdit ? 'Update' : 'Save'}
              {/* {isEdit ? 'Update' : 'Save'} */}
            </Button>
          </Stack>
        </Stack>
      )}
    </FormProvider>
  );
}

FoodMenuComboForm.propTypes = {
  item: PropTypes.object,
  isEdit: PropTypes.bool,
  menu: PropTypes.object,
  setOpen: PropTypes.func,
  parent: PropTypes.object,
};

export default FoodMenuComboForm;
