import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from 'components/hook-form';
import { Button, Divider, FormControlLabel, Radio, RadioGroup, Stack } from '@mui/material';
import { updateStoreConfigDetails } from 'redux/slices/storeConfigSlice';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';

const typeOption = [
  {
    value: 'AVERAGE',
    label: 'Average',
  },
  {
    value: 'HIGHEST',
    label: 'Highest',
  },
];

StoreConfigEditDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  updateData: PropTypes.object,
};

function StoreConfigEditDrawer({ open, setOpen, updateData }) {
  const dispatch = useDispatch();
  const { postLoading } = useSelector((state) => state.storeConfig);
  const schema = Yup.object().shape({
    currentMenuElements: Yup.number()
      .integer('')
      .min(0)
      .required('')
      .typeError('')
      .transform((curr, orig) => (orig === '' ? 0 : curr)),
    hours: Yup.number()
      .integer('')
      .min(0)
      .max(10, 'Max 10 hours')
      .required('')
      .typeError('')
      .transform((curr, orig) => (orig === '' ? 0 : curr)),
    mins: Yup.number()
      .integer('')
      .min(0)
      .max(59, 'Max 59 mins')
      .required('')
      .typeError('')
      .transform((curr, orig) => (orig === '' ? 0 : curr)),
  });
  const defaultValues = {};
  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onChange' });

  const { reset, handleSubmit, control } = methods;

  const updateStoreConf = (data) => {
    const finalData = { ...data, maxAceptedMins: Number(data?.hours * 60) + Number(data?.mins) };

    dispatch(updateStoreConfigDetails({ data: finalData, setOpen }));
  };

  useEffect(() => {
    if (!updateData) return;
    reset({ ...updateData, hours: Math.floor(updateData.maxAceptedMins / 60), mins: updateData.maxAceptedMins % 60 });
  }, [reset, updateData]);

  return (
    <>
      <CustomDrawer open={open} setOpen={setOpen} title="Update Store Config">
        <FormProvider methods={methods} onSubmit={handleSubmit(updateStoreConf)}>
          <Stack spacing={2}>
            <Stack>
              <div>
                <p>Price Type for Multi-Selectable Combos:</p>
                <Controller
                  name={`mixComboPriceType`}
                  control={control}
                  render={({ field }) => (
                    <RadioGroup {...field} row>
                      {typeOption.map((option) => (
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio />}
                          label={option.label}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </div>
            </Stack>
            <Divider />
            <Stack spacing={1}>
              <p>Total Menus on POS Desktop: </p>
              <RHFTextField name="currentMenuElements" sx={{ width: '12rem' }} />
            </Stack>
            <Divider />
            <Stack spacing={1}>
              <p>Max Accepted Time for Employees per day: </p>
              <Stack spacing={1} direction={'row'}>
                <RHFTextField name="hours" sx={{ width: '8rem' }} label="Hours" />
                <RHFTextField name="mins" sx={{ width: '8rem' }} label="Mins" />
              </Stack>
            </Stack>
            {/* <Divider />
            <Stack spacing={1}>
              <p>Estimated Delivery Time: </p>
              <Stack spacing={1} direction={'row'}>
                <RHFTextField name="deliveryEstimatedMins" sx={{ width: '8rem' }} label="Minutes" />
              </Stack>
            </Stack>
            <Divider />
            <Stack spacing={1}>
              <p>Estimated Pickup Time: </p>
              <Stack spacing={1} direction={'row'}>
                <RHFTextField name="pickupEstimatedMins" sx={{ width: '8rem' }} label="Minutes" />
              </Stack>
            </Stack>
            <Divider />
            <Stack spacing={1}>
              <p>Estimated Table Time: </p>
              <Stack spacing={1} direction={'row'}>
                <RHFTextField name="tableEstimatedMins" sx={{ width: '8rem' }} label="Minutes" />
              </Stack>
            </Stack> */}
            <Divider />
            <Stack>
              <Button disabled={postLoading} type="submit" variant="contained" color="primary">
                {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : 'Update'}
              </Button>
            </Stack>
          </Stack>
        </FormProvider>
      </CustomDrawer>
    </>
  );
}

export default StoreConfigEditDrawer;
