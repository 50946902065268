import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useGetDepartmentId from 'hooks/useGetDepartmentId';

import { getIngredientListWithPrice, getRecipeIngredientListWithPrice } from 'redux/slices/ingredientSlice';
import { getFoodMenuSizes } from 'redux/slices/menuEngineeringSlice';
import FoodMenuComboForm from './FoodMenuComboForm';

function FoodMenuComboCreate({ open, setOpen, menu, data }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  // useEffect(() => {
  //   if (!departmentId || !menu) return;
  //   dispatch(getIngredientListWithPrice({ departmentId }));
  //   dispatch(getRecipeIngredientListWithPrice({ departmentId }));
  //   // dispatch(getFoodMenuSizes({ departmentId, menuId: menu?.id }));
  // }, [departmentId, dispatch, menu]);

  return (
    <CustomOutletModal
      open={open}
      onClose={() => setOpen({ status: false, data: null })}
      title={`Create Combo Item for ${data?.title}`}
      zIndex="1600"
    >
      <FoodMenuComboForm menu={menu} setOpen={setOpen} parent={data} />
    </CustomOutletModal>
  );
}
FoodMenuComboCreate.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  menu: PropTypes.object,
  data: PropTypes.object,
};

export default FoodMenuComboCreate;
