import { Box, Button, Card, Grid, Modal, Stack, TableCell, TableRow, Typography } from '@mui/material';
import Iconify from 'components/Iconify';
import { TableNoData } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteFixedCost } from 'redux/slices/operationalSlice';
import { fCurrency } from 'utils/formatNumber';
import AdditionalFixedCost from './AdditionalFixedCost';
import AddNewFixedCostCategory from './AddNewFixedCostCategory';
import FixedCostAddEditForm from './FixedCostAddEditForm';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  textAlign: 'center',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
export default function FixedCostCard({ choosenDate }) {
  const [openEdit, setOpenEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [totalAdditionalFixedCost, setTotalAdditionalFixedCost] = useState(0);

  const { fixedCost } = useSelector((state) => state.operational);
  const departmentId = useGetDepartmentId();
  const { store } = useSelector((state) => state.store);
  const { timeDT } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleDelete = () => {
    const finalData = {
      id: fixedCost?.id,
      departmentId,
      storeId: store?.id,
      month: moment(timeDT).format('MM'),
      year: moment(timeDT).format('YYYY'),
    };
    dispatch(deleteFixedCost(finalData));
  };

  const findTotalFixedCost = (fixedCost) => {
    if (!fixedCost) return 0;
    let totalFixedCost = 0;
    if (fixedCost?.gas) totalFixedCost += fixedCost?.gas;
    if (fixedCost?.insurance) totalFixedCost += fixedCost?.insurance;
    if (fixedCost?.outgoing) totalFixedCost += fixedCost?.outgoing;
    if (fixedCost?.rent) totalFixedCost += fixedCost?.rent;
    if (fixedCost?.wasteRemoval) totalFixedCost += fixedCost?.wasteRemoval;
    if (fixedCost?.workersCompensation) totalFixedCost += fixedCost?.workersCompensation;
    if (fixedCost?.powerWater) totalFixedCost += fixedCost?.powerWater;
    return totalFixedCost;
  };

  const totalFixedCost = findTotalFixedCost(fixedCost);

  return (
    <>
      <Modal open={openDeleteModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to delete ?
          </Typography>
          <Typography id="modal-modal-description" align="center" sx={{ mt: 2 }}>
            <Button variant="contained" color="success" style={{ marginRight: '5px' }} onClick={() => handleDelete()}>
              Delete
            </Button>
            <Button variant="outlined" color="error" onClick={() => setOpenDeleteModal(false)}>
              Cancel
            </Button>
          </Typography>
        </Box>
      </Modal>
      {!fixedCost?.id ? (
        <Stack spacing={2} direction="row" sx={{ mt: 2 }} justifyContent="center">
          <TableNoData isNotFound={!fixedCost?.id} />
        </Stack>
      ) : (
        <Card sx={{ p: 2 }}>
          <Grid container spacing={3}>
            <>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Gas: &nbsp;&nbsp; </b>
                  {fixedCost?.gas}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Insurance: &nbsp;&nbsp;</b>
                  {fixedCost?.insurance}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Outgoing: &nbsp;&nbsp;</b>
                  {fixedCost?.outgoing}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Power-Water: &nbsp;&nbsp;</b>
                  {fixedCost?.powerWater}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Rent: &nbsp;&nbsp;</b>
                  {fixedCost?.rent}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Rent-Percentage: &nbsp;&nbsp;</b>
                  {fixedCost?.percentageRent}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Waste-Removal: &nbsp;&nbsp;</b>
                  {fixedCost?.wasteRemoval}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>workers-Compensation: &nbsp;&nbsp;</b>
                  {fixedCost?.workersCompensation}
                </div>
              </Grid>
              <Grid item xs={12}>
                <AdditionalFixedCost fixedCost={fixedCost} setTotalAdditionalFixedCost={setTotalAdditionalFixedCost} />
              </Grid>

              <Grid item xs={12}>
                <Stack alignItems={'flex-end'} spacing={3}>
                  <div>
                    <Button variant="text" disabled>
                      Total = {fCurrency(totalFixedCost)} + {fCurrency(totalAdditionalFixedCost)}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ marginRight: '10px' }}
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      onClick={() => setOpenAddCategory(true)}
                    >
                      New Category
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ marginRight: '10px' }}
                      startIcon={<Iconify icon="material-symbols:edit" />}
                      onClick={() => setOpenEdit(true)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="text"
                      style={{ color: 'red' }}
                      startIcon={<Iconify icon="material-symbols:delete" />}
                      onClick={() => setOpenDeleteModal(true)}
                    >
                      Delete
                    </Button>
                  </div>
                </Stack>
              </Grid>
            </>
          </Grid>

          {openEdit && (
            <FixedCostAddEditForm
              edit
              open={openEdit}
              setOpen={setOpenEdit}
              updateData={fixedCost}
              choosenDate={choosenDate}
            />
          )}
          {openAddCategory && (
            <AddNewFixedCostCategory open={openAddCategory} setOpen={setOpenAddCategory} fixedCost={fixedCost} />
          )}
        </Card>
      )}
    </>
  );
}
