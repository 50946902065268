import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  TextField,
} from '@mui/material';
import CustomMultipleAutoCompleteField from 'components/CustomComponents/CustomAutoCompleteField';
import Iconify from 'components/Iconify';
import { RHFCheckbox, RHFTextField } from 'components/hook-form';
import React, { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

function MenuOptionFormIndividualItem({ el, index, RemoveOptionTitle }) {
  const { allRecipeIngredientsWithPrice, allIngredientsWithPrice } = useSelector((state) => state.ingredients);
  const allRecipeIngredients = [{ id: null, name: 'Selecte Recipe' }, ...allRecipeIngredientsWithPrice?.ingredients];
  const allIngredients = [{ id: null, name: 'Select Ingredient' }, ...allIngredientsWithPrice?.ingredients];
  const { control, setValue, watch, resetField } = useFormContext();

  const watchIsRecipe = watch(`options.${index}.isRecipe`);

  useEffect(() => {
    //  setValue(`options.${index}.ingredientId`, null);
    resetField(`options.${index}.ingredientId`);
  }, [setValue, watchIsRecipe, resetField, index]);

  return (
    <Grid item sx={12} key={index}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <RHFTextField name={`options.${index}.name`} label="Option Name" />

        {watchIsRecipe ? (
          <Controller
            control={control}
            name={`options.${index}.ingredientId`}
            render={({ field: { onChange, ...field } }) => (
              <Autocomplete
                label="Select Recipe"
                disableClearable
                onChange={(event, data, reason, details) => {
                  onChange(data);
                  // ingredientOnChangeHandler(event, data, reason, details);
                }}
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                {...field}
                options={allRecipeIngredients || []}
                renderInput={(params) => <TextField {...params} label="Select Recipe" style={{ width: '15rem' }} />}
              />
            )}
          />
        ) : (
          <Controller
            control={control}
            name={`options.${index}.ingredientId`}
            render={({ field: { onChange, ...field } }) => (
              <Autocomplete
                label="Select Ingredient"
                disableClearable
                onChange={(event, data, reason, details) => {
                  onChange(data);
                  // ingredientOnChangeHandler(event, data, reason, details);
                }}
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                {...field}
                options={allIngredients || []}
                renderInput={(params) => <TextField {...params} label="Select Ingredient" style={{ width: '15rem' }} />}
              />
            )}
          />
        )}
        <Stack flexDirection={'row'} alignItems="center" justifyContent={'flex-end'} style={{ marginLeft: '10px' }}>
          <RHFCheckbox name={`options.${index}.isRecipe`} style={{ marginRight: '-15px' }} />
          Recipe
        </Stack>
        <Button color="error" variant="text" onClick={() => RemoveOptionTitle(el, index)}>
          <Iconify icon="solar:trash-bin-minimalistic-2-bold" height={20} width={20} />
        </Button>
      </Stack>
    </Grid>
  );
}

export default MenuOptionFormIndividualItem;
