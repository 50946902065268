import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Iconify from 'components/Iconify';
import Scrollbar from 'components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { getShiftRequest, getStoreDate } from 'redux/slices/shiftRequestSlice';

const TABLE_HEAD = [
  { id: 'requestor', label: 'Dropped By', align: 'left' },
  { id: 'phone', label: 'Phone No.', align: 'left' },
  //   { id: 'role', label: 'Roles', align: 'left' },
  { id: 'time', label: 'Shift Time', align: 'left' },
  { id: 'notes', label: 'Work-Note / Drop Reason', align: 'left' },
  //   { id: 'action', label: 'Action', align: 'left' },
];
export default function ShiftDroppedList() {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [pageNo, setPageNo] = useState(1);

  const { timeDT } = useSelector((state) => state.user);
  const { shiftRequest, loading: shiftRequestLoading } = useSelector((state) => state.ShiftRequest);

  const fromDateChange = (date) => {
    setFromDate(moment(date).format('YYYY-MM-DD'));
  };
  const toDateChange = (date) => {
    setToDate(moment(date).format('YYYY-MM-DD'));
  };

  useEffect(() => {
    if (timeDT) {
      setFromDate(moment(timeDT).format('YYYY-MM-DD'));
      setToDate(moment(timeDT).add(10, 'days').format('YYYY-MM-DD'));
    }
  }, [timeDT]);

  useEffect(() => {
    if (!departmentId) return;
    if (!fromDate) return;
    if (!toDate) return;
    if (fromDate && toDate) {
      const diffDays = moment(toDate).diff(moment(fromDate), 'days');
      if (fromDate > toDate) {
        toast.error('From date should be less than to date');
      } else if (diffDays > 30) {
        toast.error('From date and to date should be within 30 days');
      } else {
        dispatch(
          getShiftRequest({
            departmentId,
            startDate: moment(fromDate).format('YYYY-MM-DD'),
            endDate: moment(toDate).format('YYYY-MM-DD'),
            typeList: ['DROP'],
          })
        );
      }
    }
  }, [departmentId, fromDate, toDate, dispatch]);
  return (
    <Card>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
          <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
            {/* From Date */}
            <DatePicker
              //   disablePast
              label="From Date"
              value={fromDate}
              onChange={fromDateChange}
              inputFormat="dd/MMM/yyyy"
              renderInput={(params) => <TextField {...params} error={false} />}
            />

            {/* To Date */}
            <DatePicker
              //   disablePast
              label="To Date"
              value={toDate}
              onChange={toDateChange}
              inputFormat="dd/MMM/yyyy"
              renderInput={(params) => <TextField {...params} error={false} />}
            />
          </Stack>

          <Table size="small">
            <TableHeadCustom headLabel={TABLE_HEAD} />
            <TableBody>
              {shiftRequestLoading ? (
                <TableRow>
                  <TableCell colSpan={5}>
                    <CustomLoadingScreen height={'20vh'} />
                  </TableCell>
                </TableRow>
              ) : (
                shiftRequest?.shiftRequests?.map((request) => (
                  <TableRow hover key={request.id}>
                    <TableCell sx={{ display: 'flex' }}>
                      {request?.requestor?.thumbnailURL ? (
                        <Avatar
                          src={request.requestor.thumbnailURL}
                          style={{
                            height: '30px',
                            width: '30px',
                            borderRadius: '100px',
                            marginRight: '10px',
                          }}
                        />
                      ) : (
                        <Avatar alt="" src="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                      )}
                      <Typography style={{ marginTop: '5px' }} variant="subtitle2" noWrap>
                        {request.requestor.firstName} {request?.requestor?.middleName} {request.requestor.lastName}
                      </Typography>
                    </TableCell>
                    <TableCell>{request.requestor.phone}</TableCell>
                    <TableCell>
                      {moment(request.userShift?.date).format('MMM DD')},{' '}
                      {moment(request.userShift.startTime, 'hh').format('LT')} -{' '}
                      {moment(request.userShift.endTime, 'hh').format('LT')}
                    </TableCell>
                    <TableCell>
                      <Tooltip title={request.userShift?.note}>
                        <span>
                          <Button disabled>
                            <Iconify icon={'ic:round-speaker-notes'} width={20} height={20} />
                          </Button>
                        </span>
                      </Tooltip>
                      {/* </TableCell>
                    <TableCell> */}
                      <Tooltip title={request?.note}>
                        <span>
                          <Button disabled>
                            <Iconify icon={'ic:round-speaker-notes'} width={20} height={20} />
                          </Button>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
            {shiftRequest?.shiftRequests?.length <= 0 && (
              <TableNoData isNotFound={shiftRequest?.shiftRequests?.length === 0} />
            )}
          </Table>
          <Box
            sx={{ position: 'relative', marginTop: '10px' }}
            justifyContent={'end'}
            alignContent="center"
            display={'flex'}
          >
            <Pagination
              count={shiftRequest?.paginationInfo?.totalPages}
              page={pageNo}
              onChange={(e, value) => setPageNo(value)}
            />
          </Box>
        </TableContainer>
      </Scrollbar>
    </Card>
  );
}
