import { Box, Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PATH_DASHBOARD } from 'routes/paths';
import FoodSizeCreate from './FoodSize/FoodSizeCreate';
import FoodCategoriesCreate from './FoodCategories/FoodCategoriesCreate';

function FoodCategoryHeaders() {
  const { currentTab } = useSelector((state) => state.foodCategoriesAndSize);
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const setOpenHandler = () => {
    setOpen(true);
  };

  return (
    <>
      <HeaderBreadcrumbs
        heading="Categories and Sizes"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'categories', href: PATH_DASHBOARD.food.categories },
          { name: 'List' },
        ]}
        action={
          <Box className="invoiceListHeadingButtons">
            {currentTab === 0 && (
              <Button variant="contained" startIcon={<Iconify icon={'eva:plus-fill'} />} onClick={setOpenHandler}>
                Create Categories
              </Button>
            )}
            {currentTab === 1 && (
              <Button variant="contained" startIcon={<Iconify icon={'eva:plus-fill'} />} onClick={setOpenHandler}>
                Create Sizes
              </Button>
            )}

            {open && currentTab === 0 && <FoodCategoriesCreate open={open} handleClose={handleClose} />}
            {open && currentTab === 1 && <FoodSizeCreate open={open} handleClose={handleClose} />}
          </Box>
        }
      />
    </>
  );
}

export default FoodCategoryHeaders;
