import { Accordion, AccordionDetails, AccordionSummary, Button, Stack } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Iconify from 'components/Iconify';
import Page from 'components/Page';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSizeGroupOptionGroup, reorderSizeOption } from 'redux/slices/menuERSizeAndOptionSlice';
import AddMenuOptionToMenuSize from 'sections/@dashboard/menuEngineering/menuSizeOption/AddMenuOptionToMenuSize';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
// import { FormProvider } from 'components/hook-form';
import MenuSizeOptionAccordianDetails from './MenuSizeOptionAccordianDetails';
import MenuSizeOptionAccordian from './MenuSizeOptionAccordian';

function MenuSizeOptionIndex() {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { reset, control, handleSubmit } = methods;

  const { fields, move } = useFieldArray({
    control,
    name: 'data',
    keyName: 'sizeId',
  });

  const { menuSizeOptionGroup, menuSizeOptionGroupLoading } = useSelector((state) => state.menuERSizeAndOption);

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getSizeGroupOptionGroup({ departmentId }));
  }, [dispatch, departmentId]);

  useEffect(() => {
    if (!menuSizeOptionGroup?.length) return;
    reset({ data: menuSizeOptionGroup });
  }, [menuSizeOptionGroup, reset]);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;
    if (active.id !== over.id) {
      move(
        fields.findIndex((item) => item?.id === active.id),
        fields.findIndex((item) => item?.id === over.id)
      );
    }
  };

  const reOrderSubmitHandler = (data) => {
    const finalData = data.data.map((item) => ({ ...item }));

    dispatch(reorderSizeOption({ finalData, departmentId }));
  };

  return (
    <Page title="Menu Size Option ">
      <FormProvider {...methods}>
        <form id="sizeOptionReOrder" onSubmit={handleSubmit(reOrderSubmitHandler)}>
          <Stack mx={'1rem'}>
            <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext items={fields} strategy={verticalListSortingStrategy} handle>
                {menuSizeOptionGroupLoading ? (
                  <CustomLoadingScreen height="30vh" />
                ) : (
                  fields?.map((sizes, sizeIndex) => (
                    <MenuSizeOptionAccordian sizeIndex={sizeIndex} sizes={sizes} key={sizes?.id} />
                  ))
                )}
                {fields?.length > 0 && (
                  <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} mt={2} p={2}>
                    <Button variant="contained" onClick={handleSubmit(reOrderSubmitHandler)}>
                      Save
                    </Button>
                  </Stack>
                )}
              </SortableContext>
            </DndContext>
          </Stack>
        </form>
      </FormProvider>
    </Page>
  );
}

export default MenuSizeOptionIndex;
