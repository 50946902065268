import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import { PATH_AUTH } from 'routes/paths';
import { errorToastTime, successToastTime } from 'config';

export const getPublicAnnouncement = createAsyncThunk('announcement/getPublicAnnouncement', async (props) => {
  try {
    const response = await apiURL.get(
      `/announcement/all/PUBLIC/${props?.departmentId}?pageNo=${props?.pageNo - 1 || 0}&pageSize=${
        props?.pageSize || 50
      }`
    );
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});
export const createAnnouncement = createAsyncThunk('announcement/createAnnouncement', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/announcement/create`, props?.finalData);
    if (response?.status === 200) {
      if (props?.isPrivate) {
        thunkAPI.dispatch(getPrivateAnnouncement({ departmentId: props?.finalData?.departmentId }));
      } else {
        thunkAPI.dispatch(getPublicAnnouncement({ departmentId: props?.finalData?.departmentId }));
      }
      toast.success('Announcement Created', { autoClose: successToastTime });
      props?.handleClose();
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateAnnouncement = createAsyncThunk('announcement/updateAnnouncement', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put(`/announcement/update`, props?.finalData);
    if (response?.status === 200) {
      if (props?.isPrivate) {
        thunkAPI.dispatch(getPrivateAnnouncement({ departmentId: props?.finalData?.departmentId }));
      } else {
        thunkAPI.dispatch(getPublicAnnouncement({ departmentId: props?.finalData?.departmentId }));
      }
      toast.success('Announcement Updated', { autoClose: successToastTime });
      props?.handleClose();
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteAnnouncement = createAsyncThunk('announcement/deleteAnnouncement', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/announcement/${props?.id}/${props?.departmentId}`);
    if (response?.status === 200) {
      if (props?.isPrivate) {
        thunkAPI.dispatch(getPrivateAnnouncement({ departmentId: props?.departmentId }));
      } else {
        thunkAPI.dispatch(getPublicAnnouncement({ departmentId: props?.departmentId }));
      }
      toast.success('Announcement Deleted', { autoClose: successToastTime });
      props?.handleClose();
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getPrivateAnnouncement = createAsyncThunk('announcement/getPrivateAnnouncement', async (props) => {
  try {
    const response = await apiURL.get(
      `/announcement/all/PRIVATE/${props?.departmentId}?pageNo=${props?.pageNo - 1 || 0}&pageSize=${
        props?.pageSize || 50
      }`
    );
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getSingleAnnouncement = createAsyncThunk('announcement/getSingleAnnouncement', async (props) => {
  try {
    const response = await apiURL.get(`/announcement/${props?.id}/${props?.departmentId}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

const announcementSlice = createSlice({
  name: 'announcement',
  initialState: {
    currentTab: 0,
    loading: false,
    postLoading: false,
    privateAnnouncement: {},
    publicAnnouncement: {},

    singleAnnouncementLoading: false,
    singleAnnouncement: {},
  },

  reducers: {
    changeAnnouncementCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
  },

  extraReducers: {
    [getPublicAnnouncement.pending]: (state) => {
      state.loading = true;
      state.publicAnnouncement = {};
    },
    [getPublicAnnouncement.fulfilled]: (state, action) => {
      state.loading = false;
      state.publicAnnouncement = action.payload;
    },
    [getPublicAnnouncement.rejected]: (state) => {
      state.loading = false;
      state.publicAnnouncement = {};
    },
    [getPrivateAnnouncement.pending]: (state) => {
      state.loading = true;
      state.privateAnnouncement = {};
    },
    [getPrivateAnnouncement.fulfilled]: (state, action) => {
      state.loading = false;
      state.privateAnnouncement = action.payload;
    },
    [getPrivateAnnouncement.rejected]: (state) => {
      state.loading = false;
      state.privateAnnouncement = {};
    },

    [createAnnouncement.pending]: (state) => {
      state.postLoading = true;
    },
    [createAnnouncement.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [createAnnouncement.rejected]: (state) => {
      state.postLoading = false;
    },

    [updateAnnouncement.pending]: (state) => {
      state.postLoading = true;
    },
    [updateAnnouncement.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateAnnouncement.rejected]: (state) => {
      state.postLoading = false;
    },

    [getSingleAnnouncement.pending]: (state) => {
      state.singleAnnouncementLoading = true;
      state.singleAnnouncement = {};
    },
    [getSingleAnnouncement.fulfilled]: (state, action) => {
      state.singleAnnouncementLoading = false;
      state.singleAnnouncement = action.payload;
    },
    [getSingleAnnouncement.rejected]: (state) => {
      state.singleAnnouncementLoading = false;
      state.singleAnnouncement = {};
    },
  },
});

export const { changeAnnouncementCurrentTab } = announcementSlice.actions;
export default announcementSlice.reducer;
