import React, { useEffect, useState } from 'react';
import { Box, Button, MenuItem, Popover, Stack, TextField } from '@mui/material';
import Iconify from 'components/Iconify';
import { TableMoreMenu } from 'components/table';
import { dispatch, useDispatch, useSelector } from 'redux/store';
import { checkRoasterEmptyOrNot, mergeCurrentRoasterwithPrevious } from 'utils/roasterFeatureUtils';
import moment from 'moment';
import apiAxios from 'api';
import { getRoasterApiOuter } from 'outerApi';
import { convertDatetoString } from 'utils/customDateAndTimeFormat';
import {
  downloadRoaster,
  getPreviousDateRoaster,
  getRoaster,
  setPastRoaster,
  updateAllRoaster,
} from 'redux/slices/roasterSlice';
import CustomDatePicker from 'components/CustomComponents/CustomDatePicker';
import { DatePicker } from '@mui/x-date-pickers';

function RoasterExtraFilterFeatures({ choosenDate }) {
  const [openMenu, setOpenMenuActions] = useState(null);
  const [openImportDatePop, setOpenImportDatePop] = useState(null);

  const { allRoaster, postLoading, isPreviousDate } = useSelector((state) => state.roaster);
  const { selectedDepartment } = useSelector((state) => state.userDepartment);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  // checking if the roaster has any shifts or not.... returns true if roaster has shifts else false
  const checkRoasterEmptyOrNotHandler = checkRoasterEmptyOrNot(allRoaster?.users);

  const handleOpenImportPopOver = (event) => {
    setOpenImportDatePop(event.currentTarget);
  };

  const handleCloseImportPopOver = () => {
    handleCloseMenu();
    setOpenImportDatePop(null);
  };

  const open = Boolean(openImportDatePop);
  const ids = open ? 'simple-popover' : undefined;

  const ImportDateChangeRoasterHandler = (selectedDate) => {
    dispatch(
      getPreviousDateRoaster({
        anyDateOfWeek: convertDatetoString(selectedDate),
        departmentId: selectedDepartment?.id,
      })
    );
    handleCloseImportPopOver();
    handleCloseMenu();
  };

  const LastWeekRoasterImportHandler = () => {
    const currentDate = moment(choosenDate).format('YYYY-MM-DD');
    const lastWeekDate = moment(currentDate).subtract(7, 'days').format('YYYY-MM-DD');
    dispatch(
      getPreviousDateRoaster({
        anyDateOfWeek: lastWeekDate,
        departmentId: selectedDepartment?.id,
      })
    );
    handleCloseImportPopOver();
    handleCloseMenu();
  };
  const handleDraftImport = () => {
    const currentDate = moment(choosenDate).format('YYYY-MM-DD');
    dispatch(
      getRoaster({
        anyDateOfWeek: currentDate,
        departmentId: selectedDepartment?.id,
        status: 'DRAFT',
      })
    );
  };

  const DownloadRoasterPDf = () => {
    const data = { departmentId: selectedDepartment?.id, anyDateOfWeek: convertDatetoString(choosenDate) };
    dispatch(downloadRoaster(data));
  };

  return (
    <Box>
      <TableMoreMenu
        open={openMenu}
        onOpen={handleOpenMenu}
        onClose={handleCloseMenu}
        actions={
          <>
            <MenuItem>
              <Button
                onClick={handleOpenImportPopOver}
                sx={{ width: '100%', padding: '0.2rem' }}
                variant="text"
                disabled={checkRoasterEmptyOrNotHandler || postLoading || isPreviousDate}
              >
                <Iconify icon={'tabler:file-import'} />
                Import
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                onClick={handleDraftImport}
                sx={{ width: '100%', padding: '0.2rem' }}
                variant="text"
                disabled={!allRoaster?.hasDraft || allRoaster?.isPublished}
              >
                <Iconify icon={'tabler:table-import'} />
                Import Draft
              </Button>
            </MenuItem>
            <MenuItem>
              <Button
                color="secondary"
                sx={{ width: '100%', padding: '0.2rem' }}
                disabled={!allRoaster?.isPublished}
                onClick={DownloadRoasterPDf}
                startIcon={<Iconify icon="material-symbols:download" />}
              >
                Download
              </Button>
            </MenuItem>
          </>
        }
      />

      <Popover
        id={ids}
        open={open}
        anchorEl={openImportDatePop}
        onClose={handleCloseImportPopOver}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Stack p={2} gap={2}>
          Select Import Date
          <Stack alignItems="flex-start">
            <Button variant="contained" onClick={LastWeekRoasterImportHandler}>
              {' '}
              Last Week
            </Button>
          </Stack>
          or
          <DatePicker
            label="Select Date"
            onChange={(newValue) => {
              ImportDateChangeRoasterHandler(newValue);
            }}
            disableHighlightToday
            // disableFuture
            renderInput={(params) => <TextField {...params} label="" style={{ width: 'min(10rem)' }} />}
          />
        </Stack>
      </Popover>
    </Box>
  );
}

export default RoasterExtraFilterFeatures;
