import { Container } from '@mui/material';
import CheckUserDepartment from 'components/CustomComponents/CheckUserDepartment';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import useSettings from 'hooks/useSettings';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getOnlineSalesPlatform, getStoreCashDrawer } from 'redux/slices/storeSlice';
import { getFeatureBasedUser } from 'redux/slices/userSlice';
import { PATH_DASHBOARD } from 'routes/paths';
import SalesCreateUpdateForm from 'sections/@dashboard/sales/addEdit/SalesCreateUpdateForm';

export default function AddSales() {
  const departmentId = useGetDepartmentId();

  const dispatch = useDispatch();

  // prevent reload
  useEffect(() => {
    const unloadCallback = (event) => {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => {
      window.removeEventListener('beforeunload', unloadCallback);
    };
  }, []);

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getStoreCashDrawer({ departmentId }));
    dispatch(getFeatureBasedUser({ featureId: 2, departmentId }));
    dispatch(getOnlineSalesPlatform({ departmentId }));
  }, [dispatch, departmentId]);

  const { themeStretch } = useSettings();
  return (
    <CheckUserDepartment>
      <Page title="Sales: Create daily Sale">
        <Container maxWidth={themeStretch ? false : 'xl'}>
          <HeaderBreadcrumbs
            heading="Create Sales"
            links={[
              { name: 'Dashboard', href: PATH_DASHBOARD.root },
              { name: 'Sales', href: PATH_DASHBOARD.sales.list },
              { name: 'Add Sales' },
            ]}
          />
          <SalesCreateUpdateForm />
        </Container>
      </Page>
    </CheckUserDepartment>
  );
}
