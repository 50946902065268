import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFCheckbox, RHFTextField } from 'components/hook-form';
import { Box, Button, Card, FormLabel, Stack } from '@mui/material';
// import CustomDatePicker from 'components/CustomComponents/CustomDatePicker';
// import moment from 'moment';
import { useDispatch } from 'react-redux';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { UploadFirstAidLog } from 'redux/slices/storeDocumentSlice';
import { toast } from 'react-toastify';

function FirstAidAddEditForm({ data, isEdit, setOpen }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const FirstAidSchema = Yup.object().shape({
    itemDetails: Yup.string().required(''),
    comment: Yup.string().required(''),
    quantity: Yup.number().required('').typeError(''),
  });
  const defaultValues = useMemo(
    () => ({
      itemDetails: data?.itemDetails || '',
      comment: data?.comment || '',
      quantity: data?.quantity || '',
      isAvailable: data?.isAvailable || true,
    }),
    [data]
  );

  const methods = useForm({ resolver: yupResolver(FirstAidSchema), defaultValues, mode: 'onChange' });

  const { handleSubmit, reset } = methods;

  const FirstAidSubmitHandler = (data) => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        // setOpen(false);
        reset();
        if (isEdit) {
          toast.success('firstAid log updated', { autoClose: 1500 });
        } else {
          toast.success('new firstAid log added', { autoClose: 1500 });
        }
      }
    };
    const finalData = { ...data, departmentId };
    dispatch(UploadFirstAidLog({ finalData, isSuccess: isSuccessful }));
  };

  useEffect(() => {
    if (!data) return;
    reset({ ...data });
  }, [reset, data]);

  return (
    <FormProvider {...methods}>
      <Card sx={{ p: 2 }}>
        <Stack spacing={3}>
          <Stack direction={'row'} spacing={2}>
            <RHFTextField name="itemDetails" label="Name *" InputLabelProps={{ shrink: true }} />
            <RHFTextField name="quantity" label="Quantity" InputLabelProps={{ shrink: true }} />
          </Stack>

          <RHFTextField name={`comment`} label="Comment" InputLabelProps={{ shrink: true }} multiline minRows={3} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <RHFCheckbox name="isAvailable" />
            <FormLabel>Is Available ?</FormLabel>
          </Box>
          <Stack justifyContent={'flex-end'} direction={'row'} spacing={2}>
            <Button variant="contained" color="primary" onClick={handleSubmit(FirstAidSubmitHandler)}>
              {isEdit ? 'Update' : 'Add'}
            </Button>
          </Stack>
        </Stack>
      </Card>
    </FormProvider>
  );
}

FirstAidAddEditForm.propTypes = {
  data: PropTypes.object,
  isEdit: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default FirstAidAddEditForm;
