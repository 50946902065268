import { Stack } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { FormProvider } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { getFoodRecipeSummary } from 'redux/slices/FoodRecipeSlice';
import FoodRecipeSummaryForm from 'sections/@dashboard/foodMenuAndRecipe/FoodRecipeSummary/FoodRecipeSummaryForm';

const NewRecipeSummarySchema = Yup.object().shape({
  receipesSummary: Yup.array().of(
    Yup.object().shape({
      target: Yup.number().required('').typeError(''),
      targetPercent: Yup.number().required('').typeError(),
    })
  ),
});

export default function FoodRecipeSummaryBody() {
  const { recipeSummary, summaryLoading } = useSelector((state) => state.foodRecipe);

  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const defaultValues = {
    receipesSummary: [],
  };

  const methods = useForm({ defaultValues, resolver: yupResolver(NewRecipeSummarySchema), mode: 'onChange' });
  const { reset } = methods;

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getFoodRecipeSummary({ departmentId }));
  }, [dispatch, departmentId]);
  useEffect(() => {
    if (!Object.keys(recipeSummary).length) return;
    reset({ receipesSummary: [...recipeSummary?.receipesSummary] });
  }, [recipeSummary, reset]);
  return (
    <Stack>
      {summaryLoading ? (
        <CustomLoadingScreen height="70vh" />
      ) : (
        <FormProvider methods={methods}>
          <FoodRecipeSummaryForm />
        </FormProvider>
      )}
    </Stack>
  );
}
