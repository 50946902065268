import React from 'react';
import PropTypes from 'prop-types';
import QuickSummaryRoasterIndividualTableCell from './QuickSummaryRoasterIndividualTableCell';

QuickSummaryRoasterIndividualTableRow.propTypes = {
  user: PropTypes.object,
  mainIndex: PropTypes.number,
};

function QuickSummaryRoasterIndividualTableRow({ user, mainIndex }) {
  return (
    <>
      {Object.keys(user?.userShiftsInDates)?.map((roasterReport, indexes) => (
        <QuickSummaryRoasterIndividualTableCell
          roasterReportKey={roasterReport}
          key={indexes}
          mainIndex={mainIndex}
          userInfo={user}
        />
      ))}
    </>
  );
}

export default QuickSummaryRoasterIndividualTableRow;
