import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { errorToastTime, successToastTime } from 'config';
import apiURL from '../../api';
import { getAllUsers } from './userSlice';
import { getDepartOfStore } from './storeSlice';

export const getUserDepartment = createAsyncThunk('userDepartment/getUserDepartment', async (userId) => {
  try {
    const response = await apiURL.get(`/user/getUserDepartments/${userId}`);
    return response.data;
  } catch (error) {
    if (error.response.status === 400) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    }
    throw error;
  }
});

export const createDepartment = createAsyncThunk(
  'userDepartment/createDepartment',
  async ({ department, currentColor }, thunkAPI) => {
    try {
      const response = await apiURL.post(`/store/department`, {
        name: department,
        colorCode: currentColor,
      });
      if (response.status === 200) {
        toast.success('Department created successfully', { autoClose: successToastTime });
        thunkAPI.dispatch(getDepartOfStore());
      }
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const deleteDepartment = createAsyncThunk(
  'deleteDepartment/deleteDepartment',
  async ({ departmentId }, thunkAPI) => {
    try {
      const response = await apiURL.delete(`/store/department/${departmentId}`);
      if (response.status === 200) {
        toast.success('Department deleted successfully', { autoClose: successToastTime });
        thunkAPI.dispatch(getDepartOfStore());
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      }
      throw error;
    }
  }
);
export const updateDepartment = createAsyncThunk(
  'updateDepartment/updateDepartment',
  async ({ departmentId, department, currentColor }, thunkAPI) => {
    try {
      const response = await apiURL.put(`/store/department`, {
        id: departmentId,
        name: department,
        colorCode: currentColor,
      });
      if (response.status === 200) {
        toast.success('Department updated successfully', { autoClose: successToastTime });
        thunkAPI.dispatch(getDepartOfStore());
      }
      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      }
      throw error;
    }
  }
);

export const removeUserFromDepartment = createAsyncThunk(
  'userDepartment/removeUserFromDepartment',
  async (data, thunkAPI) => {
    try {
      const response = await apiURL.delete(`/user/userDepartment/${data.userId}/${data.departmentId}`);
      toast.success('User remove from department successfully', { autoClose: successToastTime });
      thunkAPI.dispatch(
        getAllUsers({
          departmentId: data.departmentId,
          pageNo: data.pageNo,
          searchUser: data.searchUser,
          statusList: [data.tabValue],
        })
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);
const userDepartmentSlice = createSlice({
  name: 'userDepartment',
  initialState: {
    department: [],
    selectedDepartment: {},
    loading: false,
  },
  reducers: {
    updateSelectedDepartment(state, action) {
      state.selectedDepartment = action.payload;
    },
  },
  extraReducers: {
    [getUserDepartment.pending]: (state) => {
      state.loading = true;
    },
    [getUserDepartment.fulfilled]: (state, action) => {
      state.loading = false;
      state.department = action.payload.departments;
    },
    [getUserDepartment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [removeUserFromDepartment.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [removeUserFromDepartment.fulfilled]: (state) => {
      state.loading = false;
      state.success = true;
    },
    [removeUserFromDepartment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [createDepartment.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [createDepartment.fulfilled]: (state) => {
      state.loading = false;
      state.success = true;
    },
    [createDepartment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [deleteDepartment.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [deleteDepartment.fulfilled]: (state) => {
      state.loading = false;
      state.success = true;
    },
    [deleteDepartment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [updateDepartment.pending]: (state) => {
      state.loading = true;
      // state.success = false;
    },
    [updateDepartment.fulfilled]: (state) => {
      state.loading = false;
      // state.success = true;
    },
    [updateDepartment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export const { updateSelectedDepartment } = userDepartmentSlice.actions;
export default userDepartmentSlice.reducer;
