import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, Grid, Stack } from '@mui/material';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { FormProvider, RHFTextField } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateFoodZones } from 'redux/slices/FoodMenuSlice';
import * as Yup from 'yup';

export default function FoodZonesAddEditForm({ open, setOpen, edit, updateData }) {
  const closeModal = () => {
    setOpen(false);
  };
  const departmentId = useGetDepartmentId();
  const ZoneFormSchema = Yup.object({
    healthy: Yup.number().required('Healthy is required').max(100, 'Healthy must be less than 100'),
  });
  const defaultValues = useMemo(
    () => ({
      healthy: updateData || 0,
    }),
    [updateData]
  );
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(ZoneFormSchema),
  });
  const { watch, handleSubmit, control } = methods;
  const dispatch = useDispatch();
  const createHandler = (data) => {
    const finalData = {
      ...data,
      departmentId,
    };
    dispatch(updateFoodZones(finalData));
    closeModal();
  };
  return (
    <>
      <CustomOutletModal open={open} zIndex="1600" title={edit ? 'Update Food Zones Data' : 'Add Food Zones Data'}>
        <FormProvider methods={methods} onSubmit={handleSubmit(createHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card sx={{ py: 3, px: 3 }}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <RHFTextField name={`healthy`} label="Enter Healthy zone value*" />
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Stack alignItems={'flex-end'}>
                <div>
                  <Button type="submit" variant="contained" style={{ marginRight: '5px' }}>
                    {edit ? 'Update' : 'Create'}
                  </Button>
                  <Button variant="text" style={{ color: 'red' }} onClick={closeModal}>
                    Cancel
                  </Button>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </CustomOutletModal>
    </>
  );
}
