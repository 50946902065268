import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';
import { useDispatch } from 'react-redux';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { getOnlineSalesPlatform } from 'redux/slices/storeSlice';
import Iconify from 'components/Iconify';
import StoreCashDrawerAddForm from './StoreCashDrawerAddForm';

export default function StoreCashDrawerHeader() {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  useEffect(() => {
    if (!departmentId) return;
    dispatch(getOnlineSalesPlatform({ departmentId }));
  }, [dispatch, departmentId]);
  const [openAddCashdrawer, setOpenAddCashdrawer] = useState(false);
  return (
    <>
      <HeaderBreadcrumbs
        heading="Store Cash Drawer"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Store' },
          // { name: 'Cash-Drawer' }
        ]}
        action={
          <Box className="invoiceListHeadingButtons">
            <Button
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} />}
              onClick={() => {
                setOpenAddCashdrawer(true);
              }}
            >
              Add CashDrawer
            </Button>
          </Box>
        }
      />
      {openAddCashdrawer && (
        <StoreCashDrawerAddForm open={openAddCashdrawer} setOpen={setOpenAddCashdrawer} edit={false} />
      )}
    </>
  );
}
