import { Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import FoodRecipeFormRecipeMap from './FoodRecipeFormRecipeMap';
import FoodRecipeFormTargets from './FoodRecipeFormTargets';
import FoodRecipeFormCalculated from './FoodRecipeFormCalculated';

const TABLE_HEAD = [
  { id: 1, name: 'Ingredient Name' },
  { id: 2, name: 'Ingredient Price (KG)' },
  //   { id: 3, name: 'Used By (GM)' },
];

function FoodRecipeForm({ menu, onSubmit }) {
  const { sizes } = menu;
  const { allRecipeofMenu, postLoading } = useSelector((state) => state.foodRecipe);
  const { ingredients } = allRecipeofMenu;
  const { handleSubmit } = useFormContext();

  const menuName = menu?.name?.replace(/ /g, '') || '';

  return (
    <Stack>
      <Stack alignItems={'flex-end'} my="1rem">
        <Button variant="contained" type="button" disabled={postLoading} onClick={handleSubmit(onSubmit)}>
          Submit
        </Button>
      </Stack>
      <FoodRecipeFormCalculated sizes={sizes} menuName={menuName} />
      <TableContainer>
        <Table>
          <TableHead className="foodRecipeFormTableHead">
            <TableRow>
              {TABLE_HEAD?.map((el) => (
                <TableCell key={el.id}>{el?.name}</TableCell>
              ))}
              <TableCell colSpan={sizes?.length} align="center">
                Used By (Gram)
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} />
              {sizes?.map((size, key) => (
                <TableCell key={key}>{size?.name}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ingredients?.map((ingredient, index) => (
              <TableRow key={index}>
                <TableCell>{ingredient?.name}</TableCell>
                <TableCell>$ {ingredient?.pricePerKg?.toFixed(2)}</TableCell>

                {sizes?.map((size, index) => (
                  <TableCell key={index}>
                    <FoodRecipeFormRecipeMap size={size} ingredient={ingredient} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <FoodRecipeFormTargets sizes={sizes} />
    </Stack>
  );
}

export default FoodRecipeForm;
