import React from 'react';
import Page from 'components/Page';
import CheckUserDepartment from 'components/CustomComponents/CheckUserDepartment';
import SalesPlatformTable from './SalesPlatformTable';

export default function SalesPlatform() {
  return (
    <CheckUserDepartment>
      <Page title="Store: Sales platform">
        <SalesPlatformTable />
      </Page>
    </CheckUserDepartment>
  );
}
