import { yupResolver } from '@hookform/resolvers/yup';
import PropTypes from 'prop-types';
import { Box, Button, Card, Grid, Stack } from '@mui/material';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { FormProvider, RHFTextField } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createAdditionalRunningCost } from 'redux/slices/operationalSlice';
import * as Yup from 'yup';

const AddNewRunningCostCategory = ({ open, setOpen, runningCost }) => {
  const dispatch = useDispatch();
  const closeModal = () => {
    setOpen(false);
  };
  const departmentId = useGetDepartmentId();

  const defaultValues = useMemo(
    () => ({
      title: '',
      amount: 0,
    }),
    []
  );

  const newCategorySchema = Yup.object({
    title: Yup.string().required('Required'),
    amount: Yup.number()
      .required('Required')
      .transform((curr, orig) => (orig === '' ? 0 : curr))
      .typeError(''),
  });
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(newCategorySchema),
    mode: 'onChange',
  });
  const runningCategorySubmitHandler = (data) => {
    const finalData = { runningCosts: [data], departmentId, runningCostId: runningCost?.id };
    dispatch(createAdditionalRunningCost(finalData));
    closeModal();
  };

  const { handleSubmit } = methods;
  return (
    <CustomOutletModal width="40rem" open={open} zIndex="1600" title={'Add Running Cost Category'}>
      <FormProvider methods={methods} onSubmit={handleSubmit(runningCategorySubmitHandler)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card sx={{ py: 3, px: 3 }}>
              <Box>
                <Grid container spacing={2} sx={{ p: 1 }}>
                  {/* <Grid item xs={6}>
                    <RHFTextField disabled name={`runningCostId`} label="Running cost id" />
                  </Grid> */}
                  <Grid item xs={6}>
                    <RHFTextField name={`title`} label="Running cost name" />
                  </Grid>

                  <Grid item xs={6}>
                    <RHFTextField name={`amount`} label="Monthly Cost amount" />
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Stack alignItems={'flex-end'}>
              <div>
                <Button type="submit" variant="contained" style={{ marginRight: '5px' }}>
                  Save
                </Button>
                <Button variant="text" style={{ color: 'red' }} onClick={closeModal}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </CustomOutletModal>
  );
};

AddNewRunningCostCategory.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  runningCost: PropTypes.object,
};

export default AddNewRunningCostCategory;
