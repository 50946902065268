import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import PropTypes from 'prop-types';
import PrivateAnnouncementCreateForm from './PrivateAnnouncementCreateForm';

function PrivateAnnouncementEdit({ open, data, handleClose }) {
  return (
    <CustomOutletModal open={open} onClose={handleClose} zIndex="1600" title="Update Private Announcement">
      <PrivateAnnouncementCreateForm handleClose={handleClose} isEdit updateData={data} />
    </CustomOutletModal>
  );
}

PrivateAnnouncementEdit.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.object,
  handleClose: PropTypes.func,
};

export default PrivateAnnouncementEdit;
