import { Button, Divider, Popover, Stack } from '@mui/material';
import CustomDatePicker from 'components/CustomComponents/CustomDatePicker';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { menuSalesReport } from 'redux/slices/FoodMenuSlice';
import { convertDatetoString } from 'utils/customDateAndTimeFormat';

function FoodMenuReportFilter() {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const [openImportDatePop, setOpenImportDatePop] = useState(null);
  const [choosenDate, setChoosenDate] = useState('DAYS');

  const handleOpenImportPopOver = (event) => {
    setOpenImportDatePop(event.currentTarget);
  };

  const handleCloseImportPopOver = () => {
    setOpenImportDatePop(null);
  };
  const open = Boolean(openImportDatePop);
  const ids = open ? 'simple-popover' : undefined;

  const defaultValues = {
    startDate: moment().startOf('isoWeek').toDate(),
    endDate: new Date(),
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, watch } = methods;

  const watchStartDate = watch('startDate');

  useEffect(() => {
    if (!departmentId) return;
    dispatch(
      menuSalesReport({
        startDate: convertDatetoString(moment().startOf('isoWeek').toDate()),
        endDate: convertDatetoString(new Date()),
        departmentId,
      })
    );
  }, [dispatch, departmentId]);

  const menuReportFilter = (status) => {
    let startDate;
    let endDate;
    setChoosenDate(status);
    switch (status) {
      case 'DAYS':
        startDate = new Date();
        endDate = startDate;
        break;
      case 'YESTERDAY':
        startDate = moment().subtract(1, 'days').toDate();
        endDate = startDate;
        break;
      case 'WEEK':
        startDate = moment().startOf('isoWeek').toDate();
        endDate = new Date();
        break;
      case 'LASTWEEK':
        startDate = moment().subtract(1, 'week').startOf('isoWeek').toDate();
        endDate = moment(startDate).add(1, 'week').toDate();
        break;
      case 'MONTH':
        startDate = moment().subtract(1, 'month');
        endDate = new Date();
        break;
      default:
        startDate = new Date();
        endDate = new Date();
    }

    dispatch(
      menuSalesReport({
        startDate: convertDatetoString(startDate),
        endDate: convertDatetoString(endDate),
        departmentId,
      })
    );
  };

  const CustomDateFilter = (data) => {
    setChoosenDate('');
    const finalData = {
      departmentId,
      startDate: convertDatetoString(data?.startDate),
      endDate: convertDatetoString(data?.endDate),
    };
    dispatch(menuSalesReport(finalData));
    handleCloseImportPopOver();
  };

  return (
    <>
      <Stack mx="0.7rem" my="1rem">
        <FormProvider {...methods}>
          <Stack alignItems="center" gap="1rem" justifyContent={'center'} direction={'row'}>
            <Button
              variant={choosenDate === 'DAYS' ? 'contained' : 'outlined'}
              onClick={() => menuReportFilter('DAYS')}
            >
              Today
            </Button>
            <Button
              variant={choosenDate === 'YESTERDAY' ? 'contained' : 'outlined'}
              onClick={() => menuReportFilter('YESTERDAY')}
            >
              Yesterday
            </Button>
            <Button
              variant={choosenDate === 'WEEK' ? 'contained' : 'outlined'}
              onClick={() => menuReportFilter('WEEK')}
            >
              This Week
            </Button>
            <Button
              variant={choosenDate === 'LASTWEEK' ? 'contained' : 'outlined'}
              onClick={() => menuReportFilter('LASTWEEK')}
            >
              Last Week
            </Button>
            <Button
              variant={choosenDate === 'MONTH' ? 'contained' : 'outlined'}
              onClick={() => menuReportFilter('MONTH')}
            >
              Last 30 Days
            </Button>
            <Button onClick={handleOpenImportPopOver}>Custom</Button>
          </Stack>

          <Popover
            id={ids}
            open={open}
            anchorEl={openImportDatePop}
            onClose={handleCloseImportPopOver}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Stack alignItems={'center'} direction="column" gap="1rem" m="1rem">
              <CustomDatePicker name="startDate" fieldWidth={'max-content'} label="Start" disableFuture />
              <CustomDatePicker
                name="endDate"
                fullWidth={false}
                fieldWidth="max-content"
                label="End"
                disableFuture
                minDate={watchStartDate}
              />
              <Stack alignItems={'flex-end'} width="100%">
                <Button variant="contained" type="button" onClick={handleSubmit(CustomDateFilter)}>
                  Filter
                </Button>
              </Stack>
            </Stack>
          </Popover>
        </FormProvider>
      </Stack>
      <Divider />
    </>
  );
}

export default FoodMenuReportFilter;
