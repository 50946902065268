import { Button, Stack } from '@mui/material';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { RHFCheckbox, RHFTextField } from 'components/hook-form';
import PropTypes from 'prop-types';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { createFoodMenuComboCategory } from 'redux/slices/menuEngineeringSlice';

function FoodMenuComboCategoryCreate({ open, setOpen, isEdit, menu, data }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const defaultValues = {
    title: '',
    isOptional: false,
    isMultiSelectable: false,
  };
  const methods = useForm({ defaultValues });

  const { reset, handleSubmit, control } = methods;

  const CreateMenuComboCategory = (data) => {
    const finalData = { ...data, departmentId, menuId: menu?.id };
    dispatch(createFoodMenuComboCategory({ finalData, setOpen, isEdit }));
  };

  useEffect(() => {
    if (isEdit) {
      reset({
        title: data?.title,
        id: data?.id,
        isOptional: data?.isOptional,
        isMultiSelectable: data?.isMultiSelectable,
      });
    }
  }, [data, isEdit, reset]);

  console.log(methods.watch());

  return (
    <>
      <CustomOutletModal
        open={open}
        onClose={() => setOpen({ status: false, data: null })}
        title={`${isEdit ? 'Edit' : 'Create'} Menu Combo Category`}
        zIndex="1600"
      >
        <FormProvider {...methods}>
          <Stack spacing={2}>
            <Stack>
              <RHFTextField
                name="title"
                label="Title"
                control={control}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="e.g. Meat Combo"
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <RHFCheckbox name="isOptional" control={control} label="Optional" />
              <RHFCheckbox name="isMultiSelectable" control={control} label="Multi Selectable" />
            </Stack>
          </Stack>
          <Stack style={{ height: '20px' }} />
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button color="error" variant="outlined" onClick={() => setOpen(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit(CreateMenuComboCategory)}>
              {isEdit ? 'Update' : 'Save'}
            </Button>
          </Stack>
        </FormProvider>
      </CustomOutletModal>
    </>
  );
}

FoodMenuComboCategoryCreate.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isEdit: PropTypes.bool,
  menu: PropTypes.object,
  data: PropTypes.object,
};

export default FoodMenuComboCategoryCreate;
