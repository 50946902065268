import { Container, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { RHFTextField } from 'components/hook-form';
import moment from 'moment';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { fCurrency } from 'utils/formatNumber';

export default function DateSelectionSection() {
  const { control, watch } = useFormContext();

  const values = watch();
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Controller
            name="date"
            control={control}
            defaultValue={values.date}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Select Date"
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(moment(newValue).format('YYYY-MM-DD'));
                }}
                renderInput={(params) => (
                  <TextField {...params} error={!!error} helperText={error ? error.message : null} />
                )}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <RHFTextField
            name="totalSales"
            label="Total Sales"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Grid>
        {values.totalSales > 0 && (
          <Grid item xs={12} sm={6} md={6}>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Total Sales(ex.gst): {fCurrency((values.totalSales * 10) / 11)}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}
