import { Card, Divider, Tab, Tabs } from '@mui/material';
import React, { useState, useEffect } from 'react';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { getChecklistCategory, setChecklistTabValue } from 'redux/slices/storeDocumentSlice';
import { useDispatch, useSelector } from 'react-redux';
import ChecklistCategories from './ChecklistCategories';
import ChecklistTasklist from './ChecklistTasklist';
import ChecklistTab from './ChecklistTab';

function CheckListBody() {
  const [tabValue, setTabValue] = useState('0');
  const { checklistTabValue } = useSelector((state) => state.storeDocument);
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!departmentId) return;
    dispatch(getChecklistCategory({ departmentId }));
  }, [departmentId, dispatch]);

  return (
    <>
      <Card>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={checklistTabValue}
          onChange={(e, value) => {
            dispatch(setChecklistTabValue(value));
            // setTabValue(value);
          }}
          sx={{ px: 2, bgcolor: 'Background.neutral' }}
        >
          {/* <Tab label="Category" value="0" /> */}
          <Tab label="Checklist" value={0} />
          <Tab label="Tasklist" value={1} />
        </Tabs>
        <Divider />
        {/* {tabValue === '0' && <ChecklistCategories />} */}
        {checklistTabValue === 0 && <ChecklistTab />}
        {checklistTabValue === 1 && <ChecklistTasklist />}
      </Card>
    </>
  );
}

export default CheckListBody;
