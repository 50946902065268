import { Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';
import { PATH_DASHBOARD } from 'routes/paths';
import UploadStoreGuidelines from './UploadStoreGuidelines';

function StoreGuidelinesHeader() {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <HeaderBreadcrumbs
        heading="Store Guidelines"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Guideline list' },
          // { name: 'Zones Details' }
        ]}
        action={
          <Button
            variant="contained"
            onClick={() => {
              setOpen(true);
            }}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            New Guideline
          </Button>
        }
      />
      {open && <UploadStoreGuidelines open={open} setOpen={setOpen} />}
    </>
  );
}

export default StoreGuidelinesHeader;
