import { Icon } from '@iconify/react';
import { Box, Button, Container, Divider, Drawer, Stack } from '@mui/material';
import Iconify from 'components/Iconify';
import React, { useState } from 'react';

// open, setOpen in mandatory
// handleClose function is passed if some changes are to be made from parent component when drawer is closed

function CustomDrawer({
  children,
  side = 'right',
  open,
  setOpen,
  handleClose = () => {},
  title = '',
  headerIcon = '',
  iconColor = '',
  iconWidth = '',
  iconHeight = '',
  PaperProps = {},
  ...other
}) {
  const toggleDrawer = (side, opening) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(opening);
    handleClose();
  };

  return (
    <Drawer anchor={side} open={open} onClose={toggleDrawer(side, false)} PaperProps={PaperProps} {...other}>
      <Stack>
        <Stack
          className="customDrawerTitle"
          alignItems={'center'}
          justifyContent={'space-between'}
          flexDirection={'row'}
          mx="1rem"
          my="1rem"
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Iconify
              icon={headerIcon}
              color={iconColor}
              width={iconWidth}
              height={iconHeight}
              style={{ marginTop: '3px', marginRight: '5px' }}
            />
            <h2>{title} </h2>
          </div>
          <Button color="error" onClick={toggleDrawer(side, false)}>
            <Icon icon="material-symbols:close" width="30" />
          </Button>
        </Stack>
        <Divider />
        <Stack className="customDrawerContent" mx="1rem" my="1rem">
          {children}
        </Stack>
      </Stack>
    </Drawer>
  );
}

export default CustomDrawer;
