import { Avatar, Box, InputAdornment, Popover, Stack, Typography } from '@mui/material';
import propTypes from 'prop-types';
import { RHFTextField } from 'components/hook-form';
import React, { useState } from 'react';
import Iconify from 'components/Iconify';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { useDispatch, useSelector } from 'react-redux';
import { getFoodMenuSizePrice } from 'redux/slices/menuEngineeringSlice';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import FoodMenuDrawer from './FoodMenuDrawer';
import FoodMenuSizeDrawer from './FoodMenuSizeDrawer';

FoodMenusDetailsAccordion.propTypes = {
  menu: propTypes.object,
  mainIndex: propTypes.number,
  menuIndex: propTypes.number,
  isOverlay: propTypes.bool,
};

function FoodMenusDetailsAccordion({ menu, mainIndex, menuIndex, isOverlay }) {
  const { foodMenuSizePrices, foodMenuSizePricesLoading } = useSelector((state) => state.menuEngineering);

  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    if (!departmentId) return;
    dispatch(getFoodMenuSizePrice({ menuId: menu.id, departmentId }));
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openSizeDetails = Boolean(anchorEl);
  const [open, setOpen] = useState(false);
  const [openSizes, setOpenSizes] = useState(false);
  const [active, setActive] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: menu.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    borderBottom: '0.5px solid rgba(145, 158, 171, 0.24)',
    backgroundColor: isDragging ? '#cccccc' : '',
    margin: '10px',
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <>
      <Stack
        flexDirection="row"
        alignItems={'center'}
        my="0.5rem"
        className="FoodMenuDetailsIndividualItem"
        ref={setNodeRef}
        style={{ ...style, ...(isOverlay && { backgroundColor: '#cccccc' }) }}
      >
        <Stack
          alignItems="center"
          gap="0.5rem"
          flexDirection={'row'}
          my="0.15rem"
          p="0.4rem"
          borderRadius={'10px'}
          fontWeight={500}
          sx={{ cursor: 'pointer' }}
        >
          <div {...listeners} {...attributes} style={{ marginTop: '10px', marginRight: '10px', cursor: 'move' }}>
            <Iconify icon="system-uicons:drag" height={20} width={20} />
          </div>
          <Stack onClick={() => setOpen(true)}>
            <Avatar alt="menu Image" src={menu?.thumbnailUrl} />
          </Stack>
          <Stack>
            <Stack onClick={() => setOpen(true)} className="menuCategoryMenuName">
              <p style={{ fontWeight: '600' }}>{menu?.name}</p>
            </Stack>
            <Stack style={{ display: 'flex', flexDirection: 'row' }} className="menuCategorySizeDetails">
              <Typography
                variant="caption"
                sx={{ color: '#637381' }}
                onMouseEnter={(event) => {
                  handlePopoverOpen(event);
                }}
                onMouseLeave={handlePopoverClose}
                onClick={() => setOpenSizes(!openSizes)}
                className="menuCategorySizeName"
              >
                {menu?.sizes?.length} Sizes
              </Typography>

              <Popover
                id="mouse-over-popover"
                sx={{ pointerEvents: 'none' }}
                open={openSizeDetails}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'center',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Box sx={{ p: 1, height: 'auto', width: 'auto' }}>
                  {foodMenuSizePricesLoading ? (
                    <CustomLoadingScreen circularWidth="20px" circularHeight="20px" />
                  ) : (
                    <>
                      {foodMenuSizePrices?.map((size, index) => (
                        <Stack key={index} direction="row" alignItems="center" justifyContent="space-between">
                          <Typography variant="caption" sx={{ color: '#637381' }}>
                            {`${size?.sizeName} - $${size?.additionalPrice ?? 0}`}
                          </Typography>
                        </Stack>
                      ))}
                    </>
                  )}
                </Box>
              </Popover>
            </Stack>
          </Stack>
        </Stack>
        <Stack className={'manageMenuSizeList'}>
          <Box className="manageMenuSizeListPriceSettings">
            <div style={{ textAlign: 'center' }}>
              <RHFTextField
                onFocus={() => setActive(true)}
                onBlur={() => setActive(false)}
                style={{ borderRadius: '10px', backgroundColor: active ? `white` : ' rgba(238, 238, 238, 0.8)' }}
                name={`data.${mainIndex}.menus.${menuIndex}.basePrice`}
                size="small"
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                InputLabelProps={{ shrink: true }}
                sx={{ width: '5rem' }}
              />
            </div>
          </Box>
        </Stack>
      </Stack>
      {open && <FoodMenuDrawer open={open} setOpen={setOpen} menu={menu} indexList={{ mainIndex, menuIndex }} />}
      {openSizes && (
        <FoodMenuSizeDrawer open={openSizes} setOpen={setOpenSizes} menu={menu} indexList={{ mainIndex, menuIndex }} />
      )}
    </>
  );
}

export default FoodMenusDetailsAccordion;
