import { Box, Card, CardContent, CardHeader, MenuItem, TableCell, Tooltip } from '@mui/material';
import Iconify from 'components/Iconify';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { TableMoreMenu } from 'components/table';
import { userNoteModalControlAction } from 'redux/slices/userNoteSlice';
import { useDispatch, useSelector } from 'react-redux';
import UserNoteAddForm from './UserNoteAddForm';
import IndividualUserNoteCard from './IndividualUserNoteCard';

function IndividualUserNoteTableCell({ day, mainIndex, userInfo, dayIndex }) {
  const [openCreateModal, setOpenCreateModal] = React.useState(false);
  const [updateData, setUpdateData] = React.useState(null);
  const { control } = useFormContext();
  const { fields, append, update, remove } = useFieldArray({
    name: `users[${mainIndex}].weeklyNote[${day}]`,
    keyName: 'userNoteFieldId',
    control,
  });

  const openCreateNotes = () => {
    setOpenCreateModal(true);
    setUpdateData(null);
  };

  const onUpdate = (item) => {
    setOpenCreateModal(true);
    setUpdateData(item);
  };
  return (
    <>
      {!fields?.length ? (
        <TableCell className="roasternullTableCell" style={{ width: '80px' }} onClick={() => openCreateNotes()}>
          <Box>
            <Iconify icon="material-symbols:add-card-outline-sharp" />
          </Box>
        </TableCell>
      ) : (
        <>
          <TableCell align="center" style={{ width: '120px', margin: '0px', padding: '0px', alignContent: 'center' }}>
            {fields?.map((note, index) => (
              <IndividualUserNoteCard
                key={note?.userNoteFieldId}
                note={note}
                index={index}
                mainIndex={mainIndex}
                userInfo={userInfo}
                onUpdate={onUpdate}
                remove={remove}
                openCreateUserNoteModal={openCreateNotes}
              />
            ))}
          </TableCell>
        </>
      )}

      {openCreateModal && (
        <UserNoteAddForm
          open={openCreateModal}
          setOpen={() => setOpenCreateModal(false)}
          user={userInfo}
          append={append}
          update={update}
          date={day}
          updateData={updateData}
        />
      )}
    </>
  );
}

export default IndividualUserNoteTableCell;
