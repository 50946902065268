import { InputAdornment, TableCell, TextField } from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import React, { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

function IndividualSizeTarget({ index, size, basePrice }) {
  const { control, register, setValue, getValues } = useFormContext();

  const onChangeTarget = (index) => {
    const target = getValues(`menuSizes.${index}.target`);
    const targetExGst = (target * 10) / 11;
    const totalCogs = getValues(`menuSizes.${index}.totalCogs`);
    const spExGst = getValues(`menuSizes.${index}.spExGst`);
    const sp = getValues(`menuSizes.${index}.sp`);
    const difference = target - sp || 0;
    const targetPercent = target ? (totalCogs * 100) / targetExGst : 0;
    const differencePercent = (difference * 100) / sp || 0;

    const targetGp = target ? (target * 10) / 11 - totalCogs : 0;
    const targetGpPercent = target ? (totalCogs * 100) / ((target * 10) / 11) : 0;

    setValue(`menuSizes.${index}.targetGp`, targetGp.toFixed(2));
    setValue(`menuSizes.${index}.targetGpPercent`, targetGpPercent.toFixed(2));
    setValue(`menuSizes.${index}.targetPercent`, targetPercent);
    setValue(`menuSizes.${index}.difference`, difference);
    setValue(`menuSizes.${index}.differencePercent`, differencePercent);
  };

  return (
    <TableCell align="center">
      <input {...register(`menuSizes.${index}.id`)} value={size?.id} hidden />
      {/* <RHFTextField
        name={`menuSizes.${index}.size`}
        size="small"
        onChange={(event) => {
          setValue(`menuSizes.${index}.target`, event.target.value);
          onChangeTarget(index);
        }}
        InputProps={{
          style: { textAlign: 'center', fontWeight: 'normal', fontSize: '14px' },
          startAdornment: (
            <InputAdornment position="start">
              <span style={{ fontSize: '15px' }}>$</span>
            </InputAdornment>
          ),
        }}
        sx={{ width: '60px' }}
        variant="standard"
      /> */}
      <Controller
        name={`menuSizes.${index}.target`}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            fullWidth
            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
            onChange={(event) => {
              setValue(`menuSizes.${index}.target`, event.target.value);
              onChangeTarget(index);
            }}
            InputProps={{
              style: { textAlign: 'center', fontWeight: 'normal', fontSize: '14px' },
              startAdornment: (
                <InputAdornment position="start">
                  <span style={{ fontSize: '15px' }}>$</span>
                </InputAdornment>
              ),
            }}
            variant={'standard'}
            error={!!error}
            helperText={error?.message}
            sx={{ width: '60px' }}
          />
        )}
      />
    </TableCell>
  );
}

export default IndividualSizeTarget;
