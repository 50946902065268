import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import { successToastTime, errorToastTime } from 'config';

export const getAllUserNotes = createAsyncThunk('userNote/getAllUserNotes', async (props) => {
  try {
    const response = await apiURL.post('/roaster/weeklyNotes', props);
    return response.data;
  } catch (err) {
    toast.error(err?.response?.data?.message, successToastTime);
    throw err;
  }
});

export const deleteUserNote = createAsyncThunk('userNote/deleteUserNote', async (props) => {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await apiURL.delete(`/roaster/weeklyNote/${props?.departmentId}/${props?.id}`);
    if (response.status === 200) props?.isSuccess(true);
    return response?.data;
  } catch (err) {
    throw err;
  }
});

export const publishUserNote = createAsyncThunk('userNote/publishUserNote', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/roaster/weeklyNotes/${props?.departmentId}`, props?.finalData);
    if (response.status === 200) {
      toast.success('User note published successfully', { autoClose: successToastTime });
      thunkAPI.dispatch(getAllUserNotes({ searchText: '', departmentId: props?.departmentId }));
    }
    return response?.data;
  } catch (err) {
    toast.error(err?.response?.data?.message, { autoClose: errorToastTime });
    throw err;
  }
});

const userNoteSlice = createSlice({
  name: 'userNote',
  initialState: {
    userNotes: [],
    userNotesLoading: false,
    userNoteWeeks: [],
    modalControl: {
      open: false,
    },
    selectedUserNote: {},
  },
  reducers: {
    setUserNoteWeek(state, action) {
      state.userNoteWeeks = action.payload;
    },

    userNoteModalControlAction(state, action) {
      state.modalControl.open = true;
      state.selectedUserNote = action.payload;
    },

    userNoteModalFalseControlAction(state) {
      state.modalControl.open = false;
      state.selectedUserNote = {};
    },
  },
  extraReducers: {
    [getAllUserNotes.pending]: (state) => {
      state.userNotesLoading = true;
      state.userNotes = [];
    },
    [getAllUserNotes.fulfilled]: (state, action) => {
      state.userNotesLoading = false;
      state.userNotes = action.payload;
    },
    [getAllUserNotes.rejected]: (state) => {
      state.userNotesLoading = false;
      state.userNotes = [];
    },

    [publishUserNote.pending]: (state) => {
      state.postLoading = true;
    },
    [publishUserNote.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [publishUserNote.rejected]: (state) => {
      state.postLoading = false;
    },
  },
});

export const { setUserNoteWeek, userNoteModalControlAction, userNoteModalFalseControlAction } = userNoteSlice.actions;

export default userNoteSlice.reducer;
