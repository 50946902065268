import { Stack, Typography } from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import React, { useEffect, useCallback } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import calculateRecipeInformation from 'utils/FoodRecipeCalculation';

function FoodRecipeFormRecipeMap({ size, ingredient }) {
  const { allRecipeofMenu } = useSelector((state) => state.foodRecipe);
  const { receipeDTOs: recipeList } = allRecipeofMenu;
  const { control, setValue, getValues } = useFormContext();

  const findRecipeIndex = recipeList?.findIndex((recipe) => recipe?.sizeId === size?.id);

  const { fields } = useFieldArray({
    control,
    name: `receipeDTOs.${findRecipeIndex}.ingredients`,
    keyName: 'fieldId',
  });

  const watchCalculated = useWatch({
    control,
    name: `receipeDTOs.${findRecipeIndex}.calculated`,
  });

  const ingredientsMapping = fields?.findIndex((ing) => ing?.id === ingredient?.id);
  const watchIngredients = useWatch({
    control,
    name: `receipeDTOs.${findRecipeIndex}.ingredients.${ingredientsMapping}`,
  });

  const calculatePricePerGram = useCallback(() => {
    const calculatePrice = (
      (Number(watchIngredients?.qtyUsed || 0) / 1000) *
      Number(ingredient?.pricePerKg || 0)
    ).toFixed(2);
    return calculatePrice || 0.0;
  }, [watchIngredients?.qtyUsed, ingredient?.pricePerKg]);

  const onChangeField = (value) => {
    const tp = watchCalculated?.targetPercent || 0;
    setValue(`receipeDTOs.${findRecipeIndex}.targetPercent`, tp.toFixed(2));
  };

  const findAllItemsInformation = useCallback(() => {
    const getIng = getValues(`receipeDTOs.${findRecipeIndex}`);
    const calculateValues = calculateRecipeInformation(getIng);
    setValue(`receipeDTOs.${findRecipeIndex}.calculated`, calculateValues);
  }, [getValues, setValue, findRecipeIndex]);

  // to change every values
  useEffect(() => {
    setValue(
      `receipeDTOs.${findRecipeIndex}.ingredients.${ingredientsMapping}.pricePerGram`,
      Number(calculatePricePerGram())
    );
    findAllItemsInformation();
  }, [calculatePricePerGram, setValue, findRecipeIndex, ingredientsMapping, findAllItemsInformation]);

  if (!recipeList?.[findRecipeIndex]?.sp) {
    return (
      <Typography component="p" alignItems="center" justifyContent={'center'} fontSize="0.8rem" color="error">
        No SP
      </Typography>
    );
  }

  return (
    <Stack flexDirection={'row'} alignItems={'center'} gap="0.2rem">
      <RHFTextField
        name={`receipeDTOs.${findRecipeIndex}.ingredients.${ingredientsMapping}.qtyUsed`}
        value={watchIngredients?.qtyUsed === 0 ? '' : watchIngredients?.qtyUsed}
        size="small"
        fullWidth={false}
        sx={{ width: 'min(10rem,6rem)' }}
        onChange={(event) => {
          if (Number.isNaN(Number(event.target.value))) return;
          setValue(`receipeDTOs.${findRecipeIndex}.ingredients.${ingredientsMapping}.qtyUsed`, event.target.value);
          onChangeField(event.target.value);
        }}
      />
      <Typography variant="p" fontSize="0.7rem">
        $ {calculatePricePerGram()}{' '}
      </Typography>
    </Stack>
  );
}

export default FoodRecipeFormRecipeMap;
