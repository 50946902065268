import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, Divider, Grid, InputAdornment, Stack, Typography } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { FormProvider, RHFTextField } from 'components/hook-form';
import * as Yup from 'yup';
import Iconify from 'components/Iconify';
import React, { useMemo } from 'react';
// import Label from 'components/Label';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { createDriverIncentive } from 'redux/slices/storeSlice';
// import DriverIncentiveForm from './DriverIncentiveForm';

export default function DriverIncentivesCard() {
  const dispatch = useDispatch();
  // const [updateData, setUpdateData] = useState();
  // const [openFormModal, setOpenFormModal] = useState(false);
  // const [openAddForm, setOpenAddForm] = useState(false);

  const { driverIncentives, loading: DIloading } = useSelector((state) => state.store);
  const { break: storeDetail } = useSelector((state) => state.store);
  const isEmpty = driverIncentives?.storeId === undefined;

  const DISchema = Yup.object({
    amount: Yup.number().required('Amount is required').max(999),
  });

  const defaultValues = useMemo(
    () => ({
      amount: driverIncentives?.amount || '',
    }),
    [driverIncentives]
  );

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(DISchema),
  });
  const { handleSubmit, formState } = methods;
  const isDirty = formState.isDirty;

  const createHandler = (data) => {
    const finalData = {
      ...data,
      storeId: storeDetail?.id,
    };
    dispatch(createDriverIncentive(finalData));
  };

  // const handleDIadd = () => {
  //   setOpenAddForm(true);
  // };
  // const handleDIedit = () => {
  //   setUpdateData(driverIncentives);
  //   setOpenFormModal(true);
  // };
  return (
    <Card>
      <Box sx={{ p: 3 }}>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant="h6">Driver Incentive Details</Typography>
          </Grid>
          {/* <Grid item xs={6}>
            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Box>
                {!DIloading && !isEmpty ? (
                  <Button
                    variant="contained"
                    startIcon={<Iconify icon={'material-symbols:edit'} />}
                    onClick={() => {
                      handleDIedit();
                    }}
                  >
                    Edit Incentive
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    startIcon={<Iconify icon={'eva:plus-fill'} />}
                    onClick={() => {
                      handleDIadd();
                    }}
                  >
                    Add Incentive
                  </Button>
                )}
              </Box>
            </Box>
          </Grid> */}
        </Grid>
        <Divider />
        {DIloading ? (
          <CustomLoadingScreen />
        ) : (
          <FormProvider methods={methods} onSubmit={handleSubmit(createHandler)}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box sx={{ p: 3 }}>
                  <Grid container spacing={0}>
                    <Grid item sm={3}>
                      <RHFTextField
                        name={`amount`}
                        label="Enter Driver Incentive Amount*"
                        InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                      />
                    </Grid>
                  </Grid>
                  <div style={{ height: '10px' }} />
                  <Grid item sm={3}>
                    <Stack alignItems={'flex-end'}>
                      <div>
                        <Button type="submit" disabled={!isDirty} variant="contained" style={{ marginRight: '5px' }}>
                          {isEmpty ? 'Create' : 'Update'}
                        </Button>
                      </div>
                    </Stack>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </FormProvider>
        )}
      </Box>
    </Card>
  );
}
