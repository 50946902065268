import { Box, MenuItem, TableCell, TableRow, Typography } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import Iconify from 'components/Iconify';
import { TableMoreMenu } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import StockEdit from 'pages/dashboard/stock/StockEdit';
import { useState } from 'react';
import { deleteStockItems } from 'redux/slices/stockSlice';
import { dispatch, useSelector } from 'redux/store';
import { fCurrency } from 'utils/formatNumber';
import StockCreateOrderTextField from './StockCreateOrderTextField';

function StockTableRow({ row, rowIndex }) {
  const departmentId = useGetDepartmentId();
  const { isCreateStockOrderSwitch } = useSelector((state) => state.stocks);
  const [openMenu, setOpenMenuActions] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState({ stockId: null, status: false });

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleDeleteDialog = () => {
    setOpenDeleteDialog({ stockId: null, status: false });
  };

  const onDeleteStockAccept = () => {
    dispatch(deleteStockItems({ departmentId, stockId: openDeleteDialog?.stockId }));
    handleDeleteDialog();
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const onDeleteRow = (stockId) => {
    setOpenDeleteDialog({ stockId, status: true });
  };

  const onEditRow = (stock) => {
    setOpenUpdateDialog(true);
    setUpdateData(stock);
  };

  return (
    <>
      <TableRow>
        <TableCell>{rowIndex}</TableCell>

        <TableCell>{row?.externalUID}</TableCell>

        <TableCell align="left">
          <Box sx={{ maxWidth: 750 }}>
            <Typography variant="subtitle2">{row?.brand}</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
              {row?.productDescription}
            </Typography>
          </Box>
        </TableCell>

        <TableCell align="left">{row?.uom}</TableCell>
        <TableCell align="left">
          {row?.weight} ({row?.weightType})
        </TableCell>
        <TableCell align="left">{row?.currentQty}</TableCell>
        <TableCell align="left">{row?.orderStock}</TableCell>

        <TableCell align="left">{row?.parLevel}</TableCell>

        <TableCell align="right">{fCurrency(row?.unitPrice)}</TableCell>
        <TableCell align="right">{fCurrency(row?.gst)}</TableCell>
        <TableCell align="right">{fCurrency(row?.levy)}</TableCell>
        <TableCell align="right">{row?.priorityNo}</TableCell>
        <TableCell align="right">{row?.wastePercent}</TableCell>
        {!isCreateStockOrderSwitch.isCreateOrder && (
          <TableCell align="right">
            <TableMoreMenu
              open={openMenu}
              onOpen={handleOpenMenu}
              onClose={handleCloseMenu}
              actions={
                <>
                  <MenuItem
                    onClick={() => {
                      onEditRow(row);
                      handleCloseMenu();
                    }}
                  >
                    <Iconify icon={'eva:edit-fill'} />
                    Edit
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      onDeleteRow(row?.id);
                      handleCloseMenu();
                    }}
                    sx={{ color: 'error.main' }}
                  >
                    <Iconify icon={'eva:trash-2-outline'} />
                    Delete
                  </MenuItem>
                </>
              }
            />
          </TableCell>
        )}
        {isCreateStockOrderSwitch.isCreateOrder && (
          <TableCell>
            <StockCreateOrderTextField row={row} />
          </TableCell>
        )}
      </TableRow>
      {openDeleteDialog?.status && (
        <CustomDeleteDialog open={openDeleteDialog} handleClose={handleDeleteDialog} onAccept={onDeleteStockAccept} />
      )}

      {openUpdateDialog && <StockEdit open={openUpdateDialog} setOpen={setOpenUpdateDialog} currentData={updateData} />}
    </>
  );
}

export default StockTableRow;
