import { Box } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getIngredientDetails } from 'redux/slices/ingredientSlice';
import IngredientManageStockForm from './IngredientManageStockForm';

function IngredientMangeStockDrawer({ open, setOpen, currentData, handleClose }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  useEffect(() => {
    if (!currentData) return;
    if (!departmentId) return;
    dispatch(getIngredientDetails({ departmentId, ingredientId: currentData }));
  }, [currentData, departmentId, dispatch]);

  return (
    // <CustomDrawer title="Manage Stocks" open={open} setOpen={setOpen} handleClose={handleClose}>
    <Box style={{ maxWidth: '50vw' }}>
      <IngredientManageStockForm currentData={currentData} />
    </Box>
    // </CustomDrawer>
  );
}

export default IngredientMangeStockDrawer;
