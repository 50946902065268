import { useSortable } from '@dnd-kit/sortable';
import { Box, Button, Stack } from '@mui/material';
import { CSS } from '@dnd-kit/utilities';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import Iconify from 'components/Iconify';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteSizeOptionGroup } from 'redux/slices/menuERSizeAndOptionSlice';
import ManageMenuSizeOption from 'sections/@dashboard/menuEngineering/menuSizeOption/ManageMenuSizeOption';

function MenuSizeOptionDetails({ options, size, optionIndex }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const [openDelete, setOpenDelete] = useState({ status: false, sizeGroupId: null, optionGroupId: null });

  const [openManage, setOpenManage] = useState(false);

  const handleDelete = () => {
    dispatch(
      deleteSizeOptionGroup({
        sizeGroupId: openDelete?.sizeGroupId,
        optionGroupId: openDelete?.optionGroupId,
        departmentId,
      })
    );
    setOpenDelete({ status: false, sizeGroupId: null, optionGroupId: null });
  };

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: options?.id,
  });

  const style = {
    borderBottom: '0.5px solid rgba(145, 158, 171, 0.24)',
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <>
      <Stack
        key={options?.id}
        flexDirection="row"
        alignItems={'center'}
        my="0.5rem"
        className="FoodMenuDetailsIndividualItem"
        //   style={{ borderBottom: '0.5px solid rgba(145, 158, 171, 0.24)' }}
        style={style}
      >
        <Stack
          alignItems="center"
          gap="0.5rem"
          flexDirection={'row'}
          my="0.15rem"
          p="0.4rem"
          borderRadius={'10px'}
          fontWeight={500}
          sx={{ cursor: 'pointer' }}
        >
          <div
            {...listeners}
            {...attributes}
            ref={setNodeRef}
            style={{ marginTop: '10px', marginRight: '10px', cursor: 'move' }}
          >
            <Iconify icon="system-uicons:drag" height={20} width={20} />
          </div>
          <Stack>
            <p style={{ fontWeight: '600' }}>{options?.optionGroupName}</p>
          </Stack>
        </Stack>
        <Stack className={'manageMenuSizeList'}>
          <Box className="manageMenuSizeListPriceSettings">
            <div style={{ textAlign: 'center' }}>
              <Button
                style={{ marginRight: '5px' }}
                variant="text"
                onClick={() => setOpenManage({ status: true, data: options })}
              >
                <Iconify icon="material-symbols:folder-managed-outline" height={20} width={20} />
              </Button>
              <Button
                variant="text"
                color="error"
                onClick={() =>
                  setOpenDelete({
                    status: true,
                    sizeGroupId: options?.sizeGroupId,
                    optionGroupId: options?.optionGroupId,
                  })
                }
              >
                <Iconify icon="tabler:trash" height={20} width={20} />
              </Button>
            </div>
          </Box>
        </Stack>
      </Stack>
      {openDelete?.status && (
        <CustomDeleteDialog
          open={openDelete?.status}
          handleClose={() => setOpenDelete({ status: false, sizeGroupId: null, optionGroupId: null })}
          onAccept={handleDelete}
        />
      )}
      {openManage?.status && (
        <ManageMenuSizeOption open={openManage} setOpen={setOpenManage} selectedRow={openManage?.data} size={size} />
      )}
    </>
  );
}

export default MenuSizeOptionDetails;
