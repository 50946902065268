import { Icon } from '@iconify/react';
import { Button, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

function TimeClockAccordionDetailsExtraInformation({ clockIn, clockOut, onEventDelete, index, onUpdate }) {
  const { timeClockStatus } = useSelector((state) => state.time);

  let totalHours = 0;
  if (!clockOut) {
    totalHours = 0;
  } else {
    const inMilliSecond = moment(clockOut?.clockTime).diff(moment(clockIn?.clockTime));
    totalHours = moment.duration(inMilliSecond).asHours();
  }

  return (
    <>
      <Stack width={'10%'}>
        <Stack>
          <Typography component="p">{totalHours ? totalHours.toFixed(2) : 'TBD'} Hr</Typography>
        </Stack>
        <Stack alignItems={'center'} justifyContent={'center'} direction="row" gap={0.2}>
          {timeClockStatus === 'PENDING' && (
            <Button onClick={() => onUpdate(clockIn, clockOut, index)} size="small">
              <Icon icon="akar-icons:edit" />
            </Button>
          )}
          <Button onClick={() => onEventDelete(clockIn, clockOut, index)} size="small">
            <Icon icon="pajamas:remove" color={'red'} />
          </Button>
        </Stack>
      </Stack>
    </>
  );
}

export default TimeClockAccordionDetailsExtraInformation;
