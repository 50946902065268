import { Divider, Stack } from '@mui/material';
import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TimeClockAccordionDetailsClockOut from './TimeClockAccordionDetailsClockOut';
import TimeClockAccordionDetailsClockIn from './TimeClockAccordionDetailsClockIn';
import TimeClockAccordionDetailsExtraInformation from './TimeClockAccordionDetailsExtraInformation';

TimeClockAccordionDetailsClockList.propTypes = {
  clockList: PropTypes.array,
  onEventDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};

function TimeClockAccordionDetailsClockList({ clockList, onEventDelete, onUpdate }) {
  const { storeConfsDetails } = useSelector((state) => state.storeConfig);

  let { maxAceptedMins } = storeConfsDetails;
  maxAceptedMins = maxAceptedMins === 0 || maxAceptedMins === null ? 0 : maxAceptedMins;

  return (
    <Stack flex={1} className="TimeClockAccordionDetailsClockListCSS" borderLeft={'2px solid #f4f6f8'}>
      {clockList?.map((clockInfo, index) => {
        const clockIn = clockInfo?.clockIn;
        const clockOut = clockInfo?.clockOut;
        const timeDifference = moment(clockOut?.clockTime).diff(moment(clockIn?.clockTime), 'minutes');

        return (
          <Stack
            key={clockInfo?.fieldId}
            flexDirection={'row'}
            className="timeClockAccordionDetailsClockList-Each-element"
            gap={1}
            p={2}
            divider={<Divider flexItem orientation="vertical" />}
          >
            <TimeClockAccordionDetailsClockIn
              clockIn={clockIn}
              index={index}
              timeDifference={timeDifference}
              maxAceptedMins={maxAceptedMins}
            />
            <TimeClockAccordionDetailsClockOut
              clockOut={clockOut}
              index={index}
              timeDifference={timeDifference}
              maxAceptedMins={maxAceptedMins}
            />
            <TimeClockAccordionDetailsExtraInformation
              clockIn={clockIn}
              clockOut={clockOut}
              onEventDelete={onEventDelete}
              index={index}
              onUpdate={onUpdate}
            />
          </Stack>
        );
      })}
    </Stack>
  );
}

export default TimeClockAccordionDetailsClockList;
