import { Button, Stack } from '@mui/material';
import CustomDatePicker from 'components/CustomComponents/CustomDatePicker';
import { FormProvider, RHFTextField } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { getReport } from 'redux/slices/reportSlice';
import { convertDatetoString } from 'utils/customDateAndTimeFormat';

function ReportFilter() {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const defaultValues = {
    startDate: moment().startOf('isoWeek').toDate(),
    endDate: new Date(),
  };
  const methods = useForm({ defaultValues });
  const { handleSubmit, watch } = methods;
  const watchStartDate = watch('startDate');

  const ReportFilterHandler = (data) => {
    const finalData = {
      departmentId,
      startDate: convertDatetoString(data?.startDate),
      endDate: convertDatetoString(data?.endDate),
    };
    dispatch(getReport(finalData));
  };

  useEffect(() => {
    if (!departmentId) return;
    dispatch(
      getReport({
        departmentId,
        startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      })
    );
  }, [dispatch, departmentId]);

  return (
    <Stack m="1rem">
      <FormProvider methods={methods} onSubmit={handleSubmit(ReportFilterHandler)}>
        <Stack alignItems={'center'} direction="row" gap="1rem">
          <CustomDatePicker
            name="startDate"
            fieldWidth={'max-content'}
            inputFormat="dd/MMM/yyyy"
            label="Start"
            disableFuture
          />
          <CustomDatePicker
            name="endDate"
            fullWidth={false}
            inputFormat="dd/MMM/yyyy"
            fieldWidth="max-content"
            label="End"
            disableFuture
            minDate={watchStartDate}
          />
          <Button variant="contained" type="submit">
            Filter
          </Button>
        </Stack>
      </FormProvider>
    </Stack>
  );
}

export default ReportFilter;
