import {
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from 'components/Iconify';
import Scrollbar from 'components/Scrollbar';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useState, useEffect } from 'react';
import { FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { deleteFoodMenuBaseIngredients, updateFoodMenuBaseIngredients } from 'redux/slices/menuEngineeringSlice';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FoodMenuBaseIngredientAddForm from './FoodMenuBaseIngredientAddForm';
import FoodMenuBaseIngredientIndividualTableCell from './FoodMenuBaseIngredientIndividualTableCell';
import FoodMenuBaseIngredientSummaryTableCell from './FoodMenuBaseIngredientSummaryTableCell';

function FoodMenuBaseIngredientTable({ menu }) {
  const [open, setOpen] = useState(false);
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, data: null, comboIndex: null });
  const { menuBaseIngredients, foodMenuSizes } = useSelector((state) => state.menuEngineering);

  const schema = Yup.object().shape({
    ingredients: Yup.array().of(
      Yup.object().shape({
        menuSizes: Yup.array().of(
          Yup.object().shape({
            qtyUsed: Yup.number()
              .typeError('')
              .transform((curr, orig) => (orig === '' ? 0 : curr)),
          })
        ),
      })
    ),
  });

  const defaultValues = {};

  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onChange' });

  const { reset, handleSubmit, formState, control } = methods;

  const menuBaseIngredientsSubmitHandler = (data) => {
    const finalData = { ...data, departmentId, menuId: menu?.id };
    dispatch(updateFoodMenuBaseIngredients({ finalData, menuId: menu?.id, setOpen }));
  };

  const isChanged = formState.isDirty;

  const HandleDeleteBaseIngredient = () => {
    dispatch(deleteFoodMenuBaseIngredients({ departmentId, id: openDeleteModal?.id, menuId: openDeleteModal?.menuId }));

    setOpenDeleteModal({ data: null, status: false });
  };

  useEffect(() => {
    if (!menuBaseIngredients?.ingredients?.length) return;
    const resetData = menuBaseIngredients?.ingredients?.map((ingredient) => {
      const menuSizes = menuBaseIngredients?.menuSizes?.map((size, index) => ({
        qtyUsed: ingredient?.menuSizes?.find((menuSize) => menuSize?.id === size?.id)?.qtyUsed || 0,
      }));
      return {
        id: ingredient?.id,
        name: ingredient?.name,
        menuSizes,
      };
    });

    reset({ ingredients: resetData });
  }, [foodMenuSizes, menuBaseIngredients, reset]);

  const findItem = ({ size, menuSizes }) => {
    const item = menuSizes?.find((menuSize) => menuSize?.id === size?.sizeId);
    return item;
  };

  console.log(methods.watch());

  return (
    <>
      {menuBaseIngredients?.ingredients?.length > 0 ? (
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <Stack spacing={2}>
              <Scrollbar>
                <TableContainer>
                  <Table size="small" stickyHeader>
                    <TableHead>
                      <TableRow hover>
                        <TableCell align="center" style={{ width: '150px' }}>
                          Ingredient
                        </TableCell>
                        {menuBaseIngredients?.menuSizes?.map((size, index) => (
                          <TableCell key={index} align="center">
                            {size?.sizeName} <br />
                            (qty / cost)
                          </TableCell>
                        ))}
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {menuBaseIngredients?.ingredients?.map((ingredient, ingredientIndex) => (
                        <TableRow hover key={ingredientIndex}>
                          <TableCell>
                            <Tooltip title={ingredient?.name} placement="top">
                              <Typography noWrap variant="body2">
                                {ingredient?.name.length > 20 ? `${ingredient?.name.slice(0, 20)}..` : ingredient?.name}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          {menuBaseIngredients?.menuSizes?.map((size, index) => (
                            <FoodMenuBaseIngredientIndividualTableCell
                              key={index}
                              size={findItem({ size, ingredient })}
                              index={index}
                              ingredientIndex={ingredientIndex}
                            />
                          ))}
                          <TableCell align="center">
                            <Button
                              variant="text"
                              color="error"
                              size="small"
                              onClick={() => {
                                setOpenDeleteModal({
                                  status: true,
                                  id: ingredient?.id,
                                  menuId: menu?.id,
                                });
                              }}
                            >
                              <Iconify icon="bi:trash-fill" height={20} width={20} />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                      <TableRow hover>
                        <TableCell style={{ fontWeight: 'bold' }}>Total:</TableCell>
                        {foodMenuSizes?.map((size, index) => (
                          <FoodMenuBaseIngredientSummaryTableCell key={index} size={size} index={index} />
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Stack>

            <Stack alignItems={'flex-end'}>
              <Stack display={'flex'} flexDirection={'row'}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Iconify icon={'eva:plus-fill'} />}
                  onClick={() => setOpen(true)}
                >
                  New
                </Button>

                <Button
                  variant="contained"
                  color="primary"
                  disabled={!isChanged}
                  onClick={handleSubmit(menuBaseIngredientsSubmitHandler)}
                  style={{ marginLeft: '10px' }}
                >
                  {/* {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : 'Save'} */}
                  Save
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </FormProvider>
      ) : (
        <Stack alignItems={'flex-start'} style={{ marginTop: '5px' }}>
          <Typography variant={'body2'}>No Base Ingredient Added</Typography>
          <Typography variant={'body2'}>
            <Button
              variant="text"
              color="primary"
              //   startIcon={<Iconify icon={'eva:plus-fill'} />}
              onClick={() => setOpen(true)}
            >
              Click Here
            </Button>
            to add new base ingredient
          </Typography>
        </Stack>
      )}
      {open && <FoodMenuBaseIngredientAddForm open={open} setOpen={setOpen} menu={menu} />}
      {openDeleteModal?.status && (
        <CustomDeleteDialog
          open={openDeleteModal}
          handleClose={setOpenDeleteModal}
          onAccept={HandleDeleteBaseIngredient}
        />
      )}
    </>
  );
}

FoodMenuBaseIngredientTable.propTypes = {
  menu: PropTypes.object,
};

export default FoodMenuBaseIngredientTable;
