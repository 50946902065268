import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import { errorToastTime } from 'config';

export const getPendingLeaveRequest = createAsyncThunk('roaster/myUpcomingLeaveRequest', async (data) => {
  try {
    const response = await apiURL.post('/roaster/viewLeaveRequests', data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getApprovedLeaveRequest = createAsyncThunk('roaster/myApprovedLeaveRequest', async (data) => {
  try {
    const response = await apiURL.post('/roaster/viewLeaveRequests', data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getLastRoasterPublishDate = createAsyncThunk('roaster/lastRoasterPublishDate', async (depId) => {
  try {
    const response = await apiURL.get(`/roaster/lastDateOfPublishedRoaster/${depId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateLeaveRequest = createAsyncThunk('roaster/updateLeaveRequest', async (data, thunkAPI) => {
  try {
    const response = await apiURL.put('/roaster/approveLeaveRequests', data.dataSet);
    if (response.status === 200) {
      thunkAPI.dispatch(
        getPendingLeaveRequest({
          departmentId: data.dataSet.departmentId,
          startDate: data.fromDate,
          endDate: data.toDate,
          status: 'PENDING',
        })
      );
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteLeaveRequest = createAsyncThunk('roaster/deleteLeaveRequest', async (data) => {
  const response = await apiURL.delete(`/roaster/leaveRequest/${data.departmentId}/${data.id}`);
  if (response.status === 200) {
    data.isSuccess(true);
  }
  return response.data;
});

const leaveRequestSlice = createSlice({
  name: 'leaveRequest',
  initialState: {
    pendingLeaveRequest: [],
    approvedLeaveRequest: [],
    lastRoasterPublishDate: null,
    loading: false,
  },

  extraReducers: {
    [getPendingLeaveRequest.pending]: (state) => {
      state.loading = true;
    },
    [getPendingLeaveRequest.fulfilled]: (state, action) => {
      state.pendingLeaveRequest = action.payload;
      state.loading = false;
    },
    [getPendingLeaveRequest.rejected]: (state) => {
      state.loading = false;
    },
    [getApprovedLeaveRequest.pending]: (state) => {
      state.loading = true;
    },
    [getApprovedLeaveRequest.fulfilled]: (state, action) => {
      state.approvedLeaveRequest = action.payload;
      state.loading = false;
    },
    [getApprovedLeaveRequest.rejected]: (state) => {
      state.loading = false;
    },
    [getLastRoasterPublishDate.pending]: (state) => {
      state.loading = true;
    },
    [getLastRoasterPublishDate.fulfilled]: (state, action) => {
      state.lastRoasterPublishDate = action.payload;
      state.loading = false;
    },
    [getLastRoasterPublishDate.rejected]: (state) => {
      state.loading = false;
    },
    [updateLeaveRequest.pending]: (state) => {
      state.loading = true;
    },
    [updateLeaveRequest.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateLeaveRequest.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default leaveRequestSlice.reducer;
