import {
  Avatar,
  Box,
  Button,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Scrollbar from 'components/Scrollbar';
import { TableNoData } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDatedTaskList, getTaskLists } from 'redux/slices/storeDocumentSlice';

function CompletedTasklist({ choosenDate }) {
  const [pageNo, setPageNo] = React.useState(1);
  const selectedDate = moment(choosenDate).format('YYYY-MM-DD');

  const today = moment(new Date()).format('YYYY-MM-DD');

  const isNotToday = selectedDate < today || selectedDate > today;
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const { taskLists, loading } = useSelector((state) => state.storeDocument);

  useEffect(() => {
    if (!departmentId) return;
    if (isNotToday) {
      dispatch(getDatedTaskList({ departmentId, status: 'COMPLETED', taskDate: choosenDate }));
    } else {
      dispatch(getTaskLists({ departmentId, status: 'COMPLETED', pageNo }));
    }
  }, [dispatch, departmentId, isNotToday, choosenDate, pageNo]);

  const findDuration = (start, end) => {
    if (!start) return '---';

    const diff = moment(end).diff(start, 'minutes');
    return `${Math.floor(diff / 60)}h ${diff % 60}m`;
  };
  return (
    <>
      <Stack spacing={2} style={{ marginTop: '20px' }}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {loading ? (
          <CustomLoadingScreen height="20vh" />
        ) : taskLists?.checklistCategories?.length < 1 ? (
          <TableNoData isNotFound={taskLists?.checkListCategories?.length < 1} />
        ) : (
          <Scrollbar>
            {taskLists?.checkListCategories?.map((category, index) => (
              <>
                <Button
                  disableRipple
                  style={{
                    padding: '5px',
                    color: 'white',
                    backgroundColor: `${category?.colorCode}`,
                    borderRadius: '5px',
                    marginLeft: '8px',
                    marginBottom: '5px',
                  }}
                >
                  {category?.name}
                </Button>
                <TableContainer key={index}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.N.</TableCell>
                        <TableCell style={{ width: '150px' }}>Name</TableCell>
                        <TableCell style={{ width: '250px' }}>Note</TableCell>
                        <TableCell>Started On</TableCell>
                        <TableCell>Completed On</TableCell>
                        <TableCell> Duration</TableCell>
                        <TableCell>Completed By</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {category?.tasks?.map((item, index) => (
                        <TableRow key={index} hover>
                          <TableCell>{index + 1}.</TableCell>
                          <TableCell>
                            {item.title?.length > 15 ? (
                              <Tooltip title={item?.title} placement="right">
                                <Typography noWrap variant="body2">
                                  {`${item.title.slice(0, 15)}..`}
                                </Typography>
                              </Tooltip>
                            ) : (
                              item.title
                            )}
                          </TableCell>
                          <TableCell>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            {item?.note ? (
                              item?.note?.length > 25 ? (
                                <Tooltip title={item?.note} placement="right">
                                  <Typography noWrap variant="body2">
                                    {item.note?.length > 25 ? `${item.note.slice(0, 25)}..` : item.note}
                                  </Typography>
                                </Tooltip>
                              ) : (
                                item?.note
                              )
                            ) : (
                              '---'
                            )}
                          </TableCell>
                          <TableCell>
                            {item?.startedDateTime
                              ? moment(item?.startedDateTime).format('ddd, DD MMM, hh:mm A')
                              : '---'}
                          </TableCell>
                          <TableCell>{moment(item?.completedDateTime).format('ddd, DD MMM, hh:mm A')}</TableCell>
                          <TableCell>{findDuration(item?.startedDateTime, item?.completedDateTime)}</TableCell>
                          <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                            {item?.user?.thumbnailURL ? (
                              <Avatar
                                src={item?.user?.thumbnailURL}
                                alt=""
                                style={{
                                  height: '30px',
                                  width: '30px',
                                  borderRadius: '100%',
                                  marginRight: '10px',
                                }}
                              />
                            ) : (
                              <Avatar
                                alt=""
                                src=""
                                style={{ height: '30px', width: '30px', borderRadius: '100%', marginRight: '10px' }}
                              />
                            )}
                            <Typography variant="subtitle2" noWrap>
                              {`${item?.user?.firstName} ${item?.user?.lastName}`}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ))}
            {taskLists?.checkListCategories?.length === 0 ? (
              <Stack display={'flex'} alignItems={'center'}>
                <TableNoData isNotFound={taskLists?.checkListCategories?.length === 0} />
              </Stack>
            ) : (
              <Box
                sx={{ position: 'relative', marginTop: '30px' }}
                justifyContent={'end'}
                alignContent="center"
                display={'flex'}
              >
                <Pagination
                  count={taskLists?.paginationInfo?.totalPages}
                  page={pageNo}
                  onChange={(e, value) => setPageNo(value)}
                />
              </Box>
            )}
          </Scrollbar>
        )}
      </Stack>
    </>
  );
}

export default CompletedTasklist;
