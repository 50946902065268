/* eslint-disable array-callback-return */
import { TableBody, TableCell, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
// import { FormProvider } from 'components/hook-form';
import { dispatch, useSelector } from 'redux/store';
import { mergeCurrentRoasterwithPrevious } from 'utils/roasterFeatureUtils';
import { removePastRoaster } from 'redux/slices/roasterSlice';

import RoasterTableBodyUserEntity from './RoasterTableBodyUserEntity';

function RoasterTableBody() {
  const { control, watch } = useFormContext();
  const { pastRoaster } = useSelector((state) => state.roaster);

  const { fields, replace } = useFieldArray({
    control,
    name: 'users',
    keyName: 'fieldID',
  });

  // this effect is used to run when import button is clicked
  useEffect(() => {
    if (!Object.keys(pastRoaster).length) return;
    const getUser = pastRoaster?.users;
    const getMergeData = mergeCurrentRoasterwithPrevious(fields, getUser);
    replace(getMergeData);
    dispatch(removePastRoaster());
  }, [pastRoaster, replace]);

  return (
    <TableBody className="roasterTableBodyContainer">
      {/* main list of array */}
      {fields?.map((obj, mainIndex) => (
        <RoasterTableBodyUserEntity key={obj?.fieldID} obj={obj} mainIndex={mainIndex} />
      ))}
    </TableBody>
  );
}

export default RoasterTableBody;
