import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFText.propTypes = {
  name: PropTypes.string,
  everyDisabled: PropTypes.bool,
};

export default function RHFText({ name, everyDisabled = 'true', ...other }) {
  const TFVariant = 'standard';
  // const everyDisabled = true;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          fullWidth
          value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          id="filled-basic"
          variant={TFVariant}
          disabled={everyDisabled}
          error={!!error}
          helperText={error?.message}
          {...other}
        />
      )}
    />
  );
}
