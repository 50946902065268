import React, { useState } from 'react';
import { Card, Divider, Tab, TableContainer, Tabs } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import LeaveRequestTable from './LeaveRequestTable';
import LeaveApproveTable from './LeaveApproveTable';

// DUMMY DATA
export default function LeaveRequestCard() {
  const [tabValue, setTabValue] = useState('pending');

  return (
    <>
      <Card>
        {/* TABS */}
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={tabValue}
          onChange={(e, value) => {
            setTabValue(value);
          }}
          sx={{ px: 2, bgcolor: 'background.neutral' }}
        >
          <Tab label="Pending" value="pending" />
          <Tab label="Approved" value="approved" />
        </Tabs>
        <Divider />

        {/* TABLE LIST */}
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            {tabValue === 'pending' ? <LeaveRequestTable /> : <LeaveApproveTable />}
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
