import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import { errorToastTime, successToastTime } from 'config';

export const getTradingHours = createAsyncThunk('storeConfig/getTradingHours', async (props) => {
  try {
    const response = await apiURL.get(`/storeConf/tradingHours/${props?.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateTradingHours = createAsyncThunk('storeConfig/updateTradingHours', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/storeConf/tradingHours/${props?.departmentId}`, props?.finalData);
    if (response.status === 200) {
      toast.success('Trading hours updated', { autoClose: successToastTime });
      thunkAPI.dispatch(getTradingHours({ departmentId: props?.departmentId }));
    }
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getStoreConfigDetails = createAsyncThunk('storeConfig/getStoreConfigDetails', async (props) => {
  try {
    const response = await apiURL.get(`/storeConf`);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateStoreConfigDetails = createAsyncThunk(
  'storeConfig/updateStoreConfigDetails',
  async (props, thunkAPI) => {
    try {
      const response = await apiURL.post(`/storeConf`, props?.data);
      if (response.status === 200) {
        toast.success('Store config updated', { autoClose: successToastTime });
        props?.setOpen(false);
        thunkAPI.dispatch(getStoreConfigDetails());
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const getEstimatedTimeData = createAsyncThunk('storeConfig/getEstimatedTimeData', async (props) => {
  try {
    const response = await apiURL.get(`/storeConf/estimatedTime/${props?.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const saveEstimatedTimeData = createAsyncThunk('storeConfig/saveEstimatedTimeData', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/storeConf/estimatedTime`, props?.finalData);
    if (response.status === 200) {
      toast.success('Estimated time updated', { autoClose: successToastTime });
      thunkAPI.dispatch(getEstimatedTimeData({ departmentId: props?.departmentId }));
    }
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

const storeConfigSlice = createSlice({
  name: 'storeConfig',
  initialState: {
    currentTab: 0,
    tradingHours: [],
    tradingHoursLoading: false,
    postLoading: false,

    storeConfsDetails: {},
    loading: false,

    storeConfWeeks: [],
    estimatedTime: {},
  },
  reducers: {
    setTradingHoursWeek: (state, action) => {
      state.storeConfWeeks = action.payload;
    },
    setStoreConfCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
  },
  extraReducers: {
    [getEstimatedTimeData.pending]: (state) => {
      state.loading = true;
      state.estimatedTime = {};
    },
    [getEstimatedTimeData.fulfilled]: (state, action) => {
      state.loading = false;
      state.estimatedTime = action.payload;
    },
    [getEstimatedTimeData.rejected]: (state) => {
      state.loading = false;
      state.estimatedTime = {};
    },

    [getTradingHours.pending]: (state) => {
      state.tradingHoursLoading = true;
      state.tradingHours = [];
    },
    [getTradingHours.fulfilled]: (state, action) => {
      state.tradingHoursLoading = false;
      state.tradingHours = action.payload;
    },
    [getTradingHours.rejected]: (state) => {
      state.tradingHoursLoading = false;
      state.tradingHours = [];
    },

    [updateTradingHours.pending]: (state) => {
      state.postLoading = true;
    },
    [updateTradingHours.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateTradingHours.rejected]: (state) => {
      state.postLoading = false;
    },

    [getStoreConfigDetails.pending]: (state) => {
      state.loading = true;
      state.storeConfsDetails = {};
    },
    [getStoreConfigDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.storeConfsDetails = action.payload;
    },
    [getStoreConfigDetails.rejected]: (state) => {
      state.loading = false;
      state.storeConfsDetails = {};
    },

    [updateStoreConfigDetails.pending]: (state) => {
      state.postLoading = true;
    },
    [updateStoreConfigDetails.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateStoreConfigDetails.rejected]: (state) => {
      state.postLoading = false;
    },
  },
});

export const { setTradingHoursWeek, setStoreConfCurrentTab } = storeConfigSlice.actions;

export default storeConfigSlice.reducer;
