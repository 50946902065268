import { Card, Stack } from '@mui/material';
import { TableNoData } from 'components/table';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import SingleSizeGroupTable from './SingleSizeGroupTable';

function HalfAndHalfBodySection() {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: 'data',
    keyName: 'fieldId',
  });

  return (
    <>
      {!fields?.length ? (
        <Stack alignItems={'center'}>
          <TableNoData isNotFound={fields?.length === 0} />
        </Stack>
      ) : (
        <>
          {fields?.map((sizeGroup, index) => (
            <Card style={{ padding: '5px', marginBottom: '15px' }} key={index}>
              <SingleSizeGroupTable key={index} sizeGroup={sizeGroup} index={index} />
            </Card>
          ))}
        </>
      )}
    </>
  );
}

export default HalfAndHalfBodySection;
