import { AccordionDetails, Stack } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import TimeClockAccordionDetailsDateList from './TimeClockAccordionDetails/TimeClockAccordionDetailsDateList';

TimeClockAccordionDetails.propTypes = {
  user: PropTypes.object,
  userIndex: PropTypes.number,
};

function TimeClockAccordionDetails({ user, userIndex }) {
  return (
    <AccordionDetails sx={{ padding: 0 }}>
      <Stack className="timeClockAccordionDetailsDateClock">
        {Object.keys(user?.timeClocks).map((date, index) => (
          <>
            <TimeClockAccordionDetailsDateList
              key={index}
              date={date}
              clockListIndex={date}
              userIndex={userIndex}
              userId={user?.userId}
            />
          </>
        ))}
      </Stack>
    </AccordionDetails>
  );
}

export default TimeClockAccordionDetails;
