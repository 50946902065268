import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { toast } from 'react-toastify';
import apiURL from 'api';
import { convertDatetoString } from 'utils/customDateAndTimeFormat';
import { errorToastTime, successToastTime } from 'config';
import moment from 'moment';

// latest
export const getAllUserClockEvents = createAsyncThunk('time/getAllUserClockEvents', async (props) => {
  try {
    const response = await apiURL.post(`/timeclock/getClockEventsForDateRange/${props.departmentId}`, props.data);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});
export const getAllUserClockEventsInternally = createAsyncThunk(
  'time/getAllUserClockEventsInternally',
  async (props) => {
    try {
      const response = await apiURL.post(`/timeclock/getClockEventsForDateRange/${props.departmentId}`, props.data);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const approveClockEvents = createAsyncThunk('time/approveClockEvents', async ({ onSuccess, data }) => {
  try {
    const response = await apiURL.put(`/timeclock/clockEvents/approve`, data);
    if (response?.status === 200) {
      onSuccess();
      toast.success('Events Approved Successfully.');
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createTimeClockByManager = createAsyncThunk(
  'time/createTimeClockByManager',
  async ({ data }, thunkAPI) => {
    try {
      const response = await apiURL.post(`/timeclock/createClockEventManually`, data);
      if (response?.status === 200) {
        if (!data?.clockInId) {
          const newData = {
            departmentId: data?.departmentId,
            data: {
              approvalStatusList: ['APPROVED'],
              startDate: moment(data?.clockInDatetime).format('YYYY-MM-DD'),
              endDate: moment(data?.clockInDatetime).format('YYYY-MM-DD'),
            },
          };
          // console.log(newData);
          thunkAPI.dispatch(getAllUserClockEventsInternally(newData));
        }
      }
      const checkClockIn = data?.clockInId ? 'Clock Time Updatead Successfully' : 'Clock Time Created Successfully';
      toast.success(checkClockIn);
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const deleteClockEventsEntity = createAsyncThunk('time/deleteClockEventsEntity', async ({ onSuccess, data }) => {
  try {
    const response = await apiURL.put(`/timeclock/clockEvents/delete`, data);
    if (response?.status === 200) {
      onSuccess();
      toast.success('Clock Event Deleted Successfully');
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getTimeClockDay = createAsyncThunk('time/getTimeClockDay', async (props) => {
  try {
    const response = await apiURL.post(`/timeclock/getTimeclocks`, props);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});
export const approveTime = createAsyncThunk('time/approveTime', async (props) => {
  try {
    const response = await apiURL.post(`/timeclock/approveTimeClock`, props);
    toast.success('Approval Success', { autoClose: successToastTime });
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createTimeClockByAdmin = createAsyncThunk('time/createTimeClockByAdmin', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/timeclock/createTimeClock`, props);
    toast.success('Created Successfully', { autoClose: successToastTime });
    thunkAPI.dispatch(getTimeClockDay({ departmentId: props.departmentId, date: convertDatetoString(new Date()) }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateTimeClockByAdmin = createAsyncThunk('time/completeTimeClock', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put(`/timeclock/completeTimeClock`, props.finalData);
    toast.success('Time Clock Updated Successfully', { autoClose: successToastTime });
    if (response.status === 200) {
      props.isSuccess(true);
    }
    thunkAPI.dispatch(
      getTimeClockDay({
        departmentId: props.finalData?.departmentId,
        date: convertDatetoString(new Date(props.finalData?.date)),
      })
    );
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateTimeClockBreakByAdmin = createAsyncThunk('time/completeTimeClockBreakByAdmin', async (props) => {
  try {
    const response = await apiURL.put(`/timeclock/completeBreak`, props);
    toast.success('Breaks Updated Successfully', { autoClose: successToastTime });
    // thunkAPI.dispatch(getTimeClockDay({ departmentId: props.departmentId, date: convertDatetoString(new Date()) }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteTimeClockBreakByAdmin = createAsyncThunk('time/deleteTimeClockBreakByAdmin', async (props) => {
  const data = {
    id: props.id,
    timeClockId: props.timeClockId,
  };
  try {
    const response = await apiURL.post(`/timeclock/deleteBreak`, data);
    toast.success('Break Deleted...', { autoClose: successToastTime });
    return response.data;
  } catch (error) {
    // console.error(error);
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteTimeClockByAdmin = createAsyncThunk('time/deleteTimeClockByAdmin', async (props, thunkAPI) => {
  // const data = {
  //   departmentId: props.departmentId,
  //   id: props.id,
  // };
  try {
    const response = await apiURL.post(`/timeclock/deleteTimeClock`, props.finalData);
    if (response.status === 200) {
      toast.success('TimeClock Deleted...', { autoClose: successToastTime });
      props.isSuccess(true);
    }
    thunkAPI.dispatch(
      getTimeClockDay({
        departmentId: props.finalData.departmentId,
        date: convertDatetoString(new Date(props?.finalData.date)),
      })
    );
    return response.data;
  } catch (error) {
    console.error(error);
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getApprovedReports = createAsyncThunk('time/getApprovedReports', async (props) => {
  const data = {
    departmentId: props.departmentId,
    roasterDate: props.selectedDate,
  };
  try {
    const response = await apiURL.post(`/report/getApprovedTimeReports`, data);
    return response.data;
  } catch (error) {
    // console.error(error);
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

const timeSlice = createSlice({
  name: 'time',
  initialState: {
    timeUser: null,
    timeUserFilter: {
      filterData: 'ALL',
      data: [],
    },
    approvedReports: null,
    timeApp: {},
    loading: false,
    internalLoading: false,
    postLoading: false,
    choosenDate: null,

    // new
    allStaffClockEvents: [],
    timeClockStatus: 'PENDING',
  },

  reducers: {
    changeTimeClockStatus(state, action) {
      state.timeClockStatus = action.payload;
    },
    changeTimeUserFilterStatus(state, action) {
      state.timeUserFilter.filterData = action.payload;
    },
    updateTimeUserFilterData(state, action) {
      state.timeUserFilter.data = action.payload;
    },
    setChoosenTimeDate(state, action) {
      state.choosenDate = action.payload;
    },
    removeDateClockEvent(state, action) {
      const userId = action.payload.userId;
      const dates = action.payload.selectedDate;
      const newAllStaffClockEvents = state.allStaffClockEvents.map((staffClockEvent) => {
        if (staffClockEvent.userId !== userId) return staffClockEvent;

        const updatedTimeClocks = { ...staffClockEvent.timeClocks };
        delete updatedTimeClocks[dates];

        return { ...staffClockEvent, timeClocks: updatedTimeClocks };
      });

      return {
        ...state,
        allStaffClockEvents: newAllStaffClockEvents,
      };
      // delete data?.action.payload.selectedDate;
    },
  },

  extraReducers: {
    [getAllUserClockEvents.pending]: (state) => {
      state.loading = true;
      state.allStaffClockEvents = [];
    },
    [getAllUserClockEvents.fulfilled]: (state, action) => {
      state.loading = false;
      state.allStaffClockEvents = action.payload;
    },
    [getAllUserClockEvents.rejected]: (state) => {
      state.loading = false;
      state.allStaffClockEvents = [];
    },

    [getAllUserClockEventsInternally.pending]: (state) => {
      state.internalLoading = true;
    },
    [getAllUserClockEventsInternally.fulfilled]: (state, action) => {
      state.internalLoading = false;
      state.allStaffClockEvents = action.payload;
    },
    [getAllUserClockEventsInternally.rejected]: (state) => {
      state.internalLoading = false;
    },

    [getApprovedReports.pending]: (state) => {
      state.loading = true;
    },
    [getApprovedReports.fulfilled]: (state, action) => {
      state.loading = false;
      state.approvedReports = action.payload;
    },
    [getApprovedReports.rejected]: (state) => {
      state.loading = false;
    },

    [getTimeClockDay.pending]: (state) => {
      state.loading = true;
    },
    [getTimeClockDay.fulfilled]: (state, action) => {
      state.loading = false;
      state.timeUser = action.payload;
    },
    [getTimeClockDay.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [approveTime.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [approveTime.fulfilled]: (state, action) => {
      state.loading = false;
      state.timeApp = action.payload;
      state.success = true;
    },
    [approveTime.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [updateTimeClockByAdmin.pending]: (state) => {
      state.postLoading = true;
    },
    [updateTimeClockByAdmin.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateTimeClockByAdmin.rejected]: (state) => {
      state.postLoading = false;
    },
    [updateTimeClockBreakByAdmin.pending]: (state) => {
      state.postLoading = true;
    },
    [updateTimeClockBreakByAdmin.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateTimeClockBreakByAdmin.rejected]: (state) => {
      state.postLoading = false;
    },

    [deleteTimeClockByAdmin.pending]: (state) => {
      state.postLoading = true;
    },
    [deleteTimeClockByAdmin.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [deleteTimeClockByAdmin.rejected]: (state) => {
      state.postLoading = false;
    },
  },
});

export const {
  changeTimeUserFilterStatus,
  updateTimeUserFilterData,
  setChoosenTimeDate,
  removeDateClockEvent,
  changeTimeClockStatus,
} = timeSlice.actions;

export default timeSlice.reducer;
