import Page from 'components/Page';
import React, { useEffect } from 'react';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { useDispatch } from 'react-redux';
import { getDealOffer } from 'redux/slices/dealSlice';
import { getFoodCategory } from 'redux/slices/menuEngineeringSlice';
import DealOfferHeader from './DealOfferHeader';
import DealOfferBody from './DealOfferBody';

function DealOfferIndex() {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getDealOffer({ departmentId }));
    dispatch(getFoodCategory({ departmentId }));
  }, [departmentId, dispatch]);
  return (
    <Page title="Deals & Offers">
      <DealOfferHeader />
      <DealOfferBody />
    </Page>
  );
}

export default DealOfferIndex;
