import { Box, Button, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Scrollbar from 'components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Page from 'components/Page';
import { deleteFoodSizes, getFoodSizes } from 'redux/slices/FoodCategoriesAndSizeSlice';
import FoodSizeEdit from './FoodSizeEdit';

const TABLE_HEAD = [
  { id: 1, label: 'S.N', align: 'left' },
  { id: 2, label: 'Name', align: 'left' },
  { id: 3, label: 'Actions', align: 'left' },
];

function FoodSizeIndex() {
  const { sizeList, loading } = useSelector((state) => state.foodCategoriesAndSize);
  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, id: null });
  const [openUpdateModal, setOpenUpdateModal] = useState({ status: false, data: null });

  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const handlePaginationChange = (event, value) => {
    dispatch(getFoodSizes({ departmentId, pageNo: value }));
  };

  const handleDeleteDialog = () => {
    setOpenDeleteModal({ id: null, status: false });
  };

  const handleUpdateDialog = () => {
    setOpenUpdateModal({ data: null, status: false });
  };

  const onDeleteStockAccept = () => {
    dispatch(deleteFoodSizes({ departmentId, id: openDeleteModal?.id }));
    handleDeleteDialog();
  };

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getFoodSizes({ departmentId, pageNo: 1 }));
  }, [dispatch, departmentId]);
  return (
    <Page title="Size">
      <Scrollbar>
        <TableContainer>
          <Table stickyHeader>
            <TableHeadCustom headLabel={TABLE_HEAD} />

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={11}>
                    <CustomLoadingScreen height="30vh" />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {sizeList?.sizes?.length <= 0 ? (
                    <TableNoData isNotFound={sizeList?.sizes.length <= 0} />
                  ) : (
                    <>
                      {sizeList?.sizes?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{row?.name}</TableCell>
                          <TableCell>
                            <Stack gap="1rem" alignItems="center" direction="row">
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  setOpenUpdateModal({ status: true, data: row });
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                  setOpenDeleteModal({ status: true, id: row?.id });
                                }}
                              >
                                Delete
                              </Button>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: 'relative' }}>
        <Pagination
          count={sizeList?.paginationInfo?.totalPages}
          page={sizeList?.paginationInfo?.pageNo + 1}
          onChange={handlePaginationChange}
          className="pagination-list-container"
          sx={{ px: 3, py: 1.5, justifyContent: 'flex-end', borderTop: 'solid 1px rgba(145, 158, 171, 0.24)' }}
        />
      </Box>

      {openDeleteModal?.status && (
        <CustomDeleteDialog open={openDeleteModal} handleClose={handleDeleteDialog} onAccept={onDeleteStockAccept} />
      )}

      {openUpdateModal?.status && (
        <FoodSizeEdit open={openDeleteModal} handleClose={handleUpdateDialog} currentData={openUpdateModal?.data} />
      )}
    </Page>
  );
}

export default FoodSizeIndex;
