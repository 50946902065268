import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  MenuItem,
  Modal,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';

import { TableHeadCustom, TableMoreMenu, TableNoData } from 'components/table';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import Iconify from 'components/Iconify';
import { useSelector, useDispatch } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers';
import {
  deleteLeaveRequest,
  getLastRoasterPublishDate,
  getPendingLeaveRequest,
  updateLeaveRequest,
} from 'redux/slices/leaveRequestSlice';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { getDepartOfStore } from 'redux/slices/storeSlice';
import { toast } from 'react-toastify';
import useGetDepartmentId from 'hooks/useGetDepartmentId';

// TABLE HEADING
const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'phone', label: 'Phone', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'action', label: 'Action', align: 'center' },
];

// MODAL STYLE
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function LeaveRequestTable() {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  // getting pending request data from redux
  const { pendingLeaveRequest, loading: pendingRequestLoading } = useSelector((state) => state.LeaveRequest);

  // getting last roaster publish date from redux
  const { lastRoasterPublishDate } = useSelector((state) => state.LeaveRequest);

  const [openReject, setOpenReject] = useState(false);
  const [openAccept, setOpenAccept] = useState(false);

  const handleRejectClose = () => setOpenReject(false);
  const handleAcceptClose = () => setOpenAccept(false);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [requestId, setRequestId] = useState(null);

  const [openMenu, setOpenMenu] = useState(null);
  const handleOpenMenu = (e) => {
    setOpenMenu(e.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpenMenu(null);
  };

  // REJECT the REQUEST
  const handleReject = (data) => {
    setRequestId(data.id);
    setOpenReject(true);
  };
  const rejectRequestFromModal = () => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        dispatch(
          getPendingLeaveRequest({
            departmentId,
            startDate: moment(fromDate).format('YYYY-MM-DD'),
            endDate: moment(toDate).format('YYYY-MM-DD'),
            status: 'PENDING',
          })
        );
        setOpenReject(false);
      }
    };
    dispatch(deleteLeaveRequest({ id: requestId, departmentId, isSuccess: isSuccessful }));
  };

  // APPROVE the REQUEST
  const handleApprove = (data) => {
    setRequestId(data.id);
    setOpenAccept(true);
  };
  const acceptRequestFromModal = () => {
    const dataSet = { id: requestId, status: 'APPROVED', departmentId };
    dispatch(
      updateLeaveRequest({
        dataSet,
        fromDate: moment(fromDate).format('YYYY-MM-DD'),
        toDate: moment(toDate).format('YYYY-MM-DD'),
      })
    );
    setOpenAccept(false);
  };

  // DATE PICKER
  const fromDateChange = (date) => {
    setFromDate(moment(date).format('YYYY-MM-DD'));
  };
  const toDateChange = (date) => {
    setToDate(moment(date).format('YYYY-MM-DD'));
  };

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getLastRoasterPublishDate(departmentId));
  }, [dispatch, departmentId]);

  useEffect(() => {
    if (!lastRoasterPublishDate) return;
    setFromDate(moment(lastRoasterPublishDate?.date).format('YYYY MM DD'));
    setToDate(moment(lastRoasterPublishDate?.date).add(15, 'days'));
  }, [lastRoasterPublishDate]);

  useEffect(() => {
    if (!fromDate) return;
    if (!toDate) return;
    if (!departmentId) return;
    if (fromDate && toDate) {
      const diffDays = moment(toDate).diff(moment(fromDate), 'days');
      if (fromDate > toDate) {
        toast.error('From date should be less than to date');
      } else if (diffDays > 90) {
        toast.error('From date and to date should be within 90 days');
      } else {
        dispatch(
          getPendingLeaveRequest({
            departmentId,
            startDate: moment(fromDate).format('YYYY-MM-DD'),
            endDate: moment(toDate).format('YYYY-MM-DD'),
            status: 'PENDING',
          })
        );
      }
    }
  }, [fromDate, toDate, departmentId, dispatch, lastRoasterPublishDate]);

  return (
    <>
      {/* Reject Modal */}
      <Modal
        open={openReject}
        onClose={handleRejectClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to Delete the Request?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="success"
              style={{ marginRight: '5px' }}
              onClick={() => rejectRequestFromModal()}
            >
              Delete
            </Button>
            <Button variant="outlined" color="error" onClick={handleRejectClose}>
              Cancel
            </Button>
          </Typography>
        </Box>
      </Modal>

      {/* Accept Modal */}
      <Modal
        open={openAccept}
        onClose={handleAcceptClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to Approve Leave Request?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="success"
              style={{ marginRight: '5px' }}
              onClick={() => acceptRequestFromModal()}
            >
              Approve
            </Button>
            <Button variant="outlined" color="error" onClick={handleAcceptClose}>
              Cancel
            </Button>
          </Typography>
        </Box>
      </Modal>

      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
        <>
          {/* From Date */}
          <DatePicker
            disablePast
            label="From Date"
            inputFormat="dd/MMM/yyyy"
            value={fromDate}
            onChange={fromDateChange}
            renderInput={(params) => <TextField {...params} error={false} />}
          />

          {/* To Date */}
          <DatePicker
            disablePast
            label="To Date"
            inputFormat="dd/MMM/yyyy"
            value={toDate}
            onChange={toDateChange}
            renderInput={(params) => <TextField {...params} error={false} />}
          />
        </>
      </Stack>
      <Table size="small">
        <TableHeadCustom headLabel={TABLE_HEAD} />
        <TableBody>
          {pendingRequestLoading ? (
            <TableRow>
              <TableCell colSpan={5}>
                <CustomLoadingScreen height="20vh" />
              </TableCell>
            </TableRow>
          ) : (
            pendingLeaveRequest?.leaveRequestList?.map((request) => (
              <TableRow hover key={request.id}>
                <TableCell sx={{ display: 'flex' }}>
                  {request.requestor.thumbnailURL ? (
                    <Avatar
                      src={request.requestor.thumbnailURL}
                      style={{
                        height: '30px',
                        width: '30px',
                        borderRadius: '100px',
                        marginRight: '30px',
                      }}
                    />
                  ) : (
                    <Avatar alt="" src="" style={{ height: '30px', width: '30px', marginRight: '30px' }} />
                  )}
                  <Typography style={{ marginTop: '5px' }} variant="subtitle2" noWrap>
                    {request.requestor.firstName} {request.requestor.lastName}
                  </Typography>
                </TableCell>
                <TableCell>{moment(request.date).format('MMM, DD')}</TableCell>
                <TableCell>{request.requestor.phone}</TableCell>
                <TableCell>{request.status}</TableCell>
                <TableCell align="center">
                  <Button>
                    <Iconify icon={'mdi:tick-all'} width={20} height={20} onClick={() => handleApprove(request)} />
                  </Button>
                  {/* <Button>
                    <Iconify
                      icon={'eva:trash-2-outline'}
                      width={20}
                      height={20}
                      onClick={() => handleReject(request)}
                    />
                  </Button> */}
                  {/* <TableMoreMenu
                    open={openMenu}
                    onOpen={handleOpenMenu}
                    onClose={handleCloseMenu}
                    actions={
                      <>
                        <MenuItem
                          onClick={() => {
                            handleApprove(request);
                            // setOpenUpdateModal(true);
                            // setUpdateData(elm);
                            setOpenMenu(null);
                          }}
                        >
                          <Iconify icon={'mdi:tick-all'} width={20} height={20} />
                          Approve
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setRejectId(request.id);
                            handleReject();
                            setOpenMenu(null);
                          }}
                        >
                          <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
                          Delete
                        </MenuItem>
                      </>
                    }
                  /> */}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
        {pendingLeaveRequest?.leaveRequestList?.length === 0 && (
          <TableNoData isNotFound={pendingLeaveRequest?.leaveRequestList} />
        )}
      </Table>
      <Box
        sx={{ position: 'relative', marginTop: '10px' }}
        justifyContent={'end'}
        alignContent="center"
        display={'flex'}
      >
        <Pagination
          count={pendingLeaveRequest?.paginationInfo?.totalPages}
          page={pageNo}
          onChange={(e, value) => setPageNo(value)}
        />
      </Box>
    </>
  );
}
