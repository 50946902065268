import { Box } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import PropTypes from 'prop-types';
import React from 'react';
import RecipeCreateForm from 'sections/@dashboard/recipe/RecipeCreateForm';

function CreateReceipe({ open, setOpen }) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CustomDrawer
      open={open}
      setOpen={handleClose}
      title={`Create new receipe`}
      PaperProps={{ sx: { innerWidth: '50%' } }}
    >
      <Box style={{ width: '600px' }}>
        <RecipeCreateForm handleClose={handleClose} />
      </Box>
    </CustomDrawer>
  );
}

CreateReceipe.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default CreateReceipe;
