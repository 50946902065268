import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import { FormProvider } from 'components/hook-form';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { PATH_DASHBOARD } from 'routes/paths';
import { Box, Button, Tab, Tabs, Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import ViewProfileDetails from './ViewProfileDetails';
import ViewPaymentDetails from './ViewPaymentDetails';
import ViewPaperworkDetails from './ViewPaperworkDetails';

function UserDetailsView({ open, setOpen, data }) {
  const navigate = useNavigate();
  const { singleUser, singleUserLoading } = useSelector((state) => state.user);
  console.log('🚀 => UserDetailsView => singleUser=>', singleUser);

  const [currentTab, setCurrentTab] = useState(0);
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const defaultValues = useMemo(
    () => ({
      firstName: singleUser?.firstName || '',
      middleName: singleUser?.middleName || '',
      lastName: singleUser?.lastName || '',

      email: singleUser?.email || '',
      phone: singleUser?.phone || '',
      dob: singleUser?.dob || '',
      gender: singleUser?.gender || '',

      isOwner: singleUser?.isOwner || false,
      bsb: singleUser?.bsb || '',
      accountNo: singleUser?.accountNo || '',
      isVerified: singleUser?.isVerified || false,
      suburb: singleUser?.suburb || '',
      address: singleUser?.address || '',
      country: singleUser?.country || '',
      state: singleUser?.state || '',

      zipcode: singleUser?.zipcode || '',
      emergencyContactName: singleUser?.emergencyContactName || '',
      emergencyContactNo: singleUser?.emergencyContactNo || '',
      superName: singleUser?.superName || '',
      superNo: singleUser?.superNo || '',
      tfnNo: singleUser?.tfnNo || '',

      weekDayPay: singleUser?.weekDayPay || 0,
      saturdayPay: singleUser?.saturdayPay || 0,
      sundayPay: singleUser?.sundayPay || 0,
      holidayPay: singleUser?.holidayPay || 0,
      overtimePay: singleUser?.overtimePay || 0,
      weeklyHrs: singleUser?.weeklyHrs || 0,
    }),
    [singleUser]
  );

  const methods = useForm({
    //  resolver: yupResolver(),
    mode: 'onChange',
    defaultValues,
  });
  const { handleSubmit, reset } = methods;
  const userSubmitHandler = (data) => {
    navigate(PATH_DASHBOARD.user.edit(data?.id));
  };

  useEffect(() => {
    if (!Object.keys(singleUser)) return;
    reset({ ...singleUser, departmentId: singleUser?.departments?.[0], role: [] });
  }, [singleUser, reset]);

  console.log(methods.watch());

  return (
    <CustomDrawer
      open={open}
      setOpen={setOpen}
      title={`Details of ${data?.firstName} ${data?.middleName ?? ''} ${data?.lastName}`}
      PaperProps={{ sx: { minWidth: '50%' } }}
    >
      <Box style={{ maxWidth: '900px' }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(userSubmitHandler)}>
          <Stack alignItems={'flex-end'}>
            <Button type="submit" variant="standard">
              Edit
            </Button>
          </Stack>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} onChange={handleChange} aria-label="Menu Edit ">
              <Tab label="Profile" id="0" />
              <Tab label="Payment" id="1" />
              <Tab label="PaperWork" id="2" />
            </Tabs>
          </Box>

          {singleUserLoading && <CustomLoadingScreen height={'30vh'} width="900px" />}

          {!singleUserLoading && currentTab === 0 && <ViewProfileDetails />}

          {!singleUserLoading && currentTab === 1 && <ViewPaymentDetails />}

          {!singleUserLoading && currentTab === 2 && <ViewPaperworkDetails />}
        </FormProvider>
      </Box>
    </CustomDrawer>
  );
}
UserDetailsView.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.object,
};
export default UserDetailsView;
