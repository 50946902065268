import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import { useSelector } from 'react-redux';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// components

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default function GuestGuard({ children }) {
  const { isAuth } = useSelector((state) => state.auth);

  if (isAuth) {
    return <Navigate to={PATH_DASHBOARD.root} />;
  }

  // if (!isAuth) {
  //   return <Navigate to={PATH_AUTH.login} />;
  // }

  // if (!isInitialized) {
  //   return <LoadingScreen />;
  // }

  return <>{children}</>;
}
