import { Container } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import useSettings from 'hooks/useSettings';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getSalesList } from 'redux/slices/salesSlice';
import { getOnlineSalesPlatform, getStoreCashDrawer, storeDetails } from 'redux/slices/storeSlice';
import { getFeatureBasedUser } from 'redux/slices/userSlice';
import { PATH_DASHBOARD } from 'routes/paths';
import SalesCreateUpdateForm from 'sections/@dashboard/sales/addEdit/SalesCreateUpdateForm';

export default function EditSales() {
  const departmentId = useGetDepartmentId();
  const { break: storeDetail } = useSelector((state) => state.store);
  const { dailySales } = useSelector((state) => state.sales);
  const { themeStretch } = useSettings();
  const dispatch = useDispatch();
  const params = useParams();

  // prevent reload
  useEffect(() => {
    const unloadCallback = (event) => {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => {
      window.removeEventListener('beforeunload', unloadCallback);
    };
  }, []);

  useEffect(() => {
    if (!departmentId) return;
    if (!storeDetail) return;
    dispatch(getStoreCashDrawer({ departmentId }));
    dispatch(getFeatureBasedUser({ featureId: 2, departmentId }));
    dispatch(getOnlineSalesPlatform({ departmentId }));
    dispatch(getSalesList({ date: params?.id, departmentId, storeId: storeDetail?.id }));
  }, [dispatch, departmentId, storeDetail, params?.id]);

  return (
    <Page title="Sales: Update daily Sale">
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading="Update Sales"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Sales', href: PATH_DASHBOARD.sales.list },
            { name: 'Edit Sales' },
          ]}
        />

        <SalesCreateUpdateForm isEdit existingData={dailySales} />
      </Container>
    </Page>
  );
}
