import Page from 'components/Page';
import React, { useEffect } from 'react';
import { changeFoodCurrentTab } from 'redux/slices/FoodCategoriesAndSizeSlice';
import { useDispatch } from 'react-redux';
import FoodCategoryHeaders from './FoodCategoryHeaders';
import FoodCategoryBody from './FoodCategoryBody';

function FoodCategoriesAndSizeIndex() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeFoodCurrentTab(0));
  }, [dispatch]);
  return (
    <Page title="Categories">
      <FoodCategoryHeaders />
      <FoodCategoryBody />
    </Page>
  );
}

export default FoodCategoriesAndSizeIndex;
