import Page from 'components/Page';
import React from 'react';
import StockIngredientsHeaders from './StockIngredientsHeaders';
import StockIngredientsCardBody from './StockIngredientsCardBody';

function StockIngredientsIndex() {
  return (
    <Page title="Ingredients">
      <StockIngredientsHeaders />
      <StockIngredientsCardBody />
    </Page>
  );
}

export default StockIngredientsIndex;
