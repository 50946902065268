import { Button, Card, FormControlLabel, Stack, Typography } from '@mui/material';
import React, { useRef, useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { toSvg } from 'html-to-image';
import { RHFCheckbox } from 'components/hook-form';
import StaffCreateActiveStepperSignaturePad from './StaffCreateActiveStepperSignaturePad';

function StaffCreateActiveStepperDeclartion() {
  const { control, setValue, register } = useFormContext();
  const [openModel, setOpenModal] = useState(false);
  const newRef = useRef(null);

  const watchAcceptTermsAndConditions = useWatch({
    control,
    name: 'declarationForm.fullName',
  });

  const watchCertifyAccuracy = useWatch({
    control,
    name: 'declarationForm.certifyAccuracy',
  });

  const watchCertifyBySigning = useWatch({
    control,
    name: 'declarationForm.certifyBySigning',
  });

  const watchSignatures = useWatch({
    control,
    name: 'declarationForm.signature',
  });

  const currentDate = new Date().toDateString();

  const filter = (node) => {
    const exclusionClasses = ['createSignatureButtonDeclarationForm'];
    return !exclusionClasses.some((classname) => node.classList?.contains(classname));
  };
  useEffect(() => {
    // if (!watchAcceptTermsAndConditions || !watchCertifyBySigning || !watchCertifyAccuracy || !watchSignatures) return;
    const newFunction = async () => {
      const data = await toSvg(newRef?.current, { filter });
      const decodeData = decodeURIComponent(data);
      const subStringData = decodeData.substring(decodeData.indexOf(',') + 1);

      const svgBlob = new Blob([subStringData], { type: 'image/svg+xml' });
      setValue('sign', svgBlob);
    };
    newFunction();
  }, [watchAcceptTermsAndConditions, setValue, watchSignatures, watchCertifyAccuracy, watchCertifyBySigning]);

  return (
    <>
      <Stack ref={newRef}>
        <Card sx={{ py: 3, px: 3 }}>
          <Stack className="UserAcceptanceDeclarationForm" gap={2}>
            <Stack direction="row" className="termsAndConditionsCSSOnlyForm" px={1}>
              <Typography component="p">
                I,
                <input
                  type="text"
                  {...register('declarationForm.fullName', { required: true })}
                  style={{ fontSize: '9pt', marginInline: '0.4rem' }}
                />
                , hereby declare that I have read and understood the terms and conditions and by signing below, I agree
                to be bound by the terms and conditions outlined in the agreement or document, and I understand that
                failure to comply with these terms may result in legal action against me.
              </Typography>
            </Stack>
            <Stack px={2}>
              <FormControlLabel
                label={`I also certify that all information provided by me in relation to this or document is true, accurate, and complete to the best of my knowledge.
              `}
                control={<RHFCheckbox name="declarationForm.certifyAccuracy" />}
              />
            </Stack>
            <Stack direction="row" alignItems="center">
              <Stack direction={'row'} gap="0.2rem" alignItems={'center'} my={2}>
                <Typography component="p" fontSize={'0.875rem'}>
                  Signed:
                </Typography>
                {watchSignatures && (
                  <>
                    <img src={watchSignatures} alt="signature" style={{ width: '10rem' }} />
                  </>
                )}
              </Stack>
              <Button
                className="createSignatureButtonDeclarationForm"
                onClick={() => setOpenModal(true)}
                size="small"
                variant="outlined"
                sx={{ fontSize: '0.7rem' }}
              >
                Create Signature
              </Button>
            </Stack>

            <Stack direction={'row'} gap="0.2rem" alignItems={'center'} mt="0.1rem">
              <Typography component="p" fontSize={'0.875rem'} width="100%">
                Date : {currentDate}
              </Typography>
            </Stack>

            <Stack px={2}>
              <FormControlLabel
                label={`By signing this declaration, I acknowledge that I have voluntarily agreed to the terms and conditions of the agreement or document and that I am legally bound by them.
              `}
                control={<RHFCheckbox name="declarationForm.certifyBySigning" disabled={!watchSignatures} />}
              />
            </Stack>
          </Stack>
        </Card>
      </Stack>
      {openModel && (
        <StaffCreateActiveStepperSignaturePad
          open={openModel}
          setOpen={setOpenModal}
          formValue={(URL) => setValue('declarationForm.signature', URL)}
        />
      )}
    </>
  );
}

export default StaffCreateActiveStepperDeclartion;
