import Page from 'components/Page';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getAllUserNotes } from 'redux/slices/userNoteSlice';
import UserNoteBody from 'sections/@dashboard/roaster/UserNote/UserNoteBody';
import UserNoteHeader from 'sections/@dashboard/roaster/UserNote/UserNoteHeader';

function UserNoteIndex() {
  const [searchText, setSearchText] = useState('');

  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getAllUserNotes({ departmentId, statusList: ['ACTIVE'], searchText }));
  }, [departmentId, dispatch, searchText]);
  return (
    <Page title="User Notes">
      <UserNoteHeader />
      <UserNoteBody />
    </Page>
  );
}

export default UserNoteIndex;
