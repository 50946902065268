import { Button, Stack, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import Iconify from 'components/Iconify';
import moment from 'moment';
import React, { useState } from 'react';
import TaskStartModal from './TaskStartModal';

function PendingTasklistCategory({ category }) {
  const [startTask, setStartTask] = useState({ status: false, data: null });
  const [markComplete, setMarkComplete] = useState({ status: false, data: null });
  const [deleteTask, setDeleteTask] = useState({ status: false, data: null });

  const checkFuture = (date) => {
    if (!date) return false;
    const currentDate = moment();
    const taskDate = moment(date);
    return taskDate.isAfter(currentDate);
  };
  const checkPast = (date) => {
    if (!date) return false;
    const currentDate = moment(new Date()).startOf('day');

    const taskDate = moment(date);

    return taskDate.isBefore(currentDate);
  };
  return (
    <>
      {category?.tasks?.map((item, index) => (
        <TableRow key={index} hover>
          <TableCell>{index + 1}.</TableCell>
          <TableCell>
            {item.title?.length > 15 ? (
              <Tooltip title={item?.title} placement="right">
                <Typography noWrap variant="body2">
                  {`${item.title.slice(0, 15)}..`}{' '}
                  {checkPast(item?.taskDate) && <Iconify icon="typcn:warning-outline" color="red" />}
                </Typography>
              </Tooltip>
            ) : (
              <>
                {item.title} {checkPast(item?.taskDate) && <Iconify icon="typcn:warning-outline" color="red" />}
              </>
            )}
          </TableCell>
          <TableCell align="center" style={{ minWidth: '160px' }}>
            {item?.taskDate ? moment(item?.taskDate).format('ddd, DD MMM, hh:mm A') : '---'}
          </TableCell>
          <TableCell align="center" style={{ minWidth: '160px' }}>
            {item?.startedDateTime ? moment(item?.startedDateTime).format('ddd, DD MMM, hh:mm A') : '---'}
          </TableCell>
          <TableCell align="right">
            <Stack style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Button
                variant="contained"
                size="small"
                color="secondary"
                style={{ marginRight: '20px' }}
                onClick={() => setStartTask({ status: true, data: item })}
                disabled={item?.startedDateTime || checkFuture(item?.taskDate)}
                startIcon={<Iconify icon="material-symbols:play-circle-outline" width={20} height={20} />}
              >
                Start
              </Button>
              <Button
                variant="contained"
                size="small"
                color={'primary'}
                style={{ marginRight: '20px' }}
                onClick={() => setMarkComplete({ status: true, data: item })}
                disabled={checkFuture(item?.taskDate)}
                startIcon={<Iconify icon="material-symbols:check-small-rounded" />}
              >
                Mark Completed
              </Button>
              <Button
                variant="text"
                size="small"
                color={'error'}
                style={{ marginRight: '20px' }}
                onClick={() => setDeleteTask({ status: true, data: item })}
                startIcon={<Iconify icon="eva:trash-2-outline" width={20} height={20} />}
              >
                Delete
              </Button>
            </Stack>
          </TableCell>
        </TableRow>
      ))}
      {startTask?.status && (
        <TaskStartModal start open={startTask?.status} setOpen={setStartTask} item={startTask?.data} />
      )}
      {markComplete?.status && (
        <TaskStartModal markComplete open={markComplete?.status} setOpen={setMarkComplete} item={markComplete?.data} />
      )}
      {deleteTask?.status && (
        <TaskStartModal deleteTask open={deleteTask?.status} setOpen={setDeleteTask} item={deleteTask?.data} />
      )}
    </>
  );
}

export default PendingTasklistCategory;
