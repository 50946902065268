import {
  Box,
  Button,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDatedTaskList, getTaskLists } from 'redux/slices/storeDocumentSlice';
import Scrollbar from 'components/Scrollbar';
import moment from 'moment';
import { TableNoData } from 'components/table';
import PendingTasklistCategory from './PendingTasklistCategory';

function PendingTasklist({ choosenDate }) {
  const [pageNo, setPageNo] = React.useState(1);
  const selectedDate = moment(choosenDate).format('YYYY-MM-DD');

  const today = moment(new Date()).format('YYYY-MM-DD');

  const isNotToday = selectedDate < today || selectedDate > today;

  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const { taskLists, loading } = useSelector((state) => state.storeDocument);

  useEffect(() => {
    if (!departmentId) return;
    if (isNotToday) {
      dispatch(getDatedTaskList({ departmentId, status: 'PENDING', taskDate: choosenDate, pageNo }));
    } else {
      dispatch(getTaskLists({ departmentId, status: 'PENDING', pageNo }));
    }
  }, [dispatch, departmentId, isNotToday, choosenDate, pageNo]);

  return (
    <>
      <Stack spacing={2} style={{ marginTop: '20px' }}>
        {loading ? (
          <CustomLoadingScreen height="20vh" />
        ) : (
          <Scrollbar>
            {taskLists?.checkListCategories?.map((category, index) => (
              <>
                <Button
                  disableRipple
                  style={{
                    padding: '5px',
                    color: 'white',
                    backgroundColor: `${category?.colorCode}`,
                    borderRadius: '5px',
                    marginLeft: '8px',
                    marginBottom: '5px',
                  }}
                >
                  {category?.name}
                </Button>
                <TableContainer key={index}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>S.N.</TableCell>
                        <TableCell style={{ width: '150px' }}>Name</TableCell>
                        <TableCell align="center">Task Date</TableCell>
                        <TableCell align="center">Started On</TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <PendingTasklistCategory category={category} />
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ))}
          </Scrollbar>
        )}
        {taskLists?.checkListCategories?.length === 0 ? (
          <Stack display={'flex'} alignItems={'center'}>
            <TableNoData isNotFound={taskLists?.checkListCategories?.length === 0} />
          </Stack>
        ) : (
          <Box
            sx={{ position: 'relative', marginTop: '30px' }}
            justifyContent={'end'}
            alignContent="center"
            display={'flex'}
          >
            <Pagination
              count={taskLists?.paginationInfo?.totalPages}
              page={pageNo}
              onChange={(e, value) => setPageNo(value)}
            />
          </Box>
        )}
      </Stack>
    </>
  );
}

export default PendingTasklist;
