import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFoodMenu } from 'redux/slices/menuEngineeringSlice';
import { useForm } from 'react-hook-form';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { FormProvider } from 'components/hook-form';
import { updateMostLikedMenus } from 'redux/slices/mostLikedMenusSlice';
import ManageMostLikedMenusBody from './ManageMostLikedMenusBody';

function ManageMostLikedMenus({ open, setOpen }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const { foodMenus, foodMenuLoading } = useSelector((state) => state.menuEngineering);
  const { mostLikedMenus } = useSelector((state) => state.mostLikedMenus);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { reset, control, handleSubmit } = methods;

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getFoodMenu({ departmentId }));
  }, [dispatch, departmentId]);

  useEffect(() => {
    if (!foodMenus?.length) return;
    if (!mostLikedMenus?.length) {
      reset({ data: foodMenus });
      return;
    }
    reset({ data: foodMenus, selectedMenus: mostLikedMenus });
  }, [foodMenus, mostLikedMenus, reset]);

  const MostLikedMenusSubmit = (data) => {
    const finalData = {
      // menus: data?.selectedMenus?.map((item) => ({ ...item })),
      departmentId,
      addMenusIds: data?.addMenus?.length ? data?.addMenus?.map((item) => item?.id) : [],
      removedMenuIds: data?.removedMenu?.length ? data?.removedMenu?.map((item) => item?.id) : [],
    };
    dispatch(updateMostLikedMenus({ finalData, departmentId, setOpen }));
  };

  return (
    <CustomDrawer
      open={open}
      setOpen={setOpen}
      title={'Most Liked Menus'}
      PaperProps={{ sx: { width: '50%' } }}
      handleClose={() => setOpen(false)}
      headerIcon="carbon:gui-management"
      iconColor="#1976d2"
      iconWidth={30}
      iconHeight={30}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(MostLikedMenusSubmit)}>
        {foodMenuLoading ? <CustomLoadingScreen height="20vh" /> : <ManageMostLikedMenusBody />}
      </FormProvider>
    </CustomDrawer>
  );
}

export default ManageMostLikedMenus;
