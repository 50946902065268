import moment from 'moment';

// Findind all date in between two dates
export function findAllDaysFromWeeks(fromDate, toDate) {
  const dates = [];

  const currDate = moment(fromDate).startOf('day');
  const lastDate = moment(toDate).startOf('day');
  dates.push(currDate.toDate());
  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone().toDate());
  }
  dates.push(lastDate.toDate());
  return dates;
}

export function convertDateintoTimeString(date) {
  return moment(date).format('HH:mm:ss');
}

export function convertTimeStringIntoDate(timestring) {
  return moment(timestring, 'hh:mm:ss').toDate();
}

// always returns an Date object // use .hours() .minutes() to get time
export function getTwoTimesDifference(endTime, startTime) {
  const formatInTime = moment(startTime);
  const formatOutTime = moment(endTime);
  const diffTime = moment.duration(formatOutTime.diff(formatInTime));

  if (diffTime >= 0) {
    return diffTime;
  }

  return moment.duration(formatOutTime.add(1, 'days').diff(formatInTime));
}

// for finding the overtime payment....

function checkOvertimePayment(userInformation) {
  const weeklyHours = moment.duration(userInformation.weeklyHrs, 'hours');
  // const overTimePay = userInformation?.overttimePay;
  const checkOvertimeMap = new Map(Object.entries(userInformation?.userShiftsInDates));
  let totalTimeInMilliSecond = 0;
  let isOverTimePay = false;

  // looping through userShifts
  checkOvertimeMap.forEach((value) => {
    if (!value?.length) return;
    if (totalTimeInMilliSecond > weeklyHours.asMilliseconds()) {
      isOverTimePay = true;
      return;
    }
    // looping through user shife / particular date roaster
    const findTotalDate = value?.reduce(
      (acc, current) => getTwoTimesDifference(current.endDateTime, current.startDateTime).asMilliseconds() + acc,
      0
    );
    totalTimeInMilliSecond += findTotalDate;
  });

  return { isOverTimePay };
}

// for finding total price of individual card/ roaster
export function findTotalPriceofRoaster(data, holidayArray, userInformation) {
  let totalPrice;
  const dateIndex = new Date(data?.currentDate).getDay();
  const diffTime = getTwoTimesDifference(data?.endDateTime, data?.startDateTime);
  const timeWithoutBreak = data?.breakMins
    ? diffTime.asMilliseconds() - data?.breakMins * 60 * 1000
    : diffTime.asMilliseconds();
  // const timeWithoutBreak = diffTime - data?.breakMins * 60 * 1000;
  // const diffTimeinNumber = (diffTime.hours() * 60 + diffTime.minutes()) / 60;
  const timeWithoutBreakinNumber = timeWithoutBreak / 1000 / 60 / 60;

  const { isOverTimePay } = checkOvertimePayment(userInformation);

  if (isOverTimePay) {
    totalPrice = timeWithoutBreakinNumber * userInformation?.overtimePay;
    return totalPrice.toFixed(2);
  }

  // if (holidayArray.includes(data?.currentDate)) {
  //   totalPrice = timeWithoutBreakinNumber * userInformation?.holidayPay;
  //   return totalPrice.toFixed(2);
  // }
  switch (dateIndex) {
    case 0:
      totalPrice = (timeWithoutBreakinNumber * userInformation?.sundayPay).toFixed(2);
      break;
    case 6:
      totalPrice = (timeWithoutBreakinNumber * userInformation?.saturdayPay).toFixed(2);
      break;
    default:
      totalPrice = (timeWithoutBreakinNumber * userInformation?.weekDayPay).toFixed(2);
  }

  return totalPrice;
}

// finding the total details on the basis of weeks

export function findTotalofEachWeeks(currentWeek, users) {
  const allDatesArray = [];
  const convertDate = moment(currentWeek, 'ddd, DD MMMM').format('YYYY-MM-DD');

  // filtering dates on the basis of currentweek / convertDate
  users.forEach((element) => {
    Object.keys(element.userShiftsInDates).forEach((obj) => {
      if (obj === convertDate) {
        allDatesArray.push(element.userShiftsInDates[obj]);
      }
    });
  });

  const getTotalPriceofAllRoaster = allDatesArray
    .flat()
    .reduce((acc, current) => acc + Number(current.totalPriceofRoaster), 0);

  const getTotalDateofAllRoaster = moment.duration(
    allDatesArray.flat().reduce((acc, current) => {
      // return acc + Number(current?.timeDifference?.asMilliseconds() - current?.breakMins * 60 * 1000);
      const timeWithoutBreak = current?.breakMins
        ? current?.timeDifference?.asMilliseconds() - current?.breakMins * 60 * 1000
        : current?.timeDifference?.asMilliseconds();
      return acc + Number(timeWithoutBreak);
    }, 0)
  );

  return {
    totalPrice: getTotalPriceofAllRoaster,
    // totalDate: `${getTotalDateofAllRoaster.asHours()}.${getTotalDateofAllRoaster.minutes()}`,
    totalDate: getTotalDateofAllRoaster,
  };
}

export function findSelectedTimeRoaster(fields) {
  if (!fields.length) return;
  const findDate = [];
  fields?.forEach((obj) => {
    const startTime = moment(obj?.startDateTime, 'YYYY-MM-DDTHH:mm:ss');
    let endTime = moment(obj?.endDateTime, 'YYYY-MM-DDTHH:mm:ss');
    const diffTime = moment.duration(endTime.diff(startTime));

    if (!diffTime.asMilliseconds() >= 0) {
      endTime = endTime.add(1, 'days');
    }
    while (startTime.hours() !== endTime.hours()) {
      findDate.push(startTime.hours());
      startTime.add(1, 'hours');
    }
    findDate.push(endTime.hours());
  });
  // eslint-disable-next-line consistent-return
  return findDate;
}

export function checkRoasterEmptyOrNot(users) {
  if (!Array.isArray(users) && users?.length <= 0) return;
  const getAllUserShifts = [];
  users?.forEach((user) => {
    getAllUserShifts.push(Object.values(user?.userShiftsInDates).flat());
  });

  if (getAllUserShifts.flat().length >= 1) {
    // eslint-disable-next-line consistent-return
    return true;
  }
  // eslint-disable-next-line consistent-return
  return false;
}

export function mergeCurrentRoasterwithPrevious(fields, getUser) {
  const newUserInfo = [];
  fields?.forEach((user) => {
    const checkUsers = getUser?.find((el) => el.id === user.id);
    if (checkUsers) {
      const getPreviousDateShifts = checkUsers?.userShiftsInDates;
      const getLeaveDates = user?.leaveApprovedDates;

      const getCurrentDateShifts = user?.userShiftsInDates;
      const manageUsers = Object.keys(getCurrentDateShifts).reduce((acc, current, index) => {
        if (getLeaveDates?.includes(current)) return { ...acc, [current]: [] };

        const shiftsValues = Object.values(getPreviousDateShifts)[index].map((obj) => ({
          userId: obj?.userId,
          breakMins: obj?.breakMins,
          endDateTime: obj?.endDateTime,
          startDateTime: obj?.startDateTime,
          forecastPay: obj?.forecastPay,
          note: obj?.note,
          date: current,
          roleId: obj?.roleId,
        }));

        return { ...acc, [current]: shiftsValues };
      }, {});

      const mani = { ...user, userShiftsInDates: manageUsers };
      newUserInfo.push(mani);
    } else {
      newUserInfo.push(user);
    }
  });
  return newUserInfo;
}

export function findWeeklyTotalOfEachUser(obj) {
  if (!Object.keys(obj)?.length) return;
  const allDayArray = [];

  Object.keys(obj.userShiftsInDates).forEach((data) => {
    if (obj.userShiftsInDates[data]) {
      allDayArray.push(obj.userShiftsInDates[data]);
    }
  });
  const getTotalWeeklyCost = allDayArray.flat().reduce((acc, current) => acc + Number(current?.totalPriceofRoaster), 0);

  const getTotalHours = allDayArray.flat().reduce((acc, current) => acc + Number(current?.timeWithoutBreak), 0);

  // eslint-disable-next-line consistent-return
  return {
    getTotalHours,
    getTotalWeeklyCost,
  };
}

// findtotal of user for clockin details in quick summary
export function findTotalDataOfUser(obj) {
  const allDayArray = [];
  Object.keys(obj.clockReportsInDates).forEach((el) => {
    if (obj.clockReportsInDates[el]) {
      allDayArray.push(obj.clockReportsInDates[el]);
    }
  });

  const getTotalTime = allDayArray.flat().reduce((acc, current) => acc + Number(current?.minutes ?? 0), 0);

  const getTotalPay = allDayArray.flat().reduce((acc, current) => acc + Number(current?.pay ?? 0), 0);

  return {
    totalTime: getTotalTime,
    totalPay: getTotalPay,
  };
}

// find total of each user for difference details in quick summary

export function findTotalDifferenceDataOfUser(obj) {
  const allDayArray = [];
  Object.keys(obj.difference).forEach((el) => {
    if (obj.difference[el]) {
      allDayArray.push(obj.difference[el]);
    }
  });
  const getTotalTimeDifference = allDayArray
    .flat()
    .reduce((acc, current) => acc + Number(current?.timeDifference ?? 0), 0);

  const getTotalPayDifference = allDayArray
    .flat()
    .reduce((acc, current) => acc + Number(current?.payDifference ?? 0), 0);

  return { getTotalTimeDifference, getTotalPayDifference };
}

// find total of each user for roaster details in quick summary
export function findWeeklyTotalOfEachUserForQuickSummary(obj) {
  if (!Object.keys(obj)?.length) return;
  const allDayArray = [];

  Object.keys(obj.userShiftsInDates).forEach((data) => {
    if (obj.userShiftsInDates[data]) {
      allDayArray.push(obj.userShiftsInDates[data]);
    }
  });
  const getTotalWeeklyCost = allDayArray.flat().reduce((acc, current) => acc + Number(current?.forecastPay), 0);

  const getTotalHours = allDayArray.flat().reduce((acc, current) => {
    const timeWithoutBreak = current?.breakMins
      ? moment(current?.endDateTime).diff(moment(current?.startDateTime)) - current?.breakMins * 60 * 1000
      : moment(current?.endDateTime).diff(moment(current?.startDateTime));
    return acc + Number(timeWithoutBreak);
  }, 0);

  // eslint-disable-next-line consistent-return
  return {
    getTotalHours,
    getTotalWeeklyCost,
  };
}

export function findTotalofEachWeeksforQuickSummary(currentWeek, users) {
  const allDatesArray = [];

  // filtering dates on the basis of currentweek / convertDate
  users.forEach((element) => {
    Object.keys(element.userShiftsInDates).forEach((obj) => {
      if (obj === currentWeek) {
        allDatesArray.push(element.userShiftsInDates[obj]);
      }
    });
  });

  const getTotalPriceofAllRoaster = allDatesArray
    .flat()
    .reduce((acc, current) => acc + Number(current?.forecastPay), 0);

  const getTotalHours = allDatesArray.flat().reduce((acc, current) => {
    const timeWithoutBreak = current?.breakMins
      ? moment(current?.endDateTime).diff(moment(current?.startDateTime)) - current?.breakMins * 60 * 1000
      : moment(current?.endDateTime).diff(moment(current?.startDateTime));
    return acc + Number(timeWithoutBreak);
  }, 0);

  return {
    totalPrice: getTotalPriceofAllRoaster,
    totalDate: getTotalHours,
  };
}

export function findWeeklyTotalOfEachUsers(obj) {
  if (!Object.keys(obj)?.length) return;
  const allDayArray = [];
  Object.keys(obj).forEach((data) => {
    if (obj[data]) {
      allDayArray.push(obj[data]);
    }
  });

  const getTotalWeeklyCost = allDayArray.flat().reduce((acc, current) => acc + Number(current?.totalPriceofRoaster), 0);

  const getTotalHours = allDayArray.flat().reduce((acc, current) => acc + Number(current?.timeWithoutBreak), 0);

  // eslint-disable-next-line consistent-return
  return {
    getTotalHours,
    getTotalWeeklyCost,
  };
}

// daily total for clock in details in quick summary
export function findTotalofEachDays(today, values) {
  const allDatesArray = [];
  values.forEach((element) => {
    Object.keys(element.clockReportsInDates).forEach((obj) => {
      if (obj === today) {
        allDatesArray.push(element.clockReportsInDates[obj]);
      }
    });
  });
  // console.log(allDatesArray.flat());

  const getTotalPrice = allDatesArray.flat().reduce((acc, current) => acc + Number(current?.pay ?? 0), 0);

  const getTotalTime = allDatesArray.flat().reduce((acc, current) => acc + Number(current?.minutes ?? 0), 0);

  return { totalPrice: getTotalPrice, totalTime: getTotalTime };
}

// daily total for difference details in quick summary

export function findTotalDifferenceOfEachDays(today, values) {
  const allDatesArray = [];
  values.forEach((element) => {
    Object.keys(element.difference).forEach((obj) => {
      if (obj === today) {
        allDatesArray.push(element.difference[obj]);
      }
    });
  });
  const getTotalTimeDifference = allDatesArray
    .flat()
    .reduce((acc, current) => acc + Number(current?.timeDifference ?? 0), 0);

  const getTotalPriceDifference = allDatesArray
    .flat()
    .reduce((acc, current) => acc + Number(current?.payDifference ?? 0), 0);

  return { getTotalTimeDifference, getTotalPriceDifference };
}
