import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, Card, CardHeader, Stack, Typography } from '@mui/material';
import { BaseOptionChart } from 'components/chart';
import { fNumber } from 'utils/formatNumber';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 275;
const LEGEND_HEIGHT = 52;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(1),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    position: 'relative !important',
    // borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT - 20}px) !important`,
  },
}));

// ----------------------------------------------------------------------

FoodZonesPieChart.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  chartColors: PropTypes.arrayOf(PropTypes.string),
  chartData: PropTypes.array,
};

export default function FoodZonesPieChart({ isEmpty, title, chartColors, chartData, ...other }) {
  const theme = useTheme();
  const healthy = chartData?.find((el) => el.label === 'Healthy');
  const danger = chartData?.find((el) => el.label === 'Danger');
  const chartLabels = chartData?.map((i) => ` ${i.label.split(' ')[0]}`);

  const chartSeries = chartData?.map((i) => i.value);

  const chartOptions = merge(BaseOptionChart(), {
    colors: chartColors,
    labels: chartLabels,
    stroke: { colors: [theme.palette.background.paper], width: 1 },
    legend: { floating: true, horizontalAlign: 'center', fontSize: '13px', position: 'bottom' },
    dataLabels: { enabled: true, dropShadow: { enabled: true } },
    tooltip: {
      fillSeriesColor: true,
      enabled: false,
      y: {
        formatter: (seriesName) => fNumber(seriesName),
        title: {},
      },
    },
    plotOptions: {
      pie: { donut: { labels: { show: false } } },
    },
  });

  return (
    <Card {...other} sx={{ padding: '1rem' }}>
      <Box
        style={{ textAlign: 'center', fontSize: '1rem', marginTop: '.3rem', fontWeight: 'bold', marginBottom: '-8px' }}
      >
        {title}
      </Box>

      <ChartWrapperStyle dir="ltr">
        <ReactApexChart type="pie" series={chartSeries} options={chartOptions} height={250} />
      </ChartWrapperStyle>
      <Stack alignItems="center">
        {!isEmpty && (
          <Stack direction="row" alignItems="center" spacing={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
              {typeof healthy?.value === 'number' ? (
                <Typography component="p" fontSize="0.8rem">
                  Healthy: 0% - {healthy?.value}%
                </Typography>
              ) : (
                <Typography component="p" fontSize="0.8rem">
                  Healthy: value not set
                </Typography>
              )}
              {typeof danger?.value === 'number' ? (
                <Typography component="p" fontSize="0.8rem">
                  Danger: {100 - danger?.value}% - 100%
                </Typography>
              ) : (
                <Typography component="p" fontSize="0.8rem">
                  Danger: value not set
                </Typography>
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Card>
  );
}
