import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

function PlatformSummary() {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: 'platformSales',
    keyName: 'fieldID',
  });
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ color: 'text.disabled', mb: 3 }}>
        Platform Sales Details:
      </Typography>
      <Stack spacing={2}>
        <Grid container spacing={2}>
          {fields?.length > 0 && (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ minWidth: '80px' }}>Name</TableCell>
                    {fields?.map((item, index) => (
                      <TableCell align="center" key={index} style={{ minWidth: '80px' }}>
                        {item?.platformName}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ minWidth: '80px' }}>Total Amount</TableCell>
                    {fields?.map((item, index) => (
                      <TableCell align="center" key={index} style={{ minWidth: '80px' }}>
                        {item?.amount}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ minWidth: '80px' }}>Total Qty</TableCell>
                    {fields?.map((item, index) => (
                      <TableCell align="center" key={index} style={{ minWidth: '80px' }}>
                        {item?.combinedQty}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {fields?.length === 0 && (
            <Table>
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Typography variant="h6" sx={{ color: 'text.disabled' }}>
                    Data Not Found
                  </Typography>
                </TableCell>
              </TableRow>
            </Table>
          )}
        </Grid>
      </Stack>
    </Box>
  );
}

export default PlatformSummary;
