import Page from 'components/Page';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { getStoreConfigDetails } from 'redux/slices/storeConfigSlice';
import TimeClockHeaders from './TimeClockHeaders';
import TimeClockBody from './TimeClockBody';
import 'css/timeclock.scss';

// new time clock
function TimeClocksIndex() {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  useEffect(() => {
    dispatch(getStoreConfigDetails());
  }, [dispatch]);
  return (
    <Page title="Time Clock">
      <TimeClockHeaders startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} />
      <TimeClockBody startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} />
    </Page>
  );
}

export default TimeClocksIndex;
