import { InputAdornment, Stack, TextField } from '@mui/material';
import Iconify from 'components/Iconify';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

UserWorkReportFilterByName.propTypes = {
  setList: PropTypes.func,
};
function UserWorkReportFilterByName({ setList }) {
  const { userWorkReport } = useSelector((state) => state.reports);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    if (!search) setList(userWorkReport);
    if (search) {
      const filtered = userWorkReport.filter(
        (item) =>
          item.firstName.toLowerCase().includes(search.toLowerCase()) ||
          item.lastName.toLowerCase().includes(search.toLowerCase())
      );
      setList(filtered);
    }
  }, [userWorkReport, search, setList]);
  return (
    <Stack m="1rem">
      <TextField
        id="outlined-basic"
        variant="outlined"
        placeholder="Search user..."
        onChange={(event) => setSearch(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
}

export default UserWorkReportFilterByName;
