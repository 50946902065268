import { combineReducers } from 'redux';
// import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
// import mailReducer from './slices/mail';
// import chatReducer from './slices/chat';
// import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
// import kanbanReducer from './slices/kanban';
import authReducer from './slices/authSlice';
import userReducer from './slices/userSlice';
import storeReducer from './slices/storeSlice';
import userDepartmentSlice from './slices/userDepartmentSlice';
import InvoiceSlice from './slices/InvoiceSlice';
import UserRoleSlice from './slices/UserRolesSlice';
import TimeSlice from './slices/timeSlice';
import RoasterSlice from './slices/roasterSlice';
import FeatureSlice from './slices/featureSlice';
import LeaveRequestSlice from './slices/leaveRequestSlice';
import ShiftRequestSlice from './slices/shiftRequestSlice';
import salesSlice from './slices/salesSlice';
import stockSlice from './slices/stockSlice';
import ingredientSlice from './slices/ingredientSlice';
import operationalSlice from './slices/operationalSlice';
import FoodCategoriesAndSizeSlice from './slices/FoodCategoriesAndSizeSlice';
import FoodMenuSlice from './slices/FoodMenuSlice';
import FoodRecipeSlice from './slices/FoodRecipeSlice';
import reportSlice from './slices/reportSlice';
import storeDocumentSlice from './slices/storeDocumentSlice';

import menuERSizeAndOptionSlice from './slices/menuERSizeAndOptionSlice';

import menuEngineeringSlice from './slices/menuEngineeringSlice';

import announcementSlice from './slices/announcementSlice';

import dealSlice from './slices/dealSlice';

import quickSummarySlice from './slices/quickSummarySlice';

import userNoteSlice from './slices/userNoteSlice';

import halfAndHalfSlice from './slices/halfAndHalfSlice';

import storeConfigSlice from './slices/storeConfigSlice';

import posConfigSlice from './slices/posConfigSlice';

import mostLikedMenusSlice from './slices/mostLikedMenusSlice';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const appReducer = combineReducers({
  auth: authReducer,
  userDepartment: userDepartmentSlice,
  user: userReducer,
  store: storeReducer,
  invoice: InvoiceSlice,
  userRole: UserRoleSlice,
  time: TimeSlice,
  roaster: RoasterSlice,
  feature: FeatureSlice,
  LeaveRequest: LeaveRequestSlice,
  ShiftRequest: ShiftRequestSlice,
  sales: salesSlice,
  stocks: stockSlice,
  ingredients: ingredientSlice,
  operational: operationalSlice,
  foodCategoriesAndSize: FoodCategoriesAndSizeSlice,
  foodMenus: FoodMenuSlice,
  foodRecipe: FoodRecipeSlice,
  reports: reportSlice,
  calendar: calendarReducer,
  storeDocument: storeDocumentSlice,

  menuERSizeAndOption: menuERSizeAndOptionSlice,
  menuEngineering: menuEngineeringSlice,

  announcement: announcementSlice,

  deal: dealSlice,

  quickSummary: quickSummarySlice,

  userNote: userNoteSlice,

  halfAndHalf: halfAndHalfSlice,

  storeConfig: storeConfigSlice,

  posConfig: posConfigSlice,

  mostLikedMenus: mostLikedMenusSlice,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export { rootPersistConfig, rootReducer };
