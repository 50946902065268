import { Box, Button, Card, MenuItem, Stack } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import CustomSelectField from 'components/CustomComponents/CustomSelectField';
import { FormProvider } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getMenuOptionGroup, sizeGroupOptionGroupBinding } from 'redux/slices/menuERSizeAndOptionSlice';

function AddMenuOptionToMenuSize({ open, setOpen, data }) {
  console.log('🚀 => AddMenuOptionToMenuSize => data=>', data);

  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const { menuOptionGroup, loading, postLoading } = useSelector((state) => state.menuERSizeAndOption);
  const remainingOptions = menuOptionGroup?.filter(
    (option) => !data?.sizeGroupsOptionGroups?.find((menuOption) => menuOption?.optionGroupName === option?.name)
  );

  const defaultValues = {
    optionGroupId: '',
    sizeGroupId: data?.id,
  };

  const methods = useForm({ defaultValues });

  const { handleSubmit, watch, reset } = methods;

  const HandleSave = (data) => {
    const finalData = {
      ...data,
      departmentId,
    };
    dispatch(sizeGroupOptionGroupBinding({ finalData, handleClose }));
  };

  const watchOptionGroupId = watch('optionGroupId');

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getMenuOptionGroup({ departmentId }));
  }, [departmentId, dispatch]);

  const handleClose = () => {
    setOpen({ status: false, data: null });
  };
  return (
    <CustomDrawer
      open={open}
      setOpen={handleClose}
      title={`Add Menu Option to ${data?.name}`}
      PaperProps={{ sx: { minWidth: '30%' } }}
    >
      <Box>
        {loading ? (
          <CustomLoadingScreen height="20vh" />
        ) : (
          <>
            <FormProvider methods={methods} onSubmit={handleSubmit(HandleSave)}>
              <Card sx={{ p: 2 }}>
                <Stack spacing={2}>
                  <CustomSelectField name="optionGroupId" label="Select Option Group" sx={{ width: '15rem' }}>
                    {remainingOptions?.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </CustomSelectField>
                </Stack>
                <Stack direction="row" justifyContent="flex-start" style={{ marginTop: '20px' }}>
                  <Button variant="contained" type="submit" disabled={!watchOptionGroupId}>
                    {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : 'Save'}
                  </Button>
                </Stack>
              </Card>
            </FormProvider>
          </>
        )}
      </Box>
    </CustomDrawer>
  );
}

export default AddMenuOptionToMenuSize;
