import { Button, TableCell, TableRow } from '@mui/material';

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Iconify from 'components/Iconify';
import ChecklistAddForm from './ChecklistAddForm';
import ChecklistDeleteModal from './ChecklistDeleteModal';

// const recurringOptions = [
//   {
//     value: 'DAILY',
//     label: 'Daily',
//   },
//   {
//     value: 'WEEKLY',
//     label: 'Weekly',
//   },
//   {
//     value: 'FORT_NIGHTLY',
//     label: 'Forthnightly',
//   },
//   {
//     value: 'MONTHLY',
//     label: 'Monthly',
//   },
// ];

function IndividualChecklist({ item, parentCategory, index }) {
  const [openEdit, setOpenEdit] = useState({ status: false, data: null });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <>
      <TableRow hover>
        <TableCell>{index + 1}.</TableCell>
        <TableCell>{item?.title}</TableCell>
        <TableCell>{item?.type}</TableCell>
        <TableCell>{item?.taskDate}</TableCell>
        <TableCell>{item?.isRecurring ? 'Yes' : 'No'}</TableCell>
        <TableCell align="right">
          <Button
            style={{ color: '#4caf50', marginLeft: '10px', marginRight: '10px' }}
            variant="text"
            startIcon={<Iconify icon="material-symbols:edit-outline" />}
            onClick={() => {
              setOpenEdit({ status: true, data: item });
              // setSelectedRow(row);
            }}
          >
            Edit
          </Button>
          <Button
            variant="text"
            color="error"
            startIcon={<Iconify icon="mdi:trash-can" />}
            onClick={() => setOpenDeleteModal({ status: true, data: item })}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
      {openEdit.status && (
        <ChecklistAddForm
          open={openEdit.status}
          setOpen={setOpenEdit}
          item={openEdit.data}
          isEdit
          categoryId={parentCategory?.id}
        />
      )}
      {openDeleteModal?.status && (
        <ChecklistDeleteModal
          open={openDeleteModal?.status}
          setOpen={setOpenDeleteModal}
          item={openDeleteModal?.data}
          categoryId={parentCategory?.id}
        />
      )}
    </>
  );
}

IndividualChecklist.propTypes = {
  item: PropTypes.object,
  parentCategory: PropTypes.object,
  index: PropTypes.number,
};
export default IndividualChecklist;
