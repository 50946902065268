import { Icon } from '@iconify/react';
import { Avatar, Box, Button, Divider, Stack } from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import React from 'react';

function FoodMenuSalesMenusAccordion({ menu, mainIndex, menuIndex }) {
  return (
    <>
      <Stack flexDirection="row" alignItems={'center'} my="0.5rem">
        <Stack
          alignItems="center"
          gap="0.5rem"
          flexDirection={'row'}
          my="0.15rem"
          p="0.4rem"
          borderRadius={'10px'}
          fontWeight={500}
        >
          <Stack>
            <Avatar alt="menu Image" src={menu?.imageUrl} />
          </Stack>
          <Stack>
            <p>
              <Button sx={{ color: 'black', fontWeight: '500', fontSize: '1rem' }}>{menu?.name}</Button>
            </p>
          </Stack>
        </Stack>
        <Stack className={'manageMenuSizeList'}>
          {menu?.sizes?.map((size, index) => (
            <Box key={index} className="manageMenuSizeListPriceSettings">
              {!size?.isPublished ? (
                <Icon icon="material-symbols:disabled-by-default" color="red" width="12px" />
              ) : (
                <Box sx={{ width: '12px' }} />
              )}
              <RHFTextField
                name={`data.${mainIndex}.menus.${menuIndex}.sizes.${index}.numberSold`}
                size="small"
                label="Qty"
                InputLabelProps={{ shrink: true }}
                sx={{ width: '4rem' }}
              />
            </Box>
          ))}
        </Stack>
      </Stack>
      <Divider />
    </>
  );
}

export default FoodMenuSalesMenusAccordion;
