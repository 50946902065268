import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { useDispatch } from 'react-redux';
import { getTradingHours } from 'redux/slices/storeConfigSlice';
import TradingHoursBody from './TradingHoursBody';
import TradingHoursCard from './TradingHoursCard';

function TradingHoursIndex() {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const [choosenType, setChoosenType] = useState('MON');

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getTradingHours({ departmentId }));
  }, [departmentId, dispatch, choosenType]);
  return (
    <Page title="Trading Hours">
      {/* <TradingHoursBody /> */}
      <TradingHoursCard choosenType={choosenType} setChoosenType={setChoosenType} />
    </Page>
  );
}

export default TradingHoursIndex;
