import { Box, Card, Divider, Tab, Tabs } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getIngredientMenusList } from 'redux/slices/ingredientSlice';
import IngredientAddMenusForm from './IngredientAddMenusForm';
import IngredientAddStockForm from './IngredientAddStockForm';

export default function IngredientStockAndMenusHandler({ currentData }) {
  const [tabValue, setTabValue] = useState('stock');

  return (
    <>
      <Card>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={tabValue}
          onChange={(e, value) => {
            setTabValue(value);
          }}
          sx={{ px: 2, bgcolor: 'background.neutral' }}
        >
          <Tab label="Stock Items" value="stock" />
          <Tab label="Menus" value="menu" />
        </Tabs>
        <Divider />
        {/* <Scrollbar> */}
        <Box style={{ minWidth: '80vw' }}>
          {tabValue === 'stock' ? (
            <IngredientAddStockForm currentData={currentData} />
          ) : (
            <IngredientAddMenusForm currentData={currentData} />
          )}
          {/* </Scrollbar> */}
        </Box>
      </Card>
    </>
  );
}
