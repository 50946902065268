import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button, Stack } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
// import { FormProvider } from 'components/hook-form';
import React, { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import DealOfferBodyTableRow from 'sections/@dashboard/DealOffer/DealOfferBodyTableRow';
import { reorderDeal } from 'redux/slices/dealSlice';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { restrictToParentElement } from '@dnd-kit/modifiers';

function DealOfferBody() {
  const dispatch = useDispatch();
  const [active, setActive] = React.useState(null);
  const departmentId = useGetDepartmentId();
  const { dealOfferList, loading } = useSelector((state) => state.deal);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { reset, control, handleSubmit } = methods;

  const { fields, move } = useFieldArray({
    control,
    name: 'data',
    keyName: 'dealId',
  });
  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;
    if (active.id !== over.id) {
      move(
        fields.findIndex((item) => item?.id === active.id),
        fields.findIndex((item) => item?.id === over.id)
      );
      // move(Number(active.id), Number(over.id));
    }
  };

  useEffect(() => {
    if (!dealOfferList?.length) return;
    reset({ data: dealOfferList });
  }, [dealOfferList, reset]);

  const reOrderManage = (data) => {
    const finalData = data?.data?.map((item) => ({ ...item }));
    dispatch(reorderDeal({ finalData, departmentId }));
  };

  return (
    <FormProvider {...methods}>
      {loading ? (
        <CustomLoadingScreen height="30vh" />
      ) : (
        <form id="dealReOrder" onSubmit={handleSubmit(reOrderManage)}>
          <Stack spacing={1}>
            <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToParentElement]}
            >
              <SortableContext items={fields} strategy={verticalListSortingStrategy} handle>
                {fields?.map((el, index) => (
                  <DealOfferBodyTableRow key={el?.id} el={el} index={index} />
                ))}
              </SortableContext>
            </DndContext>
          </Stack>
          {fields?.length > 0 && (
            <Stack direction={'row'} justifyContent={'flex-end'} spacing={1} mt={2}>
              <Button variant="contained" onClick={handleSubmit(reOrderManage)}>
                Save
              </Button>
            </Stack>
          )}
        </form>
      )}
    </FormProvider>
  );
}

export default DealOfferBody;
