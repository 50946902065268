import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import React from 'react';
import { PATH_DASHBOARD } from 'routes/paths';

function UserWorkReportHeader() {
  return (
    <HeaderBreadcrumbs
      heading="Report: User Work Report"
      links={[
        { name: 'Dashboard', href: PATH_DASHBOARD.root },
        { name: 'Report', href: PATH_DASHBOARD.report.root },
        { name: 'User Work Report' },
      ]}
    />
  );
}

export default UserWorkReportHeader;
