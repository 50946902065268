import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Iconify from 'components/Iconify';
import Page from 'components/Page';
import Scrollbar from 'components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ViewMenuOptionDetails from 'sections/@dashboard/menuEngineering/menuOption/ViewMenuOptionDetails';
import { deleteMenuOptionGroup, getMenuOptionGroup } from 'redux/slices/menuERSizeAndOptionSlice';
import { getIngredientListWithPrice, getRecipeIngredientListWithPrice } from 'redux/slices/ingredientSlice';
import MenuOptionEdit from './MenuOptionEdit';

const TABLE_HEAD = [
  { id: 1, label: 'S.N', align: 'left' },
  { id: 2, label: 'Name', align: 'left' },
  { id: 3, label: 'Actions', align: 'center' },
];

function MenuOptionIndex() {
  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, id: null });
  const [openView, setOpenView] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const { menuOptionGroup, loading } = useSelector((state) => state.menuERSizeAndOption);

  const HandleDeleteOptionGroup = () => {
    dispatch(deleteMenuOptionGroup({ departmentId, optionGroupId: openDeleteModal?.id }));
    setOpenDeleteModal({ id: null, status: false });
  };

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getMenuOptionGroup({ departmentId }));
    dispatch(getIngredientListWithPrice({ departmentId }));
    dispatch(getRecipeIngredientListWithPrice({ departmentId }));
  }, [departmentId, dispatch]);

  return (
    <Page title="Option Group">
      <>
        <Scrollbar>
          <TableContainer>
            <Table stickyHeader size="small">
              <TableHeadCustom headLabel={TABLE_HEAD} />
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={3}>
                      <CustomLoadingScreen height="30vh" />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {menuOptionGroup?.length <= 0 ? (
                      <TableNoData isNotFound={menuOptionGroup?.length <= 0} />
                    ) : (
                      <>
                        {menuOptionGroup?.map((row, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{row?.name}</TableCell>
                            <TableCell align="center">
                              <Button
                                style={{ color: '#4caf50', marginLeft: '10px', marginRight: '10px' }}
                                variant="text"
                                startIcon={<Iconify icon="material-symbols:edit-outline" />}
                                onClick={() => {
                                  setOpenEdit(true);
                                  setSelectedRow(row);
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="text"
                                color="error"
                                startIcon={<Iconify icon="mdi:trash-can" />}
                                onClick={() => {
                                  setOpenDeleteModal({ status: true, id: row?.id });
                                }}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        {openDeleteModal?.status && (
          <CustomDeleteDialog
            open={openDeleteModal}
            handleClose={setOpenDeleteModal}
            onAccept={HandleDeleteOptionGroup}
          />
        )}
        {openView && <ViewMenuOptionDetails open={openView} handleClose={setOpenView} selectedRow={selectedRow} />}
        {openEdit && <MenuOptionEdit open={openEdit} handleClose={setOpenEdit} selectedRow={selectedRow} />}
      </>
    </Page>
  );
}

export default MenuOptionIndex;
