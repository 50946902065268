import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import { successToastTime, errorToastTime } from 'config';

export const createMenuSizeGroup = createAsyncThunk('menuER/createSizeOption', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post('/menuER/sizegroup', props?.finalData);
    if (response.status === 200) {
      // eslint-disable-next-line no-unused-expressions
      props?.isEdit
        ? toast.success('Menu Size Group Updated', { autoClose: successToastTime })
        : toast.success('Menu Size Group Created', { autoClose: successToastTime });
      thunkAPI.dispatch(getMenuSizeGroup({ departmentId: props?.finalData?.departmentId }));
      props?.handleClose();
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getMenuSizeGroup = createAsyncThunk('menuER/getSizeOption', async (props) => {
  try {
    const response = await apiURL.get(`/menuER/sizegroup/${props?.departmentId}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteMenuSizeGroup = createAsyncThunk('menuER/deleteSizeOption', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/menuER/sizegroup/${props?.departmentId}/${props?.sizeGroupId}`);
    if (response.status === 200) {
      toast.success('Successfully deleted the size group', { autoClose: successToastTime });
      thunkAPI.dispatch(getMenuSizeGroup({ departmentId: props?.departmentId }));
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getIndividualMenuSize = createAsyncThunk('menuER/getIndividualMenuSize', async (props) => {
  try {
    const response = await apiURL.get(`/menuER/size/${props?.departmentId}/${props?.sizeGroupId}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteIndividualMenuSize = createAsyncThunk('menuER/deleteIndividualMenuSize', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/menuER/size/${props?.departmentId}/${props?.sizeGroupId}/${props?.sizeId}`);
    if (response.status === 200) {
      toast.sucess('Successfully deleted the size', { autoClose: successToastTime });
      thunkAPI.dispatch(getIndividualMenuSize({ departmentId: props?.departmentId, sizeGroupId: props?.sizeGroupId }));
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createMenuOptionGroup = createAsyncThunk('menuER/createOptionGroup', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post('/menuER/optiongroup', props?.finalData);
    if (response.status === 200) {
      // eslint-disable-next-line no-unused-expressions
      props?.isEdit
        ? toast.success('Menu Option Group Updated', { autoClose: successToastTime })
        : toast.success('Successfully created the option group', { autoClose: successToastTime });
      thunkAPI.dispatch(getMenuOptionGroup({ departmentId: props?.finalData?.departmentId }));
      // eslint-disable-next-line no-unused-expressions
      props?.isEdit
        ? thunkAPI.dispatch(
            getIndividualOption({ departmentId: props?.finalData?.departmentId, optionGroupId: props?.finalData?.id })
          )
        : props?.handleClose();
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getMenuOptionGroup = createAsyncThunk('menuER/getOptionGroup', async (props) => {
  try {
    const response = await apiURL.get(`/menuER/optiongroup/${props?.departmentId}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteMenuOptionGroup = createAsyncThunk('menuER/deleteOptionGroup', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/menuER/optiongroup/${props?.departmentId}/${props?.optionGroupId}`);
    if (response.status === 200) {
      toast.success('Successfully deleted the option group', { autoClose: successToastTime });
      thunkAPI.dispatch(getMenuOptionGroup({ departmentId: props?.departmentId }));
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getIndividualOption = createAsyncThunk('menuER/getIndividualOption', async (props) => {
  try {
    const response = await apiURL.get(`/menuER/option/${props?.departmentId}/${props?.optionGroupId}`);
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteIndividualOption = createAsyncThunk('menuER/deleteIndividualOption', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(
      `/menuER/option/${props?.departmentId}/${props?.optionGroupId}/${props?.optionId}`
    );
    if (response.status === 200) {
      toast.success('Successfully deleted the option', { autoClose: successToastTime });
      thunkAPI.dispatch(
        getIndividualOption({ departmentId: props?.departmentId, optionGroupId: props?.optionGroupId })
      );
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateIndividualOptionImage = createAsyncThunk(
  'menuER/updateIndividualOptionImage',
  async (props, thunkAPI) => {
    try {
      const response = await apiURL.post(`/menuER/optionImage`, props?.formData);
      if (response.status === 200) {
        toast.success('Successfully updated the option image', { autoClose: successToastTime });
        thunkAPI.dispatch(getIndividualOption({ departmentId: props?.departmentId, optionGroupId: props?.id }));
        // props?.isSuccess(true);
      }
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      thunkAPI.dispatch(getIndividualOption({ departmentId: props?.departmentId, optionGroupId: props?.id }));
      throw error;
    }
  }
);

export const deleteIndividualOptionImage = createAsyncThunk(
  'menuER/deleteIndividualOptionImage',
  async (props, thunkAPI) => {
    try {
      const response = await apiURL.delete(
        `/menuER/optionImage/${props?.departmentId}/${props?.optionGroupId}/${props?.optionId}`
      );
      if (response.status === 200) {
        toast.success('Successfully deleted the option image', { autoClose: successToastTime });
        props?.isSuccess(true);
        thunkAPI.dispatch(
          getIndividualOption({ departmentId: props?.departmentId, optionGroupId: props?.optionGroupId })
        );
        // props?.isSuccess(true);
      }
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      thunkAPI.dispatch(
        getIndividualOption({ departmentId: props?.departmentId, optionGroupId: props?.optionGroupId })
      );
      throw error;
    }
  }
);

export const sizeGroupOptionGroupBinding = createAsyncThunk(
  '/menuER/sizeGroupOptionGroupBinding',
  async (props, thunkAPI) => {
    try {
      const response = await apiURL.post('/menuER/sizeGroupOptionGroup', props?.finalData);
      if (response.status === 200) {
        toast.success('Successfully binded the size group and option group', { autoClose: successToastTime });
        thunkAPI.dispatch(getSizeGroupOptionGroup({ departmentId: props?.finalData?.departmentId }));
        props?.handleClose();
      }
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const getSizeGroupOptionGroup = createAsyncThunk('/menuER/getSizeGroupOptionGroup', async (props) => {
  try {
    const response = await apiURL.get(`menuER/sizeGroupOptionGroup/${props?.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getSizeGroupOptionGroupDetails = createAsyncThunk(
  '/menuER/getSizeGroupOptionGroupDetails',
  async (props) => {
    try {
      const response = await apiURL.get(
        `menuER/sizeOptions/${props?.departmentId}/${props?.sizeGroupOptionGroupId}/${props?.sizeGroupId}/${props?.optionGroupId}`
      );
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const updateExtraPriceForSizeOption = createAsyncThunk(
  '/menuER/updateExtraPriceForSizeOption',
  async (props) => {
    try {
      const response = await apiURL.post('/menuER/handleSizeOptions', props?.finalData);
      if (response.status === 200) {
        toast.success('Successfully updated the extra price for size option', { autoClose: successToastTime });
        props?.handleClose();
      }
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const deleteBindedOption = createAsyncThunk('/menuER/deleteBindedOption', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(
      `/menuER/sizeOptionsUsingOptionId/${props?.departmentId}/${props?.sizeGroupOptionGroupId}/${props?.optionId}`
    );
    if (response.status === 200) {
      toast.success('Price for selected option deleted', { autoClose: successToastTime });
      thunkAPI.dispatch(
        getSizeGroupOptionGroupDetails({
          departmentId: props?.departmentId,
          sizeGroupOptionGroupId: props?.sizeGroupOptionGroupId,
          sizeGroupId: props?.sizeGroupId,
          optionGroupId: props?.optionGroupId,
        })
      );
    }
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteBindedSize = createAsyncThunk('/menuER/deleteBindedSize', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(
      `/menuER/sizeOptionsUsingSizeId/${props?.departmentId}/${props?.sizeGroupOptionGroupId}/${props?.sizeId}`
    );
    if (response.status === 200) {
      toast.success('Price for selected size deleted', { autoClose: successToastTime });
      thunkAPI.dispatch(
        getSizeGroupOptionGroupDetails({
          departmentId: props?.departmentId,
          sizeGroupOptionGroupId: props?.sizeGroupOptionGroupId,
          sizeGroupId: props?.sizeGroupId,
          optionGroupId: props?.optionGroupId,
        })
      );
    }
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteSizeOptionGroup = createAsyncThunk('/menuER/deleteSizeOptionGroup', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(
      `/menuER/sizeGroupOptionGroup/${props?.departmentId}/${props?.sizeGroupId}/${props?.optionGroupId}`
    );
    if (response.status === 200) {
      toast.success('Size option group deleted', { autoClose: errorToastTime });
      thunkAPI.dispatch(getSizeGroupOptionGroup({ departmentId: props?.departmentId }));
    }
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const reorderSizeOption = createAsyncThunk('/menuER/reorderSizeOption', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put(`/menuER/order/sizeAndOptionGroups/${props?.departmentId}`, props?.finalData);
    if (response.status === 200) {
      toast.success('Successfully reordered the size option', { autoClose: successToastTime });
      thunkAPI.dispatch(
        getSizeGroupOptionGroup({
          departmentId: props?.departmentId,
        })
      );
    }
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

const menuERSizeAndOptionSlice = createSlice({
  name: 'menuERSizeAndOption',
  initialState: {
    loading: false,
    postLoading: false,
    singleMenuSizeLoading: false,
    singleMenuOptionLoading: false,
    menuSizeGroup: [],
    individualMenuSize: [],
    individualMenuOption: [],
    menuOptionGroup: [],
    menuSizeOptionGroupLoading: false,
    menuSizeOptionGroup: [],
    currentTab: 0,
  },

  reducers: {
    changeMenuERSizeOptionCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
  },

  extraReducers: {
    [createMenuSizeGroup.pending]: (state) => {
      state.postLoading = true;
    },
    [createMenuSizeGroup.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [createMenuSizeGroup.rejected]: (state) => {
      state.postLoading = false;
    },
    // ---------------------------
    [getMenuSizeGroup.pending]: (state) => {
      state.loading = true;
      state.menuSizeGroup = [];
    },
    [getMenuSizeGroup.fulfilled]: (state, action) => {
      state.loading = false;
      state.menuSizeGroup = action.payload;
    },
    [getMenuSizeGroup.rejected]: (state) => {
      state.loading = false;
      state.menuSizeGroup = [];
    },
    // ------------------------------
    [createMenuOptionGroup.pending]: (state) => {
      state.postLoading = true;
    },
    [createMenuOptionGroup.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [createMenuOptionGroup.rejected]: (state) => {
      state.postLoading = false;
    },
    // ---------------------------
    [getMenuOptionGroup.pending]: (state) => {
      state.loading = true;
      state.menuOptionGroup = [];
    },
    [getMenuOptionGroup.fulfilled]: (state, action) => {
      state.loading = false;
      state.menuOptionGroup = action.payload;
    },
    [getMenuOptionGroup.rejected]: (state) => {
      state.loading = false;
      state.menuOptionGroup = [];
    },
    // ------------------------------
    [getIndividualMenuSize.pending]: (state) => {
      state.singleMenuSizeLoading = true;
      state.individualMenuSize = [];
    },
    [getIndividualMenuSize.fulfilled]: (state, action) => {
      state.singleMenuSizeLoading = false;
      state.individualMenuSize = action.payload;
    },
    [getIndividualMenuSize.rejected]: (state) => {
      state.singleMenuSizeLoading = false;
      state.individualMenuSize = [];
    },
    // ------------------------------
    [getIndividualOption.pending]: (state) => {
      state.singleMenuOptionLoading = true;
      state.individualMenuOption = [];
    },
    [getIndividualOption.fulfilled]: (state, action) => {
      state.singleMenuOptionLoading = false;
      state.individualMenuOption = action.payload;
    },
    [getIndividualOption.rejected]: (state) => {
      state.singleMenuOptionLoading = false;
      state.individualMenuOption = [];
    },
    // --------------------------------
    [sizeGroupOptionGroupBinding.pending]: (state) => {
      state.postLoading = true;
    },
    [sizeGroupOptionGroupBinding.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [sizeGroupOptionGroupBinding.rejected]: (state) => {
      state.postLoading = false;
    },
    // -----------------------------------
    [getSizeGroupOptionGroup.pending]: (state) => {
      state.menuSizeOptionGroupLoading = true;
      state.menuSizeOptionGroup = [];
    },
    [getSizeGroupOptionGroup.fulfilled]: (state, action) => {
      state.menuSizeOptionGroupLoading = false;
      state.menuSizeOptionGroup = action.payload;
    },
    [getSizeGroupOptionGroup.rejected]: (state) => {
      state.menuSizeOptionGroupLoading = false;
      state.menuSizeOptionGroup = [];
    },
    // -----------------------------------
    [getSizeGroupOptionGroupDetails.pending]: (state) => {
      state.loading = true;
      state.sizeOptionDetails = [];
    },
    [getSizeGroupOptionGroupDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.sizeOptionDetails = action.payload;
    },
    [getSizeGroupOptionGroupDetails.rejected]: (state) => {
      state.loading = false;
      state.sizeOptionDetails = [];
    },
    // -----------------------------------
    [updateExtraPriceForSizeOption.pending]: (state) => {
      state.postLoading = true;
    },
    [updateExtraPriceForSizeOption.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateExtraPriceForSizeOption.rejected]: (state) => {
      state.postLoading = false;
    },
  },
});

export const { changeMenuERSizeOptionCurrentTab } = menuERSizeAndOptionSlice.actions;

export default menuERSizeAndOptionSlice.reducer;
