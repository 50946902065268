import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import moment from 'moment';
import { errorToastTime } from 'config';

export const getShiftRequest = createAsyncThunk('roaster/viewShiftRequest', async (data) => {
  try {
    const response = await apiURL.post('/roaster/viewShiftRequest', data);
    return response.data;
  } catch (error) {
    toast.error('Error Occured while getting Online Platforms', { autoClose: errorToastTime });
    throw error;
  }
});

export const getStoreDate = createAsyncThunk('user/storeDT', async () => {
  try {
    const response = await apiURL.get('/user/storeDT');
    return response.data;
  } catch (error) {
    toast.error('Error Occured while getting Online Platforms', { autoClose: errorToastTime });
    throw error;
  }
});

export const updateShiftRequest = createAsyncThunk('roaster/updateShiftRequest', async (data, thunkAPI) => {
  try {
    const response = await apiURL.put('/roaster/approveShiftPickupReq', data.dataSet);
    if (response.status === 200) {
      thunkAPI.dispatch(
        getShiftRequest({
          departmentId: data.dataSet.departmentId,
          startDate: moment(data.fromDate).format('YYYY-MM-DD'),
          endDate: moment(data.toDate).format('YYYY-MM-DD'),
          statusList: ['PENDING'],
          typeList: ['PICKUP'],
        })
      );
    }
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

const shiftRequestSlice = createSlice({
  name: 'shiftRequest',
  initialState: {
    shiftRequest: [],
    storeDate: null,
    loading: false,
  },

  extraReducers: {
    [getShiftRequest.pending]: (state) => {
      state.loading = true;
    },
    [getShiftRequest.fulfilled]: (state, action) => {
      state.shiftRequest = action.payload;
      state.loading = false;
    },
    [getShiftRequest.rejected]: (state) => {
      state.loading = false;
    },
    [getStoreDate.pending]: (state) => {
      state.loading = true;
    },
    [getStoreDate.fulfilled]: (state, action) => {
      state.storeDate = action.payload;
      state.loading = false;
    },
    [getStoreDate.rejected]: (state) => {
      state.loading = false;
    },
    [updateShiftRequest.pending]: (state) => {
      state.loading = true;
    },
    [updateShiftRequest.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateShiftRequest.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default shiftRequestSlice.reducer;
