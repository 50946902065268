import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, Button, FormControlLabel, FormLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import { RHFSwitch, RHFTextField } from 'components/hook-form';
import CustomDatePicker from 'components/CustomComponents/CustomDatePicker';
import moment from 'moment';
import { createChecklistItem, getChecklistCategory } from 'redux/slices/storeDocumentSlice';
import CustomMultipleAutoCompleteField from 'components/CustomComponents/CustomAutoCompleteField';
import { DatePicker } from '@mui/x-date-pickers';

const recurringOptions = [
  {
    value: 'ONCE',
    label: 'Once',
  },
  {
    value: 'DAILY',
    label: 'Daily',
  },
  {
    value: 'WEEKLY',
    label: 'Weekly',
  },
  {
    value: 'FORT_NIGHTLY',
    label: 'Forthnightly',
  },
  {
    value: 'MONTHLY',
    label: 'Monthly',
  },
];

function ChecklistAddForm({ item, open, setOpen, isEdit, categoryId }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const { loading: userLoading } = useSelector((state) => state.user);
  //   const { store } = useSelector((state) => state.store);
  const { postLoading, checklistCategories } = useSelector((state) => state.storeDocument);

  const closeModal = () => {
    setOpen(false);
  };

  const checklistYupSchema = Yup.object().shape({
    title: Yup.string().required(''),
    taskDate: Yup.date().required('').typeError(''),
    // isRecurring: Yup.boolean().required(''),
    type: Yup.string().when('isRecurring', {
      is: true,
      then: (schema) => schema.required(''),
      otherwise: (schema) => schema.notRequired(),
    }),
    // checkListCategoryId: Yup.object().required(''),
  });

  const defaultValues = {
    title: '',
    taskDate: null,
    type: '',
    checklistCategoryId: null,
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(checklistYupSchema),
  });

  const { handleSubmit, control, watch, reset } = methods;

  const ChecklistSubmitHandler = (data) => {
    const finalData = {
      ...data,
      isRecurring: data?.type !== 'ONCE',
      // type: data?.type,
      type: data?.type === 'ONCE' ? null : data?.type,
      taskDate: moment(data?.taskDate).format('YYYY-MM-DD'),
      checkListCategoryId: data?.checkListCategoryId?.id,
      departmentId,
    };
    dispatch(createChecklistItem({ finalData, setOpen, isEdit }));
  };
  // const watchRecurring = watch('isRecurring');

  useEffect(() => {
    if (!isEdit) return;
    reset({
      id: item?.id,
      title: item?.title,
      taskDate: item?.taskDate,
      isRecurring: item?.isRecurring,
      type: item?.type,
      checkListCategoryId: checklistCategories?.find((item) => item?.id === categoryId),
    });
  }, [categoryId, checklistCategories, isEdit, item, reset]);

  // useEffect(() => {
  //   if (!departmentId) return;
  //   dispatch(getChecklistCategory({ departmentId }));
  // }, [departmentId, dispatch]);

  console.log(methods.watch());

  return (
    <>
      <CustomOutletModal
        //   width="60rem"
        open={open}
        zIndex="1600"
        onClose={closeModal}
        title={'Add New Checklist '}
      >
        {userLoading ? (
          <CustomLoadingScreen height="20vh" />
        ) : (
          <FormProvider {...methods}>
            <Stack spacing={2} sx={{ p: 1 }} direction={'row'}>
              <RHFTextField name="title" label="Title*" />
              <Controller
                name="taskDate"
                control={control}
                // defaultValue={`${new Date(values?.invoiceDate)}`}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    label="Task Date*"
                    value={field.value}
                    inputFormat="dd/MMM/yyyy"
                    onChange={(newValue) => {
                      field.onChange(newValue);
                    }}
                    PopperProps={{
                      sx: { zIndex: '2100' },
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth error={!!error} helperText={error?.message} />
                    )}
                  />
                )}
              />
              {/* <CustomDatePicker name="taskDate" label="Task Date*" inputFormat="dd/MMM/yyyy" /> */}
              {/* <Stack direction="row" spacing={2}>
                <RHFSwitch style={{ width: '300px' }} name="isRecurring" label="Is Recurring*" />
              </Stack> */}
            </Stack>
            {!isEdit && (
              <Stack spacing={2} sx={{ p: 1 }}>
                <CustomMultipleAutoCompleteField
                  name="checkListCategoryId"
                  label="Select Checklist Category*"
                  options={checklistCategories}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  sx={{ width: '17rem' }}
                />
              </Stack>
            )}
            <Stack>
              <Box sx={{ px: 3 }}>
                <Controller
                  name="type"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <div>
                      <FormLabel sx={{ color: error ? 'red' : '' }}> Repeat * </FormLabel>

                      <RadioGroup {...field} row>
                        {recurringOptions.map((option) => (
                          <FormControlLabel
                            key={option.value}
                            value={option.value}
                            control={<Radio />}
                            label={option.label}
                          />
                        ))}
                      </RadioGroup>
                    </div>
                  )}
                />
              </Box>
            </Stack>

            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="contained"
                type="submit"
                style={{ marginRight: '10px' }}
                onClick={handleSubmit(ChecklistSubmitHandler)}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : isEdit ? 'Update' : 'Create'}
              </Button>
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
            </Stack>
          </FormProvider>
        )}
      </CustomOutletModal>
    </>
  );
}

ChecklistAddForm.propTypes = {
  item: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  isEdit: PropTypes.bool,
};
export default ChecklistAddForm;
