import { TableCell, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import React from 'react';

function UserNotesTableHead({ allWeeks }) {
  return (
    <TableHead>
      <TableRow sx={{ backgroundColor: '#F4F6F8' }}>
        <TableCell style={{ borderRadius: '0px', width: '120px' }} />
        {/* for showing weeks */}
        {allWeeks?.map((obj) => (
          <TableCell style={{ padding: '2px', textAlign: 'center', borderRadius: '0px' }} key={obj?.id}>
            <p style={{ padding: '2px' }}>{moment(obj?.label).format('ddd')}</p>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default UserNotesTableHead;
