import React from 'react';
import { Stack } from '@mui/material';
import StaffNewEditForm from './StaffNewEditForm';
import StaffCreateActiveStepperDepartment from './StaffCreateActiveStepperDepartment';
import StaffCreateActiveStepperConfirm from './StaffCreateActiveStepperConfirm';
import StaffCreateActiveStepperPaperwork from './StaffCreateActiveStepperPaperwork';
import StaffCreateActiveStepperTermsCondition from './StaffCreateActiveStepperTermsCondition';
import StaffCreateActiveStepperDeclartion from './StaffCreateActiveStepperDeclartion';

function StaffCreateActiveStepper({ activeStep, isEdit, ackFormRef }) {
  // eslint-disable-next-line no-nested-ternary
  return (
    <Stack my={4}>
      {activeStep === 0 && <StaffCreateActiveStepperTermsCondition />}
      {activeStep === 1 && <StaffNewEditForm isEdit={isEdit} />}
      {activeStep === 2 && <StaffCreateActiveStepperDepartment isEdit={isEdit} />}
      {activeStep === 3 && <StaffCreateActiveStepperPaperwork />}
      {activeStep === 4 && <StaffCreateActiveStepperDeclartion ackFormRef={ackFormRef} />}

      {activeStep === 5 && <StaffCreateActiveStepperConfirm ackRef={ackFormRef} />}
    </Stack>
  );
}

export default StaffCreateActiveStepper;
