import { Button, Stack, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { updateMenuCogsTarget } from 'redux/slices/menuEngineeringSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import FoodMenuCogsTableBody from './FoodMenuCogsTableBody';

const schema = yup.object().shape({
  menuSizes: yup.array().of(
    yup.object().shape({
      target: yup
        .number()
        .typeError('')
        .transform((curr, orig) => (orig === '' ? 0 : curr)),
      targetPercent: yup
        .number()
        .typeError('')
        // .typeError('')
        // .when('target', (target, schema) => {
        //   const target = Number(target);
        // })
        .transform((curr, orig) => (orig === '' ? 0 : curr)),
    })
  ),
});

function FoodMenuCogsTable({ menu }) {
  const { foodCogsReport, postLoading } = useSelector((state) => state.menuEngineering);

  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const defaultValues = {
    menuSizes: [],
  };
  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onTouched' });
  const { handleSubmit, reset } = methods;
  const cogsReportSubmitHandler = (data) => {
    const finalData = {
      departmentId,
      id: menu?.id,
      menuSizes: data?.menuSizes?.map((menuSize) => ({
        id: Number(menuSize?.id),
        target: Number(menuSize?.target),
        targetPercent: Number(menuSize?.targetPercent),
      })),
    };
    console.log('🚀 => cogsReportSubmitHandler => finalData=>', finalData);

    // dispatch(updateMenuCogsTarget({ finalData }));
  };
  const basePrice = menu?.basePrice;

  useEffect(() => {
    if (!foodCogsReport) return;

    reset({
      menuSizes: foodCogsReport?.menuSizes?.map((menuSize) => ({
        id: menuSize?.id,
        cogsCombo: menuSize?.cogsCombo ?? 0,
        cogsCoreIngredient: menuSize?.cogsCoreIngredient ?? 0,
        cogsMenuTopping: menuSize?.cogsMenuTopping ?? 0,
        // totalCogs: cogsCombo + cogsCoreIngredient + cogsMenuTopping,
        // sp: basePrice + size?.additionalPrice,
        // difference: target - sp,
        // differencePercent: ((totalCogs - sp) * 100) / sp,
        // spExGst: sp / 1.1,
        // target: cogs * 100 / targetPercent * 10/11
        // cogsPercent: (cogs * 100) / spExGst,
        // gp: sp * 10/11 - cogs,
        // gpPercent: (cogs * 100) / spExGst,

        // target gp: target * 10/11 - cogs
        // target gp % : cogs * 100 / targetExGst

        targetGp: menuSize?.target
          ? (Number(menuSize?.target) * 10) / 11 -
            (Number(menuSize?.cogsCombo ?? 0) +
              Number(menuSize?.cogsCoreIngredient ?? 0) +
              Number(menuSize?.cogsMenuTopping ?? 0))
          : 0,

        targetGpPercent: menuSize?.target
          ? ((Number(menuSize?.cogsCombo ?? 0) +
              Number(menuSize?.cogsCoreIngredient ?? 0) +
              Number(menuSize?.cogsMenuTopping ?? 0)) *
              100) /
            ((Number(menuSize?.target) * 10) / 11)
          : 0,

        totalCogs:
          Number(menuSize?.cogsCombo ?? 0) +
          Number(menuSize?.cogsCoreIngredient ?? 0) +
          Number(menuSize?.cogsMenuTopping ?? 0),
        cogsPercent:
          ((Number(menuSize?.cogsCombo ?? 0) +
            Number(menuSize?.cogsCoreIngredient ?? 0) +
            Number(menuSize?.cogsMenuTopping ?? 0)) *
            100) /
          (((Number(basePrice) + Number(menu?.sizes?.find((size) => size?.id === menuSize?.id)?.additionalPrice ?? 0)) *
            10) /
            11),
        difference: menuSize?.difference
          ? menuSize?.difference
          : Number(menuSize?.target ?? 0) -
            (Number(basePrice) + Number(menu?.sizes?.find((size) => size?.id === menuSize?.id)?.additionalPrice ?? 0)),
        differencePercent: menuSize?.differencePercent
          ? menuSize?.differencePercent
          : Number(menuSize?.target ?? 0) -
            ((Number(basePrice) +
              Number(menu?.sizes?.find((size) => size?.id === menuSize?.id)?.additionalPrice ?? 0)) *
              100) /
              (Number(basePrice) +
                Number(menu?.sizes?.find((size) => size?.id === menuSize?.id)?.additionalPrice ?? 0)),
        sp: Number(basePrice) + Number(menu?.sizes?.find((size) => size?.id === menuSize?.id)?.additionalPrice ?? 0),
        spExGst: menuSize?.spExGst
          ? menuSize?.spExGst
          : ((Number(basePrice) +
              Number(menu?.sizes?.find((size) => size?.id === menuSize?.id)?.additionalPrice ?? 0)) *
              10) /
            11,
        targetPercent: menuSize?.targetPercent ? menuSize?.targetPercent : 0,
        // : (
        //     ((Number(menuSize?.cogsCombo) +
        //       Number(menuSize?.cogsCoreIngredient) +
        //       Number(menuSize?.cogsMenuTopping)) *
        //       10) /
        //     11
        //   ).toFixed(2),
        target: menuSize?.target ? menuSize?.target : 0,
        // : (
        //     ((Number(menuSize?.cogsCombo) +
        //       Number(menuSize?.cogsCoreIngredient) +
        //       Number(menuSize?.cogsMenuTopping)) *
        //       100) /
        //     (((Number(menuSize?.cogsCombo) +
        //       Number(menuSize?.cogsCoreIngredient) +
        //       Number(menuSize?.cogsMenuTopping)) *
        //       10) /
        //       11)
        //   ).toFixed(2),
        grossProfit: menuSize?.grossProfit
          ? menuSize?.grossProfit
          : ((Number(basePrice) +
              Number(menu?.sizes?.find((size) => size?.id === menuSize?.id)?.additionalPrice ?? 0)) *
              10) /
              11 -
            (Number(menuSize?.cogsCombo ?? 0) +
              Number(menuSize?.cogsCoreIngredient ?? 0) +
              Number(menuSize?.cogsMenuTopping ?? 0)),
        grossProfitPercent: menuSize?.grossProfitPercent
          ? menuSize?.grossProfitPercent
          : 100 -
            ((Number(menuSize?.cogsCombo ?? 0) +
              Number(menuSize?.cogsCoreIngredient ?? 0) +
              Number(menuSize?.cogsMenuTopping ?? 0)) *
              100) /
              (((Number(basePrice) +
                Number(menu?.sizes?.find((size) => size?.id === menuSize?.id)?.additionalPrice ?? 0)) *
                10) /
                11),
      })),
    });
  }, [basePrice, foodCogsReport, menu, reset]);

  // console.log('errors', methods.watch());
  return (
    <FormProvider {...methods}>
      <Scrollbar>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell style={{ minWidth: '100px' }} />
                {foodCogsReport?.menuSizes?.map((size, index) => (
                  <TableCell key={index} align="center" style={{ minWidth: '120px' }}>
                    {size?.sizeName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <FoodMenuCogsTableBody menu={menu} />
          </Table>
        </TableContainer>
      </Scrollbar>
      <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center" sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSubmit(cogsReportSubmitHandler)}
          disabled={postLoading}
        >
          {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : 'Save'}
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default FoodMenuCogsTable;
