import {
  Box,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { fNumber } from 'utils/formatNumber';
import { useFormContext, useWatch } from 'react-hook-form';
import { RHFTextField } from 'components/hook-form';
import React from 'react';
import { useSelector } from 'react-redux';
import DeliverySummaryTable from './DeliverySummaryTable';

function DeliverySummary() {
  const { onlineSalesPlatform, loading: salesPlatformLoading } = useSelector((state) => state.store);
  const { featureBasedUser, loading: featureBasedUserLoading } = useSelector((state) => state.user);

  const { control, watch } = useFormContext();
  const deliveryWatch = useWatch({
    control,
    name: 'deliveryEntries',
  });

  const totalDeliveryCountEntry = watch('totalDeliveryCount');

  const findTotalOfPlatform = (item) => {
    let totalDeliveryCount = 0;

    deliveryWatch?.map((el) => {
      if (Number(el?.onlinePlatformId) === item?.id) {
        featureBasedUser?.map((user) => {
          if (Number(el?.userId) === user?.id) {
            totalDeliveryCount += Number(el?.totalDeliveryCount || 0);
          }
          return totalDeliveryCount;
        });
      }
      return totalDeliveryCount;
    });
    return totalDeliveryCount;
  };

  const findTotalDeliveryOfForm = () => {
    let totalDeliveryCount = 0;
    deliveryWatch?.map((el) => {
      featureBasedUser?.map((user) => {
        if (Number(el?.userId) === user?.id) {
          totalDeliveryCount += Number(el?.totalDeliveryCount || 0);
        }
        return totalDeliveryCount;
      });
      // totalDeliveryCount += Number(el?.deliveryCount || 0);
      return totalDeliveryCount;
    });
    return totalDeliveryCount;
  };
  return (
    <>
      <Stack spacing={1}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3} style={{ marginTop: '20px', marginLeft: '20px' }}>
            <RHFTextField
              disabled
              name={`totalDeliveryCount`}
              label="Total Delivery Count"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Stack>
      <Box sx={{ p: 3 }}>
        <Typography variant="h6" sx={{ color: 'text.disabled', mb: 3 }}>
          Delivery Entrys
        </Typography>
        <Stack spacing={2}>
          {!salesPlatformLoading && !featureBasedUserLoading && (
            <TableContainer>
              <Table stickyHeader style={{ minWidth: '700px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Delivery Drivers</TableCell>
                    {onlineSalesPlatform?.onlinePlatforms?.length > 0 &&
                      onlineSalesPlatform?.onlinePlatforms?.map((item, index) => (
                        <TableCell align="center" key={index} style={{ minWidth: '80px' }}>
                          {item?.platformName}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                {featureBasedUser?.length > 0 && (
                  <TableBody>
                    {featureBasedUser?.map((item, index) => (
                      <DeliverySummaryTable key={index} item={item} mainIndex={index} />
                    ))}
                    <TableRow hover>
                      <TableCell>Total:</TableCell>
                      {onlineSalesPlatform?.onlinePlatforms?.length > 0 &&
                        onlineSalesPlatform?.onlinePlatforms?.map((item, index) => (
                          <TableCell align="center" key={index} style={{ minWidth: '80px' }}>
                            {findTotalOfPlatform(item)}
                          </TableCell>
                        ))}
                    </TableRow>
                    <TableRow>
                      <TableCell>Delivery Count:</TableCell>
                      <TableCell align="center" style={{ minWidth: '80px' }}>
                        {findTotalDeliveryOfForm()}
                      </TableCell>
                      {findTotalDeliveryOfForm() <= totalDeliveryCountEntry && (
                        <TableCell colSpan={4} align="left" style={{ minWidth: '80px', color: 'green' }}>
                          Delivery Variance: {fNumber(totalDeliveryCountEntry - findTotalDeliveryOfForm())}
                        </TableCell>
                      )}
                      {findTotalDeliveryOfForm() > totalDeliveryCountEntry && (
                        <TableCell colSpan={4} align="left" style={{ minWidth: '80px', color: 'red' }}>
                          Delivery Variance: {fNumber(totalDeliveryCountEntry - findTotalDeliveryOfForm())}
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          )}
        </Stack>
      </Box>
    </>
  );
}

export default DeliverySummary;
