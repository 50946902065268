import { Accordion, AccordionDetails, AccordionSummary, Box, Stack } from '@mui/material';
import Iconify from 'components/Iconify';
import propTypes from 'prop-types';
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import FoodMenusDetails from './FoodMenusDetails';

FoodMenuAccordion.propTypes = {
  categories: propTypes.object,
  mainIndex: propTypes.number,
};

function FoodMenuAccordion({ categories, mainIndex, isExpanded, isOverlay, setIsExpanded }) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: categories?.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1,
  };

  return (
    <Accordion
      sx={{ paddingBlock: '0.1rem' }}
      style={{
        ...style,
        backgroundColor: isDragging ? '#cccccc' : '',
        ...(isOverlay && { backgroundColor: '#cccccc' }),
      }}
      defaultExpanded
      key={mainIndex}
      ref={setNodeRef}
    >
      <AccordionSummary
        expandIcon={<Iconify icon="ic:outline-expand-more" width="25px" />}
        aria-controls="Menu with Categories"
        id={categories?.id}
        style={{ height: '3.5rem', borderBottom: '0.5px solid rgba(145, 158, 171, 0.24)' }}
      >
        <Stack direction={'row'} justifyContent={'space-between'} width="100%">
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <div {...listeners} {...attributes} style={{ marginTop: '10px', marginRight: '10px', cursor: 'move' }}>
              <Iconify icon="system-uicons:drag" height={20} width={20} />
            </div>
            <Stack>
              <p style={{ fontWeight: '600' }}>{categories?.name}</p>
              <p style={{ color: '#637381' }}>{categories?.menus?.length} Items</p>
            </Stack>
          </Stack>
          <Stack className={'manageMenuSizeList'}>
            <Box className="manageItemsOfMenuSizeList manageItemsOfMenuSizeListColor " style={{ width: '100px' }}>
              Base Price
            </Box>
          </Stack>
        </Stack>
      </AccordionSummary>
      {/* {!isOverlay && ( */}
      <AccordionDetails className="FoodMenuAccordiansDetails">
        <FoodMenusDetails accordionMenus={categories?.menus} mainIndex={mainIndex} />
      </AccordionDetails>
      {/* )} */}
    </Accordion>
  );
}

export default FoodMenuAccordion;
