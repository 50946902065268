import { TableCell, TableRow } from '@mui/material';
import React, { useEffect } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FoodCategoryCogsReportIndividualItemForm from './FoodCategoryCogsReportIndividualItemForm';

const schema = yup.object().shape({
  menuSizes: yup.object().shape({
    target: yup
      .number()
      .transform((curr, orig) => (orig === '' ? 0 : curr))
      .typeError(''),
    targetPercent: yup
      .number()
      .transform((curr, orig) => (orig === '' ? 0 : curr))
      .typeError(''),
  }),
});

function FoodCategoryCogsReportItemSize({ menuSize, parent }) {
  const defaultValues = {};
  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onChange' });
  const { reset } = methods;

  useEffect(() => {
    if (!menuSize) return;
    reset({
      id: parent?.id,
      menuSizes: {
        id: menuSize?.id,
        sizeName: menuSize.sizeName,
        sellingPrice: Number(parent?.basePrice) + Number(menuSize?.additionalPrice ?? 0),
        spExGst: ((Number(parent?.basePrice) + Number(menuSize?.additionalPrice ?? 0)) * 10) / 11,
        cogs: menuSize?.cogs,
        cogsPercent:
          (menuSize?.cogs * 100) / (((Number(parent?.basePrice) + Number(menuSize?.additionalPrice ?? 0)) * 10) / 11),
        target: menuSize?.target ? menuSize?.target : 0,
        targetPercent: menuSize?.targetPercent ? menuSize?.targetPercent : 0,
        targetGp: menuSize?.targetGp ? menuSize?.targetGp : (menuSize?.target * 10) / 11 - menuSize?.cogs,
        targetGpPercent: menuSize?.targetGpPercent
          ? menuSize?.targetGpPercent
          : (((menuSize?.cogs * 100) / menuSize?.target) * 10) / 11,
        difference: menuSize?.difference
          ? menuSize?.difference
          : Number(menuSize?.target ?? 0) - (Number(parent?.basePrice) + Number(menuSize?.additionalPrice ?? 0)),
        differencePercent: menuSize?.differencePercent
          ? menuSize?.differencePercent
          : ((Number(menuSize?.target ?? 0) - (Number(parent?.basePrice) + Number(menuSize?.additionalPrice ?? 0))) *
              100) /
            (Number(parent?.basePrice) + Number(menuSize?.additionalPrice ?? 0)),
        grossProfit: menuSize?.grossProfit
          ? menuSize?.grossProfit
          : ((Number(parent?.basePrice) + Number(menuSize?.additionalPrice ?? 0)) * 10) / 11 - menuSize?.cogs,
        grossProfitPercent: menuSize?.grossProfitPercent
          ? menuSize?.grossProfitPercent
          : 100 -
            (menuSize?.cogs * 100) / (((Number(parent?.basePrice) + Number(menuSize?.additionalPrice ?? 0)) * 10) / 11),
      },
    });
  }, [menuSize, parent, reset]);

  return (
    <TableRow hover>
      <FormProvider {...methods}>
        <FoodCategoryCogsReportIndividualItemForm />
      </FormProvider>
    </TableRow>
  );
}

export default FoodCategoryCogsReportItemSize;
