import { Box, Button, Grid, Modal, Typography } from '@mui/material';
import Iconify from 'components/Iconify';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAdditionalRunningCost } from 'redux/slices/operationalSlice';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  textAlign: 'center',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const AdditionalRunningCost = ({ runningCost, setTotalAdditionalRunningCost }) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const { additionalRunningCost, loading: additionalRunningCostLoading } = useSelector((state) => state.operational);

  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const deleteCost = () => {
    dispatch(
      deleteAdditionalRunningCost({
        additionalRunningCostId: deleteId,
        departmentId,
        runningCostId: runningCost?.id,
      })
    );
  };

  const findTotalAdditionalRunningCost = (additionalRunningCost) => {
    if (!additionalRunningCost) return 0;
    let totalAdditional = 0;
    Object.keys(additionalRunningCost).forEach((key) => {
      totalAdditional += additionalRunningCost[key].amount;
    });
    return totalAdditional;
  };

  setTotalAdditionalRunningCost(findTotalAdditionalRunningCost(additionalRunningCost));

  return (
    <>
      <Modal open={openDeleteModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to delete this cost?
          </Typography>
          <div id="modal-modal-description">
            <Button variant="contained" color="success" style={{ marginRight: '5px' }} onClick={() => deleteCost()}>
              Delete
            </Button>
            <Button variant="outlined" color="error" onClick={() => setOpenDeleteModal(false)}>
              Cancel
            </Button>
          </div>
        </Box>
      </Modal>
      <Box>
        {!additionalRunningCostLoading && additionalRunningCost?.length > 0 ? (
          <>
            <p>
              <b>Additional Cost</b>
              <hr />
            </p>
            <Grid container spacing={3}>
              {additionalRunningCost?.map((item) => (
                <Grid key={item.id} item xs={12} sm={6} style={{ display: 'flex' }}>
                  <div>
                    <b>{(item?.title).toLowerCase().charAt(0).toUpperCase() + (item?.title).slice(1)}: &nbsp;&nbsp; </b>
                    {item?.amount}
                  </div>
                  <Iconify
                    icon="charm:circle-cross"
                    height={15}
                    width={15}
                    color={'red'}
                    style={{ marginLeft: '5px', marginTop: '5px', cursor: 'pointer' }}
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setDeleteId(item?.id);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          ''
        )}
      </Box>
    </>
  );
};

export default AdditionalRunningCost;
