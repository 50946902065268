import { Box, Button, Divider, Grid, InputAdornment, Menu, MenuItem, Stack, TextField } from '@mui/material';
import { DatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import CustomTimePicker from 'components/CustomComponents/CustomTimePicker';
import Iconify from 'components/Iconify';
import { FormProvider, RHFSelect, RHFTextField } from 'components/hook-form';
import React, { useState } from 'react';
import { Controller, useForm, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { roasterModalFalseControlAction } from 'redux/slices/roasterSlice';
import { useSelector } from 'redux/store';
import PropTypes from 'prop-types';
import moment from 'moment';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import CustomMobileTimePicker from 'components/CustomComponents/CustomMobileTimePicker';
import { toast } from 'react-toastify';

RoasterAddForm.propTypes = {
  update: PropTypes.bool,
};

function RoasterAddForm({
  append,
  userInformation,
  roasterDate,
  update,
  disableTime = [],
  NextDayShiftHandler,
  nextDayLeave,
}) {
  const { modalControl, selectedRoaster } = useSelector((state) => state.roaster);
  const { break: userBreak } = useSelector((state) => state.store);
  const { open: modalOpen } = modalControl;
  const dispatch = useDispatch();

  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [value, setValue] = useState();

  const NewRoasterSchema = Yup.object().shape({
    startDateTime: Yup.date().required('Start time is Required').nullable(),
    endDateTime: Yup.date().required('End time is Required').nullable(),
    breakMins: Yup.string(),
    roleId: Yup.string().required('Role is Required'),
  });

  const defaultValues = {
    name: `${userInformation?.firstName} ${userInformation?.lastName}`,
    date: roasterDate || '',
    startDateTime: selectedRoaster?.isUpdate ? selectedRoaster?.updateCardIndexDetails?.data.startDateTime : null,
    endDateTime: selectedRoaster?.isUpdate ? selectedRoaster?.updateCardIndexDetails?.data.endDateTime : null,
    breakMins: selectedRoaster?.updateCardIndexDetails?.data?.breakMins || '',
    roleId: selectedRoaster?.updateCardIndexDetails?.data?.roleId || '',
    note: selectedRoaster?.updateCardIndexDetails?.data?.note || '',
  };

  const methods = useForm({ defaultValues, resolver: yupResolver(NewRoasterSchema) });
  const onCloseModal = () => {
    dispatch(roasterModalFalseControlAction());
  };

  const NewDataSubmit = (data) => {
    const isNextDay = moment(data?.endDateTime).isBefore(data?.startDateTime);
    const todayStart = moment(data?.startDateTime).format('HH:mm:ss');
    let todayEnd = moment(data?.endDateTime).format('HH:mm:ss');

    // if (!nextDayLeave) {
    if (isNextDay) {
      todayEnd = moment(data?.startDateTime).endOf('day').format('HH:mm:ss');
      const startOfTomorrow = moment(data?.endDateTime).startOf('day').format('HH:mm:ss');
      const endOfTomorrow = moment(data?.endDateTime).format('HH:mm:ss');

      const isNextDayMonday = moment(roasterDate).add(1, 'day').format('ddd') === 'Mon';
      const nextDay = moment(roasterDate).add(1, 'day').format('YYYY-MM-DD');

      if (isNextDayMonday) {
        // eslint-disable-next-line no-alert
        // return alert('End time must be before 12:00 ');
        return toast.error('End time must be before 12:00 midnight as next day is Monday');
      }
      if (nextDayLeave) {
        // eslint-disable-next-line no-alert
        // return alert('You can not add next day shift on leave day');
        return toast.error('End time must be before 12:00 midnight as next day has approved leave.');
      }
      NextDayShiftHandler({
        date: nextDay,
        startDateTime: `${nextDay}T${startOfTomorrow}`,
        endDateTime: `${nextDay}T${endOfTomorrow}`,
        breakMins: data?.breakMins,
        roleId: data?.roleId,
        note: data?.note,
      });
    }

    if (selectedRoaster?.isUpdate) {
      update(selectedRoaster?.updateCardIndexDetails?.cardIndex, {
        ...data,
        id: selectedRoaster?.updateCardIndexDetails?.data?.id,
        startDateTime: `${roasterDate}T${todayStart}`,
        endDateTime: `${roasterDate}T${todayEnd}`,
      });
      onCloseModal();
      return;
    }
    append({
      ...data,
      startDateTime: `${roasterDate}T${todayStart}`,
      endDateTime: `${roasterDate}T${todayEnd}`,
    });
    // }
    // else {
    //   if (isNextDay) {
    //     todayEnd = moment(data?.startDateTime).endOf('day').format('HH:mm:ss');
    //     const startOfTomorrow = moment(data?.endDateTime).startOf('day').format('HH:mm:ss');
    //     const endOfTomorrow = moment(data?.endDateTime).format('HH:mm:ss');

    //     const isNextDayMonday = moment(roasterDate).add(1, 'day').format('ddd') === 'Mon';
    //     const nextDay = moment(roasterDate).add(1, 'day').format('YYYY-MM-DD');

    //     if (isNextDayMonday) {
    //       // eslint-disable-next-line no-alert
    //       return alert('End time must be before 12:00 ');
    //     }
    //   }

    //   if (selectedRoaster?.isUpdate) {
    //     update(selectedRoaster?.updateCardIndexDetails?.cardIndex, {
    //       ...data,
    //       id: selectedRoaster?.updateCardIndexDetails?.data?.id,
    //       startDateTime: `${roasterDate}T${todayStart}`,
    //       endDateTime: `${roasterDate}T${todayEnd}`,
    //     });
    //     onCloseModal();
    //     return;
    //   }
    //   append({
    //     ...data,
    //     startDateTime: `${roasterDate}T${todayStart}`,
    //     endDateTime: `${roasterDate}T${todayEnd}`,
    //   });
    // }
    onCloseModal();
  };

  return (
    <CustomOutletModal
      open={modalOpen}
      zIndex="1600"
      // onClose={onCloseModal}
      subtitle={`${moment(roasterDate).format('ddd, DD-MMM')} ${userInformation?.firstName} ${
        userInformation?.lastName
      }`}
      title={selectedRoaster?.isUpdate ? 'Update User Shift' : 'Create New Shift'}
    >
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(NewDataSubmit)}>
        <Box className="roasterAddFormContainer">
          <Box className="roasterAddFormContent">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomMobileTimePicker name="startDateTime" label="Start Time*" />
              </Grid>
              <Grid item xs={6}>
                <CustomMobileTimePicker name="endDateTime" label="End Time*" />
              </Grid>
              <Grid item xs={6}>
                <RHFSelect
                  name={`breakMins`}
                  label="Break Time"
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                >
                  <Divider />
                  <MenuItem value={0}>No Break</MenuItem>
                  {userBreak?.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.minutes}
                      // onClick={() => handleSelectService(index, option.name)}
                    >
                      {option.minutes} minutes
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>

              <Grid item xs={6}>
                <RHFSelect
                  name={`roleId`}
                  label="Select Roles*"
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                >
                  <Divider />
                  {userInformation?.roles?.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                      style={{
                        background: `${option?.colorCode}`,
                        color: 'white',
                        margin: '0.5rem',
                        padding: '0.5rem',
                      }}
                      // onClick={() => handleSelectService(index, option.name)}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>

              <Grid item xs={12}>
                <RHFTextField name="note" label="Note" />
              </Grid>

              <Grid item xs={12}>
                <Box
                  sx={{
                    marginLeft: 'auto',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '1rem',
                    paddingLeft: '2rem',
                  }}
                >
                  <Button variant="text" size="large" style={{ color: 'red' }} onClick={onCloseModal}>
                    Cancel
                  </Button>
                  <Button variant="contained" size="large" color="secondary" type="submit">
                    {selectedRoaster?.isUpdate ? 'Update' : 'Create'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </FormProvider>
    </CustomOutletModal>
  );
}

export default RoasterAddForm;
