import { Box, Button, Stack } from '@mui/material';
import CustomMultipleAutoCompleteField from 'components/CustomComponents/CustomAutoCompleteField';
import PropTypes from 'prop-types';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { FormProvider, RHFTextField } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { UpdateTaskList } from 'redux/slices/storeDocumentSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { getAllUserss } from 'redux/slices/userSlice';

TaskStartModal.propTypes = {
  start: PropTypes.bool,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  item: PropTypes.object,
  markComplete: PropTypes.bool,
  deleteTask: PropTypes.bool,
};

function TaskStartModal({ deleteTask, markComplete, start, open, setOpen, item }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const schema = yup.object().shape({
    // eslint-disable-next-line no-nested-ternary
    user: markComplete ? yup.object().required('') : yup.object().nullable(),
    note: !start && yup.string().required(''),
  });

  const { allUsers } = useSelector((state) => state.user);
  const defaultValues = {
    user: null,
    note: '',
  };
  const methods = useForm({ defaultValues, mode: 'onChange', resolver: yupResolver(schema) });

  const { handleSubmit } = methods;

  const startSubmitHandler = (data) => {
    if (start) {
      const finalData = { ...data, id: item.id, staffId: data?.user?.id, departmentId, actionType: 'START' };
      dispatch(UpdateTaskList({ finalData, setOpen }));
    } else if (markComplete) {
      const finalData = { ...data, id: item.id, staffId: data?.user?.id, departmentId, actionType: 'COMPLETE' };
      dispatch(UpdateTaskList({ finalData, setOpen }));
    } else if (deleteTask) {
      const finalData = { ...data, id: item.id, departmentId, actionType: 'DELETE' };
      dispatch(UpdateTaskList({ finalData, setOpen }));
    }
  };

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getAllUserss({ departmentId, statusList: ['ACTIVE'] }));
  }, [departmentId, dispatch]);

  return (
    <>
      <CustomOutletModal
        open={open}
        zIndex="1600"
        onClose={() => setOpen(false)}
        // eslint-disable-next-line no-nested-ternary
        title={start ? 'Start Task' : markComplete ? 'Mark The Task As Completed' : 'Delete Task'}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(startSubmitHandler)}>
          <Stack spacing={2}>
            {markComplete && (
              <CustomMultipleAutoCompleteField
                name="user"
                label="Select Users"
                options={allUsers?.users}
                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{ width: '20rem' }}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img width="20" src={option.thumbnailURL} alt="" />
                    {option.firstName} {option.lastName}
                  </Box>
                )}
              />
            )}
            {markComplete && <RHFTextField name="note" label="Note*" multiline rows={3} />}
            {deleteTask && <RHFTextField name="note" label="Note*" multiline rows={3} />}
          </Stack>
          <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
            {start && (
              <Button variant="contained" type="submit" color="primary">
                Start
              </Button>
            )}
            {markComplete && (
              <Button variant="contained" type="submit" color="secondary">
                Mark Complete
              </Button>
            )}
            {deleteTask && (
              <Button variant="contained" type="submit" color="error">
                Delete
              </Button>
            )}
            <Button variant="outlined" color="error" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </Stack>
        </FormProvider>
      </CustomOutletModal>
    </>
  );
}

export default TaskStartModal;
