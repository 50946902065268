import { Box, Card, MenuItem, Stack } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import { FormProvider } from 'components/hook-form';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import CustomSelectField from 'components/CustomComponents/CustomSelectField';
import VehiclesAddEditForm from './vehicles/VehiclesAddEditForm';
import FirstAidAddEditForm from './firstAid/FirstAidAddEditForm';
import EquipmentAddEditForm from './equipment/EquipmentAddEditForm';

function AddNewLogs({ open, setOpen }) {
  const documentType = [
    { id: 1, title: 'Vehicles Log' },
    { id: 2, title: 'FirstAid Log' },
    { id: 3, title: 'Equipment Log' },
  ];

  const methods = useForm();
  const watchType = methods.watch('documentType');

  return (
    <CustomDrawer open={open} setOpen={setOpen} title={`Add New Log`} PaperProps={{ sx: { innerWidth: '50%' } }}>
      <Box style={{ width: '700px' }}>
        <FormProvider methods={methods}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={2} sx={{ width: 1 }}>
              <CustomSelectField name="documentType" label="Select Log Type" sx={{ width: '15rem' }}>
                {documentType?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.title}
                  </MenuItem>
                ))}
              </CustomSelectField>
              {watchType && (
                <>
                  {watchType === 1 && <VehiclesAddEditForm setOpen={setOpen} />}
                  {watchType === 2 && <FirstAidAddEditForm setOpen={setOpen} />}
                  {watchType === 3 && <EquipmentAddEditForm setOpen={setOpen} />}
                </>
              )}
            </Stack>
          </Card>
        </FormProvider>
      </Box>
    </CustomDrawer>
  );
}

AddNewLogs.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default AddNewLogs;
