import { TableCell, TableRow } from '@mui/material';
import CustomMobileTimePicker from 'components/CustomComponents/CustomMobileTimePicker';
import { RHFCheckbox } from 'components/hook-form';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

function TradingHoursCardField({ mainIndex, obj, choosenType }) {
  const { watch, setValue } = useFormContext();
  const isClosedWatch = watch(`data.${mainIndex}.tradingHours.${choosenType}.[0].isClosed`);

  useEffect(() => {
    if (!isClosedWatch) return;
    setValue(`data.${mainIndex}.tradingHours.${choosenType}.[0].startTime`, null);
    setValue(`data.${mainIndex}.tradingHours.${choosenType}.[0].endTime`, null);
  }, [choosenType, isClosedWatch, mainIndex, setValue]);
  return (
    <TableRow key={obj?.fieldID} style={{ overflow: 'hidden' }}>
      <TableCell style={{ width: '18rem' }}>{obj?.orderType}</TableCell>
      <TableCell style={{ width: '18rem' }}>
        <CustomMobileTimePicker
          name={`data.${mainIndex}.tradingHours.${choosenType}.[0].startTime`}
          disabled={isClosedWatch}
          label="Start Time*"
        />
      </TableCell>
      <TableCell style={{ width: '18rem' }}>
        <CustomMobileTimePicker
          name={`data.${mainIndex}.tradingHours.${choosenType}.[0].endTime`}
          label="End Time*"
          disabled={isClosedWatch}
        />
      </TableCell>
      <TableCell style={{ width: '18rem' }}>
        <RHFCheckbox name={`data.${mainIndex}.tradingHours.${choosenType}.[0].isClosed`} label="Is Closed" />
      </TableCell>
    </TableRow>
  );
}

export default TradingHoursCardField;
