import Page from 'components/Page';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changePosConfigCurrentTab } from 'redux/slices/posConfigSlice';
import PosConfigHeader from './PosConfigHeader';
import PosConfigBody from './PosConfigBody';

function PosConfigIndex() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changePosConfigCurrentTab(0));
  }, [dispatch]);
  return (
    <Page title="Store: POS config">
      <PosConfigHeader />
      <PosConfigBody />
    </Page>
  );
}

export default PosConfigIndex;
