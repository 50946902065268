import { Autocomplete, Button, Grid, InputAdornment, Stack, TextField, Typography } from '@mui/material';
import Iconify from 'components/Iconify';
import { RHFTextField } from 'components/hook-form';
import { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

function RecipeCreateFormSingleIngredient({ item, index, RemoveIngredient }) {
  const { control, watch, setValue, getValues } = useFormContext();
  const { allIngredientsWithPrice } = useSelector((state) => state.ingredients);

  const watchSingleIngredient = useWatch({
    control,
    name: `recipeIngredients.${index}.singleIngredientId`,
  });
  console.log('🚀 => RecipeCreateFormSingleIngredient => watchSingleIngredient=>', watchSingleIngredient);

  const watchQtyUsed = useWatch({
    control,
    name: `recipeIngredients.${index}.qtyUsed`,
  });

  const findPriceOfIngredient = (id, qty) => {
    if (!id?.id) return 0;
    if (!qty) return 0;
    if (watchSingleIngredient?.packSize > 0) {
      const ingredientPrice = watchSingleIngredient?.unitPrice ?? 0;
      const packSize = watchSingleIngredient?.packSize ?? 0;
      const unitWeight = watchSingleIngredient?.unitWeight ?? 0;

      // const actualQty = qty * unitWeight;
      // const recipeCostOfIngredient = (ingredientPrice / unitWeight) * actualQty / 1000;
      const recipeCostOfIngredient = (ingredientPrice / packSize) * qty;
      return recipeCostOfIngredient.toFixed(3);
    }
    const ingredientPrice = allIngredientsWithPrice?.ingredients?.find((item) => item.id === id?.id)?.unitPrice ?? 0;

    const ingredientSize = allIngredientsWithPrice?.ingredients?.find((item) => item.id === id?.id)?.unitWeight ?? 0;
    const recipeCostOfIngredient = (ingredientPrice / ingredientSize) * (qty / 1000);
    return recipeCostOfIngredient.toFixed(3);
  };

  useEffect(() => {
    if (!watchSingleIngredient?.id) return;
    if (!watchQtyUsed) return;
    if (watchSingleIngredient?.packSize > 0) {
      const ingredientPrice = watchSingleIngredient?.unitPrice ?? 0;
      const packSize = watchSingleIngredient?.packSize ?? 0;
      const unitWeight = watchSingleIngredient?.unitWeight ?? 0;
      // console.log('datas', ingredientPrice, packSize, unitWeight, watchQtyUsed);

      // const recipeCostOfIngredient = (ingredientPrice / packSize) * (watchQtyUsed / unitWeight);

      const recipeCostOfIngredient = (ingredientPrice / packSize) * watchQtyUsed;

      setValue(`recipeIngredients.${index}.costOfIngredient`, recipeCostOfIngredient.toFixed(3));
      // const qtyUsed = packSize * unitWeight;
      // setValue(`recipeIngredients.${index}.qtyUsed`, qtyUsed);

      // const recipeCostOfIngredient = (ingredientPrice / packSize) * ();
    } else {
      const ingredientPrice =
        allIngredientsWithPrice?.ingredients?.find((item) => item.id === watchSingleIngredient?.id)?.unitPrice ?? 0;

      const ingredientSize =
        allIngredientsWithPrice?.ingredients?.find((item) => item.id === watchSingleIngredient?.id)?.unitWeight ?? 0;
      const recipeCostOfIngredient = (ingredientPrice / ingredientSize) * (watchQtyUsed / 1000);

      // const recipeCostOfIngredient = (ingredientPrice / packSize) * (watchQtyUsed / unitWeight);

      setValue(`recipeIngredients.${index}.costOfIngredient`, recipeCostOfIngredient.toFixed(3));
    }
  }, [watchSingleIngredient, watchQtyUsed, allIngredientsWithPrice?.ingredients, setValue, index]);
  return (
    <Grid item xs={12} key={item.fieldId}>
      <Stack direction="row" gap="1rem">
        <Controller
          control={control}
          name={`recipeIngredients.${index}.singleIngredientId`}
          render={({ field: { onChange, ...field } }) => (
            <Autocomplete
              label="Select Ingredient"
              disableClearable
              onChange={(event, data, reason, details) => {
                onChange(data);
                //  ingredientOnChangeHandler(event, data, reason, details);
              }}
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, value) => option?.id === value?.id}
              {...field}
              options={allIngredientsWithPrice?.ingredients || []}
              renderInput={(params) => <TextField {...params} label="Select Ingredient" style={{ width: '12rem' }} />}
            />
          )}
        />
        <RHFTextField
          name={`recipeIngredients.${index}.qtyUsed`}
          label="Quantity"
          style={{ width: '8rem' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {/* eslint-disable-next-line no-nested-ternary */}
                {watchSingleIngredient?.packSize ? (watchSingleIngredient?.packSize === 0 ? 'gm' : 'pcs') : 'gm'}
              </InputAdornment>
            ),
          }}
        />
        <Button variant="text" disabled>
          $ {findPriceOfIngredient(watchSingleIngredient, watchQtyUsed)}
          {/* {getValues(`recipeIngredients.${index}.costOfIngredient`)} */}
        </Button>

        <Button color="error" variant="text" onClick={() => RemoveIngredient(item, index)}>
          <Iconify icon="solar:trash-bin-minimalistic-2-bold" height={20} width={20} />
        </Button>
      </Stack>
    </Grid>
  );
}

export default RecipeCreateFormSingleIngredient;
