import { InputAdornment, TableCell, TableRow, Typography } from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

function FoodMenuSizeExtraPriceSingleSize({ menu, size, index }) {
  const [active, setActive] = useState(false);

  const { control } = useFormContext();

  const watchType = useWatch({
    control,
    name: 'type',
  });
  const watchPrice = useWatch({
    control,
    name: `menuSizes[${index}]`,
  });

  return (
    <TableRow key={size?.id}>
      <TableCell style={{ width: '100px' }}>
        <div>
          <div>{size?.name}</div>
        </div>
        <div style={{ marginTop: '-6px', fontSize: '.7rem' }}>$ {size?.additionalPrice}</div>
      </TableCell>
      <TableCell style={{ width: '150px' }}>
        {watchType === 'DELIVERY' && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <RHFTextField
              control={control}
              name={`menuSizes[${index}].additionalPriceDelivery`}
              size="small"
              onFocus={() => setActive(true)}
              onBlur={() => setActive(false)}
              style={{ borderRadius: '10px', backgroundColor: active ? `white` : ' rgba(238, 238, 238, 0.8)' }}
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              InputLabelProps={{ shrink: true }}
              sx={{ width: '5rem' }}
            />
            <Typography style={{ marginLeft: '10px', fontSize: '.8rem', marginTop: '10px' }}>
              ${' '}
              {/* {typeof watchPrice?.additionalPriceDelivery === 'number' &&
                Number(watchPrice?.additionalPriceDelivery ?? 0) +
                  Number(watchPrice?.additionalPrice ?? 0) +
                  Number(menu?.basePrice ?? 0)} */}
              {(
                Number(watchPrice?.additionalPriceDelivery ?? 0) +
                Number(watchPrice?.additionalPrice ?? 0) +
                Number(menu?.basePrice ?? 0)
              ).toFixed(2)}
            </Typography>
          </div>
        )}
        {watchType === 'PICKUP' && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <RHFTextField
              control={control}
              name={`menuSizes[${index}].additionalPricePickup`}
              size="small"
              onFocus={() => setActive(true)}
              onBlur={() => setActive(false)}
              style={{ borderRadius: '10px', backgroundColor: active ? `white` : ' rgba(238, 238, 238, 0.8)' }}
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              InputLabelProps={{ shrink: true }}
              sx={{ width: '5rem' }}
            />
            <Typography style={{ marginLeft: '10px', fontSize: '.8rem', marginTop: '10px' }}>
              ${' '}
              {(
                Number(watchPrice?.additionalPricePickup ?? 0) +
                Number(watchPrice?.additionalPrice ?? 0) +
                Number(menu?.basePrice ?? 0)
              ).toFixed(2)}
            </Typography>
          </div>
        )}
        {watchType === 'TABLE' && (
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <RHFTextField
              control={control}
              name={`menuSizes[${index}].additionalPriceTable`}
              size="small"
              onFocus={() => setActive(true)}
              onBlur={() => setActive(false)}
              style={{ borderRadius: '10px', backgroundColor: active ? `white` : ' rgba(238, 238, 238, 0.8)' }}
              InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              InputLabelProps={{ shrink: true }}
              sx={{ width: '5rem' }}
            />
            <Typography style={{ marginLeft: '10px', fontSize: '.8rem', marginTop: '10px' }}>
              ${' '}
              {(
                Number(watchPrice?.additionalPriceTable ?? 0) +
                Number(watchPrice?.additionalPrice ?? 0) +
                Number(menu?.basePrice ?? 0)
              ).toFixed(2)}
            </Typography>
          </div>
        )}
      </TableCell>
      {/* <TableCell style={{ width: '50px' }}>abcd</TableCell> */}
    </TableRow>
  );
  // <TableRow key={size?.id} hover>
  //   <TableCell>
  //     <span>{size?.name}</span> <br /> <span style={{ fontSize: '0.7rem' }}>$ {size?.additionalPrice}</span>
  //   </TableCell>
  //   <TableCell style={{ width: '150px' }}>
  //     <RHFTextField
  //       control={control}
  //       name={`sizes[${index}].extraPrice.${watchType}`}
  //       size="small"
  //       onFocus={() => setActive(true)}
  //       onBlur={() => setActive(false)}
  //       style={{ borderRadius: '10px', backgroundColor: active ? `white` : ' rgba(238, 238, 238, 0.8)' }}
  //       InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
  //       InputLabelProps={{ shrink: true }}
  //     />
  //   </TableCell>
  // </TableRow>
}

export default FoodMenuSizeExtraPriceSingleSize;
