import { TextField } from '@mui/material';
import { MobileTimePicker, TimePicker } from '@mui/x-date-pickers';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

function CustomMobileTimePicker({ label = 'Time Picker', name, ...other }) {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const [value, setValue] = useState();

  return (
    <Controller
      name={name}
      control={control}
      errors={errors}
      render={({ field, fieldState: { error } }) => (
        <MobileTimePicker
          name={name}
          label={label}
          value={value}
          onChange={(newValue) => {
            setValue(newValue);
          }}
          DialogProps={{
            sx: { zIndex: '2000' },
          }}
          {...field}
          {...other}
          renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} />}
        />
      )}
    />
  );
}

export default CustomMobileTimePicker;
