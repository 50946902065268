import { Card, Divider, Grid, Stack, Typography } from '@mui/material';
import { convertDatetoString } from 'utils/customDateAndTimeFormat';
import { useFormContext } from 'react-hook-form';
// import SVGtoPDF from 'element-pdfdoc';
import ProfileUser from '../../../assets/profileUser.png';

function StaffCreateActiveStepperConfirm() {
  const { getValues, setValue } = useFormContext();
  const values = getValues();
  const fullName = `${values?.firstName} ${values?.middleName || ''} ${values?.lastName} `;

  const getLastDetails = (data) => data?.split('/').slice(-1)[0];

  const getVisaURL = getValues('visaURL') ? getLastDetails(getValues('visaURL')) : '';
  const getWorkingRightURL = getValues('workingRightsURL') ? getLastDetails(getValues('workingRightsURL')) : '';
  const getInsuranceURL = getValues('insuranceURL') ? getLastDetails(getValues('insuranceURL')) : '';
  const getRezoPaperWorkURL = getValues('rezoPaperWorkURL') ? getLastDetails(getValues('rezoPaperWorkURL')) : '';

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3.2}>
          <Card>
            <Stack alignItems={'center'} py={3}>
              <Stack
                gap="1rem"
                width="10rem"
                height="10rem"
                borderRadius={'50%'}
                padding={'8px'}
                border="1px dashed rgba(145, 158, 171, 0.32)"
                alignItems="center"
                justifyContent={'center'}
              >
                <img src={values?.image?.preview || ProfileUser} alt="welcome" style={{ borderRadius: '50%' }} />
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={8.8}>
          <Card>
            <Stack direction="column" gap="0.5rem">
              <Stack p={3}>
                <Typography component="h2" fontSize="1.2rem" fontWeight={'700'} pb="0.5rem">
                  User Information
                </Typography>

                <Stack direction={{ xs: 'column', md: 'row' }}>
                  <Stack width={{ xs: '100%', md: '50%' }}>
                    <Typography component="p" fontWeight="600">
                      Full Name :<Typography component="span"> {fullName}</Typography>
                    </Typography>

                    <Typography component="p" fontWeight="600">
                      Date of Birth:<Typography component="span"> {convertDatetoString(values?.dob)}</Typography>
                    </Typography>
                  </Stack>
                  <Stack width={{ xs: '100%', md: '50%' }}>
                    <Typography component="p" fontWeight="600">
                      Gender:<Typography component="span"> {values?.gender}</Typography>
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Divider />

              <Stack p={3}>
                <Typography component="h2" fontSize="1.2rem" fontWeight={'700'} pb="0.5rem">
                  Contact Information
                </Typography>

                <Stack direction={{ xs: 'column', md: 'row' }}>
                  <Stack width={{ xs: '100%', md: '50%' }}>
                    <Typography component="p" fontWeight="600">
                      Email :<Typography component="span"> {values?.email}</Typography>
                    </Typography>
                    <Typography component="p" fontWeight="600">
                      Emergency Contact:<Typography component="span"> {values?.emergencyContactName}</Typography>
                    </Typography>
                  </Stack>
                  <Stack width={{ xs: '100%', md: '50%' }}>
                    <Typography component="p" fontWeight="600">
                      Phone Number :<Typography component="span"> {values?.phone}</Typography>
                    </Typography>

                    <Typography component="p" fontWeight="600">
                      Emergency ContactNo:<Typography component="span"> {values?.emergencyContactNo}</Typography>
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Divider />
              <Stack p={3}>
                <Typography component="h2" fontSize="1.2rem" fontWeight={'700'} pb="0.5rem">
                  Addresss
                </Typography>

                <Stack direction={{ xs: 'column', md: 'row' }}>
                  <Stack width={{ xs: '100%', md: '50%' }}>
                    <Typography component="p" fontWeight="600">
                      Address:<Typography component="span"> {values?.address}</Typography>
                    </Typography>
                    <Typography component="p" fontWeight="600">
                      State :<Typography component="span"> {values?.state}</Typography>
                    </Typography>
                    <Typography component="p" fontWeight="600">
                      Zipcode :<Typography component="span"> {values?.zipcode}</Typography>
                    </Typography>
                  </Stack>
                  <Stack width={{ xs: '100%', md: '50%' }}>
                    <Typography component="p" fontWeight="600">
                      Suburb :<Typography component="span"> {values?.suburb}</Typography>
                    </Typography>

                    <Typography component="p" fontWeight="600">
                      Country:<Typography component="span"> {values?.country}</Typography>
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Divider />
              <Stack p={3}>
                <Typography component="h2" fontSize="1.2rem" fontWeight={'700'} pb="0.5rem">
                  Payment
                </Typography>

                <Stack direction={{ xs: 'column', md: 'row' }}>
                  <Stack width={{ xs: '100%', md: '50%' }}>
                    <Typography component="p" fontWeight="600">
                      Account Name:<Typography component="span"> {values?.accountName}</Typography>
                    </Typography>

                    <Typography component="p" fontWeight="600">
                      BSB:<Typography component="span"> {values?.bsb}</Typography>
                    </Typography>
                  </Stack>
                  <Stack width={{ xs: '100%', md: '50%' }}>
                    <Typography component="p" fontWeight="600">
                      Account Number:<Typography component="span"> {values?.accountNo}</Typography>
                    </Typography>
                    <Typography component="p" fontWeight="600">
                      Super No:<Typography component="span"> {values?.superNumber}</Typography>
                    </Typography>
                  </Stack>
                </Stack>
                <Stack width={{ xs: '100%', md: '50%' }} pt={2}>
                  <Typography component="p" fontWeight="600">
                    TFN Status :<Typography component="span"> {values?.tfnStatus || ''}</Typography>
                  </Typography>
                  <Typography component="p" fontWeight="600">
                    Tfn No:<Typography component="span"> {values?.tfnNo}</Typography>
                  </Typography>

                  <Typography component="p" fontWeight="600">
                    Super Name:<Typography component="span"> {values?.superName}</Typography>
                  </Typography>
                  <Typography component="p" fontWeight="600">
                    Membership Fund:<Typography component="span"> {values?.superNumber}</Typography>
                  </Typography>
                </Stack>
              </Stack>
              <Divider />
              <Stack p={3}>
                <Typography component="h2" fontSize="1.2rem" fontWeight={'700'} pb="0.5rem">
                  Paperwork
                </Typography>

                <Stack>
                  <Stack gap="0.5rem" my={1} bgcolor={'rgba(145, 158, 171, 0.12)'} borderRadius={'10px'} p="1rem">
                    <Typography component="p" fontWeight="600">
                      International Student:
                      <Typography component="span"> {values?.isInternationalStudent ? 'Yes' : 'No'}</Typography>
                    </Typography>
                    {values?.isInternationalStudent && (
                      <>
                        <Stack
                          gap="1rem"
                          width="15rem"
                          height="15rem"
                          // borderRadius={'50%'}
                          padding={'8px'}
                          border="1px dashed rgba(145, 158, 171, 0.32)"
                          alignItems="center"
                          justifyContent={'center'}
                        >
                          <figure>
                            <figcaption>Passport</figcaption>
                            <img
                              src={values?.passport?.preview || values?.passportURL}
                              alt="passport"
                              style={{
                                width: '15rem',
                                height: '15rem',
                                objectFit: 'cover',
                                objectPosition: 'center',
                              }}
                            />
                          </figure>
                        </Stack>
                        <Typography component="p" fontWeight="600" mt={2}>
                          Visa :<Typography component="span"> {values?.visa?.name || getVisaURL || ''}</Typography>
                        </Typography>
                        <Typography component="p" fontWeight="600">
                          Working Right Permission :
                          <Typography component="span">
                            {' '}
                            {values?.workingRights?.name || getWorkingRightURL || ''}
                          </Typography>
                        </Typography>
                      </>
                    )}
                  </Stack>
                  <Stack gap="0.5rem" my={1} bgcolor={'rgba(145, 158, 171, 0.12)'} borderRadius={'10px'} p="1rem">
                    <Typography component="p" fontWeight="600">
                      Delivery Driver :
                      <Typography component="span"> {values?.isDeliveryDriver ? 'Yes' : 'No'}</Typography>
                    </Typography>
                    {values?.isDeliveryDriver && (
                      <>
                        <Stack
                          gap="1rem"
                          width="15rem"
                          height="15rem"
                          // borderRadius={'50%'}
                          padding={'8px'}
                          border="1px dashed rgba(145, 158, 171, 0.32)"
                          alignItems="center"
                          justifyContent={'center'}
                        >
                          <figure>
                            <figcaption>Driving License</figcaption>
                            <img
                              src={values?.drivingLicense?.preview || values?.drivingLicenseURL}
                              alt="Driving License"
                              style={{
                                width: '15rem',
                                height: '15rem',
                                objectFit: 'cover',
                                objectPosition: 'center',
                              }}
                            />
                          </figure>
                        </Stack>
                        <Typography component="p" fontWeight="600" mt={2}>
                          Insurance :
                          <Typography component="span" bgcolor="primary">
                            {' '}
                            {values?.insurance?.name || getInsuranceURL || ''}
                          </Typography>
                        </Typography>
                        <Typography component="p" fontWeight="600">
                          Rezo PaperWork :
                          <Typography component="span">
                            {' '}
                            {values?.rezoPaperWork?.name || getRezoPaperWorkURL || ''}
                          </Typography>
                        </Typography>
                      </>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default StaffCreateActiveStepperConfirm;
