import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Stack } from '@mui/material';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { FormProvider, RHFTextField } from 'components/hook-form';
import React, { useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { updateSalesForecast } from 'redux/slices/salesSlice';
import { useDispatch, useSelector } from 'redux/store';
import { convertStringDatetoDate } from 'utils/customDateAndTimeFormat';
import * as Yup from 'yup';

function RoasterSalesForecastUpdate({ open, onClose, currentWeek, getUpdatedValue }) {
  const { onlinePlatforms, salesForecast } = useSelector((state) => state.sales);
  const { selectedDepartment } = useSelector((state) => state.userDepartment);
  const formatCurrentWeek = convertStringDatetoDate(currentWeek, 'ddd,DD MMM', 'ddd').toLocaleUpperCase();

  const findSalesForeCastDay = salesForecast?.find((obj) => obj.day === formatCurrentWeek);

  //   checking if findSalesForecastday has platforms available.. if not inputing if yes not adding
  const managePlatformForecasts = onlinePlatforms?.map((obj) => {
    const checkForecastId = findSalesForeCastDay?.platformForecasts?.find((el) => el.onlinePlatformId === obj.id);
    if (checkForecastId) {
      return { ...checkForecastId };
    }
    return {
      onlinePlatformId: obj.id,
      amount: 0,
      platformName: obj.platformName,
      platformCommission: obj.commissionPercent,
    };
  });

  const NewForeCastSchema = Yup.object().shape({
    platformForecasts: Yup.array().of(
      Yup.object().shape({
        amount: Yup.number(),
      })
    ),
  });

  const defaultValues = useMemo(
    () => ({ ...findSalesForeCastDay, platformForecasts: managePlatformForecasts }),
    [findSalesForeCastDay, managePlatformForecasts]
  );

  const methods = useForm({ defaultValues, resolver: yupResolver(NewForeCastSchema) });
  const { control, getValues, handleSubmit, watch } = methods;

  const { fields } = useFieldArray({
    control,
    name: 'platformForecasts',
    keyName: 'foreCastKey',
  });
  const values = watch();
  const SalesForecastUpdateHandler = (data) => {
    const finalData = {
      ...data,
      departmentId: selectedDepartment?.id,
    };

    getUpdatedValue(finalData);
  };

  return (
    <div>
      <CustomOutletModal title={'Update Sales Forecast'} zIndex={1600} open={open} onClose={onClose}>
        <Stack flexDirection={{ xs: 'column', md: 'row' }} gap="1rem" mb="1.5rem">
          <p style={{ fontWeight: '600' }}>Day : {getValues('day')}</p>
        </Stack>
        <FormProvider methods={methods} onSubmit={handleSubmit(SalesForecastUpdateHandler)}>
          <Stack mb="2rem">
            <RHFTextField name="storeSales" label="Store Sales" sx={{ width: 'min(15rem,50%)' }} type="number" />
            {values?.storeSales && (
              <p style={{ marginLeft: '3rem', fontSize: '.7rem', color: 'red' }}>
                (Ex.gst: {(values?.storeSales - (values?.storeSales * 10) / 100).toFixed(2)})
              </p>
            )}
          </Stack>
          <Grid container spacing={1.5}>
            {fields?.map((el, index) => (
              <Grid key={el.foreCastKey} item md={6} xs={12}>
                <RHFTextField
                  name={`platformForecasts.${index}.amount`}
                  label={`${el?.platformName} Amount`}
                  type="number"
                />
                {el?.amount > 0 ? (
                  <p style={{ textAlign: 'center', fontSize: '.7rem', color: 'red' }}>
                    (Ex.gst:{' '}
                    {(
                      values?.platformForecasts[index]?.amount -
                      (values?.platformForecasts[index]?.amount *
                        onlinePlatforms?.find((obj) => obj.id === el?.onlinePlatformId)?.commissionPercent) /
                        100
                    ).toFixed(2)}
                    )
                  </p>
                ) : (
                  ''
                )}
              </Grid>
            ))}
          </Grid>

          <div style={{ height: '2rem' }} />
          <Stack alignItems="flex-end">
            <Box>
              <Button variant="outlined" color="error" onClick={onClose} style={{ marginRight: '4px' }}>
                Cancel
              </Button>
              <Button variant="contained" type="submit">
                Update
              </Button>
            </Box>
          </Stack>
        </FormProvider>
      </CustomOutletModal>
    </div>
  );
}

export default RoasterSalesForecastUpdate;
