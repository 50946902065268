import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import { successToastTime, errorToastTime } from 'config';

export const createFoodRecipeMenu = createAsyncThunk('foodConf/createFoodRecipeMenu ', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/receipe/handleReceipe`, props.finalData);
    if (response.status === 200) {
      toast.success('Receipe Updated Successfully.', { autoClose: successToastTime });
      thunkAPI.dispatch(
        getFoodRecipeDetails({ menuId: props.finalData.menuId, departmentId: props.finalData.departmentId })
      );
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getFoodRecipeDetails = createAsyncThunk('foodConf/getFoodRecipeDetails', async (props) => {
  try {
    const response = await apiURL.get(`/receipe/handleReceipe/${props.menuId}/${props.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getFoodRecipeSummary = createAsyncThunk('foodConf/getFoodRecipeSummary', async (props) => {
  try {
    const response = await apiURL.get(`/receipe/receipesSummary/${props.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateFoodRecipeSummary = createAsyncThunk('foodConf/updateFoodRecipeSummary', async (props) => {
  try {
    const response = await apiURL.put(`/receipe/updateTarget`, props.finalData);
    if (response.status === 200) {
      toast.success('Summary Updated Successfully', { autoClose: successToastTime });
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

const FoodRecipeSlice = createSlice({
  name: 'foodRecipe',
  initialState: {
    loading: false,
    postLoading: false,
    detailsLoading: false,
    summaryLoading: false,
    currentTab: 0,
    allRecipeofMenu: {},
    recipeDetails: {},
    recipeSummary: {},
  },

  reducers: {
    changeFoodMenuCurrentTab(state, action) {
      state.currentTab = action.payload;
    },

    clearAllRecipeofMenu(state) {
      state.allRecipeofMenu = {};
    },
  },

  extraReducers: {
    [getFoodRecipeDetails.pending]: (state) => {
      state.loading = true;
      state.allRecipeofMenu = {};
    },
    [getFoodRecipeDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.allRecipeofMenu = action.payload;
    },
    [getFoodRecipeDetails.rejected]: (state) => {
      state.loading = false;
    },
    [createFoodRecipeMenu.pending]: (state) => {
      state.postLoading = true;
    },
    [createFoodRecipeMenu.fulfilled]: (state) => {
      state.postLoading = false;
      state.allRecipeofMenu = {};
    },
    [createFoodRecipeMenu.rejected]: (state) => {
      state.postLoading = false;
    },

    [getFoodRecipeSummary.pending]: (state) => {
      state.summaryLoading = true;
    },
    [getFoodRecipeSummary.fulfilled]: (state, action) => {
      state.summaryLoading = false;
      state.recipeSummary = action.payload;
    },
    [getFoodRecipeSummary.rejected]: (state) => {
      state.summaryLoading = false;
    },
  },
});

export const { clearAllRecipeofMenu } = FoodRecipeSlice.actions;
export default FoodRecipeSlice.reducer;
