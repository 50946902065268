import { Box } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import React from 'react';
import { PATH_DASHBOARD } from 'routes/paths';

export default function SalesHeader() {
  return (
    <Box>
      <HeaderBreadcrumbs
        heading="Sales Report"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Sales' },
          // { name: 'Report' }
        ]}
      />
    </Box>
  );
}
