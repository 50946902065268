import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { getFoodCategory } from 'redux/slices/menuEngineeringSlice';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';
import DealOfferAddForm from './DealOfferAddForm';

CreateDealOffer.propTypes = {
  handleClose: PropTypes.func,
};

function CreateDealOffer({ handleClose }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getFoodCategory({ departmentId }));
  }, [departmentId, dispatch]);
  return (
    <Page title="Deal Offer">
      <HeaderBreadcrumbs
        heading="Create Deal"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Food', href: PATH_DASHBOARD.food.dealOffer },
        ]}
      />
      <DealOfferAddForm handleClose={handleClose} />
    </Page>
  );
}

export default CreateDealOffer;
