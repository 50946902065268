import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { TableHeadCustom } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEquipmentLog } from 'redux/slices/storeDocumentSlice';
import EquipmentTableRow from './EquipmentTableRow';

// const headLabel = [
//   { id: 'sn', label: 'S.N.' },
//   { id: 'name', label: 'Name' },
//   { id: 'model', label: 'Model' },
//   { id: 'quantity', label: 'Qty' },
//   { id: 'lastService', label: 'Last Service' <br /> ' Date' },
//   { id: 'dueService', label: 'Service Due Date' },
//   { id: 'comment', label: 'Comment' },
//   { id: 'actions', label: 'Actions', align: 'right' },
// ];

function EquipmentLogs() {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const { loading, equipmentLogs } = useSelector((state) => state.storeDocument);

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getEquipmentLog({ departmentId }));
  }, [departmentId, dispatch]);
  return (
    <>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              <b>S.N.</b>
            </TableCell>
            <TableCell>
              <b>Name</b>
            </TableCell>
            <TableCell>
              <b>Model</b>
            </TableCell>

            <TableCell>
              <b>Qty</b>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <b>Last service</b>
                {/* <b>Date</b> */}
              </div>
            </TableCell>
            <TableCell>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <b>Service Due</b>
                {/* <b>Date</b> */}
              </div>
            </TableCell>
            <TableCell>
              <b>Comment</b>
            </TableCell>
            <TableCell align="right">
              <b>Actions</b>
            </TableCell>
          </TableRow>
        </TableHead>
        {/* <TableHeadCustom headLabel={headLabel} /> */}
        <TableBody>
          {loading ? (
            <TableRow>
              <TableCell>
                <CustomLoadingScreen height="30vh" />
              </TableCell>
            </TableRow>
          ) : (
            equipmentLogs?.map((item, index) => <EquipmentTableRow key={index} row={item} index={index} />)
          )}
        </TableBody>
      </Table>
    </>
  );
}

export default EquipmentLogs;
