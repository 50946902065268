import { Accordion, AccordionDetails, AccordionSummary, Button, Stack } from '@mui/material';
import Iconify from 'components/Iconify';
import { CSS } from '@dnd-kit/utilities';
import React, { useState } from 'react';
import AddMenuOptionToMenuSize from 'sections/@dashboard/menuEngineering/menuSizeOption/AddMenuOptionToMenuSize';
import { useSortable } from '@dnd-kit/sortable';
import MenuSizeOptionAccordianDetails from './MenuSizeOptionAccordianDetails';

function MenuSizeOptionAccordian({ sizeIndex, sizes }) {
  const [openAdd, setOpenAdd] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: sizes?.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
    expanded: !isDragging,
  };
  return (
    <Accordion sx={{ paddingBlock: '0.1rem' }} expanded={isExpanded} defaultExpanded style={style}>
      <AccordionSummary
        expandIcon={
          <Iconify
            icon="ic:outline-expand-more"
            width="25px"
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          />
        }
        aria-controls="Menu with Categories"
        id={sizes?.id}
        style={{ height: '3.5rem', borderBottom: '0.5px solid rgba(145, 158, 171, 0.24)' }}
      >
        <Stack direction={'row'} justifyContent={'space-between'} width="100%">
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <div
              {...listeners}
              {...attributes}
              ref={setNodeRef}
              style={{ marginTop: '10px', marginRight: '10px', cursor: 'move' }}
            >
              <Iconify icon="system-uicons:drag" height={20} width={20} />
            </div>
            <Stack>
              <p style={{ fontWeight: '600' }}>{sizes?.name}</p>
              <p style={{ color: '#637381' }}>
                {sizes?.sizeGroupsOptionGroups?.length > 0 ? sizes?.sizeGroupsOptionGroups?.length : 0} Options
              </p>
            </Stack>
          </Stack>
          <Stack className={'manageMenuSizeList'}>
            <Button variant="outlined" size="small" onClick={() => setOpenAdd({ status: true, data: sizes })}>
              Add
            </Button>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails className="FoodMenuAccordiansDetails">
        <MenuSizeOptionAccordianDetails
          OptionInsideSize={sizes?.sizeGroupsOptionGroups}
          sizeIndex={sizeIndex}
          size={sizes}
        />
      </AccordionDetails>
      {openAdd?.status && <AddMenuOptionToMenuSize open={openAdd.status} setOpen={setOpenAdd} data={openAdd.data} />}
    </Accordion>
  );
}

export default MenuSizeOptionAccordian;
