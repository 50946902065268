import * as Yup from 'yup';

const InvoiceYupSchema = Yup.object().shape({
  invoiceNumber: Yup.string().required('Invoice Number is required'),
  totalInclGst: Yup.number()
    .required('')
    .nullable()
    .transform((curr, orig) => (orig === '' || orig === null ? 0 : curr))
    .typeError(''),
  invoiceDate: Yup.date(),
  supplierId: Yup.string().required('Supplier Id is required').nullable(),
  totalGst: Yup.number()
    .required('')
    .nullable()
    .transform((curr, orig) => (orig === '' || orig === null ? 0 : curr))
    .typeError(''),
  invoiceItems: Yup.array().of(
    Yup.object().shape({
      externalUID: Yup.string().required('').max(10, ''),
      productDesc: Yup.string().required(''),
      uom: Yup.string().required('').min(1, '').nullable(),
      weightType: Yup.string().required('').min(1, '').nullable(),
      weight: Yup.number()
        // .positive('')
        .nullable()
        .when('weightType', {
          is: (weightType) => weightType === 'KG',
          then: (schema) => schema,
          otherwise: (schema) => schema.notRequired(),
        })
        .transform((curr, orig) => (orig === '' ? null : curr))
        .typeError(''),
      suppliedQty: Yup.number()
        .required('')
        .min(0, '')
        .typeError('')
        .transform((curr, orig) => (orig === '' ? 0 : curr)),

      unitPrice: Yup.number()
        .required('')
        .min(0, '')
        .nullable()
        .transform((curr, orig) => (orig === '' || orig === null ? 0 : curr))
        .typeError(),

      gst: Yup.number()
        .nullable()
        .min(0, '')
        .transform((curr, orig) => (orig === '' || orig === null ? 0 : curr))
        .typeError(''),
      levy: Yup.number()
        .nullable()
        .min(0, '')
        .typeError('')
        .transform((curr, orig) => (orig === '' || orig === null ? 0 : curr)),
      packSize: Yup.number()
        .nullable()
        .typeError('')
        .transform((curr, orig) => (orig === '' ? null : curr)),
      // .when('uom', {
      //   is: (type) => type === 'EACH',
      //   then: (schema) => schema.required('').moreThan(0, ''),
      //   otherwise: (schema) => schema.notRequired(),
      // }),
    })
  ),
});
export default InvoiceYupSchema;
