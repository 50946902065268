import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import React from 'react';
import { useSelector } from 'react-redux';
import UserWorkReportIndividualRow from './UserWorkReportIndividualRow';

function UserWorkReportTable({ list, startDate, endDate, departmentId }) {
  const { loading } = useSelector((state) => state.reports);

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>S.N.</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Work Time</TableCell>
              <TableCell>Driver Incentive</TableCell>
              <TableCell>Actual Pay</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <CustomLoadingScreen height="20vh" />
                </TableCell>
              </TableRow>
            ) : (
              list?.map((item, index) => (
                <UserWorkReportIndividualRow
                  departmentId={departmentId}
                  key={item.id}
                  item={item}
                  index={index}
                  startDate={startDate}
                  endDate={endDate}
                />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default UserWorkReportTable;
