import { Stack, Table, TableBody, TableContainer } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getQuickSummaryClockIn, setQuickSummaryWeek } from 'redux/slices/quickSummarySlice';
import { findAllDaysFromWeeks } from 'utils/roasterFeatureUtils';
import QuickSummaryClockInTableHead from './QuickSummaryClockInTableHead';
import QuickSummaryClockInTableBody from './QuickSummaryClockInTableBody';

QuickSummaryClockInDetails.propTypes = {
  choosenDate: PropTypes.string,
};

function QuickSummaryClockInDetails({ choosenDate }) {
  const { clockInDetailsLoading, clockInSummaryReport } = useSelector((state) => state.quickSummary);

  const [allWeeks, setAllWeeks] = useState([]);
  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { reset } = methods;
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const fromDate = moment(choosenDate).startOf('isoWeek').format('YYYY-MM-DD');
  const toDate = moment(choosenDate).endOf('isoWeek').format('YYYY-MM-DD');

  //   get weeks for table headings
  useEffect(() => {
    const getAllDates = findAllDaysFromWeeks(fromDate, toDate).map((obj, index) => ({
      id: index,
      label: moment(obj).format('YYYY-MM-DD'),
      align: 'left',
    }));
    setAllWeeks(getAllDates);
    dispatch(setQuickSummaryWeek(getAllDates));
  }, [dispatch, fromDate, toDate]);

  //   get clock in details
  useEffect(() => {
    if (!departmentId) return;
    dispatch(getQuickSummaryClockIn({ departmentId, anyDateOfWeek: moment(choosenDate).format('YYYY-MM-DD') }));
  }, [choosenDate, departmentId, dispatch]);

  useEffect(() => {
    if (!Object.keys(clockInSummaryReport).length) return;

    reset({ users: clockInSummaryReport?.users });
  }, [clockInSummaryReport, reset]);

  return (
    <>
      {clockInDetailsLoading ? (
        <CustomLoadingScreen height="40vh" />
      ) : (
        <Stack>
          <FormProvider {...methods}>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table stickyHeader>
                <QuickSummaryClockInTableHead allWeeks={allWeeks} />
                <TableBody className="roasterTableBodyContainer">
                  <QuickSummaryClockInTableBody />
                </TableBody>
              </Table>
            </TableContainer>
          </FormProvider>
        </Stack>
      )}
    </>
  );
}

export default QuickSummaryClockInDetails;
