import { Container } from '@mui/material';
import Page from 'components/Page';
import React, { useEffect } from 'react';
import { isCreateStockOrderAction } from 'redux/slices/stockSlice';
import { dispatch } from 'redux/store';
import StockHeaders from 'sections/@dashboard/stocks/StockHeaders';
import StockCardBody from './StockCardBody';

function StockHomePageIndex() {
  useEffect(() => {
    dispatch(isCreateStockOrderAction(false));
  }, []);
  return (
    <Page title="Stock ">
      {/* <Container> */}
      <StockHeaders />
      <StockCardBody />
      {/* </Container> */}
    </Page>
  );
}

export default StockHomePageIndex;
