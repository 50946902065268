import React from 'react';
import Page from 'components/Page';
import { PATH_DASHBOARD } from 'routes/paths';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import ShiftDroppedList from 'sections/@dashboard/roaster/ShiftDroppedList';

export default function ShiftDropRequests() {
  return (
    <Page title="Dropped Shift">
      <HeaderBreadcrumbs
        heading="Shift Dropped Request"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Roster', href: PATH_DASHBOARD.roaster.ShiftRequest },
          { name: 'Dropped Shift List' },
        ]}
      />
      <ShiftDroppedList />
    </Page>
  );
}
