import { Avatar, Box, Checkbox, InputAdornment, Stack } from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import React from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

function MostLikedFoodMenuAccordionItem({
  menu,
  index,
  mainIndex,
  addAppend,
  addRemove,
  removeAppend,
  removeRemove,
  addFields,
  removeFields,
}) {
  const { control } = useFormContext();
  const selectedMenus = useWatch({
    control,
    name: 'selectedMenus',
  });

  const handleMenuChange = (event, option) => {
    if (event.target.checked) {
      const prevData = selectedMenus?.find((el) => el?.id === option?.id);
      if (!prevData) {
        const findIndexes = addFields?.findIndex((el) => el?.id === option?.id);
        if (findIndexes === -1) {
          addAppend({ id: option?.id });
        }
      } else {
        const findIndexes = removeFields?.findIndex((el) => el?.id === option?.id);
        if (findIndexes !== -1) {
          removeRemove(findIndexes);
        }
      }
    } else {
      const prevData = selectedMenus?.find((el) => el?.id === option?.id);
      if (prevData) {
        const findIndexes = removeFields?.findIndex((el) => el?.id === option?.id);
        if (findIndexes === -1) {
          removeAppend({ id: option?.id });
        }
      } else {
        const findIndexes = addFields?.findIndex((el) => el?.id === option?.id);
        if (findIndexes !== -1) {
          addRemove(findIndexes);
        }
      }
    }
  };

  const handleChecked = (menu) => {
    let boolean = false;
    selectedMenus?.forEach((el) => {
      if (el?.id === menu?.id) {
        boolean = true;
      }
      return boolean;
    });
    return boolean;
  };
  return (
    <>
      <Stack
        flexDirection="row"
        alignItems={'center'}
        my="0.5rem"
        className="FoodMenuDetailsIndividualItem"
        style={{ borderBottom: '0.5px solid rgba(145, 158, 171, 0.24)', margin: '10px' }}
      >
        <Stack
          alignItems="center"
          gap="0.5rem"
          flexDirection={'row'}
          my="0.15rem"
          p="0.4rem"
          borderRadius={'10px'}
          fontWeight={500}
          sx={{ cursor: 'pointer' }}
        >
          <Stack>
            <Avatar alt="menu Image" src={menu?.thumbnailUrl} />
          </Stack>
          <Stack>
            <Stack className="menuCategoryMenuName">
              <p style={{ fontWeight: '600' }}>{menu?.name}</p>
            </Stack>
          </Stack>
        </Stack>
        <Stack className={'manageMenuSizeList'}>
          <Box className="manageMenuSizeListPriceSettings">
            <div style={{ textAlign: 'center' }}>
              <Checkbox
                name={`data.${mainIndex}.menus.${index}.menuId`}
                onChange={(event) => handleMenuChange(event, menu)}
                defaultChecked={handleChecked(menu)}
                style={{ marginRight: '0.8rem' }}
              />
            </div>
          </Box>
        </Stack>
      </Stack>
    </>
  );
}

export default MostLikedFoodMenuAccordionItem;
