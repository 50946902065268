// @mui
import { Container } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import useSettings from 'hooks/useSettings';
import { useSelector, useDispatch } from 'react-redux';
import { createInvoiceManually, isopenInvoiceModal } from 'redux/slices/InvoiceSlice';
import { PATH_DASHBOARD } from 'routes/paths';
import InvoiceNewEditForm from 'sections/@dashboard/invoice/new-edit-form';
import { useNavigate } from 'react-router';
// routes

export default function InvoiceCreate() {
  const { themeStretch } = useSettings();
  const Navigate = useNavigate();

  const dispatch = useDispatch();
  const { selectedDepartment } = useSelector((state) => state.userDepartment);

  const { loading } = useSelector((state) => state.invoice);

  const redirectAfterSuccess = () => {
    dispatch(isopenInvoiceModal());
    Navigate(PATH_DASHBOARD.invoice.list);
  };

  const CreateInvoiceHandler = (data) => {
    const filteringData = {
      ...data,
      departmentId: selectedDepartment.id,
      invoiceItems: data?.invoiceItems.reduce((acc, current) => {
        if (!current.returnQuantity)
          return [
            ...acc,
            {
              ...current,
              amount: (
                Number(current.suppliedQty) * Number(current.unitPrice) +
                Number(current.gst) +
                Number(current.levy)
              ).toFixed(2),
              // packSize: current?.uom === 'EACH' ? current?.packSize : null,
              packSize: current?.packSize,
            },
          ];
        return [
          ...acc,
          {
            ...current,
            amount: (
              Number(current.suppliedQty) * Number(current.unitPrice) +
              Number(current.gst) +
              Number(current.levy)
            ).toFixed(2),
            packSize: current?.packSize,
            // packSize: current?.uom === 'EACH' ? current?.packSize : null,
          },
          {
            ...current,
            status: 'RETURN',
            suppliedQty: Number(current.returnQuantity.suppliedQty),
            // packSize: current?.uom === 'EACH' ? current?.packSize : null,
            packSize: current?.packSize,
            amount: (
              Number(current.returnQuantity.suppliedQty) * Number(current.unitPrice) +
              Number(current.gst) +
              Number(current.levy)
            ).toFixed(2),
          },
        ];
      }, []),
    };
    dispatch(createInvoiceManually({ filteringData, redirectAfterSuccess }));
  };

  return (
    <Page title="Invoices: Create a new invoice">
      {/* <Container maxWidth={themeStretch ? false : 'lg'}> */}
      <HeaderBreadcrumbs
        heading="Create a new invoice"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Invoices', href: PATH_DASHBOARD.invoice.list },
          { name: 'New invoice' },
        ]}
      />

      <InvoiceNewEditForm onSubmit={CreateInvoiceHandler} />
      {/* </Container> */}
    </Page>
  );
}
