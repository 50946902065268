import { Box, Card, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIndividualOption } from 'redux/slices/menuERSizeAndOptionSlice';
import MenuOptionForm from 'sections/@dashboard/menuEngineering/menuOption/MenuOptionForm';
import MenuOptionImage from 'sections/@dashboard/menuEngineering/menuOption/MenuOptionImage';

function MenuOptionEdit({ open, handleClose, selectedRow }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState('OPTIONDETAILS');

  const { singleMenuOptionLoading } = useSelector((state) => state.menuERSizeAndOption);

  useEffect(() => {
    if (!selectedRow) return;
    if (!departmentId) return;
    dispatch(getIndividualOption({ departmentId, optionGroupId: selectedRow?.id }));
  }, [departmentId, dispatch, selectedRow]);

  return (
    <CustomDrawer
      open={open}
      setOpen={handleClose}
      title={`Update Menu Option`}
      PaperProps={{ sx: { innerWidth: '50%' } }}
    >
      <Box style={{ width: '600px' }}>
        {/* {singleMenuOptionLoading ? (
          <CustomLoadingScreen height="30vh" />
        ) : (
          <MenuOptionForm handleClose={handleClose} selectedRow={selectedRow} isEdit />
        )} */}
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={tabValue}
          onChange={(e, value) => {
            setTabValue(value);
          }}
          sx={{ px: 2, bgcolor: 'background.neutral' }}
        >
          <Tab label="Options Details" value="OPTIONDETAILS" />
          <Tab label="Option Image" value="OPTIONIMAGE" />
        </Tabs>
        {/* eslint-disable-next-line no-nested-ternary */}
        {tabValue === 'OPTIONDETAILS' ? (
          singleMenuOptionLoading ? (
            <CustomLoadingScreen height="30vh" />
          ) : (
            <MenuOptionForm handleClose={handleClose} selectedRow={selectedRow} isEdit />
          )
        ) : (
          <MenuOptionImage selectedRow={selectedRow} />
        )}
        {/* eslint-disable-next-line no-nested-ternary */}
        {/* {singleMenuOptionLoading ? (
          <CustomLoadingScreen height="30vh" />
        ) : tabValue === 'OPTIONDETAILS' ? (
          <MenuOptionForm handleClose={handleClose} selectedRow={selectedRow} isEdit />
        ) : (
          ''
        )} */}
      </Box>
    </CustomDrawer>
  );
}

MenuOptionEdit.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedRow: PropTypes.object,
};

export default MenuOptionEdit;
