import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { errorToastTime, successToastTime } from 'config';
import apiURL from '../../api';

export const getUserRole = createAsyncThunk('userRole/getUserRole', async () => {
  try {
    const response = await apiURL.get(`/store/role`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createRole = createAsyncThunk('createRole/userRole', async ({ roleName, currentColor }, thunkAPI) => {
  try {
    const response = await apiURL.post(`store/role`, {
      title: roleName,
      colorCode: currentColor,
    });
    if (response.status === 200) {
      toast.success('Role created successfully', { autoClose: successToastTime });
      thunkAPI.dispatch(getUserRole());
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteRole = createAsyncThunk('createRole/userRole', async (roleId, thunkAPI) => {
  try {
    const response = await apiURL.delete(`store/role/${roleId}`);
    if (response.status === 200) {
      toast.success('Role deleted successfully', { autoClose: successToastTime });
      thunkAPI.dispatch(getUserRole());
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateRole = createAsyncThunk(
  'updateRole/userRole',
  async ({ roleId, roleName, currentColor }, thunkAPI) => {
    try {
      const response = await apiURL.put(`store/role`, {
        id: roleId,
        title: roleName,
        colorCode: currentColor,
      });
      if (response.status === 200) {
        toast.success('Role updated successfully', { autoClose: successToastTime });
        thunkAPI.dispatch(getUserRole());
      }
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

const userRoleSlice = createSlice({
  name: 'userRole',
  initialState: {
    role: [],
    loading: false,
  },
  extraReducers: {
    [getUserRole.pending]: (state) => {
      state.loading = true;
    },
    [getUserRole.fulfilled]: (state, action) => {
      state.loading = false;
      state.role = action.payload;
    },
    [getUserRole.rejected]: (state) => {
      state.loading = false;
    },
    [createRole.pending]: (state) => {
      state.loading = true;
    },
    [createRole.fulfilled]: (state) => {
      state.loading = false;
    },
    [createRole.rejected]: (state) => {
      state.loading = false;
    },
    [deleteRole.pending]: (state) => {
      state.loading = true;
    },
    [deleteRole.fulfilled]: (state) => {
      state.loading = false;
    },
    [deleteRole.rejected]: (state) => {
      state.loading = false;
    },
    [updateRole.pending]: (state) => {
      state.loading = true;
    },
    [updateRole.fulfilled]: (state) => {
      state.loading = false;
    },
    [updateRole.rejected]: (state) => {
      state.loading = false;
    },
  },
});
export default userRoleSlice.reducer;
