import Page from 'components/Page';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { getHalfAndHalfConfig } from 'redux/slices/halfAndHalfSlice';
import HalfAndHalfBody from './HalfAndHalfBody';

function HalfAndHalfConfig() {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getHalfAndHalfConfig({ departmentId }));
  }, [dispatch, departmentId]);
  return (
    <Page title="HalfAndHalfConfig">
      <HalfAndHalfBody />
    </Page>
  );
}

export default HalfAndHalfConfig;
