import { Button, Card, FormControlLabel, FormHelperText, Grid, Stack, Typography } from '@mui/material';
import { RHFCheckbox } from 'components/hook-form';
import { RHFFullImageUpload } from 'components/hook-form/RHFFullImageUpload';
import React, { useCallback, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import StaffCreateActiveStepperPaperworkDeliveryDriver from './StaffCreateActiveStepperPaperworkDeliveryDriver';

function StaffCreateActiveStepperPaperwork({ isEdit }) {
  const {
    control,
    setValue,
    register,
    getValues,
    resetField,
    formState: { errors },
  } = useFormContext();

  const watchIsInternationalStudent = useWatch({
    control,
    name: 'isInternationalStudent',
  });

  const watchUploadPdf = useWatch({
    control,
    name: 'visa',
  });

  const watchPassport = useWatch({
    control,
    name: 'passport',
  });

  const watchWorkingRightPdf = useWatch({
    control,
    name: 'workingRights',
  });

  const getSignUrl = getValues('signURL') || '';

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'passport',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const manageInternationStudent = (event) => {
    setValue('isInternationalStudent', event.target.checked);
    if (!event.target.checked) {
      resetField('passport');
      resetField('visa');
      resetField('workingRights');
    }
  };

  // incase of edit and they have visa
  const getLastDetails = (data) => data?.split('/').slice(-1)[0];
  const getVisaValues = getValues('visaURL') || '';
  const getPassportValues = getValues('passportURL') || '';

  const getWorkingRightsValues = getValues('workingRightsURL') || '';

  const getVisaURL = getValues('visaURL') ? getLastDetails(getValues('visaURL')) : '';
  const getWorkingRightURL = getValues('workingRightsURL') ? getLastDetails(getValues('workingRightsURL')) : '';

  const { fields, append } = useFieldArray({
    name: 'removedPaperworks',
  });

  const isInternationalStudentCheck =
    watchPassport ||
    watchUploadPdf ||
    watchWorkingRightPdf ||
    // getPassportValues ||
    getVisaValues ||
    getWorkingRightsValues;

  return (
    <Stack>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Card sx={{ py: 3, px: 3 }}>
            <Stack px={2}>
              <FormControlLabel
                label="Are you a International Student ?"
                control={
                  <RHFCheckbox
                    name="isInternationalStudent"
                    onChange={manageInternationStudent}
                    disabled={isInternationalStudentCheck}
                  />
                }
              />
            </Stack>
            {watchIsInternationalStudent && (
              <Stack>
                <Stack my={2} gap="0.5rem" py={2} alignItems={'center'} bgcolor={'rgba(145, 158, 171, 0.05)'}>
                  <Typography component="p" color={'error'}>
                    Passport Photo *
                  </Typography>
                  <RHFFullImageUpload
                    name="passport"
                    onDrop={handleDrop}
                    isEditImage={isEdit ? { isEdit, imgUrl: getValues('passportURL') || null } : null}
                  />
                  {(watchPassport || getPassportValues) && (
                    <Button
                      color="error"
                      onClick={() => {
                        resetField('passport');
                        setValue('passportURL', '');
                        if (!fields.includes('PASSPORT')) append('PASSPORT');
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </Stack>

                <Stack my={1} gap="0.5rem" py={2} alignItems={'center'} bgcolor={'rgba(145, 158, 171, 0.05)'}>
                  <Stack gap="0.5rem" alignItems={'center'}>
                    <Typography component="p" color={'error'}>
                      Visa PDF *
                    </Typography>
                    <Button variant="contained" sx={{ padding: '0 !important', width: 'fit-content' }}>
                      <label htmlFor="visa" style={{ padding: '0.5rem', cursor: 'pointer' }}>
                        <input
                          type="file"
                          {...register('visa')}
                          accept="application/pdf"
                          id="visa"
                          hidden
                          onChange={(event) => {
                            const file = event.target.files[0];
                            if (file) {
                              setValue('visa', file);
                            }
                          }}
                        />
                        <Stack>
                          Upload PDF
                          <Typography component="span" fontSize="0.8rem" color={'info'}>
                            {watchUploadPdf?.name || getVisaURL || ''}
                          </Typography>
                        </Stack>
                      </label>
                    </Button>
                    <Stack direction="row" alignItems="center" gap={1}>
                      {isEdit && getVisaValues && (
                        <a href={getVisaValues} download target="_blank" rel="noreferrer">
                          Download
                        </a>
                      )}

                      {(watchUploadPdf || getVisaURL) && (
                        <Button
                          color="error"
                          onClick={() => {
                            resetField('visa');
                            setValue('visaURL', '');
                            if (!fields.includes('VISA')) append('VISA');
                          }}
                        >
                          Remove
                        </Button>
                      )}
                    </Stack>
                  </Stack>

                  <Stack gap="0.5rem" alignItems={'center'}>
                    <Typography component="p" color={'error'}>
                      Working Right Permission *
                    </Typography>
                    <Button variant="contained" sx={{ padding: '0 !important' }}>
                      <label htmlFor="workingRights" style={{ padding: '0.5rem', cursor: 'pointer' }}>
                        <input
                          type="file"
                          {...register('workingRights')}
                          accept="application/pdf"
                          id="workingRights"
                          hidden
                          onChange={(event) => {
                            const file = event.target.files[0];
                            if (file) {
                              setValue('workingRights', file);
                            }
                          }}
                        />
                        <Stack>
                          Upload PDF
                          <Typography component="span" fontSize="0.8rem" color={'info'}>
                            {watchWorkingRightPdf?.name || getWorkingRightURL || ''}
                          </Typography>
                        </Stack>
                      </label>
                    </Button>
                    <Stack direction={'row'} gap={1} alignItems={'center'}>
                      {isEdit && getWorkingRightsValues && (
                        <a href={getWorkingRightsValues} download target="_blank" rel="noreferrer">
                          Download
                        </a>
                      )}

                      {(watchWorkingRightPdf || getWorkingRightURL) && (
                        <Button
                          color="error"
                          onClick={() => {
                            resetField('workingRights');
                            setValue('workingRightsURL', '');
                            if (!fields.includes('WORKING_RIGHTS')) append('WORKING_RIGHTS');
                          }}
                        >
                          Remove
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </Card>
        </Grid>
        <StaffCreateActiveStepperPaperworkDeliveryDriver isEdit={isEdit} />

        {isEdit && getSignUrl && (
          <Grid item xs={12} md={12}>
            <Stack m={1} p={1} bgcolor={'rgba(145, 158, 171, 0.05)'} borderRadius={'10px'}>
              <img src={getSignUrl} alt="Get Sign URL" />
            </Stack>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}

export default StaffCreateActiveStepperPaperwork;
