import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFoodMenuBaseIngredients, getFoodMenuSizes } from 'redux/slices/menuEngineeringSlice';
import FoodMenuBaseIngredientTable from './FoodMenuBaseIngredientTable';

function FoodMenuBaseIngredient({ menu, indexList }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const { menuBaseIngredientsLoading } = useSelector((state) => state.menuEngineering);

  useEffect(() => {
    if (!departmentId || !menu) return;
    dispatch(getFoodMenuBaseIngredients({ menuId: menu?.id, departmentId }));
    dispatch(getFoodMenuSizes({ departmentId, menuId: menu?.id }));
  }, [departmentId, dispatch, menu]);

  return (
    <>
      {menuBaseIngredientsLoading ? <CustomLoadingScreen height="20vh" /> : <FoodMenuBaseIngredientTable menu={menu} />}
    </>
  );
}

export default FoodMenuBaseIngredient;
