import { Box, Tab, Tabs } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import FoodMenuDefaultCount from './FoodMenuDefaultCount';
import FoodMenuTopins from './MenuTopins/FoodMenuTopins';
import FoodMenuBaseIngredient from './BaseIngredient/FoodMenuBaseIngredient';
import FoodMenuCombo from './MenuCombo/FoodMenuCombo';
import FoodMenuTopinsQty from './MenuTopins/FoodMenuTopinsQty';
import FoodMenuCogs from './MenuCogs/FoodMenuCogs';
import RecipeSummaryIndex from './RecipeSummary';
import FoodMenuDetailsPage from './FoodMenuDetails';
import FoodMenuProcessIndex from '../FoodMenuProcess';

function FoodMenuDrawer({ open, setOpen, menu, indexList }) {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CustomDrawer
      open={open}
      setOpen={setOpen}
      title={`${menu?.name}`}
      PaperProps={{ sx: { width: '70%' } }}
      handleClose={handleClose}
      // headerIcon="carbon:gui-management"
      // iconColor="#1976d2"
      // iconWidth={30}
      // iconHeight={30}
    >
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={currentTab} onChange={handleChange} aria-label="Menu Edit" variant="scrollable">
          {/* <Tab label="Size Published" id="7" /> */}
          <Tab label="Details" id="0" />
          <Tab label="Default Count" id="1" />
          <Tab label="Toppins" id="2" />
          <Tab label="Toppins Qty" id="3" />
          <Tab label="Combo" id="4" />
          {/* <Tab label="Menu Combo" id="4" /> */}
          <Tab label="C. Ingredient" id="5" />
          {/* <Tab label="Menu Ingredient" id="5" /> */}
          <Tab label="Recipe Summary" id="6" />
          <Tab label="COGS" id="7" />
          <Tab label="Process" id="8" />
        </Tabs>
      </Box>

      {/* {currentTab === 7 && <FoodMenuHandleSizePublished menu={menu} indexList={indexList} />} */}
      {currentTab === 0 && <FoodMenuDetailsPage menu={menu} indexList={indexList} />}

      {currentTab === 1 && <FoodMenuDefaultCount menu={menu} indexList={indexList} />}

      {currentTab === 2 && <FoodMenuTopins menu={menu} indexList={indexList} />}

      {currentTab === 3 && <FoodMenuTopinsQty menu={menu} indexList={indexList} />}

      {currentTab === 4 && <FoodMenuCombo menu={menu} indexList={indexList} />}

      {currentTab === 5 && <FoodMenuBaseIngredient menu={menu} indexList={indexList} />}

      {currentTab === 6 && <RecipeSummaryIndex menu={menu} />}
      {currentTab === 7 && <FoodMenuCogs menu={menu} indexList={indexList} />}

      {currentTab === 8 && <FoodMenuProcessIndex menu={menu} indexList={indexList} />}
    </CustomDrawer>
  );
}

FoodMenuDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  menu: PropTypes.object,
  indexList: PropTypes.array,
};

export default FoodMenuDrawer;
