import { Icon } from '@iconify/react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Stack, Table } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useSelector, useDispatch } from 'react-redux';
import { updateMenuSales } from 'redux/slices/FoodMenuSlice';
import FoodMenuSalesMenusAccordion from 'sections/@dashboard/sales/menuSales/FoodMenuSalesMenusAccordion';
import moment from 'moment';

function FoodMenuSalesBody({ choosenDate, setChoosenDate }) {
  const { menuSalesLoading, menuSalesDetailsList } = useSelector((state) => state.foodMenus);
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const MenuSalesSchema = Yup.object().shape({
    data: Yup.array().of(
      Yup.object().shape({
        menus: Yup.array().of(
          Yup.object().shape({
            sizes: Yup.array().of(
              Yup.object().shape({
                numberSold: Yup.number()
                  .integer('Integer Only')
                  // .required('')
                  .typeError('')
                  .moreThan(-1, '')
                  .nullable('')
                  .transform((curr, orig) => (orig === '' ? 0 : curr)),
              })
            ),
          })
        ),
      })
    ),
  });

  const defaultValues = { data: [] };

  const methods = useForm({ defaultValues, resolver: yupResolver(MenuSalesSchema), mode: 'onBlur' });

  const { watch, reset, control, handleSubmit, formState } = methods;

  const isFormDirty = formState.isDirty;

  const { fields } = useFieldArray({
    control,
    name: 'data',
  });

  const UpdateMenuSalesCount = (data) => {
    const finalData = {
      departmentId,
      dateForMenusSold: moment(choosenDate).format('YYYY-MM-DD'),
      categories: data?.data,
    };
    dispatch(updateMenuSales({ finalData }));
  };

  useEffect(() => {
    if (!menuSalesDetailsList) return;
    reset({
      data: menuSalesDetailsList,
    });
  }, [menuSalesDetailsList, reset]);

  const checkSizeNameLength = (name) => {
    if (name?.length <= 10) {
      return name;
    }
    const changeName = `${name?.slice(0, 10)}..`;
    return changeName;
  };

  return (
    <Stack my="1rem">
      {menuSalesLoading ? (
        <CustomLoadingScreen height="30vh" />
      ) : (
        <>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(UpdateMenuSalesCount)} id="foodmenuSalesCreateFormId">
              {fields?.map((categories, mainIndex) => (
                <Accordion
                  sx={{ paddingBlock: '0.1rem', borderBottom: '0.5px solid rgba(145, 158, 171, 0.24)' }}
                  defaultExpanded
                  key={mainIndex}
                >
                  <AccordionSummary
                    expandIcon={<Icon icon="ic:outline-expand-more" width="25px" />}
                    aria-controls="Menu with Categories"
                    id={categories?.id}
                  >
                    <Stack direction={'row'} justifyContent={'space-between'} width="100%">
                      <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <Icon icon="tabler:category" width={'25px'} />
                        <Stack>
                          <p style={{ fontWeight: '600' }}>{categories?.name}</p>
                          <p style={{ color: '#637381' }}>{categories?.menus?.length} Items</p>
                        </Stack>
                      </Stack>
                      <Stack className={'manageMenuSizeList'}>
                        {categories?.sizes?.map((categorySize) => (
                          <Box
                            key={categorySize?.id}
                            className="manageItemsOfMenuSizeList manageItemsOfMenuSizeListColor"
                          >
                            {checkSizeNameLength(categorySize?.name)}
                          </Box>
                        ))}
                      </Stack>
                    </Stack>
                  </AccordionSummary>

                  <AccordionDetails sx={{ background: 'rgba(145, 158, 171, 0.12)' }}>
                    {categories?.menus?.map((menu, index) => (
                      <FoodMenuSalesMenusAccordion key={index} menu={menu} mainIndex={mainIndex} menuIndex={index} />
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </form>
          </FormProvider>
        </>
      )}
    </Stack>
  );
}

export default FoodMenuSalesBody;
