import { Stack, Grid, Card, Typography, Divider, TextField, Autocomplete, Avatar } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { useFormContext, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import CustomMultipleAutoCompleteField from 'components/CustomComponents/CustomAutoCompleteField';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { getUserRoles } from 'redux/slices/userSlice';
import RHFText from './RHFText';

function ViewProfileDetails() {
  const departmentId = useGetDepartmentId();
  const [isDepartmentChanged, setIsDepartmentChanged] = useState(false);
  const [userRole, setUserRole] = useState([]);
  console.log('🚀 => ViewProfileDetails => userRole=>', userRole);

  const dispatch = useDispatch();
  const { control, setValue, resetField, getValues } = useFormContext();

  const getGender = getValues('gender') || '';

  const { singleUser, userRoles } = useSelector((state) => state.user);

  const { depOfStore } = useSelector((state) => state.store);
  const { role } = useSelector((state) => state.userRole);

  const isSuccess = () => {
    setIsDepartmentChanged(true);
  };
  const onChangeDepartment = () => {
    dispatch(getUserRoles({ userId: singleUser?.id, departmentId, isSuccess }));
  };

  const findUserRole = useCallback(() => {
    const userRoleArray = [];
    userRoles?.forEach((userRole) => {
      const findRole = role?.find((obj) => obj?.id === userRole);
      if (findRole) {
        userRoleArray.push(findRole);
      }
    });
    return userRoleArray;
  }, [userRoles, role]);

  useEffect(() => {
    if (!isDepartmentChanged) return;
    setValue('role', [...findUserRole()]);
    setUserRole([...findUserRole()]);
  }, [isDepartmentChanged, setValue, findUserRole]);

  useEffect(() => {
    if (!singleUser) return;
    dispatch(getUserRoles({ userId: singleUser?.id, departmentId: singleUser?.departments[0]?.id, isSuccess }));

    // resetField('role');
    // setIsDepartmentChanged(false);
  }, [dispatch, singleUser]);

  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }} justifyContent={'center'} mb={2}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={3.2}>
          <Stack alignItems={'center'} py={3}>
            <Stack
              gap="1rem"
              width="10rem"
              height="10rem"
              borderRadius={'50%'}
              padding={'8px'}
              border="1px dashed rgba(145, 158, 171, 0.32)"
              alignItems="center"
              justifyContent={'center'}
            >
              <Avatar
                src={singleUser?.imgURL}
                alt=""
                style={{
                  height: '150px',
                  width: '150px',
                  borderRadius: '100%',
                  // marginRight: '10px',
                }}
              />
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={12} md={8.8}>
          <Card>
            <Stack direction="column" gap="0.5rem">
              <Stack p={3}>
                <Typography component="h2" fontSize="1.2rem" fontWeight={'700'} pb="0.5rem">
                  User Information
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <RHFText name={'firstName'} label={'First Name*'} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RHFText name={'middleName'} label={'Middle Name*'} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <RHFText name={'lastName'} label={'Last Name*'} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography color="#919EAB">Gender</Typography>
                    <Typography color="#919EAB">{getGender}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name={'dob'}
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <DatePicker
                          name="dob"
                          disabled
                          views={['day', 'month', 'year']}
                          inputFormat="dd-MM-yyyy"
                          {...field}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="DOB *"
                              onKeyDown={(e) => e.preventDefault()}
                              helperText={error?.message}
                              fullWidth
                              error={!!error}
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Stack>
            </Stack>
          </Card>
        </Grid>
        <Divider />
        <Grid item xs={12}>
          <Card>
            <Stack direction="column" gap="0.5rem">
              <Stack p={3}>
                <Typography component="h2" fontSize="1.2rem" fontWeight={'700'} pb="0.5rem">
                  Contact Information
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <RHFText name={'email'} label={'Email*'} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RHFText name={'phone'} label={'Phone Number*'} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RHFText
                      name={'emergencyContactName'}
                      label={'Emergency Contact Name'}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RHFText
                      name={'emergencyContactNo'}
                      label={'Emergency Contact No'}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </Stack>
              <Divider />

              <Stack p={3}>
                <Typography component="h2" fontSize="1.2rem" fontWeight={'700'} pb="0.5rem">
                  Address
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={4}>
                    <RHFText name={'address'} label={'Address'} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RHFText name={'suburb'} label={'Suburb'} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RHFText name={'state'} label={'State'} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RHFText name={'country'} label={'Country'} InputLabelProps={{ shrink: true }} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RHFText name={'zipcode'} label={'Zip Code'} InputLabelProps={{ shrink: true }} />
                  </Grid>
                </Grid>
              </Stack>

              {!singleUser?.departments?.length ? (
                ''
              ) : (
                <Stack p={3}>
                  <Typography component="h2" fontSize="1.2rem" fontWeight={'700'} pb="0.5rem">
                    Department & Roles
                  </Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                      {/* <Card sx={{ py: 3, px: 3 }}>
                      <Stack gap="1rem">
                        <CustomMultipleAutoCompleteField
                          name="departmentId"
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          onChange={(event, value, reason, details) => {
                            setValue('departmentId', value);
                            if (reason === 'selectOption') {
                              onChangeDepartment();
                            }
                            if (reason === 'clear') {
                              resetField('departmentId');
                              resetField('role');
                            }
                          }}
                          label="Select Department"
                          options={depOfStore || []}
                          getOptionLabel={(option) => option?.name}
                        />
                        <Controller
                          control={control}
                          name="role"
                          render={({ field: { ...field } }) => (
                            <Autocomplete
                              disabled
                              label="Select Roles"
                              multiple
                              getOptionLabel={(option) => option?.title}
                              isOptionEqualToValue={(option, value) => option?.id === value?.id}
                              {...field}
                              options={role || []}
                              renderInput={(params) => <TextField {...params} label="Select Roles" />}
                            />
                          )}
                        />
                      </Stack>
                    </Card> */}
                      <Card sx={{ py: 3, px: 3 }}>
                        <Stack gap="1rem">
                          <Grid item xs={12} md={4}>
                            {singleUser?.departments?.map((item, index) => (
                              <TextField
                                disabled
                                label="User Department"
                                value={item?.name}
                                key={index}
                                style={{ width: '200px' }}
                                variant="standard"
                              />
                            ))}
                            {/* <RHFText
                            name={`departmentId[0].name`}
                            label={'Assigned Department'}
                            InputLabelProps={{ shrink: true }}
                          /> */}
                          </Grid>
                          {/* <Grid item xs={12} md={4}>
                          {userRole?.map((item, index) => (
                            <TextField
                              disabled
                              label="Selected Role"
                              value={item?.title}
                              key={index}
                              style={{ width: '200px' }}
                              variant="standard"
                            />
                          ))}
                        </Grid> */}

                          {/* <CustomMultipleAutoCompleteField
                          name="departmentId"
                          isOptionEqualToValue={(option, value) => option?.id === value?.id}
                          onChange={(event, value, reason, details) => {
                            setValue('departmentId', value);
                            if (reason === 'selectOption') {
                              onChangeDepartment();
                            }
                            if (reason === 'clear') {
                              resetField('departmentId');
                              resetField('role');
                            }
                          }}
                          label="Select Department"
                          options={depOfStore || []}
                          getOptionLabel={(option) => option?.name}
                        /> */}
                          <Controller
                            control={control}
                            name="role"
                            render={({ field: { ...field } }) => (
                              <Autocomplete
                                disabled
                                label="User Roles"
                                multiple
                                getOptionLabel={(option) => option?.title}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                {...field}
                                options={role || []}
                                renderInput={(params) => <TextField {...params} label="User Roles" />}
                              />
                            )}
                          />
                        </Stack>
                      </Card>
                    </Grid>
                  </Grid>
                </Stack>
              )}
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default ViewProfileDetails;
