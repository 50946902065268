import React, { useEffect, useState } from 'react';
import { Box, Button, Container } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';
import { useDispatch, useSelector } from 'react-redux';
import { storeSuppliers } from 'redux/slices/storeSlice';
import Iconify from 'components/Iconify';
import StoreCashDrawerAddForm from './StoreCashDrawerAddForm';
import StoreSuppliersAddEditForm from './StoreSuppliersAddEditForm';

export default function StoreSuppliersHeader() {
  const dispatch = useDispatch();
  const [openAddSuppliers, setOpenAddSuppliers] = useState(false);
  return (
    <>
      <HeaderBreadcrumbs
        heading="Store Suppliers"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Store' },
          // { name: 'Store-Suppliers' }
        ]}
        action={
          <Box className="invoiceListHeadingButtons">
            <Button
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} />}
              onClick={() => {
                setOpenAddSuppliers(true);
              }}
            >
              Add Suppliers
            </Button>
          </Box>
        }
      />
      {openAddSuppliers && (
        <StoreSuppliersAddEditForm open={openAddSuppliers} setOpen={setOpenAddSuppliers} edit={false} />
      )}
      {/* {openAddCashdrawer && (
        <StoreCashDrawerAddForm open={openAddCashdrawer} setOpen={setOpenAddCashdrawer} edit={false} />
      )} */}
    </>
  );
}
