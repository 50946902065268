import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import { PATH_AUTH } from 'routes/paths';
import { errorToastTime, successToastTime } from 'config';

export const LoginUser = createAsyncThunk('auth/LoginUser', async ({ storeId, phone, password, navigate }) => {
  try {
    const response = await apiURL.post(`/auth/webLogin`, {
      storeIdentifier: storeId,
      phone,
      password,
    });
    navigate('/dashboard/app');

    return response.data;
  } catch (error) {
    if (error.response.status === 404) {
      toast.error('User Not Found', { autoClose: errorToastTime });
    } else if (error.response.status === 401) {
      localStorage.removeItem('profiles');
      toast.error('User is not authorized', { autoClose: errorToastTime });
    } else {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    }
    navigate('/auth/login');
  }
});

export const getUserDetails = createAsyncThunk('auth/getUserDetails', async () => {
  const response = await apiURL.get(`/user/me`);

  return response.data;
});

export const createUserAcc = createAsyncThunk('auth/createUser', async () => {
  const response = await apiURL.post(`/user`);
  return response.data;
});

export const userInfoRequest = createAsyncThunk('auth/userInfoRequest', async (props) => {
  const response = await apiURL.post(`/auth/userInfoRequest`, props.finalData);
  if (response.status === 200) {
    props.isSuccess();
  }
  return response.data;
});

export const forgotPassword = createAsyncThunk('auth/forgotPassword', async ({ data, navigate }) => {
  const response = await apiURL.post(`/auth/forgotPassword`, data);
  if (response.status === 200) {
    navigate('/auth/new-password');
  }
});

export const resetPassword = createAsyncThunk('auth/resetPassword', async (data, thunkAPI) => {
  try {
    const response = await apiURL.post(`/auth/verifyForgotPassword`, data.finalData);
    if (response.status === 200) {
      thunkAPI.dispatch(
        LoginUser({
          storeId: data.finalData.storeIdentifier,
          phone: data.finalData.phone,
          password: data.finalData.password,
          navigate: data.navigate,
        })
      );
      localStorage.removeItem('resetData');
      toast.success('Password Reset Successfully', { autoClose: successToastTime });
    }
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const validateUuidFromEmail = createAsyncThunk('auth/validateUuidFromEmail', async (props) => {
  try {
    const response = await apiURL.get(`/auth/request/${props.token}`);
    return response.data;
  } catch (error) {
    props.navigate('/404', { replace: true });
    throw error;
  }
});

export const validateHRToken = createAsyncThunk('auth/validateHRToken', async (props) => {
  try {
    const response = await apiURL.get(`/auth/HR/${props.token}`);
    return response.data;
  } catch (error) {
    props.navigate('/404', { replace: true });
    throw error;
  }
});

export const validateOHNSToken = createAsyncThunk('auth/validateOHNSToken', async (props) => {
  try {
    const response = await apiURL.get(`/auth/OHNS/${props.token}`);
    return response.data;
  } catch (error) {
    props.navigate('/404', { replace: true });
    throw error;
  }
});

export const validateSOPToken = createAsyncThunk('auth/validateSOPToken', async (props) => {
  try {
    const response = await apiURL.get(`/auth/SOP/${props.token}`);
    return response.data;
  } catch (error) {
    props.navigate('/404', { replace: true });
    throw error;
  }
});

export const submitAgreementDocument = createAsyncThunk('store/submitAgreement', async (props) => {
  try {
    const response = await apiURL.post('/auth/docs', props?.formData);
    if (response.status === 200) {
      props.isSuccess();
    }
    return response.data;
  } catch (error) {
    toast.error('submit failed', { autoClose: errorToastTime });
    throw error;
  }
});

export const logoutAction = (navigate) => (dispatch) => {
  dispatch(setLogout());
  dispatch({ type: 'USER_LOGOUT' });
  navigate(PATH_AUTH.login, { replace: true });
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: {},
    error: '',
    isAuth: null,
    loading: false,
    postLoading: false,
    uuidLoading: false,
    uuidData: {},
    tokenValidationLoading: false,
    documentPDF: null,
    documentSubmitLoading: false,
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
      state.isAuth = true;
    },
    setLogout: (state) => {
      localStorage.clear();
      state.user = null;
      state.isAuth = null;
    },
  },

  extraReducers: {
    [LoginUser.pending]: (state, action) => {
      state.postLoading = true;
    },
    [LoginUser.fulfilled]: (state, action) => {
      state.postLoading = false;
      localStorage.setItem('profiles', JSON.stringify({ tokenDTO: action?.payload?.tokenDTO }));
      state.user = action?.payload;
      state.isAuth = true;
    },
    [LoginUser.rejected]: (state, action) => {
      state.postLoading = false;
      state.error = action?.payload?.message;
    },
    [getUserDetails.pending]: (state) => {
      state.loading = true;
    },
    [getUserDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.isAuth = true;
      state.user.userDTO = action.payload;
    },
    [getUserDetails.rejected]: (state) => {
      state.loading = false;
    },

    [userInfoRequest.pending]: (state) => {
      state.postLoading = true;
    },
    [userInfoRequest.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [userInfoRequest.rejected]: (state) => {
      state.postLoading = false;
    },
    [validateUuidFromEmail.pending]: (state) => {
      state.uuidLoading = true;
    },
    [validateUuidFromEmail.fulfilled]: (state, action) => {
      state.uuidLoading = false;
      state.uuidData = action.payload;
    },
    [validateUuidFromEmail.rejected]: (state) => {
      state.uuidLoading = false;
    },
    [validateHRToken.pending]: (state) => {
      state.documentPDF = null;
      state.tokenValidationLoading = true;
    },
    [validateHRToken.fulfilled]: (state, action) => {
      state.tokenValidationLoading = false;
      state.documentPDF = action.payload;
    },
    [validateHRToken.rejected]: (state) => {
      state.tokenValidationLoading = false;
    },
    [validateSOPToken.pending]: (state) => {
      state.tokenValidationLoading = true;
      state.documentPDF = null;
    },
    [validateSOPToken.fulfilled]: (state, action) => {
      state.tokenValidationLoading = false;
      state.documentPDF = action.payload;
    },
    [validateSOPToken.rejected]: (state) => {
      state.tokenValidationLoading = false;
    },
    [validateOHNSToken.pending]: (state) => {
      state.tokenValidationLoading = true;
      state.documentPDF = null;
    },
    [validateOHNSToken.fulfilled]: (state, action) => {
      state.tokenValidationLoading = false;
      state.documentPDF = action.payload;
    },
    [validateOHNSToken.rejected]: (state) => {
      state.tokenValidationLoading = false;
    },
    [submitAgreementDocument.pending]: (state) => {
      // state.tokenValidationLoading = true;
      // state.documentPDF = null;
      state.documentSubmitLoading = true;
    },
    [submitAgreementDocument.fulfilled]: (state, action) => {
      // state.tokenValidationLoading = false;
      // state.documentPDF = action.payload;
      state.documentSubmitLoading = false;
    },
    [submitAgreementDocument.rejected]: (state) => {
      // state.tokenValidationLoading = false;
      state.documentSubmitLoading = false;
    },
  },
});
export const { setUser, setLogout } = authSlice.actions;

export default authSlice.reducer;
