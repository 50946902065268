import { Box, Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { findTotalDataOfUser } from 'utils/roasterFeatureUtils';

function QuickSummaryClockInUserWeeklyData({ mainIndex }) {
  const [total, setTotal] = useState();

  const { control } = useFormContext();

  const values = useWatch({
    control,
    name: `users.${mainIndex}`,
  });

  useEffect(() => {
    if (!Object.keys(values)) return;

    const findTotal = findTotalDataOfUser(values);
    setTotal(findTotal);
  }, [values]);

  return (
    <Box
      className="roasterUserDetails"
      style={{ display: 'flex', flexDirection: 'column', fontSize: '0.6rem', position: 'absolute', right: '5px' }}
    >
      <Box>
        <div>
          <Tooltip title="weekly earnings" placement="right">
            <p>$ {total?.totalPay ? total?.totalPay.toFixed(1) : '0'}</p>
          </Tooltip>
          <Tooltip title="weekly hours" placement="right">
            <p>{total?.totalTime ? `${(total?.totalTime / 60).toFixed(2)} Hrs.` : '0 Hrs.'}</p>
          </Tooltip>
        </div>
      </Box>
    </Box>
  );
}

export default QuickSummaryClockInUserWeeklyData;
