import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function RecipeSummaryComboDetails({ combo, index }) {
  const { foodMenuRecipeSummary } = useSelector((state) => state.menuEngineering);

  const calculateAverageQty = (size, combo, sizeIndex) => {
    const qtyUsed = combo?.comboItems?.map((item) => item?.comboItemSizes[sizeIndex]?.qtyUsed ?? 0);

    const sum = qtyUsed?.reduce((a, b) => a + b, 0);
    return (sum / qtyUsed?.length).toFixed(2) ?? 0;
  };
  const calculateAverageCOGS = (size, combo, sizeIndex) => {
    const unitPrice = combo?.comboItems?.map((item) => item?.comboItemSizes[sizeIndex]?.ingredient?.unitPrice ?? 0);
    console.log('🚀 => calculateAverageCOGS => unitPrice=>', unitPrice);

    const averageUnitPrice = unitPrice?.reduce((a, b) => a + b, 0) / unitPrice?.length;
    const unitWeight = combo?.comboItems?.map((item) => item?.comboItemSizes[sizeIndex]?.ingredient?.unitWeight ?? 0);
    console.log('🚀 => calculateAverageCOGS => unitWeight=>', unitWeight);

    const averageUnitWeight = unitWeight?.reduce((a, b) => a + b, 0) / unitWeight?.length;
    const qtyUsed = combo?.comboItems?.map((item) => item?.comboItemSizes[sizeIndex]?.qtyUsed ?? 0);
    console.log('🚀 => calculateAverageCOGS => qtyUsed=>', qtyUsed);

    const averageQtyUsed = qtyUsed?.reduce((a, b) => a + b, 0) / qtyUsed?.length;

    if (!averageUnitPrice || !averageUnitWeight || !averageQtyUsed) return 0;

    return ((averageUnitPrice / averageUnitWeight) * (averageQtyUsed / 1000)).toFixed(2);
  };
  return (
    <TableRow hover key={combo?.id} style={{ backgroundColor: 'rgba(0,0,255,0.1)', color: 'white' }}>
      <TableCell align="left">
        {foodMenuRecipeSummary?.menuSizeOptions?.menuOptions?.length +
          foodMenuRecipeSummary?.menuSizeIngredient?.ingredients?.length +
          index +
          1}
        .
      </TableCell>
      <TableCell>{combo?.title}</TableCell>
      {!combo?.comboItems?.length &&
        foodMenuRecipeSummary?.menuSizeIngredient?.menuSizes?.map((size) => (
          <TableCell align="center" key={size?.id}>
            0 gm / $0
          </TableCell>
        ))}
      {combo?.comboItems[0]?.comboItemSizes?.map((size, sizeIndex) => (
        <TableCell key={sizeIndex} align="center">
          {calculateAverageQty(size, combo, sizeIndex, index) ?? 0} gm / $
          {calculateAverageCOGS(size, combo, sizeIndex, index) ?? 0}
        </TableCell>
      ))}
    </TableRow>
  );
}

RecipeSummaryComboDetails.propTypes = {
  combo: PropTypes.object,
  index: PropTypes.number,
};

export default RecipeSummaryComboDetails;
