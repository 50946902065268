import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import moment from 'moment';
import { errorToastTime, successToastTime } from 'config';

export const getStore = createAsyncThunk('store/getStore', async () => {
  try {
    const response = await apiURL.get('/store');
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateStore = createAsyncThunk('store/updateStore', async (data, thunkAPI) => {
  try {
    const response = await apiURL.put('/store/store', data?.finalData);
    if (response.status === 200) {
      toast.success('Store details updated successfully', { autoClose: successToastTime });
      thunkAPI.dispatch(getStore());
      data?.setOpen(false);
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const saveUserDepartmentRoles = createAsyncThunk(
  'store/saveUserDepartmentRoles',
  async ({ finalData, isSuccess }) => {
    try {
      const response = await apiURL.post('/store/saveUserDepartmentRoles', finalData);
      if (response.status === 200) {
        toast.success('User Roles updated successfully', { autoClose: successToastTime });
        isSuccess(true);
      }
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const storeSuppliers = createAsyncThunk('store/storeSuppliers', async () => {
  try {
    const response = await apiURL.get('/store/storeSuppliers');
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteStoreSupplier = createAsyncThunk('store/deleteStoreSupplier', async (data, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/store/supplier/${data}`);
    if (response.status === 200) {
      toast.success('Supplier deleted successfully', { autoClose: successToastTime });
      thunkAPI.dispatch(storeSuppliers());
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    thunkAPI.dispatch(storeSuppliers());
    throw error;
  }
});

export const createStoreSupplier = createAsyncThunk('store/createStoreSupplier', async (data, thunkAPI) => {
  try {
    const response = await apiURL.post('/store/storeSupplier', data);
    if (response.status === 200) {
      toast.success('Supplier created successfully', { autoClose: successToastTime });
      thunkAPI.dispatch(storeSuppliers());
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
    thunkAPI.dispatch(storeSuppliers());
    throw error;
  }
});

export const updateStoreSupplier = createAsyncThunk('store/updateStoreSupplier', async (data, thunkAPI) => {
  try {
    const response = await apiURL.put(`/store/storeSupplier/${data.id}`, data);
    if (response.status === 200) {
      toast.success('Supplier updated successfully', { autoClose: successToastTime });
      thunkAPI.dispatch(storeSuppliers());
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
    thunkAPI.dispatch(storeSuppliers());
    throw error;
  }
});

export const getDepartOfStore = createAsyncThunk('store/getDepartOfStore', async () => {
  try {
    const response = await apiURL.get('/store/department');
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, {
      autoClose: errorToastTime,
    });
    throw error;
  }
});

export const getHolidays = createAsyncThunk('store/getHolidays', async (pageNo) => {
  try {
    const response = await apiURL.get(`/store/specialDay?pageNo=${pageNo - 1}&pageSize=10`);

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
    throw error;
  }
});
export const createHoliday = createAsyncThunk(
  '/store/createHoliday',
  async ({ title, type, date, isRecurring, toast, isSuccess }) => {
    try {
      const response = await apiURL.post(`/store/specialDay `, {
        title,
        type,
        date,
        isRecurring,
      });
      if (response.status === 200) {
        isSuccess(true);
      }

      return response.data;
    } catch (error) {
      if (error.response.status === 400) {
        return toast.error('required field is missing', { autoClose: errorToastTime });
      }
      return error.response.data.message;
    }
  }
);

export const deleteHoliday = createAsyncThunk('store/deleteHoliday', async ({ id }) => {
  try {
    const response = await apiURL.delete(`/store/specialDay/${id}`);

    toast.success('Holiday delete successfully', { autoClose: successToastTime });
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
    throw error;
  }
});

export const updateHoliday = createAsyncThunk(
  'store/updateHoliday',
  async ({ id, title, holiday, date, isRecurring }) => {
    try {
      const response = await apiURL.post(`/store/updateSpecialDay`, {
        id,
        type: holiday,
        title,
        isRecurring,
        date: moment(date).format('YYYY-MM-DD'),
      });

      toast.success('Holiday updated successfully', { autoClose: successToastTime });
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const getBreaks = createAsyncThunk('store/getBreaks', async () => {
  try {
    const response = await apiURL.get(`/store/break`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
    throw error;
  }
});

export const createBreak = createAsyncThunk('store/createBreak', async (minutes, thunkAPI) => {
  try {
    const response = await apiURL.post(`/store/break`, { minutes });
    toast.success('Break created successfully', { autoClose: successToastTime });
    thunkAPI.dispatch(getBreaks());
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
    throw error;
  }
});
export const deleteBreak = createAsyncThunk('store/createBreak', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/store/break/${props.id}`);
    toast.success('Break deleted successfully', { autoClose: successToastTime });
    thunkAPI.dispatch(getBreaks());
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
    throw error;
  }
});

// Online Platform
export const getOnlineSalesPlatform = createAsyncThunk('sales/getOnlinePlatform', async ({ departmentId }) => {
  try {
    const response = await apiURL.get(`/sales/onlinePlatform/${departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
    throw error;
  }
});

export const createOnlineSalesPlatform = createAsyncThunk('sales/onlinePlatformCreate', async (data, thunkAPI) => {
  try {
    const response = await apiURL.post(`/sales/onlinePlatform`, data);
    toast.success('Online Platform created successfully', { autoClose: successToastTime });
    thunkAPI.dispatch(getOnlineSalesPlatform({ departmentId: data.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteOnlineSalesPlatform = createAsyncThunk('sales/deleteOnlinePlatform', async (data, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/sales/onlinePlatform/${data.departmentId}/${data.orderPlatformId}`);
    if (response.statusCode === 200)
      toast.success('Online Platform deleted successfully', { autoClose: successToastTime });
    thunkAPI.dispatch(getOnlineSalesPlatform({ departmentId: data.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
    throw error;
  }
});

export const updateOnlineSalesPlatform = createAsyncThunk('sales/updateOnlinePlatform', async (data, thunkAPI) => {
  try {
    const response = await apiURL.put(`/sales/onlinePlatform`, data);
    if (response.statusCode === 200)
      toast.success('Online Platform updated successfully', { autoClose: successToastTime });
    thunkAPI.dispatch(getOnlineSalesPlatform({ departmentId: data.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
    throw error;
  }
});

// Store Cash Drawer
export const getStoreCashDrawer = createAsyncThunk('sales/getStoreCashDrawer', async (data) => {
  try {
    const response = await apiURL.get(`/sales/storeCashDrawer/${data.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteCashDrawer = createAsyncThunk('sales/deleteCashDrawer', async (data, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/sales/storeCashDrawer/${data.departmentId}/${data.storeCashDrawerId}`);
    toast.success('Cash Drawer deleted successfully', { autoClose: successToastTime });
    thunkAPI.dispatch(getStoreCashDrawer({ departmentId: data.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
    throw error;
  }
});

export const createCashDrawer = createAsyncThunk('sales/createCashDrawer', async (data, thunkAPI) => {
  try {
    const response = await apiURL.post(`/sales/storeCashDrawer`, data);
    toast.success('Cash Drawer created successfully', { autoClose: successToastTime });
    thunkAPI.dispatch(getStoreCashDrawer({ departmentId: data.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    thunkAPI.dispatch(getStoreCashDrawer({ departmentId: data.departmentId }));
    throw error;
  }
});

export const updateCashDrawer = createAsyncThunk('sales/updateCashDrawer', async (data, thunkAPI) => {
  try {
    const response = await apiURL.put(`/sales/storeCashDrawer`, data);
    toast.success('Cash Drawer updated successfully', { autoClose: successToastTime });
    thunkAPI.dispatch(getStoreCashDrawer({ departmentId: data.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    thunkAPI.dispatch(getStoreCashDrawer({ departmentId: data.departmentId }));
    throw error;
  }
});

// Zones
export const getZones = createAsyncThunk('store/getZones', async (data) => {
  const response = await apiURL.get(`/roaster/zones/${data.departmentId}`);
  return response.data;
});

export const createZone = createAsyncThunk('store/createZone', async (data, thunkAPI) => {
  try {
    const response = await apiURL.put(`/roaster/zones`, data);
    toast.success('Labour Zone updated successfully', { autoClose: successToastTime });
    thunkAPI.dispatch(getZones({ departmentId: data.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    thunkAPI.dispatch(getZones({ departmentId: data.departmentId }));
    throw error;
  }
});

export const createDriverIncentive = createAsyncThunk('store/createDriverIncentive', async (data, thunkAPI) => {
  try {
    const response = await apiURL.put(`/sales/driverIncentive`, data);
    if (response.statusCode === 200)
      toast.success('Driver Incentive updated successfully', { autoClose: successToastTime });
    thunkAPI.dispatch(getDriverIncentive());
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
    throw error;
  }
});

export const getDriverIncentive = createAsyncThunk('store/getDriverIncentive', async () => {
  try {
    const response = await apiURL.get(`/sales/driverIncentive`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
    throw error;
  }
});

export const storeDetails = createAsyncThunk('store/storeDetails', async () => {
  try {
    const response = await apiURL.get(`/store`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
    throw error;
  }
});

const storeSlice = createSlice({
  name: 'store',
  initialState: {
    store: null,
    loading: false,
    depOfStore: [],
    postLoading: false,
    error: '',
    storeSuppliers: [],
    holidays: null,
    success: false,
    break: [],
    onlineSalesPlatform: [],
    storeCashDrawer: [],
    zones: null,
    suppliers: [],
    driverIncentives: null,
  },

  extraReducers: {
    [getStore.pending]: (state) => {
      state.loading = true;
    },
    [getStore.fulfilled]: (state, action) => {
      state.loading = false;
      state.store = action.payload;
    },
    [getStore.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getDepartOfStore.pending]: (state) => {
      state.loading = true;
    },
    [getDepartOfStore.fulfilled]: (state, action) => {
      state.loading = false;
      state.depOfStore = action.payload;
    },
    [getDepartOfStore.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [storeSuppliers.pending]: (state) => {
      state.loading = true;
    },
    [storeSuppliers.fulfilled]: (state, action) => {
      state.loading = false;
      state.storeSuppliers = action.payload.suppliers;
      state.suppliers = action.payload;
    },
    [storeSuppliers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getHolidays.pending]: (state) => {
      state.loading = true;
    },
    [getHolidays.fulfilled]: (state, action) => {
      state.loading = false;
      state.holidays = action.payload;
    },
    [getHolidays.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [createHoliday.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [createHoliday.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.holidays = [action.payload];
    },
    [createHoliday.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [deleteHoliday.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [deleteHoliday.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.holidays = action.payload;
    },
    [deleteHoliday.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [updateHoliday.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [updateHoliday.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.holidays = action.payload;
    },
    [updateHoliday.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [getBreaks.pending]: (state) => {
      state.loading = true;
    },
    [getBreaks.fulfilled]: (state, action) => {
      state.loading = false;
      state.break = action.payload;
    },
    [getBreaks.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [createBreak.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [createBreak.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.break = [action.payload];
    },
    [createBreak.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [deleteBreak.pending]: (state) => {
      state.loading = true;
      state.success = false;
    },
    [deleteBreak.fulfilled]: (state, action) => {
      state.loading = false;
      state.success = true;
      state.break = action.payload;
    },
    [deleteBreak.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [storeDetails.pending]: (state) => {
      state.loading = true;
    },
    [storeDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.break = action.payload;
    },
    [storeDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [saveUserDepartmentRoles.pending]: (state) => {
      state.postLoading = true;
    },
    [saveUserDepartmentRoles.fulfilled]: (state, action) => {
      state.loading = false;
      state.break = action.payload;
    },
    [saveUserDepartmentRoles.rejected]: (state) => {
      state.postLoading = false;
    },

    [getOnlineSalesPlatform.pending]: (state) => {
      state.loading = true;
    },
    [getOnlineSalesPlatform.fulfilled]: (state, action) => {
      state.loading = false;
      state.onlineSalesPlatform = action.payload;
    },
    [getOnlineSalesPlatform.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [createOnlineSalesPlatform.pending]: (state) => {
      state.loading = true;
    },
    [createOnlineSalesPlatform.fulfilled]: (state, action) => {
      state.loading = false;
      state.onlineSalesPlatform = action.payload;
    },
    [createOnlineSalesPlatform.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [deleteOnlineSalesPlatform.pending]: (state) => {
      state.loading = true;
    },
    [deleteOnlineSalesPlatform.fulfilled]: (state, action) => {
      state.loading = false;
      state.onlineSalesPlatform = action.payload;
    },
    [deleteOnlineSalesPlatform.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [updateOnlineSalesPlatform.pending]: (state) => {
      state.loading = true;
    },
    [updateOnlineSalesPlatform.fulfilled]: (state, action) => {
      state.loading = false;
      state.onlineSalesPlatform = action.payload;
    },
    [updateOnlineSalesPlatform.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getStoreCashDrawer.pending]: (state) => {
      state.loading = true;
    },
    [getStoreCashDrawer.fulfilled]: (state, action) => {
      state.loading = false;
      state.storeCashDrawer = action.payload;
    },
    [getStoreCashDrawer.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
    [deleteCashDrawer.pending]: (state) => {
      state.loading = true;
    },
    [deleteCashDrawer.fulfilled]: (state, action) => {
      state.loading = false;
      state.storeCashDrawer = action.payload;
    },
    [deleteCashDrawer.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [createCashDrawer.pending]: (state) => {
      state.loading = true;
    },
    [createCashDrawer.fulfilled]: (state, action) => {
      state.loading = false;
      state.storeCashDrawer = action.payload;
    },
    [createCashDrawer.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [updateCashDrawer.pending]: (state) => {
      state.loading = true;
    },
    [updateCashDrawer.fulfilled]: (state, action) => {
      state.loading = false;
      state.storeCashDrawer = action.payload;
    },
    [updateCashDrawer.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getZones.pending]: (state) => {
      state.loading = true;
    },
    [getZones.fulfilled]: (state, action) => {
      state.loading = false;
      state.zones = action.payload;
    },
    [getZones.rejected]: (state) => {
      state.loading = false;
      state.zones = null;
    },

    [createZone.pending]: (state) => {
      state.loading = true;
    },
    [createZone.fulfilled]: (state, action) => {
      state.loading = false;
      state.zones = action.payload;
    },
    [createZone.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [deleteStoreSupplier.pending]: (state) => {
      state.loading = true;
    },
    [deleteStoreSupplier.fulfilled]: (state, action) => {
      state.loading = false;
      state.suppliers = action.payload;
    },
    [deleteStoreSupplier.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [createStoreSupplier.pending]: (state) => {
      state.loading = true;
    },
    [createStoreSupplier.fulfilled]: (state, action) => {
      state.loading = false;
      state.suppliers = action.payload;
    },
    [createStoreSupplier.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [updateStoreSupplier.pending]: (state) => {
      state.loading = true;
    },
    [updateStoreSupplier.fulfilled]: (state, action) => {
      state.loading = false;
      state.suppliers = action.payload;
    },
    [updateStoreSupplier.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [createDriverIncentive.pending]: (state) => {
      state.loading = true;
    },
    [createDriverIncentive.fulfilled]: (state, action) => {
      state.loading = false;
      state.driverIncentives = action.payload;
    },
    [createDriverIncentive.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },

    [getDriverIncentive.pending]: (state) => {
      state.loading = true;
    },
    [getDriverIncentive.fulfilled]: (state, action) => {
      state.loading = false;
      state.driverIncentives = action.payload;
    },
    [getDriverIncentive.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export default storeSlice.reducer;
