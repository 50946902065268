import { Button, FormHelperText, Stack, Typography } from '@mui/material';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import Label from 'components/Label';
import PropTypes from 'prop-types';
import { FormProvider, RHFTextField } from 'components/hook-form';
import React, { useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { UpdateStoreGuideline, UploadStoreGuideline } from 'redux/slices/storeDocumentSlice';

function UploadStoreGuidelines({ open, setOpen, updateData, isEdit = false }) {
  const defaultValues = useMemo(() => ({ title: updateData?.title }), [updateData]);

  const methods = useForm({ defaultValues });
  const dispatch = useDispatch();

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = methods;
  const watchFile = useWatch({
    control,
    name: 'file',
  });
  const isFileValid = watchFile && !errors?.file;

  const handleUpload = (data) => {
    const isSuccessful = (isSuceess) => {
      if (isSuceess) {
        setOpen(false);
      }
    };
    if (isEdit) {
      const formData = new FormData();
      if (data?.file) {
        formData.append('file', data?.file);
      }
      formData.append(
        'title',
        new Blob([JSON.stringify({ title: data?.title, id: updateData?.id })], { type: 'application/json' })
      );

      dispatch(UpdateStoreGuideline({ formData, isSuccess: isSuccessful }));
    } else {
      const formData = new FormData();
      if (data?.file) {
        formData.append('file', data?.file);
      }
      formData.append('title', new Blob([JSON.stringify({ title: data?.title })], { type: 'application/json' }));

      dispatch(UploadStoreGuideline({ formData, isSuceess: isSuccessful }));
    }
  };
  return (
    <CustomOutletModal
      open={open}
      onClose={() => setOpen(false)}
      zIndex={1600}
      title={isEdit ? 'Update Store Guidelines' : 'Upload Store Guidelines'}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(handleUpload)}>
        <Stack spacing={3}>
          <Stack style={{ marginTop: '10px' }}>
            <RHFTextField name="title" label="Title" />
          </Stack>
          <Stack gap="0.5rem" alignItems={'center'}>
            <Button
              variant="contained"
              sx={{ padding: '0 !important', width: 'fit-content' }}
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <label htmlFor="file" style={{ padding: '0.5rem', cursor: 'pointer' }}>
                <input
                  type="file"
                  {...register('file')}
                  accept="application/pdf"
                  id="file"
                  hidden
                  onChange={(event) => {
                    const file = event.target.files[0];
                    if (file) {
                      setValue('file', file);
                    }
                  }}
                />
                <Stack>Choose PDF</Stack>
              </label>
              <Typography component="span" fontSize="0.8rem" color={'info'}>
                {watchFile?.name || (updateData?.pdfURL && 'Guidelines.pdf') ? (
                  <Label color={'error'}>{watchFile?.name || (updateData?.pdfURL && 'Guidelines.pdf')}</Label>
                ) : (
                  ''
                )}
              </Typography>
            </Button>

            {!isFileValid && (
              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {errors?.file?.message}
              </FormHelperText>
            )}
          </Stack>
        </Stack>
        <Stack direction={'row'} justifyContent={'flex-end'} spacing={2} sx={{ marginTop: '20px' }}>
          <Button type="submit" variant="contained">
            {isEdit ? 'Update' : 'Upload'}
          </Button>
        </Stack>
      </FormProvider>
    </CustomOutletModal>
  );
}

UploadStoreGuidelines.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  updateData: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default UploadStoreGuidelines;
