import { Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Scrollbar from 'components/Scrollbar';
import PropTypes from 'prop-types';
import { TableHeadCustom, TableNoData } from 'components/table';
import React from 'react';
import { useSelector } from 'react-redux';
import UserWorkReportTableRow from './UserWorkReportTableRow';

const TABLE_HEAD = [
  { id: 'sn', label: 'S.N.', align: 'left' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'workTime', label: 'Work Time', align: 'left' },
  { id: 'driverIncentive', label: 'Driver Incentive', align: 'left' },
  { id: 'actualPay', label: 'Actual Pay', align: 'left' },
  { id: 'action', label: 'Action', align: 'left' },
];

UserWorkReportBodyTable.propTypes = {
  list: PropTypes.array,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

function UserWorkReportBodyTable({ list, startDate, endDate }) {
  const { loading } = useSelector((state) => state.reports);

  return (
    <Scrollbar>
      <TableContainer sx={{ maxHeight: 800, position: 'relative' }}>
        <Table size="small">
          <TableHeadCustom headLabel={TABLE_HEAD} />
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <CustomLoadingScreen height="50vh" />
                </TableCell>
              </TableRow>
            ) : (
              list?.map((row, index) => (
                <UserWorkReportTableRow key={row.id} row={row} index={index} startDate={startDate} endDate={endDate} />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!list?.length > 0 && (
        <Stack spacing={2} direction="row" sx={{ mt: 2 }} justifyContent="center">
          <TableNoData isNotFound={list?.length === 0} />
        </Stack>
      )}
    </Scrollbar>
  );
}

export default UserWorkReportBodyTable;
