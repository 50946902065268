import { Icon } from '@iconify/react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Stack, Table } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { TableNoData } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm, useFormContext } from 'react-hook-form';

import { useSelector, useDispatch } from 'react-redux';
import { updateAllMenusWithCategories } from 'redux/slices/FoodMenuSlice';
import FoodMenuMenusAccordion from 'sections/@dashboard/foodMenuAndRecipe/FoodMenu/FoodMenuMenusAccordion';
import FoodMenuMenusReportAccordion from 'sections/@dashboard/foodMenuAndRecipe/FoodMenuReport/FoodMenuMenusReportAccordion';

function FoodMenuReportBody() {
  const { loading, menuSalesReport } = useSelector((state) => state.foodMenus);
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const checkSizeNameLength = (name) => {
    if (name?.length <= 10) {
      return name;
    }
    const changeName = `${name?.slice(0, 10)}..`;
    return changeName;
  };

  return (
    <Stack my="1rem">
      {loading ? (
        <CustomLoadingScreen height="30vh" />
      ) : (
        <>
          {!menuSalesReport?.length ? (
            <Stack alignItems={'center'}>
              <TableNoData isNotFound={!menuSalesReport?.length} />
            </Stack>
          ) : (
            <>
              {menuSalesReport?.map((categories, mainIndex) => (
                <Accordion
                  sx={{ paddingBlock: '0.1rem', borderBottom: '0.5px solid rgba(145, 158, 171, 0.24)' }}
                  defaultExpanded
                  key={mainIndex}
                >
                  <AccordionSummary
                    expandIcon={<Icon icon="ic:outline-expand-more" width="25px" />}
                    aria-controls="Menu with Categories"
                    id={categories?.id}
                  >
                    <Stack direction={'row'} justifyContent={'space-between'} width="100%">
                      <Stack direction={'row'} alignItems={'center'} spacing={1}>
                        <Icon icon="tabler:category" width={'25px'} />
                        <Stack>
                          <p style={{ fontWeight: '600' }}>{categories?.name}</p>
                          <p style={{ color: '#637381' }}>{categories?.menus?.length} Items</p>
                        </Stack>
                      </Stack>
                      <Stack className={'manageMenuSizeList'}>
                        {categories?.sizesUQ?.map((categorySize) => (
                          <Box
                            key={categorySize?.id}
                            className="manageItemsOfMenuSizeList manageItemsOfMenuSizeListColor"
                          >
                            {checkSizeNameLength(categorySize?.name)}
                          </Box>
                        ))}
                      </Stack>
                    </Stack>
                  </AccordionSummary>

                  <AccordionDetails sx={{ background: 'rgba(145, 158, 171, 0.12)' }}>
                    {categories?.menus?.map((menu, index) => (
                      <FoodMenuMenusReportAccordion key={index} menu={menu} categorySize={categories?.sizesUQ} />
                    ))}
                  </AccordionDetails>
                </Accordion>
              ))}
            </>
          )}
        </>
      )}
    </Stack>
  );
}

export default FoodMenuReportBody;
