import Page from 'components/Page';
import React from 'react';
import AnnouncementHeader from './AnnouncementHeader';
import AnnouncementBody from './AnnouncementBody';

function AnnouncementIndex() {
  return (
    <Page title="Announcement">
      <AnnouncementHeader />
      <AnnouncementBody />
    </Page>
  );
}

export default AnnouncementIndex;
