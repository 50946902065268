import {
  Autocomplete,
  Button,
  Card,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { useState, useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { getIngredientList } from 'redux/slices/ingredientSlice';

function FoodMenuIngredientSearch() {
  const [first, setFirst] = useState(null);
  const { allIngredients } = useSelector((state) => state.ingredients);
  const { menuDetails } = useSelector((state) => state.foodMenus);

  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const { control, setValue } = useFormContext();

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'ingredients',
    keyName: 'ingredientsFieldId',
  });

  const {
    fields: addFields,
    append: addAppend,
    remove: addRemove,
  } = useFieldArray({
    control,
    name: 'ingredientsAdded',
  });

  const {
    fields: removeFields,
    append: removeAppend,
    remove: removeRemove,
  } = useFieldArray({
    control,
    name: 'ingredientsRemoved',
  });

  const IngredientAddedHandler = (data) => {
    // if ingredient already exist than not adding it again

    const findIngredients = fields?.find((el) => el.ingredientId === data.id);
    if (findIngredients) return;

    // if a user mistakely remove already saved ingredients and added it again
    if (Object.keys(menuDetails).length) {
      const checkMenuDetails = menuDetails?.ingredients?.find((el) => el?.ingredientId === data?.id);
      if (checkMenuDetails) {
        const checkRemoveFields = removeFields.findIndex((obj) => obj === data?.id);
        removeRemove(checkRemoveFields);
      } else {
        addAppend(data?.id);
      }
    } else {
      addAppend(data?.id);
    }
    append({ ...data, ingredientId: data?.id });
    setValue('search', '');
    setFirst('');
  };

  const IngredientRemoveHandler = (index, obj) => {
    // checking if ingredients exist in menuDetails or not
    if (Object.keys(menuDetails).length > 0) {
      const checkMenuDetails = menuDetails?.ingredients?.find((el) => el?.ingredientId === obj?.ingredientId);

      if (checkMenuDetails) {
        removeAppend(obj?.ingredientId);
      } else {
        const checkRemoveFields = addFields.findIndex((obj) => obj === obj?.ingredientId);
        addRemove(checkRemoveFields);
      }
    } else {
      const checkRemoveFields = addFields.findIndex((obj) => obj === obj?.ingredientId);
      addRemove(checkRemoveFields);
    }
    remove(index);
  };

  useEffect(() => {
    if (!departmentId) return;
    const promise = dispatch(getIngredientList({ departmentId, searchText: first, pageNo: 1, pageSize: 3 }));
    return () => promise.abort();
  }, [first, dispatch, departmentId]);
  return (
    <Card>
      <Stack my="1rem " mx="1rem">
        <RHFTextField
          name="search"
          value={first}
          placeholder={'Search Ingredients'}
          onChange={(event) => setFirst(event.target.value)}
          label="Search Ingredients"
        />
        <Stack py="0.5rem" bgcolor={'rgba(145, 158, 171, 0.12)'} px="0.5rem">
          {allIngredients?.ingredients?.map((el) => (
            <Stack
              key={el?.id}
              py={'0.3rem'}
              px={'0.3rem'}
              my={'0.1rem'}
              divider={<Divider />}
              direction="row"
              alignItems={'center'}
              gap="0.5rem"
              borderRadius={'10px'}
              sx={{ cursor: 'pointer', ':hover': { background: 'white' } }}
              onClick={() => IngredientAddedHandler(el)}
            >
              {el?.name}
              <Divider />
            </Stack>
          ))}
        </Stack>
        <Stack mt="1rem">
          <h4>Ingredients Details</h4>

          <Stack>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Ingredient Name</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields?.map((obj, index) => (
                    <TableRow key={index}>
                      <TableCell>{obj?.name}</TableCell>
                      <TableCell>
                        <Button
                          color="error"
                          size="small"
                          variant="contained"
                          onClick={() => IngredientRemoveHandler(index, obj)}
                        >
                          Remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
}

export default FoodMenuIngredientSearch;
