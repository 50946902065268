import {
  Box,
  Button,
  Chip,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Scrollbar from 'components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteFoodCategories, getFoodCategories, getFoodSizes } from 'redux/slices/FoodCategoriesAndSizeSlice';
import FoodCategoriesEdit from './FoodCategoriesEdit';

const TABLE_HEAD = [
  { id: 1, label: 'S.N', align: 'left' },
  { id: 2, label: 'Name', align: 'left' },
  { id: 3, label: 'Sizes', align: 'left' },
  { id: 4, label: 'Actions', align: 'left' },
];

function FoodCategoriesIndex() {
  const { categoryList, loading } = useSelector((state) => state.foodCategoriesAndSize);
  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, id: null });
  const [openUpdateModal, setOpenUpdateModal] = useState({ status: false, data: null });

  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const handlePaginationChange = (event, value) => {
    dispatch(getFoodSizes({ departmentId, pageNo: value }));
  };

  const handleDeleteDialog = () => {
    setOpenDeleteModal({ id: null, status: false });
  };

  const handleUpdateDialog = () => {
    setOpenUpdateModal({ data: null, status: false });
  };

  const onDeleteCategoryAccept = () => {
    dispatch(deleteFoodCategories({ departmentId, id: openDeleteModal?.id }));
    handleDeleteDialog();
  };

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getFoodCategories({ departmentId, pageNo: 1 }));
    dispatch(getFoodSizes({ departmentId }));
  }, [dispatch, departmentId]);
  return (
    <>
      <Scrollbar>
        <TableContainer>
          <Table stickyHeader>
            <TableHeadCustom headLabel={TABLE_HEAD} />

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={11}>
                    <CustomLoadingScreen height="30vh" />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {categoryList?.categories?.length <= 0 ? (
                    <TableNoData isNotFound={categoryList?.categories?.length <= 0} />
                  ) : (
                    <>
                      {categoryList?.categories?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>{row?.name}</TableCell>
                          <TableCell>
                            <Stack flexWrap={'wrap'} direction={'row'} gap="0.5rem">
                              {row?.sizes?.map((el, index) => (
                                <Chip key={index} label={el?.name} />
                              ))}
                            </Stack>
                          </TableCell>

                          <TableCell>
                            <Stack gap="1rem" alignItems="center" direction="row">
                              <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => {
                                  setOpenUpdateModal({ status: true, data: row });
                                }}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="contained"
                                color="error"
                                onClick={() => {
                                  setOpenDeleteModal({ status: true, id: row?.id });
                                }}
                              >
                                Delete
                              </Button>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: 'relative' }}>
        <Pagination
          count={categoryList?.paginationInfo?.totalPages}
          page={categoryList?.paginationInfo?.pageNo + 1}
          onChange={handlePaginationChange}
          className="pagination-list-container"
          sx={{ px: 3, py: 1.5, justifyContent: 'flex-end', borderTop: 'solid 1px rgba(145, 158, 171, 0.24)' }}
        />
      </Box>

      {openDeleteModal?.status && (
        <CustomDeleteDialog open={openDeleteModal} handleClose={handleDeleteDialog} onAccept={onDeleteCategoryAccept} />
      )}

      {openUpdateModal?.status && (
        // <FoodSizeEdit open={openDeleteModal} handleClose={handleUpdateDialog} currentData={openUpdateModal?.data} />
        <FoodCategoriesEdit
          open={openUpdateModal}
          handleClose={handleUpdateDialog}
          currentData={openUpdateModal?.data}
        />
      )}
    </>
  );
}

export default FoodCategoriesIndex;
