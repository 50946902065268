import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFoodMenuDetails, updateFoodMenu } from 'redux/slices/FoodMenuSlice';
import FoodMenuForm from 'sections/@dashboard/foodMenuAndRecipe/FoodMenu/FoodMenuForm';

function FoodMenuEdit({ menuId, isSuccess }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const { menuDetails, detailsLoading } = useSelector((state) => state.foodMenus);

  const FoodMenuUpdateHandler = (data) => {
    const formData = new FormData();

    if (data?.file) {
      formData.append('file', data?.file);
    }
    const finalData = {
      departmentId,
      id: data?.id,
      name: data?.name,
      description: data?.description,
      menuType: data?.menuType,
      categoryId: data?.categoryId?.id,
      ingredientsAdded: data?.ingredientsAdded,
      ingredientsRemoved: data?.ingredientsRemoved,
    };
    formData.append('menu', new Blob([JSON.stringify({ ...finalData })], { type: 'application/json' }));
    const checkSuccess = () => {
      isSuccess({ name: data?.name, file: data?.file });
    };

    dispatch(updateFoodMenu({ finalData: formData, departmentId, checkSuccess }));
  };

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getFoodMenuDetails({ menuId, departmentId }));
  }, [dispatch, departmentId, menuId]);

  return (
    <>
      {detailsLoading ? (
        <CustomLoadingScreen height="70vh" />
      ) : (
        <FoodMenuForm isEdit currentData={menuDetails} onSubmit={FoodMenuUpdateHandler} />
      )}
    </>
  );
}

export default FoodMenuEdit;
