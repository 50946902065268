import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import { errorToastTime, successToastTime } from 'config';

export const getHalfAndHalfConfig = createAsyncThunk('halfAndHalf/getHalfAndHalfConfig', async (props) => {
  try {
    const response = await apiURL.get(`/storeConf/halfHalf/${props?.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const postHalfAndHalfConfig = createAsyncThunk('halfAndHalf/postHalfAndHalfConfig', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/storeConf/halfHalf/${props?.departmentId}`, props?.finalData);
    if (response.status === 200) {
      toast.success('Half and Half Config Updated Successfully', { autoClose: successToastTime });
      thunkAPI.dispatch(getHalfAndHalfConfig({ departmentId: props?.departmentId }));
    }
    return response?.data;
  } catch (err) {
    toast.error(err?.response?.data?.message, { autoClose: errorToastTime });
    throw err;
  }
});

const halfAndHalfSlice = createSlice({
  name: 'halfAndHalf',
  initialState: {
    loading: false,
    postLoading: false,
    halfAndHalfData: [],
  },
  reducers: {},
  extraReducers: {
    [getHalfAndHalfConfig.pending]: (state) => {
      state.loading = true;
      state.halfAndHalfData = [];
    },
    [getHalfAndHalfConfig.fulfilled]: (state, action) => {
      state.loading = false;
      state.halfAndHalfData = action.payload;
    },
    [getHalfAndHalfConfig.rejected]: (state) => {
      state.loading = false;
      state.halfAndHalfData = [];
    },

    [postHalfAndHalfConfig.pending]: (state) => {
      state.postLoading = true;
    },
    [postHalfAndHalfConfig.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [postHalfAndHalfConfig.rejected]: (state) => {
      state.postLoading = false;
    },
  },
});

export default halfAndHalfSlice.reducer;
