import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  MenuItem,
  Stack,
} from '@mui/material';
import { FormProvider } from 'components/hook-form';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { getUserRole } from 'redux/slices/UserRolesSlice';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import CustomSelectField from 'components/CustomComponents/CustomSelectField';
import { getAllFeatures, getRoleBasedFeatures, saveRoleFeatures } from 'redux/slices/featureSlice';

export default function FeatureCard() {
  const dispatch = useDispatch();
  //   get role from store
  const { role, loading: roleLoading } = useSelector((state) => state.userRole);

  //  get Allfeature from store
  const { allFeatures, loading: allFeatureLoading } = useSelector((state) => state.feature);

  // getting role based features
  const { roleBasedFeature } = useSelector((state) => state.feature);

  const defaultValues = {
    selectedFeatures: [],
    addedFeatureIds: [],
    removedFeatureIds: [],
    roleId: '',
  };

  const methods = useForm({ defaultValues, mode: 'onChange' });
  const { control, watch, reset, handleSubmit } = methods;

  const {
    fields: removeFields,
    append: removeAppend,
    remove: removeRemove,
  } = useFieldArray({
    control,
    name: 'removedFeatureIds',
  });

  const {
    fields: addFields,
    append: addAppend,
    remove: addRemove,
  } = useFieldArray({
    control,
    name: 'addedFeatureIds',
  });

  const watchRoleId = watch('roleId');

  const roleFeatureSubmitHandler = (data) => {
    const Dataset = {
      roleId: data?.roleId,
      addedFeatureIds: data?.addedFeatureIds,
      removedFeatureIds: data?.removedFeatureIds,
    };
    dispatch(saveRoleFeatures(Dataset));
    reset();
  };

  const onChangeFeatures = (event, checked, field) => {
    // roleBasedFeature = existing feature of the selected role
    const id = Number(event.target.value);
    if (checked) {
      if (!roleBasedFeature.includes(id)) {
        addAppend(id);
      } else {
        const findRemoveIndex = removeFields.findIndex((item) => item === id);
        removeRemove(findRemoveIndex);
      }
      field.onChange([...field.value, id]);
    } else {
      if (!roleBasedFeature.includes(id)) {
        const findAddIndex = addFields.findIndex((item) => item === id);
        addRemove(findAddIndex);
      } else {
        removeAppend(id);
      }
      field.onChange(field.value.filter((value) => value !== id));
    }
  };

  useEffect(() => {
    dispatch(getUserRole());
    dispatch(getAllFeatures());
  }, [dispatch]);
  useEffect(() => {
    if (watchRoleId) {
      dispatch(getRoleBasedFeatures(watchRoleId));
    }
  }, [watchRoleId, dispatch]);

  return (
    <>
      <Card sx={{ p: 3 }}>
        <FormProvider methods={methods} onSubmit={handleSubmit(roleFeatureSubmitHandler)}>
          {roleLoading ? (
            <CustomLoadingScreen />
          ) : (
            <>
              <Stack spacing={2} sx={{ width: 1 }}>
                <CustomSelectField name="roleId" label="Select Role" sx={{ width: '15rem' }}>
                  {role?.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {' '}
                      {item.title}{' '}
                    </MenuItem>
                  ))}
                </CustomSelectField>
                {watchRoleId && (
                  <>
                    {allFeatureLoading ? (
                      <CustomLoadingScreen />
                    ) : (
                      <FormControl component="fieldset" variant="standard">
                        <FormLabel component="legend">Select Features</FormLabel>
                        <FormGroup>
                          <Controller
                            name="selectedFeatures"
                            control={methods.control}
                            render={({ field }) => (
                              <>
                                {allFeatures?.map((item) => (
                                  <FormControlLabel
                                    key={item.id}
                                    label={item.title}
                                    control={
                                      <Checkbox
                                        value={item.id}
                                        {...(roleBasedFeature.includes(item.id) ? { defaultChecked: true } : '')}
                                        onChange={(event, checked) => onChangeFeatures(event, checked, field)}
                                      />
                                    }
                                  />
                                ))}
                              </>
                            )}
                          />
                        </FormGroup>
                      </FormControl>
                    )}
                    <Box>
                      <Button type="submit" variant="contained" color="primary">
                        Apply Features
                      </Button>
                    </Box>
                  </>
                )}
              </Stack>
            </>
          )}
        </FormProvider>
      </Card>
    </>
  );
}
