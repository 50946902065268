import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiURL from 'api';
import { errorToastTime } from 'config';
import { toast } from 'react-toastify';

export const getRoaster = createAsyncThunk('roaster/getRoaster', async (props) => {
  try {
    const response = await apiURL.post(`/roaster/getRoaster`, props);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

// a thunk function for past roaster api
export const getPreviousDateRoaster = createAsyncThunk('roaster/getPreviousDateRoaster', async (props) => {
  try {
    const response = await apiURL.post(`/roaster/getRoaster`, props);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createAndUpdateRoaster = createAsyncThunk(
  'roaster/createAndUpdateRoaster',
  async ({ finalData, isSuccess }) => {
    try {
      const response = await apiURL.post(`/roaster`, finalData);
      // toast.success('Roaster Updated Successfully.');
      isSuccess(true);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message || 'Something went wrong. Please Try Again', {
        autoClose: errorToastTime,
      });
      throw error;
    }
  }
);

export const getQuickSummary = createAsyncThunk('roaster/getQuickSummary', async (props) => {
  const response = await apiURL.post(`/report/getQuickSummary`, props);
  return response.data;
});

export const downloadRoaster = createAsyncThunk('roaster/downloadPDF', async (data) => {
  try {
    const response = await apiURL.post('/roaster/roasterPdf', data, { responseType: 'blob' });
    if (response.status === 200) {
      const fileURL = window.URL.createObjectURL(response.data);
      const dwLink = document.createElement('a');
      dwLink.href = fileURL;
      dwLink.download = `roasterPDF${data.anyDateOfWeek}.pdf`;
      dwLink.click();
    }
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

const RoasterSlice = createSlice({
  name: 'roasterRedux',
  initialState: {
    loading: false,
    postLoading: false,
    allRoaster: {},
    allSummary: {},
    selectedRoaster: {},
    isPreviousDate: false,
    modalControl: {
      open: false,
    },
    pastRoaster: {},
    draftRoaster: {},
    weeklyPercentage: null,
    summaryWeek: null,
  },
  reducers: {
    roasterModalControlAction(state, action) {
      state.modalControl.open = true;
      state.selectedRoaster = action.payload;
    },
    setPastRoaster(state, action) {
      state.pastRoaster = action.payload;
    },
    removePastRoaster(state) {
      state.pastRoaster = {};
    },

    clearAllRoaster(state) {
      state.allRoaster = {};
    },

    roasterModalFalseControlAction(state) {
      state.modalControl.open = false;
      state.selectedRoaster = {};
    },
    isPreviousDate(state) {
      state.isPreviousDate = true;
    },
    isNotPreviousDate(state) {
      state.isPreviousDate = false;
    },
    setWeeklyPercentage(state, action) {
      state.weeklyPercentage = action.payload;
    },
    setSummaryWeek(state, action) {
      state.summaryWeek = action.payload;
    },
  },

  extraReducers: {
    [getRoaster.pending]: (state) => {
      state.loading = true;
      state.allRoaster = {};
    },
    [getRoaster.fulfilled]: (state, action) => {
      state.loading = false;
      state.allRoaster = action.payload;
    },
    [getRoaster.rejected]: (state) => {
      state.loading = false;
      state.allRoaster = {};
    },
    [createAndUpdateRoaster.pending]: (state) => {
      state.postLoading = true;
    },
    [createAndUpdateRoaster.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [createAndUpdateRoaster.rejected]: (state) => {
      state.postLoading = false;
    },

    [getPreviousDateRoaster.pending]: (state) => {
      state.postLoading = true;
    },
    [getPreviousDateRoaster.fulfilled]: (state, action) => {
      state.postLoading = false;
      state.pastRoaster = action.payload;
    },
    [getPreviousDateRoaster.rejected]: (state) => {
      state.postLoading = false;
    },

    [getQuickSummary.pending]: (state) => {
      state.loading = true;
    },
    [getQuickSummary.fulfilled]: (state, action) => {
      state.loading = false;
      state.allSummary = action.payload;
    },
    [getQuickSummary.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const {
  roasterModalControlAction,
  clearAllRoaster,
  updateAllRoaster,
  roasterModalFalseControlAction,
  setPastRoaster,
  removePastRoaster,
  isPreviousDate,
  isNotPreviousDate,
  setWeeklyPercentage,
  setSummaryWeek,
} = RoasterSlice.actions;

export default RoasterSlice.reducer;
