import { Box, Card, Stack, Tab, Tabs } from '@mui/material';
import { TableNoData } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getFoodCategory } from 'redux/slices/menuEngineeringSlice';
import { setRecommendedMenuCurrentTab } from 'redux/slices/mostLikedMenusSlice';
import RecommendedMenusInnerBody from './RecommendedMenusInnerBody';

function RecommendedMenusBody() {
  const { foodCategories } = useSelector((state) => state.menuEngineering);
  const { recommendedMenuCurrentTab } = useSelector((state) => state.mostLikedMenus);

  const departmentId = useGetDepartmentId();

  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    dispatch(setRecommendedMenuCurrentTab(newValue));
  };

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getFoodCategory({ departmentId }));
  }, [dispatch, departmentId]);

  return (
    <>
      {foodCategories?.length === 0 ? (
        <Stack alignItems={'center'} justifyContent={'center'}>
          <TableNoData isNotFound={foodCategories?.length === 0} />
        </Stack>
      ) : (
        <Card>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={recommendedMenuCurrentTab} onChange={handleChange} aria-label="Menu Edit" variant="scrollable">
              <Tab label="Defaut recommended" value={null} />
              {foodCategories.map((foodCategory, index) => (
                <Tab key={index} label={foodCategory.name} value={foodCategory.id} />
              ))}
            </Tabs>
          </Box>
          {recommendedMenuCurrentTab === null && <RecommendedMenusInnerBody isDefault />}
          {/* eslint-disable-next-line array-callback-return, consistent-return */}
          {foodCategories?.map((foodCategory, index) => {
            if (foodCategory.id === recommendedMenuCurrentTab) {
              return <RecommendedMenusInnerBody key={index} foodCategory={foodCategory} />;
            }
          })}
        </Card>
      )}
    </>
  );
}

export default RecommendedMenusBody;
