import { Accordion, Stack } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import TimeClockAccordionSummary from './TimeClockAccordionSummary';
import TimeClockAccordionDetails from './TimeClockAccordionDetails';

TimeClockAccordion.propTypes = {
  user: PropTypes.object,
  userIndex: PropTypes.number,
};

function TimeClockAccordion({ user, userIndex }) {
  const checkUser = Object.values(user?.timeClocks).flat().length || 0;
  if (!checkUser) {
    return null;
  }
  return (
    <Stack>
      <Accordion defaultExpanded>
        <TimeClockAccordionSummary user={user} />
        <TimeClockAccordionDetails user={user} userIndex={userIndex} />
      </Accordion>
    </Stack>
  );
}

export default TimeClockAccordion;
