// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { DatePicker } from '@mui/x-date-pickers';
import { Stack, TextField, MenuItem } from '@mui/material';
import { RHFSelect, RHFTextField } from 'components/hook-form';
import moment from 'moment';
import { useSelector } from 'react-redux';
import CustomSelectField from 'components/CustomComponents/CustomSelectField';
// components

// ----------------------------------------------------------------------

// const STATUS_OPTIONS = ['paid', 'unpaid', 'overdue', 'draft'];

// ----------------------------------------------------------------------

export default function InvoiceEditStatusSection() {
  const { control, watch, getValues, setValue } = useFormContext();
  const { storeSuppliers } = useSelector((state) => state.store);

  const values = watch();

  const onSelectSupplier = (event) => {
    const supplierId = event.target.value;
    const supplierDue = storeSuppliers?.find((el) => el?.id === supplierId).dueDays;
    setValue('supplierId', supplierId);
    const dueDates = moment(getValues('invoiceDate')).add(supplierDue, 'days').toDate();
    setValue('dueDate', dueDates);
  };

  const onChangeInvoiceDate = (newValue) => {
    const selectedSuppliers = getValues('supplierId');
    const supplierDue = storeSuppliers?.find((el) => el?.id === Number(selectedSuppliers)).dueDays || 0;
    const dueDates = moment(newValue).add(supplierDue, 'days').toDate();
    setValue('dueDate', dueDates);
  };

  return (
    <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ p: 3, bgcolor: 'background.neutral' }}>
      <Controller
        name="supplierId"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <TextField
            {...field}
            select
            fullWidth
            label="Select Suppliers"
            InputLabelProps={{ shrink: true }}
            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
            error={!!error}
            helperText={error?.message}
            onChange={(event) => {
              field.onChange(event.target.value);
              onSelectSupplier(event);
            }}
          >
            {storeSuppliers?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        )}
      />

      <RHFTextField name="invoiceNumber" label="Invoice number" />
      <Controller
        name="invoiceDate"
        control={control}
        defaultValue={`${new Date(values?.invoiceDate)}`}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            label="Invoice Date"
            value={field.value}
            onChange={(newValue) => {
              field.onChange(newValue);
              onChangeInvoiceDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} />}
          />
        )}
      />
      <Controller
        name="dueDate"
        control={control}
        render={({ field, fieldState: { error } }) => (
          <DatePicker
            label="Due date"
            {...field}
            minDate={getValues('invoiceDate')}
            renderInput={(params) => <TextField {...params} fullWidth error={!!error} helperText={error?.message} />}
          />
        )}
      />
    </Stack>
  );
}
