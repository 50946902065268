import { useNavigate, useParams } from 'react-router';
import { Button, Card, Container, Stack, Typography } from '@mui/material';
import Page from 'components/Page';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import { useForm, useWatch } from 'react-hook-form';
import { FormProvider } from 'components/hook-form';
import { useState, useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { submitAgreementDocument, validateOHNSToken } from 'redux/slices/authSlice';
import StaffCreateActiveStepperTermsConditionSignature from 'sections/@dashboard/staffCreate/StaffCreateActiveStepperTermsConditionSignature';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { toSvg } from 'html-to-image';
import logoImg from 'assets/logoImg.jpeg';

import PDFRender from '../SendHR/PDFRender';

function SendOHNS() {
  const { documentPDF, tokenValidationLoading, documentSubmitLoding } = useSelector((state) => state.auth);
  const [isSucceed, setIsSuccess] = useState(false);
  const newRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();

  const userToken = token;

  const defaultValues = {
    fullName: '',
  };
  const methods = useForm({ defaultValues });
  const { control, setValue, handleSubmit } = methods;

  const watchTandC = useWatch({
    control,
    name: 'acceptTermsAndConditions',
  });
  const watchName = useWatch({
    control,
    name: 'declaration.fullName',
  });

  const watchDeclaredSignatures = useWatch({
    control,
    name: 'declaration.signature',
  });

  const watchCertifyBySigning = useWatch({
    control,
    name: 'certifyBySigning',
  });

  const isSuccess = () => {
    setIsSuccess(true);
  };

  const SOPSubmitHandler = (data) => {
    if (!data?.declaration?.fullName) return toast.warning('Please enter your name');
    const formData = new FormData();
    if (data?.sign) {
      formData.append('file', data.sign, 'file.svg');
    }

    const finalData = {
      type: 'OHNS',
      token: userToken,
    };
    formData.append('token', new Blob([JSON.stringify({ ...finalData })], { type: 'application/json' }));
    dispatch(submitAgreementDocument({ formData, isSuccess }));
  };

  useEffect(() => {
    dispatch(validateOHNSToken({ token, navigate }));
  }, [dispatch, token, navigate]);

  const filter = (node) => {
    const exclusionClasses = ['createSignatureButtonDeclarationForm'];
    return !exclusionClasses.some((classname) => node.classList?.contains(classname));
  };

  useEffect(() => {
    const newFunction = async () => {
      const data = await toSvg(newRef?.current, { filter });
      const decodeData = decodeURIComponent(data);
      const subStringData = decodeData.substring(decodeData.indexOf(',') + 1);

      const svgBlob = new Blob([subStringData], { type: 'image/svg+xml' });
      setValue('sign', svgBlob);
    };
    newFunction();
  }, [setValue, watchTandC, watchDeclaredSignatures, watchCertifyBySigning]);

  return (
    <>
      <LogoOnlyLayout />
      {isSucceed ? (
        <Stack height="70vh" alignItems="center" justifyContent={'center'} gap="1rem">
          <img src={logoImg} alt="pbilogo" style={{ borderRadius: '50%', width: '15rem' }} />
          <h1>Your Information is Recorded successfully. Thank You</h1>
        </Stack>
      ) : (
        <Page title="OHNS Document">
          <Container sx={{ marginBlock: '6rem' }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(SOPSubmitHandler)}>
              {tokenValidationLoading ? (
                <CustomLoadingScreen height={'50vh'} />
              ) : (
                <Card style={{ padding: '20px' }}>
                  <Stack direction="column" spacing={2} alignItems={'center'}>
                    <Typography variant="h6" color="error">
                      Please Read the provided document and sign to agree the T&C.
                    </Typography>

                    <PDFRender title="OHNS Document" url={documentPDF} />
                  </Stack>
                  <Stack className="UserAcceptanceDeclarationForm" gap={2}>
                    <StaffCreateActiveStepperTermsConditionSignature newRef={newRef} />
                  </Stack>
                  <Stack direction="row" justifyContent="flex-end" mt={2}>
                    <Button
                      variant="contained"
                      size="small"
                      type="submit"
                      disabled={!watchTandC || !watchName || documentSubmitLoding || !watchCertifyBySigning}
                    >
                      {documentSubmitLoding ? (
                        <CustomLoadingScreen circularWidth={'20px'} color="secondary" />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  </Stack>
                </Card>
              )}
            </FormProvider>
          </Container>
        </Page>
      )}
    </>
  );
}

export default SendOHNS;
