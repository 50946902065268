import { Box, Card, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeFoodCurrentTab } from 'redux/slices/FoodCategoriesAndSizeSlice';
import FoodSizeIndex from './FoodSize/FoodSizeIndex';
import FoodCategoriesIndex from './FoodCategories';

function FoodCategoryBody() {
  const { currentTab } = useSelector((state) => state.foodCategoriesAndSize);
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    dispatch(changeFoodCurrentTab(newValue));
  };
  return (
    <Card>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingLeft: '0.7rem' }}>
        <Tabs value={currentTab} onChange={handleChange} aria-label="Stock and Order Tabs">
          <Tab label="Category" id="0" />
          <Tab label="Size" id="1" />
        </Tabs>
      </Box>

      {currentTab === 0 && <FoodCategoriesIndex />}

      {currentTab === 1 && <FoodSizeIndex />}
    </Card>
  );
}

export default FoodCategoryBody;
