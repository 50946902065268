import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import Iconify from 'components/Iconify';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { getAllMenusList } from 'redux/slices/FoodMenuSlice';
import { toast } from 'react-toastify';
import { getIngredientDetails, getIngredientMenusList, updateIngredientMenus } from 'redux/slices/ingredientSlice';
import Scrollbar from 'components/Scrollbar';

const TABLE_HEAD = [
  { id: 1, name: 'S/N' },
  { id: 2, name: 'Name' },
  { id: 3, name: 'Menu Type' },
];

IngredientAddMenusForm.propTypes = {
  currentData: PropTypes.number,
};

function IngredientAddMenusForm({ currentData }) {
  const getDepartment = useGetDepartmentId();
  const [searchText, setSearchText] = useState(null);
  const { allMenuList } = useSelector((state) => state.foodMenus);
  const { ingredientMenus } = useSelector((state) => state.ingredients);
  const { ingredientDetails } = useSelector((state) => state.ingredients);

  const dispatch = useDispatch();

  const defaultValues = {
    addMenuIds: [],
    removeMenuIds: [],
  };

  const methods = useForm({ defaultValues, mode: 'onChange' });
  const { handleSubmit, control, reset } = methods;

  const {
    fields: removeFields,
    append: removeAppend,
    remove: removeRemove,
  } = useFieldArray({
    control,
    name: 'removeMenuIds',
  });

  const {
    fields: addFields,
    append: addAppend,
    remove: addRemove,
  } = useFieldArray({
    control,
    name: 'addMenuIds',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'menus',
    keyName: 'menuFieldId',
  });

  const UpdateIngredientMenusList = (data) => {
    const finalData = {
      name: ingredientDetails?.name,
      isAllergic: ingredientDetails?.isAllergic,
      id: ingredientDetails?.id,
      departmentId: getDepartment,
      storageInstruction: ingredientDetails?.storageInstruction,
      type: ingredientDetails?.type,
      stockItems: ingredientDetails?.stockItems,
      addMenuIds: data?.addMenuIds,
      removeMenuIds: data?.removeMenuIds,
    };
    const formData = new FormData();

    formData.append('ingredient', new Blob([JSON.stringify(finalData)], { type: 'application/json' }));
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        toast.success('Ingredient Menu Updated');
      } else {
        toast.error('Failed to update Ingredient Menu');
      }
    };

    dispatch(updateIngredientMenus({ formData, isSuccess: isSuccessful }));
  };

  const MenuAddHandler = (item) => {
    const id = Number(item?.id);
    if (ingredientMenus?.menus?.find((obj) => obj?.menuId === id)) {
      toast.warning('Menu already exist on ingredient');
    } else if (!ingredientMenus?.menus?.find((obj) => obj?.menuId === id) && fields?.find((item) => item.id === id)) {
      toast.warning('Menu already exist on form');
    } else {
      append(item);
      addAppend(item.id);
      setSearchText('');
    }
  };

  const MenuRemoveHandler = (index) => {
    const id = Number(fields[index]?.id);
    if (ingredientMenus?.menus?.find((obj) => obj?.id === id)) {
      removeAppend(fields[index].menuId);
      remove(index);
    } else if (!ingredientMenus?.menus?.find((obj) => obj?.id === id)) {
      addRemove(fields[index].id);
      remove(index);
    }
  };

  useEffect(() => {
    if (!ingredientMenus?.menus) return;
    reset({ menus: ingredientMenus?.menus });
  }, [ingredientMenus, reset]);

  useEffect(() => {
    if (!getDepartment) return;
    const promise = dispatch(
      getAllMenusList({ departmentId: getDepartment, searchTxt: searchText, pageNo: 1, pageSize: 3 })
    );
    return () => promise.abort();
  }, [dispatch, getDepartment, searchText]);

  useEffect(() => {
    if (!getDepartment) return;
    dispatch(getIngredientMenusList({ departmentId: getDepartment, ingredientId: currentData }));
    //  dispatch(getIngredientDetails({ departmentId: getDepartment, id: currentData }));
  }, [getDepartment, currentData, dispatch]);
  return (
    <FormProvider {...methods}>
      <Stack mt="1rem" mx=".5rem">
        <Stack my="1rem">
          <TextField
            fullWidth
            label="Search Stock"
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
          />
          {/* <RHFTextField
            name="search"
            placeholder={'Search Menus'}
            onChange={(event) => setSearchText(event.target.value)}
            label="Search Menus"
          /> */}
          <Stack py="0.5rem" bgcolor={'rgba(145, 158, 171, 0.12)'} px="0.5rem">
            {allMenuList?.menus?.map((obj) => (
              <Stack
                key={obj?.id}
                py={'0.3rem'}
                px={'0.3rem'}
                my={'0.1rem'}
                divider={<Divider />}
                direction="row"
                alignItems={'center'}
                gap="0.5rem"
                borderRadius={'10px'}
                sx={{ cursor: 'pointer', ':hover': { background: 'white' } }}
                onClick={() => MenuAddHandler(obj)}
              >
                {obj?.name}
                <Divider />
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Stack className="stackOrderModalContent2">
          <Scrollbar>
            <TableContainer sx={{ minWidth: 1100 }}>
              <Table stickyHeader style={{ maxWidth: '800px' }} size="small">
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((obj) => (
                      <TableCell key={obj.id}>{obj?.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fields?.map((obj, index) => (
                    <TableRow key={index} sx={{ borderBottom: '1px solid #F4F6F8' }}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{obj?.name}</TableCell>
                      <TableCell>{obj?.menuType ? obj.menuType : obj.description}</TableCell>
                      <TableCell>
                        <Button
                          variant="text"
                          color="error"
                          onClick={() => MenuRemoveHandler(index)}
                          startIcon={<Iconify icon="material-symbols:delete-rounded" />}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Stack>
        <Stack justifyContent={'flex-start'} direction={'row'}>
          <Button
            style={{ padding: '5px', margin: '5px' }}
            variant="contained"
            type="submit"
            onClick={handleSubmit(UpdateIngredientMenusList)}
          >
            Update
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
}

export default IngredientAddMenusForm;
