import { Box, Button, Divider, InputAdornment, MenuItem, Stack } from '@mui/material';
import CustomSelectField from 'components/CustomComponents/CustomSelectField';
import { FormProvider, RHFSelect, RHFTextField } from 'components/hook-form';
import { useForm, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import StockYupSchema from 'pages/dashboard/stock/StockYupSchema';

const WEIGHT_TYPE = ['KG', 'PCS'];

const UOM_TYPE = ['BAG', 'BTL', 'CTN', 'EACH', 'JAR', 'PKT', 'ROLL', 'TIN', 'TUB', 'TRAY', 'OTHER'];

function StockAddForm({ onSubmit, isUpdate = false, currentData = {} }) {
  const { storeSuppliers } = useSelector((state) => state.store);
  const { postLoading } = useSelector((state) => state.stocks);

  const defaultValues = useMemo(
    () => ({
      id: currentData?.id || null,
      externalUID: currentData?.externalUID || '',
      brand: currentData?.brand || '',
      productDescription: currentData?.productDescription || '',
      uom: currentData?.uom || '',
      unitWeight: currentData?.unitWeight || 0,
      weight: currentData?.weight || 0,
      weightType: currentData?.weightType || '',
      currentQty: currentData?.currentQty || 0,
      minQty: currentData?.minQty || 0,
      packSize: currentData?.packSize || 0,
      parLevel: currentData?.parLevel || 0,
      orderStock: currentData?.orderStock || 0,
      unitPrice: currentData?.unitPrice || 0,
      gst: currentData?.gst || 0,
      levy: currentData?.levy || 0,
      wastePercent: currentData?.wastePercent || 0,
      supplierId: currentData?.supplierId || '',
    }),
    [currentData]
  );

  const methods = useForm({ defaultValues, resolver: yupResolver(StockYupSchema), mode: 'onChange' });

  const { control, handleSubmit } = methods;

  const watchUnitPrice = useWatch({
    control,
    name: 'unitPrice',
  });

  const watchGst = useWatch({
    control,
    name: 'gst',
  });

  const watchCurrentQty = useWatch({
    control,
    name: 'currentQty',
  });

  const watchLevy = useWatch({
    control,
    name: 'levy',
  });

  const watchUnitWeight = useWatch({
    control,
    name: 'unitWeight',
  });

  const totalWeight = Number(watchUnitWeight || 0) * Number(watchCurrentQty || 0);
  const totalPrice = Number(watchCurrentQty) * Number(watchUnitPrice) + Number(watchGst) + Number(watchLevy);
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="column" spacing={2.5}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
          <RHFTextField
            name={`externalUID`}
            label="External UID"
            sx={{ maxWidth: { md: 130 } }}
            InputLabelProps={{ shrink: true }}
          />

          {/* <input type="hidden" {...register(`status`)} value="APPROVED" /> */}

          <RHFTextField
            name={`brand`}
            label="Brand"
            sx={{ maxWidth: { md: 130 } }}
            InputLabelProps={{ shrink: true }}
          />

          <RHFTextField name={`productDescription`} label="Description" InputLabelProps={{ shrink: true }} />

          {/* For UOM */}
        </Stack>
        <Divider />

        {/* for weight type */}

        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
          <RHFSelect
            name={`uom`}
            label="UOM"
            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            sx={{ maxWidth: { md: 150 } }}
          >
            <Divider />

            {UOM_TYPE.map((option, index) => (
              <MenuItem
                key={index}
                value={option}
                // onClick={() => handleSelectService(index, option.name)}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option}
              </MenuItem>
            ))}
          </RHFSelect>

          <RHFSelect name="supplierId" label="Select Supplier" sx={{ width: '10rem' }}>
            {storeSuppliers?.map((option) => (
              <MenuItem
                key={option?.id}
                value={option?.id}
                // onClick={() => handleSelectService(index, option.name)}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option.name}
              </MenuItem>
            ))}
          </RHFSelect>
        </Stack>
        <Divider />

        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
          <RHFSelect
            name={`weightType`}
            label="W Type"
            InputLabelProps={{ shrink: true }}
            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
            sx={{ maxWidth: { md: 80 } }}
          >
            <Divider />

            {WEIGHT_TYPE.map((option) => (
              <MenuItem
                key={option}
                value={option}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option}
              </MenuItem>
            ))}
          </RHFSelect>

          <RHFTextField
            name={`unitWeight`}
            label="Unit Weight"
            InputLabelProps={{ shrink: true }}
            sx={{ maxWidth: { md: 150 } }}
          />
        </Stack>

        <Divider />
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
          <RHFTextField
            //   size="small"
            type="number"
            name={`currentQty`}
            label="Current Qty"
            placeholder="0"
            // onChange={(event) => handleChangeQuantity(event, index)}
            InputLabelProps={{ shrink: true }}
            sx={{ maxWidth: { md: 110 } }}
          />

          <RHFTextField
            //   size="small"
            type="number"
            name={`minQty`}
            label="Min Qty"
            placeholder="0"
            // onChange={(event) => handleChangeQuantity(event, index)}
            InputLabelProps={{ shrink: true }}
            sx={{ maxWidth: { md: 110 } }}
          />

          <RHFTextField
            name={`packSize`}
            label="Pack Size"
            InputLabelProps={{ shrink: true }}
            placeholder="0"
            sx={{ maxWidth: { md: 110 } }}
          />
        </Stack>

        <Divider />

        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
          <RHFTextField
            //   size="small"
            type="number"
            name={`parLevel`}
            label="par Level"
            placeholder="0"
            // onChange={(event) => handleChangeQuantity(event, index)}
            InputLabelProps={{ shrink: true }}
            sx={{ maxWidth: { md: 110 } }}
          />

          <RHFTextField
            //   size="small"
            type="number"
            name={`orderStock`}
            label="Order Stock"
            placeholder="0"
            // onChange={(event) => handleChangeQuantity(event, index)}
            InputLabelProps={{ shrink: true }}
            sx={{ maxWidth: { md: 110 } }}
          />

          <RHFTextField
            //   size="small"
            type="number"
            name={`wastePercent`}
            label="Waste %"
            placeholder="0"
            // onChange={(event) => handleChangeQuantity(event, index)}
            InputLabelProps={{ shrink: true }}
            sx={{ maxWidth: { md: 110 } }}
          />
        </Stack>
        <Divider />

        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
          <RHFTextField
            //   size="small"
            type="number"
            name={`unitPrice`}
            label="Unit Price"
            placeholder="0"
            // onChange={(event) => handleChangePrice(event, index)}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            sx={{ maxWidth: { md: 80 } }}
          />

          <RHFTextField
            //   size="small"
            type="number"
            name={`gst`}
            label="gst"
            placeholder="0"
            // onChange={(event) => handleChangeGST(event, index)}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            sx={{ maxWidth: { md: 80 } }}
          />

          <RHFTextField
            //   size="small"
            type="number"
            name={`levy`}
            label="Levy"
            placeholder="0"
            // onChange={(event) => handleChangeLevy(event, index)}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            sx={{ maxWidth: { md: 80 } }}
          />
        </Stack>
        <Divider />
        <Stack>
          <Box>
            <p style={{ fontSize: '1rem' }}>
              Total : <span style={{ fontSize: '1.2rem', fontWeight: '600' }}>$ {totalPrice.toFixed(2)}</span>
            </p>
            <p>
              Weight :<span style={{ fontSize: '1.2rem', fontWeight: '600' }}>{totalWeight.toFixed(2)} KG</span>
            </p>
          </Box>
        </Stack>

        <Stack alignItems="flex-end">
          <Button variant="contained" type="submit" disabled={postLoading}>
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Stack>
      </Stack>
    </FormProvider>
  );
}

export default StockAddForm;
