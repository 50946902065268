import {
  Box,
  Button,
  Divider,
  MenuItem,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Scrollbar from 'components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'components/table';
import { dispatch, useSelector } from 'redux/store';
import { useEffect, useState } from 'react';
import { getStock, isOpenCreateOrderModalAction } from 'redux/slices/stockSlice';
import { FormProvider, useForm } from 'react-hook-form';

import useGetDepartmentId from 'hooks/useGetDepartmentId';
import StockTableRow from './StockTableRow';
import StockOrderConfirmModal from './StockOrderConfirmModal';

const TABLE_HEAD = [
  { id: 'S.N', label: 'S.N', align: 'left' },
  { id: 'externalUID', label: 'External UID', align: 'left' },
  { id: 'brand', label: 'Brand', align: 'left' },
  { id: 'uom', label: 'uom', align: 'left' },
  { id: 'weight', label: 'weight', align: 'left' },
  { id: 'quantity', label: 'Quantity', align: 'left' },
  { id: 'orderStock', label: 'Order Stock', align: 'left' },

  { id: 'parLevel', label: 'ParLevel', align: 'left' },

  { id: 'unitPrice', label: 'Unit Price', align: 'left' },
  { id: 'gst', label: 'gst', align: 'left' },
  { id: 'levy', label: 'Levy', align: 'left' },
  { id: 'priority', label: 'Priority', align: 'left' },
  { id: 'wastePercent', label: 'Waste %', align: 'left' },
];

const PAGINATION_PAGESIZE = [
  { id: 1, label: 25 },
  { id: 2, label: 50 },
  { id: 3, label: 100 },
];

function StockCardBodyTable() {
  const { loading, allStocks, isCreateStockOrderSwitch } = useSelector((state) => state.stocks);
  const { isCreateOrder, isCreateOrderModal } = isCreateStockOrderSwitch;
  const [pages, setPages] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const departmentId = useGetDepartmentId();

  const methods = useForm();
  const { reset, getValues } = methods;

  useEffect(() => {
    if (!isCreateOrder) {
      reset({});
    }
  }, [isCreateOrder, reset]);

  const handlePaginationChange = (event, value) => {
    if (isCreateOrder) {
      const getAllValues = getValues();
      const filterValues = Object.keys(getAllValues).reduce((acc, current) => {
        const currentData = getAllValues[current];
        if (!currentData?.orderQty) return { ...acc };

        return { ...acc, [current]: { ...currentData } };
      }, {});
      reset({ ...filterValues });
    }
    dispatch(getStock({ departmentId, pageNo: value, pageSize }));
  };

  const PageSizeChangeHandler = (event) => {
    setPageSize(event.target.value);
    dispatch(getStock({ departmentId, pageNo: 1, pageSize: event.target.value }));
  };

  const OrderQuantityParHandler = () => {
    const getAllValues = getValues();
    const filterValues = Object.keys(getAllValues).reduce((acc, current) => {
      const currentData = getAllValues[current];
      if (currentData?.orderQty) return { ...acc, [current]: { ...currentData } };
      const findParLevel = Number(currentData?.parLevel) - Number(currentData?.currentQty);

      return { ...acc, [current]: { ...currentData, orderQty: findParLevel > 0 ? Number(findParLevel) : 1 } };
    }, {});
    reset({ ...filterValues });
  };

  return (
    <>
      <Scrollbar>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {TABLE_HEAD?.map((headCell) => (
                  <TableCell key={headCell.id}>{headCell.label}</TableCell>
                ))}
                <TableCell>
                  {isCreateOrder ? (
                    <Stack alignItems={'center'} flexDirection={'row'}>
                      <Typography fontWeight="600" fontSize="0.875rem" sx={{ width: 'min(8rem,100%)' }}>
                        Qty
                      </Typography>
                      <Button variant="contained" color="secondary" onClick={OrderQuantityParHandler}>
                        Par
                      </Button>
                    </Stack>
                  ) : (
                    'Actions'
                  )}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={14}>
                    <CustomLoadingScreen height="30vh" />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {allStocks?.stockItems?.length <= 0 ? (
                    <TableNoData isNotFound={allStocks?.stockItems?.length <= 0} />
                  ) : (
                    <>
                      <FormProvider {...methods}>
                        {allStocks?.stockItems?.map((row, index) => (
                          <StockTableRow row={row} key={index} rowIndex={index + 1} />
                        ))}

                        {isCreateOrderModal && (
                          <StockOrderConfirmModal
                            open={() => dispatch(isOpenCreateOrderModalAction(true))}
                            setOpen={() => dispatch(isOpenCreateOrderModalAction(false))}
                          />
                        )}
                      </FormProvider>
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>
      <Divider />

      <Stack direction={'row'} gap="1rem" alignItems={'center'} justifyContent={'flex-end'}>
        <Select size="small" label="Select" value={pageSize} onChange={PageSizeChangeHandler}>
          {PAGINATION_PAGESIZE.map((el) => (
            <MenuItem value={el?.label} key={el.id}>
              {el?.label}
            </MenuItem>
          ))}
        </Select>
        <Pagination
          count={allStocks?.paginationInfo?.totalPages}
          page={allStocks?.paginationInfo?.pageNo + 1}
          onChange={handlePaginationChange}
          className="pagination-list-container"
          sx={{ px: 3, py: 1.5, justifyContent: 'flex-end', borderTop: 'solid 1px rgba(145, 158, 171, 0.24)' }}
        />
      </Stack>
    </>
  );
}

export default StockCardBodyTable;
