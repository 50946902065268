import {
  Avatar,
  Button,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { FormProvider } from 'components/hook-form';
import IngredientEdit from 'pages/dashboard/Ingredients/IngredientEdit';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import IngredientAddStockForm from './IngredientAddStockForm';
import IngredientStockAndMenusHandler from './IngredientStockAndMenusHandler';
import RHFText from '../user/viewDetails/RHFText';

function IngredientManageStockForm({ currentData }) {
  const { ingredientDetails, postLoading } = useSelector((state) => state.ingredients);

  const [isIngredientEdit, setIsIngredientEdit] = useState(false);

  const methods = useForm();
  const { handleSubmit, reset } = methods;

  const ingredientManageStockHandler = (data) => {};

  useEffect(() => {
    reset({ ...ingredientDetails });
  }, [ingredientDetails, reset]);
  return (
    <>
      {postLoading ? (
        <CustomLoadingScreen height="70vh" />
      ) : (
        <>
          <Stack style={{ marginBottom: '10px' }}>
            <Stack alignItems={'center'} gap="1rem">
              {!isIngredientEdit ? (
                <>
                  <Stack direction="row" alignItems="center" gap="1rem">
                    <Stack>
                      <Avatar src={ingredientDetails?.imgURL} sx={{ width: '7rem', height: '7rem' }} />
                    </Stack>
                    <Stack className="fontWeightPtoSpan" spacing={1}>
                      <Stack direction="row" spacing={1}>
                        <Stack>
                          <TextField label="Name" value={ingredientDetails?.name} disabled variant="standard" />
                        </Stack>
                        <Stack>
                          <TextField label="Type" value={ingredientDetails?.type} disabled variant="standard" />
                        </Stack>
                        <Stack>
                          <TextField
                            label="Shelf Life In Days"
                            value={ingredientDetails?.shelfLife}
                            disabled
                            variant="standard"
                          />
                        </Stack>
                      </Stack>
                      <Stack>
                        {/* <Grid item xs={12}> */}
                        <TextField
                          label="Storage Instruction"
                          value={ingredientDetails?.storageInstruction}
                          multiline
                          disabled
                          variant="standard"
                        />
                        {/* </Grid> */}
                      </Stack>
                      {/* <p>
                        Name : <span>{ingredientDetails?.name}</span>
                      </p>
                      <Stack direction={'row'} gap="1rem">
                        <p>Type : {ingredientDetails?.type}</p>
                        <p>Shelf Life : {ingredientDetails?.shelfLife}</p>
                      </Stack>
                      <p>
                        Instruction : <span> {ingredientDetails?.storageInstruction} </span>
                      </p> */}
                      <Stack justifyContent={'flex-end'} direction="row" gap="1rem" marginTop={'5px'}>
                        <Button variant="contained" onClick={() => setIsIngredientEdit(true)}>
                          Edit
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </>
              ) : (
                <IngredientEdit currentData={ingredientDetails} cancelIngredientForm={setIsIngredientEdit} />
              )}
            </Stack>
          </Stack>
          <FormProvider methods={methods} onSubmit={handleSubmit(ingredientManageStockHandler)}>
            <IngredientStockAndMenusHandler currentData={currentData} />
            {/* <IngredientAddStockForm currentData={currentData} /> */}
          </FormProvider>
        </>
      )}
    </>
  );
}

export default IngredientManageStockForm;
