import Page from 'components/Page';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { changeMenuERSizeOptionCurrentTab } from 'redux/slices/menuERSizeAndOptionSlice';
import MenuEngineeringHeader from './MenuEngineeringHeader';
import MenuEngineeringBody from './MenuEngineeringBody';

function MenuEngineeringIndex() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeMenuERSizeOptionCurrentTab(0));
  }, [dispatch]);
  return (
    <Page title="Menu Engineering">
      <MenuEngineeringHeader />
      <MenuEngineeringBody />
    </Page>
  );
}

export default MenuEngineeringIndex;
