import { Button, Card, Divider, FormControlLabel, Stack, TextField } from '@mui/material';
import React from 'react';
import { RHFCheckbox } from 'components/hook-form';
import 'css/termsAndConditions.scss';
import ReactPDF, { Document, PDFDownloadLink, PDFViewer, Page, Text } from '@react-pdf/renderer';

function StaffCreateActiveStepperTermsCondition() {
  return (
    <Stack>
      <Card sx={{ py: 3, px: 3 }}>
        <Stack className="termsAndConditionsCSSOnly" my={2}>
          <p className="c1">
            <span className="c0">TERMS AND CONDITIONS FOR COMPANY TO GET STAFF PERSONAL INFORMATION AND PAPERWORK</span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">Introduction</span>
          </p>
          <p className="c1">
            <span className="c0">
              These terms and conditions are a legally binding agreement between the company (hereinafter referred to as
              &quot;the Company&quot;) and its employees (hereinafter referred to as &quot;the Employee&quot;) in
              relation to the collection, use, and storage of personal information and paperwork of the Employee. By
              signing these terms and conditions, the Employee agrees to the terms set out herein.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">Collection of Personal Information</span>
          </p>
          <p className="c1">
            <span className="c0">
              The Company may collect personal information about the Employee, including but not limited to, name,
              address, phone number, email address, social security number, date of birth, and any other information
              necessary for employment or legal purposes. This personal information is necessary for the Company to
              carry out its obligations under employment law and regulations.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              The Employee acknowledges and agrees that the Company may collect this information directly from the
              Employee or from third parties, including but not limited to, previous employers, credit agencies, and
              criminal record check services.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              The Company will collect and process personal information in accordance with applicable data protection
              laws, including the General Data Protection Regulation (GDPR) and the Data Protection Act 2018.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">Use of Personal Information</span>
          </p>
          <p className="c1">
            <span className="c0">
              The Company may use personal information collected from the Employee for the following purposes:
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Employment verification and background checks: The Company may use personal information to verify the
              Employee&#39;s eligibility to work and conduct background checks, including criminal record checks, where
              necessary.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Payroll and benefits administration: The Company may use personal information to administer payroll,
              employee benefits, and pensions.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Performance evaluation and training: The Company may use personal information to evaluate the
              Employee&#39;s performance and provide training.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Compliance with legal and regulatory requirements: The Company may use personal information to comply with
              legal and regulatory requirements, including tax, health and safety, and equal opportunities legislation.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              The Employee acknowledges and agrees that the Company may process personal information for the above
              purposes and that the processing of personal information is necessary for the performance of the
              employment contract.
            </span>
          </p>
          <p className="c1" id="h.gjdgxs">
            <span className="c0">Personal information may be use for the Marketing propose as well.</span>
          </p>
          <p className="c1">
            <span className="c0">Disclosure of Personal Information</span>
          </p>
          <p className="c1">
            <span className="c0">
              The Company may disclose personal information about the Employee to third parties in the following
              circumstances:
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              To government agencies or law enforcement authorities as required by law: The Company may disclose
              personal information if required to do so by law or if necessary to comply with a court order or legal
              process.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              To third-party service providers who perform services on behalf of the Company: The Company may disclose
              personal information to third-party service providers who perform services on behalf of the Company,
              including but not limited to, payroll providers, pension providers, and benefits providers.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              To a potential buyer or successor in the event of a merger or acquisition of the Company: The Company may
              disclose personal information to a potential buyer or successor in the event of a merger or acquisition of
              the Company.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              The Employee acknowledges and agrees that the Company may disclose personal information for the above
              purposes and that the disclosure of personal information is necessary for the performance of the
              employment contract.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">Paperwork</span>
          </p>
          <p className="c1">
            <span className="c0">
              The Company may require the Employee to complete and sign paperwork related to employment, such as
              employment contracts, confidentiality agreements, and intellectual property agreements.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              The Employee acknowledges and agrees that the completion and signing of such paperwork is necessary for
              the performance of the employment contract.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">Storage of Personal Information and Paperwork</span>
          </p>
          <p className="c1">
            <span className="c0">
              The Company will take reasonable steps to protect personal information and paperwork collected from the
              Employee. All personal information and paperwork will be stored securely and only accessed by authorized
              personnel.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              The Employee acknowledges and agrees that the Company will store personal information and paperwork for
              the duration of the employment contract and for a reasonable period thereafter, in accordance with
              applicable data protection laws.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">Employee Access to Personal Information</span>
          </p>
          <p className="c1">
            <span className="c0">
              The Employee has the right to access their personal information held by the Company and to request
              corrections to any inaccurate or incomplete information.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              Employee acknowledges and agrees that the Company will respond to such requests in accordance with
              applicable data protection laws and within a reasonable timeframe.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">Consent</span>
          </p>
          <p className="c1">
            <span className="c0">
              By signing these terms and conditions, the Employee gives their consent to the Company to collect, use,
              and disclose personal information and paperwork in accordance with the terms set out herein.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              The Employee acknowledges and agrees that they may withdraw their consent at any time by contacting the
              Company&#39;s Data Protection Officer, although such withdrawal may affect the ability of the Company to
              provide employment.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">Changes to Terms and Conditions</span>
          </p>
          <p className="c1">
            <span className="c0">
              The Company may modify these terms and conditions at any time, in accordance with applicable data
              protection laws. The Employee will be notified of any changes to these terms and conditions in writing or
              through an appropriate communication channel.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">Governing Law and Jurisdiction</span>
          </p>
          <p className="c1">
            <span className="c0">
              These terms and conditions are governed by and construed in accordance with the laws of the jurisdiction
              in which the Company is registered. Any dispute arising out of or in connection with these terms and
              conditions shall be subject to the exclusive jurisdiction of the courts of the jurisdiction in which the
              Company is registered.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">Entire Agreement</span>
          </p>
          <p className="c1">
            <span className="c0">
              These terms and conditions constitute the entire agreement between the Company and the Employee in
              relation to the collection, use, and storage of personal information and paperwork, and supersede all
              prior agreements and understandings, whether written or oral.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0" />
          </p>
          <p className="c1">
            <span className="c0">
              By signing these terms and conditions, the Employee acknowledges that they have read, understood, and
              agreed to the terms set out herein, and that they have received a copy of these terms and conditions.
            </span>
          </p>
        </Stack>

        <Stack m={1.5}>
          <FormControlLabel
            label="Agree Terms and Conditions"
            control={<RHFCheckbox name="acceptTermsAndCondition" />}
          />
        </Stack>
      </Card>
    </Stack>
  );
}

export default StaffCreateActiveStepperTermsCondition;
