import { Button, Stack } from '@mui/material';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { useRef } from 'react';
import SignatureCanvas from 'react-signature-canvas';

function StaffCreateActiveStepperSignaturePad({ open, setOpen, formValue = () => {} }) {
  const sigCanvas = useRef(null);

  const create = () => {
    const URL = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    // setValue('declaration.signature', URL);
    formValue(URL);
    setOpen(false);
  };

  return (
    <CustomOutletModal open={open} setOpen={setOpen} zIndex={1600} title="Create Signature">
      <Stack gap="1rem">
        <Stack bgcolor={'rgb(238, 235, 235)'} p={1}>
          <SignatureCanvas penColor="black" backgroundColor={'white'} ref={sigCanvas} canvasProps={{ height: 350 }} />
        </Stack>
        <Stack alignItems={'flex-end'} direction="row" gap="1rem">
          <Button onClick={() => sigCanvas.current.clear()} size="small" sx={{ marginRight: 'auto' }}>
            Clear
          </Button>
          <Button onClick={create} size="small" variant="contained">
            Create
          </Button>

          <Button onClick={() => setOpen(false)} size="small" variant="contained" color="error">
            Cancel
          </Button>
        </Stack>
      </Stack>
    </CustomOutletModal>
  );
}

export default StaffCreateActiveStepperSignaturePad;
