import { TableCell } from '@mui/material';
import PropTypes from 'prop-types';

import { RHFTextField } from 'components/hook-form';
import React from 'react';

DeliverySummaryTableIndividualCell.propTypes = {
  mainIndex: PropTypes.number,
  index: PropTypes.number,
  item: PropTypes.object,
  platform: PropTypes.object,
  filterUser: PropTypes.array,
};

function DeliverySummaryTableIndividualCell({ mainIndex, index, item, platform, filterUser }) {
  const filterUsers = filterUser?.find(
    (el) => el?.userId === Number(item?.id) && el?.onlinePlatformId === Number(platform?.id)
  );

  return (
    <TableCell>
      <RHFTextField
        name={`deliveryEntries.${mainIndex}.${index}.deliveryCount`}
        size="small"
        value={filterUsers?.totalDeliveryCount}
        disabled
      />
    </TableCell>
  );
}

export default DeliverySummaryTableIndividualCell;
