import { Avatar, Box, Button, Grid, Icon, Stack, Table, TableContainer } from '@mui/material';
import { FormProvider, RHFTextField } from 'components/hook-form';
import Iconify from 'components/Iconify';
import React from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import IndividualTimeClockDrawerEdit from './IndividualTimeClockDrawerEdit';

function TimeClockDrawerForm({ userObj, isEdit, setOpen }) {
  const defaultValues = {
    firstName: userObj?.firstName || '',
    timeclocks: userObj?.timeclocks || [],
    userId: userObj?.userId || null,
  };

  const methods = useForm({ defaultValues });
  const { control, reset, watch } = methods;

  const { fields, remove } = useFieldArray({
    control,
    name: 'timeclocks',
    keyName: 'fieldId',
  });

  return (
    <FormProvider methods={methods}>
      <TableContainer>
        <Table style={{ maxWidth: '800px' }}>
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <Box style={{ marginTop: '1rem', fontWeight: '500', display: 'flex', justifyContent: 'space-between' }}>
                <Stack direction="row" gap="0.6rem" alignItems={'center'} mt="0.8rem">
                  <Avatar src={userObj?.thumbnailURL} />
                  <span style={{ fontWeight: '700' }}>
                    {userObj?.firstName} {userObj?.lastName}
                  </span>
                </Stack>
              </Box>
            </Grid>
            <Grid item xs={12}>
              {fields?.map((obj, index) => {
                return (
                  <>
                    <IndividualTimeClockDrawerEdit obj={obj} index={index} remove={remove} />
                  </>
                );
              })}
            </Grid>
          </Grid>
        </Table>
      </TableContainer>
    </FormProvider>
  );
}

export default TimeClockDrawerForm;
