import { useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Switch,
  Button,
  Divider,
  TableBody,
  TableContainer,
  FormControlLabel,
  TableRow,
  TableCell,
  Pagination,
} from '@mui/material';
import useSettings from 'hooks/useSettings';
import useTable, { emptyRows } from 'hooks/useTable';
import { PATH_DASHBOARD } from 'routes/paths';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import Scrollbar from 'components/Scrollbar';
import Label from 'components/Label';
import { InvoiceTableRow, InvoiceTableToolbar } from 'sections/@dashboard/invoice/list';
import { TableEmptyRows, TableHeadCustom, TableNoData } from 'components/table';
import useTabs from 'hooks/useTabs';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { clearInvoiceDetails, deleteInvoices, getInvoiceFilter, getInvoicePDFDetails } from 'redux/slices/InvoiceSlice';
import { FormProvider } from 'components/hook-form';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { DialogAnimate } from 'components/animate';
// routes

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'invoiceNumber', label: 'Invoice Number', align: 'left' },
  { id: 'createDate', label: 'Invoice Date', align: 'left' },
  { id: 'dueDate', label: 'Due Date', align: 'left' },

  { id: 'totalGst', label: 'Total GST', align: 'center', width: 140 },
  { id: 'price', label: 'Total Incl GST', align: 'center', width: 140 },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'actions', label: 'Action', align: 'left' },
];

// ----------------------------------------------------------------------
export default function InvoiceList() {
  const [openDeleteDialog, setOpenDeleteDialog] = useState({ status: false, id: null });
  const dispatch = useDispatch();
  const { invoiceList, postLoading, loading } = useSelector((state) => state.invoice);
  const { selectedDepartment } = useSelector((state) => state.userDepartment);

  const defaultValues = useMemo(
    () => ({
      departmentId: selectedDepartment?.id,
      statusList: ['APPROVED'],
      searchTxt: '',
    }),
    [selectedDepartment]
  );
  const methods = useForm({ defaultValues });
  const [pages, setPages] = useState(1);
  const watchAll = methods.watch();
  const watchDepartmentId = methods.watch('departmentId');

  const { themeStretch } = useSettings();
  const [pdfFile, setPdfFile] = useState(null);

  const navigate = useNavigate();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    onSelectRow,
    //
    onSort,
    onChangeDense,
  } = useTable({ defaultOrderBy: 'createDate' });

  const { currentTab: filterStatus, onChangeTab: onFilterStatus } = useTabs('APPROVED');
  // const [findCurrentTab, setFindCurrentTab] = useState('APPROVED');

  const handleDeleteRow = (id) => {
    setOpenDeleteDialog({ status: true, id });
  };

  const handleEditRow = (id) => {
    navigate(PATH_DASHBOARD.invoice.edit(id));
  };

  const handleViewRow = (id) => {
    navigate(PATH_DASHBOARD.invoice.view(id));
  };

  const denseHeight = dense ? 56 : 76;

  const TABS = [
    { value: 'APPROVED', label: 'approved', color: 'info' },
    { value: 'PENDING', label: 'Pending', color: 'success' },
  ];

  useEffect(() => {
    if (!watchDepartmentId) return;
    const newObj = { ...watchAll };
    dispatch(getInvoiceFilter({ data: newObj, pages: 1 }));
  }, [watchDepartmentId, dispatch, filterStatus]);

  useEffect(() => {
    methods.setValue('departmentId', selectedDepartment?.id);
  }, [selectedDepartment, methods]);

  const filterSubmitHandler = (data) => {
    dispatch(getInvoiceFilter({ data, pages: 1 }));
  };

  const handlePaginationChange = (event, value) => {
    setPages(value);
    dispatch(getInvoiceFilter({ data: { ...watchAll }, pages: value }));
  };

  const FileHandler = (e) => {
    if (e.target.files) {
      setPdfFile(e.target.files[0]);
      const newFormData = new FormData();
      newFormData.append('file', e.target.files[0]);

      const getPdfID = (data) => {
        if (data) navigate(PATH_DASHBOARD.invoice.edit(data));
      };
      dispatch(getInvoicePDFDetails({ selectedDepartment: selectedDepartment?.id, files: newFormData, getPdfID }));
    }
  };

  const deleteDialogBoxClose = () => setOpenDeleteDialog({ status: false, id: null });

  const deleteInvoiceHandler = (invoiceId) => {
    const departmentId = selectedDepartment?.id;
    const isSuccess = (status) => {
      if (status) {
        dispatch(getInvoiceFilter({ data: { ...watchAll }, pages: 1 }));
      }
    };
    dispatch(deleteInvoices({ departmentId, invoiceId, isSuccess }));
    deleteDialogBoxClose();
  };

  useEffect(() => {
    dispatch(clearInvoiceDetails());
  }, [dispatch]);

  return (
    <Page title="Invoice: List">
      {/* <Container maxWidth={themeStretch ? false : 'lg'}> */}
      <HeaderBreadcrumbs
        heading="Invoice List"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Invoices', href: PATH_DASHBOARD.invoice.root },
          { name: 'List' },
        ]}
        action={
          <Box className="invoiceListHeadingButtons">
            <form color="secondary">
              <label htmlFor="upload_pdf">
                <input
                  type="file"
                  name="upload_pdf"
                  accept="application/pdf"
                  id="upload_pdf"
                  onChange={(e) => FileHandler(e)}
                  hidden
                />
                {loading ? (
                  <Box style={{ display: 'flex', gap: '0.5rem' }}>
                    {' '}
                    <CustomLoadingScreen circularWidth="20px" color="warning" />{' '}
                    <span style={{ fontSize: '0.8rem' }}>{pdfFile?.name}</span>
                  </Box>
                ) : (
                  'Upload PDF'
                )}
              </label>
            </form>
            <Button
              variant="contained"
              component={RouterLink}
              to={PATH_DASHBOARD.invoice.new}
              startIcon={<Iconify icon={'eva:plus-fill'} />}
            >
              New Invoice
            </Button>
          </Box>
        }
      />

      <Card>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={filterStatus}
          onChange={(event, newValue) => {
            onFilterStatus(event, newValue);
            methods.resetField('statusList');
            methods.setValue('statusList', [newValue]);
          }}
          sx={{ px: 2, bgcolor: 'background.neutral' }}
        >
          {TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              value={tab.value}
              icon={<Label color={tab.color}> {tab.count} </Label>}
              label={tab.label}
            />
          ))}
        </Tabs>
        <Divider />
        <FormProvider methods={methods} onSubmit={methods.handleSubmit(filterSubmitHandler)}>
          <InvoiceTableToolbar />
        </FormProvider>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom order={order} orderBy={orderBy} headLabel={TABLE_HEAD} numSelected={selected.length} />
              <TableBody>
                {postLoading ? (
                  <TableRow>
                    <TableCell align="center" colSpan={6}>
                      <CustomLoadingScreen height={'30vh'} />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {invoiceList?.invoices?.map((row) => (
                      <InvoiceTableRow
                        key={row.id}
                        row={row}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        onViewRow={() => handleViewRow(row.id)}
                        onEditRow={() => handleEditRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                      />
                    ))}

                    <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, invoiceList.length)} />
                    <TableNoData isNotFound={invoiceList?.invoices?.length <= 0} />
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ position: 'relative' }}>
          <Pagination
            count={invoiceList?.paginationInfo?.totalPages}
            page={pages}
            onChange={handlePaginationChange}
            className="pagination-list-container"
            sx={{ px: 3, py: 1.5, justifyContent: 'flex-end', borderTop: 'solid 1px rgba(145, 158, 171, 0.24)' }}
          />
          <FormControlLabel
            control={<Switch checked={dense} onChange={onChangeDense} />}
            label="Dense"
            sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
          />
        </Box>
      </Card>
      {/* </Container> */}

      {openDeleteDialog.status && (
        <DialogAnimate open={openDeleteDialog} onClose={deleteDialogBoxClose}>
          <Box className="deleteObjectDialogBox">
            <h2>Are you sure you want to Delete ?</h2>
            <Box className="deleteObjectDialogBoxBody">
              <Button color="error" onClick={() => deleteInvoiceHandler(openDeleteDialog.id)}>
                Yes
              </Button>
              <Button color="secondary" variant="contained" onClick={deleteDialogBoxClose}>
                No
              </Button>
            </Box>
          </Box>
        </DialogAnimate>
      )}
    </Page>
  );
}
