import { Button, Stack } from '@mui/material';
import { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { useDispatch, useSelector } from 'react-redux';
import { getFoodMenuSizePrice, updateFoodMenuSizePrice } from 'redux/slices/menuEngineeringSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import FoodMenuSizeExtraPriceSectionForm from './FoodMenuSizeExtraPriceSectionForm';

const schema = Yup.object().shape({
  menuSizes: Yup.array().of(
    Yup.object().shape({
      additionalPriceDelivery: Yup.number()
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .typeError(''),
      additionalPricePickup: Yup.number()
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .typeError(''),
      additionalPriceTable: Yup.number()
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .typeError(''),
    })
  ),
});

function FoodMenuSizeExtraPriceSection({ menu, indexList }) {
  const [choosenType, setChoosenType] = useState('DELIVERY');
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const { postLoading, foodMenuSizePrices } = useSelector((state) => state.menuEngineering);

  const defaultValues = {
    type: 'DELIVERY',
  };
  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onChange' });
  const { handleSubmit, setValue, reset } = methods;

  const menuSizeSetType = (status) => {
    if (!status) return;
    setChoosenType(status);
    setValue('type', status);
  };

  const PriceSizeSubmitHandler = (data) => {
    const finalData = { ...data, departmentId, id: menu?.id };
    dispatch(updateFoodMenuSizePrice({ finalData }));
  };
  useEffect(() => {
    if (!menu || !departmentId) return;
    dispatch(getFoodMenuSizePrice({ menuId: menu.id, departmentId }));
  }, [departmentId, dispatch, menu]);
  useEffect(() => {
    if (!foodMenuSizePrices) return;
    reset({
      type: choosenType,
      menuSizes: foodMenuSizePrices?.map((size) => ({
        sizeId: size?.sizeId,
        name: size?.sizeName,
        isPublished: size?.isPublished,
        additionalPrice: size?.additionalPrice ?? 0,
        additionalPriceDelivery: size?.additionalPriceDelivery ?? 0,
        additionalPricePickup: size?.additionalPricePickup ?? 0,
        additionalPriceTable: size?.additionalPriceTable ?? 0,
      })),
    });
  }, [choosenType, foodMenuSizePrices, menu, reset]);

  const isFormDirty = methods?.formState?.isDirty;

  console.log(methods.watch());

  return (
    <>
      <FormProvider {...methods}>
        <Stack alignItems="center" gap="1rem" justifyContent={'center'} direction={'row'} mt={1}>
          <Button
            variant={choosenType === 'DELIVERY' ? 'contained' : 'outlined'}
            onClick={() => menuSizeSetType('DELIVERY')}
          >
            Delivery
          </Button>
          <Button
            variant={choosenType === 'PICKUP' ? 'contained' : 'outlined'}
            onClick={() => menuSizeSetType('PICKUP')}
          >
            Pickup
          </Button>
          <Button variant={choosenType === 'TABLE' ? 'contained' : 'outlined'} onClick={() => menuSizeSetType('TABLE')}>
            Table
          </Button>
        </Stack>
        <FoodMenuSizeExtraPriceSectionForm menu={menu} indexList={indexList} choosenType={choosenType} />
        <Stack alignItems="center" gap="1rem" justifyContent={'flex-end'} direction={'row'} mt={1}>
          <Button
            variant="contained"
            onClick={handleSubmit(PriceSizeSubmitHandler)}
            disabled={postLoading || !isFormDirty}
          >
            Save
          </Button>
        </Stack>
      </FormProvider>
    </>
  );
}

export default FoodMenuSizeExtraPriceSection;
