import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import React from 'react';
import EquipmentAddEditForm from './EquipmentAddEditForm';

function EquipmentEditDrawer({ open, setOpen, data }) {
  return (
    <CustomDrawer
      open={open}
      setOpen={setOpen}
      title={`Edit details of ${data?.equipmentName}`}
      PaperProps={{ sx: { innerWidth: '50%' } }}
    >
      <Box style={{ maxWidth: '700px' }}>
        {/* <Card sx={{ p: 3 }}> */}
        <EquipmentAddEditForm data={data} isEdit />
        {/* </Card> */}
      </Box>
    </CustomDrawer>
  );
}

EquipmentEditDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  data: PropTypes.object,
};

export default EquipmentEditDrawer;
