import { TableBody } from '@mui/material';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import UserNoteTableBodyUserEntity from './UserNoteTableBodyUserEntity';

function UserNotesTableBody() {
  const { control } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: 'users',
    keyName: 'fieldID',
  });

  return (
    <TableBody className="userNoteTableBodyContainer">
      {/* main list of array */}
      {fields?.map((obj, mainIndex) => (
        <UserNoteTableBodyUserEntity obj={obj} mainIndex={mainIndex} key={obj?.fieldID} />
      ))}
    </TableBody>
  );
}

export default UserNotesTableBody;
