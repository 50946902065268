import { Box, Card, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeMenuEngineeringCurrentTab } from 'redux/slices/menuEngineeringSlice';
import FoodCategoryIndex from 'sections/@dashboard/FoodMenuAndCategory/FoodCategory/FoodCategoryIndex';
import FoodMenuIndex from 'sections/@dashboard/FoodMenuAndCategory/FoodMenu/FoodMenuIndex';

function FoodMenuAndCategoryBody() {
  const dispatch = useDispatch();
  const { currentTab } = useSelector((state) => state.menuEngineering);

  const handleChange = (event, newValue) => {
    dispatch(changeMenuEngineeringCurrentTab(newValue));
  };
  return (
    <Card>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingLeft: '0.7rem' }}>
        <Tabs value={currentTab} onChange={handleChange} aria-label="Stock and Order Tabs">
          <Tab label="Menu" id="0" />
          <Tab label="Category" id="1" />
        </Tabs>
      </Box>

      {currentTab === 0 && <FoodMenuIndex />}
      {currentTab === 1 && <FoodCategoryIndex />}
    </Card>
  );
}

export default FoodMenuAndCategoryBody;
