import { Box, Button } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import StockCreate from 'pages/dashboard/stock/StockCreate';
import { useState } from 'react';
import { useSelector } from 'redux/store';
import { PATH_DASHBOARD } from 'routes/paths';

function StockHeaders() {
  const { postLoading, isCreateStockOrderSwitch, stockCurrentTab } = useSelector((state) => state.stocks);
  const [open, setOpen] = useState(false);
  return (
    <>
      <HeaderBreadcrumbs
        heading="Stocks"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Stocks', href: PATH_DASHBOARD.stock.root },
          { name: 'List' },
        ]}
        action={
          <Box className="invoiceListHeadingButtons">
            <Button
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} />}
              type="submit"
              form="roasterSubmit"
              onClick={() => setOpen(true)}
              disabled={
                postLoading ||
                isCreateStockOrderSwitch?.isCreateOrder ||
                isCreateStockOrderSwitch?.isCreateOrderModal ||
                stockCurrentTab === 1
              }
            >
              {postLoading ? <CustomLoadingScreen circularWidth={'20px'} color="secondary" /> : 'Create'}
            </Button>
          </Box>
        }
      />

      {open && <StockCreate open={open} setOpen={setOpen} />}
    </>
  );
}

export default StockHeaders;
