import * as Yup from 'yup';

const StockYupSchema = Yup.object().shape({
  externalUID: Yup.string().required('').max(10, ''),
  productDescription: Yup.string().required('').typeError(''),

  uom: Yup.string().required('').min(1, ''),
  weightType: Yup.string().typeError('').required(''),

  unitWeight: Yup.number()
    .nullable()
    .typeError('')
    .when('weightType', {
      is: (weightType) => weightType === 'KG',
      then: (schema) => schema.moreThan(0, ''),
      otherwise: (schema) => schema.notRequired(),
    })
    .transform((curr, orig) => (orig === '' || orig === null ? 0 : curr)),

  supplierId: Yup.string().required('').typeError(''),

  currentQty: Yup.number()
    .required('')
    .positive('')
    .nullable()
    .min(1, '')
    .typeError('')
    .transform((curr, orig) => (orig === '' || orig === 0 ? null : curr)),

  minQty: Yup.number()
    .positive('')
    .nullable()
    .typeError('')
    .transform((curr, orig) => (orig === '' || orig === 0 ? null : curr)),

  packSize: Yup.number()
    .positive('')
    .nullable()
    .typeError('')
    .transform((curr, orig) => (orig === '' || orig === 0 ? null : curr)),

  unitPrice: Yup.number().required('').positive('').typeError(''),

  gst: Yup.number().nullable().typeError(''),
  levy: Yup.number().nullable().typeError(''),

  parLevel: Yup.number(),
  orderStock: Yup.number(),
  priorityNo: Yup.number(),
  wastePercent: Yup.number(),
});

export default StockYupSchema;
