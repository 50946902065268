import { Icon } from '@iconify/react';
import { Box, Button, Stack, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useFormContext, useWatch } from 'react-hook-form';
import { findTotalofEachWeeks } from 'utils/roasterFeatureUtils';
import Iconify from 'components/Iconify';
import RoasterSalesForecastUpdate from './RoasterSalesForecastUpdate';
import RoasterViewSalesForeCast from './RoasterViewSalesForeCast';

function RoasterAllUserIndividualDateInfo({ currentWeek, salesForeCast, mainIndex }) {
  const [getTotal, setGetTotal] = useState();
  const { control, watch } = useFormContext();
  const values = useWatch({
    control,
    name: 'users',
  });

  useEffect(() => {
    if (!values && !values.length) return;
    const getTotalofEverything = findTotalofEachWeeks(currentWeek, values);
    setGetTotal(getTotalofEverything);
  }, [values, currentWeek]);

  const findTotalWorkTime = (totalDate) => {
    const hours = Math.floor(totalDate / 1000 / 60 / 60);
    const minutes = Math.ceil((totalDate / 1000 / 60) % 60);
    if (minutes > 59) return `${hours + 1}H 0M`;
    return `${hours}H ${minutes}M`;
  };

  return (
    <Box className="roasterTotalData">
      <div style={{ padding: '0', display: 'flex', justifyContent: 'center' }}>
        <Tooltip title="Total Cost">
          <Box style={{ display: 'inline-flex', marginTop: '4px' }}>
            <Icon icon="ic:baseline-attach-money" width={15} />
          </Box>
        </Tooltip>
        <p style={{ display: 'inline-flex', fontWeight: '600' }}>{Number(getTotal?.totalPrice).toFixed(2)}</p>
        <Tooltip title="Total Hours">
          <Box style={{ display: 'inline-flex', marginLeft: '7px', marginTop: '4px' }}>
            <Icon icon="material-symbols:nest-clock-farsight-analog-outline" width={15} />
          </Box>
        </Tooltip>
        <p style={{ display: 'inline-flex', fontWeight: '600' }}>{findTotalWorkTime(getTotal?.totalDate)}</p>
      </div>

      {/* Sales forecast for each day of week */}
      <RoasterViewSalesForeCast
        currentWeek={currentWeek}
        salesForeCast={salesForeCast}
        getTotal={getTotal}
        mainIndex={mainIndex}
      />
    </Box>
  );
}

export default RoasterAllUserIndividualDateInfo;
