import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Box, Button, Stack } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import Iconify from 'components/Iconify';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { deleteSizeOptionGroup } from 'redux/slices/menuERSizeAndOptionSlice';
import ManageMenuSizeOption from 'sections/@dashboard/menuEngineering/menuSizeOption/ManageMenuSizeOption';
import MenuSizeOptionDetails from './MenuSizeOptionDetails';

function MenuSizeOptionAccordianDetails({ sizeIndex, OptionInsideSize, size }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const { control } = useFormContext();

  const { fields, move } = useFieldArray({
    control,
    name: `data.${sizeIndex}.sizeGroupsOptionGroups`,
    keyName: 'sizeGroupOptionGroupId',
  });

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;
    if (active.id !== over.id) {
      move(
        fields.findIndex((item) => item?.id === active.id),
        fields.findIndex((item) => item?.id === over.id)
      );
    }
  };
  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
      <SortableContext items={fields} strategy={verticalListSortingStrategy} handle>
        {fields?.map((options, optionIndex) => (
          <MenuSizeOptionDetails key={options?.id} options={options} optionIndex={optionIndex} size={size} />
        ))}
      </SortableContext>
    </DndContext>
  );
}

export default MenuSizeOptionAccordianDetails;
