import { DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { Button, Stack } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  getDefaultRecommendedMenus,
  getRecommendedMenus,
  reOrderRecommendedMenus,
} from 'redux/slices/mostLikedMenusSlice';
import PropTypes from 'prop-types';
import { TableNoData } from 'components/table';
import RecommendedMenusBodyTableRow from './RecommendedMenusBodyTableRow';

RecommendedMenusInnerBody.propTypes = {
  foodCategory: PropTypes.object,
  isDefault: PropTypes.bool,
};

function RecommendedMenusInnerBody({ foodCategory, isDefault }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const { loading, recommendedMenus } = useSelector((state) => state.mostLikedMenus);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { reset, control, handleSubmit } = methods;

  const { fields, move } = useFieldArray({
    control,
    name: 'data',
    keyName: 'menuId',
  });

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;
    if (active.id !== over.id) {
      move(
        fields.findIndex((item) => item?.id === active.id),
        fields.findIndex((item) => item?.id === over.id)
      );
    }
  };

  const reOrderManage = (data) => {
    const finalData = {
      menus: data?.data?.map((item) => ({ ...item })),
      departmentId,
      categoryId: foodCategory?.id,
    };
    dispatch(reOrderRecommendedMenus({ finalData, departmentId, categoryId: foodCategory ? foodCategory?.id : null }));
  };

  useEffect(() => {
    if (!departmentId) return;
    if (isDefault) {
      dispatch(getDefaultRecommendedMenus({ departmentId }));
    } else {
      dispatch(getRecommendedMenus({ departmentId, categoryId: foodCategory?.id }));
    }
  }, [dispatch, departmentId, foodCategory, isDefault]);

  useEffect(() => {
    if (!recommendedMenus?.length) reset({ data: [] });
    else reset({ data: recommendedMenus });
  }, [recommendedMenus, reset]);

  return (
    <>
      <FormProvider {...methods}>
        {loading ? (
          <CustomLoadingScreen height="20vh" />
        ) : (
          <form id="recommendedMenusReOrder" onSubmit={handleSubmit(reOrderManage)}>
            <Stack spacing={1}>
              <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToParentElement]}
              >
                <SortableContext items={fields} strategy={verticalListSortingStrategy}>
                  {fields?.length === 0 && (
                    <Stack alignItems={'center'} justifyContent={'center'}>
                      <TableNoData isNotFound={fields?.length === 0} />
                    </Stack>
                  )}
                  {fields?.map((item, index) => (
                    <RecommendedMenusBodyTableRow key={item?.menuId} index={index} item={item} />
                  ))}
                </SortableContext>
              </DndContext>
            </Stack>
            {fields?.length > 0 && (
              <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ mt: 2 }}>
                <Button variant="contained" onClick={handleSubmit(reOrderManage)}>
                  Save
                </Button>
              </Stack>
            )}
          </form>
        )}
      </FormProvider>
    </>
  );
}

export default RecommendedMenusInnerBody;
