import { Box, Button, Pagination, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Scrollbar from 'components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { getStockOrderDetails, getStockOrderList } from 'redux/slices/stockSlice';
import { dispatch, useSelector } from 'redux/store';
import ViewOrderDetailsModal from 'sections/@dashboard/stocks/ViewOrderDetailsModal';

const TABLE_HEAD = [
  { id: 1, label: 'Order Date', align: 'left' },
  { id: 2, label: 'Total ExGst ($)', align: 'left' },
  { id: 3, label: 'Details', align: 'left' },
];

function StockOrderList() {
  const { loading, postLoading, stockOrderList, stockOrderDetails } = useSelector((state) => state.stocks);
  const [pages, setPages] = useState(1);

  const [open, setOpen] = useState(false);

  const departmentId = useGetDepartmentId();
  useEffect(() => {
    dispatch(getStockOrderList({ departmentId, pageNo: 1 }));
  }, [departmentId]);

  const handlePaginationChange = (event, value) => {
    setPages(value);
    dispatch(getStockOrderList({ departmentId, pageNo: value }));
  };

  const OpenOrderDetailsHandler = (orderId) => {
    const isSuccess = () => {
      setOpen(true);
    };
    dispatch(getStockOrderDetails({ departmentId, orderId, isSuccess }));
  };
  return (
    <>
      <Scrollbar>
        <TableContainer>
          <Table stickyHeader>
            <TableHeadCustom headLabel={TABLE_HEAD} />

            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={11}>
                    <CustomLoadingScreen height="30vh" />
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {stockOrderList?.orders?.length <= 0 ? (
                    <TableNoData isNotFound={stockOrderList?.orders?.length <= 0} />
                  ) : (
                    <>
                      {stockOrderList?.orders?.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell>{row?.orderDate}</TableCell>
                          <TableCell>{row?.totalExGst}</TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              color="secondary"
                              onClick={() => {
                                OpenOrderDetailsHandler(row?.id);
                              }}
                            >
                              View
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <Box sx={{ position: 'relative' }}>
        <Pagination
          count={stockOrderList?.paginationInfo?.totalPages}
          page={pages}
          onChange={handlePaginationChange}
          className="pagination-list-container"
          sx={{ px: 3, py: 1.5, justifyContent: 'flex-end', borderTop: 'solid 1px rgba(145, 158, 171, 0.24)' }}
        />
      </Box>

      {open && !postLoading && <ViewOrderDetailsModal open={open} setOpen={setOpen} currentValue={stockOrderDetails} />}
    </>
  );
}

export default StockOrderList;
