import { Card, Divider, InputAdornment, MenuItem, Stack, Tab, Tabs, TextField } from '@mui/material';
import Iconify from 'components/Iconify';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ActiveUserList from './ActiveUserList';
import InactiveUserList from './InactiveUserList';

export default function UserListBody() {
  const { depOfStore, loading: depOfStoreLoading } = useSelector((state) => state.store);
  const [tabValue, setTabValue] = useState('active');
  const [departmentId, setDepartmentId] = useState(depOfStore?.[0]?.id);
  const [searchUser, setSearchUser] = useState('');

  return (
    <Card>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        scrollButtons="auto"
        value={tabValue}
        onChange={(e, value) => {
          setTabValue(value);
        }}
        sx={{ px: 2, bgcolor: 'background.neutral' }}
      >
        <Tab label="Active" value="active" />
        <Tab label="InActive" value="inactive" />
      </Tabs>
      <Divider />
      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
        <TextField
          fullWidth
          select
          label="Department"
          value={departmentId}
          onChange={(e) => setDepartmentId(e.target.value)}
          SelectProps={{
            MenuProps: {
              sx: { '& .MuiPaper-root': { maxHeight: 260 } },
            },
          }}
          sx={{
            maxWidth: { sm: 240 },
            textTransform: 'capitalize',
          }}
        >
          <MenuItem
            value=""
            sx={{
              mx: 1,
              my: 0.5,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            All
          </MenuItem>

          {depOfStore?.map((option) => (
            <MenuItem
              key={option}
              value={option?.id}
              sx={{
                mx: 1,
                my: 0.5,
                borderRadius: 0.75,
                typography: 'body2',
                textTransform: 'capitalize',
              }}
            >
              {option?.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          fullWidth
          value={searchUser}
          onChange={(event) => setSearchUser(event.target.value)}
          placeholder="Search user..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
      {tabValue === 'active' ? (
        <ActiveUserList departmentId={departmentId} searchUser={searchUser} />
      ) : (
        <InactiveUserList departmentId={departmentId} searchUser={searchUser} />
      )}
    </Card>
  );
}
