import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Iconify from 'components/Iconify';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Scrollbar from 'components/Scrollbar';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { deleteFoodMenuCombo, deleteFoodMenuComboCategory } from 'redux/slices/menuEngineeringSlice';
import { getIngredientListWithPrice, getRecipeIngredientListWithPrice } from 'redux/slices/ingredientSlice';
import Label from 'components/Label';
import FoodMenuComboCreate from './FoodMenuComboCreate';
import FoodMenuComboEdit from './FoodMenuComboEdit';
import FoodMenuComboCategoryCreate from './FoodMenuComboCategoryCreate';

function FoodMenuComboTable({ menu }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const [open, setOpen] = useState({ status: false, data: null });
  const [openCategory, setOpenCategory] = useState(false);
  const [openCategoryEdit, setOpenCategoryEdit] = useState({ status: false, data: null });
  const [openEdit, setOpenEdit] = useState({ status: false, data: null, parent: null });
  const [openCategoryDelete, setOpenCategoryDelete] = useState({ status: false, id: null });
  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, data: null, comboIndex: null });
  const { menuCombo, foodMenuSizes } = useSelector((state) => state.menuEngineering);
  console.log('🚀 => FoodMenuComboTable => menuCombo=>', menuCombo);

  const HandleDeleteMenuCombo = () => {
    dispatch(deleteFoodMenuCombo({ departmentId, comboItemId: openDeleteModal?.data?.id, menuId: menu?.id }));
    setOpenDeleteModal({ data: null, status: false });
  };

  const HandleDeleteCategory = () => {
    dispatch(deleteFoodMenuComboCategory({ departmentId, comboId: openCategoryDelete?.id, menuId: menu?.id }));
    setOpenCategoryDelete({ id: null, status: false });
  };

  const calculateCogs = ({ unitPrice, unitWeight, qtyUsed }) => {
    if (!unitPrice || !unitWeight || !qtyUsed) return 0;
    return Number((unitPrice / unitWeight) * (qtyUsed / 1000)).toFixed(2);
  };

  const findItemSizePrice = (item, size) => {
    const comboItemSize = item?.comboItemSizes?.find((comboItemSize) => comboItemSize?.menuSizeId === size?.id);
    if (!comboItemSize) return 0;
    const additionalPrice = comboItemSize?.additionalPrice;
    const qtyUsed = comboItemSize?.qtyUsed;

    const unitPrice = item?.ingredient?.unitPrice ?? 0;
    const unitWeight = item?.ingredient?.unitWeight ?? 0;

    const cogs = calculateCogs({ unitPrice, unitWeight, qtyUsed });
    return `$ ${additionalPrice} / ${qtyUsed} gm / $ ${cogs}`;
  };

  useEffect(() => {
    if (!departmentId || !menu) return;
    dispatch(getIngredientListWithPrice({ departmentId }));
    dispatch(getRecipeIngredientListWithPrice({ departmentId }));
  }, [departmentId, dispatch, menu]);
  return (
    <>
      {menuCombo?.length > 0 ? (
        <Scrollbar>
          <Stack spacing={1} style={{ marginTop: '10px' }}>
            <Grid container spacing={2}>
              {menuCombo?.map((combo, comboIndex) => (
                <>
                  <Grid item xs={12} key={comboIndex}>
                    <Stack spacing={1}>
                      <Stack direction={'row'}>
                        <Typography variant="subtitle1" fontWeight="bold" style={{ marginTop: '5px' }}>
                          {combo?.title}
                        </Typography>
                        <Box style={{ marginTop: '3px', marginLeft: '5px', marginRight: '5px' }}>
                          <Label color={combo?.isMultiSelectable ? 'success' : 'error'}>
                            {combo?.isMultiSelectable ? 'Multi-Selectable' : 'Not-Multi-Selectable'}
                          </Label>
                        </Box>
                        {/* <Box style={{ marginTop: '3px', marginLeft: '5px', marginRight: '5px' }}>
                          <Label color={combo?.isMultiSelectable ? 'success' : 'error'}>
                            {combo?.isMultiSelectable ? 'Multi-Selectable' : <s>Multi-selectable</s>}
                          </Label>
                        </Box> */}
                        <Box style={{ marginTop: '3px', marginLeft: '5px', marginRight: '5px' }}>
                          <Label color={combo?.isOptional ? 'success' : 'error'}>
                            {combo?.isOptional ? 'Optional' : 'Not-Optional'}
                          </Label>
                        </Box>
                        {/* <Box style={{ marginTop: '3px', marginLeft: '5px', marginRight: '5px' }}>
                          <Label color={combo?.isOptional ? 'success' : 'error'}>
                            {combo?.isOptional ? 'Optional' : <s>Optional</s>}
                          </Label>
                        </Box> */}
                        <Button
                          variant="text"
                          color="success"
                          size="small"
                          onClick={() => {
                            setOpenCategoryEdit({ status: true, data: combo });
                          }}
                        >
                          <Iconify icon="uil:edit" height={20} width={20} />
                        </Button>
                        <Button
                          style={{ marginRight: '5px' }}
                          variant="text"
                          color="primary"
                          startIcon={<Iconify icon={'eva:plus-fill'} />}
                          onClick={() => {
                            setOpen({ status: true, data: combo });
                          }}
                        >
                          Item
                        </Button>
                        {!combo?.comboItems?.length > 0 && (
                          <Button
                            variant="text"
                            color="error"
                            size="small"
                            onClick={() => setOpenCategoryDelete({ status: true, id: combo?.id })}
                          >
                            <Iconify icon="uil:trash-alt" height={20} width={20} />
                          </Button>
                        )}
                      </Stack>
                      <Stack>
                        <TableContainer>
                          <Table size="small" stickyHeader>
                            <TableHead>
                              <TableRow>
                                <TableCell>Item Name</TableCell>
                                {foodMenuSizes?.map((size, index) => (
                                  <TableCell key={index} align="center">
                                    {size?.sizeName} <br />
                                    ad. price / Qty / COGS
                                  </TableCell>
                                ))}
                                <TableCell align="center">Action</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {combo?.comboItems?.map((item, itemIndex) => (
                                <TableRow hover key={itemIndex}>
                                  <TableCell>{item?.itemName}</TableCell>
                                  {foodMenuSizes?.map((size, index) => (
                                    <TableCell key={index} align="center">
                                      {findItemSizePrice(item, size)}
                                    </TableCell>
                                  ))}
                                  <TableCell align="center">
                                    <Button
                                      variant="text"
                                      color="primary"
                                      size="small"
                                      onClick={() => {
                                        setOpenEdit({ status: true, data: item, parent: combo });
                                      }}
                                    >
                                      <Iconify icon="uil:edit" height={20} width={20} />
                                    </Button>
                                    <Button
                                      variant="text"
                                      color="error"
                                      size="small"
                                      onClick={() => {
                                        setOpenDeleteModal({
                                          status: true,
                                          data: item,
                                        });
                                      }}
                                    >
                                      <Iconify icon="bi:trash-fill" height={20} width={20} />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Stack>
                    </Stack>
                    <Divider sx={{ py: 1 }} />
                  </Grid>
                </>
              ))}
            </Grid>
          </Stack>
          <div style={{ marginTop: '10px' }} />
          <Stack alignItems={'flex-end'} style={{ marginTop: '5px' }}>
            <Stack display={'flex'} flexDirection={'row'}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                onClick={() => setOpenCategory(true)}
              >
                Category
              </Button>
            </Stack>
          </Stack>
        </Scrollbar>
      ) : (
        <Stack alignItems={'flex-start'} style={{ marginTop: '5px' }}>
          <Typography variant={'body2'}>No Combos Created</Typography>
          <Typography variant={'body2'}>
            <Button variant="text" color="primary" onClick={() => setOpenCategory(true)}>
              Click Here
            </Button>
            to add new Combo Category
          </Typography>
        </Stack>
      )}

      {open?.status && <FoodMenuComboCreate open={open?.status} setOpen={setOpen} menu={menu} data={open?.data} />}
      {openCategory && <FoodMenuComboCategoryCreate open={openCategory} setOpen={setOpenCategory} menu={menu} />}
      {openCategoryEdit?.status && (
        <FoodMenuComboCategoryCreate
          open={openCategoryEdit?.status}
          setOpen={setOpenCategoryEdit}
          menu={menu}
          isEdit
          data={openCategoryEdit?.data}
        />
      )}
      {openEdit?.status && (
        <FoodMenuComboEdit
          open={openEdit?.status}
          setOpen={setOpenEdit}
          parent={openEdit?.parent}
          item={openEdit?.data}
          menu={menu}
        />
      )}
      {openDeleteModal?.status && (
        <CustomDeleteDialog open={openDeleteModal} handleClose={setOpenDeleteModal} onAccept={HandleDeleteMenuCombo} />
      )}
      {openCategoryDelete?.status && (
        <CustomDeleteDialog
          open={openCategoryDelete}
          handleClose={setOpenCategoryDelete}
          onAccept={HandleDeleteCategory}
        />
      )}
    </>
  );
}

export default FoodMenuComboTable;
