import { Button, Card, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { deleteFoodMenu, getSingleFoodMenu } from 'redux/slices/menuEngineeringSlice';
import Iconify from 'components/Iconify';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import FoodMenuForm from '../FoodMenuForm';

function FoodMenuDetailsPage({ menu }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const [openDelete, setOpenDelete] = React.useState({ status: false, id: null });
  const { singleFoodMenuLoading } = useSelector((state) => state.menuEngineering);

  useEffect(() => {
    if (!departmentId || !menu?.id) return;
    dispatch(getSingleFoodMenu({ departmentId, menuId: menu?.id }));
  }, [dispatch, departmentId, menu?.id]);

  const proceedDelete = () => {
    dispatch(deleteFoodMenu({ departmentId, menuId: openDelete?.id }));
    setOpenDelete({ status: false, id: null });
  };
  const handleDelete = () => {
    setOpenDelete({ status: false, id: null });
  };

  return (
    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ width: '100%' }}>
      {singleFoodMenuLoading ? (
        <CustomLoadingScreen height="20vh" />
      ) : (
        <Card style={{ width: '600px', padding: '10px' }}>
          <FoodMenuForm menu={menu} isEdit />
          <Stack alignItems={'flex-start'}>
            <Button variant="contained" color="error" onClick={() => setOpenDelete({ status: true, id: menu?.id })}>
              <Iconify icon={'eva:trash-2-fill'} /> Delete
            </Button>
          </Stack>
        </Card>
      )}
      {openDelete?.status && (
        <CustomDeleteDialog open={openDelete?.status} handleClose={handleDelete} onAccept={proceedDelete} />
      )}
    </Stack>
  );
}

FoodMenuDetailsPage.propTypes = {
  menu: PropTypes.object,
};

export default FoodMenuDetailsPage;
