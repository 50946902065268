import Page from 'components/Page';
import React from 'react';
import RecommendedMenusHeader from './RecommendedMenusHeader';
import RecommendedMenusBody from './RecommendedMenusBody';

function RecommendedMenuIndex() {
  return (
    <>
      <Page title="Recommended Menus">
        <RecommendedMenusHeader />
        <RecommendedMenusBody />
      </Page>
    </>
  );
}

export default RecommendedMenuIndex;
