import { Autocomplete, Box, Stack, TextField } from '@mui/material';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllMenusWithCategories } from 'redux/slices/FoodMenuSlice';
import FoodRecipeManageDrawer from 'sections/@dashboard/foodMenuAndRecipe/FoodRecipe/FoodRecipeManageDrawer';

function FoodRecipeFilter() {
  const departmentId = useGetDepartmentId();
  const { loading, allMenuWithCategories } = useSelector((state) => state.foodMenus);
  const [open, setOpen] = useState(false);
  const [indexList, setIndexList] = useState(null);

  const dispatch = useDispatch();
  const filterAllMenusWithCategories = allMenuWithCategories.map((obj) => obj?.menus).flat() || [];

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getAllMenusWithCategories({ departmentId }));
  }, [dispatch, departmentId]);

  return (
    <>
      <Stack mx={'1rem'} my="1rem" direction={'row'} alignItems="center" justifyContent={'space-between'} gap="2rem">
        {/* <TextField label="Search Menu" fullWidth /> */}

        <Autocomplete
          name="searchMenu"
          options={filterAllMenusWithCategories}
          onChange={(event, value, reason, details) => {
            if (reason === 'clear') return;
            setIndexList({ menu: details?.option });
            setOpen(true);
          }}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          getOptionLabel={(option) => option?.name}
          renderInput={(params) => <TextField {...params} label="Search Recipe" />}
          fullWidth
          renderOption={(props, option) => (
            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
              {option?.name}
            </Box>
          )}
        />
      </Stack>

      {open && <FoodRecipeManageDrawer open={setOpen} setOpen={setOpen} menu={indexList?.menu} />}
    </>
  );
}

export default FoodRecipeFilter;
