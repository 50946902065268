import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import FoodMenuToppinsWithQtyIndividualTableCell from './FoodMenuToppinsWithQtyIndividualTableCell';

function FoodMenuToppinsWithQtyRow({ option, optionIndex }) {
  const { menuTopinsWithQty, postLoading } = useSelector((state) => state.menuEngineering);
  return (
    <TableRow hover key={optionIndex}>
      <TableCell>
        {option.optionName?.length > 20 ? `${option.optionName.slice(0, 20)}..` : option.optionName}
      </TableCell>
      {option?.menuSizes?.map((size, index) => (
        <FoodMenuToppinsWithQtyIndividualTableCell key={index} size={size} index={index} optionIndex={optionIndex} />
      ))}
    </TableRow>
  );
}

FoodMenuToppinsWithQtyRow.propTypes = {
  option: PropTypes.object,
  optionIndex: PropTypes.number,
};

export default FoodMenuToppinsWithQtyRow;
