import { Box, Button, Divider, Stack } from '@mui/material';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import Iconify from 'components/Iconify';
import moment from 'moment';
import React from 'react';

function ViewIndividualEvent({ open, setOpen, data }) {
  const totalData = data?.length;
  return (
    <CustomOutletModal
      open={open}
      width="25rem"
      height={totalData > 1 ? '30rem' : '20rem'}
      onClose={setOpen}
      title="Event Details"
      zIndex="2000"
      headerIcon="flat-color-icons:leave"
      iconColor="#1976d2"
      iconWidth={30}
      iconHeight={30}
      useDivider
      useCloseIcon
      style={{ position: 'absolute' }}
      overflowY="auto"
    >
      <Box>
        {data?.map((item, index) => (
          <>
            <Stack spacing={1} mx="1rem" my="1rem" key={index}>
              <Stack direction="row" spacing={2}>
                <Box style={{ marginRight: '7px' }}>
                  <Iconify icon="subway:title" color="#1f19d2db" width={20} height={20} />
                </Box>
                {item?.title}
              </Stack>
              <Stack direction="row" spacing={2}>
                <Box style={{ marginRight: '7px' }}>
                  <Iconify icon="simple-line-icons:note" color="#1f19d2db" width={20} height={20} />
                </Box>
                {item?.description ? item?.description : 'No description Provided'}
              </Stack>
              <Stack direction="row" spacing={2}>
                <Box style={{ marginRight: '7px' }}>
                  <Iconify icon="icon-park-solid:category-management" color="#1f19d2db" width={20} height={20} />
                </Box>
                {item?.type === 'HOLIDAY' ? 'Public Holiday' : 'Store Event'}
              </Stack>
              <Stack direction="row" spacing={2}>
                <Box style={{ marginRight: '7px' }}>
                  <Iconify icon="material-symbols:timer-off-outline" color="#1f19d2db" width={20} height={20} />
                </Box>
                {moment(item?.start).format('ddd, DD MMM hh:mm A')}
              </Stack>
              <Stack direction="row" spacing={2}>
                <Box style={{ marginRight: '7px' }}>
                  <Iconify icon="material-symbols:timer-outline" color="#1f19d2db" width={20} height={20} />
                </Box>
                {moment(item?.end).format('ddd, DD MMM hh:mm A')}
              </Stack>
            </Stack>
            <Divider />
          </>
        ))}
      </Box>

      {/* <Stack direction="row" spacing={2} justifyContent={'flex-end'}>
        <Button color={'error'} variant="text" onClick={setOpen}>
          Close
        </Button>
      </Stack> */}
    </CustomOutletModal>
  );
}

export default ViewIndividualEvent;
