import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Card, Stack } from '@mui/material';
import * as Yup from 'yup';
import moment from 'moment';
import { FormProvider } from 'components/hook-form';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { PATH_DASHBOARD } from 'routes/paths';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { postSales } from 'redux/slices/salesSlice';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import DateSelectionSection from './DateSelectionSection';
import DeliveryEntrysSection from './DeliveryEntrysSection';
import SalesPayoutSection from './SalesPayoutSection';
import PlatformSalesSection from './PlatformSalesSection';
import CashDrawerSection from './CashDrawerSection';
import SalesAdditionalInfoSection from './SalesAdditionalInfoSection';
import SalesCashCalculationSection from './SalesCashCalculationSection';

SalesCreateUpdateForm.propTypes = {
  existingData: PropTypes.object,
  isEdit: PropTypes.bool,
};
export default function SalesCreateUpdateForm({ existingData, isEdit }) {
  const { storeCashDrawer } = useSelector((state) => state.store);
  const departmentId = useGetDepartmentId();
  const { onlineSalesPlatform } = useSelector((state) => state.store);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const salesSchema = Yup.object({
    date: Yup.date().required('Required'),
    cashDrawers: Yup.array().of(
      Yup.object().shape({
        fiveCents: Yup.string().nullable(),
        tenCents: Yup.string().nullable(),
        twentyCents: Yup.string().nullable(),
        fiftyCents: Yup.string().nullable(),
        oneDollors: Yup.string().nullable(),
        twoDollors: Yup.string().nullable(),
        fiveDollors: Yup.string().nullable(),
        tenDollors: Yup.string().nullable(),
        twentyDollors: Yup.string().nullable(),
        fiftyDollors: Yup.string().nullable(),
        hundredDollors: Yup.string().nullable(),
        tillAmount: Yup.string().required('Required'),
      })
    ),
  });

  const defaultValues = useMemo(
    () => ({
      date: moment(existingData?.date).format('YYYY-MM-DD') || moment(new Date()).format('YYYY-MM-DD'),
      totalSales: existingData?.totalSales || 0,
      actualCash: existingData?.actualCash || 0,
      eft: existingData?.eft || 0,
      online: existingData?.online || 0,
      voucher: existingData?.voucher || 0,
      totalPayouts: existingData?.totalPayouts || 0,
      expectedCash: existingData?.expectedCash || 0,
      varianceAmount: existingData?.varianceAmount || 0,
      cashDrawers:
        existingData?.cashDrawers ||
        storeCashDrawer?.storeCashDrawers?.map((cashDrawer) => ({
          fiveCents: 0,
          tenCents: 0,
          twentyCents: 0,
          fiftyCents: 0,
          oneDollors: 0,
          twoDollors: 0,
          fiveDollors: 0,
          tenDollors: 0,
          twentyDollors: 0,
          fiftyDollors: 0,
          hundredDollors: 0,
          tillAmount: 0,
          storeCashDrawerId: cashDrawer.id,
        })),

      platformSales:
        existingData?.platformSales ||
        onlineSalesPlatform?.onlinePlatforms?.map((platform) => ({
          onlinePlatformId: platform.id,
          amount: 0,
        })),
      payouts: existingData?.payouts || [
        {
          title: '',
          amount: 0,
        },
      ],
    }),
    [existingData, storeCashDrawer, onlineSalesPlatform]
  );
  const methods = useForm({
    resolver: yupResolver(salesSchema),
    mode: 'onChange',
    defaultValues,
  });
  const { watch, setValue, reset, handleSubmit } = methods;
  const values = watch();

  // no useEffect =>
  useEffect(() => {
    if (isEdit) {
      reset(existingData);
    }
  }, [isEdit, existingData, reset]);

  const salesSubmitHandler = (data) => {
    const manageDeliveryCount = data?.legend?.flat().filter((el) => el.deliveryCount);

    const finalData = {
      ...data,
      departmentId,
      date: moment(data?.date).format('YYYY-MM-DD'),
      deliveryEntrys: manageDeliveryCount,
    };

    const isSuccessful = (isSuccess) => {
      if (isSuccess) navigate(PATH_DASHBOARD.sales.list);
    };
    dispatch(postSales({ finalData, isSuccess: isSuccessful }));
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(salesSubmitHandler)}>
        <Card sx={{ p: 2 }}>
          <Stack>
            <>
              <DateSelectionSection />
              <CashDrawerSection />
              <div style={{ height: '10px' }} />
              <SalesAdditionalInfoSection />
              <div style={{ height: '10px' }} />

              <PlatformSalesSection />
              <div style={{ height: '10px' }} />
              <SalesPayoutSection />
              <SalesCashCalculationSection />

              <DeliveryEntrysSection />
            </>
          </Stack>
          <Stack alignItems={'flex-end'}>
            <Button type="submit" variant="contained" color="primary">
              {isEdit ? 'Update Sales' : 'Create Sales'}
            </Button>
          </Stack>
        </Card>
      </FormProvider>
    </>
  );
}
