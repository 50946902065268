import {
  Box,
  Card,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import { RHFTextField, RHFUploadAvatar } from 'components/hook-form';
import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const recurringOptions = [
  {
    value: 'ONCE',
    label: 'Once',
  },
  {
    value: 'DAILY',
    label: 'Daily',
  },
  {
    value: 'WEEKLY',
    label: 'Weekly',
  },
  {
    value: 'FORTNIGHTLY',
    label: 'Forthnightly',
  },
  {
    value: 'MONTHLY',
    label: 'Monthly',
  },
  {
    value: 'YEARLY',
    label: 'Yearly',
  },
];

function DealOfferDealDetails() {
  const [maxDateTime, setMaxDateTime] = React.useState(null);

  const { control, setValue, watch, getValues } = useFormContext();
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'file',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const occuranceTypeChange = (e) => {
    const value = e?.target?.value;
    setValue('occuranceType', value);
    // setValue('startDateTime', null);
    setValue('endDateTime', null);
  };

  const watchOccuranceType = watch('occuranceType');

  const getMaxDate = useCallback(() => {
    const occuranceType = getValues('occuranceType');
    const startDate = getValues('startDateTime');
    if (!occuranceType || !startDate) return null;
    switch (occuranceType) {
      case 'DAILY':
        return moment(startDate).add(1, 'days').format('YYYY-MM-DDTHH:mm:ss');
      case 'WEEKLY':
        return moment(startDate).add(1, 'weeks').format('YYYY-MM-DDTHH:mm:ss');
      case 'FORTNIGHTLY':
        return moment(startDate).add(2, 'weeks').format('YYYY-MM-DDTHH:mm:ss');
      case 'MONTHLY':
        return moment(startDate).add(1, 'months').format('YYYY-MM-DDTHH:mm:ss');
      case 'YEARLY':
        return moment(startDate).add(1, 'years').format('YYYY-MM-DDTHH:mm:ss');
      default:
        return null;
    }
  }, [getValues]);

  const watchStartDate = watch('startDateTime');

  useEffect(() => {
    if (!watchOccuranceType || !watchStartDate) return;
    const maxDate = getMaxDate();
    setMaxDateTime(maxDate);
  }, [watchStartDate, watchOccuranceType, setMaxDateTime, getMaxDate]);
  return (
    <Grid container spacing={1}>
      {/* Image  */}
      <Grid item xs={12} md={3}>
        <Card sx={{ py: 10, px: 3 }}>
          <Box sx={{ mb: 5 }}>
            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="file"
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Upload Deal Image ( Allowed *.jpeg, *.jpg, *.png )
                    <br />
                  </Typography>
                }
              />
            </Box>
          </Box>
        </Card>
      </Grid>
      {/* Deal Details */}
      <Grid item xs={12} md={9}>
        <Card sx={{ py: 3, px: 3 }}>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <RHFTextField name="title" label="Title*" />
              </Grid>
              <Grid item xs={4}>
                <RHFTextField
                  name="sellingPrice"
                  label="Selling Price*"
                  InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                />
              </Grid>
              <Grid item xs={12}>
                <RHFTextField name="description" label="Description*" multiline minRows={2} />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ px: 3 }}>
                  <Controller
                    name="occuranceType"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <div>
                        <FormLabel sx={{ color: error ? 'red' : '' }}> Repeat * </FormLabel>

                        <RadioGroup {...field} onChange={(event) => occuranceTypeChange(event)} row>
                          {recurringOptions.map((option) => (
                            <FormControlLabel
                              key={option.value}
                              value={option.value}
                              control={<Radio />}
                              label={option.label}
                            />
                          ))}
                        </RadioGroup>
                      </div>
                    )}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="startDateTime"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <MobileDateTimePicker
                        {...field}
                        // disablePast
                        label="Select Start date"
                        inputFormat="dd/MMM/yyyy hh:mm a"
                        DialogProps={{ sx: { zIndex: 10000 } }}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                      />
                      {error && (
                        <FormLabel
                          style={{ marginLeft: '10px', marginTop: '5px', fontSize: '.8rem' }}
                          sx={{ color: 'red' }}
                        >
                          {error?.message}
                        </FormLabel>
                      )}
                    </div>
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="endDateTime"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <MobileDateTimePicker
                        {...field}
                        // disablePast
                        minDate={watchStartDate}
                        maxDate={maxDateTime}
                        label="Select End date"
                        inputFormat="dd/MMM/yyyy hh:mm a"
                        DialogProps={{ sx: { zIndex: 10000 } }}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                      />
                      {error && (
                        <FormLabel
                          style={{ marginLeft: '10px', marginTop: '5px', fontSize: '.8rem' }}
                          sx={{ color: 'red' }}
                        >
                          {error?.message}
                        </FormLabel>
                      )}
                    </div>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export default DealOfferDealDetails;
