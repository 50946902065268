import { Button, Stack, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { convertStringDatetoDate } from 'utils/customDateAndTimeFormat';

TimeClockAccordionDetailsClockDate.propTypes = {
  date: PropTypes.string,
  onApprove: PropTypes.func,
  clockList: PropTypes.array,
};

function TimeClockAccordionDetailsClockDate({ date, onApprove, clockList }) {
  const [isDisabled, setIsDisabled] = useState(false);

  const { timeClockStatus } = useSelector((state) => state.time);
  const newDate = convertStringDatetoDate(date, 'YYYY-MM-DD', 'Do MMM');

  const findTimeDifference = (clock) => {
    let diff = 0;
    const clockInTime = moment(clock?.clockIn?.clockTime);
    const clockOutTime = moment(clock?.clockOut?.clockTime);

    diff = clockOutTime.diff(clockInTime, 'minutes');

    return diff;
  };

  function containHighValue(diffArray, value = 960) {
    return diffArray.some((diff) => diff > value);
  }

  useEffect(() => {
    const diffArray = [];

    clockList?.forEach((clock) => {
      diffArray.push(findTimeDifference(clock));
    });
    if (containHighValue(diffArray, 960)) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [clockList]);

  return (
    <Stack alignItems={'center'} justifyContent={'center'}>
      <Typography fontWeight={'500'}>{newDate}</Typography>
      {timeClockStatus === 'PENDING' && (
        <Button variant="contained" size="small" onClick={onApprove} disabled={isDisabled}>
          Approve
        </Button>
      )}
    </Stack>
  );
}

export default TimeClockAccordionDetailsClockDate;
