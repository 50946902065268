import { Box, Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { PATH_DASHBOARD } from 'routes/paths';
import PrivateAnnouncementCreate from 'sections/@dashboard/announcement/PrivateAnnouncementCreate';
import PublicAnnouncementCreate from 'sections/@dashboard/announcement/PublicAnnouncementCreate';

function AnnouncementHeader() {
  const { currentTab } = useSelector((state) => state.announcement);
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const setOpenHandler = () => {
    setOpen(true);
  };
  return (
    <>
      <HeaderBreadcrumbs
        heading="Announcement"
        links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Announcement' }]}
        action={
          <Box className="invoiceListHeadingButtons">
            {
              <Button variant="contained" startIcon={<Iconify icon={'eva:plus-fill'} />} onClick={setOpenHandler}>
                Announcement
              </Button>
            }

            {open && currentTab === 0 && <PublicAnnouncementCreate open={open} handleClose={handleClose} />}
            {open && currentTab === 1 && <PrivateAnnouncementCreate open={open} handleClose={handleClose} />}
          </Box>
        }
      />
    </>
  );
}

export default AnnouncementHeader;
