import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import React from 'react';
import DeliveryZoneForm from './DeliveryZoneForm';

function UpdateDeliveryZone({ open, handleClose, updateData }) {
  return (
    <CustomOutletModal open={open} zIndex="1600" title="Update Delivery Zone">
      <DeliveryZoneForm handleClose={handleClose} updateData={updateData} isEdit />
    </CustomOutletModal>
  );
}

export default UpdateDeliveryZone;
