import { Box, Card, Tab, Tabs } from '@mui/material';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import React, { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { getSalesList, postSales } from 'redux/slices/salesSlice';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormProvider } from 'components/hook-form';
import { findAllDaysFromWeeks } from 'utils/roasterFeatureUtils';
import AddEditSales from '../addEdit';

import SalesListingFilter from '../SalesListingFilter';
import SalesSummary from './SalesSummary';
import salesSchema from '../SalesYupResolver';

export default function SalesBody() {
  const { storeDate } = useSelector((state) => state.ShiftRequest);
  const { dailySales, paymentType } = useSelector((state) => state.sales);

  const {
    onlineSalesPlatform,
    break: storeDetail,
    storeCashDrawer,
    driverIncentives,
  } = useSelector((state) => state.store);

  const defaultValues = useMemo(
    () => ({
      storeSales: 0,
      actualCash: 0,
      online: 0,
      totalPayouts: 0,
      pcEft: 0,
      // voucher: 0,
      // storeOnline: 0,
      // other: 0,
      // totalUnpaid: 0,
      // totalOnlineOrder: 0,
      expectedCash: 0,
      varianceAmount: 0,
      note: '',
      totalDeliveryCount: 0,
      paymentTypeSales: paymentType?.map((el) => ({ paymentTypeId: el.id, amount: 0 })),
      // storeEftSales: storeEft?.map((eft) => ({ storeEFTId: eft.id, amount: 0 })),
      cashDrawers: storeCashDrawer?.storeCashDrawers?.map((cashDrawer) => ({
        fiveCents: 0,
        tenCents: 0,
        twentyCents: 0,
        fiftyCents: 0,
        oneDollors: 0,
        twoDollors: 0,
        fiveDollors: 0,
        tenDollors: 0,
        twentyDollors: 0,
        fiftyDollors: 0,
        hundredDollors: 0,
        tillAmount: 0,
        storeCashDrawerId: cashDrawer.id,
      })),

      platformSales: onlineSalesPlatform?.onlinePlatforms?.map((platform) => ({
        onlinePlatformId: platform.id,
        amount: 0,
        qty: 0,
      })),
      payouts: [],
    }),
    [storeCashDrawer, onlineSalesPlatform, paymentType]
  );

  const [choosenDate, setChoosenDate] = useState(new Date());

  const [allWeeks, setAllWeeks] = useState([]);
  const [currentTab, setCurrentTab] = useState();
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const methods = useForm({
    resolver: yupResolver(salesSchema),
    mode: 'onChange',
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = methods;

  const defaultTab = allWeeks?.find((item) => item.label === moment(choosenDate).format('YYYY-MM-DD'));

  useEffect(() => {
    setCurrentTab(allWeeks?.find((item) => item.label === moment(choosenDate).format('YYYY-MM-DD'))?.id);
  }, [allWeeks, choosenDate]);

  const fromDate = moment(choosenDate).startOf('isoWeek').format('YYYY-MM-DD');
  const toDate = moment(choosenDate).endOf('isoWeek').format('YYYY-MM-DD');

  useEffect(() => {
    if (!departmentId) return;
    if (!storeDetail?.id) return;
    const currentTabDate = allWeeks?.find((item) => item.id === currentTab)?.label;

    dispatch(
      getSalesList({ date: moment(currentTabDate).format('YYYY-MM-DD'), departmentId, storeId: storeDetail?.id })
    );
  }, [dispatch, departmentId, storeDetail?.id, allWeeks, currentTab]);

  useEffect(() => {
    if (!storeDate) return;
    setChoosenDate(storeDate);
  }, [storeDate]);

  //   get weeks for tabs
  useEffect(() => {
    const getAllDates = findAllDaysFromWeeks(fromDate, toDate).map((obj, index) => ({
      id: index,
      label: moment(obj).format('YYYY-MM-DD'),
    }));
    setAllWeeks(getAllDates);
  }, [fromDate, toDate]);

  // finding default tabs
  useEffect(() => {
    if (!allWeeks.length) return;
    setCurrentTab(defaultTab?.id);
  }, [allWeeks, defaultTab]);

  // Disabling future tabs
  const isDisabled = (week) => {
    const isFuture = moment(week.label).isAfter(moment(storeDate).format('YYYY-MM-DD'));
    if (!isFuture) return false;
    return true;
  };

  const SalesSubmitHandler = (data) => {
    const manageDeliveryCount = data?.legend?.flat().filter((el) => el.deliveryCount);
    const date = allWeeks?.find((item) => item.id === currentTab)?.label;

    const finalData = {
      ...data,
      departmentId,
      storeId: storeDetail?.id,
      date: moment(date).format('YYYY-MM-DD'),
      deliveryEntrys: manageDeliveryCount?.map((item) => ({
        ...item,
        totalIncentive: item?.deliveryCount * driverIncentives?.amount,
      })),
    };
    // console.log('🚀 => SalesSubmitHandler => finalData=>', finalData);

    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        reset({ ...dailySales });
      }
    };
    dispatch(postSales({ finalData, isSuccess: isSuccessful }));
  };

  useEffect(() => {
    if (!dailySales?.id) reset({ ...defaultValues });
    else reset({ ...dailySales, note: dailySales?.note || '' });
  }, [dailySales, reset, defaultValues]);

  // console.log('dailySales', methods.watch());
  // console.log('errors', errors);

  return (
    <>
      {/* <Box> */}
      <SalesListingFilter choosenDate={choosenDate} setChoosenDate={setChoosenDate} />

      <Card>
        <FormProvider methods={methods} onSubmit={handleSubmit(SalesSubmitHandler)}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={currentTab}
              onChange={(event, value) => {
                // reset();
                setCurrentTab(value);
              }}
              aria-label="Stock and Order Tabs"
              variant="scrollable"
            >
              {allWeeks?.map((week) => (
                <Tab
                  // label={moment(week.label).format('MMM-DD, ddd')}
                  label={moment(week.label).format('ddd, DD MMM')}
                  id={week.id}
                  key={week.id}
                  disabled={isDisabled(week)}
                  style={{ marginLeft: '10px' }}
                />
              ))}
              <Tab label="Summary" id="7" />
            </Tabs>
          </Box>

          {currentTab === 0 && (
            <>
              <AddEditSales choosenDate={allWeeks[0]?.label} />
            </>
          )}
          {currentTab === 1 && (
            <>
              <AddEditSales choosenDate={allWeeks[1]?.label} />
            </>
          )}
          {currentTab === 2 && (
            <>
              <AddEditSales choosenDate={allWeeks[2]?.label} />
            </>
          )}
          {currentTab === 3 && (
            <>
              <AddEditSales choosenDate={allWeeks[3]?.label} />
            </>
          )}
          {currentTab === 4 && (
            <>
              <AddEditSales choosenDate={allWeeks[4]?.label} />
            </>
          )}
          {currentTab === 5 && (
            <>
              <AddEditSales choosenDate={allWeeks[5]?.label} />
            </>
          )}
          {currentTab === 6 && (
            <>
              <AddEditSales choosenDate={allWeeks[6]?.label} />
            </>
          )}
        </FormProvider>
        {currentTab === 7 && <SalesSummary date={allWeeks} />}
      </Card>
      {/* </Box> */}
    </>
  );
}
