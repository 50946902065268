import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Stack } from '@mui/material';
import CustomMultipleAutoCompleteField from 'components/CustomComponents/CustomAutoCompleteField';
import CustomDatePicker from 'components/CustomComponents/CustomDatePicker';
import CustomMobileTimePicker from 'components/CustomComponents/CustomMobileTimePicker';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import CustomTimePicker from 'components/CustomComponents/CustomTimePicker';
import { FormProvider, RHFTextField } from 'components/hook-form';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { createTimeClockByAdmin } from 'redux/slices/timeSlice';
import { getAllUserss } from 'redux/slices/userSlice';
import { useDispatch, useSelector } from 'redux/store';
import { convertDateintoTimeString, convertDatetoString } from 'utils/customDateAndTimeFormat';
import * as Yup from 'yup';

function AddTimeDuration({ open, setOpen }) {
  const dispatch = useDispatch();
  const { selectedDepartment } = useSelector((state) => state.userDepartment);
  const { allUsers, loading } = useSelector((state) => state.user);

  const closeModal = () => {
    setOpen(false);
  };

  const TimeDurationSchema = Yup.object().shape({
    date: Yup.date().required('Date is required'),
    inTime: Yup.date().required('InTime is required'),
  });

  const defaultValue = {
    inTime: null,
    outTime: null,
    date: null,
  };

  const methods = useForm({ defaultValues: defaultValue, resolver: yupResolver(TimeDurationSchema) });
  const { handleSubmit, watch } = methods;

  useEffect(() => {
    if (!Object.keys(selectedDepartment)) return;
    dispatch(getAllUserss({ departmentId: selectedDepartment?.id, statusList: ['ACTIVE'] }));
  }, [selectedDepartment, dispatch]);

  const AddTimeDurationHandler = (data) => {
    const finalData = {
      userId: data?.userId?.id,
      departmentId: selectedDepartment?.id,
      date: convertDatetoString(data?.date),
      inTime: convertDateintoTimeString(data?.inTime),
      outTime: convertDateintoTimeString(data?.outTime),
    };
    dispatch(createTimeClockByAdmin(finalData))
      .unwrap()
      .then((originalPromiseResult) => {
        closeModal();
      });
  };

  return (
    <CustomOutletModal open={open} onClose={closeModal} zIndex="1600" title="Create Time Duration">
      <FormProvider methods={methods} onSubmit={handleSubmit(AddTimeDurationHandler)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomMultipleAutoCompleteField
              name="userId"
              label="Select User"
              options={allUsers?.users}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName} + ${option.phone}`}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <img width="20" src={option.thumbnailURL} alt="" />
                  {option.firstName} {option.lastName} + {option.phone}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomDatePicker name="date" label="Select Date" />
            {/* <RHFTextField name="date" type="date" label="Select Date" InputLabelProps={{ shrink: 'true' }} /> */}
          </Grid>

          <Grid item xs={6}>
            {/* <CustomTimePicker name="inTime" label="Select InTime" style={{ width: '100%' }} /> */}
            <CustomMobileTimePicker name="inTime" label="Select InTime" style={{ width: '100%' }} />
          </Grid>

          <Grid item xs={6}>
            {/* <CustomTimePicker name="outTime" label="Select outTime" fullWidth /> */}
            <CustomMobileTimePicker name="outTime" label="Select outTime" style={{ width: '100%' }} />
          </Grid>
          <Grid item xs={12}>
            <Stack alignItems={'flex-end'}>
              <div>
                <Button type="submit" variant="contained" style={{ marginRight: '5px' }}>
                  Create
                </Button>
                <Button variant="text" style={{ color: 'red' }} onClick={closeModal}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </CustomOutletModal>
  );
}

export default AddTimeDuration;
