import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import UserNotesTableCellWithAvatar from './UserNotesTableCellWithAvatar';
import IndividualUserNoteTableCell from './IndividualUserNoteTableCell';

UserNoteTableBodyUserEntity.propTypes = {
  obj: PropTypes.object,
  mainIndex: PropTypes.number,
};

function UserNoteTableBodyUserEntity({ obj, mainIndex }) {
  return (
    <TableRow key={obj?.fieldID} style={{ overflow: 'hidden' }}>
      <TableCell>
        <UserNotesTableCellWithAvatar obj={obj} mainIndex={mainIndex} />
      </TableCell>
      {Object.keys(obj?.weeklyNote).map((day, dayIndex) => (
        <IndividualUserNoteTableCell
          key={dayIndex}
          day={day}
          mainIndex={mainIndex}
          userInfo={obj}
          dayIndex={dayIndex}
        />
      ))}
    </TableRow>
  );
}

export default UserNoteTableBodyUserEntity;
