import React, { useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';
import { approveClockEvents, createTimeClockByManager, deleteClockEventsEntity } from 'redux/slices/timeSlice';
import { useDispatch } from 'react-redux';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import { convertDatetoString } from 'utils/customDateAndTimeFormat';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import moment from 'moment';
import TimeClockAccordionDetailsClockDate from './TimeClockAccordionDetailsClockDate';
import TimeClockAccordionDetailsClockList from './TimeClockAccordionDetailsClockList';
import EditTimeClockEventsDrawer from '../../EditTimeClockEvents/EditTimeClockEventsDrawer';

TimeClockAccordionDetailsDateList.propTypes = {
  date: PropTypes.string,
  userIndex: PropTypes.number,
  userId: PropTypes.number,
  clockListIndex: PropTypes.number,
};

function TimeClockAccordionDetailsDateList({ userId, clockListIndex, date, userIndex }) {
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [isRender, setIsRender] = useState(false);
  const [eventData, setEventData] = useState(null);
  const { control, getValues, setValue } = useFormContext();
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const { fields, remove } = useFieldArray({
    control,
    name: `data.${userIndex}.timeClocks.${clockListIndex}`,
    keyName: 'fieldId',
  });

  // for deleting clockEvents / modal
  const handleOpen = (clockIn, clockOut, index) => {
    setOpen(true);
    setEventData({ clockIn, clockOut, index });
  };

  const handleClose = () => {
    setOpen(false);
    setEventData(null);
  };

  // for updating clockEvents / modal
  const handleOpenUpdate = (clockIn, clockOut, index) => {
    setOpenUpdate(true);
    setEventData({ clockIn, clockOut, index });
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
    setEventData(null);
  };

  // deleteing clockEvents
  const DeleteClockEventHandler = () => {
    const onSuccess = () => {
      remove(eventData?.index);
      handleClose();
    };
    const finalData = {
      clockInId: eventData?.clockIn?.id,
      clockOutId: eventData?.clockOut?.id,
      employeeId: userId,
      departmentId,
    };
    dispatch(deleteClockEventsEntity({ onSuccess, data: finalData }));
  };

  const UpdateClockEventHandler = (data) => {
    // const clockIn = data?.clockInDatetime;
    // let clockOut = data?.clockOutDatetime;

    // const difference = moment(clockOut).diff(moment(clockIn));
    // if (difference < 0) {
    //   clockOut = moment(clockOut).add(1, 'day');
    // }

    const finalData = {
      ...data,
      departmentId,
      employeeId: userId,
      // clockInDatetime: convertDatetoString(clockIn, 'YYYY-MM-DDTHH:mm:ss'),
      // clockOutDatetime: convertDatetoString(clockOut, 'YYYY-MM-DDTHH:mm:ss'),
      clockInDatetime: convertDatetoString(data?.clockInDatetime, 'YYYY-MM-DDTHH:mm:ss'),
      clockOutDatetime: convertDatetoString(data?.clockOutDatetime, 'YYYY-MM-DDTHH:mm:ss'),
    };
    // console.log('🚀 => UpdateClockEventHandler => finalData=>', finalData);
    dispatch(createTimeClockByManager({ data: finalData })).then((res) => {
      if (res?.meta?.requestStatus === 'fulfilled') {
        remove(eventData?.index);
      }
      handleCloseUpdate();
    });
  };

  // for approving
  const ApproveDateEventHandler = () => {
    const clockList = getValues(`data.${userIndex}.timeClocks.${clockListIndex}`);

    let isClockOutNull = false;
    let i = 0;

    while (i < clockList.length) {
      const clockOut = clockList?.[i]?.clockOut;
      if (!clockOut) {
        isClockOutNull = true;
        break;
      }
      i += 1;
    }

    if (isClockOutNull) return toast.error('Clock Out Empty. ');

    const onSuccess = () => {
      setValue(`data.${userIndex}.timeClocks.${clockListIndex}`, []);
      setIsRender(true);
    };
    const finalData = {
      employeeId: userId,
      departmentId,
      clockList,
    };
    dispatch(approveClockEvents({ onSuccess, data: finalData }));
  };

  return (
    <>
      {fields?.length ? (
        <>
          <Stack className="timeClockAccordionDetailsEachDateClock" direction="row" gap={1} p={1} minHeight={'2rem'}>
            <TimeClockAccordionDetailsClockDate date={date} onApprove={ApproveDateEventHandler} clockList={fields} />
            <TimeClockAccordionDetailsClockList
              clockList={fields}
              onEventDelete={handleOpen}
              onUpdate={handleOpenUpdate}
            />
          </Stack>

          {open && <CustomDeleteDialog open={open} handleClose={handleClose} onAccept={DeleteClockEventHandler} />}
          {openUpdate && (
            <EditTimeClockEventsDrawer
              open={openUpdate}
              handleClose={handleCloseUpdate}
              data={{ ...eventData, userId }}
              onUpdate={UpdateClockEventHandler}
            />
          )}
        </>
      ) : (
        ''
      )}
    </>
  );
}

export default TimeClockAccordionDetailsDateList;
