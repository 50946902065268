import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import React from 'react';
import PublicAnnouncementCreateForm from './PublicAnnouncementCreateForm';

function PublicAnnouncementEdit({ open, handleClose, data }) {
  return (
    <CustomOutletModal open={open} onClose={handleClose} zIndex="1600" title="Update Public Announcement">
      <PublicAnnouncementCreateForm handleClose={handleClose} isEdit updateData={data} />
    </CustomOutletModal>
  );
}

export default PublicAnnouncementEdit;
