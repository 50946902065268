import {
  Button,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import { TableHeadCustom } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateFoodRecipeSummary } from 'redux/slices/FoodRecipeSlice';
import { calculateTargetChanges } from 'utils/FoodRecipeCalculation';
// import FoodRecipeSummaryTable from './FoodRecipeSummaryTable';

const TABLE_HEAD = [
  { id: 'sn', label: 'S.N.', align: 'left' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'sp', label: 'SP', align: 'left' },
  { id: 'spExGST', label: 'SP. ex. GST', align: 'left' },
  { id: 'cogs', label: 'Cogs', align: 'left' },
  { id: 'cogspercent', label: 'Cogs %', align: 'left' },
  { id: 'gp', label: 'GP', align: 'left' },
  { id: 'gpPercent', label: 'GP %', align: 'left' },

  { id: 'target', label: 'Taget ', align: 'left' },
  { id: 'targetPercent', label: 'Taget Percent', align: 'left' },
  { id: 'difference', label: 'Diff', align: 'left' },
  { id: 'differencePercent', label: 'Diff %', align: 'left' },
  { id: 'actions', label: 'Actions', align: 'left' },
];
export default function FoodRecipeSummaryForm() {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const { setValue, control, handleSubmit, setError } = useFormContext();
  const watchValues = useWatch({
    control,
    name: 'receipesSummary',
  });

  const onChangeTarget = (row, index) => {
    const calculateChanges = calculateTargetChanges(row);
    setValue(`receipesSummary.${index}.targetPercent`, calculateChanges?.targetPercent.toFixed(2));
    setValue(`receipesSummary.${index}.difference`, calculateChanges?.diff.toFixed(2));
    setValue(`receipesSummary.${index}.differencePercentage`, calculateChanges?.diffPercent);
  };
  const onChangeTargetPercent = (row, index) => {
    const calcTarget = (Number(row?.cogs) * 100) / (Number(row?.targetPercent * 10) / 11);
    const target = calcTarget === Infinity ? 0 : calcTarget;
    setValue(`receipesSummary.${index}.target`, target?.toFixed(2));
    const diff = target - row?.sp || 0;
    const diffPercent = ((target - row?.sp) * 100) / row?.sp || 0;

    setValue(`receipesSummary.${index}.difference`, diff.toFixed(2));
    setValue(`receipesSummary.${index}.differencePercentage`, diffPercent);
  };

  const SaveTargetHandler = (data) => {
    const finalData = { ...data, departmentId };
    dispatch(updateFoodRecipeSummary({ finalData }));
  };

  return (
    <TableContainer>
      <Table>
        <TableHeadCustom headLabel={TABLE_HEAD} />
        <TableBody>
          {watchValues?.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}.</TableCell>
              <TableCell>{row?.name}</TableCell>
              <TableCell>{row?.sp}</TableCell>
              <TableCell>{row?.spExGST.toFixed(2)}</TableCell>
              <TableCell>{row?.cogs.toFixed(2)}</TableCell>
              <TableCell>{row?.cogsPercentage.toFixed(2)}</TableCell>
              <TableCell>{row?.gp.toFixed(2)}</TableCell>
              <TableCell>{row?.gpPercentage.toFixed(2)}</TableCell>

              <TableCell>
                <RHFTextField
                  name={`receipesSummary.${index}.target`}
                  size="small"
                  InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  onChange={(event) => {
                    setValue(`receipesSummary.${index}.target`, event.target.value);
                    onChangeTarget(row, index);
                  }}
                  fullWidth={false}
                  sx={{ width: '6rem' }}
                />
              </TableCell>
              <TableCell>
                <RHFTextField
                  name={`receipesSummary.${index}.targetPercent`}
                  size="small"
                  InputProps={{ endAdornment: <InputAdornment position="start">%</InputAdornment> }}
                  onChange={(event) => {
                    setValue(`receipesSummary.${index}.targetPercent`, event.target.value);
                    onChangeTargetPercent(row, index);
                  }}
                  fullWidth={false}
                  sx={{ width: '6.5rem' }}
                />
              </TableCell>
              <TableCell>
                <Typography
                  component="span"
                  fontSize="0.875rem"
                  display="inline-block"
                  width="3rem"
                  sx={{ wordWrap: 'break-word' }}
                >
                  {Number(row?.difference).toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  component="span"
                  fontSize="0.875rem"
                  display="inline-block"
                  width="4rem"
                  sx={{ wordWrap: 'break-word' }}
                >
                  {Number(row?.differencePercentage)?.toFixed(2)}
                </Typography>
              </TableCell>
              <TableCell>
                {/* <Button variant="contained" onClick={handleSubmit(SaveTargetHandler)}> */}
                <Button variant="contained" onClick={() => SaveTargetHandler(row)}>
                  Save
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
