import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Stack, TextField } from '@mui/material';
import CustomMultipleAutoCompleteField from 'components/CustomComponents/CustomAutoCompleteField';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { FormProvider, RHFTextField } from 'components/hook-form';
import React, { useEffect, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import { createSuburb } from 'redux/slices/posConfigSlice';
import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().required(''),
});

function SuburbAddForm({ handleClose, updateData, isEdit }) {
  const [postalCode, setPostalCode] = React.useState(updateData?.postalCode || '');
  const { postLoading, storeStates, deliveryZoneData, storeCities, loading } = useSelector((state) => state.posConfig);
  const dispatch = useDispatch();
  const defaultValues = {
    name: '',
  };

  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onChange' });

  const { reset, handleSubmit } = methods;

  const SuburbSubmitHandler = (data) => {
    const finalData = {
      ...data,
      postalCode,
      deliveryZoneId: data?.deliveryZoneId?.id,
      cityId: data?.cityId?.id,
      // stateId: data?.stateId?.id,
    };
    console.log('🚀 => SuburbSubmitHandler => finalData=>', finalData);

    dispatch(createSuburb({ finalData, handleClose, isEdit }));
  };

  useEffect(() => {
    if (!isEdit) return;
    reset({
      ...updateData,
    });
  }, [isEdit, updateData, reset]);

  console.log(methods?.watch(), 'formData');

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(SuburbSubmitHandler)}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <RHFTextField name="name" label="Name" />
        </Grid>
        <Grid item xs={4}>
          <TextField
            required
            id="outlined-required"
            label="Postal Code"
            value={postalCode}
            onChange={(event) => setPostalCode(event.target.value)}
          />
        </Grid>
        {loading ? (
          <CustomLoadingScreen />
        ) : (
          <>
            <Grid item xs={6}>
              <CustomMultipleAutoCompleteField
                name="deliveryZoneId"
                label="Select Delivery Zone"
                options={deliveryZoneData}
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Grid>
            {/* <Grid item xs={4}>
          <CustomMultipleAutoCompleteField
            name="stateId"
            label="Select State"
            options={storeStates}
            getOptionLabel={(option) => option?.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
          />
        </Grid> */}
            <Grid item xs={6}>
              <CustomMultipleAutoCompleteField
                name="cityId"
                label="Select City"
                options={storeCities}
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Grid>
          </>
        )}
      </Grid>
      <Stack alignItems="flex-end" justifyContent={'flex-end'} mt="1rem" direction="row" gap="1rem">
        <Button variant="contained" type="submit" color="secondary" disabled={postLoading}>
          Save
        </Button>
        <Button variant="outlined" onClick={handleClose} color="error">
          Cancel
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default SuburbAddForm;
