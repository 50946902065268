import { Box } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import PropTypes from 'prop-types';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';

import { getSizeGroupOptionGroupDetails } from 'redux/slices/menuERSizeAndOptionSlice';
import MenuSizeOptionPriceEditForm from './MenuSizeOptionPriceEditForm';

ManageMenuSizeOption.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  selectedRow: PropTypes.object,
};

function ManageMenuSizeOption({ open, setOpen, selectedRow, size }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.menuERSizeAndOption);

  useEffect(() => {
    if (!selectedRow || !departmentId) return;
    dispatch(
      getSizeGroupOptionGroupDetails({
        departmentId,
        sizeGroupOptionGroupId: selectedRow.id,
        sizeGroupId: selectedRow.sizeGroupId,
        optionGroupId: selectedRow.optionGroupId,
      })
    );
  }, [departmentId, dispatch, selectedRow]);

  return (
    <CustomDrawer
      open={open}
      setOpen={setOpen}
      title={` ${size?.name},  ${selectedRow?.optionGroupName}`}
      PaperProps={{ sx: { minWidth: '70%' } }}
    >
      <Box>
        {loading ? (
          <CustomLoadingScreen height="30vh" />
        ) : (
          <MenuSizeOptionPriceEditForm handleClose={setOpen} selectedRow={selectedRow} />
        )}
      </Box>
    </CustomDrawer>
  );
}

export default ManageMenuSizeOption;
