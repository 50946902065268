import { Card, Divider, Tab, TableContainer, Tabs } from '@mui/material';
import Scrollbar from 'components/Scrollbar';
import React, { useState } from 'react';
// import { useDispatch } from 'react-redux';
import ShiftApproveTable from './ShiftApproveTable';
import ShiftRequestTable from './ShiftRequestTable';

export default function ShiftRequestCard() {
  // const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState('pending');
  // const [pageNo, setPageNo] = useState(0);

  return (
    <>
      <Card>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={selectedTab}
          onChange={(e, value) => {
            setSelectedTab(value);
          }}
          sx={{ px: 2, bgcolor: 'background.neutral' }}
        >
          <Tab label="Pending" value="pending" />
          <Tab label="Approved" value="approved" />
        </Tabs>
        <Divider />

        {/* TABLE LIST */}
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
            {selectedTab === 'pending' ? <ShiftRequestTable /> : <ShiftApproveTable />}
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
}
