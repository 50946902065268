import React from 'react';
import { PropTypes } from 'prop-types';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { Button, Divider, FormHelperText, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { FormProvider } from 'components/hook-form';
import { deleteAgreementDocument, uploadAgreementDocument } from 'redux/slices/userSlice';
import Label from 'components/Label';

function HrDocumentUpload({ open, setOpen }) {
  const { agreementList } = useSelector((state) => state.user);

  const methods = useForm({});
  const dispatch = useDispatch();
  const {
    control,
    register,
    handleSubmit,
    setValue,
    unregister,
    formState: { errors },
  } = methods;
  const watchSOP = useWatch({
    control,
    name: 'SOP',
  });

  const watchOHNS = useWatch({
    control,
    name: 'OHNS',
  });

  const watchHR = useWatch({
    control,
    name: 'HR',
  });
  const isSOPValid = watchSOP && !errors?.SOP;
  const isOHNSValid = watchOHNS && !errors?.OHNS;
  const isHRValid = watchHR && !errors?.HR;

  const handleSOPSubmit = (event) => {
    const file = event.target.files[0];
    if (file) {
      setValue('SOP', file);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', 'SOP');
      dispatch(uploadAgreementDocument({ formData, type: 'SOP' }));
    }
  };
  const handleOHNSSubmit = (event) => {
    const file = event.target.files[0];
    if (file) {
      setValue('OHNS', file);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', 'OHNS');
      dispatch(uploadAgreementDocument({ formData, type: 'OHNS' }));
    }
  };
  const handleHRSubmit = (event) => {
    const file = event.target.files[0];
    if (file) {
      setValue('HR', file);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', 'HR');
      dispatch(uploadAgreementDocument({ formData, type: 'HR' }));
    }
  };
  const HandleHRDelete = () => {
    unregister('HR');
    dispatch(deleteAgreementDocument({ type: 'HR' }));
  };

  const HandleOHNSDelete = () => {
    unregister('OHNS');
    dispatch(deleteAgreementDocument({ type: 'OHNS' }));
  };

  const HandleSOPDelete = () => {
    unregister('SOP');
    dispatch(deleteAgreementDocument({ type: 'SOP' }));
  };

  return (
    <CustomOutletModal open={open} zIndex={1600} title={'Upload Documents '}>
      <FormProvider methods={methods} onSubmit={handleSubmit()}>
        <Stack style={{ marginTop: '10px', marginLeft: '20px' }}>
          <Typography component="p" color={'error'} style={{ marginTop: '5px', marginBottom: '5px' }}>
            SOP PDF *
          </Typography>
          <Stack gap="0.5rem" direction={'row'}>
            <Button variant="contained" sx={{ padding: '0 !important', width: 'fit-content' }}>
              <label htmlFor="SOP" style={{ padding: '0.5rem', cursor: 'pointer' }}>
                <input
                  type="file"
                  {...register('SOP')}
                  accept="application/pdf"
                  id="SOP"
                  hidden
                  onChange={(event) => {
                    handleSOPSubmit(event);
                  }}
                />
                <Stack>Upload PDF</Stack>
              </label>
            </Button>
            <Typography component="span" fontSize="0.8rem" color={'info'} style={{ marginTop: '8px' }}>
              {watchSOP?.name || (agreementList?.SOP && 'SOP.pdf') ? (
                <a href={agreementList?.SOP} target="_blank" rel="noreferrer">
                  <Label color={'success'}>{watchSOP?.name || (agreementList?.SOP && 'SOP.pdf')}</Label>
                </a>
              ) : (
                ''
              )}
            </Typography>

            {agreementList?.SOP && (
              <Stack>
                <Button variant="text" color="error" onClick={() => HandleSOPDelete()}>
                  Delete SOP
                </Button>
              </Stack>
            )}
            {!isSOPValid && (
              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {errors?.SOP?.message}
              </FormHelperText>
            )}
          </Stack>
          <Divider sx={{ borderStyle: 'dashed', borderWidth: '2px', my: 2 }} />

          <Typography component="p" color={'error'} style={{ marginTop: '5px', marginBottom: '5px' }}>
            OHNS PDF *
          </Typography>
          <Stack gap="0.5rem" direction={'row'}>
            <Button variant="contained" sx={{ padding: '0 !important', width: 'fit-content' }}>
              <label htmlFor="OHNS" style={{ padding: '0.5rem', cursor: 'pointer' }}>
                <input
                  type="file"
                  {...register('OHNS')}
                  accept="application/pdf"
                  id="OHNS"
                  hidden
                  onChange={(event) => {
                    handleOHNSSubmit(event);
                  }}
                />
                <Stack>Upload PDF</Stack>
              </label>
            </Button>
            <Typography component="span" fontSize="0.8rem" color={'info'} style={{ marginTop: '8px' }}>
              {watchOHNS?.name || (agreementList?.OHNS && 'OHNS.pdf') ? (
                <a href={agreementList?.OHNS} target="_blank" rel="noreferrer">
                  <Label color={'success'}>{watchOHNS?.name || (agreementList?.OHNS && 'OHNS.pdf')}</Label>
                </a>
              ) : (
                ''
              )}
            </Typography>
            {agreementList?.OHNS && (
              <Stack>
                <Button variant="text" color="error" onClick={() => HandleOHNSDelete()}>
                  Delete OHNS
                </Button>
              </Stack>
            )}
            {!isOHNSValid && (
              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {errors?.OHNS?.message}
              </FormHelperText>
            )}
          </Stack>
          <Divider sx={{ borderStyle: 'dashed', borderWidth: '2px', my: 2 }} />
          <Typography component="p" color={'error'} style={{ marginTop: '5px', marginBottom: '5px' }}>
            HR PDF *
          </Typography>
          <Stack gap="0.5rem" direction={'row'}>
            <Button variant="contained" sx={{ padding: '0 !important', width: 'fit-content' }}>
              <label htmlFor="HR" style={{ padding: '0.5rem', cursor: 'pointer' }}>
                <input
                  type="file"
                  {...register('HR')}
                  accept="application/pdf"
                  id="HR"
                  hidden
                  onChange={(event) => {
                    handleHRSubmit(event);
                  }}
                />
                <Stack>Upload PDF</Stack>
              </label>
            </Button>
            <Typography component="span" fontSize="0.8rem" color={'info'} style={{ marginTop: '8px' }}>
              {watchHR?.name || (agreementList?.HR && 'HR.pdf') ? (
                <a href={agreementList?.HR} target="_blank" rel="noreferrer">
                  <Label color={'success'}>{watchHR?.name || (agreementList?.HR && 'HR.pdf')}</Label>
                </a>
              ) : (
                ''
              )}
            </Typography>
            {agreementList?.HR && (
              <Stack>
                <Button variant="text" color="error" onClick={() => HandleHRDelete()}>
                  Delete HR
                </Button>
              </Stack>
            )}
            {!isHRValid && (
              <FormHelperText error sx={{ px: 2, textAlign: 'center' }}>
                {errors?.HR?.message}
              </FormHelperText>
            )}
          </Stack>
        </Stack>
        <Stack alignItems="flex-end" justifyContent={'flex-end'} mt="1rem" direction="row" gap="1rem">
          <Button variant="contained" onClick={() => setOpen(false)} color="error">
            close
          </Button>
        </Stack>
      </FormProvider>
    </CustomOutletModal>
  );
}
HrDocumentUpload.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};
export default HrDocumentUpload;
