import { Table, TableBody, TableContainer } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FoodMenuSizeGeneralPriceSingleSize from './FoodMenuSizeGeneralPriceSingleSize';

function FoodMenuSizeGeneralPriceSection() {
  const { control } = useFormContext();
  const watchData = useWatch({
    control,
    name: 'menuSizes',
  });

  return (
    <Table size="small" style={{ marginTop: '5px' }}>
      <TableBody>
        {watchData?.map((size, index) => (
          <FoodMenuSizeGeneralPriceSingleSize size={size} index={index} key={index} />
        ))}
      </TableBody>
    </Table>
  );
}

export default FoodMenuSizeGeneralPriceSection;
