import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import Iconify from 'components/Iconify';
import PropTypes from 'prop-types';
import { FormProvider, RHFTextField } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  createNewRecipeIngredient,
  deleteIndividualRecipeIngredient,
  getIngredientList,
  getIngredientListWithPrice,
} from 'redux/slices/ingredientSlice';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import RecipeCreateFormSingleIngredient from './RecipeCreateFormSingleIngredient';

const RecipeSchema = Yup.object().shape({
  name: Yup.string().required(''),
  recipeIngredients: Yup.array().of(
    Yup.object().shape({
      qtyUsed: Yup.number()
        .required('')
        .transform((curr, orig) => (orig === '' ? 0 : curr))

        .typeError(''),
      // singleIngredientId: Yup.object().required(''),
    })
  ),
});

function RecipeCreateForm({ handleClose, updateData, isEdit }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, id: null });
  const { allIngredients, allIngredientsWithPrice, loading, individualRecipeDetails, postLoading } = useSelector(
    (state) => state.ingredients
  );

  const defaultValues = {
    name: '',
    recipeIngredients: [],
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(RecipeSchema),
    mode: 'onChange',
  });

  const { control, handleSubmit, reset, formState, watch } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'recipeIngredients',
    keyName: 'fieldId',
  });

  const AddNewIngredient = () => {
    append({});
  };

  const RemoveIngredient = (el, index) => {
    if (!el?.id) {
      remove(index);
    } else {
      setOpenDeleteModal({ status: true, id: el?.id });
    }
  };

  const recipeSubmitHandler = (data) => {
    console.log('🚀 => recipeSubmitHandler => data=>', data);

    if (isEdit) {
      const finalData = {
        ...data,
        departmentId,
        id: updateData?.id,
        // recipeIngredients: data?.recipeIngredients?.map((el) => ({
        //   ...el,
        //   singleIngredientId: el?.singleIngredientId?.id ? el?.singleIngredientId?.id : el?.singleIngredientId,
        // })),
        recipeIngredients: data?.recipeIngredients?.map((el) => ({
          ...el,
          qtyUsed:
            el?.singleIngredientId?.packSize > 0
              ? el?.singleIngredientId?.unitWeight * el?.qtyUsed * 1000
              : el?.qtyUsed,
          singleIngredientId: el?.singleIngredientId?.id,
        })),
      };
      dispatch(createNewRecipeIngredient({ finalData, handleClose }));
    } else {
      const finalData = {
        ...data,
        departmentId,
        recipeIngredients: data?.recipeIngredients?.map((el) => ({
          ...el,
          qtyUsed:
            el?.singleIngredientId?.packSize > 0
              ? el?.singleIngredientId?.unitWeight * el?.qtyUsed * 1000
              : el?.qtyUsed,
          singleIngredientId: el?.singleIngredientId?.id,
        })),
      };
      dispatch(createNewRecipeIngredient({ finalData, handleClose }));
    }
  };

  const handleDeleteSingleIngredient = () => {
    dispatch(deleteIndividualRecipeIngredient({ departmentId, id: updateData?.id, ingredientId: openDeleteModal?.id }));
    setOpenDeleteModal({ id: null, status: false });
  };

  const ingredientOnChangeHandler = () => {};

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getIngredientListWithPrice({ departmentId }));
  }, [departmentId, dispatch]);

  useEffect(() => {
    if (!updateData) return;
    reset({
      name: updateData?.name,
      recipeIngredients: individualRecipeDetails?.map((item) => ({
        ...item,
        qtyUsed:
          item?.singleIngredient?.packSize > 0
            ? // ? Number(item?.qtyUsed) / Number(item?.singleIngredient?.unitPrice)
              Number(item?.qtyUsed) / Number(item?.singleIngredient?.unitWeight) / 1000
            : Number(item?.qtyUsed),
        singleIngredientId: item?.singleIngredient,
      })),
    });
  }, [updateData, individualRecipeDetails, reset]);

  const isChanged = formState.isDirty;

  const watchRecipeIngredients = useWatch({
    control,
    name: 'recipeIngredients',
  });

  const findTotalCostOfRecipe = () => {
    let totalCost = 0;
    watchRecipeIngredients?.forEach((item) => {
      if (item?.singleIngredientId?.packSize > 0) {
        const singleIngredient = allIngredientsWithPrice?.ingredients?.find(
          (el) => el?.id === item?.singleIngredientId?.id
        );
        const unitWeight = singleIngredient?.unitWeight ?? 0;
        const unitPrice = singleIngredient?.unitPrice ?? 0;
        const packSize = singleIngredient?.packSize ?? 0;
        // const actualQty = qty * unitWeight;
        // const actualCost = (unitPrice / unitWeight) * actualQty / 1000;

        const actualCost = (unitPrice / packSize) * item?.qtyUsed;

        // eslint-disable-next-line no-unused-expressions
        actualCost > 0 ? (totalCost += actualCost) : (totalCost += 0);
      } else {
        const singleIngredient = allIngredientsWithPrice?.ingredients?.find(
          (el) => el?.id === item?.singleIngredientId?.id
        );
        const unitWeight = singleIngredient?.unitWeight ?? 0;
        const unitPrice = singleIngredient?.unitPrice ?? 0;
        const actualCost = (unitPrice / unitWeight) * (item?.qtyUsed / 1000);
        // eslint-disable-next-line no-unused-expressions
        actualCost > 0 ? (totalCost += actualCost) : (totalCost += 0);
      }
    });
    return totalCost.toFixed(3);
  };

  const findTotalWeightOfRecipe = () => {
    let totalWeight = 0;
    watchRecipeIngredients?.forEach((item) => {
      if (item?.singleIngredientId?.packSize > 0) {
        totalWeight += Number(item?.singleIngredientId?.unitWeight ?? 0) * Number(item?.qtyUsed ?? 0) * 1000;
      } else {
        totalWeight += Number(item?.qtyUsed ?? 0);
      }
    });
    return totalWeight;
  };

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(recipeSubmitHandler)}>
        <Stack my="1rem" gap="1rem">
          <RHFTextField name="name" label="Name" />
          <Grid container spacing={2}>
            {loading ? (
              <CustomLoadingScreen height="2vh" />
            ) : (
              <>
                {fields?.map((item, index) => (
                  <RecipeCreateFormSingleIngredient
                    key={index}
                    item={item}
                    index={index}
                    RemoveIngredient={RemoveIngredient}
                  />
                ))}
              </>
            )}
          </Grid>
        </Stack>
        <Stack alignItems="flex-end" marginTop={1}>
          <Button
            variant="contained"
            color="secondary"
            style={{ backgroundColor: '#4caf50' }}
            startIcon={<Iconify icon="material-symbols:add" />}
            onClick={AddNewIngredient}
          >
            Add
          </Button>
        </Stack>
        <Stack alignItems="flex-start" marginTop={1}>
          <Typography color="textSecondary">
            {/* <strong>Ingredients Used: </strong> {fields?.length} <br /> */}
            <strong>Total Cost: </strong> $ {findTotalCostOfRecipe()} <br />
            <strong>Total Weight : </strong> {findTotalWeightOfRecipe().toFixed(2)} g (
            {(findTotalWeightOfRecipe() / 1000).toFixed(2)} kg)
          </Typography>
        </Stack>
        <Stack alignItems="flex-start" marginTop={1}>
          {fields?.length !== 0 && (
            <Button variant="contained" type="submit" color="secondary" disabled={!isChanged}>
              {postLoading ? <CustomLoadingScreen circularWidth={'20px'} color="secondary" /> : 'Submit'}
            </Button>
          )}
        </Stack>
      </FormProvider>
      {openDeleteModal.status && (
        <CustomDeleteDialog
          open={openDeleteModal}
          handleClose={setOpenDeleteModal}
          onAccept={handleDeleteSingleIngredient}
        />
      )}
    </>
  );
}

RecipeCreateForm.propTypes = {
  handleClose: PropTypes.func,
  updateData: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default RecipeCreateForm;
