import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { fNumber } from 'utils/formatNumber';
import { useFormContext, useWatch } from 'react-hook-form';
import React from 'react';
import { useSelector } from 'react-redux';

import DeliveryEntryTableBody from './DeliveryEntryTableBody';

export default function DeliveryEntrysSection() {
  const { onlineSalesPlatform, loading: salesPlatformLoading } = useSelector((state) => state.store);
  const { featureBasedUser, loading: featureBasedUserLoading } = useSelector((state) => state.user);

  const { control, watch } = useFormContext();
  const deliveryWatch = useWatch({
    control,
    name: 'legend',
  });

  const findTotalOfPlatform = (item) => {
    let totalDeliveryCount = 0;

    deliveryWatch?.flat().map((el) => {
      if (Number(el?.onlinePlatformId) === item?.id) {
        totalDeliveryCount += Number(el?.deliveryCount || 0);
      }
      return totalDeliveryCount;
    });
    return totalDeliveryCount;
  };

  const findTotalDeliveryOfForm = () => {
    let totalDeliveryCount = 0;
    deliveryWatch?.flat().map((el) => {
      totalDeliveryCount += Number(el?.deliveryCount || 0);
      return totalDeliveryCount;
    });
    return totalDeliveryCount;
  };

  const inputDeliveryCount = watch('totalDeliveryCount');

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ color: 'text.disabled', mb: 3 }}>
        Delivery Entrys
      </Typography>
      <Stack spacing={2}>
        {!salesPlatformLoading && !featureBasedUserLoading && (
          <TableContainer>
            <Table stickyHeader style={{ minWidth: '700px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Delivery Drivers</TableCell>
                  {onlineSalesPlatform?.onlinePlatforms?.length > 0 &&
                    onlineSalesPlatform?.onlinePlatforms?.map((item, index) => (
                      <TableCell align="center" key={index} style={{ minWidth: '80px' }}>
                        {item?.platformName}
                      </TableCell>
                    ))}
                </TableRow>
              </TableHead>
              {featureBasedUser?.length > 0 && (
                <TableBody>
                  {featureBasedUser?.map((item, index) => (
                    <DeliveryEntryTableBody key={index} item={item} mainIndex={index} />
                  ))}
                  <TableRow hover>
                    <TableCell>Total:</TableCell>
                    {onlineSalesPlatform?.onlinePlatforms?.length > 0 &&
                      onlineSalesPlatform?.onlinePlatforms?.map((item, index) => (
                        <TableCell align="center" key={index} style={{ minWidth: '80px' }}>
                          {findTotalOfPlatform(item)}
                        </TableCell>
                      ))}
                  </TableRow>
                  <TableRow>
                    <TableCell>No. of Deliveries:</TableCell>
                    <TableCell align="center" style={{ minWidth: '80px' }}>
                      {findTotalDeliveryOfForm()}
                    </TableCell>
                    {findTotalDeliveryOfForm() <= inputDeliveryCount && (
                      <TableCell colSpan={4} align="left" style={{ minWidth: '80px', color: 'green' }}>
                        Delivery Variance: {fNumber(inputDeliveryCount - findTotalDeliveryOfForm())}
                      </TableCell>
                    )}
                    {findTotalDeliveryOfForm() > inputDeliveryCount && (
                      <TableCell colSpan={4} align="left" style={{ minWidth: '80px', color: 'red' }}>
                        Delivery Variance: {fNumber(inputDeliveryCount - findTotalDeliveryOfForm())}
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        )}
      </Stack>
    </Box>
  );
}
