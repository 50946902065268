import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import { successToastTime, errorToastTime } from 'config';

// FOOD CATEGORY SECTION
export const getFoodCategory = createAsyncThunk('/menuER/getFoodCategory', async (props) => {
  try {
    const response = await apiURL.get(`/menuER/category/${props?.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createFoodCategory = createAsyncThunk('/menuER/createFoodCategory', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post('/menuER/category', props?.finalData);
    if (response.status === 200) {
      toast.success('Successfully created the food category', { autoClose: successToastTime });
      thunkAPI.dispatch(getFoodCategory({ departmentId: props?.finalData?.departmentId }));
      props?.handleClose();
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateFoodCategory = createAsyncThunk('/menuER/updateFoodCategory', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put('/menuER/category', props?.finalData);
    if (response.status === 200) {
      toast.success('Successfully updated the food category', { autoClose: successToastTime });
      thunkAPI.dispatch(getFoodCategory({ departmentId: props?.finalData?.departmentId }));
      props?.handleClose();
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteFoodCategory = createAsyncThunk('/menuER/deleteFoodCategory', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/menuER/category/${props?.departmentId}/${props?.categoryId}`);
    if (response.status === 200) {
      toast.success('Successfully deleted the food category', { autoClose: successToastTime });
      thunkAPI.dispatch(getFoodCategory({ departmentId: props?.departmentId }));
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

// FOOD MENU SECTION
export const createFoodMenu = createAsyncThunk('/menuER/createFoodMenu', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post('/menuER/menu', props?.formData);
    if (response.status === 200) {
      toast.success('Successfully created the food menu', { autoClose: successToastTime });
      thunkAPI.dispatch(getFoodMenu({ departmentId: props?.departmentId }));
      props?.handleClose();
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getSingleFoodMenu = createAsyncThunk('/menuER/getSingleFoodMenu', async (props) => {
  try {
    const response = await apiURL.get(`/menuER/menu/${props?.departmentId}/${props?.menuId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateFoodMenu = createAsyncThunk('/menuER/updateFoodMenu', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put('/menuER/menu', props?.formData);
    if (response.status === 200) {
      toast.success('Successfully updated the food menu', { autoClose: successToastTime });
      thunkAPI.dispatch(getSingleFoodMenu({ departmentId: props?.departmentId, menuId: props?.menuId }));
      thunkAPI.dispatch(getFoodMenuInternally({ departmentId: props?.departmentId }));
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getFoodMenuInternally = createAsyncThunk('/menuER/getFoodMenuInternally', async (props) => {
  try {
    const response = await apiURL.get(`/menuER/menuSizesPrices/${props?.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteFoodMenu = createAsyncThunk('/menuER/deleteFoodMenu', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/menuER/menu/${props?.departmentId}/${props?.menuId}`);
    if (response.status === 200) {
      toast.success('Successfully deleted the food menu', { autoClose: successToastTime });
      thunkAPI.dispatch(getFoodMenu({ departmentId: props?.departmentId }));
    }
    return response?.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getFoodMenu = createAsyncThunk('/menuER/getFoodMenu', async (props) => {
  try {
    const response = await apiURL.get(`/menuER/menuSizesPrices/${props?.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getFoodMenuSizes = createAsyncThunk('/menuER/getFoodMenuSizes', async (props) => {
  try {
    const response = await apiURL.get(`/menuER/menuSizesPrices/${props?.menuId}/${props?.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateSizePricesOfFoodMenu = createAsyncThunk(
  '/menuER/updateSizePricesOfFoodMenu',
  async (props, thunkAPI) => {
    try {
      const response = await apiURL.post(`/menuER/handleMenuSizePrices/${props?.departmentId}`, props?.finalData);
      if (response.status === 200) {
        toast.success('Successfully updated the size prices for food menu', { autoClose: successToastTime });
        thunkAPI.dispatch(getFoodMenu({ departmentId: props?.departmentId }));
      }
      return response?.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

// FOOD MENU DEFAULT COUNT SECTION
export const getMenusDefaultCount = createAsyncThunk('/menuER/getMenusDefaultCount', async (props) => {
  try {
    const response = await apiURL.get(
      `/menuER/menuSizeGroupOptionGroup/${props?.departmentId}/${props?.sizeGroupId}/${props?.menuId}`
    );
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const saveMenusDefaultCount = createAsyncThunk('/menuER/saveMenusDefaultCount', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post('/menuER/menuSizeGroupOptionGroup', props?.finalData);
    if (response.status === 200) {
      props?.isSuccess(true);
      thunkAPI.dispatch(
        getMenusDefaultCount({
          departmentId: props?.finalData?.departmentId,
          menuId: props?.finalData?.menuId,
          sizeGroupId: props?.sizeGroupId,
        })
      );
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const handleMenuSizeIsPublished = createAsyncThunk(
  '/menuER/handleMenuSizeIsPublished',
  async (props, thunkAPI) => {
    try {
      const response = await apiURL.post('/menuER/handleIsPublished', props?.finalData);
      if (response.status === 200) {
        toast.success('IsPublished updated', { autoClose: successToastTime });
        thunkAPI.dispatch(getFoodMenu({ departmentId: props?.finalData?.departmentId }));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const getFoodMenuOptionGroup = createAsyncThunk('/menuER/getMenuOptionGroup', async (props) => {
  try {
    const response = await apiURL.get(`/menuER/menuOptionGroups/${props?.departmentId}/${props?.sizeGroupId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

// MENU TOPINS SECTION

export const getMenuTopinsOption = createAsyncThunk('/menuER/getMenuTopinsOption', async (props) => {
  try {
    const response = await apiURL.get(`/menuER/menuOption/${props?.departmentId}/${props?.menuId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateMenuTopinsOption = createAsyncThunk('/menuER/updateMenuTopinsOption', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post('/menuER/menuOption', props?.finalData);
    if (response.status === 200) {
      toast.success('Topins updated', { autoClose: successToastTime });
      thunkAPI.dispatch(
        getMenuTopinsOption({ departmentId: props?.finalData?.departmentId, menuId: props?.finalData?.menuId })
      );
    }
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

// MENU TOPINS QTY SECTION
export const getMenuTopinsQty = createAsyncThunk('/menuER/getMenuTopinQty', async (props) => {
  try {
    const response = await apiURL.get(`/menuER/menuSizeOptions/${props?.menuId}/${props?.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateMenuTopinsQty = createAsyncThunk('/menuER/updateMenuTopinQty', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post('/menuER/menuSizeOptions', props?.finalData);
    if (response?.status === 200) {
      toast.success('Topins Qty updated', { autoClose: successToastTime });
      thunkAPI.dispatch(getMenuTopinsQty({ departmentId: props?.finalData?.departmentId, menuId: props?.menuId }));
    }
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

// MENU COMBO SECTION

export const createFoodMenuComboCategory = createAsyncThunk(
  '/menuER/createFoodMenuComboCategory',
  async (props, thunkAPI) => {
    try {
      const response = await apiURL.post('/menuER/menuCombo', props?.finalData);
      if (response.status === 200) {
        if (props?.isEdit) toast.success('Combo Category updated', { autoClose: successToastTime });
        if (!props?.isEdit) toast.success('Combo Category created', { autoClose: successToastTime });
        props?.setOpen({ status: false, data: null });
        thunkAPI.dispatch(
          getFoodMenuCombo({ departmentId: props?.finalData?.departmentId, menuId: props?.finalData?.menuId })
        );
      }
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const getFoodMenuCombo = createAsyncThunk('/menuER/getFoodMenuCombo', async (props) => {
  try {
    const response = await apiURL.get(`/menuER/menuCombos/${props?.departmentId}/${props?.menuId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateFoodMenuCombo = createAsyncThunk('/menuER/updateFoodMenuCombo', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post('/menuER/comboItems', props?.finalData);
    if (response.status === 200) {
      toast.success('Combo updated', { autoClose: successToastTime });
      props?.setOpen(false);
      thunkAPI.dispatch(
        getFoodMenuCombo({ departmentId: props?.finalData?.departmentId, menuId: props?.finalData?.menuId })
      );
    }
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteFoodMenuCombo = createAsyncThunk('/menuER/deleteFoodMenuCombo', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/menuER/deleteComboItem/${props?.departmentId}/${props?.comboItemId}`);
    if (response.status === 200) {
      toast.success('Combo deleted', { autoClose: successToastTime });
      thunkAPI.dispatch(getFoodMenuCombo({ departmentId: props?.departmentId, menuId: props?.menuId }));
    }
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteFoodMenuComboCategory = createAsyncThunk(
  '/menuER/deleteFoodMenuComboCategory',
  async (props, thunkAPI) => {
    try {
      const response = await apiURL.delete(`/menuER/deleteMenuCombo/${props?.departmentId}/${props?.comboId}`);
      if (response.status === 200) {
        toast.success('Combo Category deleted', { autoClose: successToastTime });
        thunkAPI.dispatch(getFoodMenuCombo({ departmentId: props?.departmentId, menuId: props?.menuId }));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const getFoodMenuBaseIngredients = createAsyncThunk('/menuER/getFoodMenuBaseIngredients', async (props) => {
  try {
    const response = await apiURL.get(`/menuER/menuSizeIngredients/${props?.departmentId}/${props?.menuId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateFoodMenuBaseIngredients = createAsyncThunk(
  '/menuER/updateFoodMenuBaseIngredients',
  async (props, thunkAPI) => {
    try {
      const response = await apiURL.post('/menuER/menuSizeIngredients', props?.finalData);
      if (response.status === 200) {
        toast.success('Base Ingredients updated', { autoClose: successToastTime });
        props?.setOpen(false);
        thunkAPI.dispatch(
          getFoodMenuBaseIngredients({ departmentId: props?.finalData?.departmentId, menuId: props?.menuId })
        );
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const deleteFoodMenuBaseIngredients = createAsyncThunk(
  '/menuER/deleteFoodMenuBaseIngredients',
  async (props, thunkAPI) => {
    try {
      const response = await apiURL.delete(
        `/menuER/menuSizeIngredients/${props?.departmentId}/${props?.menuId}/${props?.id}`
      );
      if (response.status === 200) {
        toast.success('Base Ingredients deleted', { autoClose: successToastTime });
        thunkAPI.dispatch(getFoodMenuBaseIngredients({ departmentId: props?.departmentId, menuId: props?.menuId }));
      }
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const getMenuCogsReport = createAsyncThunk('menuER/getMenuCogs', async (props) => {
  try {
    const response = await apiURL.get(`menuER/cogs/${props?.departmentId}/${props?.menuId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateMenuCogsTarget = createAsyncThunk('menuER/postMenuCogs', async (props) => {
  try {
    const response = await apiURL.post('menuER/target', props?.finalData);
    if (response.status === 200) {
      toast.success('Target updated', { autoClose: successToastTime });
      props?.isDirty(false);
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getCategoryCogsReport = createAsyncThunk('menuER/categoryMenuCogs', async (props) => {
  try {
    const response = await apiURL.get(`menuER/cogsSummary/${props?.departmentId}/${props?.categoryId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getFoodMenuRecipeSummary = createAsyncThunk('menuER/getFoodMenuRecipeSummary', async (props) => {
  try {
    const response = await apiURL.get(`menuER/recipeSummary/${props?.departmentId}/${props?.menuId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateFoodMenuSizePrice = createAsyncThunk(
  'menuER/updateMenuSizePricesForEachMenu',
  async (props, thunkAPI) => {
    try {
      const response = await apiURL.post('menuER/handleMenuSizePrice', props?.finalData);
      if (response.status === 200) {
        toast.success('Menu Size Details updated', { autoClose: successToastTime });
        thunkAPI.dispatch(
          getFoodMenuSizePrice({ departmentId: props?.finalData?.departmentId, menuId: props?.finalData?.id })
        );
      }
      return response.data;
    } catch (error) {
      toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const getFoodMenuSizePrice = createAsyncThunk('menuER/getMenuSizePricesForEachMenu', async (props) => {
  try {
    const response = await apiURL.get(`menuER/menuSizesPrices/${props?.menuId}/${props?.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getFoodMenuProcess = createAsyncThunk('menuER/getFoodMenuProcess', async (props) => {
  try {
    const response = await apiURL.get(`menuER/menuProcess/${props?.menuId}/${props?.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createFoodMenuProcess = createAsyncThunk('menuER/createFoodMenuProcess', async (props) => {
  try {
    const response = await apiURL.post('menuER/menuProcess', props?.formData);
    if (response.status === 200) {
      toast.success('Menu Process Created', { autoClose: successToastTime });
      props?.isSuccess(true);
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateSingleMenuProcessField = createAsyncThunk('menuER/updateSingleMenuProcessField', async (props) => {
  try {
    const response = await apiURL.put('menuER/menuProcess', props?.formData);
    if (response.status === 200) {
      toast.success('Menu Process Updated', { autoClose: successToastTime });
      props?.isSuccess(true);
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteSingleMenuProcessField = createAsyncThunk('menuER/deleteSingleMenuProcessField', async (props) => {
  try {
    const response = await apiURL.delete(`menuER/menuProcess/${props?.departmentId}/${props?.id}`);
    if (response.status === 200) {
      toast.success('Menu Process Deleted', { autoClose: successToastTime });
      props?.isSuccess(true);
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteSingleMenuProcessImage = createAsyncThunk('menuER/deleteSingleMenuProcessImage', async (props) => {
  try {
    const response = await apiURL.delete(`menuER/menuProcessImage/${props?.departmentId}/${props?.id}`);
    if (response.status === 200) {
      toast.success('Menu Process Image Deleted', { autoClose: successToastTime });
      props?.isSuccess(true);
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

const menuEngineeringSlice = createSlice({
  name: 'menuEngineering',
  initialState: {
    loading: false,
    postLoading: false,
    currentTab: 0,

    foodCategories: [],
    sizeOptionDetails: [],

    foodMenuLoading: false,
    foodMenus: [],

    defaultCountLoading: false,
    foodMenuDefaultCount: [],

    menuOptionGroupLoading: false,
    menuSpecificOptionGroup: [],

    menuTopinsOption: [],
    menuTopinsOptionLoading: false,

    menuTopinsWithQty: [],
    menuTopinsWithQtyLoading: false,

    menuBaseIngredients: [],
    menuBaseIngredientsLoading: false,

    menuCombo: [],
    menuComboLoading: false,

    foodMenuSizes: [],
    foodMenuSizesLoading: false,

    foodCogsLoading: false,
    foodCogsReport: {},

    categoryCogsLoading: false,
    categoryCogsReport: [],

    foodMenuRecipeSummaryLoading: false,
    foodMenuRecipeSummary: {},

    foodMenuSizePrices: [],
    foodMenuSizePricesLoading: false,

    singleFoodMenu: {},
    singleFoodMenuLoading: false,
    updateFoodMenuLoading: false,

    foodMenuProcess: [],
    foodMenuProcessLoading: false,
  },

  reducers: {
    changeMenuEngineeringCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
  },

  extraReducers: {
    [getFoodMenuInternally.pending]: () => {},
    [getFoodMenuInternally.fulfilled]: (state, action) => {
      state.foodMenus = action.payload;
    },
    [getFoodMenuInternally.rejected]: () => {},

    [getSingleFoodMenu.pending]: (state) => {
      state.singleFoodMenuLoading = true;
      state.singleFoodMenu = {};
    },
    [getSingleFoodMenu.fulfilled]: (state, action) => {
      state.singleFoodMenuLoading = false;
      state.singleFoodMenu = action.payload;
    },
    [getSingleFoodMenu.rejected]: (state) => {
      state.singleFoodMenuLoading = false;
      state.singleFoodMenu = {};
    },
    [updateFoodMenu.pending]: (state) => {
      state.updateFoodMenuLoading = true;
    },
    [updateFoodMenu.fulfilled]: (state) => {
      state.updateFoodMenuLoading = false;
    },
    [updateFoodMenu.rejected]: (state) => {
      state.updateFoodMenuLoading = false;
    },

    [getFoodMenuSizePrice.pending]: (state) => {
      state.foodMenuSizePricesLoading = true;
      state.foodMenuSizePrices = [];
    },
    [getFoodMenuSizePrice.fulfilled]: (state, action) => {
      state.foodMenuSizePricesLoading = false;
      state.foodMenuSizePrices = action.payload;
    },
    [getFoodMenuSizePrice.rejected]: (state) => {
      state.foodMenuSizePricesLoading = false;
      state.foodMenuSizePrices = [];
    },
    [updateFoodMenuSizePrice.pending]: (state) => {
      state.postLoading = true;
    },
    [updateFoodMenuSizePrice.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateFoodMenuSizePrice.rejected]: (state) => {
      state.postLoading = false;
    },
    [getFoodMenuRecipeSummary.pending]: (state) => {
      state.foodMenuRecipeSummaryLoading = true;
      state.foodMenuRecipeSummary = {};
    },
    [getFoodMenuRecipeSummary.fulfilled]: (state, action) => {
      state.foodMenuRecipeSummaryLoading = false;
      state.foodMenuRecipeSummary = action.payload;
    },
    [getFoodMenuRecipeSummary.rejected]: (state) => {
      state.foodMenuRecipeSummaryLoading = false;
      state.foodMenuRecipeSummary = {};
    },

    [getFoodCategory.pending]: (state) => {
      state.loading = true;
      state.foodCategories = [];
    },
    [getFoodCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.foodCategories = action.payload;
    },
    [getFoodCategory.rejected]: (state) => {
      state.loading = false;
      state.foodCategories = [];
    },
    // -----------------------------------
    [createFoodMenu.pending]: (state) => {
      state.updateFoodMenuLoading = true;
    },
    [createFoodMenu.fulfilled]: (state) => {
      state.updateFoodMenuLoading = false;
    },
    [createFoodMenu.rejected]: (state) => {
      state.updateFoodMenuLoading = false;
    },
    // -----------------------------------
    [getFoodMenu.pending]: (state) => {
      state.foodMenuLoading = true;
      state.foodMenus = [];
    },
    [getFoodMenu.fulfilled]: (state, action) => {
      state.foodMenuLoading = false;
      state.foodMenus = action.payload;
    },
    [getFoodMenu.rejected]: (state) => {
      state.foodMenuLoading = false;
      state.foodMenus = [];
    },
    // -----------------------------------
    [getFoodMenuSizes.pending]: (state) => {
      state.foodMenuSizes = [];
      state.foodMenuSizesLoading = true;
    },
    [getFoodMenuSizes.fulfilled]: (state, action) => {
      state.foodMenuSizes = action.payload;
      state.foodMenuSizesLoading = false;
    },
    [getFoodMenuSizes.rejected]: (state) => {
      state.foodMenuSizes = [];
      state.foodMenuSizesLoading = false;
    },
    // -----------------------------------
    [updateSizePricesOfFoodMenu.pending]: (state) => {
      state.postLoading = true;
    },
    [updateSizePricesOfFoodMenu.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateSizePricesOfFoodMenu.rejected]: (state) => {
      state.postLoading = false;
    },
    // -----------------------------------
    [getMenusDefaultCount.pending]: (state) => {
      state.defaultCountLoading = true;
      state.foodMenuDefaultCount = [];
    },
    [getMenusDefaultCount.fulfilled]: (state, action) => {
      state.defaultCountLoading = false;
      state.foodMenuDefaultCount = action.payload;
    },
    [getMenusDefaultCount.rejected]: (state) => {
      state.defaultCountLoading = false;
      state.foodMenuDefaultCount = [];
    },
    // --------------------------------------
    [saveMenusDefaultCount.pending]: (state) => {
      state.postLoading = true;
    },
    [saveMenusDefaultCount.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [saveMenusDefaultCount.rejected]: (state) => {
      state.postLoading = false;
    },
    // --------------------------------------
    [handleMenuSizeIsPublished.pending]: (state) => {
      state.postLoading = true;
    },
    [handleMenuSizeIsPublished.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [handleMenuSizeIsPublished.rejected]: (state) => {
      state.postLoading = false;
    },
    // --------------------------------------
    [getFoodMenuOptionGroup.pending]: (state) => {
      state.menuOptionGroupLoading = true;
      state.menuSpecificOptionGroup = [];
    },
    [getFoodMenuOptionGroup.fulfilled]: (state, action) => {
      state.menuOptionGroupLoading = false;
      state.menuSpecificOptionGroup = action.payload;
    },
    [getFoodMenuOptionGroup.rejected]: (state) => {
      state.menuOptionGroupLoading = false;
      state.menuSpecificOptionGroup = [];
    },
    // --------------------------------------
    [getMenuTopinsOption.pending]: (state) => {
      state.menuTopinsOptionLoading = true;
      state.menuTopinsOption = [];
    },
    [getMenuTopinsOption.fulfilled]: (state, action) => {
      state.menuTopinsOptionLoading = false;
      state.menuTopinsOption = action.payload;
    },
    [getMenuTopinsOption.rejected]: (state) => {
      state.menuTopinsOptionLoading = false;
      state.menuTopinsOption = [];
    },
    // --------------------------------------
    [updateMenuTopinsOption.pending]: (state) => {
      state.postLoading = true;
    },
    [updateMenuTopinsOption.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateMenuTopinsOption.rejected]: (state) => {
      state.postLoading = false;
    },
    // --------------------------------------

    [getMenuTopinsQty.pending]: (state) => {
      state.menuTopinsWithQtyLoading = true;
      state.menuTopinsWithQty = [];
    },
    [getMenuTopinsQty.fulfilled]: (state, action) => {
      state.menuTopinsWithQtyLoading = false;
      state.menuTopinsWithQty = action.payload;
    },
    [getMenuTopinsQty.rejected]: (state) => {
      state.menuTopinsWithQtyLoading = false;
      state.menuTopinsWithQty = [];
    },
    // --------------------------------------
    [updateMenuTopinsQty.pending]: (state) => {
      state.postLoading = true;
    },
    [updateMenuTopinsQty.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateMenuTopinsQty.rejected]: (state) => {
      state.postLoading = false;
    },
    // --------------------------------------
    [getFoodMenuBaseIngredients.pending]: (state) => {
      state.menuBaseIngredientsLoading = true;
      state.menuBaseIngredients = [];
    },
    [getFoodMenuBaseIngredients.fulfilled]: (state, action) => {
      state.menuBaseIngredientsLoading = false;
      state.menuBaseIngredients = action.payload;
    },
    [getFoodMenuBaseIngredients.rejected]: (state) => {
      state.menuBaseIngredientsLoading = false;
      state.menuBaseIngredients = [];
    },
    // --------------------------------------
    [getFoodMenuCombo.pending]: (state) => {
      state.menuComboLoading = true;
      state.menuCombo = [];
    },
    [getFoodMenuCombo.fulfilled]: (state, action) => {
      state.menuComboLoading = false;
      state.menuCombo = action.payload;
    },
    [getFoodMenuCombo.rejected]: (state) => {
      state.menuComboLoading = false;
      state.menuCombo = [];
    },

    // --------------------------------------
    [updateFoodMenuCombo.pending]: (state) => {
      state.postLoading = true;
    },
    [updateFoodMenuCombo.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateFoodMenuCombo.rejected]: (state) => {
      state.postLoading = false;
    },
    // --------------------------------------
    [updateFoodMenuBaseIngredients.pending]: (state) => {
      state.postLoading = true;
    },
    [updateFoodMenuBaseIngredients.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateFoodMenuBaseIngredients.rejected]: (state) => {
      state.postLoading = false;
    },

    // --------------------------------------
    [getMenuCogsReport.pending]: (state) => {
      state.foodCogsLoading = true;
      state.foodCogsReport = {};
    },
    [getMenuCogsReport.fulfilled]: (state, action) => {
      state.foodCogsLoading = false;
      state.foodCogsReport = action.payload;
    },
    [getMenuCogsReport.rejected]: (state) => {
      state.foodCogsLoading = false;
    },

    //   -------------------------------------
    [updateMenuCogsTarget.pending]: (state) => {
      state.postLoading = true;
    },
    [updateMenuCogsTarget.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateMenuCogsTarget.rejected]: (state) => {
      state.postLoading = false;
    },

    //   -----------------------------------------------
    [getCategoryCogsReport.pending]: (state) => {
      state.categoryCogsLoading = true;
      state.categoryCogsReport = [];
    },
    [getCategoryCogsReport.fulfilled]: (state, action) => {
      state.categoryCogsLoading = false;
      state.categoryCogsReport = action.payload;
    },
    [getCategoryCogsReport.rejected]: (state) => {
      state.categoryCogsLoading = false;
      state.categoryCogsReport = [];
    },
    // -----------------------------------------------

    [getFoodMenuProcess.pending]: (state) => {
      state.foodMenuProcessLoading = true;
      state.foodMenuProcess = [];
    },
    [getFoodMenuProcess.fulfilled]: (state, action) => {
      state.foodMenuProcessLoading = false;
      state.foodMenuProcess = action.payload;
    },
    [getFoodMenuProcess.rejected]: (state) => {
      state.foodMenuProcessLoading = false;
      state.foodMenuProcess = [];
    },
    // -----------------------------------------------

    [createFoodMenuProcess.pending]: (state) => {
      state.postLoading = true;
    },
    [createFoodMenuProcess.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [createFoodMenuProcess.rejected]: (state) => {
      state.postLoading = false;
    },
  },
});

export const { changeMenuEngineeringCurrentTab } = menuEngineeringSlice.actions;

export default menuEngineeringSlice.reducer;
