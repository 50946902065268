import { Card } from '@mui/material';
import React from 'react';
import InsightReportBodyChart from 'sections/@dashboard/report/insights/InsightReportBodyChart';
import InsightReportFilter from 'sections/@dashboard/report/insights/InsightReportFilter';

function InsightReportBody() {
  return (
    <Card>
      <InsightReportFilter />
      <InsightReportBodyChart />
    </Card>
  );
}

export default InsightReportBody;
