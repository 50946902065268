import { Button, Grid } from '@mui/material';
import Iconify from 'components/Iconify';
import { CSS } from '@dnd-kit/utilities';
import { RHFTextField } from 'components/hook-form';
import React, { useState } from 'react';
import RHFText from 'sections/@dashboard/user/viewDetails/RHFText';
import { useSortable } from '@dnd-kit/sortable';

function SingleSizeField({ el, index, RemoveSizeTitle }) {
  const [isEdit, setIsEdit] = useState(!el?.id);

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: el.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    margin: `10px 0px 10px 0px`,
    borderBottom: '0.5px solid rgba(145, 158, 171, 0.24)',
    backgroundColor: isDragging ? '#cccccc' : '',
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <Grid item sx={12} style={style} ref={setNodeRef}>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div {...listeners} {...attributes} style={{ marginTop: '10px', marginRight: '5px', cursor: 'move' }}>
          <Iconify icon="system-uicons:drag" height={20} width={20} />
        </div>
        {isEdit ? (
          <RHFTextField name={`sizes.${index}.name`} label="Size Name*" size="small" />
        ) : (
          <RHFText
            name={`sizes.${index}.name`}
            label="Size Name*"
            size="small"
            disabled
            style={{ marginRight: '30px' }}
          />
        )}

        <Button
          color="primary"
          variant={`${isEdit ? 'contained' : 'text'}`}
          onClick={() => setIsEdit(!isEdit)}
          style={{
            margin: '0px',
            padding: '0px',
            height: '30px',
            width: '10px',
            marginLeft: '10px',
            marginTop: '5px',
          }}
        >
          <Iconify icon="uil:edit" height={20} width={20} />
        </Button>
        <Button
          color="error"
          variant="text"
          onClick={() => RemoveSizeTitle(el, index)}
          style={{
            margin: '0px',
            padding: '0px',
            height: '30px',
            width: '10px',
            marginTop: '5px',
          }}
        >
          <Iconify icon="solar:trash-bin-minimalistic-2-bold" height={20} width={20} />
        </Button>
      </div>
    </Grid>
  );
}

export default SingleSizeField;
