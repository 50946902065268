import { Button, TableCell } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { useDispatch, useSelector } from 'react-redux';
import { updateMenuCogsTarget } from 'redux/slices/menuEngineeringSlice';
import FoodCategoryIndividualSizeTarget from './FoodCategoryIndividualSizeTarget';
import FoodCategoryIndividualSizeTargetPercent from './FoodCategoryIndividualSizeTargetPercent';

function FoodCategoryCogsReportIndividualItemForm() {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const { control, handleSubmit, formState } = useFormContext({ mode: 'onChange' });
  const { postLoading } = useSelector((state) => state.menuEngineering);

  const watchSingleItem = useWatch({ control, name: 'menuSizes' });

  const handleSingleItemChangeSubmit = (data) => {
    const finalData = {
      id: data?.id,
      departmentId,
      menuSizes: [
        {
          id: data?.menuSizes?.id,
          target: data?.menuSizes?.target,
          targetPercent: data?.menuSizes?.targetPercent,
        },
      ],
    };
    dispatch(updateMenuCogsTarget({ finalData }));
  };
  const { isDirty } = formState;

  return (
    <>
      <TableCell align="left">{watchSingleItem?.sizeName}</TableCell>
      <TableCell align="center">$ {Number(watchSingleItem?.sellingPrice).toFixed(2)}</TableCell>
      <TableCell align="center">$ {Number(watchSingleItem?.cogs).toFixed(2)}</TableCell>
      <TableCell align="center"> {Number(watchSingleItem?.cogsPercent).toFixed(2)} %</TableCell>

      <TableCell align="center">$ {Number(watchSingleItem?.grossProfit).toFixed(2)}</TableCell>
      <TableCell align="center"> {Number(watchSingleItem?.grossProfitPercent).toFixed(2)} %</TableCell>
      <TableCell align="center">
        <FoodCategoryIndividualSizeTarget />
      </TableCell>
      <TableCell align="center">
        <FoodCategoryIndividualSizeTargetPercent />
      </TableCell>
      <TableCell align="center">
        $ {Number(watchSingleItem?.targetGp ? watchSingleItem?.targetGp : 0).toFixed(2)}
      </TableCell>
      <TableCell align="center">
        {Number(watchSingleItem?.targetGpPercent ? watchSingleItem?.targetGpPercent : 0).toFixed(2)} %
      </TableCell>
      <TableCell align="center">$ {Number(watchSingleItem?.difference).toFixed(2)}</TableCell>
      <TableCell align="center"> {Number(watchSingleItem?.differencePercent).toFixed(2)} %</TableCell>

      <TableCell align="right">
        <Button
          variant="contained"
          disabled={postLoading}
          color="primary"
          size="small"
          onClick={handleSubmit(handleSingleItemChangeSubmit)}
        >
          Save
        </Button>
      </TableCell>
    </>
  );
}

export default FoodCategoryCogsReportIndividualItemForm;
