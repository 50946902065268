import { Box, Card, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeMenuERSizeOptionCurrentTab } from 'redux/slices/menuERSizeAndOptionSlice';
import MenuSizeIndex from './sizeGroup/MenuSizeIndex';
import MenuOptionIndex from './optionGroup/MenuOptionIndex';
import MenuSizeOptionIndex from './sizeGroupOptionGroup/MenuSizeOptionIndex';
// import FoodMenuSizeIndex from './sizeGroup';

function MenuEngineeringBody() {
  const { currentTab } = useSelector((state) => state.menuERSizeAndOption);

  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    dispatch(changeMenuERSizeOptionCurrentTab(newValue));
  };
  return (
    <Card>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingLeft: '0.7rem' }}>
        <Tabs value={currentTab} onChange={handleChange} aria-label="Stock and Order Tabs">
          <Tab label="Size Group" id="0" />
          <Tab label="Option Group" id="1" />
          <Tab label="Size Option Grouping" id="2" />
          {/* <Tab label="size dnd" id="3" /> */}
        </Tabs>
      </Box>

      {currentTab === 0 && <MenuSizeIndex />}

      {currentTab === 1 && <MenuOptionIndex />}

      {currentTab === 2 && <MenuSizeOptionIndex />}

      {/* {currentTab === 3 && <FoodMenuSizeIndex />} */}
    </Card>
  );
}

export default MenuEngineeringBody;
