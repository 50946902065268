import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Iconify from 'components/Iconify';
import Page from 'components/Page';
import Scrollbar from 'components/Scrollbar';
import { TableHeadCustom, TableNoData } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ViewMenuSizeDetails from 'sections/@dashboard/menuEngineering/menuSize/ViewMenuSizeDetails';
import { deleteMenuSizeGroup, getMenuSizeGroup } from 'redux/slices/menuERSizeAndOptionSlice';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import MenuSizeEdit from './MenuSizeEdit';

const TABLE_HEAD = [
  { id: 1, label: 'S.N', align: 'left' },
  { id: 2, label: 'Name', align: 'left' },
  { id: 3, label: 'Actions', align: 'center' },
];

function MenuSizeIndex() {
  const [openView, setOpenView] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, id: null });

  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const { menuSizeGroup, loading } = useSelector((state) => state.menuERSizeAndOption);

  const HandleDeleteSizeGroup = () => {
    dispatch(deleteMenuSizeGroup({ departmentId, sizeGroupId: openDeleteModal?.id }));
    setOpenDeleteModal({ id: null, status: false });
  };

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getMenuSizeGroup({ departmentId }));
  }, [departmentId, dispatch]);
  return (
    <>
      <>
        <Page title="Size Group">
          <Scrollbar>
            <TableContainer>
              <Table stickyHeader size="small">
                <TableHeadCustom headLabel={TABLE_HEAD} />
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <CustomLoadingScreen height="30vh" />
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {menuSizeGroup?.length <= 0 ? (
                        <TableNoData isNotFound={menuSizeGroup?.length <= 0} />
                      ) : (
                        <>
                          {menuSizeGroup?.map((row, index) => (
                            <TableRow key={index} hover>
                              <TableCell>{index + 1}</TableCell>
                              <TableCell>{row?.name}</TableCell>
                              <TableCell align="center">
                                <Button
                                  style={{ color: '#4caf50', marginLeft: '10px', marginRight: '10px' }}
                                  variant="text"
                                  startIcon={<Iconify icon="material-symbols:edit-outline" />}
                                  onClick={() => {
                                    setOpenEdit(true);
                                    setSelectedRow(row);
                                  }}
                                >
                                  Edit
                                </Button>
                                <Button
                                  variant="text"
                                  color="error"
                                  startIcon={<Iconify icon="mdi:trash-can" />}
                                  onClick={() => setOpenDeleteModal({ status: true, id: row?.id })}
                                >
                                  Delete
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Page>
      </>
      {/* {openView && <ViewMenuSizeDetails openView={openView} setOpenView={setOpenView} selectedRow={selectedRow} />} */}
      {openDeleteModal.status && (
        <CustomDeleteDialog open={openDeleteModal} handleClose={setOpenDeleteModal} onAccept={HandleDeleteSizeGroup} />
      )}
      {openEdit && <MenuSizeEdit openEdit={openEdit} setOpenEdit={setOpenEdit} selectedRow={selectedRow} />}
    </>
  );
}

export default MenuSizeIndex;
