import { useNavigate, useParams } from 'react-router-dom';
// @mui
import useSettings from 'hooks/useSettings';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';
import InvoiceNewEditForm from 'sections/@dashboard/invoice/new-edit-form';
import { useSelector, useDispatch } from 'redux/store';
import { useEffect, useState } from 'react';
import { getInvoiceDetails, isopenInvoiceModal, updateInvoices } from 'redux/slices/InvoiceSlice';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import { convertDatetoString } from 'utils/customDateAndTimeFormat';

// routes

// ----------------------------------------------------------------------

export default function InvoiceEdit() {
  const [updatedInvoices, setIUpdatedInvoices] = useState({});
  const selectedDepartment = useGetDepartmentId();
  const { invoiceDetails, loading } = useSelector((state) => state.invoice);
  const { storeSuppliers } = useSelector((state) => state.store);

  const dispatch = useDispatch();
  const { id } = useParams();
  const Navigate = useNavigate();

  useEffect(() => {
    if (!selectedDepartment) return;
    dispatch(getInvoiceDetails({ departmentId: selectedDepartment, invoiceId: id }));
  }, [selectedDepartment, dispatch, id]);

  useEffect(() => {
    if (!Object.keys(invoiceDetails).length) return;

    if (!invoiceDetails?.returnInvoiceItems) {
      setIUpdatedInvoices({ ...invoiceDetails, invoiceItems: invoiceDetails?.invoiceItems || [] });
      return;
    }
    const returnInvoice = invoiceDetails.returnInvoiceItems;
    // arranging invoice items and return invoice items
    const suppliers = storeSuppliers?.find((obj) => obj.id === invoiceDetails?.supplierId);
    const dueDates = moment(invoiceDetails?.invoiceDate).add(suppliers?.dueDays, 'days').toDate();

    const mappingData = {
      ...invoiceDetails,
      totalGst: invoiceDetails?.totalGst === 0 ? '0' : invoiceDetails?.totalGst,
      invoiceItems:
        invoiceDetails?.invoiceItems?.map((obj) => {
          const isReturnInclude = returnInvoice?.find((el) => el?.externalUID === obj?.externalUID);
          if (isReturnInclude)
            return { ...obj, returnQuantity: { suppliedQty: isReturnInclude?.suppliedQty, id: isReturnInclude?.id } };
          return { ...obj };
        }) || [],
    };
    setIUpdatedInvoices(mappingData);
  }, [invoiceDetails, storeSuppliers]);

  const redirectAfterSuccess = () => {
    dispatch(isopenInvoiceModal());
    Navigate(PATH_DASHBOARD.invoice.list);
  };

  const updateInvoiceHandler = (data) => {
    const finalData = {
      ...data,
      departmentId: selectedDepartment,
      id: Number(id),
      status: 'APPROVED',
      invoiceDate: convertDatetoString(data?.invoiceDate),
      dueDate: convertDatetoString(data?.dueDate),
      invoiceItems:
        data?.invoiceItems?.reduce((acc, current) => {
          if (!current?.returnQuantity)
            return [
              ...acc,
              {
                ...current,
                status: 'APPROVED',
                amount: (
                  Number(current.suppliedQty) * Number(current.unitPrice) +
                  Number(current.gst) +
                  Number(current.levy)
                ).toFixed(2),
                packSize: current?.uom === 'EACH' ? current?.packSize : null,
              },
            ];
          return [
            ...acc,
            {
              ...current,
              status: 'APPROVED',
              amount: (
                Number(current.suppliedQty) * Number(current.unitPrice) +
                Number(current.gst) +
                Number(current.levy)
              ).toFixed(2),
              packSize: current?.uom === 'EACH' ? current?.packSize : null,
            },
            {
              ...current,
              ...current.returnQuantity,
              id: current?.returnQuantity?.id || null,
              status: 'RETURN',
              // suppliedQty: Number(current.returnQuantity.suppliedQty),
              amount: (
                Number(current.returnQuantity.suppliedQty) * Number(current.unitPrice) +
                Number(current.gst) +
                Number(current.levy)
              ).toFixed(2),
              packSize: current?.uom === 'EACH' ? current?.packSize : null,
            },
          ];
        }, []) || [],
    };

    dispatch(updateInvoices({ finalData, redirectAfterSuccess }));
  };

  return (
    <Page title="Invoices: Edit">
      {/* <Container maxWidth={themeStretch ? false : 'lg'}> */}
      <HeaderBreadcrumbs
        heading="Edit invoice"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Invoices', href: PATH_DASHBOARD.invoice.list },
          { name: `INV-${invoiceDetails?.invoiceNumber}` || '' },
        ]}
      />
      {loading ? (
        <CustomLoadingScreen width="100%" height="80vh" />
      ) : (
        <InvoiceNewEditForm isEdit currentInvoice={updatedInvoices} onSubmit={updateInvoiceHandler} />
      )}
      {/* </Container> */}
    </Page>
  );
}
