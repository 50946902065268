import { useEffect, useState } from 'react';
// @mui
import { Box, Button, Container } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

// routes
import { NewUserSchema, validatePaperworkSchema, validatePayOnlySchema } from 'sections/@dashboard/user/UserYupSchema';
import { FormProvider } from 'components/hook-form';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import { convertDatetoString } from 'utils/customDateAndTimeFormat';
import { useDispatch, useSelector } from 'react-redux';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import moment from 'moment';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import StaffEditStepper from 'sections/@dashboard/staffEdit/StaffEditStepper';
import { approvePendingRequest, userInfoRequestDetails } from 'redux/slices/userSlice';
import { DialogAnimate } from 'components/animate';
import { getDepartOfStore } from 'redux/slices/storeSlice';

// ----------------------------------------------------------------------

export default function StaffEditIndex() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [alreadyExistPhone, setAlreadyExistPhone] = useState(false);
  const navigate = useNavigate();
  const { detailsLoading, userInfoRequestDetail } = useSelector((state) => state.user);
  const validateDateOfBirth = moment().subtract(13, 'years').toDate();

  const defaultValues = {
    firstName: '',
    middleName: '',
    lastName: '',

    email: '',
    phone: '',
    dob: validateDateOfBirth,
    gender: '',

    isOwner: false,
    bsb: '',
    accountNo: '',
    isVerified: false,
    suburb: '',
    address: '',
    country: '',
    state: '',

    zipcode: '',
    emergencyContactName: '',
    emergencyContactNo: null,

    tfnStatus: '',
    tfnNo: '',
    bankName: '',

    isInternationalStudent: false,
    passport: null,
    passportURL: null,
    visa: null,
    visaURL: null,
    workingRights: null,
    workingRightsURL: null,

    isDeliveryDriver: false,
    drivingLicense: null,
    drivingLicenseURL: null,
    insurance: null,
    insuranceURL: null,
    rezoPaperWork: null,
    rezoPaperWorkURL: null,
    removedPaperworks: [],

    acceptTermsAndCondition: false,
    declarationForm: {
      acceptTermsAndConditions: false,
      certifyAccuracy: false,
      certifyBySigning: false,
      signature: null,
    },
  };

  const newSchema = NewUserSchema.concat(validatePayOnlySchema).concat(validatePaperworkSchema);

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(newSchema),
    mode: 'onChange',
  });
  const { reset, handleSubmit } = methods;

  const manageAlreadyExist = (status) => {
    if (status === 'phone') {
      setAlreadyExistPhone(true);
    }
  };

  const ApprovePendingUserHandler = (data, { updateForces = false }) => {
    const formData = new FormData();

    if (data?.profileImage) formData.append('profileImage', data?.profileImage);
    if (data?.visa) formData.append('visa', data?.visa);
    if (data?.workingRights) formData.append('workingRights', data?.workingRights);
    if (data?.drivingLicense) formData.append('drivingLicense', data?.drivingLicense);
    if (data?.insurance) formData.append('insurance', data?.insurance);
    if (data?.rezoPaperWork) formData.append('rezoPaperWork', data?.rezoPaperWork);
    if (data?.passport) formData.append('passport', data?.passport);
    if (data?.sign) formData.append('sign', data?.sign, 'sign.svg');

    const finalData = {
      ...data,
      dob: convertDatetoString(data?.dob),
      departmentId: data?.departmentId?.id || null,
      phone: data?.phone?.replace(/ /g, ''),
      tfnNo: data?.tfnNo?.replace(/ /g, ''),
      emergencyContactNo: data?.emergencyContactNo?.replace(/ /g, '') || null,
      forceUpdate: updateForces,
    };

    formData.append('user', new Blob([JSON.stringify({ ...finalData })], { type: 'application/json' }));

    dispatch(approvePendingRequest({ data: formData, navigate, manageAlreadyExist }));
  };

  const onAcceptExistingPhoneNumber = (data) => ApprovePendingUserHandler(data, { updateForces: true });

  useEffect(() => {
    dispatch(userInfoRequestDetails({ id }));
    dispatch(getDepartOfStore());
  }, [dispatch, id]);

  useEffect(() => {
    if (!Object.keys(userInfoRequestDetail).length) return;
    const phoneNumber = userInfoRequestDetail?.phone;
    let emergencyContactNo = userInfoRequestDetail?.emergencyContactNo || '';
    let tfnNo = userInfoRequestDetail?.tfnNo || '';

    if (tfnNo) {
      tfnNo = `${tfnNo?.substring(0, 3)} ${tfnNo?.substring(0, 3)} ${tfnNo?.substring(0, 3)}`;
    }
    if (emergencyContactNo) {
      emergencyContactNo = `${emergencyContactNo?.substring(0, 4)} ${emergencyContactNo?.substring(
        0,
        3
      )} ${emergencyContactNo?.substring(0, 3)}`;
    }
    const checkPhoneNumber = `${phoneNumber.substring(0, 4)} ${phoneNumber.substring(4, 7)} ${phoneNumber.substring(
      7,
      10
    )}`;
    reset({
      ...userInfoRequestDetail,
      phone: checkPhoneNumber,
      tfnNo,
      emergencyContactNo,
      passport: null,
      visa: null,
      workingRights: null,
      drivingLicense: null,
      insurance: null,
      regoPaperWork: null,
      removedPaperworks: [],
      acceptTermsAndCondition: true,
    });
  }, [userInfoRequestDetail, reset]);

  return (
    <>
      <Page title="Staff: Approve Details">
        <Container sx={{ marginBlock: '6rem' }}>
          <HeaderBreadcrumbs heading="Approve Staff Details" links={[{ name: 'Dashboard' }]} />
          {detailsLoading ? (
            <CustomLoadingScreen height={'30vh'} />
          ) : (
            <FormProvider methods={methods}>
              <StaffEditStepper onSubmit={ApprovePendingUserHandler} isEdit />
            </FormProvider>
          )}
        </Container>
      </Page>

      {alreadyExistPhone && (
        <DialogAnimate open={alreadyExistPhone} onClose={() => setAlreadyExistPhone(false)}>
          <Box className="deleteObjectDialogBox">
            <h2 style={{ padding: '1rem' }}>User with Phone Number Already Exist. Do you want to Override Details ?</h2>
            <Box className="deleteObjectDialogBoxBody">
              <Button color="error" onClick={handleSubmit(onAcceptExistingPhoneNumber)}>
                Yes
              </Button>
              <Button color="secondary" variant="contained" onClick={() => setAlreadyExistPhone(false)}>
                No
              </Button>
            </Box>
          </Box>
        </DialogAnimate>
      )}
    </>
  );
}
