import { Accordion, AccordionDetails, AccordionSummary, Button, Icon, Stack } from '@mui/material';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { updateMenuTopinsOption } from 'redux/slices/menuEngineeringSlice';
import FoodMenuTopinsCheckbox from './FoodMenuTopinsCheckbox';

function removeDuplicates(array) {
  return [...new Set(array)];
}

function FoodMenuTopinsBody({ menu }) {
  const departmentId = useGetDepartmentId();
  const { control, handleSubmit, formState } = useFormContext();
  const { postLoading } = useSelector((state) => state.menuEngineering);
  const dispatch = useDispatch();

  const { fields } = useFieldArray({
    control,
    name: 'menuSpecificOptionGroup',
  });

  const FoodMenuTopinsSubmitHandler = (data) => {
    const finalData = {
      menuId: menu?.id,
      departmentId,
      addedOptionIds: removeDuplicates(data?.addedOptionIds),
      removedOptionIds: removeDuplicates(data?.removedOptionIds),
    };

    dispatch(updateMenuTopinsOption({ finalData }));
  };

  const isChanged = formState.isDirty;

  return (
    <>
      {fields?.map((item, mainIndex) => (
        <Accordion
          sx={{ paddingBlock: '0.1rem', borderBottom: '0.5px solid rgba(145, 158, 171, 0.24)' }}
          defaultExpanded
          key={mainIndex}
        >
          <AccordionSummary
            expandIcon={<Icon icon="ic:outline-expand-more" width="25px" />}
            aria-controls="Menu with Categories"
            id={item?.id}
          >
            <Stack direction={'row'} justifyContent={'space-between'} width="100%">
              <Stack direction={'row'} alignItems={'center'} spacing={1}>
                <Icon icon="tabler:category" width={'25px'} />
                <Stack>
                  <p style={{ fontWeight: '600' }}>{item?.optionGroupName}</p>
                  {/* <p style={{ color: '#637381' }}>{item?.options?.length} Items</p> */}
                </Stack>
              </Stack>
            </Stack>
          </AccordionSummary>
          <AccordionDetails sx={{ background: 'rgba(145, 158, 171, 0.12)' }}>
            {item?.options?.map((option, index) => (
              <React.Fragment key={index}>
                <FoodMenuTopinsCheckbox key={index} option={option} index={index} />
              </React.Fragment>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
      <Stack alignItems={'flex-end'}>
        <Button
          variant="contained"
          disabled={postLoading || !isChanged}
          onClick={handleSubmit(FoodMenuTopinsSubmitHandler)}
        >
          {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : 'Save'}
        </Button>
      </Stack>
    </>
  );
}

FoodMenuTopinsBody.propTypes = {
  menu: PropTypes.object,
};

export default FoodMenuTopinsBody;
