import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import React from 'react';
import { PATH_DASHBOARD } from 'routes/paths';

export default function ZonesHeader() {
  return (
    <HeaderBreadcrumbs
      heading="Zones Details"
      links={[
        { name: 'Dashboard', href: PATH_DASHBOARD.root },
        { name: 'Store' },
        // { name: 'Zones Details' }
      ]}
    />
  );
}
