import { Icon } from '@iconify/react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Divider, Stack } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import React from 'react';
import { useSelector } from 'react-redux';
import FoodRecipeMenus from 'sections/@dashboard/foodMenuAndRecipe/FoodRecipe/FoodRecipeMenus';

function FoodRecipeBody() {
  const { loading, allMenuWithCategories } = useSelector((state) => state.foodMenus);

  const checkSizeNameLength = (name) => {
    if (name?.length <= 10) {
      return name;
    }
    const changeName = `${name?.slice(0, 10)}..`;
    return changeName;
  };

  return (
    <Stack>
      {loading ? (
        <CustomLoadingScreen height="70vh" />
      ) : (
        <Box>
          {allMenuWithCategories?.map((categories, mainIndex) => (
            <Accordion
              sx={{ paddingBlock: '0.1rem', borderBottom: '0.5px solid rgba(145, 158, 171, 0.24)' }}
              defaultExpanded
              key={mainIndex}
            >
              <AccordionSummary
                expandIcon={<Icon icon="ic:outline-expand-more" width="25px" />}
                aria-controls="Menu with Categories"
                id={categories?.id}
              >
                <Stack direction={'row'} justifyContent={'space-between'} width="100%">
                  <Stack direction={'row'} alignItems={'center'} spacing={1}>
                    <Icon icon="tabler:category" width={'25px'} />
                    <Stack>
                      <p style={{ fontWeight: '600' }}>{categories?.name}</p>
                      <p style={{ color: '#637381' }}>{categories?.menus?.length} Items</p>
                    </Stack>
                  </Stack>
                  <Stack className={'manageMenuSizeList'}>
                    {categories?.sizes?.map((categorySize) => (
                      <Box key={categorySize?.id} className="manageItemsOfMenuSizeList manageItemsOfMenuSizeListColor">
                        {checkSizeNameLength(categorySize?.name)}
                      </Box>
                    ))}
                  </Stack>
                </Stack>
              </AccordionSummary>

              <AccordionDetails sx={{ background: 'rgba(145, 158, 171, 0.12)' }}>
                <Box className="foodPortionRecipeBodyContainer">
                  {categories?.menus?.map((menu, index) => (
                    <FoodRecipeMenus key={index} menu={menu} />
                  ))}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      )}
    </Stack>
  );
}

export default FoodRecipeBody;
