import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiURL from 'api';
import { errorToastTime } from 'config';
import { toast } from 'react-toastify';

export const getQuickSummaryClockIn = createAsyncThunk('report/getQuickSummaryClockIn', async (props) => {
  try {
    const response = await apiURL.post('/report/getQuickSummary', props);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, errorToastTime);
    throw error;
  }
});

export const getQuickSummaryDifference = createAsyncThunk('report/getQuickSummaryDifference', async (props) => {
  try {
    const response = await apiURL.post('/report/getQuickSummaryDifference', props);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, errorToastTime);
    throw error;
  }
});

const QuickSummarySlice = createSlice({
  name: 'quickSummary',
  initialState: {
    clockInDetailsLoading: false,
    clockInSummaryReport: {},

    differenceDetailsLoading: false,
    differenceSummaryReport: {},

    quickSummaryWeek: null,
  },
  reducers: {
    setQuickSummaryWeek(state, action) {
      state.quickSummaryWeek = action.payload;
    },
  },
  extraReducers: {
    [getQuickSummaryClockIn.pending]: (state) => {
      state.clockInDetailsLoading = true;
      state.clockInSummaryReport = {};
    },
    [getQuickSummaryClockIn.fulfilled]: (state, action) => {
      state.clockInDetailsLoading = false;
      state.clockInSummaryReport = action.payload;
    },
    [getQuickSummaryClockIn.rejected]: (state) => {
      state.clockInDetailsLoading = false;
      state.clockInSummaryReport = {};
    },
    // --------------------------------------------------
    [getQuickSummaryDifference.pending]: (state) => {
      state.differenceDetailsLoading = true;
      state.differenceSummaryReport = {};
    },
    [getQuickSummaryDifference.fulfilled]: (state, action) => {
      state.differenceDetailsLoading = false;
      state.differenceSummaryReport = action.payload;
    },
    [getQuickSummaryDifference.rejected]: (state) => {
      state.differenceDetailsLoading = false;
      state.differenceSummaryReport = {};
    },
  },
});

export const { setQuickSummaryWeek } = QuickSummarySlice.actions;
export default QuickSummarySlice.reducer;
