import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Iconify from 'components/Iconify';
import Page from 'components/Page';
import Scrollbar from 'components/Scrollbar';
import { TableHeadCustom } from 'components/table';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSingleSuburb, getSuburb } from 'redux/slices/posConfigSlice';
import ManageSuburbDetails from './ManageSuburbDetails';
import EditSuburb from './EditSuburb';

const TABLE_HEAD = [
  { id: 1, label: 'S.N', align: 'left' },
  { id: 2, label: 'Name', align: 'left' },
  { id: 3, label: 'Postal Code', align: 'center' },
  { id: 4, label: 'Delivery Zone', align: 'left' },
  { id: 5, label: 'Delivery Price', align: 'center' },
  { id: 6, label: 'Actions', align: 'center' },
];

function PosSuburbIndex() {
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, id: null });
  const [openManage, setOpenManage] = useState({ status: false, id: null });
  const dispatch = useDispatch();
  const { suburbLoading, suburbsData } = useSelector((state) => state.posConfig);

  useEffect(() => {
    dispatch(getSuburb());
  }, [dispatch]);

  const HandleDeleteSuburb = () => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        setOpenDeleteModal({ id: null, status: false });
      }
    };
    dispatch(deleteSingleSuburb({ id: openDeleteModal?.id, isSuccess: isSuccessful }));
  };

  const handleOpenEdit = (row) => {
    const data = {
      ...row,
      deliveryZoneId: row?.deliveryZone,
      cityId: row?.city,
    };
    setSelectedRow(data);
    setOpenEdit(true);
  };

  return (
    <Page title="POS: Suburb">
      <>
        <Scrollbar>
          <TableContainer>
            <Table stickyHeader size="small">
              <TableHeadCustom headLabel={TABLE_HEAD} />
              <TableBody>
                {suburbLoading ? (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <CustomLoadingScreen height="30vh" />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {suburbsData?.map((row, index) => (
                      <TableRow key={row.id} hover>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell align="center">{row?.postalCode}</TableCell>
                        <TableCell>{row?.deliveryZoneName}</TableCell>
                        <TableCell align="center">{row?.deliveryZonePrice}</TableCell>
                        <TableCell align="center">
                          <Button
                            variant="text"
                            color="primary"
                            startIcon={<Iconify icon="mdi:eye" />}
                            onClick={() => setOpenManage({ status: true, id: row?.id })}
                          >
                            manage
                          </Button>
                          <Button
                            style={{ color: '#4caf50', marginLeft: '10px', marginRight: '10px' }}
                            variant="text"
                            startIcon={<Iconify icon="material-symbols:edit-outline" />}
                            onClick={() => {
                              handleOpenEdit(row);
                              // setOpenEdit(true);
                              // setSelectedRow(row);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="text"
                            color="error"
                            startIcon={<Iconify icon="mdi:trash-can" />}
                            onClick={() => setOpenDeleteModal({ status: true, id: row?.id })}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        {openDeleteModal?.status && (
          <CustomDeleteDialog
            open={openDeleteModal?.status}
            handleClose={() => setOpenDeleteModal({ status: false, id: null })}
            onAccept={HandleDeleteSuburb}
          />
        )}
        {openManage?.status && (
          <ManageSuburbDetails
            open={openManage?.status}
            handleClose={() => setOpenManage({ status: false, id: null })}
            suburbId={openManage?.id}
          />
        )}
        {openEdit && <EditSuburb open={openEdit} handleClose={() => setOpenEdit(false)} updateData={selectedRow} />}
      </>
    </Page>
  );
}

export default PosSuburbIndex;
