import { Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import Page from 'components/Page';
import React, { useEffect } from 'react';
import { PATH_DASHBOARD } from 'routes/paths';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getUserDepartment } from 'redux/slices/userDepartmentSlice';
import { getDepartOfStore } from 'redux/slices/storeSlice';
import UserListBody from 'sections/@dashboard/user/list/UserListBody';

export default function UserListPage() {
  const dispatch = useDispatch();

  const { user: myInfo } = useSelector((state) => state.auth);

  const { userDTO: user } = myInfo;

  useEffect(() => {
    if (!user) return;
    if (user?.isOwner === true) {
      dispatch(getDepartOfStore());
    } else {
      dispatch(getUserDepartment(user?.id));
    }
  }, [dispatch, user]);

  return (
    <Page title="User: list">
      <HeaderBreadcrumbs
        heading="User List"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'User' },
          // { name: 'List' }
        ]}
        action={
          <Button
            variant="contained"
            component={RouterLink}
            to={PATH_DASHBOARD.user.new}
            startIcon={<Iconify icon={'eva:plus-fill'} />}
          >
            New User
          </Button>
        }
      />
      <UserListBody />
    </Page>
  );
}
