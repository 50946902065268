import { TableCell } from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import { memo, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

function DeliveryEntryIndividualCell({ mainIndex, index, item, platform, filterUser }) {
  const { setValue, register } = useFormContext();

  const filterUsers = filterUser?.find(
    (el) => el?.userId === Number(item?.id) && el?.onlinePlatformId === Number(platform?.id)
  );

  useEffect(() => {
    setValue(`legend.${mainIndex}.${index}.deliveryCount`, filterUsers?.deliveryCount);
    setValue(`legend.${mainIndex}.${index}.saleId`, filterUsers?.saleId);
    setValue(`legend.${mainIndex}.${index}.id`, filterUsers?.id);
  }, [filterUsers, mainIndex, index, filterUser?.deliveryCount, setValue]);
  return (
    <TableCell>
      <RHFTextField name={`legend.${mainIndex}.${index}.deliveryCount`} size="small" />
      <input {...register(`legend.${mainIndex}.${index}.userId`)} value={item?.id} hidden />
      <input {...register(`legend.${mainIndex}.${index}.onlinePlatformId`)} value={platform?.id} hidden />
    </TableCell>
  );
}

export default memo(DeliveryEntryIndividualCell);
