export function findDealCategoryMenus(item) {
  const newMenus = [];
  item?.dealCategoryMenus?.forEach((el) => {
    const menuFromAddedMenus = item?.addedMenus?.find((prev) => prev?.id === el?.menuId);
    if (menuFromAddedMenus) {
      newMenus.push({
        dealCategoryId: el?.dealCategoryId,
        menuId: el?.menuId,
        specialPrice: menuFromAddedMenus?.specialPrice,
        name: el?.name,
      });
    }
  });

  return newMenus;
}

export function findAddedMenus(item, index, singleDeal) {
  const addedMenus = [];
  item?.addedMenus?.forEach((el) => {
    const findPrev = singleDeal[index]?.dealCategoryMenus?.find((prev) => prev?.menuId === el?.id);
    if (!findPrev) {
      addedMenus.push({ id: el?.id, specialPrice: el?.specialPrice, name: el?.name });
    }
  });
  return addedMenus;
}
