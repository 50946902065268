import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createFoodMenu } from 'redux/slices/FoodMenuSlice';
import FoodMenuForm from 'sections/@dashboard/foodMenuAndRecipe/FoodMenu/FoodMenuForm';

function FoodMenuCreate({ open, setOpen }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const FoodMenuCreateHandler = (data) => {
    const formData = new FormData();

    if (data?.file) {
      formData.append('file', data?.file);
    }
    const finalData = {
      departmentId,
      name: data?.name,
      description: data?.description,
      menuType: data?.menuType,
      categoryId: data?.categoryId?.id,
      ingredientsAdded: data?.ingredientsAdded,
    };
    formData.append('menu', new Blob([JSON.stringify({ ...finalData })], { type: 'application/json' }));

    dispatch(createFoodMenu({ finalData: formData, departmentId, setOpen }));
  };
  return (
    <CustomDrawer open={open} setOpen={setOpen} title="Create Food Menu" PaperProps={{ sx: { minWidth: '40%' } }}>
      <FoodMenuForm onSubmit={FoodMenuCreateHandler} />
    </CustomDrawer>
  );
}

export default FoodMenuCreate;
