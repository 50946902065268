import { Stack, Typography } from '@mui/material';
import { sum } from 'lodash';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

function CashDrawerStoreSalesInformation() {
  const { onlineSalesPlatform } = useSelector((state) => state.store);

  const { control } = useFormContext();

  const watchStoreSales =
    useWatch({
      control,
      name: 'storeSales',
    }) || 0;

  const platformSales = useWatch({
    control,
    name: 'platformSales',
  });
  const totalOnRowExcludingCommission = platformSales?.map((item) => {
    let cash = 0;
    onlineSalesPlatform?.onlinePlatforms?.forEach((el) => {
      if (el.id === item?.onlinePlatformId) {
        cash = Number(item?.amount) - (Number(item?.amount) * Number(el?.commissionPercent)) / 100;
      }
    });
    return cash;
  });
  const totalOnlineExCommission = sum(totalOnRowExcludingCommission);
  const watchOnlineExCommission =
    useWatch({
      control,
      name: 'onlineExcludingCommission',
    }) ||
    totalOnlineExCommission ||
    0;

  const storeSalesIncTax = Number(watchStoreSales) - (Number(watchStoreSales).toFixed(2) * 10) / 100;
  const totalSalesInfo = storeSalesIncTax + Number(watchOnlineExCommission);

  return (
    <Stack bgcolor={'#F4F6F8'} my={0.5} p={1} borderRadius={'10px'}>
      <Typography component="p" fontSize="0.8rem  ">
        Sales IncTax (SxT) :
        <Typography component="span" fontSize="0.9rem" fontWeight={'600'}>
          {' '}
          $ {storeSalesIncTax.toFixed(2)}
        </Typography>
      </Typography>
      <Typography component="p" fontSize="0.8rem">
        SxT + PlatformS :
        <Typography component="span" fontSize="0.9rem" fontWeight={'600'}>
          {' '}
          $ {totalSalesInfo.toFixed(2)}
        </Typography>
      </Typography>
    </Stack>
  );
}

export default CashDrawerStoreSalesInformation;
