import React from 'react';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';
import LeaveRequestCard from 'sections/@dashboard/roaster/LeaveRequestCard';
import Page from 'components/Page';

export default function LeaveRequest() {
  return (
    <Page title="Leave Request">
      <HeaderBreadcrumbs
        heading="Leave Request"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Roster', href: PATH_DASHBOARD.roaster.LeaveRequest },
          { name: 'Leave Requests' },
        ]}
      />

      <LeaveRequestCard />
    </Page>
  );
}
