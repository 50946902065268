import { Box, Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { findTotalDifferenceDataOfUser } from 'utils/roasterFeatureUtils';

function QuickSummaryDifferenceUserWeeklyData({ mainIndex }) {
  const [total, setTotal] = useState();
  console.log('🚀 => QuickSummaryDifferenceUserWeeklyData => total=>', total);

  const { control } = useFormContext();

  const values = useWatch({
    control,
    name: `users.${mainIndex}`,
  });

  useEffect(() => {
    if (!Object.keys(values)) return;

    const findTotal = findTotalDifferenceDataOfUser(values);
    setTotal(findTotal);
  }, [values]);

  const findTimeDifference = (time) => {
    const absTime = Math.abs(time);
    const hours = Math.floor(absTime / 60);
    const minutes = Math.ceil(absTime % 60);
    console.log('🚀 => findTimeDifference => time=>', minutes);
    if (minutes === 60) return `${hours + 1}H 0M`;
    return `${hours}H ${minutes}M`;
  };

  return (
    <Box
      className="roasterUserDetails"
      style={{
        display: 'flex',
        flexDirection: 'column',
        fontSize: '0.6rem',
        position: 'absolute',
        right: '5px',
      }}
    >
      <Box>
        <div>
          <Tooltip title="pay difference" placement="right">
            <p style={{ color: `${total?.getTotalPayDifference < 0 ? 'red' : 'black'}` }}>
              $ {total?.getTotalPayDifference ? total?.getTotalPayDifference.toFixed(1) : '0'}
            </p>
          </Tooltip>
          <Tooltip title="Time difference" placement="right">
            <p style={{ color: `${total?.getTotalTimeDifference < 0 ? 'red' : 'black'}` }}>
              {total?.getTotalTimeDifference ? findTimeDifference(total?.getTotalTimeDifference) : '0H 0M'}
            </p>
          </Tooltip>
        </div>
      </Box>
    </Box>
  );
}

export default QuickSummaryDifferenceUserWeeklyData;
