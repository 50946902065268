import {
  Box,
  Button,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import Iconify from 'components/Iconify';
import PropTypes from 'prop-types';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getChecklistItem } from 'redux/slices/storeDocumentSlice';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { TableNoData } from 'components/table';
import ChecklistAddForm from './ChecklistAddForm';
import IndividualChecklist from './IndividualChecklist';
import IndividualChecklistDeleted from './IndividualChecklistDeleted';

function ChecklistIndividualTab({ category }) {
  const [choosenType, setChoosenType] = useState('ACTIVE');
  const [pageNo, setPageNo] = useState(1);
  const [addChecklist, setAddChecklist] = useState(false);
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const { checklists, loading } = useSelector((state) => state.storeDocument);

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getChecklistItem({ departmentId, categoryId: category?.id, status: choosenType, pageNo: pageNo - 1 }));
  }, [departmentId, dispatch, category, choosenType, pageNo]);

  return (
    <>
      <Stack alignItems="center" gap="1rem" justifyContent={'flex-start'} direction={'row'} mt={1} marginLeft={2}>
        <Button variant={choosenType === 'ACTIVE' ? 'contained' : 'outlined'} onClick={() => setChoosenType('ACTIVE')}>
          Active
        </Button>
        <Button
          variant={choosenType === 'DELETED' ? 'contained' : 'outlined'}
          onClick={() => setChoosenType('DELETED')}
        >
          Deleted
        </Button>
        {/* <Button
          variant={'contained'}
          size="small"
          style={{ margin: '10px' }}
          startIcon={<Iconify icon={'mdi:plus'} />}
          onClick={() => setAddChecklist(true)}
        >
          New
        </Button> */}
      </Stack>
      <Stack mt={2}>
        <TableContainer>
          {choosenType === 'DELETED' && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>S.N.</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Occuring Type</TableCell>
                  <TableCell>Task Date</TableCell>
                  <TableCell>Is Recurring</TableCell>
                  <TableCell align="center">Note</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* eslint-disable-next-line no-nested-ternary */}
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <CustomLoadingScreen height="20vh" />
                    </TableCell>
                  </TableRow>
                ) : checklists?.checklists?.length < 1 ? (
                  <TableNoData isNotFound={checklists?.checklists?.length < 1} />
                ) : (
                  checklists?.checklists?.map((item, index) => (
                    <IndividualChecklistDeleted key={index} item={item} index={index} parentCategory={category} />
                  ))
                )}
              </TableBody>
            </Table>
          )}
          {choosenType === 'ACTIVE' && (
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>S.N.</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Occuring Type</TableCell>
                  <TableCell>Task Date</TableCell>
                  <TableCell>Is Recurring</TableCell>
                  <TableCell align="right">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* eslint-disable-next-line no-nested-ternary */}
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <CustomLoadingScreen height="20vh" />
                    </TableCell>
                  </TableRow>
                ) : checklists?.checklists?.length < 1 ? (
                  <TableNoData isNotFound={checklists?.checklists?.length < 1} />
                ) : (
                  checklists?.checklists?.map((item, index) => (
                    <IndividualChecklist key={index} item={item} index={index} parentCategory={category} />
                  ))
                )}
              </TableBody>
            </Table>
          )}
          {!checklists?.checklists?.length > 0 ? (
            ''
          ) : (
            <Box
              sx={{ position: 'relative', marginTop: '30px' }}
              justifyContent={'end'}
              alignContent="center"
              display={'flex'}
            >
              <Pagination
                count={checklists?.paginationInfo?.totalPages}
                page={pageNo}
                onChange={(e, value) => setPageNo(value)}
              />
            </Box>
          )}
        </TableContainer>
      </Stack>
    </>
  );
}

ChecklistIndividualTab.propTypes = {
  category: PropTypes.object,
};

export default ChecklistIndividualTab;
