import {
  Box,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Scrollbar from 'components/Scrollbar';
import { TableNoData } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPrivateAnnouncement } from 'redux/slices/announcementSlice';
import PrivateAnnouncementTableBody from './PrivateAnnouncementTableBody';

function PrivateAnnouncementIndex() {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const [pageNo, setPageNo] = React.useState(1);

  const { privateAnnouncement, loading } = useSelector((state) => state.announcement);

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getPrivateAnnouncement({ departmentId, pageNo }));
  }, [dispatch, departmentId, pageNo]);
  return (
    <>
      {loading ? (
        <CustomLoadingScreen height="20vh" />
      ) : (
        <Scrollbar>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>S.N.</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell align="center">Is Permanent</TableCell>
                  <TableCell align="center">Start At</TableCell>
                  <TableCell align="center">End At</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {privateAnnouncement?.announcements?.map((item, index) => (
                  <PrivateAnnouncementTableBody key={item.id} item={item} index={index} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {privateAnnouncement?.announcements?.length === 0 ? (
            <Stack direction={'row'} justifyContent={'center'} alignItems={'center'}>
              <TableNoData isNotFound={privateAnnouncement?.announcements?.length === 0} />
            </Stack>
          ) : (
            <Box
              sx={{ position: 'relative', marginTop: '30px' }}
              justifyContent={'end'}
              alignContent="center"
              display={'flex'}
            >
              <Pagination
                count={privateAnnouncement?.paginationInfo?.totalPages}
                page={pageNo}
                onChange={(e, value) => setPageNo(value)}
              />
            </Box>
          )}
        </Scrollbar>
      )}
    </>
  );
}

export default PrivateAnnouncementIndex;
