import { Button, InputAdornment, TableCell, TextField } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

function FoodMenuBaseIngredientIndividualTableCell({ ingredientIndex, index, size }) {
  const { menuBaseIngredients, foodMenuSizes } = useSelector((state) => state.menuEngineering);

  const { register, control, setValue } = useFormContext();

  const watchQty = useWatch({
    control,
    name: `ingredients.${ingredientIndex}.menuSizes.${index}.qtyUsed`,
  });

  const findItemCost = () => {
    const findCost = menuBaseIngredients?.ingredients[ingredientIndex]?.unitPrice;
    const findWeight = menuBaseIngredients?.ingredients[ingredientIndex]?.unitWeight;
    if (!watchQty || !findCost || !findWeight) return 0;
    const itemPrice = (findCost / findWeight) * (watchQty / 1000);
    return itemPrice.toFixed(2);
  };

  useEffect(() => {
    const findCost = menuBaseIngredients?.ingredients[ingredientIndex]?.unitPrice;
    const findWeight = menuBaseIngredients?.ingredients[ingredientIndex]?.unitWeight;
    if (!watchQty || !findCost || !findWeight) {
      const itemPrice = 0;
      setValue(`ingredients.${ingredientIndex}.menuSizes.${index}.cost`, itemPrice.toFixed(2));
    } else {
      const itemPrice = (findCost / findWeight) * (watchQty / 1000);
      setValue(`ingredients.${ingredientIndex}.menuSizes.${index}.cost`, itemPrice.toFixed(2));
    }
  }, [index, ingredientIndex, menuBaseIngredients?.ingredients, setValue, watchQty]);
  return (
    <>
      <input
        type="hidden"
        {...register(`ingredients.${ingredientIndex}.menuSizes.${index}.id`)}
        value={foodMenuSizes[index]?.id}
      />
      <TableCell key={index} align="center">
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Controller
            name={`ingredients.${ingredientIndex}.menuSizes.${index}.qtyUsed`}
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                fullWidth
                value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                id="filled-basic"
                variant={'standard'}
                error={!!error}
                helperText={error?.message}
                sx={{ width: '60px' }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">gm</InputAdornment>,
                }}
              />
            )}
          />
          <Button variant="text" disabled>
            $ {findItemCost()}
          </Button>
        </div>
      </TableCell>
    </>
  );
}

export default FoodMenuBaseIngredientIndividualTableCell;
