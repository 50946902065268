import { Grid, Stack, Divider, Typography, InputAdornment } from '@mui/material';
import React from 'react';
import RHFText from './RHFText';

function ViewPaymentDetails() {
  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      spacing={2}
      sx={{ width: 1 }}
      justifyContent={'center'}
      mb={2}
      style={{ marginTop: '5px' }}
    >
      <Grid container>
        <Grid item>
          <Stack p={3}>
            <Typography component="h2" fontSize="1.2rem" fontWeight={'700'} pb="0.5rem">
              Account
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <RHFText name={`accountName`} label="Account Name" InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item xs={12} md={4}>
                <RHFText name={`accountNo`} label="Account Number" InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item xs={12} md={4}>
                <RHFText name={`bankName`} label="Bank Name" InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item xs={12} md={4}>
                <RHFText name={`bsb`} label="bsb *" InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item xs={12} md={4}>
                <RHFText name={`superName`} label="Super Name" InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item xs={12} md={4}>
                <RHFText name={`superNumber`} label="Super Number" InputLabelProps={{ shrink: true }} />
              </Grid>
              <Grid item sx={12} md={4}>
                <RHFText name="tfnNo" label={'TFN No.'} />
              </Grid>
            </Grid>
          </Stack>
          <Divider />
          <Stack p={3}>
            <Typography component="h2" fontSize="1.2rem" fontWeight={'700'} pb="0.5rem">
              Payment
            </Typography>
            <Grid container spacing={1}>
              <Grid item sx={12} md={4}>
                <RHFText
                  name="weekDayPay"
                  label={'Week Day Pay*'}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item sx={12} md={4}>
                <RHFText
                  name="saturdayPay"
                  label={'saturdayPay*'}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item sx={12} md={4}>
                <RHFText
                  name="sundayPay"
                  label={'sundayPay*'}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item sx={12} md={4}>
                <RHFText
                  name="holidayPay"
                  label={'holidayPay*'}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item sx={12} md={4}>
                <RHFText
                  name="holidayPay"
                  label={'holidayPay*'}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item sx={12} md={4}>
                <RHFText
                  name="overtimePay"
                  label={'overtimePay*'}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item sx={12} md={4}>
                <RHFText name="weeklyHrs" label={'weeklyHrs*'} />
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default ViewPaymentDetails;
