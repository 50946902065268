import { Box, Button, Card, Grid, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Iconify from 'components/Iconify';
import moment from 'moment';
import React from 'react';

export default function SalesListingFilter({ choosenDate, setChoosenDate }) {
  return (
    <>
      <Grid item xs={12} sm={6} md={4} style={{ margin: '10px' }}>
        <DatePicker
          label="Select Date"
          value={choosenDate}
          inputFormat="dd/MMM/yyyy"
          onChange={(newValue) => setChoosenDate(newValue)}
          renderInput={(params) => <TextField {...params} error={false} />}
        />
        <Button variant="text" color="error" style={{ marginTop: '10px' }} onClick={() => setChoosenDate(new Date())}>
          today
        </Button>
      </Grid>
    </>
  );
}
