import React from 'react';
import Page from 'components/Page';
import InsightReportHeader from './InsightReportHeader';
import InsightReportBody from './InsightReportBody';

function InsightReport() {
  return (
    <Page title="Insights">
      <InsightReportHeader />
      <InsightReportBody />
    </Page>
  );
}

export default InsightReport;
