import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { errorToastTime, successToastTime } from 'config';
import apiURL from '../../api';

export const getInvoicePDFDetails = createAsyncThunk('invoice/getInvoicePDFDetails', async (props) => {
  await apiURL
    .post(`/invoice/uploadInvoicePDF/${props.selectedDepartment}`, props.files)
    .then((res) => {
      props.getPdfID(res.data);
      return res.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
      throw err;
    });
});

export const getInvoiceFilter = createAsyncThunk('invoice/getInvoiceFilter', async (props) => {
  // props = {data : data ,pages : 0}
  try {
    const response = await apiURL.post(`/invoice/getInvoices?pageNo=${props.pages - 1}&pageSize=${10}`, props.data);

    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createInvoiceManually = createAsyncThunk(
  'invoice/createInvoiceManually',
  async ({ filteringData, redirectAfterSuccess }) => {
    try {
      const response = await apiURL.post(`/invoice`, filteringData);
      if (response.status === 200) {
        toast.success('Invoice Created Successfully', { autoClose: successToastTime });
        redirectAfterSuccess();
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const updateInvoices = createAsyncThunk('invoice/updateInvoices', async (props) => {
  try {
    const response = await apiURL.put(`/invoice`, props.finalData);
    if (response.status === 200) {
      toast.success('Invoice Updated Successfully', { autoClose: successToastTime });
      props.redirectAfterSuccess();
    }
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getInvoiceDetails = createAsyncThunk('invoice/getInvoiceDetails', async (props) => {
  try {
    const response = await apiURL.post(`/invoice/getInvoiceDetails`, props);

    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteInvoices = createAsyncThunk(
  'invoice/deleteInvoices',
  async ({ departmentId, invoiceId, isSuccess }) => {
    try {
      const response = await apiURL.delete(`/invoice/${departmentId}/${invoiceId}`);
      if (response.status === 200) {
        isSuccess(true);
        toast.success('Invoice Deleted Successfully.', { autoClose: successToastTime });
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const deleteInvoiceItems = createAsyncThunk('invoice/deleteInvoiceItems', async ({ data, isSuccess }) => {
  await apiURL
    .post(`/invoice/deleteInvoiceItem`, data)
    .then((res) => {
      toast.success('Invoice Item Remove Successfully', { autoClose: successToastTime });
      isSuccess(true);
      return res.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
      throw err;
    });
});

export const getPdfFile = createAsyncThunk('invoice/download', async ({ invoiceUrl, isSuccess }) => {
  await apiURL
    .get(`/invoice/downloadPDF?invoiceURL=${invoiceUrl}`)
    .then((res) => {
      toast.success('Invoice PDF Downloaded successfully.', { autoClose: successToastTime });
      isSuccess(res.data);
      return res.data;
    })
    .catch((err) => {
      toast.error(err?.response?.data?.message || 'Error Occured.Please Try Again', { autoClose: errorToastTime });
      throw err;
    });
});

const InvoiceSlice = createSlice({
  name: 'userInvoices',
  initialState: {
    InvoicePdf: [],
    openInvoiceModal: false,
    loading: false,
    invoiceList: [],
    invoiceDetails: {},
    postLoading: false,
    error: '',
    selectedUpdateInvoiceItemsId: { isAvailable: false, item: null, itemIndex: null },
  },
  reducers: {
    updateSelectedDepartment(state, action) {
      state.selectedDepartment = action.payload;
    },
    isopenInvoiceModal(state) {
      state.openInvoiceModal = !state.openInvoiceModal;
    },
    clearInvoiceDetails(state) {
      state.invoiceDetails = {};
    },

    selectUpdateInvoiceItemsId(state, action) {
      state.selectedUpdateInvoiceItemsId.isAvailable = true;
      state.selectedUpdateInvoiceItemsId.item = action.payload.id;
      state.selectedUpdateInvoiceItemsId.itemIndex = action.payload.itemIndex;
    },
    unSelectUpdateInvoiceItemsId(state) {
      state.selectedUpdateInvoiceItemsId.isAvailable = false;
      state.selectedUpdateInvoiceItemsId.item = null;
      state.selectedUpdateInvoiceItemsId.itemIndex = null;
    },
  },
  extraReducers: {
    [getInvoicePDFDetails.pending]: (state) => {
      state.loading = true;
    },
    [getInvoicePDFDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.InvoicePdf = action.payload;
    },
    [getInvoicePDFDetails.rejected]: (state) => {
      state.loading = false;
    },
    [getInvoiceFilter.pending]: (state) => {
      state.postLoading = true;
    },
    [getInvoiceFilter.fulfilled]: (state, action) => {
      state.postLoading = false;
      state.invoiceList = action.payload;
    },
    [getInvoiceFilter.rejected]: (state, action) => {
      state.postLoading = false;
      state.error = action.payload.message;
    },
    [createInvoiceManually.pending]: (state) => {
      state.postLoading = true;
    },
    [createInvoiceManually.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateInvoices.pending]: (state) => {
      state.postLoading = true;
    },
    [updateInvoices.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [updateInvoices.rejected]: (state) => {
      state.postLoading = false;
    },

    [createInvoiceManually.rejected]: (state) => {
      state.postLoading = false;
    },

    [getInvoiceDetails.pending]: (state) => {
      state.loading = true;
    },
    [getInvoiceDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.invoiceDetails = action.payload;
    },
    [getInvoiceDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload.message;
    },
  },
});

export const {
  updateSelectedDepartment,
  selectUpdateInvoiceItemsId,
  unSelectUpdateInvoiceItemsId,
  isopenInvoiceModal,
  clearInvoiceDetails,
} = InvoiceSlice.actions;
export default InvoiceSlice.reducer;
