import { Button, Stack } from '@mui/material';
import CustomMultipleAutoCompleteField from 'components/CustomComponents/CustomAutoCompleteField';
import Iconify from 'components/Iconify';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FoodCategoryCreate from '../FoodCategory/FoodCategoryCreate';

function FoodMenuFormCategoryAndSize() {
  const { foodCategories } = useSelector((state) => state.menuEngineering);
  const { menuSizeGroup } = useSelector((state) => state.menuERSizeAndOption);

  const [openCreateCategory, setOpenCreateCategory] = useState(false);
  return (
    <>
      <Stack spacing="1rem">
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={'1rem'}>
          <Stack flexGrow={1}>
            <CustomMultipleAutoCompleteField
              name="sizeGroupId"
              label="Select Size Group"
              options={menuSizeGroup}
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack spacing="1rem">
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={'1rem'}>
          <Stack flexGrow={1}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <CustomMultipleAutoCompleteField
                name="categoryId"
                label="Select Menu Category*"
                options={foodCategories}
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                sx={{ width: '25rem' }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenCreateCategory(true)}
                startIcon={<Iconify icon={'eva:plus-fill'} />}
                style={{ marginLeft: '10px', height: '35px', marginTop: '10px' }}
                size="small"
              >
                Category
              </Button>
            </div>
          </Stack>
        </Stack>
      </Stack>
      {openCreateCategory && (
        <FoodCategoryCreate open={openCreateCategory} handleClose={() => setOpenCreateCategory(false)} />
      )}
    </>
  );
}

export default FoodMenuFormCategoryAndSize;
