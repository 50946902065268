import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getRoaster } from 'redux/slices/roasterSlice';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { FormProvider, useForm } from 'react-hook-form';
import { setQuickSummaryWeek } from 'redux/slices/quickSummarySlice';
import { findAllDaysFromWeeks } from 'utils/roasterFeatureUtils';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { Stack, Table, TableContainer } from '@mui/material';
import QuickSummaryRoasterTableHead from './QuickSummaryRoasterTableHead';
import QuickSummaryRoasterTableBody from './QuickSummaryRoasterTableBody';

QuickSummaryRoasterInfo.propTypes = {
  choosenDate: PropTypes.string,
};

function QuickSummaryRoasterInfo({ choosenDate }) {
  const { allRoaster, loading } = useSelector((state) => state.roaster);

  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const [allWeeks, setAllWeeks] = useState([]);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { reset } = methods;

  const fromDate = moment(choosenDate).startOf('isoWeek').format('YYYY-MM-DD');
  const toDate = moment(choosenDate).endOf('isoWeek').format('YYYY-MM-DD');

  //   get weeks for table headings
  useEffect(() => {
    const getAllDates = findAllDaysFromWeeks(fromDate, toDate).map((obj, index) => ({
      id: index,
      label: moment(obj).format('YYYY-MM-DD'),
      align: 'left',
    }));
    setAllWeeks(getAllDates);
    dispatch(setQuickSummaryWeek(getAllDates));
  }, [dispatch, fromDate, toDate]);

  // get roaster details
  useEffect(() => {
    if (!departmentId) return;
    dispatch(getRoaster({ departmentId, anyDateOfWeek: moment(choosenDate).format('YYYY-MM-DD') }));
  }, [choosenDate, departmentId, dispatch]);

  useEffect(() => {
    if (!allRoaster?.users?.length) reset({ users: [] });
    reset({ users: allRoaster?.users });
  }, [allRoaster, reset]);

  console.log('allRoaster', methods.watch());

  return (
    <>
      {loading ? (
        <CustomLoadingScreen height="30vh" />
      ) : (
        <Stack>
          <FormProvider {...methods}>
            <TableContainer>
              <Table stickyHeader>
                <QuickSummaryRoasterTableHead allWeeks={allWeeks} />
                <QuickSummaryRoasterTableBody />
              </Table>
            </TableContainer>
          </FormProvider>
        </Stack>
      )}
    </>
  );
}

export default QuickSummaryRoasterInfo;
