import { Divider, InputAdornment, Stack, Typography } from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import calculateRecipeInformation from 'utils/FoodRecipeCalculation';
import formatStringLength from 'utils/formatStringLength';
import FoodRecipeFormCalculatedInformation from './FoodRecipeFormCalculatedInformation';

function FoodRecipeFormTargets({ sizes }) {
  const { control, setValue, getValues } = useFormContext();
  const watchRecipe = useWatch({
    control,
    name: 'receipeDTOs',
  });

  const onChangeTarget = (index, recipe) => {
    const calculateValues = calculateRecipeInformation(recipe);
    setValue(`receipeDTOs.${index}.calculated`, calculateValues);
    setValue(`receipeDTOs.${index}.targetPercent`, calculateValues?.targetPercent.toFixed(2));
  };

  const onChangeTargetPercent = (index, recipe) => {
    const getCalculated = getValues(`receipeDTOs.${index}.calculated`);
    const target = (Number(getCalculated.cogs) * 100) / (Number(recipe?.targetPercent * 10) / 11) || 0;
    setValue(`receipeDTOs.${index}.target`, target === Infinity ? 0 : target.toFixed(2));
    const calculateValues = calculateRecipeInformation(recipe);
    setValue(`receipeDTOs.${index}.calculated`, calculateValues);
  };

  return (
    <Stack p={'1rem'} bgcolor={'#F4F6F8'}>
      <Stack flexDirection={'row'} mb="1rem">
        <Typography variant="p" fontSize="1.2rem" fontWeight="600" mb="0.5rem" width="50%">
          Targets
        </Typography>

        <Typography variant="p" fontSize="1.2rem" fontWeight="600" mb="0.5rem">
          Other
        </Typography>
      </Stack>
      <Stack flexDirection={'column'} gap="1rem" divider={<Divider flexItem />}>
        {watchRecipe?.map((recipe, index) => {
          const size = sizes?.find((size) => size?.id === recipe?.sizeId);
          if (!recipe?.sp) {
            return (
              <Typography component="p" alignItems="center" justifyContent={'center'} fontSize="0.8rem" color="error">
                No SP of {size?.name} Size. Please Provide SP
              </Typography>
            );
          }
          return (
            <Stack key={index} flexDirection={'row'} alignItems={'center'} gap="3rem">
              <Stack flexDirection={'row'} alignItems={'center'} gap="1rem">
                <Typography variant="p" fontSize="0.9rem" fontWeight="500" minWidth="7rem">
                  {formatStringLength(size?.name, 10)}
                </Typography>
                <RHFTextField
                  name={`receipeDTOs.${index}.target`}
                  size="small"
                  sx={{ maxWidth: '7rem' }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  onChange={(event) => {
                    // if (Number.isNaN(Number(event.target.value))) return;
                    setValue(`receipeDTOs.${index}.target`, event.target.value);
                    onChangeTarget(index, recipe);
                  }}
                />

                <RHFTextField
                  name={`receipeDTOs.${index}.targetPercent`}
                  size="small"
                  sx={{ maxWidth: '6rem' }}
                  onChange={(event) => {
                    // if (Number.isNaN(Number(event.target.value))) return;
                    setValue(`receipeDTOs.${index}.targetPercent`, event.target.value);
                    onChangeTargetPercent(index, recipe);
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                />
              </Stack>

              <FoodRecipeFormCalculatedInformation index={index} />
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}

export default FoodRecipeFormTargets;
