import { Card } from '@mui/material';
import IngredientCardBodyTable from 'sections/@dashboard/ingredients/IngredientCardBodyTable';
import IngredientFilter from 'sections/@dashboard/ingredients/IngredientFilter';

function StockIngredientsCardBody() {
  return (
    <Card>
      <IngredientFilter />
      <IngredientCardBodyTable />
    </Card>
  );
}

export default StockIngredientsCardBody;
