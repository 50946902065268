import React from 'react';
import PropTypes from 'prop-types';
import QuickSummaryClockInIndividualTableCell from './QuickSummaryClockInIndividualTableCell';

QuickSummaryClockInIndividualTableRow.propTypes = {
  user: PropTypes.object,
  mainIndex: PropTypes.number,
};

function QuickSummaryClockInIndividualTableRow({ user, mainIndex }) {
  const array = Object.keys(user?.clockReportsInDates);
  // const reverseArray = array.reverse();
  // const sortedArray = array.sort((a, b) => new Date(a) - new Date(b));

  return (
    <>
      {array?.map((clockReport, indexes) => (
        <QuickSummaryClockInIndividualTableCell
          clockReportKey={clockReport}
          key={indexes}
          mainIndex={mainIndex}
          userInfo={user}
        />
      ))}
    </>
  );
}

export default QuickSummaryClockInIndividualTableRow;
