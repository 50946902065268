import { yupResolver } from '@hookform/resolvers/yup';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { RHFCheckbox, RHFSelect, RHFTextField, RHFUploadAvatar } from 'components/hook-form';
import { Button, FormLabel, InputAdornment, MenuItem, Stack, Typography } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { ColorSinglePicker } from 'components/color-utils';
import { createFoodMenu, updateFoodMenu } from 'redux/slices/menuEngineeringSlice';
import Iconify from 'components/Iconify';
import FoodCategoryCreate from '../FoodCategory/FoodCategoryCreate';
import FoodMenuFormCategoryAndSize from './FoodMenuFormCategoryAndSize';

const menuType = ['FOOD', 'DRINKS', 'PACKAGING', 'OTHERS'];
const COLOR_OPTIONS = [
  '#F0F8FF', // aliceblue
  '#FAEBD7', // antiquewhite
  '#00FFFF', // aqua
  '#7FFFD4', // aquamarine
  '#F5F5DC', // beige
  '#DFFF00', // chartreuse
  '#6495ED', // cornflowerblue
  '#54D62C', // theme.palette.success.main,
  '#FFC107', // theme.palette.warning.main,
];

function FoodMenuForm({ handleClose, isEdit, menu }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const [openCreateCategory, setOpenCreateCategory] = useState(false);
  const { updateFoodMenuLoading, foodCategories, singleFoodMenu } = useSelector((state) => state.menuEngineering);

  const { menuSizeGroup } = useSelector((state) => state.menuERSizeAndOption);
  const { store } = useSelector((state) => state.store);

  const defaultValues = useMemo(
    () => ({
      name: '',
      description: '',
      basePrice: '',
      hasHalfAndHalf: false,
      colorCode: '#fff',
      menuType: '',
      categoryId: isEdit && foodCategories?.[0],
      sizeGroupId: isEdit && menuSizeGroup?.[0],
      setColor: (isEdit && singleFoodMenu?.colorCode !== '#fff') || false,
      file: isEdit && singleFoodMenu?.imageUrl,
    }),
    [foodCategories, isEdit, singleFoodMenu, menuSizeGroup]
  );
  const menuSchema = Yup.object().shape({
    name: Yup.string().required(''),
    description: Yup.string().required(''),
    basePrice: Yup.number().required('').typeError('').min(0.1, ''),
    menuType: Yup.string().required(''),
    colorCode: Yup.string().required(''),
  });
  const methods = useForm({ defaultValues, resolver: yupResolver(menuSchema), mode: 'onChange' });
  const { handleSubmit, control, setValue, reset } = methods;
  const OnMenuCreate = (data) => {
    if (!isEdit) {
      const formData = new FormData();
      if (data?.file) {
        formData.append('file', data?.file);
      }
      const finalData = {
        ...data,
        colorCode: data?.setColor ? data?.colorCode : '#fff',
        categoryId: data?.categoryId?.id,
        sizeGroupId: data?.sizeGroupId?.id,
        storeId: store?.id,
        departmentId,
      };

      formData.append('menu', new Blob([JSON.stringify(finalData)], { type: 'application/json' }));

      dispatch(createFoodMenu({ formData, handleClose, departmentId }));
    } else {
      const formData = new FormData();
      if (data?.file) {
        formData.append('file', data?.file);
      }
      const finalData = {
        ...data,
        id: menu?.id,
        colorCode: data?.setColor ? data?.colorCode : '#fff',
        categoryId: data?.categoryId?.id,
        sizeGroupId: data?.sizeGroupId?.id,
        departmentId,
      };
      console.log(finalData, 'finalData');

      formData.append('menu', new Blob([JSON.stringify(finalData)], { type: 'application/json' }));
      dispatch(updateFoodMenu({ formData, departmentId, menuId: menu?.id }));
    }
  };
  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'file',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const watchSetColor = methods.watch('setColor');

  useEffect(() => {
    if (!isEdit || !singleFoodMenu || !foodCategories || !menuSizeGroup) return;

    reset({
      id: singleFoodMenu?.id,
      basePrice: singleFoodMenu?.basePrice,
      name: singleFoodMenu?.name,
      menuType: singleFoodMenu?.menuType,
      description: singleFoodMenu?.description,
      colorCode: singleFoodMenu?.colorCode,
      setColor: singleFoodMenu?.colorCode !== '#fff',
      categoryId: foodCategories?.find((item) => item?.id === singleFoodMenu?.categoryId),
      sizeGroupId: menuSizeGroup?.find((item) => item?.id === singleFoodMenu?.sizeGroupId),
      hasHalfAndHalf: singleFoodMenu?.hasHalfAndHalf,
      file: singleFoodMenu?.imageUrl,
    });
  }, [foodCategories, isEdit, singleFoodMenu, menuSizeGroup, reset]);

  const watchFile = methods.watch('file');

  return (
    <>
      <FormProvider {...methods}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }} justifyContent={'center'} mb={2}>
          <RHFUploadAvatar
            name="file"
            onDrop={handleDrop}
            //  isEditImage={isEdit ? { isEdit, imgUrl: currentData?.imageUrl } : null}
            helperText={
              <Typography
                variant="caption"
                sx={{
                  mt: 2,
                  mx: 'auto',
                  display: 'block',
                  textAlign: 'center',
                  color: 'text.secondary',
                }}
              >
                Upload Menu Image ( Allowed *.jpeg, *.jpg, *.png )
                <br />
              </Typography>
            }
          />
        </Stack>
        {isEdit && watchFile && (
          <Stack alignItems={'center'} mb={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setValue('removeMenuImage', true);
                setValue('file', null);
              }}
            >
              <Iconify icon="mdi:trash-can-outline" width={20} height={20} /> Remove Image
            </Button>
          </Stack>
        )}
        <Stack spacing="1rem">
          <RHFTextField name="name" label="Name*" />
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={'1rem'}>
            <RHFTextField
              name="basePrice"
              label="Base Price*"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />

            <RHFSelect name="menuType" label="Menu Type">
              {menuType?.map((el) => (
                <MenuItem key={el} value={el}>
                  {el}
                </MenuItem>
              ))}
            </RHFSelect>
          </Stack>
          <RHFTextField name="description" label="Description" multiline minRows={2} />
          <FoodMenuFormCategoryAndSize />
          <Stack spacing="1rem" display={'flex'} flexDirection={'row'} style={{ marginTop: '-5px' }}>
            <RHFCheckbox name="hasHalfAndHalf" style={{ marginTop: '4px' }} />
            <Typography style={{ fontSize: '0.8rem', marginLeft: '-15px' }}>Half And Half</Typography>
          </Stack>
          <Stack
            spacing="1rem"
            display={'flex'}
            flexDirection={'row'}
            style={{ marginTop: '-5px', marginBottom: '-5px' }}
          >
            <RHFCheckbox name="setColor" style={{ marginTop: '4px' }} />
            <Typography style={{ fontSize: '0.8rem', marginLeft: '-15px' }}>Set Color</Typography>
          </Stack>
          {watchSetColor && (
            <Controller
              name="colorCode"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <div style={{ display: 'flex' }}>
                  <ColorSinglePicker value={field.value} onChange={field.onChange} colors={COLOR_OPTIONS} />
                  {error && (
                    <FormLabel style={{ marginLeft: '10px', marginTop: '5px' }} sx={{ color: 'red' }}>
                      Color *
                    </FormLabel>
                  )}
                </div>
              )}
            />
          )}
        </Stack>

        <Stack alignItems="flex-end" justifyContent={'flex-end'} mt="1rem" direction="row" gap="1rem">
          <Button variant="contained" onClick={handleSubmit(OnMenuCreate)} color="secondary">
            {updateFoodMenuLoading ? <CustomLoadingScreen circularWidth={'20px'} color="secondary" /> : 'Submit'}
          </Button>
        </Stack>
      </FormProvider>
      {openCreateCategory && (
        <FoodCategoryCreate open={openCreateCategory} handleClose={() => setOpenCreateCategory(false)} />
      )}
    </>
  );
}

FoodMenuForm.propTypes = {
  handleClose: PropTypes.func,
  isEdit: PropTypes.bool,
  menu: PropTypes.object,
};

export default FoodMenuForm;
