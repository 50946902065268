import { Box, Button, Grid } from '@mui/material';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { RHFTextField, FormProvider } from 'components/hook-form';
import * as yup from 'yup';
import moment from 'moment';
import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { useDispatch, useSelector } from 'react-redux';
import { userNoteModalFalseControlAction } from 'redux/slices/userNoteSlice';

function UserNoteAddForm({ append, open, setOpen, user, date, updateData, update }) {
  const departmentId = useGetDepartmentId();
  const { modalControl, selectedUserNote } = useSelector((state) => state.userNote);
  const { open: modalOpen } = modalControl;
  const dispatch = useDispatch();

  const NewNoteSchema = yup.object().shape({
    note: yup.string().required(''),
  });
  const defaultValues = {
    note: updateData?.note?.note ? updateData?.note?.note : '',
  };
  const methods = useForm({ defaultValues, resolver: yupResolver(NewNoteSchema) });

  const CreateNewNote = (data) => {
    console.log('🚀 => CreateNewNote => data=>', data);

    if (!updateData) {
      const finalData = {
        day: moment(date).format('ddd').toUpperCase(),
        note: data?.note,
        userId: user?.id,
        departmentId,
      };
      append({
        ...finalData,
      });
    } else {
      const finalData = {
        day: moment(date).format('ddd').toUpperCase(),
        note: data?.note,
        userId: user?.id,
        departmentId,
        id: updateData?.note?.id,
      };
      update(updateData?.index, {
        ...finalData,
      });
    }

    setOpen(false);
  };

  // const onCloseModal = () => {
  //   dispatch(userNoteModalFalseControlAction());
  // };

  return (
    <CustomOutletModal
      // open={modalOpen}
      open={open}
      title={`${moment(date).format('dddd')}, ${user?.firstName} ${user?.lastName}`}
      headerIcon="ion:create-outline"
      iconWidth={20}
      iconHeight={20}
      zIndex="2000"
    >
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(CreateNewNote)}>
        {/* <FormProvider {...methods}> */}
        <Box className="roasterAddFormContainer">
          <Box className="roasterAddFormContent">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <RHFTextField name="note" label="Note" multiline rows={4} />
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    marginLeft: 'auto',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '1rem',
                    paddingLeft: '2rem',
                  }}
                >
                  <Button variant="text" size="large" style={{ color: 'red' }} onClick={setOpen}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    color="secondary"
                    type="submit"
                    // onClick={() => methods.handleSubmit(CreateNewNote)}
                  >
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </FormProvider>
    </CustomOutletModal>
  );
}

export default UserNoteAddForm;
