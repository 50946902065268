import { Box, Card, Grid } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import React from 'react';
import { useSelector } from 'react-redux';

function StoreConfigBody() {
  const { loading, storeConfsDetails } = useSelector((state) => state.storeConfig);

  return (
    <>
      {loading ? (
        <CustomLoadingScreen height="60vh" />
      ) : (
        <>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Box
                  sx={{
                    display: 'grid',
                    rowGap: 3,
                    columnGap: 4,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                      <b>Total Menus on POS Desktop: </b>
                    </div>
                    <div>{storeConfsDetails?.currentMenuElements}</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                      <b>Price Type for Multi-selectable combos: </b>
                    </div>
                    <div>{storeConfsDetails?.mixComboPriceType}</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                      <b>Max Menu Element: </b>
                    </div>
                    <div>{storeConfsDetails?.maxMenuElements}</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                      <b>Max Accepted Hours for Employee Per Day: </b>
                    </div>
                    <div>
                      {Math.floor(storeConfsDetails?.maxAceptedMins / 60)}Hrs.{' '}
                      {Math.ceil(storeConfsDetails?.maxAceptedMins % 60)}Mins.
                    </div>
                  </div>
                  {/* <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                      <b>Estimated Delivery Time: </b>
                    </div>
                    <div>{storeConfsDetails?.deliveryEstimatedMins}Mins.</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                      <b>Estimated Pickup Time: </b>
                    </div>
                    <div>{storeConfsDetails?.pickupEstimatedMins}Mins.</div>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>
                      <b>Estimated Table Time: </b>
                    </div>
                    <div>{storeConfsDetails?.tableEstimatedMins}Mins.</div>
                  </div> */}
                </Box>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default StoreConfigBody;
