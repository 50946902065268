import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Card, Grid, Stack } from '@mui/material';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { FormProvider, RHFTextField } from 'components/hook-form';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createStoreSupplier, updateStoreSupplier } from 'redux/slices/storeSlice';
import * as Yup from 'yup';

StoreSuppliersAddEditForm.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  updateData: PropTypes.object,
  edit: PropTypes.bool,
};

export default function StoreSuppliersAddEditForm({ open, setOpen, updateData, edit }) {
  const { break: storeDetail } = useSelector((state) => state.store);
  const closeModal = () => {
    setOpen(false);
  };
  const StoreSuppliersSchema = Yup.object({
    name: Yup.string().required('Supplier Name is required'),
    abn: Yup.string().required('ABN is required').length(11, 'ABN must be 11 digits'),
    // address: Yup.string().required('Address is required'),
    email: Yup.string().required('Email is required'),
    // phone1: Yup.string().required('Phone is required').nullable(),
    dueDays: Yup.string().required('Due Days is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: updateData?.name || '',
      abn: updateData?.abn || '',
      address: updateData?.address || '',
      email: updateData?.email || '',
      phone1: updateData?.phone1 || '',
      phone2: updateData?.phone2 || '',
      dueDays: updateData?.dueDays || '',
      fax1: updateData?.fax1 || '',
      fax2: updateData?.fax2 || '',
      websiteURL: updateData?.websiteURL || '',
    }),
    [updateData]
  );

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(StoreSuppliersSchema),
  });

  const { handleSubmit } = methods;

  const dispatch = useDispatch();

  const createStoreSuppliersHandler = (data) => {
    if (edit) {
      const finalData = { ...data, id: updateData?.id, storeId: storeDetail?.id };
      dispatch(updateStoreSupplier(finalData));
      closeModal();
    } else {
      const finalData = { ...data, storeId: storeDetail?.id };
      dispatch(createStoreSupplier(finalData));
      closeModal();
    }
  };

  return (
    <>
      <CustomOutletModal
        width="60rem"
        open={open}
        zIndex="1600"
        title={edit ? 'Edit Store Supplier Details' : 'Add New Store Supplier'}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(createStoreSuppliersHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card sx={{ py: 3, px: 3 }}>
                <Box>
                  <Grid container spacing={2} sx={{ p: 1 }}>
                    <Grid item xs={4}>
                      <RHFTextField name={`name`} label="Supplier Name*" />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFTextField name={`abn`} label="ABN number*" />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFTextField name={`dueDays`} label="Supplier Due Days*" />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ p: 1 }}>
                    <Grid item xs={4}>
                      <RHFTextField name={`email`} label="Supplier's Email*" />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFTextField name={`address`} label="Supplier's Address" />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFTextField name={`websiteURL`} label="Supplier's Website" />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ p: 1 }}>
                    <Grid item xs={6}>
                      <RHFTextField name={`phone1`} label="Supplier's Primary Contact" />
                    </Grid>
                    <Grid item xs={6}>
                      <RHFTextField name={`phone2`} label="Supplier's Alternative Contact" />
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} sx={{ p: 1 }}>
                    <Grid item xs={6}>
                      <RHFTextField name={`fax1`} label="Supplier's Fax Number" />
                    </Grid>
                    <Grid item xs={6}>
                      <RHFTextField name={`fax2`} label="Supplier's Alternative Fax Number" />
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Stack alignItems={'flex-end'}>
                <div>
                  <Button type="submit" variant="contained" style={{ marginRight: '5px' }}>
                    {edit ? 'Update' : 'Create'}
                  </Button>
                  <Button variant="text" style={{ color: 'red' }} onClick={closeModal}>
                    Cancel
                  </Button>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </CustomOutletModal>
    </>
  );
}
