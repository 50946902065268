import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';
import SalesPlatformList from 'sections/@dashboard/store/SalesPlatformList';
import { useDispatch, useSelector } from 'react-redux';
import { getOnlineSalesPlatform } from 'redux/slices/storeSlice';
import Iconify from 'components/Iconify';
import SalesPlatformCreateForm from 'sections/@dashboard/store/SalesPlatformCreateForm';

export default function SalesPlatformTable() {
  const dispatch = useDispatch();
  const { selectedDepartment } = useSelector((state) => state.userDepartment);
  useEffect(() => {
    if (!selectedDepartment.id) return;
    dispatch(getOnlineSalesPlatform({ departmentId: selectedDepartment.id }));
  }, [dispatch, selectedDepartment]);
  const [openAddPlatformModal, setOpenAddPlatformModal] = useState(false);
  return (
    <>
      <HeaderBreadcrumbs
        heading="Online Sales Platform"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Store' },
          // { name: 'Sales-Platform' }
        ]}
        action={
          <Box className="invoiceListHeadingButtons">
            <Button
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} />}
              onClick={() => {
                setOpenAddPlatformModal(true);
              }}
            >
              Add Platform
            </Button>
          </Box>
        }
      />

      <SalesPlatformList />
      {openAddPlatformModal && (
        <SalesPlatformCreateForm open={openAddPlatformModal} setOpen={setOpenAddPlatformModal} edit={false} />
      )}
    </>
  );
}
