import { Box, Button, Card, Grid, Stack, TextField } from '@mui/material';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { FormProvider, RHFTextField } from 'components/hook-form';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import { createFixedCost, updateFixedCost } from 'redux/slices/operationalSlice';

FixedCostAddEditForm.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  updateData: PropTypes.object,
  edit: PropTypes.bool,
};
export default function FixedCostAddEditForm({ open, setOpen, edit, updateData, choosenDate, setChoosenDate }) {
  const date = new Date();
  const { break: storeDetail } = useSelector((state) => state.store);
  const [selectedDate, setSelectedDate] = useState(date);
  const departmentId = useGetDepartmentId();

  const closeModal = () => {
    setOpen(false);
  };

  const defaultValues = useMemo(
    () => ({
      departmentId,
      storeId: storeDetail?.id,
      gas: updateData?.gas || 0,
      insurance: updateData?.insurance || 0,
      outgoing: updateData?.outgoing || 0,
      percentageRent: updateData?.percentageRent || 0,
      rent: updateData?.rent || 0,
      powerWater: updateData?.powerWater || 0,
      wasteRemoval: updateData?.wasteRemoval || 0,
      workersCompensation: updateData?.workersCompensation || 0,
    }),
    [storeDetail, updateData]
  );

  const methods = useForm({
    defaultValues,
    // resolver: yupResolver(FixedCostSchema),
  });

  const { handleSubmit } = methods;

  const dispatch = useDispatch();

  const fixedCostSubmitHandler = (data) => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        setChoosenDate(selectedDate);
        closeModal();
      }
    };
    if (edit) {
      const finalData = {
        ...data,
        month: moment(choosenDate).format('MM'),
        year: moment(choosenDate).format('YYYY'),
        id: updateData?.id,
      };
      dispatch(updateFixedCost(finalData));
      closeModal();
    } else {
      const finalData = {
        ...data,
        month: moment(selectedDate).format('MM'),
        year: moment(selectedDate).format('YYYY'),
      };
      dispatch(createFixedCost({ finalData, isSuccess: isSuccessful }));
      closeModal();
    }
  };

  return (
    <>
      <CustomOutletModal
        width="60rem"
        open={open}
        zIndex="1600"
        title={edit ? 'Edit Fixed Cost Details' : 'Add Fixed Cost Details'}
      >
        <FormProvider methods={methods} onSubmit={handleSubmit(fixedCostSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card sx={{ py: 3, px: 3 }}>
                <Box>
                  <Grid container spacing={2} sx={{ p: 1 }}>
                    <Grid item xs={12}>
                      <DatePicker
                        disabled={edit}
                        disablePast
                        views={['year', 'month']}
                        label="Select Date"
                        value={choosenDate}
                        onChange={(newValue) => setChoosenDate(newValue)}
                        renderInput={(params) => <TextField {...params} error={false} />}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFTextField name={`gas`} label="Monthly Gas cost" />
                    </Grid>

                    <Grid item xs={4}>
                      <RHFTextField name={`insurance`} label="Monthly Insurance Cost" />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFTextField name={`outgoing`} label="Monthly Outgoing Cost" />
                    </Grid>

                    <Grid item xs={4}>
                      <RHFTextField name={`rent`} label="Monthly Rent Cost" />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFTextField name={`percentageRent`} label="Monthly Rent Percentage" />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFTextField name={`powerWater`} label="Monthly Power Water Cost" />
                    </Grid>

                    <Grid item xs={4}>
                      <RHFTextField name={`wasteRemoval`} label="Monthly Waste Removal Cost" />
                    </Grid>
                    <Grid item xs={4}>
                      <RHFTextField name={`workersCompensation`} label="Monthly Workers Compensation Cost" />
                    </Grid>
                  </Grid>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <Stack alignItems={'flex-end'}>
                <div>
                  <Button type="submit" variant="contained" style={{ marginRight: '5px' }}>
                    {edit ? 'Update' : 'Create'}
                  </Button>
                  <Button variant="text" style={{ color: 'red' }} onClick={closeModal}>
                    Cancel
                  </Button>
                </div>
              </Stack>
            </Grid>
          </Grid>
        </FormProvider>
      </CustomOutletModal>
    </>
  );
}
