import { Box, Card, Stack, Tab, Tabs } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TableNoData } from 'components/table';
import FoodCategoryCogsReport from './FoodCategoryCogsReport';

function FoodCogsReportBody() {
  const { foodCategories } = useSelector((state) => state.menuEngineering);
  const [currentTab, setCurrentTab] = useState();

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    if (!foodCategories?.length) return;
    setCurrentTab(foodCategories[0]?.id);
  }, [setCurrentTab, foodCategories]);

  return (
    <>
      {foodCategories?.length === 0 ? (
        <Stack alignItems={'center'} justifyContent={'center'}>
          <TableNoData isNotFound={foodCategories?.length === 0} />
        </Stack>
      ) : (
        <Card>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={currentTab} onChange={handleChange} aria-label="Menu Edit" variant="scrollable">
              {foodCategories.map((foodCategory, index) => (
                <Tab key={index} label={foodCategory.name} value={foodCategory.id} />
              ))}
            </Tabs>
          </Box>
          {/* eslint-disable-next-line array-callback-return, consistent-return */}
          {foodCategories?.map((foodCategory, index) => {
            if (foodCategory.id === currentTab) {
              return <FoodCategoryCogsReport key={index} foodCategory={foodCategory} />;
            }
          })}
        </Card>
      )}
    </>
  );
}

export default FoodCogsReportBody;
