import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import React from 'react';
import { useDispatch } from 'react-redux';
import { clearAllRecipeofMenu } from 'redux/slices/FoodRecipeSlice';
import FoodRecipeCreateUpdate from './FoodRecipeCreateUpdate';

function FoodRecipeManageDrawer({ open, setOpen, menu }) {
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(clearAllRecipeofMenu());
  };

  return (
    <CustomDrawer
      open={open}
      setOpen={setOpen}
      title="Manage Recipe"
      handleClose={handleClose}
      PaperProps={{ sx: { minWidth: '50%' } }}
    >
      <FoodRecipeCreateUpdate menu={menu} setOpen={setOpen} />
    </CustomDrawer>
  );
}

export default FoodRecipeManageDrawer;
