import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import { successToastTime } from 'config';

export const getAllFeatures = createAsyncThunk('features/getAllFeatures', async () => {
  const response = await apiURL.get('/store/feature');
  return response.data;
});

export const getRoleBasedFeatures = createAsyncThunk('store/roleBasedFeatures/', async (roleId) => {
  const response = await apiURL.get(`/store/roleFeature/${roleId}`);
  return response.data;
});

export const saveRoleFeatures = createAsyncThunk('store/saveUserRoleFeatures', async (data) => {
  const response = await apiURL.put('/store/roleFeature', data);
  if (response.status === 200) {
    toast.success('Roles features updated', { autoClose: successToastTime });
  }
  return response.data;
});

const featureSlice = createSlice({
  name: 'feature',
  initialState: {
    allFeatures: null,
    roleBasedFeature: [],
    loading: false,
    success: false,
  },
  extraReducers: {
    [getAllFeatures.pending]: (state) => {
      state.loading = true;
    },
    [getAllFeatures.fulfilled]: (state, action) => {
      state.allFeatures = action.payload;
      state.loading = false;
    },
    [getAllFeatures.rejected]: (state) => {
      state.loading = false;
    },

    [getRoleBasedFeatures.pending]: (state) => {
      state.loading = true;
    },
    [getRoleBasedFeatures.fulfilled]: (state, action) => {
      state.loading = false;
      state.roleBasedFeature = action.payload;
    },
    [getRoleBasedFeatures.rejected]: (state) => {
      state.loading = false;
    },
    [saveRoleFeatures.pending]: (state) => {
      state.loading = true;
    },
    [saveRoleFeatures.fulfilled]: (state) => {
      state.loading = false;
    },
    [saveRoleFeatures.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default featureSlice.reducer;
