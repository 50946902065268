import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import React from 'react';
import MenuSizeForm from 'sections/@dashboard/menuEngineering/menuSize/MenuSizeForm';

function MenuSizeCreate({ open, handleClose }) {
  return (
    <CustomDrawer
      open={open}
      setOpen={handleClose}
      title={`Create Menu Size`}
      PaperProps={{ sx: { innerWidth: '50%' } }}
    >
      <Box style={{ width: '600px' }}>
        <MenuSizeForm handleClose={handleClose} />
      </Box>
    </CustomDrawer>
  );
}

MenuSizeCreate.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default MenuSizeCreate;
