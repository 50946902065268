import { Button, Grid, Icon } from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import Iconify from 'components/Iconify';
import moment from 'moment';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { deleteTimeClockBreakByAdmin, updateTimeClockBreakByAdmin } from 'redux/slices/timeSlice';
import { useDispatch, useSelector } from 'redux/store';

function TimeClockDrawerBreakForm({ mainIndex, obj, index, timeClockId, checkIsApproved }) {
  const { control } = useFormContext();
  const { selectedDepartment } = useSelector((state) => state.userDepartment);
  const { postLoading } = useSelector((state) => state.time);

  const dispatch = useDispatch();
  const breakWatch = useWatch({
    control,
    name: `timeclocks.${mainIndex}.breaks.${index}`,
  });

  const breakChangeHandler = () => {
    // total break seconds
    let totalBreaks = 0;
    let totalWork = 0;

    obj?.breaks?.forEach((el) => {
      const startBreak = moment(el.startTime, 'HH:mm:ss');
      const endBreak = moment(el.endTime, 'HH:mm:ss');
      totalBreaks += endBreak.diff(startBreak, 'seconds');
    });

    // total work seconds
    const startWork = moment(obj?.inTime, 'HH:mm:ss');
    const endWork = moment(obj?.outTime, 'HH:mm:ss');
    totalWork += endWork.diff(startWork, 'seconds');

    const finalData = { ...breakWatch, departmentId: selectedDepartment?.id, timeClockId };
    dispatch(updateTimeClockBreakByAdmin(finalData));
  };

  const breakDeleteHandler = () => {
    const finalData = { ...breakWatch, departmentId: selectedDepartment?.id, timeClockId };
    dispatch(deleteTimeClockBreakByAdmin(finalData));
  };

  return (
    <Grid container item xs={12} spacing={2.5}>
      <Grid item xs={4}>
        <RHFTextField
          type="time"
          size="small"
          name={`timeclocks.${mainIndex}.breaks.${index}.startTime`}
          label="startTime"
          disabled={checkIsApproved}
        />
      </Grid>
      <Grid item xs={4}>
        <RHFTextField
          type="time"
          size="small"
          name={`timeclocks.${mainIndex}.breaks.${index}.endTime`}
          label="endTime"
          disabled={checkIsApproved}
        />
      </Grid>
      <Grid item xs={4}>
        <Button
          style={{ marginRight: '1rem' }}
          variant="contained"
          color="primary"
          disabled={checkIsApproved || postLoading}
          type="button"
          onClick={breakChangeHandler}
        >
          Edit
        </Button>
        <Button
          style={{ cursor: 'pointer' }}
          variant="text"
          color="error"
          disabled={checkIsApproved || postLoading}
          onClick={breakDeleteHandler}
        >
          <Iconify icon={'material-symbols:delete-sharp'} width={24} height={24} />
        </Button>
      </Grid>
    </Grid>
  );
}

export default TimeClockDrawerBreakForm;
