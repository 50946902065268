import { MenuItem, TableCell, TableRow, useTheme } from '@mui/material';
import Iconify from 'components/Iconify';
import Label from 'components/Label';
import { TableMoreMenu } from 'components/table';
import PropTypes from 'prop-types';
import moment from 'moment';
import React, { useState } from 'react';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import { useDispatch } from 'react-redux';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { deleteAnnouncement } from 'redux/slices/announcementSlice';
import PrivateAnnouncementEdit from './PrivateAnnouncementEdit';
import PrivateAnnouncementView from './PrivateAnnouncementView';

function PrivateAnnouncementTableBody({ item, index }) {
  const theme = useTheme();
  const [openMenu, setOpenMenuActions] = useState(null);
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };
  const [openEdit, setOpenEdit] = useState({ status: false, data: {} });
  const [openView, setOpenView] = useState({ status: false, id: null });
  const [openDelete, setOpenDelete] = useState({ status: false, id: null });

  const HandleDelete = () => {
    dispatch(deleteAnnouncement({ id: openDelete.id, departmentId, isPrivate: true }));
    setOpenDelete({ status: false, id: null });
  };
  return (
    <TableRow hover>
      <TableCell>{index + 1}.</TableCell>
      <TableCell style={{ width: '150px' }}>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: `${item?.title}` }} />
      </TableCell>
      <TableCell style={{ width: '400px' }}>
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: `${item?.description}` }} />
      </TableCell>
      <TableCell align="center">
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={(item?.isPermanent && 'success') || 'error'}
          sx={{ textTransform: 'capitalize' }}
        >
          {item?.isPermanent ? 'Yes' : 'No'}
        </Label>
      </TableCell>
      <TableCell align="center">{item?.startDate ? moment(item?.startDate).format('ddd, DD MMM') : '---'}</TableCell>
      <TableCell align="center">{item?.endDate ? moment(item?.endDate).format('ddd, DD MMM') : '---'}</TableCell>
      <TableCell>
        <TableMoreMenu
          open={openMenu}
          onOpen={(event) => setOpenMenuActions(event.currentTarget)}
          onClose={() => setOpenMenuActions(null)}
          className="tablemore"
          actions={
            <>
              <MenuItem
                onClick={() => {
                  setOpenView({ status: true, id: item?.id });
                  handleCloseMenu();
                }}
              >
                <Iconify icon="mdi:eye" />
                View
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenEdit({ status: true, data: item });
                  handleCloseMenu();
                }}
              >
                <Iconify icon="mdi:pencil" />
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenDelete({ status: true, id: item?.id });
                  handleCloseMenu();
                }}
              >
                <Iconify icon="mdi:delete" />
                Delete
              </MenuItem>
            </>
          }
        />
        {openEdit.status && (
          <PrivateAnnouncementEdit
            open={openEdit.status}
            data={openEdit.data}
            handleClose={() => setOpenEdit({ status: false, data: {} })}
          />
        )}
        {openDelete.status && (
          <CustomDeleteDialog
            open={openDelete.status}
            handleClose={() => setOpenDelete({ status: false, id: null })}
            onAccept={HandleDelete}
          />
        )}
        {openView.status && (
          <PrivateAnnouncementView
            open={openView.status}
            id={openView.id}
            handleClose={() => setOpenView({ status: false, id: null })}
          />
        )}
      </TableCell>
    </TableRow>
  );
}

PrivateAnnouncementTableBody.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
};

export default PrivateAnnouncementTableBody;
