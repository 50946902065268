import { Autocomplete, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { FormProvider } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { useForm, useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getFoodCategories } from 'redux/slices/FoodCategoriesAndSizeSlice';
import { getAllMenusWithCategories } from 'redux/slices/FoodMenuSlice';
import FoodMenuEditDrawer from 'sections/@dashboard/foodMenuAndRecipe/FoodMenu/FoodMenuEditDrawer';

function FoodMenuFilter() {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const { loading, allMenuWithCategories } = useSelector((state) => state.foodMenus);
  const [open, setOpen] = useState(false);
  const [indexList, setIndexList] = useState(null);

  const { control } = useFormContext();

  const watchData = useWatch({
    control,
    name: 'data',
  });

  const filterAllMenusWithCategories = watchData?.map((obj) => obj?.menus).flat() || [];

  const onChangeSearchHandler = (event, value, reason, details) => {
    const findCategoryIndex = allMenuWithCategories?.findIndex(
      (category) => category?.id === details?.option.categoryId
    );
    const findMenuIndex = allMenuWithCategories?.[findCategoryIndex]?.menus?.findIndex(
      (menu) => menu?.id === details?.option.id
    );

    setIndexList({ indexList: { mainIndex: findCategoryIndex, menuIndex: findMenuIndex }, menu: details?.option });
  };

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getFoodCategories({ departmentId }));
    dispatch(getAllMenusWithCategories({ departmentId }));
  }, [dispatch, departmentId]);

  return (
    <>
      <Stack mx={'1rem'} my="1rem" direction={'row'} alignItems="center" justifyContent={'space-between'} gap="2rem">
        <Autocomplete
          name="searchMenu"
          options={filterAllMenusWithCategories}
          getOptionLabel={(option) => option?.name}
          includeInputInList={false}
          onChange={(event, value, reason, details) => {
            if (reason === 'clear') return;
            setOpen(true);
            onChangeSearchHandler(event, value, reason, details);
          }}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          renderInput={(params) => <TextField {...params} label="Search Menus" value="" />}
          fullWidth
          renderOption={(props, option) => (
            <Stack
              component="li"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems="flex-start"
              width="100%"
              {...props}
            >
              <Stack>{option?.name}</Stack>
              <Stack direction={'row'} ml="auto" gap="1rem">
                {option?.sizes?.map((size, index) => (
                  <Stack
                    gap="1rem"
                    key={index}
                    bgcolor={'rgba(145, 158, 171, 0.12)'}
                    direction={'row'}
                    p={0.5}
                    borderRadius={'10px'}
                  >
                    <Typography component={'p'}>{size?.name}</Typography>
                    <Typography component={'p'} bgcolor={'white'} p={0.5}>
                      $ {size?.price}
                    </Typography>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
        />

        <Stack alignItems={'flex-end'}>
          <Button variant="contained" form="foodmenuPriceCreateFormId" type="submit">
            Save
          </Button>
        </Stack>
      </Stack>
      {open && (
        <FoodMenuEditDrawer open={open} setOpen={setOpen} menu={indexList?.menu} indexList={indexList?.indexList} />
      )}
    </>
  );
}

export default FoodMenuFilter;
