import { Box, Button, Table, TableCell, TableContainer, TableRow } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Iconify from 'components/Iconify';
import Scrollbar from 'components/Scrollbar';
import { TableNoData } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteIndividualOption, getIndividualOption } from 'redux/slices/menuERSizeAndOptionSlice';

function ViewMenuOptionDetails({ open, handleClose, selectedRow }) {
  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, id: null });

  const { singleMenuOptionLoading, individualMenuOption } = useSelector((state) => state.menuERSizeAndOption);

  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const handleDeleteSingleOption = () => {
    dispatch(deleteIndividualOption({ departmentId, optionGroupId: selectedRow?.id, optionId: openDeleteModal?.id }));
    setOpenDeleteModal({ id: null, status: false });
  };

  useEffect(() => {
    if (!selectedRow) return;
    if (!departmentId) return;
    dispatch(getIndividualOption({ departmentId, optionGroupId: selectedRow?.id }));
  }, [selectedRow, dispatch, departmentId]);

  return (
    <CustomDrawer
      open={open}
      setOpen={handleClose}
      title={`Details of ${selectedRow?.name}`}
      PaperProps={{ sx: { innerWidth: '50%' } }}
    >
      <Box style={{ width: '600px' }}>
        <>
          <Scrollbar>
            <TableContainer>
              <Table stickyHeader size="small">
                {singleMenuOptionLoading ? (
                  <CustomLoadingScreen height="30vh" />
                ) : (
                  <>
                    {individualMenuOption?.length <= 0 ? (
                      <TableNoData isNotFound={individualMenuOption?.length <= 0} />
                    ) : (
                      <>
                        {individualMenuOption?.map((row, index) => (
                          <TableRow key={index} hover>
                            <TableCell>{index + 1}.</TableCell>
                            <TableCell>{row?.name}</TableCell>
                            <TableCell align="center">
                              {/* <Button
                                                             style={{ color: '#4caf50', marginLeft: '10px', marginRight: '10px' }}
                                                               variant="text"
                                                                   startIcon={<Iconify icon="material-symbols:edit-outline" />}
                                                                     >
                                                                           Edit
                                                                               </Button> */}
                              <Button
                                style={{ color: '#f44336', marginLeft: '10px', marginRight: '10px' }}
                                variant="text"
                                startIcon={<Iconify icon="mdi:delete-outline" />}
                                onClick={() => setOpenDeleteModal({ status: true, id: row?.id })}
                              >
                                Delete
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                      </>
                    )}
                  </>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          {openDeleteModal.status && (
            <CustomDeleteDialog
              open={openDeleteModal}
              handleClose={setOpenDeleteModal}
              onAccept={handleDeleteSingleOption}
            />
          )}
        </>
      </Box>
    </CustomDrawer>
  );
}

export default ViewMenuOptionDetails;
