import {
  // Avatar,
  Box,
  // Button,
  // ButtonBase,
  // Menu,
  // MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  // useTheme,
} from '@mui/material';
// import Iconify from 'components/Iconify';
// import Label from 'components/Label';
import { TableHeadCustom, TableNoData } from 'components/table';
import useTable from 'hooks/useTable';
// import moment from 'moment';
// import { getUserStoreDT } from 'redux/slices/userSlice';
// import { getTimeClockDay } from 'redux/slices/timeSlice';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
// import { convertDatetoString, convertStringTimetoTime } from 'utils/customDateAndTimeFormat';

import React, { useState } from 'react';
import { useSelector } from 'redux/store';
import TimeDurationClockTableBody from 'sections/@dashboard/time/TimeDurationClockTableBody';
import EditTimeClockDuration from './EditTimeClockDuration';

const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'center' },
  { id: 'clockin', label: 'Clock In', align: 'left' },
  { id: 'clockout', label: 'Clock Out', align: 'left' },
  { id: 'totalhours', label: 'Total Hours', align: 'left' },
  { id: 'breakhours', label: 'Total Break Hours', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
];

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };

function TimeDurationClockTable() {
  // const theme = useTheme();

  const [openDrawer, setOpenDrawer] = useState(false);

  const { loading, timeUserFilter } = useSelector((state) => state.time);
  const [drawerUserObj, setDrawerUserObj] = useState({});
  const { dense } = useTable();

  return (
    <Box>
      <TableContainer>
        <Table size={dense ? 'small' : 'medium'} style={{ minWidth: '800px' }}>
          <TableHeadCustom headLabel={TABLE_HEAD} />
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colspan="7">
                  <CustomLoadingScreen height="30vh" />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {timeUserFilter?.data?.map((elm, index) => (
                  <TimeDurationClockTableBody
                    key={index}
                    elm={elm}
                    setOpenDrawer={setOpenDrawer}
                    setDrawerUserObj={setDrawerUserObj}
                  />
                ))}
                <TableNoData isNotFound={timeUserFilter?.data?.length <= 0} />
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {openDrawer && <EditTimeClockDuration open={openDrawer} setOpen={setOpenDrawer} userObj={drawerUserObj} />}
    </Box>
  );
}

export default TimeDurationClockTable;
