import { Box, Button, Stack } from '@mui/material';
import { RHFCheckbox } from 'components/hook-form';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

function FoodMenuTopinsCheckbox({ option }) {
  const { control } = useFormContext();
  const { menuTopinsOption } = useSelector((state) => state.menuEngineering);

  const {
    fields: addFields,
    append: addAppend,
    remove: addRemove,
  } = useFieldArray({
    control,
    name: 'addedOptionIds',
  });

  const {
    fields: removeFields,
    append: removeAppend,
    remove: removeRemove,
  } = useFieldArray({
    control,
    name: 'removedOptionIds',
  });

  const handleTopinsChange = (event, option) => {
    if (!event.target.checked) {
      if (menuTopinsOption?.length <= 0) {
        const findRemoveIndex = addFields.findIndex((item) => item === option?.id);
        removeRemove(findRemoveIndex);
      } else {
        menuTopinsOption?.map((item) => {
          if (item?.optionId === option?.id) {
            removeAppend(option?.id);
          } else {
            const findRemoveIndex = addFields.findIndex((item) => item === option?.id);
            addRemove(findRemoveIndex);
          }
          return item;
        });
      }
    } else if (event.target.checked) {
      if (menuTopinsOption?.length <= 0) {
        addAppend(option?.id);
      } else {
        menuTopinsOption?.map((item) => {
          if (item?.optionId === option?.id) {
            const findRemoveIndex = addFields?.findIndex((item) => item === option?.id);
            addRemove(findRemoveIndex);
          } else {
            addAppend(option?.id);
          }
          return item;
        });
      }
    }
  };

  const handleChecked = (e) => {
    let boolean = false;
    menuTopinsOption?.map((item) => {
      if (item?.optionId === e) {
        boolean = true;
      }
      return boolean;
    });
    return boolean;
  };
  return (
    <Stack flexDirection="row" alignItems={'center'} my="0.5rem">
      <Stack
        alignItems="center"
        gap="0.5rem"
        flexDirection={'row'}
        my="0.15rem"
        p="0.4rem"
        borderRadius={'10px'}
        fontWeight={500}
        sx={{ cursor: 'pointer' }}
      >
        <Stack>
          <Button sx={{ color: 'black', fontWeight: '500', fontSize: '1rem' }}>{option?.name}</Button>
        </Stack>
      </Stack>
      <Stack className={'manageMenuSizeList'}>
        <Box className="manageMenuSizeListPriceSettings">
          <RHFCheckbox
            name={`selectedOptionIds`}
            onChange={(event) => handleTopinsChange(event, option)}
            defaultChecked={handleChecked(option?.id)}
          />
        </Box>
      </Stack>
    </Stack>
  );
}

export default FoodMenuTopinsCheckbox;
