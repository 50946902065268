import { Button, Card, Container, FormControlLabel, Stack, Typography } from '@mui/material';
import Page from 'components/Page';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import { useForm, useWatch } from 'react-hook-form';
import { FormProvider, RHFCheckbox } from 'components/hook-form';
import { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { useNavigate, useParams } from 'react-router';
import { submitAgreementDocument, validateHRToken } from 'redux/slices/authSlice';
import { toSvg } from 'html-to-image';
import StaffCreateActiveStepperSignaturePad from 'sections/@dashboard/staffCreate/StaffCreateActiveStepperSignaturePad';
import logoImg from 'assets/logoImg.jpeg';

import PDFRender from './PDFRender';

function SendHR() {
  const [isSucceed, setIsSuccess] = useState(false);
  const [openModel, setOpenModal] = useState(false);
  const { documentPDF, tokenValidationLoading, documentSubmitLoading } = useSelector((state) => state.auth);

  const newRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useParams();
  const userToken = token;

  const defaultValues = {
    fullName: '',
  };
  const methods = useForm({ defaultValues });
  const { control, setValue, handleSubmit, register } = methods;

  const watchSignatures = useWatch({
    control,
    name: 'signature',
  });
  const watchCertifyBySigning = useWatch({
    control,
    name: 'certifyBySigning',
  });
  const watchTandC = useWatch({
    control,
    name: 'acceptTermsAndConditions',
  });
  const watchCertifyAccuracy = useWatch({
    control,
    name: 'certifyAccuracy',
  });
  const watchName = useWatch({
    control,
    name: 'declaration.fullName',
  });

  const isSuccess = () => {
    setIsSuccess(true);
  };

  const currentDate = new Date().toDateString();
  const HRSubmitHandler = (data) => {
    if (!data?.declaration?.fullName) return toast.warning('Please enter your name');
    const formData = new FormData();
    if (data?.sign) {
      formData.append('file', data.sign, 'file.svg');
    }

    const finalData = {
      type: 'HR',
      token: userToken,
    };
    formData.append('token', new Blob([JSON.stringify({ ...finalData })], { type: 'application/json' }));
    dispatch(submitAgreementDocument({ formData, isSuccess }));
  };
  useEffect(() => {
    dispatch(validateHRToken({ token, navigate }));
  }, [dispatch, token, navigate]);

  const filter = (node) => {
    const exclusionClasses = ['createSignatureButtonDeclarationForm'];
    return !exclusionClasses.some((classname) => node.classList?.contains(classname));
  };

  useEffect(() => {
    const newFunction = async () => {
      const data = await toSvg(newRef?.current, { filter });
      const decodeData = decodeURIComponent(data);
      const subStringData = decodeData.substring(decodeData.indexOf(',') + 1);

      const svgBlob = new Blob([subStringData], { type: 'image/svg+xml' });
      setValue('sign', svgBlob);
    };
    newFunction();
  }, [setValue, watchSignatures, watchCertifyBySigning, watchName, watchTandC, watchCertifyAccuracy]);

  return (
    <>
      <LogoOnlyLayout />
      {isSucceed ? (
        <Stack height="70vh" alignItems="center" justifyContent={'center'} gap="1rem">
          <img src={logoImg} alt="pbilogo" style={{ borderRadius: '50%', width: '15rem' }} />
          <h1>Your Information is Recorded successfully. Thank You</h1>
        </Stack>
      ) : (
        <Page title="HR Document">
          <Container sx={{ marginBlock: '6rem' }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(HRSubmitHandler)}>
              {tokenValidationLoading ? (
                <CustomLoadingScreen height={'20vh'} />
              ) : (
                <Card style={{ padding: '20px' }}>
                  <Stack direction="column" spacing={2} alignItems={'center'}>
                    <Typography variant="h6" color="error">
                      Please Read the provided document and sign to agree the T&C.
                    </Typography>

                    <PDFRender title="SOP Document" url={documentPDF} />
                  </Stack>
                  <Stack ref={newRef} className=" termsAndConditionsCSSOnlyForm" gap={2}>
                    <Stack px={2} py={2}>
                      <p className="c6" style={{ paddingTop: '1rem', fontSize: '.8rem' }}>
                        I
                        <input
                          type="text"
                          {...register('declaration.fullName')}
                          style={{ fontSize: '9pt', marginInline: '0.4rem' }}
                        />
                        acknowledge that I received a copy of HR Manual and that I have read and understood it.
                      </p>
                    </Stack>
                    <Stack px={2} py={1}>
                      <FormControlLabel
                        label={`  I agree to comply with the policies and procedures applicable to me contained within the HR Handbook to the best of my ability and to comply with all policies and procedures when attending other workplaces.`}
                        control={
                          <>
                            <RHFCheckbox name="acceptTermsAndConditions" size="small" />
                          </>
                        }
                      />
                    </Stack>

                    <Stack px={2}>
                      <FormControlLabel
                        label={`I also certify that all information provided by me in relation to this or document is true, accurate, and complete to the best of my knowledge.
              `}
                        control={<RHFCheckbox name="certifyAccuracy" />}
                      />
                    </Stack>
                    <Stack direction="row" alignItems="center">
                      <Stack direction={'row'} gap="0.2rem" alignItems={'center'} my={2}>
                        <Typography component="p" fontSize={'0.875rem'}>
                          Signed:
                        </Typography>
                        {watchSignatures && (
                          <>
                            <img src={watchSignatures} alt="signature" style={{ width: '10rem' }} />
                          </>
                        )}
                      </Stack>
                      <Button
                        className="createSignatureButtonDeclarationForm"
                        onClick={() => setOpenModal(true)}
                        size="small"
                        variant="outlined"
                        sx={{ fontSize: '0.7rem' }}
                      >
                        Create Signature
                      </Button>
                    </Stack>

                    <Stack direction={'row'} gap="0.2rem" alignItems={'center'} mt="0.1rem">
                      <Typography component="p" fontSize={'0.875rem'} width="100%">
                        Date : {currentDate}
                      </Typography>
                    </Stack>

                    <Stack px={2}>
                      <FormControlLabel
                        label={`By signing this declaration, I acknowledge that I have voluntarily agreed to the terms and conditions of the agreement or document and that I am legally bound by them.
              `}
                        control={<RHFCheckbox name="certifyBySigning" disabled={!watchSignatures} />}
                      />
                    </Stack>
                  </Stack>

                  <Stack direction="row" justifyContent="flex-end" mt={2}>
                    <Button
                      variant="contained"
                      size="small"
                      type="submit"
                      disabled={!watchCertifyBySigning || !watchTandC || !watchName || documentSubmitLoading}
                    >
                      {documentSubmitLoading ? (
                        <CustomLoadingScreen circularWidth={'20px'} color="secondary" />
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  </Stack>
                </Card>
              )}
            </FormProvider>
          </Container>
        </Page>
      )}
      {openModel && (
        <StaffCreateActiveStepperSignaturePad
          open={openModel}
          setOpen={setOpenModal}
          formValue={(URL) => setValue('signature', URL)}
        />
      )}
    </>
  );
}

export default SendHR;
