import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Pagination,
  Popover,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import CustomDatePicker from 'components/CustomComponents/CustomDatePicker';
import { FormProvider } from 'components/hook-form';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUserWorkReport } from 'redux/slices/reportSlice';
import { convertDatetoString } from 'utils/customDateAndTimeFormat';
import { TableNoData } from 'components/table';
import Iconify from 'components/Iconify';
import UserWorkReportTable from './UserWorkReportTable';

function UserWorkReportBody() {
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState('ACTIVE');
  const [searchUser, setSearchUser] = useState('');

  const [departmentId, setDepartmentId] = useState('All');
  const [pageNo, setPageNo] = useState(1);

  const [startDate, setStartDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  const [endDate, setEndDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  const [choosenDate, setChoosenDate] = useState('DAYS');
  const [openImportDatePop, setOpenImportDatePop] = useState(null);
  const { depOfStore } = useSelector((state) => state.store);
  const { alluserWorkReport } = useSelector((state) => state.reports);

  const handleOpenImportPopOver = (event) => {
    setOpenImportDatePop(event.currentTarget);
  };

  const handleCloseImportPopOver = () => {
    setOpenImportDatePop(null);
  };

  const defaultValues = {
    startDate: moment(new Date()).toDate(),
    endDate: new Date(),
  };

  const methods = useForm({ defaultValues });
  const { handleSubmit, watch } = methods;
  const watchStartDate = watch('startDate');

  const ReportFilterHandler = (data) => {
    setChoosenDate('CUSTOM');
    setStartDate(convertDatetoString(data?.startDate));
    setEndDate(convertDatetoString(data?.endDate));
    handleCloseImportPopOver();
  };

  const open = Boolean(openImportDatePop);
  const ids = open ? 'simple-popover' : undefined;

  useEffect(() => {
    setPageNo(1);
  }, [tabValue, departmentId]);

  useEffect(() => {
    if (departmentId !== 'All') {
      dispatch(
        getAllUserWorkReport({
          status: tabValue,
          departmentId,
          startDate: startDate || moment(new Date()).subtract(15, 'days').format('YYYY-MM-DD'),
          endDate: endDate || moment(new Date()).format('YYYY-MM-DD'),
          pageNo,
          name: searchUser,
        })
      );
    } else {
      dispatch(
        getAllUserWorkReport({
          status: tabValue,
          startDate: startDate || moment(new Date()).subtract(15, 'days').format('YYYY-MM-DD'),
          endDate: endDate || moment(new Date()).format('YYYY-MM-DD'),
          pageNo,
          name: searchUser,
        })
      );
    }
  }, [departmentId, dispatch, endDate, startDate, tabValue, pageNo, searchUser]);

  const userReportFilter = (status) => {
    let startDate;
    let endDate;
    setChoosenDate(status);
    switch (status) {
      case 'DAYS':
        startDate = new Date();
        endDate = startDate;
        break;
      case 'YESTERDAY':
        startDate = moment().subtract(1, 'days').toDate();
        endDate = startDate;
        break;
      case 'WEEK':
        startDate = moment().startOf('isoWeek').toDate();
        endDate = new Date();
        break;
      case 'LAST_WEEK':
        startDate = moment().subtract(1, 'weeks').startOf('isoWeek').toDate();
        endDate = moment().subtract(1, 'weeks').endOf('isoWeek').toDate();
        break;
      case 'FORTNIGHT':
        startDate = moment().subtract(14, 'days').startOf('isoWeek').toDate();
        // endDate = moment(startDate).add(13, 'days').toDate();
        endDate = moment(startDate).add(10, 'days').endOf('isoWeek').toDate();
        break;
      case 'MONTH':
        startDate = moment(new Date()).subtract(30, 'days');
        endDate = new Date();
        break;
      case 'LAST_MONTH':
        startDate = moment().subtract(1, 'months').startOf('month').toDate();
        endDate = moment().subtract(1, 'months').endOf('month').toDate();
        break;

      default:
        break;
    }
    setStartDate(convertDatetoString(startDate));
    setEndDate(convertDatetoString(endDate));
  };
  return (
    <>
      <Card>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={tabValue}
          onChange={(e, value) => {
            setTabValue(value);
          }}
          sx={{ px: 2, bgcolor: 'background.neutral' }}
        >
          <Tab label="Active" value="ACTIVE" />
          <Tab label="InActive" value="INACTIVE" />
        </Tabs>
        <Divider />
        <Stack spacing={2} direction={{ xs: 'row' }} sx={{ py: 2.5, px: 3 }}>
          <TextField
            fullWidth
            select
            label="Department"
            value={departmentId}
            onChange={(e) => setDepartmentId(e.target.value)}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            <MenuItem value={'All'}>All</MenuItem>
            {depOfStore?.map((option) => (
              <MenuItem key={option?.id} value={option?.id}>
                {option?.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            value={searchUser}
            onChange={(event) => setSearchUser(event.target.value)}
            placeholder="Search user..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Divider />
        <Stack m={'1rem'}>
          <FormProvider methods={methods} onSubmit={handleSubmit(ReportFilterHandler)}>
            <Stack
              alignItems={'center'}
              justifyContent={'center'}
              gap="1rem"
              display={'flex'}
              flexDirection={'row'}
              flexWrap={'wrap'}
            >
              <Button
                variant={choosenDate === 'DAYS' ? 'contained' : 'outlined'}
                onClick={() => userReportFilter('DAYS')}
              >
                Today
              </Button>
              <Button
                variant={choosenDate === 'YESTERDAY' ? 'contained' : 'outlined'}
                onClick={() => userReportFilter('YESTERDAY')}
              >
                Yesterday
              </Button>
              <Button
                variant={choosenDate === 'WEEK' ? 'contained' : 'outlined'}
                onClick={() => userReportFilter('WEEK')}
              >
                This Week
              </Button>
              <Button
                variant={choosenDate === 'LAST_WEEK' ? 'contained' : 'outlined'}
                onClick={() => userReportFilter('LAST_WEEK')}
              >
                Last Week
              </Button>
              <Button
                variant={choosenDate === 'FORTNIGHT' ? 'contained' : 'outlined'}
                onClick={() => userReportFilter('FORTNIGHT')}
              >
                Fortnight
              </Button>
              <Button
                variant={choosenDate === 'MONTH' ? 'contained' : 'outlined'}
                onClick={() => userReportFilter('MONTH')}
              >
                30 Days
              </Button>
              <Button
                variant={choosenDate === 'LAST_MONTH' ? 'contained' : 'outlined'}
                onClick={() => userReportFilter('LAST_MONTH')}
              >
                Prev Month
              </Button>
              <Button
                onClick={(event) => {
                  handleOpenImportPopOver(event);
                }}
              >
                Custom
              </Button>
            </Stack>
            <Popover
              id={ids}
              open={open}
              anchorEl={openImportDatePop}
              onClose={handleCloseImportPopOver}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <Stack alignItems={'center'} direction="column" gap="1rem" m="1rem">
                <CustomDatePicker
                  name="startDate"
                  fieldWidth={'max-content'}
                  inputFormat="dd/MMM/yyyy"
                  label="Start Date"
                  disableFuture
                />
                <CustomDatePicker
                  name="endDate"
                  fullWidth={false}
                  fieldWidth="max-content"
                  inputFormat="dd/MMM/yyyy"
                  label="End Date"
                  disableFuture
                  minDate={watchStartDate}
                />
                <Stack width="100%">
                  <Button variant="contained" type="button" onClick={handleSubmit(ReportFilterHandler)}>
                    Filter
                  </Button>
                </Stack>
              </Stack>
            </Popover>
          </FormProvider>
        </Stack>
        {choosenDate === 'CUSTOM' && (
          <Stack spacing={2} direction="row" sx={{ mt: 2 }} justifyContent="center">
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              {/* <Iconify icon="mdi:calendar" /> */}
              From: {moment(startDate).format('ddd, DD MMM YYYY')} - To: {moment(endDate).format('ddd, DD MMM YYYY')}
            </Typography>
          </Stack>
        )}
        {choosenDate === 'WEEK' && (
          <Stack spacing={2} direction="row" sx={{ mt: 2 }} justifyContent="center">
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              {/* <Iconify icon="mdi:calendar" /> */}
              From: {moment(startDate).format('ddd, DD MMM YYYY')} - To:{' '}
              {/* </Typography>{' '}
            <Typography variant="h6" sx={{ color: 'text.secondary' }}> */}
              {/* <Iconify icon="mdi:calendar" /> */}
              {moment(endDate).format('ddd, DD MMM YYYY')}
            </Typography>
          </Stack>
        )}
        {choosenDate === 'LAST_WEEK' && (
          <Stack spacing={2} direction="row" sx={{ mt: 2 }} justifyContent="center">
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              {/* <Iconify icon="mdi:calendar" /> */}
              From: {moment(startDate).format('ddd, DD MMM YYYY')} - To:{' '}
              {/* </Typography>{' '}
            <Typography variant="h6" sx={{ color: 'text.secondary' }}> */}
              {/* <Iconify icon="mdi:calendar" /> */}
              {moment(endDate).format('ddd, DD MMM YYYY')}
            </Typography>
          </Stack>
        )}
        {choosenDate === 'FORTNIGHT' && (
          <Stack spacing={2} direction="row" sx={{ mt: 2 }} justifyContent="center">
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              {/* <Iconify icon="mdi:calendar" /> */}
              From: {moment(startDate).format('ddd, DD MMM YYYY')} - To:{' '}
              {/* </Typography>{' '}
            <Typography variant="h6" sx={{ color: 'text.secondary' }}> */}
              {/* <Iconify icon="mdi:calendar" /> */}
              {moment(endDate).format('ddd, DD MMM YYYY')}
            </Typography>
          </Stack>
        )}
        {choosenDate === 'MONTH' && (
          <Stack spacing={2} direction="row" sx={{ mt: 2 }} justifyContent="center">
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              {/* <Iconify icon="mdi:calendar" /> */}
              From: {moment(startDate).format('ddd, DD MMM YYYY')} - To:{' '}
              {/* </Typography>{' '}
            <Typography variant="h6" sx={{ color: 'text.secondary' }}> */}
              {/* <Iconify icon="mdi:calendar" /> */}
              {moment(endDate).format('ddd, DD MMM YYYY')}
            </Typography>
          </Stack>
        )}
        {choosenDate === 'LAST_MONTH' && (
          <Stack spacing={2} direction="row" sx={{ mt: 2 }} justifyContent="center">
            <Typography variant="h6" sx={{ color: 'text.secondary' }}>
              {/* <Iconify icon="mdi:calendar" /> */}
              From: {moment(startDate).format('ddd, DD MMM YYYY')} - To:{' '}
              {/* </Typography>{' '}
            <Typography variant="h6" sx={{ color: 'text.secondary' }}> */}
              {/* <Iconify icon="mdi:calendar" /> */}
              {moment(endDate).format('ddd, DD MMM YYYY')}
            </Typography>
          </Stack>
        )}
        <UserWorkReportTable
          list={alluserWorkReport.users}
          startDate={startDate}
          endDate={endDate}
          departmentId={departmentId}
        />
        {!alluserWorkReport?.users?.length > 0 ? (
          <Stack spacing={2} direction="row" sx={{ mt: 2 }} justifyContent="center">
            <TableNoData isNotFound={alluserWorkReport?.users?.length === 0} />
          </Stack>
        ) : (
          <Box
            sx={{ position: 'relative', marginTop: '30px' }}
            justifyContent={'end'}
            alignContent="center"
            display={'flex'}
          >
            <Pagination
              count={alluserWorkReport?.paginationInfo?.totalPages}
              page={pageNo}
              onChange={(e, value) => setPageNo(value)}
            />
          </Box>
        )}
      </Card>
    </>
  );
}

export default UserWorkReportBody;
