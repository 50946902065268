import { Button, Stack } from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

function StockCreateOrderTextField({ row }) {
  const { watch, setValue } = useFormContext();

  const checkRequiredQty = () => {
    const findNeededValue = Number(row?.parLevel) - Number(row?.currentQty);
    if (findNeededValue <= 0) {
      return 1;
    }
    return findNeededValue;
  };
  useEffect(() => {
    setValue(`${row?.id}.supplierId`, row?.supplierId);
    setValue(`${row?.id}.stockItemId`, row?.id);
    setValue(`${row?.id}.externalUID`, row?.externalUID);
    setValue(`${row?.id}.brand`, row?.brand);
    setValue(`${row?.id}.productDescription`, row?.productDescription);
    setValue(`${row?.id}.parLevel`, row?.parLevel);
    setValue(`${row?.id}.currentQty`, row?.currentQty);
    setValue(`${row?.id}.unitPrice`, row?.unitPrice);
    setValue(`${row?.id}.changedSupplierId`, row?.supplierId);
  }, [setValue, row]);
  return (
    <Stack flexDirection={'row'} gap={'0.2rem'}>
      <RHFTextField name={`${row?.id}.orderQty`} size="small" sx={{ width: '4.2rem' }} />
      <Button
        variant="contained"
        size="small"
        color="secondary"
        onClick={() => setValue(`${row?.id}.orderQty`, checkRequiredQty())}
      >
        {checkRequiredQty()}
      </Button>
    </Stack>
  );
}

export default StockCreateOrderTextField;
