import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Stack, TextField, Typography } from '@mui/material';
import { MobileDateTimePicker, TimePicker } from '@mui/x-date-pickers';
import CustomMobileTimePicker from 'components/CustomComponents/CustomMobileTimePicker';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { FormProvider } from 'components/hook-form';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { manageDecimal } from 'utils/managedecimal';
import * as Yup from 'yup';

const TimeDurationSchema = Yup.object().shape({
  clockInDatetime: Yup.date().required(''),
  clockOutDatetime: Yup.date().required(''),
});

function EditTimeClockEventsDrawer({ open, handleClose, data, onUpdate }) {
  // console.log('🚀 => EditTimeClockEventsDrawer => data=>', data);

  const [isDisabled, setIsDisabled] = useState(false);

  const defaultValue = {
    clockInDatetime: data?.clockIn?.clockTime,
    clockInId: data?.clockIn?.id,
    clockOutId: data?.clockOut?.id,
    clockOutDatetime: data?.clockOut?.clockTime ? data?.clockOut?.clockTime : null,
  };
  const methods = useForm({ defaultValues: defaultValue, resolver: yupResolver(TimeDurationSchema) });
  const { handleSubmit, watch } = methods;
  const EditTimeDurationHandler = (datas) => {
    onUpdate(datas);
  };

  const inHours = () => {
    const watchClockIn = watch('clockInDatetime');
    let watchClockOut = watch('clockOutDatetime');

    const inHours = moment.duration(moment(watchClockOut)?.diff(moment(watchClockIn))).asHours() || 0;
    if (inHours >= 0) return inHours;
    watchClockOut = moment(watchClockOut).add(1, 'day');
    const newInHours = moment.duration(moment(watchClockOut)?.diff(moment(watchClockIn))).asHours() || 0;
    return newInHours;
  };

  const clockInDT = watch('clockInDatetime');
  const clockOutDT = watch('clockOutDatetime');

  // this useEffect will run everytime when in and out clock value is changed and check whether the diff is > 16 hours or not
  // it is used to disable the update button
  useEffect(() => {
    const inHours = moment.duration(moment(clockOutDT)?.diff(moment(clockInDT))).asHours() || 0;

    if (inHours < 0) {
      const watchClockOut = moment(clockOutDT).add(1, 'day');
      const newInHours = moment.duration(moment(watchClockOut)?.diff(moment(clockInDT))).asHours() || 0;

      if (newInHours > 16) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
    } else if (inHours > 16) {
      setIsDisabled(true);
    } else if (inHours < 16 && inHours > 0) {
      setIsDisabled(false);
    }
  }, [clockInDT, clockOutDT]);

  return (
    <CustomOutletModal open={open} onClose={handleClose} zIndex="1600" title="Update Time Duration">
      <FormProvider methods={methods} onSubmit={handleSubmit(EditTimeDurationHandler)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {/* <CustomMobileTimePicker name="clockInDatetime" label="Clock In" /> */}
            <Controller
              name={'clockInDatetime'}
              control={methods.control}
              // errors={errors}
              render={({ field }) => (
                <MobileDateTimePicker
                  DialogProps={{ sx: { zIndex: 4000 } }}
                  label="Clock In"
                  // defaultValue={selectedDate}
                  // maxDateTime={watchClockOutTime}
                  inputFormat="dd/MMM/yyyy hh:mm a"
                  {...field}
                  renderInput={(params) => <TextField required fullWidth {...params} />}
                />
              )}
            />
            {/* <Controller
              name={'clockInDatetime'}
              control={control}
              errors={errors}
              render={({ field }) => (
                <TimePicker
                  DialogProps={{ sx: { zIndex: 4000 } }}
                  label="Clock In"
                  PopperProps={{
                    sx: { zIndex: '1600' },
                  }}
                  {...field}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              )}
            /> */}
          </Grid>
          <Grid item xs={6}>
            {/* <CustomMobileTimePicker name="clockOutDatetime" label="Clock Out" /> */}
            <Controller
              name={'clockOutDatetime'}
              control={methods.control}
              // errors={errors}
              render={({ field }) => (
                <MobileDateTimePicker
                  DialogProps={{ sx: { zIndex: 4000 } }}
                  label="Clock In"
                  // defaultValue={selectedDate}
                  // maxDateTime={watchClockOutTime}
                  inputFormat="dd/MMM/yyyy hh:mm a"
                  {...field}
                  renderInput={(params) => <TextField required fullWidth {...params} />}
                />
              )}
            />
            {/* <Controller
              name={'clockOutDatetime'}
              control={control}
              errors={errors}
              render={({ field }) => (
                <TimePicker
                  DialogProps={{ sx: { zIndex: 4000 } }}
                  PopperProps={{
                    sx: { zIndex: '1600' },
                  }}
                  label="Clock Out"
                  {...field}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              )}
            /> */}
          </Grid>
          <Grid item xs={12}>
            <Stack flexDirection={'row'} alignItems="center" justifyContent={'flex-end'} gap={1}>
              <Typography component="p">{manageDecimal(inHours())} Hr</Typography>
              <Button type="submit" variant="contained" style={{ marginRight: '5px' }} disabled={isDisabled}>
                Update and Approve
              </Button>
              <Button variant="text" style={{ color: 'red' }} onClick={handleClose}>
                Cancel
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </CustomOutletModal>
  );
}

export default EditTimeClockEventsDrawer;
