import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { getIndividualDealDetails } from 'redux/slices/dealSlice';
import { PATH_DASHBOARD } from 'routes/paths';
import DealOfferAddForm from './DealOfferAddForm';

function EditDealOffer() {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const { id } = useParams();

  useEffect(() => {
    if (!id) return;
    if (!departmentId) return;
    dispatch(getIndividualDealDetails({ id, departmentId }));
  }, [departmentId, dispatch, id]);

  return (
    <Page title="Deal Offer">
      <HeaderBreadcrumbs
        heading="Edit Deal"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Food', href: PATH_DASHBOARD.food.dealOffer },
        ]}
      />
      <DealOfferAddForm isEdit />
    </Page>
  );
}

export default EditDealOffer;
