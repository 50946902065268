import { Box, Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import React, { useState } from 'react';
import { PATH_DASHBOARD } from 'routes/paths';
import IngredientManageDrawer from 'sections/@dashboard/ingredients/IngredientManageDrawer';
import CreateIngredients from './CreateIngredients';

function StockIngredientsHeaders() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <HeaderBreadcrumbs
        heading="Ingredients"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Stocks', href: PATH_DASHBOARD.stock.root },
          { name: 'List' },
        ]}
        // action={
        //   <Box className="invoiceListHeadingButtons">
        //     <Button
        //       variant="contained"
        //       startIcon={<Iconify icon={'eva:plus-fill'} />}
        //       type="submit"
        //       // form="roasterSubmit"
        //       onClick={() => setOpen(true)}
        //     >
        //       Create
        //     </Button>
        //   </Box>
        // }
      />

      {/* {open && <IngredientManageDrawer open={open} setOpen={setOpen} />} */}
    </>
  );
}

export default StockIngredientsHeaders;
