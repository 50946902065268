import { Button, Grid, Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';
import { toast } from 'react-toastify';
import { errorToastTime } from 'config';

QuickSummaryFilter.propTypes = {
  choosenDate: PropTypes.string,
  setChoosenDate: PropTypes.func,
};

function QuickSummaryFilter({ choosenDate, setChoosenDate }) {
  return (
    <>
      <Grid>
        <Stack
          spacing={2}
          alignItems="center"
          justifyContent={'space-between'}
          direction={{ xs: 'column', md: 'row' }}
          sx={{ py: 2, px: 2 }}
        >
          <DatePicker
            disableFuture
            // minDate={getMinDate}
            label="Select Date"
            inputFormat="dd/MMM/yyyy"
            value={choosenDate}
            onChange={(date) => {
              setChoosenDate(moment(date).format('YYYY-MM-DD'));
            }}
            renderInput={(params) => <TextField {...params} error={false} />}
          />
          <Stack direction={{ xs: 'column', md: 'row' }} gap="1rem">
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setChoosenDate(moment(choosenDate).subtract(14, 'days').format('YYYY-MM-DD'));
              }}
            >
              Fortnight
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setChoosenDate(moment(choosenDate).subtract(7, 'days').format('YYYY-MM-DD'));
              }}
            >
              Last Week
            </Button>
            <Button
              color="error"
              onClick={() => {
                setChoosenDate(moment(new Date()).format('YYYY-MM-DD'));
              }}
            >
              Today
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                // eslint-disable-next-line no-unused-expressions
                moment(choosenDate).isBefore(moment(new Date()).startOf('isoWeek').format('YYYY-MM-DD'))
                  ? setChoosenDate(moment(choosenDate).add(7, 'days').format('YYYY-MM-DD'))
                  : toast.error('You can not go beyond current week', { autoClose: errorToastTime });
              }}
            >
              Next Week
            </Button>
          </Stack>
        </Stack>
      </Grid>
    </>
  );
}

export default QuickSummaryFilter;
