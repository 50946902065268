import { Box, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { findWeeklyTotalOfEachUser, findWeeklyTotalOfEachUserForQuickSummary } from 'utils/roasterFeatureUtils';

function QuickSummaryRoasterUserWeeklyData({ mainIndex, totalPriceOfWeek }) {
  const [total, setTotal] = useState();
  console.log('🚀 => QuickSummaryRoasterUserWeeklyData => total=>', total);

  const { control } = useFormContext();

  const values = useWatch({
    control,
    name: `users.${mainIndex}`,
  });

  useEffect(() => {
    if (!Object.keys(values)) return;
    const findTotal = findWeeklyTotalOfEachUserForQuickSummary(values);

    setTotal(findTotal);
  }, [values, totalPriceOfWeek]);

  const findTotalWorkTime = (totalDate) => {
    const hours = Math.floor(totalDate / 1000 / 60 / 60);
    const minutes = Math.ceil((totalDate / 1000 / 60) % 60);
    if (minutes > 59) return `${hours + 1}H 0M`;
    return `${hours}H ${minutes}M`;
  };

  return (
    <Box
      className="roasterUserDetails"
      style={{
        display: 'flex',
        flexDirection: 'column',
        fontSize: '0.6rem',
        position: 'absolute',
        right: '10px',
        top: '35%',
      }}
    >
      <Box>
        <div>
          <Tooltip title="weekly earnings" placement="right">
            <p>$ {total?.getTotalWeeklyCost ? total?.getTotalWeeklyCost.toFixed(1) : '0'}</p>
          </Tooltip>
          <Tooltip title="weekly hours" placement="right">
            <p>{findTotalWorkTime(total?.getTotalHours)}</p>
          </Tooltip>
        </div>
      </Box>
    </Box>
  );
}

export default QuickSummaryRoasterUserWeeklyData;
