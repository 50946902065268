import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Pagination,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Iconify from 'components/Iconify';
import { TableHeadCustom, TableNoData } from 'components/table';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { DatePicker } from '@mui/x-date-pickers';
import { getUserRole } from 'redux/slices/UserRolesSlice';
// import { getDepartOfStore } from 'redux/slices/storeSlice';
import { getShiftRequest, getStoreDate, updateShiftRequest } from 'redux/slices/shiftRequestSlice';
import { toast } from 'react-toastify';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { getUserRoles } from 'redux/slices/userSlice';
import useGetDepartmentId from 'hooks/useGetDepartmentId';

const TABLE_HEAD = [
  { id: 'requestor', label: 'Picked By', align: 'left' },
  { id: 'phone', label: 'Phone', align: 'left' },
  { id: 'actual', label: 'Dropped By', align: 'left' },
  { id: 'contact', label: 'Phone', align: 'left' },
  { id: 'time', label: 'Shift Time', align: 'left' },
  { id: 'note', label: 'Note', align: 'left' },
  { id: 'action', label: 'Action', align: 'left' },
];

// MODAL STYLE
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ShiftRequestTable() {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  //   getting store date
  const { storeDate } = useSelector((state) => state.ShiftRequest);
  const { shiftRequest, loading: shiftRequestLoading } = useSelector((state) => state.ShiftRequest);
  const { role, loading: roleLoading } = useSelector((state) => state.userRole);
  const { timeDT, loading: userLoading, userRoles } = useSelector((state) => state.user);

  const [openReject, setOpenReject] = useState(false);
  const [openAccept, setOpenAccept] = useState(false);
  const handleRejectClose = () => setOpenReject(false);
  const handleAcceptClose = () => setOpenAccept(false);
  // const [rejectData, setRejectData] = useState(null);
  const [acceptData, setAcceptData] = useState(null);
  const [roleId, setRoleId] = useState(userRoles[0]);
  const [myRole, setMyRole] = useState([]);
  const [pageNo, setPageNo] = useState(1);

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState();

  const handleApprove = (data) => {
    setOpenAccept(true);
    setAcceptData(data);
    dispatch(getUserRoles({ userId: data.requestor.id, departmentId }));
  };
  const acceptRequestFromModal = () => {
    const dataSet = { id: acceptData.id, departmentId, departmentRoleId: roleId, shiftId: acceptData.userShiftId };
    dispatch(updateShiftRequest({ dataSet, fromDate, toDate }));
    setOpenAccept(false);
  };

  // const handleReject = (data) => {
  //   setOpenReject(true);
  //   // setRejectData(data);
  // };
  const rejectRequestFromModal = () => {
    // dispatch(updateLeaveRequest({ ...rejectData, status: 'REJECTED' }));
    setOpenReject(false);
  };

  const fromDateChange = (date) => {
    setFromDate(moment(date).format('YYYY-MM-DD'));
  };
  const toDateChange = (date) => {
    setToDate(moment(date).format('YYYY-MM-DD'));
  };

  const handleRoleChange = (e) => {
    setRoleId(e.target.value);
    // setRoleName(e.target.name);
  };

  useEffect(() => {
    const myRoles = [];
    if (!userLoading && userRoles.length > 0) {
      role.forEach((item) => {
        userRoles.forEach((role) => {
          if (item.id === role) {
            const values = { id: item.id, title: item.title };
            myRoles.push(values);
          }
        });
      });
    }
    setMyRole(myRoles);
  }, [userRoles, role, userLoading]);
  useEffect(() => {
    dispatch(getUserRole());
  }, [dispatch]);

  useEffect(() => {
    if (timeDT) {
      setFromDate(moment(timeDT).format('YYYY-MM-DD'));
      setToDate(moment(timeDT).add(10, 'days').format('YYYY-MM-DD'));
    }
  }, [timeDT]);

  useEffect(() => {
    if (!departmentId) return;
    if (!fromDate) return;
    if (!toDate) return;
    if (fromDate && toDate) {
      const diffDays = moment(toDate).diff(moment(fromDate), 'days');
      if (fromDate > toDate) {
        toast.error('From date should be less than to date');
      } else if (diffDays > 30) {
        toast.error('From date and to date should be within 30 days');
      } else {
        dispatch(
          getShiftRequest({
            departmentId,
            startDate: moment(fromDate).format('YYYY-MM-DD'),
            endDate: moment(toDate).format('YYYY-MM-DD'),
            statusList: ['PENDING'],
            typeList: ['PICKUP'],
          })
        );
      }
    }
  }, [departmentId, fromDate, toDate, dispatch]);

  return (
    <>
      {/* Reject Modal */}
      <Modal
        open={openReject}
        onClose={handleRejectClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to Reject the Request?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="success"
              style={{ marginRight: '5px' }}
              onClick={() => rejectRequestFromModal()}
            >
              Reject
            </Button>
            <Button variant="outlined" color="error" onClick={handleRejectClose}>
              Cancel
            </Button>
          </Typography>
        </Box>
      </Modal>

      {/* Accept Modal */}
      <Modal
        open={openAccept}
        onClose={handleAcceptClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: '10px' }}>
            Please Apply role for this employee:
          </Typography>
          {roleLoading && userLoading ? (
            <CustomLoadingScreen />
          ) : (
            <FormControl style={{ width: '250px' }}>
              <InputLabel id="demo-simple-select-label">Select Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                name="roleId"
                value={roleId}
                input={<OutlinedInput label="Select Role" />}
                onChange={(e) => handleRoleChange(e)}
              >
                {myRole?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.title}{' '}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="success"
              style={{ marginRight: '5px' }}
              onClick={() => acceptRequestFromModal()}
            >
              Approve
            </Button>
            <Button variant="outlined" color="error" onClick={handleAcceptClose}>
              Cancel
            </Button>
          </Typography>
        </Box>
      </Modal>

      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
        {/* Department Selection */}
        {/* <FormControl style={{ width: '250px' }}>
          <InputLabel id="demo-simple-select-label">Select Department</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={departmentId}
            input={<OutlinedInput label="Select Department" />}
            onChange={() => setDepartmentId(watchDepartmentId)}
          >
            {depOfStore?.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
        {/* From Date */}
        <DatePicker
          disablePast
          label="From Date"
          value={fromDate}
          inputFormat="dd/MMM/yyyy"
          onChange={fromDateChange}
          renderInput={(params) => <TextField {...params} error={false} />}
        />

        {/* To Date */}
        <DatePicker
          disablePast
          label="To Date"
          value={toDate}
          inputFormat="dd/MMM/yyyy"
          onChange={toDateChange}
          renderInput={(params) => <TextField {...params} error={false} />}
        />
      </Stack>
      <Table size="small" className="shiftRequestTable">
        <TableHeadCustom headLabel={TABLE_HEAD} />
        <TableBody>
          {shiftRequestLoading ? (
            <TableRow>
              <TableCell colSpan={5}>
                <CustomLoadingScreen height={'20vh'} />
              </TableCell>
            </TableRow>
          ) : (
            shiftRequest?.shiftRequests?.map((item) => (
              <TableRow hover key={item.id}>
                <TableCell>
                  <div style={{ display: 'flex' }}>
                    {item?.requestor?.thumbnailURL ? (
                      <Avatar
                        src={item.requestor?.thumbnailURL}
                        style={{
                          height: '30px',
                          width: '30px',
                          borderRadius: '100px',
                          marginRight: '10px',
                        }}
                      />
                    ) : (
                      <Avatar alt="" src="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                    )}
                    <Typography style={{ marginTop: '5px' }} variant="subtitle2" noWrap className="fontChange">
                      {item.requestor.firstName} {item.requestor.lastName}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className="fontChange">{item.requestor.phone}</TableCell>
                <TableCell>
                  <div style={{ display: 'flex' }}>
                    {item?.userShift?.shiftUser?.thumbnailURL ? (
                      <Avatar
                        src={item?.userShift?.shiftUser?.thumbnailURL}
                        style={{
                          height: '30px',
                          width: '30px',
                          borderRadius: '100px',
                          marginRight: '10px',
                        }}
                      />
                    ) : (
                      <Avatar alt="" src="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                    )}
                    <Typography style={{ marginTop: '5px' }} variant="subtitle2" noWrap className="fontChange">
                      {item.userShift?.shiftUser?.firstName} {item.userShift?.shiftUser?.lastName}
                    </Typography>
                  </div>
                </TableCell>
                <TableCell className="fontChange">{item?.userShift?.shiftUser?.phone}</TableCell>
                <TableCell className="fontChange shiftTimeofRequest">
                  <span>{moment(item.userShift?.date).format('MMM DD')}, </span>{' '}
                  <span>{moment(item.userShift.startTime, 'hh').format('LT')} - </span>{' '}
                  <span>{moment(item.userShift.endTime, 'hh').format('LT')}</span>
                </TableCell>
                <TableCell>
                  <Tooltip title={item.userShift?.note}>
                    <span>
                      <Button disabled>
                        <Iconify icon={'ic:round-speaker-notes'} width={20} height={20} className="fontChange" />
                      </Button>
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ display: 'flex' }}>
                  <Button>
                    <Iconify
                      icon={'mdi:tick-all'}
                      width={20}
                      height={20}
                      onClick={() => handleApprove(item)}
                      className="fontChange"
                    />
                  </Button>
                  {/* <Button>
                    <Iconify icon={'charm:circle-cross'} width={20} height={20} onClick={() => handleReject(item)} />
                  </Button> */}
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
        {shiftRequest?.shiftRequests?.length === 0 && <TableNoData isNotFound={shiftRequest?.shiftRequests} />}
      </Table>
      <Box
        sx={{ position: 'relative', marginTop: '10px' }}
        justifyContent={'end'}
        alignContent="center"
        display={'flex'}
      >
        <Pagination
          count={shiftRequest?.paginationInfo?.totalPages}
          page={pageNo}
          onChange={(e, value) => setPageNo(value)}
        />
      </Box>
    </>
  );
}
