import { Avatar, Button, TableCell, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Iconify from 'components/Iconify';
import { useDispatch } from 'react-redux';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { getIndividualUserWorkReport } from 'redux/slices/reportSlice';
import UserWorkReportDetailsViewDrawer from './UserWorkReportDetailsViewDrawer';

function UserWorkReportTableRow({ row, index, startDate, endDate }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const [detailsView, setDetailsView] = useState(false);
  const [data, setData] = useState(null);
  const viewClicked = (row) => {
    setData(row);
    setDetailsView(true);
    dispatch(getIndividualUserWorkReport({ id: row?.userId, startDate, endDate, departmentId }));
  };
  const findWorkTime = (row) => {
    const hours = Math.floor(row?.workTimeInMinutes / 60);
    const minutes = row?.workTimeInMinutes % 60;
    return `${hours} hr ${minutes} min`;
  };
  return (
    <>
      <TableRow hover>
        <TableCell style={{ width: '15px' }}>{index + 1}</TableCell>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          {row?.thumbNailUrl ? (
            <Avatar
              src={row?.thumbNailUrl}
              alt=""
              style={{
                height: '30px',
                width: '30px',
                borderRadius: '100%',
                marginRight: '10px',
              }}
            />
          ) : (
            <Avatar
              alt=""
              src=""
              style={{ height: '30px', width: '30px', borderRadius: '100%', marginRight: '10px' }}
            />
          )}
          <Typography variant="subtitle2" noWrap>
            {`${row?.firstName} ${row?.middleName ?? ''} ${row?.lastName}`}
          </Typography>
        </TableCell>
        <TableCell>{row?.workTimeInMinutes ? `${(row?.workTimeInMinutes / 60).toFixed(2)} Hrs` : 0}</TableCell>
        <TableCell>{row?.totalDriverIncentive ? `$ ${row?.totalDriverIncentive}` : `$ 0`}</TableCell>
        <TableCell>{row?.totalActualPay ? `$ ${row?.totalActualPay}` : `$ 0`}</TableCell>
        <TableCell>
          <Button
            onClick={() => {
              viewClicked(row);
            }}
          >
            <Iconify icon="ic:baseline-remove-red-eye" />
          </Button>
        </TableCell>
      </TableRow>
      {detailsView && <UserWorkReportDetailsViewDrawer setOpen={setDetailsView} open={detailsView} data={data} />}
    </>
  );
}

UserWorkReportTableRow.propTypes = {
  row: PropTypes.object,
  index: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
};

export default UserWorkReportTableRow;
