import Page from 'components/Page';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getPaymentType } from 'redux/slices/salesSlice';
import { getStoreDate } from 'redux/slices/shiftRequestSlice';
import { getDriverIncentive, getOnlineSalesPlatform, getStoreCashDrawer, storeDetails } from 'redux/slices/storeSlice';
import { getAllUserss, getFeatureBasedUser } from 'redux/slices/userSlice';
import SalesBody from 'sections/@dashboard/sales/salesReport/SalesBody';
import SalesHeader from 'sections/@dashboard/sales/salesReport/SalesHeader';

export default function SalesPageIndex() {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  useEffect(() => {
    dispatch(storeDetails());
    dispatch(getDriverIncentive());
    dispatch(getStoreDate());
    dispatch(getDriverIncentive());
  }, [dispatch]);

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getPaymentType({ departmentId }));
    dispatch(getFeatureBasedUser({ featureId: 2, departmentId }));
    dispatch(getStoreCashDrawer({ departmentId }));
    dispatch(getOnlineSalesPlatform({ departmentId }));
    dispatch(getAllUserss({ departmentId }));
  }, [dispatch, departmentId]);

  useEffect(() => {
    const unloadCallback = (event) => {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => {
      window.removeEventListener('beforeunload', unloadCallback);
    };
  }, []);

  return (
    <>
      <Page title="Sales Report">
        <SalesHeader />
        <SalesBody />
      </Page>
    </>
  );
}
