import Page from 'components/Page';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getAgreementDocument, getAllUserss } from 'redux/slices/userSlice';
import HrHeader from './HrHeader';
import HrBody from './HrBody';

function HrAndOhns() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllUserss({}));
    dispatch(getAgreementDocument());
  });
  return (
    <Page title="User: HR & SOP">
      <HrHeader />
      <HrBody />
    </Page>
  );
}

export default HrAndOhns;
