import { Box, Card, CardContent, CardHeader, Tooltip } from '@mui/material';
import Iconify from 'components/Iconify';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { findTotalPriceofRoaster, getTwoTimesDifference } from 'utils/roasterFeatureUtils';

function QuickSummaryRoasterIndividualCard({ data, userInfo, mainIndex, roasterReportKey }) {
  const { allRoaster } = useSelector((state) => state.roaster);

  const cardColor = userInfo?.roles?.find((obj) => obj.id === Number(data.roleId))?.colorCode;
  const timeDifference = getTwoTimesDifference(data?.endDateTime, data?.startDateTime);

  const timeWithoutBreak = data?.breakMins
    ? timeDifference?.asMilliseconds() - data?.breakMins * 60 * 1000
    : timeDifference?.asMilliseconds();

  const TotalPriceofRoaster = findTotalPriceofRoaster(data, allRoaster?.holidayDates, userInfo);

  // useEffect(() => {
  //   setValue(
  //     `users.${mainIndex}.userShiftsInDates.${roasterReportKey}.${cardFieldIndex}.timeDifference`,
  //     timeDifference
  //   );
  //   setValue(
  //     `users.${mainIndex}.userShiftsInDates.${roasterReportKey}.${cardFieldIndex}.timeWithoutBreak`,
  //     timeWithoutBreak
  //   );
  //   setValue(
  //     `users.${mainIndex}.userShiftsInDates.${roasterReportKey}.${cardFieldIndex}.forecastPay`,
  //     Number(TotalPriceofRoaster)
  //   );
  //   setValue(
  //     `users.${mainIndex}.userShiftsInDates.${roasterReportKey}.${cardFieldIndex}.totalPriceofRoaster`,
  //     Number(TotalPriceofRoaster)
  //   );
  // }, [register, setValue]);

  const findWorkTime = (time) => {
    const hours = Math.floor(time / 1000 / 60 / 60);
    const minutes = Math.ceil((time / 1000 / 60) % 60);
    if (minutes > 59) return `${hours + 1}H 0M`;
    return `${hours}H ${minutes}M`;
  };

  return (
    <>
      <Card
        sx={{ marginBottom: '0.1rem', borderRadius: '5px', background: `${cardColor}` }}
        className="individualRoasterCardContainer"
      >
        <CardHeader
          avatar={
            <p
              style={{
                fontSize: '0.85rem',
                paddingInline: '0.1rem',
                marginTop: '0.1rem',
                borderRadius: '5px',
                fontWeight: '600',
              }}
              className="headingTopics"
            >
              <span>
                $ {TotalPriceofRoaster}
                {', '} {findWorkTime(timeWithoutBreak)}
              </span>
            </p>
          }
          sx={{ padding: '0.2rem', color: 'white' }}
          className="individualRoasterCardHeaders"
        />
        <CardContent className="individualRoasterCardContent" style={{ color: 'black' }}>
          <p style={{ marginTop: '-15px', padding: '0' }}>
            <span style={{ fontWeight: '600' }}> {moment(data.startDateTime).format('h:mm A')}</span>
            {' - '}
            <span style={{ fontWeight: '600' }}> {moment(data.endDateTime).format('h:mm A')}</span>
          </p>
          <div style={{ padding: '0', display: 'flex', marginBottom: '-4px' }}>
            <Tooltip title="Break">
              <Box className="individualRoasterClockIcon">
                <Box>
                  <Iconify icon="material-symbols:nest-clock-farsight-analog-outline" width="15" />
                </Box>
              </Box>
            </Tooltip>
            <p style={{ display: 'inline-flex', fontWeight: '600', marginLeft: '2px' }}>
              {data?.breakMins ? data?.breakMins : 0} M{' '}
            </p>
            {data?.note && (
              <Tooltip title={data.note}>
                <Box className="individualRoasterNoteIcon">
                  <Box>
                    <Iconify icon="material-symbols:speaker-notes" width="15" />
                  </Box>
                </Box>
              </Tooltip>
            )}
          </div>
        </CardContent>
      </Card>
    </>
  );
}

export default QuickSummaryRoasterIndividualCard;
