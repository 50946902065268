import {
  Autocomplete,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { RHFCheckbox, RHFTextField } from 'components/hook-form';
import React, { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

function FoodMenuComboIngredients() {
  const { control, setValue } = useFormContext();
  const watchRecipe = useWatch({ control, name: 'isRecipe' });
  const { allIngredientsWithPrice, allRecipeIngredientsWithPrice } = useSelector((state) => state.ingredients);
  const allRecipeIngredients = [{ id: null, name: 'Selecte Recipe' }, ...allRecipeIngredientsWithPrice?.ingredients];
  const allIngredients = [{ id: null, name: 'Select Ingredient' }, ...allIngredientsWithPrice?.ingredients];

  const { foodMenuSizes, foodMenuSizesLoading } = useSelector((state) => state.menuEngineering);

  const { fields } = useFieldArray({
    control,
    name: 'menuSizes',
  });

  const watchIngredients = useWatch({ control, name: 'ingredientId' });

  useEffect(() => {
    if (watchIngredients?.id) return;
    fields?.forEach((el, index) => {
      setValue(`menuSizes.${index}.qtyUsed`, 0);
    });
  }, [fields, setValue, watchIngredients]);

  // useEffect(() => {
  //   if (watchRecipe) return;
  //   setValue(`ingredientId`, null);
  // }, [setValue, watchRecipe]);
  return (
    <>
      <Stack display={'flex'} flexDirection={'row'}>
        <Stack>
          {watchRecipe ? (
            <Controller
              control={control}
              name={`ingredientId`}
              render={({ field: { onChange, ...fields } }) => (
                <Autocomplete
                  label="Select Recipe"
                  disableClearable
                  onChange={(event, data, reason, details) => {
                    onChange(data);
                  }}
                  getOptionLabel={(option) => option?.name}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  {...fields}
                  options={[{ id: null, name: 'Selecte Recipe' }, ...allRecipeIngredientsWithPrice?.ingredients] || []}
                  renderInput={(params) => <TextField {...params} label="Select Recipe" style={{ width: '12rem' }} />}
                />
              )}
            />
          ) : (
            <Controller
              control={control}
              name={`ingredientId`}
              render={({ field: { onChange, value, ...fields } }) => (
                <Autocomplete
                  label="Select Ingredient"
                  disableClearable
                  onChange={(event, data, reason, details) => {
                    onChange(data);
                  }}
                  value={value}
                  getOptionLabel={(option) => option?.name}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  {...fields}
                  options={[{ id: null, name: 'Select Ingredient' }, ...allIngredientsWithPrice?.ingredients] || []}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Ingredient" style={{ width: '12rem' }} />
                  )}
                />
              )}
            />
          )}
        </Stack>
        <Stack flexDirection={'row'} alignItems="center" justifyContent={'flex-end'} style={{ marginLeft: '10px' }}>
          <RHFCheckbox name={`isRecipe`} style={{ marginRight: '-15px' }} />
          Recipe
        </Stack>
      </Stack>
      <Stack>
        <Table size="small" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Size</TableCell>
              <TableCell align="center">Qty</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {foodMenuSizesLoading ? (
              <TableRow>
                <TableCell colSpan={2}>
                  <CustomLoadingScreen height="20vh" />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {fields.map((field, index) => (
                  <TableRow key={field.id}>
                    {foodMenuSizes?.[index]?.sizeName && <TableCell>{foodMenuSizes?.[index]?.sizeName}</TableCell>}
                    <TableCell>
                      <RHFTextField
                        control={control}
                        disabled={!watchIngredients}
                        name={`menuSizes.${index}.qtyUsed`}
                        size="small"
                        InputProps={{
                          endAdornment: <InputAdornment position="end">gm</InputAdornment>,
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        sx={{ width: { md: 100 } }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
      </Stack>
    </>
  );
}

export default FoodMenuComboIngredients;
