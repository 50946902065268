import { Autocomplete, Button, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import { RHFSelect, RHFTextField, RHFUploadAvatar } from 'components/hook-form';
import React, { useEffect, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Icon } from '@iconify/react';

import FoodCategoriesCreate from 'pages/dashboard/FoodCategoryAndSizes/FoodCategories/FoodCategoriesCreate';
import CustomMultipleAutoCompleteField from 'components/CustomComponents/CustomAutoCompleteField';
import { dispatch } from 'redux/store';
import { getFoodSizes } from 'redux/slices/FoodCategoriesAndSizeSlice';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import FoodMenuIngredientSearch from './FoodMenuIngredientSearch';

const MENU_TYPE = ['FOOD', 'DRINKS', 'PACKAGING', 'OTHERS'];

const NewFoodMenuSchema = Yup.object().shape({
  name: Yup.string().required(''),
  description: Yup.string(),
  menuType: Yup.string().required('').min(1, ''),
  categoryId: Yup.object().shape({
    name: Yup.string().required(),
  }),
});

function FoodMenuForm({ onSubmit, currentData = {}, isEdit = false }) {
  const [open, setOpen] = useState(false);
  const departmentId = useGetDepartmentId();
  const { categoryList } = useSelector((state) => state.foodCategoriesAndSize);
  const { postLoading } = useSelector((state) => state.foodMenus);

  const handleClose = () => setOpen(false);

  const defaultValues = {
    name: currentData?.name || '',
    description: currentData?.description || '',
    menuType: currentData?.menuType || '',
    ingredientsAdded: [],
    ingredients: [],
    ingredientsRemoved: [],
    categoryId: null,
  };
  const methods = useForm({ defaultValues, resolver: yupResolver(NewFoodMenuSchema), mode: 'onBlur' });
  const { handleSubmit, setValue, reset } = methods;

  useEffect(() => {
    if (!Object.keys(currentData).length) return;
    const currentDatas = {
      ...currentData,
      ingredientsAdded: [],
      ingredientsRemoved: [],
      categoryId: categoryList?.categories?.find((obj) => obj?.id === currentData?.categoryId),
    };
    reset(currentDatas);
  }, [reset, currentData, categoryList]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'file',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} id="formMenuUpdateForm">
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }} justifyContent={'center'} mb={2}>
            <RHFUploadAvatar
              name="file"
              onDrop={handleDrop}
              isEditImage={isEdit ? { isEdit, imgUrl: currentData?.imageUrl } : null}
              helperText={
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.secondary',
                  }}
                >
                  Upload Menu Image ( Allowed *.jpeg, *.jpg, *.png )
                  <br />
                </Typography>
              }
            />
          </Stack>
          <Stack spacing="1rem">
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={'1rem'}>
              <RHFTextField name="name" label="Name" />
              <RHFSelect name="menuType" label="Menu Type">
                {MENU_TYPE?.map((el) => (
                  <MenuItem key={el} value={el}>
                    {el}
                  </MenuItem>
                ))}
              </RHFSelect>
            </Stack>
            <RHFTextField name="description" label="Description" multiline minRows={2} />
          </Stack>

          <Stack my="1rem" direction="row" gap="2rem" justifyContent={'space-between'} alignItems={'center'}>
            <Stack flexGrow={1}>
              <CustomMultipleAutoCompleteField
                name="categoryId"
                label="Select Category"
                disabled={isEdit}
                options={categoryList?.categories}
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
              />
            </Stack>
            <Button
              variant="contained"
              size="medium"
              type="button"
              onClick={() => {
                dispatch(getFoodSizes({ departmentId }));
                setOpen(true);
              }}
            >
              <Icon icon="material-symbols:add" width={20} />
              Category
            </Button>
          </Stack>

          <FoodMenuIngredientSearch />

          <Stack alignItems={'flex-end'} mt="1rem">
            <Button variant="contained" type="button" onClick={handleSubmit(onSubmit)} disabled={postLoading}>
              {isEdit ? 'Update' : 'Create'}
            </Button>
          </Stack>
        </form>
      </FormProvider>
      {open && <FoodCategoriesCreate open={open} handleClose={handleClose} />}
    </>
  );
}

export default FoodMenuForm;
