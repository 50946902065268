import React, { useState } from 'react';
import { Avatar, Box, Button, Divider, Stack, Typography } from '@mui/material';

function FoodMenuMenusReportAccordion({ menu, categorySize }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Stack flexDirection="row" alignItems={'center'} my="0.5rem">
        <Stack
          alignItems="center"
          gap="0.5rem"
          flexDirection={'row'}
          my="0.15rem"
          p="0.4rem"
          borderRadius={'10px'}
          fontWeight={500}
        >
          <Stack>
            <Avatar alt="menu Image" src={menu?.imageUrl} />
          </Stack>
          <Stack>
            <p>
              <Button sx={{ color: 'black', fontWeight: '500', fontSize: '1rem' }} onClick={() => setOpen(true)}>
                {menu?.name}
              </Button>
            </p>
          </Stack>
        </Stack>
        <Stack className={'manageMenuSizeList'}>
          {categorySize?.map((categorySize, index) => {
            const categorySizeId = categorySize?.id;
            const findMenuSizeObj = menu?.sizesWithPrices?.find((menu) => menu?.sizeId === categorySizeId);
            if (!findMenuSizeObj) {
              return <>No</>;
            }
            return (
              <Box key={index} className="manageMenuSizeListPriceSettings">
                <Stack bgcolor="white" p="0.2rem" borderRadius={'10px'} width="85%" mr="0.7rem">
                  <Typography component={'p'} fontSize="0.8rem" borderBottom={'1px solid rgba(145, 158, 171, 0.24)'}>
                    $ {findMenuSizeObj?.saleAmt}
                  </Typography>
                  <Typography component={'p'} fontSize="0.8rem">
                    {findMenuSizeObj?.saleQty}
                  </Typography>
                </Stack>
              </Box>
            );
          })}
          {/* {menu?.sizesWithPrices?.map((size, index) => {
            return (
              <Box key={index} className="manageMenuSizeListPriceSettings">
                <Stack bgcolor="white" p="0.2rem" borderRadius={'10px'} width="85%" mr="0.7rem">
                  <Typography component={'p'} fontSize="0.8rem" borderBottom={'1px solid rgba(145, 158, 171, 0.24)'}>
                    $ {size?.saleAmt}
                  </Typography>
                  <Typography component={'p'} fontSize="0.8rem">
                    {size?.saleQty}
                  </Typography>
                </Stack>
              </Box>
            );
          })} */}
        </Stack>
      </Stack>
      <Divider />
    </>
  );
}

export default FoodMenuMenusReportAccordion;
