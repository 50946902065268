import { Box, Card, CardContent, CardHeader } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

QuickSummaryClockInIndividualCard.propTypes = {
  data: PropTypes.object,

  userInfo: PropTypes.object,
};

function QuickSummaryClockInIndividualCard({ data, userInfo }) {
  const { role } = useSelector((state) => state.userRole);

  const cardColor = role?.find((role) => role?.id === userInfo?.roleIds[0])?.colorCode;
  return (
    <Card
      sx={{
        marginBottom: '0.1rem',
        marginLeft: 'auto',
        marginRight: 'auto',
        // background: `${cardColor}`,
        background: `black`,
        borderRadius: '5px',
        width: '120px',
      }}
      className="individualRoasterCardContainer"
    >
      <Box style={{ display: 'flex', flexDirection: 'column' }}>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          <Box style={{ width: '50%' }}>
            <p style={{ color: 'white', textAlign: 'center', fontSize: '0.7rem' }}>$ {data?.pay}</p>
          </Box>
          <Box style={{ width: '50%' }}>
            <p style={{ color: 'white', textAlign: 'center', fontSize: '0.7rem' }}>{`${Math.floor(
              data?.minutes / 60
            )}H ${Math.floor(data?.minutes % 60)}M`}</p>
          </Box>
        </Box>
        <Box>
          <p style={{ padding: '0', color: 'white', textAlign: 'center', fontSize: '0.7rem' }}>
            <span style={{ fontWeight: '600', fontSize: '0.7rem' }}>
              {' '}
              {moment(data?.clockInTime).format('hh:mm A')}
            </span>
            {' - '}
            <span style={{ fontWeight: '600', fontSize: '0.7rem' }}>
              {' '}
              {moment(data?.clockOutTime).format('hh:mm A')}
            </span>
          </p>
        </Box>
      </Box>
      {/* <CardHeader
        avatar={
          <Box
            style={{
              fontSize: '0.85rem',
              paddingInline: '0.1rem',
              marginTop: '0.1rem',
              borderRadius: '5px',
              fontWeight: '600',
              color: 'white',
              display: 'flex',
            }}
            // className="headingTopics"
          >
            <Box style={{ width: '40%' }}>$ {data?.pay}</Box>
            <Box style={{ width: '40%' }}>{`${Math.floor(data?.minutes / 60)}H ${Math.floor(
              data?.minutes % 60
            )}M`}</Box>
          </Box>
        }
        sx={{ padding: '0.2rem', color: 'white' }}
        className="individualRoasterCardHeaders"
      /> */}
      {/* <CardContent className="individualRoasterCardContent" style={{ color: 'white' }}>
        <p style={{ marginTop: '-15px', padding: '0' }}>
          <span style={{ fontWeight: '600' }}> {moment(data?.clockInTime).format('hh:mm A')}</span>
          {' - '}
          <span style={{ fontWeight: '600' }}> {moment(data?.clockOutTime).format('hh:mm A')}</span>
        </p>
      </CardContent> */}
    </Card>
  );
}

export default QuickSummaryClockInIndividualCard;
