import { Box, Card, Typography } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryCogsReport } from 'redux/slices/menuEngineeringSlice';
import FoodCategoryCogsReportItem from './FoodCategoryCogsReportItem';

function FoodCategoryCogsReport({ foodCategory }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const { categoryCogsLoading, categoryCogsReport } = useSelector((state) => state.menuEngineering);

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getCategoryCogsReport({ departmentId, categoryId: foodCategory.id }));
  }, [dispatch, departmentId, foodCategory]);

  return (
    <Box style={{ padding: '5px' }}>
      {categoryCogsLoading ? (
        <CustomLoadingScreen height="30vh" />
      ) : (
        <>
          {categoryCogsReport?.map((item, index) => (
            <FoodCategoryCogsReportItem key={index} item={item} />
          ))}
        </>
      )}
    </Box>
  );
}

export default FoodCategoryCogsReport;
