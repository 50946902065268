import { Box, Button } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
// import Iconify from 'components/Iconify';
import React from 'react';
import { useSelector } from 'react-redux';
import { PATH_DASHBOARD } from 'routes/paths';
// import { PATH_DASHBOARD } from 'routes/paths';
// import ViewStoreEventsFromRoaster from 'sections/@dashboard/roaster/ViewStoreEventsFromRoaster';
// import { styled } from '@mui/material/styles';

function RoasterHeaders() {
  const { postLoading, allRoaster, isPreviousDate } = useSelector((state) => state.roaster);
  // const { zones, loading: zoneLoading } = useSelector((state) => state.store);
  // const isEmpty = zones?.id === undefined;
  // const { weeklyPercentage } = useSelector((state) => state.roaster);
  // const [openEventsView, setOpenEventsView] = React.useState(false);

  return (
    <>
      <HeaderBreadcrumbs
        style={{ marginBottom: '0px' }}
        heading="Roster"
        // just to prevent page crash though it doesn't appear on screen
        links={[
          <Button
            key={1}
            variant="contained"
            color="primary"
            startIcon={<Iconify icon={'mdi:eye'} />}
            // onClick={() => setOpenEventsView(true)}
            className="roasterViewEventButton"
          >
            Events
          </Button>,
          // Actual link code if required for breadcumbs

          // { name: 'Dashboard', href: PATH_DASHBOARD.root },
          // { name: 'Roster', href: PATH_DASHBOARD.roaster.root },
          // { name: 'List' },
        ]}
        action={
          <Box className="invoiceListHeadingButtons">
            <Button
              variant="text"
              id="roasterSaveDraft"
              color="error"
              startIcon={<Iconify icon={'carbon:license-draft'} />}
              disabled={allRoaster?.isPublished || isPreviousDate}
              type="reset"
              form="roasterSubmit"
            >
              Save Draft
            </Button>
            <Button
              id="roasterPublish"
              variant="contained"
              startIcon={<Iconify icon={'eva:plus-fill'} />}
              type="submit"
              form="roasterSubmit"
              disabled={postLoading || isPreviousDate}
            >
              {postLoading ? <CustomLoadingScreen circularWidth={'20px'} color="secondary" /> : 'Publish'}
            </Button>
          </Box>
        }
      />

      {/* View Events Button */}
      {/* {allRoaster?.events?.length > 0 && (
        <Stack alignItems={'flex-start'} className="roasterViewEventButton">
          <Button
            variant="text"
            color="primary"
            startIcon={<Iconify icon={'mdi:eye'} />}
            onClick={() => setOpenEventsView(true)}
          >
            Events
          </Button>
        </Stack>
      )}
      {openEventsView && (
        <ViewStoreEventsFromRoaster open={openEventsView} handleClose={() => setOpenEventsView(false)} />
      )} */}
    </>
  );
}

export default RoasterHeaders;
