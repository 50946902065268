import { Box } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import React, { useState } from 'react';
import { PATH_DASHBOARD } from 'routes/paths';
import FoodMenuSalesBody from 'sections/@dashboard/sales/menuSales/FoodMenuSalesBody';
import FoodMenuSalesFilter from 'sections/@dashboard/sales/menuSales/FoodMenuSalesFilter';

export default function MenuSalesDetails() {
  const [choosenDate, setChoosenDate] = useState(new Date());
  return (
    <Page title="Menu Sales Details">
      <HeaderBreadcrumbs
        heading="Item Sales Details"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'sales', href: PATH_DASHBOARD.sales.list },
          // { name: 'menu sales list' },
        ]}
      />
      <Box>
        <FoodMenuSalesFilter choosenDate={choosenDate} setChoosenDate={setChoosenDate} />
        <FoodMenuSalesBody choosenDate={choosenDate} setChoosenDate={setChoosenDate} />
      </Box>
    </Page>
  );
}
