import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React from 'react';
import { updateStock } from 'redux/slices/stockSlice';
import { dispatch } from 'redux/store';
import StockAddForm from 'sections/@dashboard/stocks/StockAddForm';

function StockEdit({ open, setOpen, currentData }) {
  const departmentId = useGetDepartmentId();

  const updateStockHandler = (data) => {
    const weight = data?.unitWeight || 0 * data?.currentQty || 0;

    const finalData = { ...data, departmentId, weight };

    dispatch(updateStock({ finalData, setOpen }));
  };
  return (
    <CustomDrawer title="Edit Stock" open={open} setOpen={setOpen}>
      <StockAddForm onSubmit={updateStockHandler} currentData={currentData} isUpdate />
    </CustomDrawer>
  );
}

export default StockEdit;
