import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import { errorToastTime } from 'config';

export const getMostLikedMenus = createAsyncThunk('mostLikedMenus/getMostLikedMenus', async (props) => {
  try {
    const response = await apiURL.get(`/menuER/mostLikedMenus/${props?.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const reorderMostLikedMenus = createAsyncThunk(
  'mostLikedMenus/reorderMostLikedMenus',
  async (props, thunkAPI) => {
    try {
      const response = await apiURL.put(`/menuER/order/mostLikedMenus`, props?.finalData);
      if (response.status === 200) {
        thunkAPI.dispatch(getMostLikedMenus({ departmentId: props?.departmentId }));
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const updateMostLikedMenus = createAsyncThunk('mostLikedMenus/updateMostLikedMenus', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/menuER/mostLikedMenus`, props?.finalData);
    if (response.status === 200) {
      toast.success('Most Liked Menus Updated Successfully', { autoClose: errorToastTime });
      props?.setOpen(false);
      thunkAPI.dispatch(getMostLikedMenus({ departmentId: props?.departmentId }));
    }
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getRecommendedMenus = createAsyncThunk('mostLikedMenus/getRecommendedMenus', async (props) => {
  try {
    const response = await apiURL.get(`/menuER/recommendedMenus/${props?.departmentId}/${props?.categoryId}`);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const reOrderRecommendedMenus = createAsyncThunk(
  'mostLikedMenus/reOrderRecommendedMenus',
  async (props, thunkAPI) => {
    try {
      const response = await apiURL.put(`/menuER/order/recommendedMenus`, props?.finalData);
      if (response.status === 200) {
        toast.success('Recommended Menus Re-Ordered', { autoClose: errorToastTime });
        if (props?.categoryId === null) {
          thunkAPI.dispatch(getDefaultRecommendedMenus({ departmentId: props?.departmentId }));
        } else {
          thunkAPI.dispatch(getRecommendedMenus({ departmentId: props?.departmentId, categoryId: props?.categoryId }));
        }
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const getDefaultRecommendedMenus = createAsyncThunk(
  'mostLikedMenus/getDefaultRecommendedMenus',
  async (props) => {
    try {
      const response = await apiURL.get(`/menuER/defaultRecommendedMenus/${props?.departmentId}`);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const updateRecommendedMenus = createAsyncThunk(
  'mostLikedMenus/updateRecommendedMenus',
  async (props, thunkAPI) => {
    try {
      const response = await apiURL.post(`/menuER/recommendedMenu`, props?.finalData);
      if (response.status === 200) {
        toast.success('Recommended Menus Updated Successfully', { autoClose: errorToastTime });
        props?.setOpen(false);
        if (props?.categoryId === null) {
          thunkAPI.dispatch(getDefaultRecommendedMenus({ departmentId: props?.departmentId }));
        } else {
          thunkAPI.dispatch(getRecommendedMenus({ departmentId: props?.departmentId, categoryId: props?.categoryId }));
        }
      }
      return response.data;
    } catch (error) {
      toast.error(error.response.data.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

const mostLikedMenusSlice = createSlice({
  name: 'mostLikedMenus',
  initialState: {
    mostLikedMenus: [],
    recommendedMenus: [],
    loading: false,

    recommendedMenuCurrentTab: null,
  },
  reducers: {
    setRecommendedMenuCurrentTab: (state, action) => {
      state.recommendedMenuCurrentTab = action.payload;
    },
  },
  extraReducers: {
    [getMostLikedMenus.pending]: (state) => {
      state.loading = true;
      state.mostLikedMenus = [];
    },
    [getMostLikedMenus.fulfilled]: (state, action) => {
      state.mostLikedMenus = action.payload;
      state.loading = false;
    },
    [getMostLikedMenus.rejected]: (state) => {
      state.mostLikedMenus = [];
      state.loading = false;
    },

    [getRecommendedMenus.pending]: (state) => {
      state.loading = true;
      state.recommendedMenus = [];
    },
    [getRecommendedMenus.fulfilled]: (state, action) => {
      state.recommendedMenus = action.payload;
      state.loading = false;
    },
    [getRecommendedMenus.rejected]: (state) => {
      state.recommendedMenus = [];
      state.loading = false;
    },
    [getDefaultRecommendedMenus.pending]: (state) => {
      state.loading = true;
      state.recommendedMenus = [];
    },
    [getDefaultRecommendedMenus.fulfilled]: (state, action) => {
      state.recommendedMenus = action.payload;
      state.loading = false;
    },
    [getDefaultRecommendedMenus.rejected]: (state) => {
      state.recommendedMenus = [];
      state.loading = false;
    },
  },
});

export const { setRecommendedMenuCurrentTab } = mostLikedMenusSlice.actions;

export default mostLikedMenusSlice.reducer;
