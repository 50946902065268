import { TableCell } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import QuickSummaryRoasterIndividualCard from './QuickSummaryRoasterIndividualCard';

QuickSummaryRoasterIndividualTableCell.propTypes = {
  roasterReportKey: PropTypes.string,
  mainIndex: PropTypes.number,
  userInfo: PropTypes.object,
};

function QuickSummaryRoasterIndividualTableCell({ roasterReportKey, mainIndex, userInfo }) {
  const { control } = useFormContext();
  const watch = useWatch({ control, name: `users.${mainIndex}.userShiftsInDates.${roasterReportKey}` });

  return (
    <>
      {!watch?.length ? (
        <TableCell />
      ) : (
        <>
          <TableCell style={{ padding: '5px' }}>
            {watch?.map((roasterReport, index) => (
              <QuickSummaryRoasterIndividualCard
                key={index}
                data={roasterReport}
                mainIndex={mainIndex}
                roasterReportKey={roasterReportKey}
                userInfo={userInfo}
              />
            ))}
          </TableCell>
        </>
      )}
    </>
  );
}

export default QuickSummaryRoasterIndividualTableCell;
