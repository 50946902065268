import { Accordion, AccordionDetails, AccordionSummary, Box, Stack } from '@mui/material';
import Iconify from 'components/Iconify';
import React from 'react';
import MostLikedFoodMenuAccordionItem from './MostLikedFoodMenuAccordionItem';

function MostLikedFoodMenuAccordion({
  category,
  mainIndex,
  addAppend,
  addRemove,
  removeAppend,
  removeRemove,
  addFields,
  removeFields,
}) {
  return (
    <Accordion sx={{ paddingBlock: '0.1rem' }} defaultExpanded>
      <AccordionSummary
        expandIcon={<Iconify icon="ic:outline-expand-more" width="25px" />}
        aria-controls="Menu with Categories"
        id={category?.id}
        style={{ height: '3.5rem', borderBottom: '0.5px solid rgba(145, 158, 171, 0.24)' }}
      >
        <Stack direction={'row'} justifyContent={'space-between'} width="100%">
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Stack>
              <p style={{ fontWeight: '600' }}>{category?.name}</p>
              <p style={{ color: '#637381' }}>{category?.menus?.length} Items</p>
            </Stack>
          </Stack>
          <Stack className={'manageMenuSizeList'}>
            <Box className="manageItemsOfMenuSizeList manageItemsOfMenuSizeListColor " style={{ width: '130px' }}>
              Is Most Liked
            </Box>
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails className="FoodMenuAccoriansDetails">
        {category?.menus?.map((menu, index) => (
          <MostLikedFoodMenuAccordionItem
            key={menu?.id}
            menu={menu}
            index={index}
            mainIndex={mainIndex}
            addAppend={addAppend}
            addRemove={addRemove}
            removeAppend={removeAppend}
            removeRemove={removeRemove}
            addFields={addFields}
            removeFields={removeFields}
          />
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default MostLikedFoodMenuAccordion;
