import { TableBody, TableRow } from '@mui/material';
import { TableNoData } from 'components/table';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import QuickSummaryRoasterTableCellWithAvatar from './QuickSummaryRoasterTableCellWithAvatar';
import QuickSummaryRoasterIndividualTableRow from './QuickSummaryRoasterIndividualTableRow';

function QuickSummaryRoasterTableBody() {
  const { loading } = useSelector((state) => state.roaster);

  const { control } = useFormContext();
  const { fields } = useFieldArray({
    name: `users`,
    keyName: 'usersID',
    control,
  });

  return (
    <>
      {loading ? (
        ''
      ) : (
        <>
          <TableBody className="roasterTableBodyContainer">
            {fields?.length > 0 ? (
              <>
                {fields?.map((user, mainIndex) => (
                  // each user data
                  <TableRow key={mainIndex}>
                    <QuickSummaryRoasterTableCellWithAvatar user={user} mainIndex={mainIndex} />
                    <QuickSummaryRoasterIndividualTableRow user={user} mainIndex={mainIndex} />
                  </TableRow>
                ))}
              </>
            ) : (
              <TableNoData isNotFound={fields?.length <= 0} />
            )}
          </TableBody>
        </>
      )}
    </>
  );
}

export default QuickSummaryRoasterTableBody;
