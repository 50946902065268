import { Box } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import React from 'react';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import FoodCategoryForm from './FoodCategoryForm';

function FoodCategoryCreate({ open, handleClose }) {
  return (
    <CustomOutletModal open={open} zIndex="1600" title="Create Food Category">
      <FoodCategoryForm handleClose={handleClose} />
    </CustomOutletModal>
    //  <CustomDrawer
    //    open={open}
    //    setOpen={handleClose}
    //    title={`Create Food Category`}
    //    PaperProps={{ sx: { innerWidth: '50%' } }}
    //  >
    //    <Box style={{ width: '600px' }}>
    //    </Box>
    //  </CustomDrawer>
  );
}

export default FoodCategoryCreate;
