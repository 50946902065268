import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import { errorToastTime, successToastTime } from 'config';

export const getDeliveryZone = createAsyncThunk('deliveryZone/getDeliveryZone', async () => {
  try {
    const response = await apiURL.get(`/pos/deliveryZones`);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteDeliveryZone = createAsyncThunk('deliveryZone/deleteDeliveryZone', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/pos/deliveryZone/${props?.id}`);
    if (response.status === 200) {
      toast.success('Delivery zone deleted successfully', { autoClose: successToastTime });
      props?.isSuccess(true);
      thunkAPI.dispatch(getDeliveryZone());
    }
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createDeliveryZone = createAsyncThunk('deliveryZone/createDeliveryZone', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/pos/deliveryZone`, props?.data);
    if (response.status === 200) {
      // eslint-disable-next-line no-unused-expressions
      props?.isEdit
        ? toast.success('Delivery zone updated successfully', { autoClose: successToastTime })
        : toast.success('Delivery zone created successfully', { autoClose: successToastTime });
      props?.handleClose();
      thunkAPI.dispatch(getDeliveryZone());
    }
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getSuburb = createAsyncThunk('suburb/getSuburb', async () => {
  try {
    const response = await apiURL.get(`/pos/suburbs`);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createSuburb = createAsyncThunk('suburb/createSuburb', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/pos/suburb`, props?.finalData);
    if (response.status === 200) {
      // eslint-disable-next-line no-unused-expressions
      props?.isEdit
        ? toast.success('Suburb updated successfully', { autoClose: successToastTime })
        : toast.success('Suburb created successfully', { autoClose: successToastTime });
      props?.handleClose();
      thunkAPI.dispatch(getSuburb());
    }
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteSingleSuburb = createAsyncThunk('suburb/deleteSingleSuburb', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/pos/suburb/${props?.id}`);
    if (response.status === 200) {
      toast.success('Suburb deleted successfully', { autoClose: successToastTime });
      props?.isSuccess(true);
      thunkAPI.dispatch(getSuburb());
    }
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

// export const getStateInStore = createAsyncThunk('suburb/getStateInStore', async () => {
//   try {
//     const response = await apiURL.get(`/pos/statesInStore`);
//     return response.data;
//   } catch (error) {
//     toast.error(error.response.data.message, { autoClose: errorToastTime });
//     throw error;
//   }
// });

export const citiesInStore = createAsyncThunk('suburb/citiesInStore', async (props) => {
  try {
    const response = await apiURL.get(`/pos/allCitiesInStore`);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getStreetList = createAsyncThunk('street/getStreetList', async (props) => {
  try {
    const response = await apiURL.get(`/pos/streets/${props?.suburbId}`);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createNewStreet = createAsyncThunk('street/createNewStreet', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/pos/street`, props?.finalData);
    if (response.status === 200) {
      // eslint-disable-next-line no-unused-expressions
      props?.isEdit
        ? toast.success('Street updated successfully', { autoClose: successToastTime })
        : toast.success('Street created successfully', { autoClose: successToastTime });
      props?.handleClose();
      thunkAPI.dispatch(getStreetList({ suburbId: props?.finalData?.suburbId }));
    }
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteSingleStreet = createAsyncThunk('street/deleteSingleStreet', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/pos/street/${props?.suburbId}/${props?.id}`);
    if (response.status === 200) {
      toast.success('Street deleted successfully', { autoClose: successToastTime });
      props?.isSuccess(true);
      thunkAPI.dispatch(getStreetList({ suburbId: props?.suburbId }));
    }
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

const posConfigSlice = createSlice({
  name: 'posConfig',
  initialState: {
    loading: false,
    postLoading: false,
    deliveryZoneData: [],

    suburbsData: [],
    suburbLoading: false,

    storeStates: [],
    storeCities: [],

    streetList: [],
    streetLoading: false,

    currentTab: 0,
  },
  reducers: {
    changePosConfigCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
  },
  extraReducers: {
    [getDeliveryZone.pending]: (state) => {
      state.loading = true;
      state.deliveryZoneData = [];
    },
    [getDeliveryZone.fulfilled]: (state, action) => {
      state.loading = false;
      state.deliveryZoneData = action.payload;
    },
    [getDeliveryZone.rejected]: (state) => {
      state.loading = false;
      state.deliveryZoneData = [];
    },

    [createDeliveryZone.pending]: (state) => {
      state.postLoading = true;
    },
    [createDeliveryZone.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [createDeliveryZone.rejected]: (state) => {
      state.postLoading = false;
    },

    [getSuburb.pending]: (state) => {
      state.suburbLoading = true;
      state.suburbsData = [];
    },
    [getSuburb.fulfilled]: (state, action) => {
      state.suburbLoading = false;
      state.suburbsData = action.payload;
    },
    [getSuburb.rejected]: (state) => {
      state.suburbLoading = false;
      state.suburbsData = [];
    },

    [createSuburb.pending]: (state) => {
      state.postLoading = true;
    },
    [createSuburb.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [createSuburb.rejected]: (state) => {
      state.postLoading = false;
    },

    // [getStateInStore.pending]: (state) => {
    //   state.storeStates = [];
    //   state.stateLoading = true;
    // },
    // [getStateInStore.fulfilled]: (state, action) => {
    //   state.stateLoading = false;
    //   state.storeStates = action.payload;
    // },
    // [getStateInStore.rejected]: (state) => {
    //   state.storeStates = [];
    //   state.stateLoading = false;
    // },

    [citiesInStore.pending]: (state) => {
      state.storeCities = [];
    },
    [citiesInStore.fulfilled]: (state, action) => {
      state.storeCities = action.payload;
    },
    [citiesInStore.rejected]: (state) => {
      state.storeCities = [];
    },

    [getStreetList.pending]: (state) => {
      state.streetLoading = true;
      state.streetList = [];
    },
    [getStreetList.fulfilled]: (state, action) => {
      state.streetLoading = false;
      state.streetList = action.payload;
    },
    [getStreetList.rejected]: (state) => {
      state.streetLoading = false;
      state.streetList = [];
    },
  },
});

export const { changePosConfigCurrentTab } = posConfigSlice.actions;

export default posConfigSlice.reducer;
