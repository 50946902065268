import { Avatar, Button, Card, CardContent, CardHeader, IconButton, MenuItem, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useSelector } from 'redux/store';
import { TableMoreMenu } from 'components/table';
import Iconify from 'components/Iconify';
import moment from 'moment';
import { findTotalPriceofRoaster, getTwoTimesDifference } from 'utils/roasterFeatureUtils';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/system';
import { useDrag } from 'react-dnd';

IndividualRoasterCard.propTypes = {
  data: PropTypes.object,
  cardFieldIndex: PropTypes.number,
};

function IndividualRoasterCard({
  data,
  userInformation,
  mainIndex,
  roasterKey,
  cardFieldIndex,
  remove,
  onUpdate,
  openCreateRoasterModal,
}) {
  const [openMenu, setOpenMenuActions] = useState(null);
  const { allRoaster, isPreviousDate } = useSelector((state) => state.roaster);
  const { setValue, register, getValues } = useFormContext();

  const [{ isDragging }, dragRef] = useDrag({
    type: 'individualRoasterCard',
    item: {
      data: {
        breakMins: data?.breakMins,
        date: data?.date,
        endDateTime: data?.endDateTime,
        forecastPay: data?.forecastPay,
        note: data?.note,
        startDateTime: data?.startDateTime,
        timeDifference: data?.timeDifference,
        timeWithoutBreak: data?.timeWithoutBreak,
        TotalPriceofRoaster: data?.TotalPriceofRoaster,
      },
      roleId: data?.roleId,
      cardFieldIndex,
    },
    canDrag: !isPreviousDate,

    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const cardColor = userInformation?.roles?.find((obj) => obj.id === Number(data.roleId))?.colorCode;

  const onDeleteRow = () => {
    if (isPreviousDate) return;
    if (data?.id) {
      const getData = getValues('removedShiftIds');
      setValue('removedShiftIds', [...getData, data.id]);
    }
    remove(cardFieldIndex);
  };
  const onEditRow = () => {
    onUpdate(cardFieldIndex, data);
  };
  const timeDifference = getTwoTimesDifference(data?.endDateTime, data?.startDateTime);

  const timeWithoutBreak = data?.breakMins
    ? timeDifference?.asMilliseconds() - data?.breakMins * 60 * 1000
    : timeDifference?.asMilliseconds();
  // const timeWithoutBreak = 0;
  const TotalPriceofRoaster = findTotalPriceofRoaster(data, allRoaster?.holidayDates, userInformation);
  // const TotalPriceofRoaster = 0;

  useEffect(() => {
    setValue(`users.${mainIndex}.userShiftsInDates.${roasterKey}.${cardFieldIndex}.timeDifference`, timeDifference);
    setValue(`users.${mainIndex}.userShiftsInDates.${roasterKey}.${cardFieldIndex}.timeWithoutBreak`, timeWithoutBreak);
    setValue(
      `users.${mainIndex}.userShiftsInDates.${roasterKey}.${cardFieldIndex}.forecastPay`,
      Number(TotalPriceofRoaster)
    );
    setValue(
      `users.${mainIndex}.userShiftsInDates.${roasterKey}.${cardFieldIndex}.totalPriceofRoaster`,
      Number(TotalPriceofRoaster)
    );
  }, [register, setValue]);

  const findWorkTime = (time) => {
    const hours = Math.floor(time / 1000 / 60 / 60);
    const minutes = Math.ceil((time / 1000 / 60) % 60);
    if (minutes > 59) return `${hours + 1}H 0M`;
    return `${hours}H ${minutes}M`;
  };

  return (
    <Card
      sx={{ marginBottom: '0.1rem', background: `${cardColor}`, border: `${isDragging ? '2px solid black' : ''}` }}
      className="individualRoasterCardContainer"
      ref={dragRef}
    >
      <CardHeader
        avatar={
          <p
            style={{
              fontSize: '0.85rem',
              paddingInline: '0.1rem',
              marginTop: '0.1rem',
              borderRadius: '5px',
              fontWeight: '600',
            }}
            className="headingTopics"
          >
            <span>
              $ {TotalPriceofRoaster}
              {', '} {findWorkTime(timeWithoutBreak)}
            </span>
          </p>
        }
        action={
          <>
            {!isPreviousDate && (
              <TableMoreMenu
                open={openMenu}
                onOpen={handleOpenMenu}
                onClose={handleCloseMenu}
                actions={
                  <>
                    <MenuItem
                      onClick={() => {
                        onEditRow();
                        handleCloseMenu();
                      }}
                    >
                      <Iconify icon={'eva:edit-fill'} />
                      Edit
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        onDeleteRow();
                        handleCloseMenu();
                      }}
                      sx={{ color: 'error.main' }}
                    >
                      <Iconify icon={'eva:trash-2-outline'} />
                      Delete
                    </MenuItem>
                  </>
                }
              />
            )}
          </>
        }
        sx={{ padding: '0.2rem', color: 'white' }}
        className="individualRoasterCardHeaders"
      />
      <CardContent className="individualRoasterCardContent" style={{ color: 'black' }}>
        <p style={{ marginTop: '-15px', padding: '0' }}>
          <span style={{ fontWeight: '600' }}> {moment(data.startDateTime).format('h:mm A')}</span>
          {' - '}
          <span style={{ fontWeight: '600' }}> {moment(data.endDateTime).format('h:mm A')}</span>
        </p>
        <div style={{ padding: '0', display: 'flex', marginBottom: '-4px' }}>
          <Tooltip title="Break">
            <Box className="individualRoasterClockIcon">
              <Icon icon="material-symbols:nest-clock-farsight-analog-outline" width="15" />
            </Box>
          </Tooltip>
          <p style={{ display: 'inline-flex', fontWeight: '600', marginLeft: '2px' }}>
            {data?.breakMins ? data?.breakMins : 0} M{' '}
          </p>
          {data?.note && (
            <Tooltip title={data.note}>
              <Box className="individualRoasterNoteIcon">
                <Icon icon="material-symbols:speaker-notes" width="15" />
              </Box>
            </Tooltip>
          )}
        </div>
        {!isPreviousDate && (
          <Box className="roasterCardNewButton">
            <Icon
              icon="material-symbols:add-card-outline"
              width="18"
              style={{ cursor: 'pointer' }}
              onClick={openCreateRoasterModal}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

export default IndividualRoasterCard;
