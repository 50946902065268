import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';

// for categories
export const getFoodCategories = createAsyncThunk('foodConf/getFoodCategories', async (props) => {
  try {
    let pageNoString;
    if (props?.pageNo) {
      pageNoString = `?pageNo=${props.pageNo - 1}&pageSize=${props.pageSize || 25}`;
    } else {
      pageNoString = '';
    }

    const response = await apiURL.get(`/foodConf/category/${props.departmentId}${pageNoString}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message);
    throw error;
  }
});

export const createFoodCategories = createAsyncThunk('foodConf/createFoodCateogies', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/foodConf/category`, props.finalData);
    if (response.status === 200) {
      toast.success('Food Categories created Successfully.');
      props.handleClose();
    }
    thunkAPI.dispatch(getFoodCategories({ departmentId: props.finalData?.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message);
    throw error;
  }
});

export const updateFoodCategories = createAsyncThunk('foodConf/updateFoodCategories', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put(`/foodConf/category`, props.finalData);
    if (response.status === 200) {
      toast.success('Food Categories Updated Successfully.');
      props.handleClose();
    }
    thunkAPI.dispatch(getFoodCategories({ departmentId: props.finalData?.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message);
    throw error;
  }
});

export const deleteFoodCategories = createAsyncThunk('foodConf/deleteFoodCategories', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/foodConf/category/${props.departmentId}/${props.id}`);
    if (response.status === 200) {
      toast.success('Food Categories Deleted Successfully.');
    }
    thunkAPI.dispatch(getFoodCategories({ departmentId: props?.departmentId, pageNo: 1 }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message);
    throw error;
  }
});

// for food
export const getFoodSizes = createAsyncThunk('foodConf/getFoodSizes', async (props) => {
  try {
    let pageNoString;
    if (props?.pageNo) {
      pageNoString = `?pageNo=${props.pageNo - 1}&pageSize=${props.pageSize || 25}`;
    } else {
      pageNoString = '';
    }

    const response = await apiURL.get(`/foodConf/size/${props.departmentId}${pageNoString}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message);
    throw error;
  }
});

export const createFoodSize = createAsyncThunk('foodConf/createFoodSize', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/foodConf/size`, props.finalData);
    if (response.status === 200) {
      toast.success('Food Size created Successfully.');
      props.handleClose();
    }
    thunkAPI.dispatch(getFoodSizes({ departmentId: props.finalData?.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message);
    throw error;
  }
});

export const updateFoodSize = createAsyncThunk('foodConf/updateFoodSize', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put(`/foodConf/size`, props.finalData);
    if (response.status === 200) {
      toast.success('Food Size Updated Successfully.');
      props.handleClose();
    }
    thunkAPI.dispatch(getFoodSizes({ departmentId: props.finalData?.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message);
    throw error;
  }
});

export const deleteFoodSizes = createAsyncThunk('foodConf/deleteFoodSizes', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/foodConf/size/${props.departmentId}/${props.id}`);
    if (response.status === 200) {
      toast.success('Food Size Deleted Successfully.');
    }
    thunkAPI.dispatch(getFoodSizes({ departmentId: props?.departmentId, pageNo: 1 }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message);
    throw error;
  }
});

const FoodCategoriesAndSizes = createSlice({
  name: 'foodCategoriesAndSizes',
  initialState: {
    categoryList: [],
    sizeList: {},
    currentTab: 0,
    categoryDetails: {},
    sizeDetails: {},
    loading: false,
    postLoading: false,
  },

  reducers: {
    changeFoodCurrentTab(state, action) {
      state.currentTab = action.payload;
    },
  },

  extraReducers: {
    [getFoodSizes.pending]: (state) => {
      state.loading = true;
    },
    [getFoodSizes.fulfilled]: (state, action) => {
      state.loading = false;
      state.sizeList = action.payload;
    },
    [getFoodSizes.rejected]: (state) => {
      state.loading = false;
    },

    [getFoodCategories.pending]: (state) => {
      state.loading = true;
    },
    [getFoodCategories.fulfilled]: (state, action) => {
      state.loading = false;
      state.categoryList = action.payload;
    },
    [getFoodCategories.rejected]: (state) => {
      state.loading = false;
    },
  },
});
export const { changeFoodCurrentTab } = FoodCategoriesAndSizes.actions;

export default FoodCategoriesAndSizes.reducer;
