import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Iconify from 'components/Iconify';
import { TableHeadCustom, TableNoData } from 'components/table';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
// import { useForm } from 'react-hook-form';
// import { getDepartOfStore } from 'redux/slices/storeSlice';
import { getShiftRequest, getStoreDate } from 'redux/slices/shiftRequestSlice';
import { toast } from 'react-toastify';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import useGetDepartmentId from 'hooks/useGetDepartmentId';

const TABLE_HEAD = [
  { id: 'requestor', label: 'Picked By', align: 'left' },
  { id: 'phone', label: 'Phone', align: 'left' },
  // { id: 'actual', label: 'Dropped By', align: 'left' },
  // { id: 'contact', label: 'Phone', align: 'left' },
  { id: 'time', label: 'Shift Time', align: 'left' },
  { id: 'note', label: 'Note', align: 'left' },
  { id: 'approvedBy', label: 'Approved By', align: 'left' },
];
export default function ShiftApproveTable() {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  //   getting store date
  const { timeDT } = useSelector((state) => state.user);

  // getting approved shift request data
  const { shiftRequest, loading: shiftRequestLoading } = useSelector((state) => state.ShiftRequest);

  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [pageNo, setPageNo] = useState(1);

  const fromDateChange = (date) => {
    setFromDate(moment(date).format('YYYY-MM-DD'));
  };
  const toDateChange = (date) => {
    setToDate(moment(date).format('YYYY-MM-DD'));
  };

  useEffect(() => {
    if (timeDT) {
      setFromDate(moment(timeDT).add(-5, 'days').format('YYYY-MM-DD'));
      setToDate(moment(timeDT).add(10, 'days').format('YYYY-MM-DD'));
    }
  }, [timeDT]);
  useEffect(() => {
    if (!departmentId) return;
    if (!fromDate) return;
    if (!toDate) return;
    if (fromDate && toDate) {
      const diffDays = moment(toDate).diff(moment(fromDate), 'days');
      if (fromDate > toDate) {
        toast.error('From date should be less than to date');
      } else if (diffDays > 30) {
        toast.error('From date and to date should be within 30 days');
      } else {
        dispatch(
          getShiftRequest({
            departmentId,
            startDate: moment(fromDate).format('YYYY-MM-DD'),
            endDate: moment(toDate).format('YYYY-MM-DD'),
            statusList: ['APPROVED'],
            typeList: ['PICKUP'],
          })
        );
      }
    }
  }, [fromDate, toDate, departmentId, dispatch]);

  return (
    <>
      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
        {/* From Date */}
        <DatePicker
          // disablePast
          label="From Date"
          value={fromDate}
          inputFormat="dd/MMM/yyyy"
          onChange={fromDateChange}
          renderInput={(params) => <TextField {...params} error={false} />}
        />

        {/* To Date */}
        <DatePicker
          // disablePast
          label="To Date"
          value={toDate}
          inputFormat="dd/MMM/yyyy"
          onChange={toDateChange}
          renderInput={(params) => <TextField {...params} error={false} />}
        />
      </Stack>

      <Table size="small" className="shiftRequestTable">
        <TableHeadCustom headLabel={TABLE_HEAD} />
        <TableBody>
          {shiftRequestLoading ? (
            <TableRow>
              <TableCell colSpan={5}>
                <CustomLoadingScreen height={'20vh'} />
              </TableCell>
            </TableRow>
          ) : (
            shiftRequest?.shiftRequests?.map((item) => (
              <TableRow hover key={item.id}>
                {/* Requestor */}
                <TableCell>
                  <div style={{ display: 'flex' }}>
                    {item?.requestor?.thumbnailURL ? (
                      <Avatar
                        src={item.requestor?.thumbnailURL}
                        style={{
                          height: '30px',
                          width: '30px',
                          borderRadius: '100px',
                          marginRight: '10px',
                        }}
                      />
                    ) : (
                      <Avatar alt="" src="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                    )}
                    <Typography style={{ marginTop: '5px' }} variant="subtitle2" noWrap className="fontChange">
                      {item.requestor.firstName} {item.requestor.lastName}
                    </Typography>
                  </div>
                </TableCell>
                {/* Requestor contact */}
                <TableCell className="fontChange">{item.requestor.phone}</TableCell>
                {/* Original shift user */}
                {/* <TableCell>
                  <div style={{ display: 'flex' }}>
                    {item?.userShift?.shiftUser?.thumbnailURL ? (
                      <Avatar
                        src={item?.userShift?.shiftUser?.thumbnailURL}
                        style={{
                          height: '30px',
                          width: '30px',
                          borderRadius: '100px',
                          marginRight: '10px',
                        }}
                      />
                    ) : (
                      <Avatar alt="" src="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                    )}
                    <Typography style={{ marginTop: '5px' }} variant="subtitle2" noWrap className="fontChange">
                      {item.userShift?.shiftUser?.firstName} {item.userShift?.shiftUser?.lastName}
                    </Typography>
                  </div>
                </TableCell> */}
                {/* Original user contact */}
                {/* <TableCell className="fontChange">{item?.userShift?.shiftUser?.phone}</TableCell> */}
                <TableCell className="fontChange shiftTimeofRequest">
                  <span>{moment(item.userShift?.date).format('MMM DD')}, </span>{' '}
                  <span>{moment(item.userShift.startTime, 'hh').format('LT')} - </span>{' '}
                  <span>{moment(item.userShift.endTime, 'hh').format('LT')}</span>
                </TableCell>
                <TableCell>
                  <Tooltip title={item.userShift?.note}>
                    <span>
                      <Button disabled>
                        <Iconify icon={'ic:round-speaker-notes'} width={20} height={20} className="fontChange" />
                      </Button>
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <div style={{ display: 'flex' }}>
                    {item?.approver?.thumbnailURL ? (
                      <Avatar
                        src={item?.approver?.thumbnailURL}
                        style={{
                          height: '30px',
                          width: '30px',
                          borderRadius: '100px',
                          marginRight: '10px',
                        }}
                      />
                    ) : (
                      <Avatar alt="" src="" style={{ height: '30px', width: '30px', marginRight: '10px' }} />
                    )}
                    <Typography style={{ marginTop: '5px' }} variant="subtitle2" noWrap className="fontChange">
                      {item.approver?.firstName} {item.approver?.lastName}
                    </Typography>
                  </div>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
        {shiftRequest?.shiftRequests?.length === 0 && <TableNoData isNotFound={shiftRequest?.shiftRequests} />}
      </Table>
      <Box
        sx={{ position: 'relative', marginTop: '10px' }}
        justifyContent={'end'}
        alignContent="center"
        display={'flex'}
      >
        <Pagination
          count={shiftRequest?.paginationInfo?.totalPages}
          page={pageNo}
          onChange={(e, value) => setPageNo(value)}
        />
      </Box>
    </>
  );
}
