import PropTypes from 'prop-types';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
  Tooltip,
  Avatar,
} from '@mui/material';
// utils
import createAvatar from 'utils/createAvatar';

import { fDate } from '../../../../utils/formatTime';
import { fCurrency } from '../../../../utils/formatNumber';
// components

import Label from '../../../../components/Label';
import Image from '../../../../components/Image';
import Scrollbar from '../../../../components/Scrollbar';
//
import InvoiceToolbar from './InvoiceToolbar';

// ----------------------------------------------------------------------

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

InvoiceDetails.propTypes = {
  invoice: PropTypes.object.isRequired,
  suppliers: PropTypes.object,
};

export default function InvoiceDetails({ invoice, suppliers }) {
  const theme = useTheme();

  if (!invoice) {
    return '';
  }

  let totalPrices = 0;
  let previousInvoiceTotalPrice = 0;

  return (
    <>
      <InvoiceToolbar invoice={invoice} />

      <Card sx={{ pt: 5, px: 5 }}>
        <Grid container>
          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Image
              disabledEffect
              visibleByDefault
              alt="logo"
              src="/logo/logo_full.svg"
              sx={{ maxWidth: 70, borderRadius: '50%' }}
            />
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'right' } }}>
              <Label
                variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                color={
                  (invoice?.status === 'APPROVED' && 'success') ||
                  (invoice?.status === 'PENDING' && 'warning') ||
                  // (invoice?.status === 'RETURN' && 'error') ||
                  'default'
                }
                sx={{ textTransform: 'uppercase', mb: 1 }}
              >
                {invoice?.status}
              </Label>

              <Typography variant="h6">{`INV-${invoice?.invoiceNumber}`}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Invoice from
            </Typography>
            <Typography variant="body2">{suppliers?.name}</Typography>
            <Typography variant="body2">{suppliers?.abn}</Typography>
            <Typography variant="body2">{suppliers?.email}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              date create
            </Typography>
            <Typography variant="body2">{invoice?.invoiceDate && fDate(invoice?.invoiceDate)}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Due date
            </Typography>
            <Typography variant="body2">{invoice?.dueDate && fDate(invoice?.dueDate)}</Typography>
          </Grid>
        </Grid>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 960 }}>
            <Table>
              <TableHead
                sx={{
                  borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                  '& th': { backgroundColor: 'transparent' },
                }}
              >
                <TableRow>
                  <TableCell width={40}>#</TableCell>
                  <TableCell align="left">External UUID</TableCell>

                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">uom</TableCell>

                  <TableCell align="left">Weight</TableCell>

                  <TableCell align="left" width={30}>
                    Qty
                  </TableCell>
                  <TableCell align="right">Unit price</TableCell>
                  <TableCell align="right">GST</TableCell>
                  <TableCell align="right">Levy</TableCell>

                  <TableCell align="right">Total</TableCell>
                  <TableCell align="right">Return Quantity</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {invoice?.invoiceItems?.map((row, index) => {
                  const rowTotal = row?.unitPrice * row.suppliedQty + row?.gst + row?.levy;
                  totalPrices += rowTotal;
                  let findReturnInvoices = '';

                  if (invoice?.returnInvoiceItems) {
                    findReturnInvoices = invoice?.returnInvoiceItems.find((el) => el.externalUID === row.externalUID);
                  }

                  return (
                    <TableRow
                      key={index}
                      sx={{
                        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                      }}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell align="left" sx={{ maxWidth: 500 }}>
                        {row?.externalUID}
                      </TableCell>

                      <TableCell align="left">
                        <Box sx={{ maxWidth: 750 }}>
                          <Typography variant="subtitle2">{row?.brand}</Typography>
                          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                            {row?.productDesc}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell align="left">{row?.uom}</TableCell>

                      <TableCell align="left">
                        {row?.weight} ({row?.weightType})
                      </TableCell>

                      <TableCell align="left">{row?.suppliedQty}</TableCell>
                      <TableCell align="right">{fCurrency(row?.unitPrice)}</TableCell>
                      <TableCell align="right">{fCurrency(row?.gst)}</TableCell>
                      <TableCell align="right">{fCurrency(row?.levy)}</TableCell>

                      <TableCell align="right">{fCurrency(rowTotal)}</TableCell>
                      <TableCell align="right" sx={{ display: 'flex' }}>
                        {findReturnInvoices && (
                          <Tooltip
                            disableFocusListener
                            title={`
                            Supplied Quantity : ${findReturnInvoices?.suppliedQty}, 
                             Unit Price : ${findReturnInvoices?.unitPrice}, gst : ${
                              findReturnInvoices?.gst || 0
                            }, levy : ${findReturnInvoices?.levy || 0} `}
                            placement="left"
                          >
                            <Avatar
                              alt={findReturnInvoices?.suppliedQty}
                              color={createAvatar(findReturnInvoices?.supplierName).color}
                              sx={{ mr: 2 }}
                            >
                              {findReturnInvoices?.suppliedQty}
                            </Avatar>
                          </Tooltip>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}

                <RowResultStyle>
                  <TableCell colSpan={9} />
                  <TableCell align="right">
                    <Box sx={{ mt: 2 }} />
                    <Typography>Subtotal</Typography>
                  </TableCell>
                  <TableCell align="right" width={120}>
                    <Box sx={{ mt: 2 }} />
                    <Typography variant="h6">{fCurrency(totalPrices)}</Typography>
                  </TableCell>
                </RowResultStyle>

                <RowResultStyle>
                  <TableCell colSpan={9} />
                  <TableCell align="right">
                    <Typography variant="h6">Total</Typography>
                  </TableCell>
                  <TableCell align="right" width={140}>
                    <Typography variant="h6">${invoice?.totalInclGst}</Typography>
                  </TableCell>
                </RowResultStyle>
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Divider sx={{ mt: 5 }} />

        {/* previous Invoice Items */}
        {invoice?.previousInvoiceItems?.length && (
          <Scrollbar>
            <Box mt="1rem">
              <h2>Previous Invoice Items</h2>
            </Box>

            <TableContainer sx={{ minWidth: 960 }}>
              <Table>
                <TableHead
                  sx={{
                    borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                    '& th': { backgroundColor: 'transparent' },
                  }}
                >
                  <TableRow>
                    <TableCell width={40}>#</TableCell>
                    <TableCell align="left">External UUID</TableCell>

                    <TableCell align="left">Description</TableCell>
                    <TableCell align="left">uom</TableCell>

                    <TableCell align="left">Weight</TableCell>

                    <TableCell align="left" width={30}>
                      Qty
                    </TableCell>
                    <TableCell align="right">Unit price</TableCell>
                    <TableCell align="right">GST</TableCell>
                    <TableCell align="right">Levy</TableCell>

                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {invoice?.previousInvoiceItems?.map((row, index) => {
                    const rowTotal = row?.unitPrice * row.suppliedQty + row?.gst + row?.levy;
                    previousInvoiceTotalPrice += rowTotal;
                    return (
                      <>
                        <TableRow
                          key={index}
                          sx={{
                            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                          }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell align="left" sx={{ maxWidth: 500 }}>
                            {row?.externalUID}
                          </TableCell>

                          <TableCell align="left">
                            <Box sx={{ maxWidth: 750 }}>
                              <Typography variant="subtitle2">{row?.brand}</Typography>
                              <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                                {row?.productDesc}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell align="left">{row?.uom}</TableCell>

                          <TableCell align="left">
                            {row?.weight} ({row?.weightType})
                          </TableCell>

                          <TableCell align="left">{row?.suppliedQty}</TableCell>
                          <TableCell align="right">{fCurrency(row?.unitPrice)}</TableCell>
                          <TableCell align="right">{fCurrency(row?.gst)}</TableCell>
                          <TableCell align="right">{fCurrency(row?.levy)}</TableCell>

                          <TableCell align="right">{fCurrency(rowTotal)}</TableCell>
                        </TableRow>
                      </>
                    );
                  })}

                  <RowResultStyle>
                    <TableCell colSpan={8} />
                    <TableCell align="right">
                      <Typography variant="h6">Total</Typography>
                    </TableCell>
                    <TableCell align="right" width={140}>
                      <Typography variant="h6">{fCurrency(previousInvoiceTotalPrice)}</Typography>
                    </TableCell>
                  </RowResultStyle>
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
        )}
        <Divider sx={{ mt: 5 }} />
      </Card>
    </>
  );
}
