import { Box, Card, Typography } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import React from 'react';

function HrSignedDocumentViewDrawer({ open, setOpen, data }) {
  return (
    <CustomDrawer open={open} setOpen={setOpen} title={`View Details`}>
      <Card xs={{ p: 3 }}>
        {/* <img src={data?.url} alt="document" /> */}
        <object data={data?.url} type="image/svg+xml">
          <img src="./../../../../assets/fallback.jpg" alt="SVG " />
        </object>
        {/* <Typography variant="h6" sx={{ mt: 2 }}>
          Status: {data?.status}
        </Typography> */}
      </Card>
    </CustomDrawer>
  );
}

export default HrSignedDocumentViewDrawer;
