import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import { useSelector, useDispatch } from 'react-redux';
import { logoutAction } from 'redux/slices/authSlice';
// import { PATH_AUTH, PATH_DASHBOARD } from 'routes/paths';
// import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
// import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuth } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const localStorages = JSON.parse(localStorage.getItem('profiles'))?.tokenDTO?.acessToken;

  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState(null);

  useEffect(() => {
    const handleInvalidToken = (e) => {
      if (!e.key) {
        dispatch(logoutAction());
      }

      // when a user logs in
      if (e.key === 'profiles') {
        document.location.reload();
      }
    };

    window.addEventListener('storage', handleInvalidToken);
    return function cleanup() {
      window.removeEventListener('storage', handleInvalidToken);
    };
  });

  if (!isAuth || !localStorages) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
