import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// import { toast } from 'react-toastify';
import apiURL from 'api';
import { successToastTime, errorToastTime } from 'config';
import { toast } from 'react-toastify';

export const UploadStoreGuideline = createAsyncThunk('store/uploadStoreGuidelines', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/storeConf/guideline`, props?.formData);
    if (response.status === 200) {
      thunkAPI.dispatch(getStoreGuideline());
      props?.isSuceess(true);
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});
export const UpdateStoreGuideline = createAsyncThunk('store/updateStoreGuidelines', async (props, thunkAPI) => {
  try {
    const response = await apiURL.put(`/storeConf/guideline`, props?.formData);
    if (response.status === 200) {
      thunkAPI.dispatch(getStoreGuideline());
      props?.isSuccess(true);
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const DeleteStoreGuideline = createAsyncThunk('store/deleteStoreGuidelines', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/storeConf/guideline/${props?.id}`);
    if (response.status === 200) {
      thunkAPI.dispatch(getStoreGuideline());
    }
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});
export const getStoreGuideline = createAsyncThunk('store/getStoreGuideline', async () => {
  try {
    const response = await apiURL.get(`/storeConf/guidelines`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getVehiclesLog = createAsyncThunk('store/getVehiclesLog', async (props) => {
  try {
    const response = await apiURL.get(`/storeConf/vehicleLogs/${props?.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const UploadVehiclesLog = createAsyncThunk('store/uploadVehiclesLog', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/storeConf/handleVehicleLog`, props?.finalData);
    if (response.status === 200) {
      thunkAPI.dispatch(getVehiclesLog({ departmentId: props?.finalData?.departmentId }));
      props?.isSuccess(true);
    }
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const DeleteVehiclesLog = createAsyncThunk('store/deleteVehiclesLog', async (props, thunkAPI) => {
  const response = await apiURL.delete(`/storeConf/vehicleLog/${props?.departmentId}/${props?.id}`);
  if (response?.status === 200) {
    thunkAPI.dispatch(getVehiclesLog({ departmentId: props?.departmentId }));
    toast.success('vehicle log deleted', { autoClose: successToastTime });
  }
  return response.data;
});

export const getFirstAidLog = createAsyncThunk('store/getFirstAidLog', async (props) => {
  try {
    const response = await apiURL.get(`/storeConf/firstaidLogs/${props?.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const UploadFirstAidLog = createAsyncThunk('store/uploadFirstAidLog', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/storeConf/handleFirstaidLog`, props?.finalData);
    if (response.status === 200) {
      thunkAPI.dispatch(getFirstAidLog({ departmentId: props?.finalData?.departmentId }));
      props?.isSuccess(true);
    }
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const DeleteFirstAidLog = createAsyncThunk('store/deleteFirstaidLog', async (props, thunkAPI) => {
  const response = await apiURL.delete(`/storeConf/firstaidLog/${props?.departmentId}/${props?.id}`);
  if (response?.status === 200) {
    thunkAPI.dispatch(getFirstAidLog({ departmentId: props?.departmentId }));
    toast.success('FirstAid log deleted', { autoClose: successToastTime });
  }
  return response.data;
});

export const getEquipmentLog = createAsyncThunk('store/getEquipmentLog', async (props) => {
  try {
    const response = await apiURL.get(`/storeConf/equipmentLogs/${props?.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const UploadEquipmentLog = createAsyncThunk('store/uploadEquipmentLog', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/storeConf/handleEquipmentLog`, props?.finalData);
    if (response.status === 200) {
      thunkAPI.dispatch(getEquipmentLog({ departmentId: props?.finalData?.departmentId }));
      props?.isSuccess(true);
    }
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const DeleteEquipmentLog = createAsyncThunk('store/deleteEquipmentLog', async (props, thunkAPI) => {
  const response = await apiURL.delete(`/storeConf/equipmentLog/${props?.departmentId}/${props?.id}`);
  if (response?.status === 200) {
    thunkAPI.dispatch(getEquipmentLog({ departmentId: props?.departmentId }));
    toast.success('Equipment log deleted', { autoClose: successToastTime });
  }
  return response.data;
});

export const getChecklistCategory = createAsyncThunk('store/getChecklistCategory', async (props) => {
  try {
    const response = await apiURL.get(`/storeConf/checklistCategories/${props?.departmentId}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createChecklistCategory = createAsyncThunk('store/createChecklistCategory', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/storeConf/handleChecklistCategory`, props?.finalData);
    if (response.status === 200) {
      thunkAPI.dispatch(getChecklistCategory({ departmentId: props?.finalData?.departmentId }));
      toast.success('Checklist category updated', { autoClose: successToastTime });
      props?.setOpen(false);
    }
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteChecklistCategory = createAsyncThunk('store/deleteChecklistCategory', async (props, thunkAPI) => {
  try {
    const response = await apiURL.delete(`/storeConf/checklistCategory/${props?.id}/${props?.departmentId}`);
    if (response.status === 200) {
      thunkAPI.dispatch(getChecklistCategory({ departmentId: props?.departmentId }));
      toast.success('Checklist category deleted', { autoClose: successToastTime });
    }
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getChecklistItem = createAsyncThunk('store/getChecklistItem', async (props) => {
  try {
    const response = await apiURL.get(
      `/storeConf/checklists/${props?.departmentId}/${props?.categoryId}/${props?.status}?pageNo=${props?.pageNo || 0}`
    );
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createChecklistItem = createAsyncThunk('store/createChecklistItem', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/storeConf/handleChecklist`, props?.finalData);
    if (response.status === 200) {
      thunkAPI.dispatch(
        getChecklistItem({
          departmentId: props?.finalData?.departmentId,
          categoryId: props?.finalData?.checkListCategoryId,
          status: 'ACTIVE',
        })
      );
      if (props?.isEdit) {
        toast.success('Checklist item updated', { autoClose: successToastTime });
        props?.setOpen({ status: false, data: null });
      } else {
        toast.success('Checklist item created', { autoClose: successToastTime });
        props?.setOpen(false);
      }
    }
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteChecklistItem = createAsyncThunk('store/deleteChecklistItem', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/storeConf/checklist`, props?.finalData);
    if (response.status === 200) {
      thunkAPI.dispatch(
        getChecklistItem({
          departmentId: props?.finalData?.departmentId,
          categoryId: props?.finalData?.checklistCategoryId,
          status: 'ACTIVE',
        })
      );
      toast.success('Checklist item deleted', { autoClose: successToastTime });
      props?.setOpen({ status: false, data: null });
    }
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getTaskLists = createAsyncThunk('store/getTaskLists', async (props) => {
  try {
    const response = await apiURL.get(
      `/storeConf/task/${props?.status}/${props?.departmentId}?pageNo=${props?.pageNo - 1 || 0}`
    );
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getDatedTaskList = createAsyncThunk('store/getDatedTaskList', async (props) => {
  try {
    const response = await apiURL.post(`/storeConf/taskOnDay`, props);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const UpdateTaskList = createAsyncThunk('store/updateTaskList', async (props, thunkAPI) => {
  try {
    const response = await apiURL.post(`/storeConf/handleTask`, props?.finalData);
    if (response.status === 200) {
      thunkAPI.dispatch(getTaskLists({ status: 'PENDING', departmentId: props?.finalData?.departmentId }));
      toast.success('Task list updated', { autoClose: successToastTime });
      props?.setOpen({ status: false, data: null });
    }
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

const storeDocumentSlice = createSlice({
  name: 'storeDocument',
  initialState: {
    loading: false,
    storeGuidelines: [],
    vehicleLogs: [],
    firstAidLogs: [],
    equipmentLogs: [],
    checklists: {},
    singleChecklist: {},
    singleChecklistLoading: false,

    checklistCategories: [],
    taskLists: {},
    postLoading: false,

    checklistTabValue: 0,
    checklistCategoryTabValue: 0,
  },
  reducers: {
    clearSingleChecklist(state) {
      state.singleChecklist = {};
    },
    setChecklistTabValue(state, action) {
      state.checklistTabValue = action.payload;
    },
    setChecklistCategoryTabValue(state, action) {
      state.checklistCategoryTabValue = action.payload;
    },
  },

  extraReducers: {
    [UpdateTaskList.pending]: (state) => {
      state.postLoading = true;
    },
    [UpdateTaskList.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [UpdateTaskList.rejected]: (state) => {
      state.postLoading = false;
    },

    [getTaskLists.pending]: (state) => {
      state.loading = true;
      state.taskLists = {};
    },
    [getTaskLists.fulfilled]: (state, action) => {
      state.loading = false;
      state.taskLists = action.payload;
    },
    [getTaskLists.rejected]: (state) => {
      state.loading = false;
      state.taskLists = {};
    },
    [getDatedTaskList.pending]: (state) => {
      state.loading = true;
      state.taskLists = {};
    },
    [getDatedTaskList.fulfilled]: (state, action) => {
      state.loading = false;
      state.taskLists = action.payload;
    },
    [getDatedTaskList.rejected]: (state) => {
      state.loading = false;
      state.taskLists = {};
    },

    [createChecklistItem.pending]: (state) => {
      state.postLoading = true;
    },
    [createChecklistItem.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [createChecklistItem.rejected]: (state) => {
      state.postLoading = false;
    },

    [getChecklistItem.pending]: (state) => {
      state.loading = true;
      state.checklists = {};
    },
    [getChecklistItem.fulfilled]: (state, action) => {
      state.loading = false;
      state.checklists = action.payload;
    },
    [getChecklistItem.rejected]: (state) => {
      state.loading = false;
      state.checklists = {};
    },

    [deleteChecklistCategory.pending]: (state) => {
      state.postLoading = true;
    },
    [deleteChecklistCategory.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [deleteChecklistCategory.rejected]: (state) => {
      state.postLoading = false;
    },

    [createChecklistCategory.pending]: (state) => {
      state.postLoading = true;
    },
    [createChecklistCategory.fulfilled]: (state) => {
      state.postLoading = false;
    },
    [createChecklistCategory.rejected]: (state) => {
      state.postLoading = false;
    },
    [getChecklistCategory.pending]: (state) => {
      state.loading = true;
      state.checklistCategories = [];
    },
    [getChecklistCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.checklistCategories = action.payload;
    },
    [getChecklistCategory.rejected]: (state) => {
      state.loading = false;
      state.checklistCategories = [];
    },
    //  --------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
    // [getChecklistById.pending]: (state) => {
    //   state.singleChecklistLoading = true;
    //   state.singleChecklist = {};
    // },
    // [getChecklistById.fulfilled]: (state, action) => {
    //   state.singleChecklistLoading = false;
    //   state.singleChecklist = action.payload;
    // },
    // [getChecklistById.rejected]: (state) => {
    //   state.singleChecklistLoading = false;
    //   state.singleChecklist = {};
    // },

    // [getChecklist.pending]: (state) => {
    //   state.loading = true;
    //   state.checklists = [];
    // },
    // [getChecklist.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.checklists = action.payload;
    // },
    // [getChecklist.rejected]: (state) => {
    //   state.loading = false;
    //   state.checklists = [];
    // },

    [getEquipmentLog.pending]: (state) => {
      state.loading = true;
      state.equipmentLogs = [];
    },
    [getEquipmentLog.fulfilled]: (state, action) => {
      state.loading = false;
      state.equipmentLogs = action.payload;
    },
    [getEquipmentLog.rejected]: (state) => {
      state.loading = false;
      state.equipmentLogs = [];
    },
    [getVehiclesLog.pending]: (state) => {
      state.loading = true;
      state.vehicleLogs = [];
    },
    [getVehiclesLog.fulfilled]: (state, action) => {
      state.loading = false;
      state.vehicleLogs = action.payload;
    },
    [getVehiclesLog.rejected]: (state) => {
      state.loading = false;
      state.vehicleLogs = [];
    },
    [getFirstAidLog.pending]: (state) => {
      state.loading = true;
      state.firstAidLogs = [];
    },
    [getFirstAidLog.fulfilled]: (state, action) => {
      state.loading = false;
      state.firstAidLogs = action.payload;
    },
    [getFirstAidLog.rejected]: (state) => {
      state.loading = false;
      state.firstAidLogs = [];
    },

    [getStoreGuideline.pending]: (state) => {
      state.loading = true;
      state.storeGuidelines = [];
    },
    [getStoreGuideline.fulfilled]: (state, action) => {
      state.loading = false;
      state.storeGuidelines = action.payload;
    },
    [getStoreGuideline.rejected]: (state) => {
      state.loading = false;
      state.storeGuidelines = [];
    },
  },
});

export const { clearSingleChecklist, setChecklistTabValue, setChecklistCategoryTabValue } = storeDocumentSlice.actions;

export default storeDocumentSlice.reducer;
