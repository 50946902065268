import { Button } from '@mui/material';
import { Box } from '@mui/system';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { isopenInvoiceModal } from 'redux/slices/InvoiceSlice';
import { useDispatch, useSelector } from 'redux/store';

import InvoiceDetailsShowModal from './InvoiceModal/InvoiceDetailsShowModal';

function ViewInvoiceDataModal({ open, invoice, isEdit }) {
  const { invoiceDetails, postLoading } = useSelector((state) => state.invoice);
  const dispatch = useDispatch();
  return (
    <CustomOutletModal open={open} zIndex="1500" width={'90vw'} height={'90vh'} disablePortal>
      <Box className="editInvociesDetailsModalContainer" style={isEdit ? { display: 'flex' } : { display: 'block' }}>
        <Box
          className="editInvoiceDetailsModalItems"
          style={invoiceDetails?.invoiceUrl && isEdit ? { width: '50%' } : { width: '100%' }}
        >
          <InvoiceDetailsShowModal invoice={invoice} />
        </Box>
        {invoiceDetails?.invoiceUrl && isEdit && (
          <Box
            className="editInvoicesPDfContainer"
            style={invoiceDetails?.invoiceUrl && isEdit ? { width: '50%' } : { width: '100%' }}
          >
            {isEdit && invoiceDetails?.invoiceUrl && (
              <object width="100%" height="70%" name="pdffile" data={invoiceDetails?.invoiceUrl} type="application/pdf">
                Loading...
              </object>
            )}
          </Box>
        )}
      </Box>

      <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem', gap: '2rem' }}>
        <Button color="secondary" type="button" onClick={() => dispatch(isopenInvoiceModal())}>
          Go Back
        </Button>

        <Button variant="contained" type="submit" disabled={postLoading}>
          Confirm and Save
        </Button>
      </Box>
    </CustomOutletModal>
  );
}

export default ViewInvoiceDataModal;
