import { Box } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import React from 'react';
import TimeClockDrawerForm from 'sections/@dashboard/time/TimeClockDrawerForm';

function EditTimeClockDuration({ open, setOpen, userObj }) {
  return (
    <CustomDrawer open={open} setOpen={setOpen} title="Update Time Clock">
      <Box style={{ padding: '1rem' }}>
        <TimeClockDrawerForm userObj={userObj} isEdit setOpen={setOpen} />
      </Box>
    </CustomDrawer>
  );
}

export default EditTimeClockDuration;
