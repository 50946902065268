import React from 'react';
import PropTypes from 'prop-types';
import QuickSummaryDifferenceIndividualTableCell from './QuickSummaryDifferenceIndividualTableCell';

QuickSummaryDifferenceIndividualTableRow.propTypes = {
  user: PropTypes.object,
  mainIndex: PropTypes.number,
};
function QuickSummaryDifferenceIndividualTableRow({ user, mainIndex }) {
  const array = Object.keys(user?.difference);

  // const reverseArray = array.reverse();
  // const sortedArray = array.sort((a, b) => new Date(b) - new Date(a));

  return (
    <>
      {array?.map((differenceReport, indexes) => (
        <QuickSummaryDifferenceIndividualTableCell
          differenceReportKey={differenceReport}
          key={indexes}
          mainIndex={mainIndex}
          userInfo={user}
        />
      ))}
    </>
  );
}

export default QuickSummaryDifferenceIndividualTableRow;
