import { RHFTextField } from 'components/hook-form';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

function InvoiceItemsPackSize({ index }) {
  const { control } = useFormContext();
  const watchUOM = useWatch({
    control,
    name: `invoiceItems[${index}].uom`,
  });
  return (
    <>
      {watchUOM === 'EACH' && (
        <RHFTextField
          size="small"
          name={`invoiceItems[${index}].packSize`}
          // onChange={(event) => handleChangeReturnedQuantity(event, index)}
          label="Pack Size"
          InputLabelProps={{ shrink: true }}
          sx={{ maxWidth: { md: 120 }, marginRight: 1 }}
        />
      )}
    </>
  );
}

export default InvoiceItemsPackSize;
