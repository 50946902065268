import { Button, Stack } from '@mui/material';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { FormProvider, RHFTextField } from 'components/hook-form';
import * as yup from 'yup';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { deleteChecklistItem } from 'redux/slices/storeDocumentSlice';

const deleteSchema = yup.object().shape({
  note: yup.string().required(''),
});

ChecklistDeleteModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  item: PropTypes.object,
  categoryId: PropTypes.number,
};

function ChecklistDeleteModal({ open, setOpen, item, categoryId }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const defaultValues = {
    note: '',
  };

  const methods = useForm({ defaultValues, resolver: yupResolver(deleteSchema) });

  const { handleSubmit } = methods;

  const deleteChecklistSubmitHandler = (data) => {
    const finalData = { ...data, id: item?.id, departmentId, checklistCategoryId: categoryId };

    dispatch(deleteChecklistItem({ finalData, setOpen }));
  };
  return (
    <CustomOutletModal
      open={open}
      zIndex="1600"
      onClose={() => setOpen({ status: false, data: null })}
      title={'Delete Checklist'}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(deleteChecklistSubmitHandler)}>
        <Stack spacing={2}>
          <RHFTextField
            name="note"
            label="Note*
            "
            multiline
            rows={3}
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" spacing={2} sx={{ mt: 2 }}>
          <Button variant="contained" type="submit" color="error">
            Delete
          </Button>
          <Button variant="outlined" color="error" onClick={() => setOpen({ status: false, data: null })}>
            Cancel
          </Button>
        </Stack>
      </FormProvider>
    </CustomOutletModal>
  );
}

export default ChecklistDeleteModal;
