import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import apiURL from 'api';
import moment from 'moment';
import { errorToastTime } from 'config';

export const getFixedCostList = createAsyncThunk('operational/getFixedCost', async (data) => {
  const response = await apiURL.post(`/operational/fixedcost/get`, data);
  return response.data;
  // toast.error(error?.response?.data.message, { autoClose: 1500 });
});

export const createFixedCost = createAsyncThunk('operational/postFixedCost', async (data, thunkAPI) => {
  const date = new Date();
  try {
    const response = await apiURL.post(`/operational/fixedCost`, data.finalData);
    if (response.status === 200) data.isSuccess(true);
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(
      getFixedCostList({
        month: moment(date).format('MM'),
        year: moment(date).format('YYYY'),
        departmentId: data?.departmentId,
      })
    );
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const updateFixedCost = createAsyncThunk('operational/updateFixedCost', async (data, thunkAPI) => {
  try {
    const response = await apiURL.put(`operational/fixedCost`, data);
    thunkAPI.dispatch(getFixedCostList({ month: data?.month, year: data?.year, departmentId: data?.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});
export const deleteFixedCost = createAsyncThunk('operational/deleteFixedCost', async (data) => {
  try {
    const response = await apiURL.delete(`operational/fixedCost/${data.departmentId}/${data?.id}`);
    // thunkAPI.dispatch(getFixedCostList({ month: data?.month, year: data?.year, departmentId: data?.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data?.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createRunnigCost = createAsyncThunk('operational/postRunningCost', async (data, thunkAPI) => {
  try {
    const response = await apiURL.post(`/operational/runningCost`, data.finalData);
    if (response.status === 200) {
      data?.isSuccess(true);
      thunkAPI.dispatch(
        getRunningCostList({
          month: data.finalData.month,
          year: data.finalData.year,
          departmentId: data.finalData.departmentId,
        })
      );
    }
    // return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const getRunningCostList = createAsyncThunk('operational/getRunningCost', async (data) => {
  const response = await apiURL.post(`/operational/runningcost/get`, data);
  return response.data;
});

export const getPreviousMonthRunningCost = createAsyncThunk('operational/getPreviousMonthRunningCost', async (data) => {
  const pastRunningCost = await apiURL.post(`/operational/runningcost/get`, data);
  if (pastRunningCost.status === 200) {
    const runningCostResponse = await apiURL.post(`/operational/runningCost`, {
      ...pastRunningCost?.data,

      id: null,
      departmentId: data?.departmentId,
      month: data?.currentMonth,
      year: data?.currentYear,
    });
    const pastAdditionalRunningCost = await apiURL.get(
      `/operational/additionalRunningCost/${data.departmentId}/${pastRunningCost?.data.id}`
    );
    if (runningCostResponse.status === 200) {
      const dataArray = pastAdditionalRunningCost?.data?.map((item) => {
        return {
          title: item?.title,
          amount: item?.amount,
        };
      });
      const finalData = {
        runningCosts: dataArray,
        runningCostId: runningCostResponse?.data,
        departmentId: data?.departmentId,
      };
      const additionalRunningCost = await apiURL.post(`/operational/additionalRunningCost`, finalData);
      data?.isSuccess(true);
    }
  }
});

export const getPreviousMonthFixedCost = createAsyncThunk('operational/getPreviousMonthFixedCost', async (data) => {
  const pastFixedCost = await apiURL.post(`/operational/fixedcost/get`, data);

  if (pastFixedCost.status === 200) {
    const fixedCostResponse = await apiURL.post(`/operational/fixedCost`, {
      ...pastFixedCost?.data,

      id: null,
      departmentId: data?.departmentId,
      month: data?.currentMonth,
      year: data?.currentYear,
    });
    const pastAdditionalFixedCost = await apiURL.get(
      `/operational/additionalFixedCost/${data.departmentId}/${pastFixedCost?.data.id}`
    );
    if (fixedCostResponse.status === 200) {
      const dataArray = pastAdditionalFixedCost?.data?.map((item) => {
        return {
          title: item?.title,
          amount: item?.amount,
        };
      });
      const finalData = {
        fixedCosts: dataArray,
        fixedCostId: fixedCostResponse?.data,
        departmentId: data?.departmentId,
      };
      const additionalFixedCost = await apiURL.post(`/operational/additionalFixedCost`, finalData);
      data?.isSuccess(true);
    }
  }
});
export const updateRunningCost = createAsyncThunk('operational/updateRunningCost', async (data, thunkAPI) => {
  try {
    const response = await apiURL.put(`/operational/runningCost`, data);
    thunkAPI.dispatch(getRunningCostList({ month: data?.month, year: data?.year, departmentId: data?.departmentId }));
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const deleteRunningCost = createAsyncThunk('operational/deleteRunningCost', async (data) => {
  try {
    const response = await apiURL.delete(`/operational/runningCost/${data.departmentId}/${data?.id}`);
    return response.data;
  } catch (error) {
    toast.error(error?.response?.data.message, { autoClose: errorToastTime });
    throw error;
  }
});

export const createAdditionalRunningCost = createAsyncThunk(
  'operational/postAdditionalRunningCost',
  async (data, thunkAPI) => {
    try {
      const response = await apiURL.post(`/operational/additionalRunningCost`, data);
      thunkAPI.dispatch(getRunningCostList({ id: data?.runningCostId, departmentId: data?.departmentId }));
    } catch (error) {
      toast.error(error?.response?.data.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);
export const getAdditionalRunningCost = createAsyncThunk('operational/getAdditionalRunningCost', async (data) => {
  const response = await apiURL.get(`/operational/additionalRunningCost/${data.departmentId}/${data.runningCostId}`);
  return response.data;
});
export const deleteAdditionalRunningCost = createAsyncThunk(
  'operational/deleteAdditionalRunningCost',
  async (data, thunkAPI) => {
    try {
      const response = await apiURL.delete(
        `/operational/additionalRunningCost/${data.departmentId}/${data.additionalRunningCostId}`
      );
      thunkAPI.dispatch(
        getAdditionalRunningCost({ departmentId: data.departmentId, runningCostId: data.runningCostId })
      );
      //   // return response.data;
    } catch (error) {
      toast.error(error?.response?.data.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);

export const createAdditionalFixedCost = createAsyncThunk(
  'operational/postAdditionalFixedCost',
  async (data, thunkAPI) => {
    try {
      const response = await apiURL.post(`/operational/additionalFixedCost`, data);
      thunkAPI.dispatch(getFixedCostList({ id: data?.fixedCostId, departmentId: data?.departmentId }));
    } catch (error) {
      toast.error(error?.response?.data.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);
export const getAdditionalFixedCost = createAsyncThunk('operational/getAdditionalFixedCost', async (data) => {
  const response = await apiURL.get(`/operational/additionalFixedCost/${data.departmentId}/${data.fixedCostId}`);
  return response.data;
  // toast.error(error?.response?.data.message);
});
export const deleteAdditionalFixedCost = createAsyncThunk(
  'operational/deleteAdditionalRunningCost',
  async (data, thunkAPI) => {
    try {
      const response = await apiURL.delete(
        `/operational/additionalFixedCost/${data.departmentId}/${data.additionalFixedCostId}`
      );
      thunkAPI.dispatch(getAdditionalFixedCost({ departmentId: data.departmentId, fixedCostId: data.fixedCostId }));
      //   // return response.data;
    } catch (error) {
      toast.error(error?.response?.data.message, { autoClose: errorToastTime });
      throw error;
    }
  }
);
const operationalSlice = createSlice({
  name: 'operational',
  initialState: {
    loading: true,
    fixedCost: {},
    runningCost: {},
    additionalRunningCost: {},
    additionalFixedCost: {},
    previousMonthRunningCost: {},
    previousMonthFixedCost: {},
  },
  reducers: {
    cleanPastMonthRunningCost: (state) => {
      state.previousMonthRunningCost = {};
    },
    cleanPastMonthFixedCost: (state) => {
      state.previousMonthFixedCost = {};
    },
  },

  extraReducers: {
    [getFixedCostList.pending]: (state) => {
      state.fixedCost = {};
      state.additionalFixedCost = {};
      state.loading = true;
    },
    [getFixedCostList.fulfilled]: (state, action) => {
      state.loading = false;
      state.fixedCost = action.payload;
    },
    [getFixedCostList.rejected]: (state) => {
      state.fixedCost = {};
      state.loading = false;
    },

    [updateFixedCost.pending]: (state) => {
      state.loading = true;
    },
    [updateFixedCost.fulfilled]: (state) => {
      state.loading = false;
      // state.fixedCost = action.payload;
    },
    [updateFixedCost.rejected]: (state) => {
      state.loading = false;
    },

    [createFixedCost.pending]: (state) => {
      state.loading = true;
    },
    [createFixedCost.fulfilled]: (state, action) => {
      state.loading = false;
      state.fixedCost = action.payload;
    },
    [createFixedCost.rejected]: (state) => {
      state.loading = false;
    },

    [deleteFixedCost.pending]: (state) => {
      state.loading = true;
    },
    [deleteFixedCost.fulfilled]: (state) => {
      state.loading = false;
      state.fixedCost = {};
    },
    [deleteFixedCost.rejected]: (state) => {
      state.loading = false;
    },
    [createRunnigCost.pending]: (state) => {
      state.loading = true;
    },
    [createRunnigCost.fulfilled]: (state, action) => {
      state.loading = false;
      state.runningCost = action.payload;
    },
    [createRunnigCost.rejected]: (state) => {
      state.loading = false;
    },
    [getRunningCostList.pending]: (state) => {
      state.loading = true;
      state.runningCost = {};
      state.additionalRunningCost = {};
    },
    [getRunningCostList.fulfilled]: (state, action) => {
      state.loading = false;
      state.runningCost = action.payload;
    },
    [getRunningCostList.rejected]: (state) => {
      state.loading = false;
      state.runningCost = {};
    },
    [updateRunningCost.pending]: (state) => {
      state.loading = true;
    },
    [updateRunningCost.fulfilled]: (state, action) => {
      state.loading = false;
      state.runningCost = action.payload;
    },
    [updateRunningCost.rejected]: (state) => {
      state.loading = false;
    },
    [deleteRunningCost.pending]: (state) => {
      state.loading = true;
    },
    [deleteRunningCost.fulfilled]: (state, action) => {
      state.loading = false;
      state.runningCost = action.payload;
    },
    [deleteRunningCost.rejected]: (state) => {
      state.loading = false;
    },
    [getAdditionalRunningCost.pending]: (state) => {
      state.loading = true;
      state.additionalRunningCost = {};
    },
    [getAdditionalRunningCost.fulfilled]: (state, action) => {
      state.loading = false;
      state.additionalRunningCost = action.payload;
    },
    [getAdditionalRunningCost.rejected]: (state) => {
      state.loading = false;
      state.additionalRunningCost = {};
    },

    [getAdditionalFixedCost.pending]: (state) => {
      state.loading = true;
      state.additionalFixedCost = {};
    },
    [getAdditionalFixedCost.fulfilled]: (state, action) => {
      state.loading = false;
      state.additionalFixedCost = action.payload;
    },
    [getAdditionalFixedCost.rejected]: (state) => {
      state.loading = false;
      state.additionalFixedCost = {};
    },

    [getPreviousMonthRunningCost.pending]: (state) => {
      state.loading = true;
    },
    [getPreviousMonthRunningCost.fulfilled]: (state, action) => {
      state.loading = false;
      state.previousMonthRunningCost = action.payload;
    },
    [getPreviousMonthRunningCost.rejected]: (state) => {
      state.loading = false;
    },

    [getPreviousMonthFixedCost.pending]: (state) => {
      state.loading = true;
    },
    [getPreviousMonthFixedCost.fulfilled]: (state, action) => {
      state.loading = false;
      state.previousMonthFixedCost = action.payload;
    },
    [getPreviousMonthFixedCost.rejected]: (state) => {
      state.loading = false;
    },
  },
});
export const { cleanPastMonthRunningCost, cleanPastMonthFixedCost } = operationalSlice.actions;

export default operationalSlice.reducer;
