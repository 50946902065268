import { TableCell } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

function FoodMenuBaseIngredientSummaryTableCell({ size, index }) {
  const { control } = useFormContext();
  const watchData = useWatch({
    control,
    name: 'ingredients',
  });

  const findTotalOfColumn = () => {
    let total = 0;
    watchData?.forEach((item) => {
      total += Number(item?.menuSizes[index]?.cost) > 0 ? Number(item?.menuSizes[index]?.cost) : 0;
      return total;
    });
    return total.toFixed(2);
  };

  const findTotalWeightOfColumn = () => {
    let total = 0;
    watchData?.forEach((item) => {
      total += Number(item?.menuSizes[index]?.qtyUsed ?? 0);
      return total;
    });
    return total.toFixed(2);
  };

  return (
    <TableCell align="center" style={{ fontWeight: 'bold' }}>
      {findTotalWeightOfColumn()} gm / $ {findTotalOfColumn()}
    </TableCell>
  );
}

export default FoodMenuBaseIngredientSummaryTableCell;
