import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import React from 'react';
import { PATH_DASHBOARD } from 'routes/paths';
import ShiftRequestCard from 'sections/@dashboard/roaster/ShiftRequestCard';

export default function ShiftRequest() {
  return (
    <Page title="Shift-Pickup Request">
      <HeaderBreadcrumbs
        heading="Shift Pickup Request"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Roster', href: PATH_DASHBOARD.roaster.ShiftRequest },
          { name: 'Shift-Pickup Requests' },
        ]}
      />

      <ShiftRequestCard />
    </Page>
  );
}
