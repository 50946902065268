import { Card, Stack, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Scrollbar from 'components/Scrollbar';
import { TableHeadCustom } from 'components/table';
import React from 'react';
import { useSelector } from 'react-redux';
import GuideLinesTableRow from './GuideLinesTableRow';

const TABLE_HEAD = [
  { id: 'sn', label: 'SN' },
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'document', label: 'Document', align: 'left' },

  { id: 'action', label: 'Action', align: 'right' },
];

function StoreGuidelinesContent() {
  const { storeGuidelines, loading } = useSelector((state) => state.storeDocument);

  return (
    <Stack spacing={3}>
      <Card>
        <Scrollbar>
          <TableContainer>
            <Table size="small">
              <TableHeadCustom headLabel={TABLE_HEAD} />
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <CustomLoadingScreen height="30vh" />
                    </TableCell>
                  </TableRow>
                ) : (
                  storeGuidelines?.map((row, index) => <GuideLinesTableRow key={index} index={index} row={row} />)
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </Stack>
  );
}

export default StoreGuidelinesContent;
