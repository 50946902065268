import { Card } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import TimeClockFilter from './TimeClockFilter';
import TimeClockTable from './TimeClockTable';

TimeClockBody.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  setEndDate: PropTypes.func,
  setStartDate: PropTypes.func,
};

function TimeClockBody({ startDate, endDate, setEndDate, setStartDate }) {
  return (
    <Card>
      <TimeClockFilter startDate={startDate} endDate={endDate} setEndDate={setEndDate} setStartDate={setStartDate} />
      <TimeClockTable />
    </Card>
  );
}

export default TimeClockBody;
