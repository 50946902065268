import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import FoodMenuAccordion from './FoodMenuAccordion';

function FoodMenuBody() {
  const { control } = useFormContext();
  const [isExpanded, setIsExpanded] = React.useState(true);

  const { fields, move } = useFieldArray({
    control,
    name: 'data',
  });

  const handleDragEnd = (event) => {
    setIsExpanded(true);
    const { active, over } = event;
    if (!over) return;
    if (active.id !== over.id) {
      move(
        fields.findIndex((item) => item?.id === active.id),
        fields.findIndex((item) => item?.id === over.id)
      );
    }
  };

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} modifiers={[restrictToParentElement]}>
      <SortableContext items={fields} strategy={verticalListSortingStrategy}>
        {fields?.map((categories, mainIndex) => (
          <FoodMenuAccordion
            key={categories?.id}
            categories={categories}
            mainIndex={mainIndex}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
        ))}
      </SortableContext>
      {/* <DragOverlay>
        {active ? (
          <FoodMenuAccordion
            categories={fields.find((item) => item.id === active)}
            mainIndex={fields.findIndex((item) => item.id === active)}
            isExpanded={isExpanded}
            isOverlay
          />
        ) : null}
      </DragOverlay> */}
    </DndContext>
  );
}

export default FoodMenuBody;
