import { Button, Stack } from '@mui/material';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import Iconify from 'components/Iconify';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteDealCategory } from 'redux/slices/dealSlice';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import DealCategoryIndividualDeal from './DealCategoryndividualDeal';

DealOfferDealCategories.propTypes = {
  edit: PropTypes.bool,
};

function DealOfferDealCategories({ edit }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState({
    status: false,
    dealId: null,
    dealCatId: null,
    index: null,
  });
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'dealCategories',
    keyName: 'dealCategoriesId',
  });

  const RemoveSingleDeal = (el, index) => {
    if (!el?.id) {
      remove(index);
    } else {
      setOpenDeleteDialog({ status: true, dealCatId: el?.id, dealId: el?.dealId, index });
    }
  };

  const handleDealCategoryDelete = () => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        setOpenDeleteDialog({ status: false, dealId: null, dealCatId: null });
        remove(openDeleteDialog?.index);
      }
    };
    dispatch(
      deleteDealCategory({
        departmentId,
        dealId: openDeleteDialog?.dealId,
        dealCatId: openDeleteDialog?.dealCatId,
        isSuccess: isSuccessful,
      })
    );
  };

  const AddNewDealItem = () => {
    append({ qty: 0 });
  };
  return (
    <>
      <Stack gap="1rem">
        {fields?.map((el, index) => (
          <DealCategoryIndividualDeal
            key={index}
            el={el}
            index={index}
            RemoveSingleDeal={RemoveSingleDeal}
            isEdit={edit}
          />
        ))}
        <Stack alignItems={'flex-start'} marginTop={1}>
          <Button
            variant="contained"
            color="secondary"
            style={{ backgroundColor: '#4caf50' }}
            startIcon={<Iconify icon="material-symbols:add" />}
            onClick={AddNewDealItem}
          >
            Category
          </Button>
        </Stack>
      </Stack>
      {openDeleteDialog.status && (
        <CustomDeleteDialog
          open={openDeleteDialog.status}
          handleClose={() => setOpenDeleteDialog({ status: false, dealId: null, dealCatId: null })}
          onAccept={handleDealCategoryDelete}
        />
      )}
    </>
  );
}

export default DealOfferDealCategories;
