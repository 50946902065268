import { Button, Stack } from '@mui/material';
import { FormProvider, RHFTextField } from 'components/hook-form';
import React from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useForm } from 'react-hook-form';

const NewFoodSizeSchema = Yup.object().shape({
  name: Yup.string().required(''),
});

function FoodSizeForm({ handleClose, isEdit, onSubmit, currentData }) {
  const defaultValues = {
    id: currentData?.id || null,
    name: currentData?.name || '',
  };
  const methods = useForm({ defaultValues, resolver: yupResolver(NewFoodSizeSchema), mode: 'onBlur' });
  const { handleSubmit } = methods;
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack my="1rem">
        <RHFTextField name="name" label="name" />
      </Stack>

      <Stack alignItems="flex-end" justifyContent={'flex-end'} mt="1rem" direction="row" gap="1rem">
        <Button variant="contained" color="secondary" type="submit">
          {isEdit ? 'Save' : 'Create'}
        </Button>
        <Button variant="contained" onClick={handleClose} color="error">
          Cancel
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default FoodSizeForm;
