import { Box, Stack, Typography } from '@mui/material';
import { RHFCheckbox, RHFTextField } from 'components/hook-form';
import React from 'react';

function FoodMenuPriceEdit({ menu, indexList }) {
  const { mainIndex, menuIndex } = indexList;
  return (
    <Stack gap="2rem" my="1rem">
      <Stack>
        <Typography component={'h3'} fontSize="1.1rem" fontWeight={'600'}>
          {menu?.name}
        </Typography>
      </Stack>
      {menu?.sizes?.map((size, index) => (
        <Stack key={index} flexDirection={'row'} gap="1rem">
          <Stack width="20%">
            <p className="manageItemsOfMenuSizeListModalDrawer">{size?.name}</p>
          </Stack>
          <Stack flexDirection={'row'} alignItems="center" gap="1rem">
            <RHFTextField
              name={`data.${mainIndex}.menus.${menuIndex}.sizes.${index}.price`}
              size="small"
              label="Price"
              InputLabelProps={{ shrink: true }}
              // InputProps={{ sx: { background: 'white' } }}
              sx={{ width: '7rem' }}
            />
            <Stack flexDirection={'row'} alignItems="center" gap="1rem">
              isPublished :
              <RHFCheckbox name={`data.${mainIndex}.menus.${menuIndex}.sizes.${index}.isPublished`} />
            </Stack>
          </Stack>
        </Stack>
      ))}

      <Typography fontSize="0.9rem" color="red" my="1rem">
        * Make Sure to Save to Update Availability
      </Typography>
    </Stack>
  );
}

export default FoodMenuPriceEdit;
