import { Autocomplete, Box, Button, Stack, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import CustomDatePicker from 'components/CustomComponents/CustomDatePicker';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFoodCategories } from 'redux/slices/FoodCategoriesAndSizeSlice';
import { getMenuSalesDetailsList } from 'redux/slices/FoodMenuSlice';

function FoodMenuSalesFilter({ choosenDate, setChoosenDate }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const { menuSalesDetailsList } = useSelector((state) => state.foodMenus);

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getFoodCategories({ departmentId }));
    dispatch(getMenuSalesDetailsList({ departmentId, dateForMenusSold: moment(choosenDate).format('YYYY-MM-DD') }));
  }, [dispatch, departmentId, choosenDate]);
  const filterAllMenusWithCategories = menuSalesDetailsList?.map((obj) => obj?.menus).flat() || [];

  return (
    <Stack mx={'1rem'} my="1rem" direction={'row'} alignItems="center" justifyContent={'space-between'} gap="2rem">
      {/* <Autocomplete
        name="searchMenu"
        options={menuSalesDetailsList}
        getOptionLabel={(option) => option?.menus?.map((menu) => menu?.name)}
        renderInput={(params) => <TextField {...params} label="Search Menus" />}
        fullWidth
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option?.name}
          </Box>
        )}
      /> */}
      <Autocomplete
        name="searchMenu"
        options={filterAllMenusWithCategories}
        getOptionLabel={(option) => option?.name}
        onChange={(event, value, reason, details) => {
          // if (reason === 'clear') return;
          // setOpen(true);
          // onChangeSearchHandler(event, value, reason, details);
        }}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderInput={(params) => <TextField {...params} label="Search Menus" />}
        fullWidth
        renderOption={(props, option) => (
          <Stack
            component="li"
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems="flex-start"
            width="100%"
            {...props}
          >
            <Stack>{option?.name}</Stack>
            <Stack direction={'row'} ml="auto" gap="1rem">
              {option?.sizes?.map((size, index) => (
                <Stack
                  gap="1rem"
                  key={index}
                  bgcolor={'rgba(145, 158, 171, 0.12)'}
                  direction={'row'}
                  p={0.5}
                  borderRadius={'10px'}
                >
                  <Typography component={'p'}>{size?.name}</Typography>
                  <Typography component={'p'} bgcolor={'white'} p={0.5}>
                    {size?.numberSold || 0}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}
      />
      <DatePicker
        disableFuture
        label="Select Date"
        value={choosenDate}
        onChange={(date) => {
          setChoosenDate(moment(date).format('YYYY-MM-DD'));
        }}
        renderInput={(params) => <TextField {...params} error={false} />}
      />

      <Stack alignItems={'flex-end'}>
        <Button variant="contained" form="foodmenuSalesCreateFormId" type="submit">
          Save
        </Button>
      </Stack>
    </Stack>
  );
}

export default FoodMenuSalesFilter;
