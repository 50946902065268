import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFoodMenuCombo, getFoodMenuSizes } from 'redux/slices/menuEngineeringSlice';
import FoodMenuComboTable from './FoodMenuComboTable';

function FoodMenuCombo({ menu }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const { menuComboLoading } = useSelector((state) => state.menuEngineering);

  useEffect(() => {
    if (!departmentId || !menu) return;
    dispatch(getFoodMenuCombo({ menuId: menu?.id, departmentId }));
    dispatch(getFoodMenuSizes({ departmentId, menuId: menu?.id }));
  }, [departmentId, dispatch, menu]);
  return <>{menuComboLoading ? <CustomLoadingScreen height="20vh" /> : <FoodMenuComboTable menu={menu} />}</>;
}

export default FoodMenuCombo;
