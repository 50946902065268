import { Button, Table, TableContainer } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Scrollbar from 'components/Scrollbar';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { createAndUpdateRoaster, getRoaster, isNotPreviousDate, isPreviousDate } from 'redux/slices/roasterSlice';
import { useDispatch, useSelector } from 'redux/store';
import RoasterTableBody from 'sections/@dashboard/roaster/RoasterTableBody';
import RoasterTableHead from 'sections/@dashboard/roaster/RoasterTableHead';
import { findAllDaysFromWeeks } from 'utils/roasterFeatureUtils';

function RoasterCardBodyTable() {
  const dispatch = useDispatch();
  const [allWeeks, setAllWeeks] = useState([]);
  const { selectedDepartment } = useSelector((state) => state.userDepartment);
  const { userStoreDT } = useSelector((state) => state.user);
  const { loading, allRoaster } = useSelector((state) => state.roaster);
  const { fromDate, toDate } = allRoaster;

  const methods = useForm({});
  const { reset } = methods;

  // get roaster
  useEffect(() => {
    if (!userStoreDT) return;
    if (!Object.keys(selectedDepartment).length) return;
    dispatch(
      getRoaster({
        departmentId: selectedDepartment.id,
        anyDateOfWeek: userStoreDT,
      })
    );
  }, [dispatch, selectedDepartment, userStoreDT]);

  useEffect(() => {
    if (!Object.keys(allRoaster).length) return;
    const currentDates = moment(new Date()).isoWeekday(1).format('YYYY-MM-DD');
    const isSameOrAfterDay = moment(currentDates).isSameOrBefore(allRoaster?.toDate);
    if (!isSameOrAfterDay) {
      dispatch(isPreviousDate());
      return;
    }
    dispatch(isNotPreviousDate());
  }, [allRoaster, dispatch]);

  // reset form with roaster data from api
  useEffect(() => {
    if (!Object.keys(allRoaster).length) return;
    reset({ ...allRoaster, removedShiftIds: [] });
  }, [reset, allRoaster]);

  // get weeks for table headings
  useEffect(() => {
    if (!Object.keys(allRoaster).length) return;
    const getAllDates = findAllDaysFromWeeks(fromDate, toDate).map((obj, index) => ({
      id: index,
      label: moment(obj).format('ddd, DD MMMM'),
      align: 'left',
    }));
    setAllWeeks(getAllDates);
  }, [allRoaster, fromDate, toDate]);

  // roaster publish handler
  const RoasterSubmitHandler = (data) => {
    const isSuccess = (isSucceed) => {
      if (isSucceed) {
        toast.success('Roaster updated successfully');
        dispatch(
          getRoaster({
            departmentId: selectedDepartment.id,
            anyDateOfWeek: data?.toDate,
          })
        );
      }
    };
    const finalData = { ...data, departmentId: selectedDepartment.id };

    dispatch(createAndUpdateRoaster({ finalData, isSuccess }));
  };

  // roaster draft publish handler
  const RoasterDraftHandler = (data) => {
    const isSuccess = (isSucceed) => {
      if (isSucceed) {
        toast.success('Draft saved successfully');
        dispatch(
          getRoaster({
            departmentId: selectedDepartment.id,
            anyDateOfWeek: data?.toDate,
          })
        );
      }
    };
    const finalData = { ...data, departmentId: selectedDepartment.id, status: 'DRAFT' };

    dispatch(createAndUpdateRoaster({ finalData, isSuccess }));
  };

  return (
    <>
      {loading ? (
        <CustomLoadingScreen height={'30vh'} />
      ) : (
        <Scrollbar>
          <FormProvider {...methods}>
            <form
              id="roasterSubmit"
              onSubmit={methods.handleSubmit(RoasterSubmitHandler)}
              onReset={methods.handleSubmit(RoasterDraftHandler)}
            />
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table stickyHeader>
                {/* table headings */}

                <RoasterTableHead />

                {/* table body component */}

                <RoasterTableBody />
              </Table>
            </TableContainer>
          </FormProvider>
        </Scrollbar>
      )}
    </>
  );
}

export default RoasterCardBodyTable;
