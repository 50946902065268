import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Avatar, TableRow, TableCell, Typography, MenuItem, Modal, Box, Button } from '@mui/material';
import { active, getSingleUser, inActive } from 'redux/slices/userSlice';
import { removeUserFromDepartment } from 'redux/slices/userDepartmentSlice';
// components
import { PATH_DASHBOARD } from 'routes/paths';
import Label from '../../../../components/Label';
import { TableMoreMenu } from '../../../../components/table';
import UserDetailsView from '../viewDetails';

// ----------------------------------------------------------------------

UserTableRow.propTypes = {
  row: PropTypes.object,
  selected: PropTypes.bool,
  filterDep: PropTypes.string,
  tabValue: PropTypes.string,
  pageNo: PropTypes.number,
  searchUser: PropTypes.string,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function UserTableRow({ row, selected, filterDep, tabValue, pageNo, searchUser }) {
  const [openInactive, setOpenInactive] = useState(false);
  const handleCloseInactive = () => setOpenInactive(false);
  const [openReactive, setOpenReactive] = useState(false);
  const [openView, setOpenView] = useState(false);
  const handleCloseReactive = () => setOpenReactive(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);

  const theme = useTheme();

  const { id, firstName, lastName, middleName, phone, email, status, thumbnailURL } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleRemoveDept = (id, filterDep) => {
    dispatch(removeUserFromDepartment({ userId: id, departmentId: filterDep, tabValue, pageNo, searchUser }));
  };

  const handleViewClick = (userId) => {
    dispatch(getSingleUser({ userId }));
    handleCloseMenu();
    setOpenView(true);
  };
  return (
    <>
      {/* Inactivate Modal */}
      <Modal
        open={openInactive}
        onClose={handleCloseInactive}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure want to InActivate this User?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, ml: 8 }}>
            <Button
              variant="contained"
              color="success"
              sx={{ mr: 4 }}
              onClick={() => dispatch(inActive({ id, tabValue, filterDep, pageNo, searchUser }))}
            >
              Yes
            </Button>
            <Button variant="outlined" color="error" onClick={handleCloseInactive}>
              No
            </Button>
          </Typography>
        </Box>
      </Modal>

      {/* ReActivate Modal */}
      <Modal
        open={openReactive}
        onClose={handleCloseReactive}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure want to ReActivate this User?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, ml: 8 }}>
            <Button
              variant="contained"
              color="success"
              sx={{ mr: 4 }}
              onClick={() => dispatch(active({ id, tabValue, filterDep, pageNo, searchUser }))}
            >
              Yes
            </Button>
            <Button variant="outlined" color="error" onClick={handleCloseReactive}>
              No
            </Button>
          </Typography>
        </Box>
      </Modal>

      <TableRow hover selected={selected}>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          {thumbnailURL ? (
            <Avatar
              src={thumbnailURL}
              alt=""
              style={{
                height: '30px',
                width: '30px',
                borderRadius: '100%',
                marginRight: '10px',
              }}
            />
          ) : (
            <Avatar
              alt=""
              src=""
              style={{ height: '30px', width: '30px', borderRadius: '100%', marginRight: '10px' }}
            />
          )}
          <Typography variant="subtitle2" noWrap>
            {`${firstName} ${middleName || ''} ${lastName}`}
          </Typography>
        </TableCell>

        <TableCell align="left">{phone}</TableCell>

        <TableCell align="left">{email}</TableCell>

        <TableCell align="left">
          <Label
            variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
            color={(status === 'INACTIVE' && 'error') || 'success'}
            sx={{ textTransform: 'capitalize' }}
          >
            {status}
          </Label>
        </TableCell>

        <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            onOpen={(event) => setOpenMenuActions(event.currentTarget)}
            onClose={() => setOpenMenuActions(null)}
            className="tablemore"
            actions={
              <>
                {user?.userDTO?.isOwner === true ? (
                  <>
                    <MenuItem
                      onClick={() => {
                        handleViewClick(row?.id);
                      }}
                    >
                      View
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate(PATH_DASHBOARD.user.edit(id));
                        handleCloseMenu();
                      }}
                    >
                      Edit
                    </MenuItem>
                    {filterDep !== 'ALL' && filterDep && (
                      <>
                        <MenuItem
                          onClick={() => {
                            handleRemoveDept(id, filterDep);
                            handleCloseMenu();
                          }}
                        >
                          Remove From DEPT
                        </MenuItem>
                      </>
                    )}
                    {status === 'ACTIVE' && (
                      <>
                        <MenuItem
                          onClick={() => {
                            handleCloseMenu();
                            setOpenInactive(true);
                          }}
                        >
                          In Active
                        </MenuItem>
                      </>
                    )}

                    {status === 'INACTIVE' && (
                      <>
                        <MenuItem
                          onClick={() => {
                            handleCloseMenu();
                            setOpenReactive(true);
                          }}
                        >
                          Active
                        </MenuItem>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    <MenuItem
                      onClick={() => {
                        navigate(PATH_DASHBOARD.user.edit(id));
                        handleCloseMenu();
                      }}
                    >
                      Edit
                    </MenuItem>
                  </>
                )}
              </>
            }
          />
        </TableCell>
      </TableRow>
      {openView && <UserDetailsView open={openView} setOpen={setOpenView} data={row} />}
    </>
  );
}
