import { Button, Card, Grid, Stack, Tooltip } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import Iconify from 'components/Iconify';
import PropTypes from 'prop-types';
import { RHFTextField, RHFUploadAvatar } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  deleteSingleMenuProcessImage,
  getFoodMenuProcess,
  updateSingleMenuProcessField,
} from 'redux/slices/menuEngineeringSlice';

function FoodMenuProcessIndividualField({ el, index, RemoveImageField, menu }) {
  const { setValue, control, getValues } = useFormContext();
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const [openImageDelete, setOpenImageDelete] = React.useState({ status: false, id: null });
  const watchData = useWatch({ control, name: `data.${index}` });

  const updateSingleField = () => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        dispatch(getFoodMenuProcess({ departmentId, menuId: menu?.id }));
      }
    };
    const { id, note, imageUrl } = watchData;
    if (typeof imageUrl === 'string') {
      const formData = new FormData();
      formData.append(
        'menu',
        new Blob([JSON.stringify({ note, departmentId, id, menuId: menu?.id })], { type: 'application/json' })
      );
      dispatch(updateSingleMenuProcessField({ formData, isSuccess: isSuccessful }));
    } else {
      const formData = new FormData();
      formData.append('file', imageUrl);
      formData.append(
        'menu',
        new Blob([JSON.stringify({ note, departmentId, id, menuId: menu?.id })], { type: 'application/json' })
      );
      dispatch(updateSingleMenuProcessField({ formData, isSuccess: isSuccessful }));
    }
  };

  const deleteSingleProcessImage = () => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        dispatch(getFoodMenuProcess({ departmentId, menuId: menu?.id }));
        setOpenImageDelete({ status: false, id: null });
      }
    };
    dispatch(deleteSingleMenuProcessImage({ id: openImageDelete?.id, departmentId, isSuccess: isSuccessful }));
  };

  console.log('el', el);

  return (
    <>
      <Grid item xs={12}>
        <Card sx={{ p: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={3} lg={2} style={{ position: 'relative' }}>
              <RHFUploadAvatar
                name={`data.${index}.imageUrl`}
                maxSize={3145728}
                onDrop={(acceptedFiles) => {
                  const file = acceptedFiles[0];

                  if (file) {
                    setValue(
                      `data.${index}.imageUrl`,
                      Object.assign(file, {
                        preview: URL.createObjectURL(file),
                      })
                    );
                  }
                }}
              />
              {el?.id && (
                <Stack className="menuProcessImageDelete">
                  <Tooltip title="Delete Image" placement="top">
                    <div>
                      <Iconify
                        icon="maki:cross"
                        height={18}
                        width={18}
                        color={'red'}
                        onClick={() => setOpenImageDelete({ status: true, id: el?.id })}
                      />
                    </div>
                  </Tooltip>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12} md={9} lg={10}>
              <Stack direction={'column'} spacing={1} marginTop={'20px'}>
                <RHFTextField multiline minRows={3} name={`data.${index}.note`} label="Note" size="small" />
                <Stack direction={'row'} spacing={1} justifyContent={'flex-end'} my={1}>
                  {el?.id ? (
                    <Button
                      color="primary"
                      variant="contained"
                      //   disabled={!isFieldEdited}
                      onClick={() => updateSingleField()}
                    >
                      Update
                    </Button>
                  ) : null}
                  <Button color="error" variant="text" onClick={() => RemoveImageField(el, index)}>
                    <Iconify icon="solar:trash-bin-minimalistic-2-bold" height={20} width={20} />
                  </Button>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {/* <Grid item xs={12}>
        <Card sx={{ p: 1 }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4} style={{ position: 'relative' }}>
              <RHFUploadAvatar
                name={`data.${index}.imageUrl`}
                maxSize={3145728}
                onDrop={(acceptedFiles) => {
                  const file = acceptedFiles[0];

                  if (file) {
                    setValue(
                      `data.${index}.imageUrl`,
                      Object.assign(file, {
                        preview: URL.createObjectURL(file),
                      })
                    );
                  }
                }}
              />
              {el?.id && (
                <Stack style={{ position: 'absolute', cursor: 'pointer', top: '5px', right: '5px' }}>
                  {/* <Button
                color="error"
                variant="contained"
                //   disabled={!isFieldEdited}
                onClick={() => setOpenImageDelete({ status: true, id: el?.id })}
                style={{ margin: 0, padding: 0, height: '5px', width: '5px' }}
              > */}
      {/* <Tooltip title="Delete Image" placement="top">
                    <div>
                      <Iconify
                        icon="maki:cross"
                        height={18}
                        width={18}
                        color={'red'}
                        onClick={() => setOpenImageDelete({ status: true, id: el?.id })}
                      />
                    </div>
                  </Tooltip> */}
      {/* </Button> */}
      {/* </Stack>
              )}
            </Grid>
            <Grid item sm={0.5} />
            <Grid item xs={12} sm={7.5}>
              <RHFTextField multiline minRows={3} name={`data.${index}.note`} label="Note" size="small" />
              <Stack direction={'row'} spacing={1} justifyContent={'center'} my={1}>
                <Button color="error" variant="contained" onClick={() => RemoveImageField(el, index)}>
                  <Iconify icon="solar:trash-bin-minimalistic-2-bold" /> Remove
                </Button>
                {el?.id ? (
                  <Button
                    color="primary"
                    variant="contained"
                    //   disabled={!isFieldEdited}
                    onClick={() => updateSingleField()}
                  >
                    Update
                  </Button>
                ) : null}
              </Stack>
            </Grid>
          </Grid>
        </Card>
      </Grid> */}
      {openImageDelete?.status && (
        <CustomDeleteDialog
          open={openImageDelete?.status}
          handleClose={() => setOpenImageDelete({ status: false, id: null })}
          onAccept={deleteSingleProcessImage}
        />
      )}
    </>
  );
}

FoodMenuProcessIndividualField.propTypes = {
  el: PropTypes.object,
  index: PropTypes.number,
  RemoveImageField: PropTypes.func,
  menu: PropTypes.object,
};

export default FoodMenuProcessIndividualField;
