import { Autocomplete, Button, Stack, TextField } from '@mui/material';
import { FormProvider, RHFTextField } from 'components/hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

const NewFoodCategoriesSchema = Yup.object().shape({
  name: Yup.string().required(''),
});

function FoodCategoriesForm({ handleClose, isEdit = false, onSubmit, currentData }) {
  const { sizeList } = useSelector((state) => state.foodCategoriesAndSize);

  const defaultValues = {
    id: currentData?.id || null,
    name: currentData?.name || '',
    addedSizes: currentData?.sizes || [],

    removedSizes: [],
  };
  const methods = useForm({ defaultValues, resolver: yupResolver(NewFoodCategoriesSchema), mode: 'onBlur' });
  const { handleSubmit, control, watch } = methods;

  // const { fields, append, replace } = useFieldArray({
  //   control,
  //   name: 'addedSizes',
  //   keyName: 'sizeIds',
  // });

  const {
    fields: removeFields,
    append: removeAppend,
    remove: removeRemove,
  } = useFieldArray({
    control,
    name: 'removedSizes',
    keyName: 'removeSizeId',
  });
  const sizeOnChangeHandler = (event, data, reason, details) => {
    if (!isEdit) return;
    if (reason === 'removeOption') {
      removeAppend(details?.option);
      // removeAppend(data);
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack my="1rem" gap="1rem">
        <RHFTextField name="name" label="name" />

        {/* <CustomMultipleAutoCompleteField name="addedSizes" label="Added Sizes" options={sizeList?.sizes} /> */}
        <Controller
          control={control}
          name="addedSizes"
          render={({ field: { onChange, ...field } }) => (
            <Autocomplete
              label="Select Sizes"
              multiple
              onChange={(event, data, reason, details) => {
                onChange(data);
                sizeOnChangeHandler(event, data, reason, details);
              }}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              {...field}
              options={sizeList?.sizes || []}
              renderInput={(params) => <TextField {...params} label="Select Sizes" />}
            />
          )}
        />
      </Stack>

      <Stack alignItems="flex-end" justifyContent={'flex-end'} mt="1rem" direction="row" gap="1rem">
        <Button variant="contained" color="secondary" type="submit">
          {isEdit ? 'Save' : 'Create'}
        </Button>
        <Button variant="contained" onClick={handleClose} color="error">
          Cancel
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default FoodCategoriesForm;
