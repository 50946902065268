import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, Stack, TextField } from '@mui/material';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import CustomMultipleAutoCompleteField from 'components/CustomComponents/CustomAutoCompleteField';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { FormProvider } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { changeTimeClockStatus, createTimeClockByManager } from 'redux/slices/timeSlice';
import { getAllUserss } from 'redux/slices/userSlice';
import PropTypes from 'prop-types';
import { convertDatetoString } from 'utils/customDateAndTimeFormat';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { errorToastTime } from 'config';

AddTimeClockDrawer.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setStartDate: PropTypes.func,
  setEndDate: PropTypes.func,
};

function AddTimeClockDrawer({ open, handleClose, setStartDate, setEndDate, startDate, endDate }) {
  const [startDateTime, setStartDateTime] = React.useState(new Date());
  const [endDateTime, setEndDateTime] = React.useState(new Date());

  const { allUsers } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const TimeDurationSchema = Yup.object().shape({
    // clockInDatetime: Yup.date().required(''),
    // clockOutDatetime: Yup.date()
    //   .required('')
    //   // eslint-disable-next-line func-names
    //   .test('clockInDateTime', 'Clock out Date should not be more than 16 hours from Clock In Date', function (value) {
    //     // eslint-disable-next-line react/no-this-in-sfc
    //     const { clockInDatetime } = this.parent;
    //     if (!clockInDatetime || !value) return true;
    //     const start = new Date(clockInDatetime);
    //     const end = new Date(value);
    //     const diff = Math.abs(end - start) / (1000 * 60 * 60);

    //     return diff <= 16;
    //   }),
    userId: Yup.object()
      .shape({
        id: Yup.number().nullable().required(''),
      })
      .nullable(),
  });

  const defaultValue = {
    clockInDatetime: new Date(),
    clockOutDatetime: new Date(),
  };

  const methods = useForm({ defaultValues: defaultValue, resolver: yupResolver(TimeDurationSchema), mode: 'onChange' });
  const { handleSubmit } = methods;

  // console.log(errors);

  const AddTimeDurationHandler = (data) => {
    if (moment(endDateTime).isBefore(startDateTime))
      return toast.error('Clock Out Date should not be less than Clock In Date', { autoClose: errorToastTime });
    const finalData = {
      departmentId,
      employeeId: data?.userId?.id,
      // clockInDatetime: convertDatetoString(data?.clockInDatetime, 'YYYY-MM-DDTHH:mm:ss'),
      // clockOutDatetime: convertDatetoString(data?.clockOutDatetime, 'YYYY-MM-DDTHH:mm:ss'),
      clockInDatetime: convertDatetoString(startDateTime, 'YYYY-MM-DDTHH:mm:ss'),
      clockOutDatetime: convertDatetoString(endDateTime, 'YYYY-MM-DDTHH:mm:ss'),
    };
    dispatch(createTimeClockByManager({ data: finalData })).then(() => {
      setStartDate(moment(startDateTime).format('YYYY-MM-DD'));
      setEndDate(moment(endDateTime).format('YYYY-MM-DD'));
      handleClose();
      dispatch(changeTimeClockStatus('APPROVED'));
    });
  };

  useEffect(() => {
    if (!startDate || !endDate) return;
    if (moment(startDate).format('YYYY-MM-DD') !== moment(endDate).format('YYYY-MM-DD')) return;
    setStartDateTime(new Date(startDate));
    setEndDateTime(new Date(endDate));
  }, [startDate, endDate]);

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getAllUserss({ departmentId, statusList: ['ACTIVE'] }));
  }, [departmentId, dispatch]);

  // const watchClockOutTime = methods?.watch('clockOutDatetime');
  // const watchClockInTime = methods?.watch('clockInDatetime');

  return (
    <CustomOutletModal open={open} onClose={handleClose} zIndex="1600" title="Create Time Duration">
      <FormProvider methods={methods} onSubmit={handleSubmit(AddTimeDurationHandler)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <CustomMultipleAutoCompleteField
              name="userId"
              label="Select User"
              options={allUsers?.users}
              getOptionLabel={(option) => `${option.firstName} ${option.lastName} + ${option.phone}`}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <img width="20" src={option.thumbnailURL} alt="" />
                  {option.firstName} {option.lastName} + {option.phone}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={6} />
          <Grid item xs={6}>
            <MobileDateTimePicker
              label="Clock In Date"
              value={startDateTime}
              DialogProps={{ sx: { zIndex: 4000 } }}
              // maxDateTime={endDateTime}
              inputFormat="dd/MMM/yyyy hh:mm a"
              onChange={(newValue) => {
                setStartDateTime(newValue);
              }}
              renderInput={(props) => <TextField {...props} />}
            />
          </Grid>
          <Grid item xs={6}>
            <MobileDateTimePicker
              label="Clock Out Date"
              value={endDateTime}
              DialogProps={{ sx: { zIndex: 4000 } }}
              minDateTime={startDateTime}
              inputFormat="dd/MMM/yyyy hh:mm a"
              onChange={(newValue) => {
                setEndDateTime(newValue);
              }}
              renderInput={(props) => <TextField {...props} />}
            />
          </Grid>
          {/* <Grid item xs={6}>
            <Controller
              name={'clockInDatetime'}
              control={control}
              errors={errors}
              render={({ field }) => (
                <MobileDateTimePicker
                  DialogProps={{ sx: { zIndex: 4000 } }}
                  label="Clock In"
                  defaultValue={selectedDate}
                  maxDateTime={watchClockOutTime}
                  inputFormat="dd/MMM/yyyy hh:mm a"
                  {...field}
                  renderInput={(params) => <TextField required fullWidth {...params} />}
                />
              )}
            />
            {errors?.clockInDatetime && <p style={{ color: 'red' }}>{errors?.clockInDatetime?.message}</p>}
          </Grid> */}

          {/* <Grid item xs={6}>
            <Controller
              name={'clockOutDatetime'}
              control={control}
              errors={errors}
              render={({ field }) => (
                <MobileDateTimePicker
                  DialogProps={{ sx: { zIndex: 4000 } }}
                  label="Clock Out"
                  minDateTime={watchClockInTime}
                  inputFormat="dd/MMM/yyyy hh:mm a"
                  {...field}
                  renderInput={(params) => <TextField required fullWidth {...params} />}
                />
              )}
            />
            {errors?.clockOutDatetime && <p style={{ color: 'red' }}>{errors?.clockOutDatetime?.message}</p>}
          </Grid> */}
          <Grid item xs={12}>
            <Stack alignItems={'flex-end'}>
              <div>
                <Button type="submit" variant="contained" style={{ marginRight: '5px' }}>
                  Create
                </Button>
                <Button variant="text" style={{ color: 'red' }} onClick={handleClose}>
                  Cancel
                </Button>
              </div>
            </Stack>
          </Grid>
        </Grid>
      </FormProvider>
    </CustomOutletModal>
  );
}

export default AddTimeClockDrawer;
