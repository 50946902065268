import { Button, Card, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import { RHFCheckbox } from 'components/hook-form';
import { RHFFullImageUpload } from 'components/hook-form/RHFFullImageUpload';
import React, { useCallback } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';

function StaffCreateActiveStepperPaperworkDeliveryDriver({ isEdit }) {
  const { control, setValue, getValues, register, resetField } = useFormContext();

  const watchIsDeliveryDriver = useWatch({
    control,
    name: 'isDeliveryDriver',
  });

  const watchInsurancePDF = useWatch({
    control,
    name: 'insurance',
  });

  const watchDrivingLicense = useWatch({
    control,
    name: 'drivingLicense',
  });

  const watchRegoPaperworkPDF = useWatch({
    control,
    name: 'rezoPaperWork',
  });

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'drivingLicense',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  const manageDeliveryDriver = (event) => {
    setValue('isDeliveryDriver', event.target.checked);
    if (!event.target.checked) {
      resetField('drivingLicense');
      resetField('insurance');
      resetField('rezoPaperWork');
    }
  };

  const getLastDetails = (data) => data?.split('/').slice(-1)[0];
  const getInsuranceValues = getValues('insuranceURL') || '';
  const getRezoPaperWorkValue = getValues('rezoPaperWorkURL') || '';
  const getLicenseValues = getValues('drivingLicenseURL') || '';

  const getInsuranceURL = getValues('insuranceURL') ? getLastDetails(getValues('insuranceURL')) : '';
  const getRezoPaperWorkURL = getValues('rezoPaperWorkURL') ? getLastDetails(getValues('rezoPaperWorkURL')) : '';

  const { fields, append } = useFieldArray({
    name: 'removedPaperworks',
  });

  const isDrivingLicenseCheck =
    watchDrivingLicense ||
    watchInsurancePDF ||
    watchRegoPaperworkPDF ||
    getLicenseValues ||
    getInsuranceValues ||
    getRezoPaperWorkValue;

  return (
    <Grid item xs={12} md={6}>
      <Card sx={{ py: 3, px: 3 }}>
        <Stack px={2}>
          <FormControlLabel
            label="Are you a Delivery Driver ?"
            control={
              <RHFCheckbox name="isDeliveryDriver" onChange={manageDeliveryDriver} disabled={isDrivingLicenseCheck} />
            }
          />
        </Stack>
        {watchIsDeliveryDriver && (
          <Stack>
            <Stack my={2} gap="0.5rem" py={2} alignItems={'center'} bgcolor={'rgba(145, 158, 171, 0.05)'}>
              <Typography component="p" color="error">
                Driving License *
              </Typography>
              <RHFFullImageUpload
                name="drivingLicense"
                onDrop={handleDrop}
                isEditImage={isEdit ? { isEdit, imgUrl: getValues('drivingLicenseURL') || null } : null}
              />

              {(watchDrivingLicense || getLicenseValues) && (
                <Button
                  color="error"
                  onClick={() => {
                    resetField('drivingLicense');
                    setValue('drivingLicenseURL', '');
                    if (!fields.includes('DRIVING_LICENSE')) append('DRIVING_LICENSE');
                  }}
                >
                  Remove
                </Button>
              )}
            </Stack>
            <Stack my={1} gap="0.5rem" py={2} alignItems={'center'} bgcolor={'rgba(145, 158, 171, 0.05)'}>
              <Stack gap="0.5rem" alignItems={'center'}>
                <Typography component="p" color="error">
                  Insurance PDF *
                </Typography>
                <Button variant="contained" sx={{ padding: '0 !important' }}>
                  <label htmlFor="insurance" style={{ padding: '0.5rem', cursor: 'pointer' }}>
                    <input
                      type="file"
                      {...register('insurance')}
                      accept="application/pdf"
                      id="insurance"
                      hidden
                      onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                          setValue('insurance', file);
                        }
                      }}
                    />
                    <Stack>
                      Upload PDF
                      <Typography component="span" fontSize="0.8rem" color={'info'}>
                        {watchInsurancePDF?.name || getInsuranceURL || ''}
                      </Typography>
                    </Stack>
                  </label>
                </Button>
                <Stack direction="row" alignItems="center" gap={1}>
                  {isEdit && getInsuranceValues && (
                    <a href={getInsuranceValues} download target="_blank" rel="noreferrer">
                      Download
                    </a>
                  )}
                  {(watchInsurancePDF || getInsuranceValues) && (
                    <Button
                      color="error"
                      onClick={() => {
                        resetField('insurance');
                        setValue('insuranceURL', '');
                        if (!fields.includes('INSURANCE')) append('INSURANCE');
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </Stack>
              </Stack>

              <Stack gap="0.5rem" alignItems="center">
                <Typography component="p" color="error">
                  Rego Paperwork PDF *
                </Typography>
                <Button variant="contained" sx={{ padding: '0 !important' }}>
                  <label htmlFor="rezoPaperWork" style={{ padding: '0.5rem', cursor: 'pointer' }}>
                    <input
                      type="file"
                      {...register('rezoPaperWork')}
                      accept="application/pdf"
                      id="rezoPaperWork"
                      hidden
                      onChange={(event) => {
                        const file = event.target.files[0];
                        if (file) {
                          setValue('rezoPaperWork', file);
                        }
                      }}
                    />
                    <Stack>
                      Upload PDF
                      <Typography component="span" fontSize="0.8rem" color={'info'}>
                        {watchRegoPaperworkPDF?.name || getRezoPaperWorkURL || ''}
                      </Typography>
                    </Stack>
                  </label>
                </Button>
                <Stack direction="row" gap={1} alignItems={'center'}>
                  {isEdit && getRezoPaperWorkValue && (
                    <a href={getRezoPaperWorkValue} download target="_blank" rel="noreferrer">
                      Download
                    </a>
                  )}
                  {(watchRegoPaperworkPDF || getRezoPaperWorkValue) && (
                    <Button
                      color="error"
                      onClick={() => {
                        resetField('rezoPaperWork');
                        setValue('rezoPaperWorkURL', '');
                        if (!fields.includes('REZO_PAPERWORK')) append('REZO_PAPERWORK');
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        )}
      </Card>
    </Grid>
  );
}

export default StaffCreateActiveStepperPaperworkDeliveryDriver;
