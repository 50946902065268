// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  verify: path(ROOTS_AUTH, '/verify'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  request: path(ROOTS_AUTH, '/request/'),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    calendar: path(ROOTS_DASHBOARD, '/calendar'),
    announcement: path(ROOTS_DASHBOARD, '/announcement'),

    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/chat/${name}`),
  },
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    new: path(ROOTS_DASHBOARD, '/user/new'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    requestList: path(ROOTS_DASHBOARD, '/user/requestList'),
    hrOhns: path(ROOTS_DASHBOARD, '/user/hrOhns'),
    requestListEdit: (id) => path(ROOTS_DASHBOARD, `/user/requestList/edit/${id}`),

    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    edit: (id) => path(ROOTS_DASHBOARD, `/user/editUser/${id}`),
    demoEdit: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    // sendHr: path(ROOTS_DASHBOARD, '/user/sendHr'),
    // sendOHNS: path(ROOTS_DASHBOARD, '/user/sendOHNS'),
    // sendSOP: path(ROOTS_DASHBOARD, '/user/sendSOP'),
  },
  department: {
    root: path(ROOTS_DASHBOARD, '/department'),
    departmentRole: path(ROOTS_DASHBOARD, '/department/departmentRole'),
    depUserRole: path(ROOTS_DASHBOARD, '/department/depUserRole'),
    depRoleFeature: path(ROOTS_DASHBOARD, '/department/depRoleFeature'),
  },
  store: {
    root: path(ROOTS_DASHBOARD, '/store'),
    specialday: path(ROOTS_DASHBOARD, '/store/specialday'),
    break: path(ROOTS_DASHBOARD, '/store/break'),
    storedetail: path(ROOTS_DASHBOARD, '/store/storedetail'),
    // onlineSalesPlatform: path(ROOTS_DASHBOARD, '/store/onlineSalesPlatform'),
    // storeCashDrawer: path(ROOTS_DASHBOARD, '/store/storeCashDrawer'),
    // driverIncentive: path(ROOTS_DASHBOARD, '/store/driverIncentive'),
    zones: path(ROOTS_DASHBOARD, '/store/zones'),
    storeSuppliers: path(ROOTS_DASHBOARD, '/store/storeSuppliers'),
    halfAndHalf: path(ROOTS_DASHBOARD, '/store/halfAndHalf'),
    storeConfig: path(ROOTS_DASHBOARD, '/store/storeConfig'),
    tradingHours: path(ROOTS_DASHBOARD, '/store/tradingHours'),
    posConfig: path(ROOTS_DASHBOARD, '/store/posConfig'),
  },
  time: {
    root: path(ROOTS_DASHBOARD, '/time'),
    timeduration: path(ROOTS_DASHBOARD, '/time/timeduration'),
    timeclock: path(ROOTS_DASHBOARD, '/time/timeclock'),
    approvedReports: path(ROOTS_DASHBOARD, '/time/approvedReports'),
    edit: (id) => path(ROOTS_DASHBOARD, `/time/editApproval/${id}`),
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    new: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    view: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}`),
    edit: (name) => path(ROOTS_DASHBOARD, `/e-commerce/product/${name}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
    demoView: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  },
  invoice: {
    root: path(ROOTS_DASHBOARD, '/invoice'),
    list: path(ROOTS_DASHBOARD, '/invoice/list'),
    new: path(ROOTS_DASHBOARD, '/invoice/new'),
    view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
    edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
    demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
    demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
  },
  roaster: {
    root: path(ROOTS_DASHBOARD, '/roaster'),
    list: path(ROOTS_DASHBOARD, '/roaster/list'),
    userNote: path(ROOTS_DASHBOARD, '/roaster/userNote'),
    leaveRequest: path(ROOTS_DASHBOARD, '/roaster/leaveRequest'),
    shiftPickupRequest: path(ROOTS_DASHBOARD, '/roaster/shiftPickupRequest'),
    shiftDropRequest: path(ROOTS_DASHBOARD, '/roaster/shiftDropRequest'),
    quickSummary: path(ROOTS_DASHBOARD, '/roaster/quickSummary'),
  },

  stock: {
    root: path(ROOTS_DASHBOARD, '/stock'),
    list: path(ROOTS_DASHBOARD, '/stock/list'),
    ingredients: path(ROOTS_DASHBOARD, '/stock/ingredients'),
    receipe: path(ROOTS_DASHBOARD, '/stock/receipe'),
  },

  food: {
    root: path(ROOTS_DASHBOARD, '/food'),
    list: path(ROOTS_DASHBOARD, '/food/list'),
    categories: path(ROOTS_DASHBOARD, '/food/category/list'),
    menuEngineering: path(ROOTS_DASHBOARD, '/food/menuEngineering'),
    menuCategory: path(ROOTS_DASHBOARD, '/food/menuCategory'),
    cogsReport: path(ROOTS_DASHBOARD, '/food/cogsReport'),
    dealOffer: path(ROOTS_DASHBOARD, '/food/dealOffer'),
    createDealOffer: path(ROOTS_DASHBOARD, '/food/createDealOffer'),
    editDeal: (id) => path(ROOTS_DASHBOARD, `/food/editDeal/${id}`),
    mostLikedMenus: path(ROOTS_DASHBOARD, '/food/mostLikedMenus'),
    recommendedMenus: path(ROOTS_DASHBOARD, '/food/recommendedMenus'),
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    new: path(ROOTS_DASHBOARD, '/blog/new'),
    view: (title) => path(ROOTS_DASHBOARD, `/blog/post/${title}`),
    demoView: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  },

  operationalCost: {
    root: path(ROOTS_DASHBOARD, '/operationalCost'),
    runningCost: path(ROOTS_DASHBOARD, '/operationalCost/runningCost'),
    fixedCost: path(ROOTS_DASHBOARD, '/operationalCost/fixedCost'),
    // additionalRunningCost: path(ROOTS_DASHBOARD, '/operationalCost/additionalRunningCost'),
  },
  report: {
    root: path(ROOTS_DASHBOARD, '/report'),
    list: path(ROOTS_DASHBOARD, '/report/list'),
    insights: path(ROOTS_DASHBOARD, '/report/insights'),
    userWork: path(ROOTS_DASHBOARD, '/report/userWork'),
    userworkReport: path(ROOTS_DASHBOARD, '/report/userworkReport'),
  },
  sales: {
    root: path(ROOTS_DASHBOARD, '/sales'),
    list: path(ROOTS_DASHBOARD, '/sales/list'),
    itemSummary: path(ROOTS_DASHBOARD, '/sales/itemSummary'),
    storeCashDrawer: path(ROOTS_DASHBOARD, '/sales/storeCashDrawer'),
    driverIncentive: path(ROOTS_DASHBOARD, '/sales/driverIncentive'),
    onlineSalesPlatform: path(ROOTS_DASHBOARD, '/sales/onlineSalesPlatform'),
    edit: (id) => path(ROOTS_DASHBOARD, `/sales/editSales/${id}`),
    addSales: path(ROOTS_DASHBOARD, '/sales/addSales'),
    payType: path(ROOTS_DASHBOARD, '/sales/payType'),
  },

  documents: {
    root: path(ROOTS_DASHBOARD, '/documents'),
    guidelines: path(ROOTS_DASHBOARD, '/documents/guidelines'),
    logs: path(ROOTS_DASHBOARD, '/documents/logs'),
    checklist: path(ROOTS_DASHBOARD, '/documents/checklist'),
    // createChecklist: path(ROOTS_DASHBOARD, '/documents/checklist/createChecklist'),
    // editChecklist: (id) => path(ROOTS_DASHBOARD, `/documents/checklist/editChecklist/${id}`),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
