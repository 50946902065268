import { Button, Stack } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import { useState } from 'react';
import { PATH_DASHBOARD } from 'routes/paths';
import HrCreateEmail from './HrCreateEmail';
import HrDocumentUpload from './HrDocumentUpload';

function HrHeader() {
  const [open, setOpen] = useState(false);
  const [openUpload, setOpenUpload] = useState(false);
  const SendEmailHandler = () => {
    setOpen(true);
  };
  const uploadDocumentHandler = () => {
    setOpenUpload(true);
  };
  return (
    <>
      <HeaderBreadcrumbs
        heading="Documents  List"
        links={[{ name: 'Dashboard', href: PATH_DASHBOARD.root }, { name: 'Documents' }]}
        action={
          <Stack gap={'1rem'} direction={'row'} justifyContent={'flex-end'}>
            <Button variant="contained" startIcon={<Iconify icon={'eva:plus-fill'} />} onClick={uploadDocumentHandler}>
              Upload
            </Button>
            <Button variant="contained" startIcon={<Iconify icon={'eva:plus-fill'} />} onClick={SendEmailHandler}>
              Send Email
            </Button>
          </Stack>
        }
      />
      {}
      {open && <HrCreateEmail open={open} setOpen={setOpen} />}
      {openUpload && <HrDocumentUpload open={openUpload} setOpen={setOpenUpload} />}
    </>
  );
}

export default HrHeader;
