import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import React from 'react';
import { useSelector } from 'redux/store';

const TABLE_HEAD = [
  { id: 1, name: 'UID' },
  { id: 2, name: 'Brand (Description)' },
  { id: 3, name: 'Order Qty' },
  { id: 4, name: 'Unit Price' },
  { id: 5, name: 'Total' },
];

function ViewOrderDetailsModal({ open, setOpen, currentValue }) {
  const { storeSuppliers, store } = useSelector((state) => state.store);
  const getSupplierName = (id) => {
    const getSupplier = storeSuppliers.find((obj) => obj.id === id);
    if (!getSupplier) return '';
    return getSupplier.name;
  };

  return (
    <CustomDrawer open={open} setOpen={setOpen} title="View Order Details">
      <Stack gap="1.5rem" className="stockOrderConfirmModalContainer">
        {currentValue?.orderItems?.map((el, index) => {
          const supplier = storeSuppliers.find((obj) => obj.id === Number(el?.supplierId));
          const changedSupplier = storeSuppliers.find((obj) => obj.id === Number(el?.changedSupplierId));

          return (
            <Stack key={index} className="stockOrderConfirmModalContent">
              <Stack
                className="stackOrderModalContent"
                flexDirection={{ md: 'row', xs: 'column' }}
                gap="1rem"
                justifyContent="space-between"
              >
                <Stack my={'0.8rem'}>
                  <p>
                    Supplier : <span> {changedSupplier?.name || supplier?.name}</span>
                  </p>
                  <p>
                    Email : <span> {supplier?.email}</span>
                  </p>
                </Stack>
                <Stack my={'0.8rem'}>
                  <p>
                    Payment Due : <span> {supplier?.dueDays}</span>
                  </p>
                  <p>
                    ABN : <span> {supplier?.abn}</span>
                  </p>
                </Stack>
              </Stack>

              <Stack className="stackOrderModalContent2">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {TABLE_HEAD.map((obj) => (
                          <TableCell key={obj.id}>{obj?.name}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {el?.data?.map((obj, index) => {
                        const total = Number(obj?.orderQty) * Number(obj?.unitPrice);
                        return (
                          <TableRow key={index} sx={{ borderBottom: '1px solid #F4F6F8' }}>
                            <TableCell>{obj?.externalUID}</TableCell>
                            <TableCell>
                              <p>{obj?.brand}</p>
                              <span>{obj?.productDescription}</span>
                            </TableCell>
                            <TableCell>{obj?.orderQty}</TableCell>
                            <TableCell>{obj?.unitPrice}</TableCell>
                            <TableCell>$ {obj?.amount}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </CustomDrawer>
  );
}

export default ViewOrderDetailsModal;
