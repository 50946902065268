import Page from 'components/Page';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdditionalRunningCost, getRunningCostList } from 'redux/slices/operationalSlice';
import RunningCostBody from 'sections/@dashboard/operationalCost/RunningCostBody';
import RunningCostHeader from 'sections/@dashboard/operationalCost/RunningCostHeader';

export default function RunningCost() {
  const dispatch = useDispatch();
  const [choosenDate, setChoosenDate] = useState();
  const { timeDT } = useSelector((state) => state.user);
  const departmentId = useGetDepartmentId();
  const { runningCost } = useSelector((state) => state.operational);

  useEffect(() => {
    if (!timeDT) return;
    setChoosenDate(timeDT);
  }, [timeDT]);

  useEffect(() => {
    if (!departmentId) return;
    if (!choosenDate) return;
    dispatch(
      getRunningCostList({
        departmentId,
        month: moment(choosenDate).format('MM'),
        year: moment(choosenDate).format('YYYY'),
      })
    );
  }, [departmentId, choosenDate, dispatch]);

  useEffect(() => {
    if (!departmentId) return;
    if (!runningCost?.id) return;
    dispatch(getAdditionalRunningCost({ departmentId, runningCostId: runningCost?.id }));
  }, [runningCost, departmentId, dispatch]);
  return (
    <Page title="Operational: running cost">
      <RunningCostHeader choosenDate={choosenDate} setChoosenDate={setChoosenDate} />
      <RunningCostBody choosenDate={choosenDate} setChoosenDate={setChoosenDate} />
    </Page>
  );
}
