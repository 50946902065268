import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

function FoodRecipeFormCalculatedInformation({ index }) {
  const { control } = useFormContext();

  const calculated = useWatch({
    control,
    name: `receipeDTOs.${index}.calculated`,
  });

  return (
    <Stack className="foodRecipeFormCalculatedInformationContainer">
      <Stack>
        <Typography component="p" fontWeight="600">
          Diff
        </Typography>
        <Typography component="span" fontWeight="500" width="max-content">
          $ {calculated?.diff.toFixed(2)}
        </Typography>
        <Typography component="span" fontWeight="500" width="max-content">
          {calculated?.diffPercent.toFixed(2)} %
        </Typography>
      </Stack>

      <Stack alignItems="flex-start">
        <Typography component="p" fontWeight="600">
          cogs
        </Typography>
        <Typography component="span" fontWeight="500" width="max-content">
          $ {calculated?.cogs.toFixed(2)}
        </Typography>
        <Typography component="span" fontWeight="500" width="max-content">
          {calculated?.cogsPercent === 'NaN' ? 0 : calculated?.cogsPercent?.toFixed(2)} %
        </Typography>
      </Stack>
      <Stack flexDirection="column">
        <Typography component="p" fontWeight="600">
          sp :
          <Typography component="span" fontWeight="500 ">
            {' '}
            $ {calculated?.sp.toFixed(2)}
          </Typography>
        </Typography>

        <Typography component="p" width="max-content" fontWeight="600">
          ExstGST
        </Typography>
        <Typography component="span" fontWeight="500">
          {' '}
          ${calculated?.spExstGst.toFixed(2)}
        </Typography>
      </Stack>

      <Stack>
        <Typography component="p" fontWeight="600">
          GP
        </Typography>
        <Typography component="span" fontWeight="500" width="max-content">
          ${calculated?.grossProfit.toFixed(2)}
        </Typography>
        <Typography component="span" fontWeight="500" width="max-content">
          {calculated?.grossProfitPercent.toFixed(2)} %
        </Typography>
      </Stack>
    </Stack>
  );
}

export default FoodRecipeFormCalculatedInformation;
