import * as Yup from 'yup';

const salesSchema = Yup.object({
  cashDrawers: Yup.array().of(
    Yup.object().shape({
      fiveCents: Yup.number()
        .min(0)
        .integer('')
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .nullable()
        .typeError(),
      tenCents: Yup.number()
        .integer('')
        .min(0)
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .nullable()
        .typeError(),
      twentyCents: Yup.number()
        .integer('')
        .min(0)
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .nullable()
        .typeError(''),
      fiftyCents: Yup.number()
        .integer('')
        .min(0)
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .nullable()
        .typeError(''),
      oneDollors: Yup.number()
        .integer('')
        .min(0)
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .nullable()
        .typeError(''),
      twoDollors: Yup.number()
        .integer('')
        .min(0)
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .nullable()
        .typeError(''),
      fiveDollors: Yup.number()
        .integer('')
        .min(0)
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .nullable()
        .typeError(''),
      tenDollors: Yup.number()
        .integer('')
        .min(0)
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .nullable()
        .typeError(''),
      twentyDollors: Yup.number()
        .integer('')
        .min(0)
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .nullable()
        .typeError(''),
      fiftyDollors: Yup.number()
        .integer('')
        .min(0)
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .nullable()
        .typeError(''),
      hundredDollors: Yup.number()
        .integer('')
        .min(0)
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .nullable()
        .typeError(''),
    })
  ),
  platformSales: Yup.array().of(
    Yup.object().shape({
      amount: Yup.number()
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .typeError(''),
      qty: Yup.number()
        .integer('')
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .typeError(''),
    })
  ),
  storeSales: Yup.number().required('').typeError(''),
  paymentTypeSales: Yup.array().of(
    Yup.object().shape({
      amount: Yup.number()
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .typeError(''),
    })
  ),
  pcEft: Yup.number()
    .transform((curr, orig) => (orig === '' ? 0 : curr))
    .typeError(''),
  // eft: Yup.number()
  //   .transform((curr, orig) => (orig === '' ? 0 : curr))
  //   .typeError(''),
  // storeOnline: Yup.number()
  //   .transform((curr, orig) => (orig === '' ? 0 : curr))
  //   .typeError(''),
  // voucher: Yup.number()
  //   .transform((curr, orig) => (orig === '' ? 0 : curr))
  //   .typeError(''),
  // other: Yup.number()
  //   .transform((curr, orig) => (orig === '' ? 0 : curr))
  //   .typeError(''),
  // totalUnpaid: Yup.number()
  //   .transform((curr, orig) => (orig === '' ? 0 : curr))
  //   .typeError(''),
  // totalOnlineOrder: Yup.number()
  //   .transform((curr, orig) => (orig === '' ? 0 : curr))
  //   .typeError(''),
  totalDeliveryCount: Yup.number()
    .integer('')
    .transform((curr, orig) => (orig === '' ? 0 : curr))
    .typeError(''),
  note: Yup.string().nullable().max(256, 'Maximum 256 characters only'),
  legend: Yup.array().of(
    Yup.array().of(
      Yup.object().shape({
        deliveryCount: Yup.number()
          .integer('')
          .transform((curr, orig) => (orig === '' ? 0 : curr))
          .typeError(''),
      })
    )
  ),
});

export default salesSchema;
