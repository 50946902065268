import { Avatar, Button, TableCell, TableRow, Typography } from '@mui/material';
import Iconify from 'components/Iconify';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import UserWorkReportDetailsViewDrawer from 'sections/@dashboard/report/userWorkInfo/UserWorkReportDetailsViewDrawer';

UserWorkReportIndividualRow.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  departmentId: PropTypes.string,
};

function UserWorkReportIndividualRow({ item, index, startDate, endDate, departmentId }) {
  const [data, setData] = useState(null);
  const [detailsView, setDetailsView] = useState(false);
  const viewClicked = (row) => {
    setDetailsView(true);
    setData(row);
    // dispatch(getIndividualUserWorkReport({ id: row?.id, startDate, endDate }));
  };
  return (
    <>
      <TableRow hover>
        <TableCell>{index + 1}.</TableCell>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          {item?.thumbnailURL ? (
            <Avatar
              src={item?.thumbnailURL}
              alt=""
              style={{
                height: '30px',
                width: '30px',
                borderRadius: '100%',
                marginRight: '10px',
              }}
            />
          ) : (
            <Avatar
              alt=""
              src=""
              style={{ height: '30px', width: '30px', borderRadius: '100%', marginRight: '10px' }}
            />
          )}
          <Typography variant="subtitle2" noWrap>
            {`${item?.firstName} ${item?.middleName ?? ''} ${item?.lastName}`}
          </Typography>
        </TableCell>
        <TableCell>{item?.workTimeInMinutes ? `${(item?.workTimeInMinutes / 60).toFixed(2)} Hrs` : 0}</TableCell>
        <TableCell>{item?.totalDriverIncentive ? `$ ${item?.totalDriverIncentive}` : `$ 0`}</TableCell>
        <TableCell>{item?.totalActualPay ? `$ ${item?.totalActualPay}` : `$ 0`}</TableCell>
        <TableCell>
          <Button
            onClick={() => {
              viewClicked(item);
            }}
          >
            <Iconify icon="ic:baseline-remove-red-eye" />
          </Button>
        </TableCell>
      </TableRow>
      {detailsView && (
        <UserWorkReportDetailsViewDrawer
          departmentId={departmentId}
          setOpen={setDetailsView}
          open={detailsView}
          data={data}
          startDate={startDate}
          endDate={endDate}
        />
      )}
    </>
  );
}

export default UserWorkReportIndividualRow;
