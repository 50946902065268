import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import CreateIngredients from 'pages/dashboard/Ingredients/CreateIngredients';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIngredientDetails, getIngredientMenusList } from 'redux/slices/ingredientSlice';
import IngredientMangeStockDrawer from './IngredientMangeStockDrawer';

function IngredientManageDrawer({ open, setOpen, isUpdate = false, currentData }) {
  const getDepartment = useGetDepartmentId();
  const dispatch = useDispatch();
  const [isUpdates, setIsUpdates] = useState({
    isUpdate,
    currentData,
  });
  useEffect(() => {
    if (!getDepartment) return;
    dispatch(getIngredientDetails({ departmentId: getDepartment, ingredientId: currentData }));
  }, [getDepartment, currentData, dispatch]);
  return (
    <CustomDrawer
      open={open}
      setOpen={setOpen}
      title={isUpdates?.isUpdate ? 'Update Ingredients' : 'Create Ingredient'}
    >
      {/* {isUpdates?.isUpdate ? ( */}
      <IngredientMangeStockDrawer currentData={isUpdates?.currentData} />
      {/* ) : ( */}
      {/* <CreateIngredients setOpen={setOpen} setIsUpdates={setIsUpdates} /> */}
      {/* )} */}
    </CustomDrawer>
  );
}

export default IngredientManageDrawer;
