import {
  Avatar,
  Box,
  //   Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import PropTypes from 'prop-types';
import Label from 'components/Label';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleAnnouncement } from 'redux/slices/announcementSlice';

function PrivateAnnouncementView({ open, id, handleClose }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const { singleAnnouncement, singleAnnouncementLoading } = useSelector((state) => state.announcement);

  useEffect(() => {
    if (!departmentId || !id) return;
    dispatch(getSingleAnnouncement({ id, departmentId }));
  }, [departmentId, dispatch, id]);

  const theme = useTheme();
  return (
    <CustomDrawer open={open} setOpen={handleClose} title="View Announcement " PaperProps={{ sx: { width: '50%' } }}>
      <Stack spacing={2}>
        {singleAnnouncementLoading ? (
          <CustomLoadingScreen height="30vh" />
        ) : (
          <>
            <Stack spacing={2}>
              <Table size="small">
                <TableBody>
                  <TableRow hover>
                    <TableCell style={{ minWidth: '140px', fontWeight: 'bold' }}>Title: </TableCell>
                    <TableCell>
                      {/* eslint-disable-next-line react/no-danger */}
                      <span dangerouslySetInnerHTML={{ __html: singleAnnouncement?.title }} />
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell style={{ fontWeight: 'bold' }}>Description: </TableCell>
                    <TableCell>
                      {/* eslint-disable-next-line react/no-danger */}
                      <span dangerouslySetInnerHTML={{ __html: singleAnnouncement?.description }} />
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell style={{ fontWeight: 'bold' }}>Is Permanent: </TableCell>
                    <TableCell>
                      <Label
                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                        color={(singleAnnouncement?.isPermanent && 'success') || 'error'}
                        sx={{ textTransform: 'capitalize' }}
                      >
                        {singleAnnouncement?.isPermanent ? 'Yes' : 'No'}
                      </Label>
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell style={{ fontWeight: 'bold' }}>Start At: </TableCell>
                    <TableCell>
                      {singleAnnouncement?.startDate
                        ? moment(singleAnnouncement?.startDate).format('YYYY-MMM-DD hh:mm A')
                        : '---'}
                    </TableCell>
                  </TableRow>
                  <TableRow hover>
                    <TableCell style={{ fontWeight: 'bold' }}>End At: </TableCell>
                    <TableCell>
                      {singleAnnouncement?.endDate
                        ? moment(singleAnnouncement?.endDate).format('YYYY-MMM-DD hh:mm A')
                        : '---'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Read By: </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {singleAnnouncement?.readBy?.length === 0 ? (
                        <Typography variant="subtitle2" noWrap style={{ color: 'gray' }}>
                          No one has read this announcement yet.
                        </Typography>
                      ) : (
                        singleAnnouncement?.readBy?.map((item) => (
                          <Box
                            key={item.id}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              margin: '3px',
                              padding: '2px',
                              width: '130px',
                            }}
                          >
                            {item?.thumbnailURL ? (
                              <Avatar
                                src={item?.thumbnailURL}
                                alt=""
                                style={{
                                  height: '30px',
                                  width: '30px',
                                  borderRadius: '100%',
                                  marginRight: '10px',
                                }}
                              />
                            ) : (
                              <Avatar
                                alt=""
                                src=""
                                style={{ height: '30px', width: '30px', borderRadius: '100%', marginRight: '10px' }}
                              />
                            )}
                            <Typography variant="subtitle2" noWrap style={{ marginTop: '3px' }}>
                              <Tooltip title={`${item?.firstName} ${item?.middleName || ''} ${item?.lastName}`}>
                                <span>{`${item?.firstName} ${item?.middleName || ''} ${item?.lastName}`}</span>
                              </Tooltip>
                            </Typography>
                          </Box>
                        ))
                      )}
                    </TableCell>
                  </TableRow>
                  {/* <Divider /> */}

                  <TableRow>
                    <TableCell style={{ fontWeight: 'bold' }}>Not Read By: </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell />
                    <TableCell style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {singleAnnouncement?.notReadBy?.map((item) => (
                        <Box
                          key={item.id}
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            margin: '3px',
                            padding: '2px',
                            width: '150px',
                          }}
                        >
                          {item?.thumbnailURL ? (
                            <Avatar
                              src={item?.thumbnailURL}
                              alt=""
                              style={{
                                height: '30px',
                                width: '30px',
                                borderRadius: '100%',
                                marginRight: '10px',
                              }}
                            />
                          ) : (
                            <Avatar
                              alt=""
                              src=""
                              style={{ height: '30px', width: '30px', borderRadius: '100%', marginRight: '10px' }}
                            />
                          )}
                          <Typography variant="subtitle2" noWrap style={{ marginTop: '3px' }}>
                            <Tooltip title={`${item?.firstName} ${item?.middleName || ''} ${item?.lastName}`}>
                              <span>{`${item?.firstName} ${item?.middleName || ''} ${item?.lastName}`}</span>
                            </Tooltip>
                          </Typography>
                        </Box>
                      ))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Stack>
          </>
        )}
      </Stack>
    </CustomDrawer>
  );
}

PrivateAnnouncementView.propTypes = {
  open: PropTypes.bool,
  id: PropTypes.string,
  handleClose: PropTypes.func,
};

export default PrivateAnnouncementView;
