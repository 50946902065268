import { Box, Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import React from 'react';
import { useSelector } from 'react-redux';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import StoreConfigEditDrawer from '../storeConfig/StoreConfigEditDrawer';

function StoreConfigureHeader() {
  const { currentTab, storeConfsDetails, postLoading } = useSelector((state) => state.storeConfig);
  const [openUpdateDrawer, setOpenUpdateDrawer] = React.useState(false);
  return (
    <>
      <HeaderBreadcrumbs
        heading="Store Configure"
        links={[
          // avoid page crash
          <Button key={1} variant="contained" color="primary">
            Events
          </Button>,
        ]}
        action={
          <Box className="invoiceListHeadingButtons">
            {currentTab === 0 && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<Iconify icon={'carbon:license-draft'} />}
                type="reset"
                form="halfAndHalfPublishForm"
                id="halfAnfHalfPublish"
              >
                Save
              </Button>
            )}
            {currentTab === 1 && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<Iconify icon={'carbon:license-draft'} />}
                onClick={() => setOpenUpdateDrawer(true)}
              >
                Update Details
              </Button>
            )}
            {/* {currentTab === 2 && (
              <Button
                variant="contained"
                color="primary"
                startIcon={postLoading ? null : <Iconify icon={'carbon:license-draft'} />}
                type="reset"
                form="tradingHoursPublishForm"
                id="tradingHours"
                disabled={postLoading}
              >
                {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : null}
                Save
              </Button>
            )} */}
          </Box>
        }
      />
      {openUpdateDrawer && (
        <StoreConfigEditDrawer open={openUpdateDrawer} setOpen={setOpenUpdateDrawer} updateData={storeConfsDetails} />
      )}
    </>
  );
}

export default StoreConfigureHeader;
