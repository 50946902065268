import { Box, Checkbox, InputAdornment, Stack, TextField, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

DealOfferAddMenuIndividualMenu.propTypes = {
  menuIndex: PropTypes.number,
  menu: PropTypes.object,
  index: PropTypes.number,
  addAppend: PropTypes.func,
  addRemove: PropTypes.func,
  removeAppend: PropTypes.func,
  removeRemove: PropTypes.func,
  addFields: PropTypes.array,
  removeFields: PropTypes.array,
  addUpdate: PropTypes.func,
};

function DealOfferAddMenuIndividualMenu({
  menuIndex,
  menu,
  index,
  addAppend,
  addRemove,
  removeAppend,
  removeRemove,
  addFields,
  removeFields,
  addUpdate,
}) {
  const { singleDeal } = useSelector((state) => state.deal);

  const handleMenuChange = (event, option) => {
    if (!event.target.checked) {
      const prevData = singleDeal[index]?.dealCategoryMenus?.find((el) => el?.menuId === option?.id);
      if (prevData) {
        if (!removeFields?.includes(option)) {
          removeAppend({ id: option?.id, name: option?.name });
        }
        const findIndexes = addFields?.findIndex((el) => el?.id === option?.id);
        if (findIndexes !== -1) {
          addRemove(findIndexes);
        }
      } else {
        const findIndexes = addFields?.findIndex((el) => el?.id === option?.id);
        if (findIndexes !== -1) {
          addRemove(findIndexes);
        }
      }
    } else if (event.target.checked) {
      const removeIndex = removeFields?.findIndex((el) => el?.id === option?.id);
      if (removeIndex !== -1) {
        removeRemove(removeIndex);
      }
      if (!addFields?.includes(option)) {
        addAppend({ id: option?.id, name: option?.name });
      }
    }
  };

  const handleChecked = (menu) => {
    let boolean = false;
    addFields?.forEach((el) => {
      if (el?.id === menu?.id) {
        boolean = true;
      }
      return boolean;
    });
    return boolean;
  };

  const handleSpecialPriceChange = (event) => {
    const findIndexes = addFields?.findIndex((el) => el?.name === menu?.name);
    if (findIndexes !== -1) {
      addUpdate(findIndexes, { ...addFields[findIndexes], specialPrice: event.target.value });
    }
  };

  const findMenu = addFields?.find((el) => el?.id === menu?.id)?.specialPrice || '';

  return (
    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} key={menuIndex} style={{ position: 'relative' }}>
      <Stack
        alignItems="center"
        gap="0.2rem"
        flexDirection={'row'}
        my="0.15rem"
        p="0.4rem"
        borderRadius={'10px'}
        fontWeight={500}
        sx={{ cursor: 'pointer' }}
      >
        <Checkbox
          name={`dealCategories.${index}.menu.${menuIndex}.menuId`}
          onChange={(event) => handleMenuChange(event, menu)}
          defaultChecked={handleChecked(menu)}
          style={{ marginRight: '0.8rem' }}
        />
        <Typography variant="subtitle1" style={{ marginLeft: '-15px' }}>
          {menu?.name?.length > 30 ? (
            <Tooltip title={menu?.name} placement="top-start">
              {`${menu?.name?.slice(0, 30)}...`}
            </Tooltip>
          ) : (
            menu?.name
          )}
        </Typography>
        <Box style={{ position: 'absolute', top: '10px', right: '7rem' }}>
          <TextField
            name={`dealCategories.${index}.menu.${menuIndex}.specialPrice`}
            size={'small'}
            value={findMenu}
            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
            onChange={(event) => handleSpecialPriceChange(event)}
            style={{ width: '10rem' }}
            label="Special Price"
          />
        </Box>
      </Stack>
    </Stack>
  );
}

export default DealOfferAddMenuIndividualMenu;
