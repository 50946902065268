import { Box, Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import React, { useState } from 'react';
import ManageMostLikedMenus from './ManageMostLikedMenus';

function MostLikedMenusHeader() {
  const [openManage, setOpenManage] = useState(false);
  return (
    <HeaderBreadcrumbs
      style={{ marginBottom: '0px' }}
      heading="Most Liked Menus"
      // just to prevent page crash though it doesn't appear on screen
      links={[
        <Button
          key={1}
          variant="contained"
          color="primary"
          startIcon={<Iconify icon={'mdi:eye'} />}
          // onClick={() => setOpenEventsView(true)}
          className="roasterViewEventButton"
        >
          Events
        </Button>,
      ]}
      action={
        <Box className="invoiceListHeadingButtons">
          <Button
            variant="contained"
            color="warning"
            startIcon={<Iconify icon={'gg:reorder'} />}
            type="submit"
            form="mostLikedMenusReOrder"
            id="reOrderMostLikedMenus"
          >
            Reorder
          </Button>
          <Button variant="contained" onClick={() => setOpenManage(true)}>
            Manage
          </Button>
          {openManage && <ManageMostLikedMenus open={openManage} setOpen={setOpenManage} />}
        </Box>
      }
    />
  );
}

export default MostLikedMenusHeader;
