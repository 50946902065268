import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import React from 'react';
import FoodCategoryForm from './FoodCategoryForm';

function EditFoodCategory({ openEdit, setOpenEdit, selectedRow }) {
  return (
    <CustomOutletModal open={openEdit} zIndex="1600" title="Create Food Category">
      <FoodCategoryForm handleClose={() => setOpenEdit(false)} selectedRow={selectedRow} isEdit />
    </CustomOutletModal>
  );
}

export default EditFoodCategory;
