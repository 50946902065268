import { Stack, TextField, Typography, useTheme } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import CustomTimePicker from 'components/CustomComponents/CustomTimePicker';
import { RHFTextField } from 'components/hook-form';
import moment from 'moment';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { convertDateintoTimeString } from 'utils/customDateAndTimeFormat';
import { meterToKm } from 'utils/distanceMeasure';

function TimeClockAccordionDetailsClockIn({ clockIn, index, timeDifference, maxAceptedMins }) {
  const theme = useTheme();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const address = clockIn?.address || 'No Address';
  const distanceInKm = meterToKm(clockIn?.distanceInMeters || 0);
  const clockInTime = convertDateintoTimeString(clockIn?.clockTime, 'hh:mm a');
  const clockInDate = moment(clockIn?.clockTime).format('ddd, DD-MMM');

  // const handleChange = (newValue) => {
  //   setValue(newValue);
  // };

  const findColor = () => {
    let color = '';
    if (timeDifference > 960) {
      color = 'red';
    } else if (maxAceptedMins > 0) {
      if (timeDifference > maxAceptedMins) {
        color = '#f6be00';
      } else {
        color = '';
      }
    }
    return color;
  };
  return (
    <Stack direction="row" gap={1.5} width={'45%'} alignItems={'center'}>
      {/* <Stack sx={{ width: '0.5rem', height: '0.5rem', bgcolor: theme.palette.success.main, borderRadius: '50%' }} /> */}
      <Stack gap={1}>
        <Stack flexDirection={'row'} gap={1}>
          <Typography component="p" fontWeight={'500'}>
            {address} |
          </Typography>
          <Typography component="p" fontWeight={'500'}>
            {distanceInKm.toFixed(2)} KM
          </Typography>
        </Stack>
        <Stack alignItems={'flex-start'} justifyContent={'flex-start'}>
          {/* <Controller
            name={`clockList.${index}.clockIn.clockTime`}
            control={control}
            errors={errors}
            render={({ field, fieldState: { error } }) => (
              <TimePicker
                label={'Clock In'}
                disabled
                closeOnSelect={false}
                PopperProps={{
                  sx: { zIndex: '1600' },
                }}
                {...field}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    disabled
                    InputLabelProps={{ shrink: true }}
                    sx={{ maxWidth: '10rem' }}
                    size="small"
                    error={false}
                  />
                )}
              />
            )}
          /> */}
          <Typography
            component="p"
            fontWeight={600}
            style={{
              color: findColor(),
            }}
          >
            {`${clockInDate}, ${clockInTime} `}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default TimeClockAccordionDetailsClockIn;
