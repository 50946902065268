import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React from 'react';
import { useDispatch } from 'react-redux';
import { updateFoodCategories } from 'redux/slices/FoodCategoriesAndSizeSlice';
import FoodCategoriesForm from 'sections/@dashboard/foodCategory/foodCategories/FoodCategoriesForm';

function FoodCategoriesEdit({ open, handleClose, currentData }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const FoodCategoriesUpdateHandler = (data) => {
    const finalData = {
      departmentId,
      ...data,
      addedSizes: data?.addedSizes.map((obj) => obj.id),
      removedSizes: data?.removedSizes?.map((obj) => obj.id),
    };
    dispatch(updateFoodCategories({ finalData, handleClose }));
  };
  return (
    <CustomOutletModal open={open} zIndex="1600" title="Update Food Categories">
      <FoodCategoriesForm
        handleClose={handleClose}
        isEdit
        currentData={currentData}
        onSubmit={FoodCategoriesUpdateHandler}
      />
    </CustomOutletModal>
  );
}

export default FoodCategoriesEdit;
