import { Avatar, Box, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import DeliverySummaryTableIndividualCell from './DeliverySummaryTableIndividualCell';

DeliverySummaryTable.propTypes = {
  item: PropTypes.object,
  mainIndex: PropTypes.number,
};
function DeliverySummaryTable({ item, mainIndex }) {
  const { control } = useFormContext();
  const { onlineSalesPlatform } = useSelector((state) => state.store);
  const deliveryWatch = useWatch({
    control,
    name: 'deliveryEntries',
  });

  const filterUser = deliveryWatch?.filter((el) => el?.userId === item?.id);

  return (
    <TableRow key={item?.fieldID} style={{ overflow: 'hidden' }} hover>
      <TableCell style={{ position: 'relation', padding: 2 }}>
        <Box
          sx={{
            display: 'flex',
            gap: '0.5rem',
            flexDirection: 'row',
            alignItems: 'center',
            flexWrap: 'nowrap',
            minWidth: '120px',
            marginLeft: '20px',
          }}
        >
          {item?.thumbnailURL ? (
            <Avatar
              src={item.thumbnailURL}
              style={{
                height: '30px',
                width: '30px',
                borderRadius: '100px',
                marginRight: '2px',
              }}
            />
          ) : (
            <Avatar alt="" src="" style={{ height: '30px', width: '30px', marginRight: '2px' }} />
          )}
          <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
            {item?.firstName}
          </Typography>
        </Box>
      </TableCell>
      {onlineSalesPlatform?.onlinePlatforms?.length > 0 && (
        <>
          {onlineSalesPlatform?.onlinePlatforms?.map((platform, index) => (
            <DeliverySummaryTableIndividualCell
              key={index}
              mainIndex={mainIndex}
              index={index}
              item={item}
              platform={platform}
              filterUser={filterUser}
            />
          ))}
        </>
      )}
    </TableRow>
  );
}

export default DeliverySummaryTable;
