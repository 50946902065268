import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { FormProvider, RHFCheckbox, RHFTextField } from 'components/hook-form';
import { Button, Grid, Stack } from '@mui/material';
import { createNewStreet } from 'redux/slices/posConfigSlice';

const schema = Yup.object().shape({
  name: Yup.string().required(''),
});
function StreetAddForm({ handleClose, suburbId, isEdit, updateData }) {
  const dispatch = useDispatch();

  const defaultValues = {
    name: '',
    doDelivery: true,
  };

  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onChange' });

  const { reset, handleSubmit } = methods;

  const StreetSubmitHandler = (data) => {
    const finalData = { ...data, suburbId };
    dispatch(createNewStreet({ finalData, handleClose, isEdit }));
  };

  useEffect(() => {
    if (!isEdit) return;
    reset({ ...updateData });
  }, [isEdit, reset, updateData]);

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(StreetSubmitHandler)}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <RHFTextField name="name" label="Street Name" />
        </Grid>
        <Grid item xs={4}>
          <RHFCheckbox name="doDelivery" label="Do Delivery" />
        </Grid>
      </Grid>
      <Stack alignItems="flex-end" justifyContent={'flex-end'} mt="1rem" direction="row" gap="1rem">
        <Button variant="contained" type="submit" color="secondary">
          Save
        </Button>
        <Button variant="outlined" onClick={handleClose} color="error">
          Cancel
        </Button>
      </Stack>
    </FormProvider>
  );
}

export default StreetAddForm;
