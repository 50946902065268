import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Iconify from 'components/Iconify';
import { TableHeadCustom, TableNoData } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteRecipeIngredient, getRecipeIngredientList } from 'redux/slices/ingredientSlice';
import EditRecipe from './EditRecipe';

const TABLE_HEAD = [
  { id: 1, label: 'S.N', align: 'left' },
  { id: 2, label: 'Name', align: 'left' },
  { id: 3, label: 'Actions', align: 'center' },
];

function ReceipeBody() {
  const [openEdit, setOpenEdit] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, id: null });

  const [selectedRow, setSelectedRow] = useState(null);

  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const { recipeIngredientList, recipeLoading } = useSelector((state) => state.ingredients);

  const HandleDeleteRecipe = () => {
    dispatch(deleteRecipeIngredient({ id: openDeleteModal.id, departmentId }));
    setOpenDeleteModal({ status: false, id: null });
  };

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getRecipeIngredientList({ departmentId }));
  }, [departmentId, dispatch]);

  return (
    <>
      <TableContainer>
        <Table stickyHeader size="small">
          <TableHeadCustom headLabel={TABLE_HEAD} />
          <TableBody>
            {recipeLoading ? (
              <TableRow>
                <TableCell colSpan={3}>
                  <CustomLoadingScreen height="30vh" />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {recipeIngredientList?.ingredients?.length <= 0 ? (
                  <TableNoData isNotFound={recipeIngredientList?.ingredients?.length <= 0} />
                ) : (
                  <>
                    {recipeIngredientList?.ingredients?.map((row, index) => (
                      <TableRow key={row.id} hover>
                        <TableCell>{index + 1}.</TableCell>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell align="center">
                          <Button
                            style={{ color: '#4caf50', marginLeft: '10px', marginRight: '10px' }}
                            variant="text"
                            startIcon={<Iconify icon="material-symbols:edit-outline" />}
                            onClick={() => {
                              setOpenEdit(true);
                              setSelectedRow(row);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="text"
                            color="error"
                            startIcon={<Iconify icon="mdi:trash-can" />}
                            onClick={() => {
                              setOpenDeleteModal({ status: true, id: row.id });
                            }}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {openDeleteModal?.status && (
        <CustomDeleteDialog open={openDeleteModal} handleClose={setOpenDeleteModal} onAccept={HandleDeleteRecipe} />
      )}
      {openEdit && <EditRecipe open={openEdit} setOpen={setOpenEdit} data={selectedRow} />}
    </>
  );
}

export default ReceipeBody;
