import { TableCell, TableRow } from '@mui/material';
import React, { useState } from 'react';
import RoasterTableCellWithAvatar from './RoasterTableCellWithAvatar';
import IndividualRoasterTableCell from './IndividualRoasterTableCell';

function RoasterTableBodyUserEntity({ obj, mainIndex }) {
  // state for shift split if clockout time is for next day
  const [nextdayHandler, setNextDayHandler] = useState(false);
  const [startTime, setStartTime] = useState(null);

  return (
    <TableRow key={obj?.fieldID} style={{ overflow: 'hidden' }}>
      {/* each user data */}
      <TableCell style={{ position: 'relative' }}>
        <RoasterTableCellWithAvatar obj={obj} mainIndex={mainIndex} />
      </TableCell>

      {Object.keys(obj?.userShiftsInDates).map((roaster, index) => (
        <IndividualRoasterTableCell
          key={index}
          roasterKey={roaster}
          mainIndex={mainIndex}
          userInformation={obj}
          nextdayHandler={nextdayHandler}
          setNextDayHandler={setNextDayHandler}
          startTime={startTime}
          setStartTime={setStartTime}
        />
      ))}
    </TableRow>
  );
}

export default RoasterTableBodyUserEntity;
