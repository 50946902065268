import Page from 'components/Page';
import React from 'react';
import StoreConfigureHeader from './StoreConfigureHeader';
import StoreConfigureBody from './StoreConfigureBody';

function StoreConfigureIndex() {
  return (
    <Page title="Store Configure">
      <StoreConfigureHeader />
      <StoreConfigureBody />
    </Page>
  );
}

export default StoreConfigureIndex;
