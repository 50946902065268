import { Stack } from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

function FoodRecipeFormCalculatedSize({ index, size, menuName }) {
  const { setValue, getValues } = useFormContext();
  const getRecipeName = getValues(`receipeDTOs.${index}.name`);

  useEffect(() => {
    if (getRecipeName || getRecipeName === '') return;
    let sizeName = '';
    if (size) {
      sizeName = `${menuName}-${size?.name?.replace(/ /g, '')}`;
    }
    setValue(`receipeDTOs.${index}.name`, sizeName);
  }, [menuName, index, size, setValue, getRecipeName]);
  return (
    <Stack flexDirection={'column'} gap="1rem" spacing={1}>
      <RHFTextField
        name={`receipeDTOs.${index}.name`}
        size="small"
        label={`${size?.name} title`}
        fullWidth={false}
        InputLabelProps={{ shrink: true }}
      />
    </Stack>
  );
}

export default FoodRecipeFormCalculatedSize;
