import { Button, Stack } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import PropTypes from 'prop-types';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import DealOfferAddMenuIndividualMenu from './DealOfferAddMenuIndividualMenu';

AddMenusToDealCategory.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  index: PropTypes.number,
};

function AddMenusToDealCategory({ open, handleClose, index }) {
  const { control, setValue } = useFormContext();

  const { menusOfSelectedSizeLoading, menusOfSelectedSize, singleDeal } = useSelector((state) => state.deal);

  const {
    fields: addFields,
    append: addAppend,
    remove: addRemove,
    update: addUpdate,
  } = useFieldArray({
    control,
    name: `dealCategories.${index}.addedMenus`,
    keyName: 'addedMenusId',
  });

  const {
    fields: removeFields,
    append: removeAppend,
    remove: removeRemove,
  } = useFieldArray({
    control,
    name: `dealCategories.${index}.removedMenuIds`,
    keyName: 'removedMenusId',
  });

  const handleSaveClick = () => {
    const menuList = [];

    addFields?.forEach((el) => {
      menuList.push(el?.name);
    });
    setValue(`dealCategories.${index}.menusOfCategories`, menuList);
    handleClose();
  };

  const accidentalClose = () => {
    const findInitialMenusList = singleDeal[index]?.dealCategoryMenus?.map((el) => ({
      id: el?.menuId,
      dealCategoryId: el?.dealCategoryId,
      specialPrice: el?.specialPrice ?? 0,
      name: el?.menu?.name,
    }));

    const menuNames = singleDeal[index]?.dealCategoryMenus?.map((el) => el?.menu?.name);
    setValue(`dealCategories.${index}.addedMenus`, findInitialMenusList);
    setValue(`dealCategories.${index}.menusOfCategories`, menuNames);
    handleClose();
  };

  return (
    <CustomDrawer
      open={open}
      setOpen={accidentalClose}
      title="Add Menus To Deal Category"
      PaperProps={{ sx: { width: '500px' } }}
    >
      {menusOfSelectedSizeLoading ? (
        <CustomLoadingScreen height="20vh" />
      ) : (
        <>
          <Stack spacing={1}>
            {menusOfSelectedSize?.map((el, menuIndex) => (
              <DealOfferAddMenuIndividualMenu
                key={menuIndex}
                menuIndex={menuIndex}
                menu={el}
                index={index}
                addAppend={addAppend}
                addRemove={addRemove}
                removeAppend={removeAppend}
                removeRemove={removeRemove}
                addFields={addFields}
                removeFields={removeFields}
                addUpdate={addUpdate}
              />
            ))}
          </Stack>
          <Stack>
            <Stack justifyContent={'flex-end'} alignItems={'flex-end'}>
              <Button variant="contained" color="primary" onClick={handleSaveClick}>
                Save
              </Button>
            </Stack>
          </Stack>
        </>
      )}
    </CustomDrawer>
  );
}

export default AddMenusToDealCategory;
