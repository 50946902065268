import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React from 'react';
import { useDispatch } from 'react-redux';
import { createFoodSize } from 'redux/slices/FoodCategoriesAndSizeSlice';
import FoodSizeForm from 'sections/@dashboard/foodCategory/foodSizes/FoodSizeForm';

function FoodSizeCreate({ open, handleClose }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const FoodSizeCreateHandler = (data) => {
    const finalData = {
      departmentId,
      ...data,
    };
    dispatch(createFoodSize({ finalData, handleClose }));
  };
  return (
    <CustomOutletModal open={open} zIndex="1600" title="Create Food Size">
      <FoodSizeForm handleClose={handleClose} onSubmit={FoodSizeCreateHandler} />
    </CustomOutletModal>
  );
}

export default FoodSizeCreate;
