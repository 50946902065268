import { Box } from '@mui/material';
import React from 'react';
import FoodRecipeCreateUpdate from '../FoodRecipe/FoodRecipeCreateUpdate';

function FoodMenuRecipeTab({ menu }) {
  return (
    <Box>
      <FoodRecipeCreateUpdate menu={menu} />
    </Box>
  );
}

export default FoodMenuRecipeTab;
