import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import React from 'react';
import Scrollbar from 'components/Scrollbar';
import FoodCategoryCogsReportItemSize from './FoodCategoryCogsReportItemSize';

function FoodCategoryCogsReportItem({ item }) {
  return (
    <>
      <Box style={{ padding: '5px' }}>
        <Typography variant="body1">{item.name}</Typography>
        <Scrollbar>
          <TableContainer style={{ minWidth: '1100px' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Size Name</TableCell>
                  <TableCell align="center">SP</TableCell>
                  <TableCell align="center">Cogs</TableCell>
                  <TableCell align="center">Cogs %</TableCell>
                  <TableCell align="center">Gross Profit</TableCell>
                  <TableCell align="center">Gross Profit %</TableCell>
                  <TableCell align="center">target</TableCell>
                  <TableCell align="center">target%</TableCell>
                  <TableCell align="center">Target GP</TableCell>
                  <TableCell align="center">Target GP %</TableCell>
                  <TableCell align="center">Difference</TableCell>
                  <TableCell align="center">Difference %</TableCell>
                  <TableCell align="right">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {item?.menuSizes?.map((menuSize, index) => (
                  <FoodCategoryCogsReportItemSize key={index} menuSize={menuSize} parent={item} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Box>
    </>
  );
}

export default FoodCategoryCogsReportItem;
