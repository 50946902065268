import { Button, InputAdornment, TableCell, TextField } from '@mui/material';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';

function FoodMenuToppinsWithQtyIndividualTableCell({ index, optionIndex }) {
  const { control, setValue } = useFormContext();
  const { menuTopinsWithQty } = useSelector((state) => state.menuEngineering);

  const watchQty = useWatch({
    control,
    name: `menuOptions[${optionIndex}].menuSizes[${index}].qtyUsed`,
  });

  const findItemCost = () => {
    const findCost = menuTopinsWithQty?.menuOptions[optionIndex]?.ingredient?.unitPrice;
    const findWeight = menuTopinsWithQty?.menuOptions[optionIndex]?.ingredient?.unitWeight;
    if (!watchQty || !findCost || !findWeight) return 0;

    const itemPrice = (findCost / findWeight) * (watchQty / 1000);

    return itemPrice.toFixed(2);
  };

  useEffect(() => {
    const findCost = menuTopinsWithQty?.menuOptions[optionIndex]?.ingredient?.unitPrice;
    const findWeight = menuTopinsWithQty?.menuOptions[optionIndex]?.ingredient?.unitWeight;
    if (!watchQty || !findCost || !findWeight) return;
    const itemPrice = (findCost / findWeight) * (watchQty / 1000);
    setValue(`menuOptions[${optionIndex}].menuSizes[${index}].cost`, itemPrice);
  }, [index, menuTopinsWithQty?.menuOptions, optionIndex, setValue, watchQty]);
  return (
    <TableCell key={index} align="center">
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <Controller
          name={`menuOptions[${optionIndex}].menuSizes[${index}].qtyUsed`}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              fullWidth
              value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
              id="filled-basic"
              variant={'standard'}
              error={!!error}
              helperText={error?.message}
              sx={{ width: '60px' }}
              InputProps={{
                endAdornment: <InputAdornment position="end">gm</InputAdornment>,
              }}
            />
          )}
        />

        <Button variant="text" disabled>
          $ {findItemCost()}
        </Button>
      </div>
    </TableCell>
  );
}

FoodMenuToppinsWithQtyIndividualTableCell.propTypes = {
  index: PropTypes.number,
  optionIndex: PropTypes.number,
};

export default FoodMenuToppinsWithQtyIndividualTableCell;
