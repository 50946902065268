import { Icon } from '@iconify/react';
import { Box, Button, FormControlLabel, SpeedDial, Stack, Switch, TextField } from '@mui/material';
import { FormProvider, RHFTextField } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getStock, isCreateStockOrderSwitchAction, isOpenCreateOrderModalAction } from 'redux/slices/stockSlice';
import { useDispatch, useSelector } from 'redux/store';

function StockFilter() {
  const methods = useForm();
  const dispatch = useDispatch();
  const getDepartment = useGetDepartmentId();
  const { isCreateStockOrderSwitch } = useSelector((state) => state.stocks);
  const { isCreateOrder } = isCreateStockOrderSwitch;

  const [first, setFirst] = useState();
  const OnChangeTextField = (event) => {
    setFirst(event);
  };

  useEffect(() => {
    if (!getDepartment) return;
    const promise = dispatch(getStock({ departmentId: getDepartment, searchTxt: first, pageNo: 1 }));
    return () => promise.abort();
  }, [first, dispatch, getDepartment]);

  return (
    <FormProvider methods={methods}>
      <Stack m={2} alignItems="center" flexDirection="row" justifyContent="space-between">
        <TextField
          name="searchTxt"
          sx={{ width: 'min(30rem,70%)' }}
          label="Search Stock"
          onKeyDown={(event) => {
            if (event.keyCode === 13) {
              event.preventDefault();
              return false;
            }
          }}
          onChange={(event) => OnChangeTextField(event.target.value)}
        />
        <Stack flexDirection={'row'} gap="1rem" alignItems={'center'} justifyContent={'center'}>
          <FormControlLabel
            control={<Switch checked={isCreateOrder} onChange={() => dispatch(isCreateStockOrderSwitchAction())} />}
            label="Create Order"
            labelPlacement="start"
          />
          <Button
            sx={{ display: isCreateStockOrderSwitch?.isCreateOrder ? 'block !important' : 'none !important' }}
            hidden={!isCreateStockOrderSwitch?.isCreateOrder}
            onClick={() => dispatch(isOpenCreateOrderModalAction(true))}
          >
            <Icon icon="noto-v1:laptop-computer" width="30px" />
          </Button>

          {/* <SpeedDial
            ariaLabel="View selected Order"
            icon={<Icon icon="noto-v1:laptop-computer" width="25px" />}
            hidden={!isCreateStockOrderSwitch?.isCreateOrder}
            onClick={() => dispatch(isOpenCreateOrderModalAction(true))}
          /> */}
        </Stack>
      </Stack>
    </FormProvider>
  );
}

export default StockFilter;
