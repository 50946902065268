import { Button, TableCell, TableRow } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import Iconify from 'components/Iconify';
import PropTypes from 'prop-types';
import { RHFTextField } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { saveMenusDefaultCount } from 'redux/slices/menuEngineeringSlice';
import { toast } from 'react-toastify';
import { successToastTime } from 'config';

function FoodMenuDefaultCountIndivisualRow({ index, item, menu }) {
  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, data: null });
  const { setValue } = useFormContext();
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const HandleDefaultCountDelete = () => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        toast.success('Default Count Deleted', { autoClose: successToastTime });
        setValue(`sizeGroupOptionGroups.${index}.defaultCount`, 0);
      }
    };
    const finalData = {
      menuId: menu?.id,
      departmentId,
      sizeGroupOptionGroups: [
        {
          id: openDeleteModal?.data?.id,
          defaultCount: 0,
          optionGroupId: openDeleteModal?.data?.optionGroupId,
        },
      ],
    };
    dispatch(
      saveMenusDefaultCount({ finalData, menuId: menu?.id, sizeGroupId: menu?.sizeGroupId, isSuccess: isSuccessful })
    );
    setOpenDeleteModal({ status: false, data: null });
  };

  return (
    <>
      <TableRow key={index} hover>
        <TableCell>{item?.optionGroupName}</TableCell>
        <TableCell align="center">
          <RHFTextField name={`sizeGroupOptionGroups.${index}.defaultCount`} size="small" style={{ width: '8rem' }} />
        </TableCell>
        <TableCell align="center">
          <Button
            variant="text"
            color="error"
            size="small"
            disabled={!(item?.defaultCount > 0)}
            onClick={() => setOpenDeleteModal({ status: true, data: item })}
          >
            <Iconify icon="mdi:delete" height={20} width={20} />
          </Button>
        </TableCell>
      </TableRow>
      {openDeleteModal?.status && (
        <CustomDeleteDialog
          open={openDeleteModal}
          handleClose={setOpenDeleteModal}
          onAccept={HandleDefaultCountDelete}
        />
      )}
    </>
  );
}

FoodMenuDefaultCountIndivisualRow.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  menu: PropTypes.object,
};

export default FoodMenuDefaultCountIndivisualRow;
