import Page from 'components/Page';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdditionalFixedCost, getFixedCostList } from 'redux/slices/operationalSlice';
import FixedCostBody from 'sections/@dashboard/operationalCost/FixedCostBody';
import FixedCostHeader from 'sections/@dashboard/operationalCost/FixedCostHeader';

export default function FixedCost() {
  const dispatch = useDispatch();
  const [choosenDate, setChoosenDate] = useState();
  const departmentId = useGetDepartmentId();

  const { timeDT } = useSelector((state) => state.user);
  const { fixedCost } = useSelector((state) => state.operational);

  useEffect(() => {
    if (!timeDT) return;
    setChoosenDate(timeDT);
  }, [timeDT]);

  useEffect(() => {
    if (!departmentId) return;
    if (!choosenDate) return;
    dispatch(
      getFixedCostList({
        month: moment(choosenDate).format('MM'),
        year: moment(choosenDate).format('YYYY'),
        departmentId,
      })
    );
  }, [departmentId, choosenDate, dispatch]);

  useEffect(() => {
    if (!fixedCost?.id) return;
    if (!departmentId) return;
    dispatch(getAdditionalFixedCost({ departmentId, fixedCostId: fixedCost?.id }));
  }, [fixedCost, departmentId, dispatch]);

  return (
    <Page title="Operational: fixed cost">
      <FixedCostHeader choosenDate={choosenDate} setChoosenDate={setChoosenDate} />
      <FixedCostBody choosenDate={choosenDate} setChoosenDate={setChoosenDate} />
    </Page>
  );
}
