import React, { useCallback } from 'react';
import { Button, Grid, Stack } from '@mui/material';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useDrop } from 'react-dnd';
import Iconify from 'components/Iconify';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import SingleSizeField from './SingleSizeField';

function SizeFileds({ setOpenDeleteModal }) {
  const { control } = useFormContext();
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: 'sizes',
    keyName: 'sizesId',
  });
  const RemoveSizeTitle = (el, index) => {
    if (!el?.id) {
      remove(index);
    } else {
      setOpenDeleteModal({ status: true, id: el?.id });
    }
  };

  const AddNewSizeTitle = () => {
    append({ name: '' });
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (!over) return;
    if (active.id !== over.id) {
      move(
        fields.findIndex((item) => item.id === active.id),
        fields.findIndex((item) => item.id === over.id)
      );
    }
  };

  return (
    <Grid container spacing={1}>
      <Stack spacing={2}>
        <Stack>
          <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={fields} strategy={verticalListSortingStrategy}>
              {fields?.map((el, index) => (
                <SingleSizeField key={el.id} el={el} index={index} RemoveSizeTitle={RemoveSizeTitle} />
              ))}
            </SortableContext>
          </DndContext>
        </Stack>

        <Stack alignItems="flex-start" marginTop={3}>
          <Button
            variant="contained"
            color="secondary"
            style={{ backgroundColor: '#4caf50', marginLeft: '10px' }}
            startIcon={<Iconify icon="material-symbols:add" />}
            onClick={AddNewSizeTitle}
          >
            Add Size
          </Button>
        </Stack>
      </Stack>
    </Grid>
  );
}

export default SizeFileds;
