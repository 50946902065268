import { TableCell } from '@mui/material';
import moment from 'moment';
import React from 'react';

function UserWorkReportWeekWiseSummaryCell({ weeklyArray }) {
  const findWeeklyTotalData = () => {
    let weeklyTotalPay = 0;
    let weeklyTotalHours = 0;

    weeklyArray?.map((item) => {
      if (moment(item?.reportDate).format('ddd') === 'Sun') return;
      if (moment(item?.reportDate).format('ddd') === 'Sat') return;
      if (item?.isHoliday) return;
      // eslint-disable-next-line array-callback-return
      item?.data?.map((el) => {
        const diff = moment(el?.clockOutTime).diff(moment(el?.clockInTime), 'minutes');
        weeklyTotalHours += diff;
        weeklyTotalPay += el?.pay;
        return { weeklyTotalHours, weeklyTotalPay };
      });
      return { weeklyTotalHours, weeklyTotalPay };
    });
    weeklyTotalHours = Number(weeklyTotalHours / 60).toFixed(2);
    return { weeklyTotalHours, weeklyTotalPay };
  };

  return (
    <TableCell>
      {findWeeklyTotalData()?.weeklyTotalHours} Hrs. / $ {findWeeklyTotalData()?.weeklyTotalPay}
    </TableCell>
  );
}

export default UserWorkReportWeekWiseSummaryCell;
