import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import * as htmlToImage from 'html-to-image';
import { useFormContext } from 'react-hook-form';
import StaffCreateActiveStepper from '../staffCreate/StaffCreateActiveStepper';
import StaffEditActiveStepper from './StaffEditActiveStepper';
// import StaffCreateActiveStepper from './StaffCreateActiveStepper';

const steps = ['User Information', 'Payment Information', 'Paperwork', 'Confirm'];

export default function StaffEditStepper({ onSubmit, isEdit = false, isUserEdit = false }) {
  const [activeStep, setActiveStep] = useState(0);

  const {
    clearErrors,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext();

  const watchDeclarationForm = watch('declarationForm');

  // const watchSignature = watch('signature');

  const isStepFailed = (step) => step === activeStep;

  const handleNext = async () => {
    let isValidate = false;

    switch (activeStep) {
      case 0:
        isValidate = await trigger([
          'firstName',
          'middlename',
          'lastName',
          'phone',
          'email',
          'gender',
          'dob',
          'state',
          'suburb',
          'address',
          'zipcode',
          'emergencyContactName',
          'emergencyContactNo',
        ]);
        break;
      case 1:
        isValidate = await trigger([
          'accountName',
          'accountNo',
          'bsb',
          'superName',
          'superNumber',
          'tfnStatus',
          'tfnNo',
          'bankName',
          'membership',

          'weekDayPay',
          'saturdayPay',
          'sundayPay',
          'holidayPay',
          'overtimePay',
          'weeklyHrs',
        ]);
        break;
      case 2:
        isValidate = await trigger([
          'isInternationalStudent',
          'passport',
          'visa',
          'workingRights',
          'isDeliveryDriver',
          'insurance',
          'rezoPaperWork',
        ]);
        break;
      case 3:
        isValidate = true;
        break;
      default:
        isValidate = true;
    }

    if (isValidate && !Object.keys(errors).length) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      isStepFailed(activeStep);
    }
  };

  const handleBack = () => {
    clearErrors();
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (Object.keys(errors).length && isStepFailed(index)) {
            labelProps.optional = (
              <Typography variant="caption" color="error">
                Fill Required Field
              </Typography>
            );

            labelProps.error = true;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <>
          <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </>
      ) : (
        <>
          {/* here is a code for changing body part */}
          <StaffEditActiveStepper activeStep={activeStep} isEdit={isEdit} />
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {activeStep === steps.length - 1 ? (
              <Button variant="contained" type="button" onClick={handleSubmit(onSubmit)}>
                {isEdit ? 'Approve' : 'Save'}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={handleNext}
                type="button"
                disabled={
                  activeStep === 4 &&
                  (!watchDeclarationForm?.acceptTermsAndConditions ||
                    !watchDeclarationForm?.certifyAccuracy ||
                    !watchDeclarationForm?.signature ||
                    !watchDeclarationForm?.certifyBySigning ||
                    !watchDeclarationForm?.signature)
                }
              >
                Next
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}
