import Page from 'components/Page';
import React from 'react';
import FoodMenuHeaders from './FoodMenuHeaders';
import FoodMenuAndRecipeBody from './FoodMenuAndRecipeBody';

function FoodMenuAndReceipIndex() {
  return (
    <Page title="Menu">
      <FoodMenuHeaders />
      <FoodMenuAndRecipeBody />
    </Page>
  );
}

export default FoodMenuAndReceipIndex;
