import { Avatar, Box, Tooltip } from '@mui/material';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

function UserNotesTableCellWithAvatar({ obj }) {
  const firstName = obj?.firstName.split(' ')[0];
  const name = firstName.length > 8 ? `${firstName.slice(0, 8)}...` : firstName;
  const { control } = useFormContext();
  const values = useWatch({
    control,
    name: 'users',
  });
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '0.5rem',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'nowrap',
        minWidth: '120px',
      }}
    >
      {obj?.thumbnailURL ? (
        <Avatar
          src={obj.thumbnailURL}
          style={{
            height: '30px',
            width: '30px',
            borderRadius: '100px',
            marginRight: '2px',
          }}
        />
      ) : (
        <Avatar alt="" src="" style={{ height: '30px', width: '30px', marginRight: '2px' }} />
      )}
      <Tooltip title={`${obj?.firstName} ${obj?.middleName ?? ''} ${obj?.lastName}`}>
        <p
          className="roasterUserDetails"
          style={{ cursor: 'pointer', display: 'felx', flexWrap: 'nowrap', overflow: 'hidden' }}
        >
          {name}
        </p>
      </Tooltip>
    </Box>
  );
}

export default UserNotesTableCellWithAvatar;
