import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getFoodMenuOptionGroup, getMenuTopinsOption } from 'redux/slices/menuEngineeringSlice';
import { Stack } from '@mui/material';
import FoodMenuTopinsBody from './FoodMenuTopinsBody';

function FoodMenuTopins({ menu }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const { menuSpecificOptionGroup, menuOptionGroupLoading, menuTopinsOption, menuTopinsOptionLoading } = useSelector(
    (state) => state.menuEngineering
  );

  const defaultValues = {
    addedOptionIds: [],
    removedOptionIds: [],
  };
  const methods = useForm({ defaultValues });
  const { reset } = methods;

  useEffect(() => {
    reset({ menuSpecificOptionGroup, menuTopinsOption });
  }, [menuSpecificOptionGroup, menuTopinsOption, reset]);

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getFoodMenuOptionGroup({ departmentId, sizeGroupId: menu?.sizeGroupId }));
    dispatch(getMenuTopinsOption({ departmentId, menuId: menu?.id }));
  }, [departmentId, dispatch, menu]);

  return (
    <>
      <FormProvider {...methods}>
        <Stack spacing={2}>
          {menuOptionGroupLoading || menuTopinsOptionLoading ? (
            <CustomLoadingScreen height="20vh" />
          ) : (
            <FoodMenuTopinsBody menu={menu} />
          )}
        </Stack>
      </FormProvider>
    </>
  );
}

FoodMenuTopins.propTypes = {
  menu: PropTypes.object,
};

export default FoodMenuTopins;
