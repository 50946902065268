import { useSnackbar } from 'notistack';

import PropTypes from 'prop-types';
import { useState } from 'react';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem } from '@mui/material';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { logoutAction, setLogout } from '../../../redux/slices/authSlice';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// routes
import { PATH_DASHBOARD, PATH_AUTH } from '../../../routes/paths';
// components
import MyAvatar from '../../../components/MyAvatar';
import { IconButtonAnimate } from '../../../components/animate';
import MenuPopover from '../../../components/MenuPopover';
import maleImg from '../../../assets/male.png';
import femaleImg from '../../../assets/female.png';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
  isCollapse: PropTypes.bool,
};

const MENU_OPTIONS = [
  {
    label: 'My Account',
    linkTo: PATH_DASHBOARD.user.account,
  },
];

export default function NavbarAccount({ isCollapse }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(null);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector((state) => state.auth);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const LogoutHandler = () => {
    dispatch(logoutAction(navigate));
  };

  return (
    // <Link underline="none" color="inherit" component={RouterLink} to={PATH_DASHBOARD.user.profile}>
    <RootStyle
      sx={{
        ...(isCollapse && {
          bgcolor: 'transparent',
        }),
      }}
    >
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        {/* <MyAvatar /> */}
        {user?.userDTO?.imgURL && (
          <img
            src={user?.userDTO?.imgURL}
            alt=""
            className="img-fluid"
            style={{ width: '40px', height: '40px', borderRadius: '100px' }}
          />
        )}
        {!user?.userDTO?.imgURL && user?.userDTO?.gender === 'MALE' && (
          <img
            src={maleImg}
            alt=""
            className="img-fluid"
            style={{ width: '40px', height: '40px', borderRadius: '100px' }}
          />
        )}
        {!user?.userDTO?.imgURL && user?.userDTO?.gender === 'FEMALE' && (
          <img
            src={femaleImg}
            alt=""
            className="img-fluid"
            style={{ width: '40px', height: '40px', borderRadius: '100px' }}
          />
        )}
        {/* <img
          src={femaleImg}
          alt=""
          className="img-fluid"
          style={{ width: '40px', height: '40px', borderRadius: '100px' }}
        /> */}
      </IconButtonAnimate>
      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Divider sx={{ borderStyle: 'dashed' }} />
        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <MenuItem onClick={LogoutHandler} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>

      <Box
        sx={{
          ml: 2,
          transition: (theme) =>
            theme.transitions.create('width', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(isCollapse && {
            ml: 0,
            width: 0,
          }),
        }}
      >
        <Typography variant="subtitle2" noWrap>
          {`${user?.userDTO?.firstName} ${user?.userDTO?.middleName ?? ''} ${user?.userDTO?.lastName}`}
        </Typography>
        <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
          {user?.role}
        </Typography>
      </Box>
    </RootStyle>
    // </Link>
  );
}
