import { TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

function RecipeSummaryOptionDetails({ option, index }) {
  const { foodMenuRecipeSummary } = useSelector((state) => state.menuEngineering);
  const findCOGS = (size) => {
    const unitPrice = option?.ingredient?.unitPrice ?? 0;
    const unitWeight = option?.ingredient?.unitWeight ?? 0;
    const qtyUsed = size?.qtyUsed ?? 0;

    if (!unitPrice || !unitWeight || !qtyUsed) return 0;
    return ((unitPrice / unitWeight) * (qtyUsed / 1000)).toFixed(2);
  };

  const findDetails = ({ size, option }) => {
    if (!option?.ingredient) {
      return `${size?.qtyUsed ?? 0} gm / $ 0`;
    }
    return `${size?.qtyUsed ?? 0} gm / $ ${findCOGS(option?.menuSizes?.find((s) => s?.id === size?.id))}`;
  };

  return (
    <TableRow hover key={option?.id} style={{ backgroundColor: `rgba(255,0,0, .1)` }}>
      <TableCell align="left">{index + 1}.</TableCell>
      <TableCell>{option?.optionName}</TableCell>
      {/* {foodMenuRecipeSummary?.menuSizeIngredient?.menuSizes?.map((size) => (
        <TableCell align="center" key={size?.id}>
          {findDetails({ size, option })}
        </TableCell>
      ))} */}
      {option?.menuSizes?.map((size) => (
        <TableCell align="center" key={size?.id}>
          {size?.qtyUsed ?? 0} gm / $ {findCOGS(size)}
        </TableCell>
      ))}
    </TableRow>
  );
}

RecipeSummaryOptionDetails.propTypes = {
  option: PropTypes.object,
  index: PropTypes.number,
};

export default RecipeSummaryOptionDetails;
