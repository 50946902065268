import { Stack, Table, TableBody, TableContainer } from '@mui/material';

import { useFormContext, useWatch } from 'react-hook-form';
import FoodMenuSizeExtraPriceSingleSize from './FoodMenuSizeExtraPriceSingleSize';

function FoodMenuSizeExtraPriceSectionForm({ menu, choosenType }) {
  const { control } = useFormContext();
  const watchData = useWatch({
    control,
    name: 'menuSizes',
  });

  return (
    <Stack alignItems={'center'}>
      <TableContainer style={{ maxWidth: '350px' }}>
        <Table size="small" style={{ marginTop: '5px' }}>
          <TableBody>
            {watchData?.map((size, index) => (
              <FoodMenuSizeExtraPriceSingleSize
                size={size}
                menu={menu}
                index={index}
                key={index}
                choosenType={choosenType}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Stack>
  );
}

export default FoodMenuSizeExtraPriceSectionForm;
