import React, { useEffect } from 'react';
import Page from 'components/Page';
import { useSelector, useDispatch } from 'react-redux';
import { getZones } from 'redux/slices/storeSlice';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { getRoaster } from 'redux/slices/roasterSlice';
import RoasterHeaders from './RoasterHeaders';
import RoasterCardBody from './RoasterCardBody';

function RoasterPageIndex() {
  const dispatch = useDispatch();
  const { selectedDepartment } = useSelector((state) => state.userDepartment);

  // Accidental Reload Prevention
  useEffect(() => {
    const unloadCallback = (event) => {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => {
      window.removeEventListener('beforeunload', unloadCallback);
    };
  }, []);

  useEffect(() => {
    if (!selectedDepartment?.id) return;
    dispatch(getZones({ departmentId: selectedDepartment?.id }));
    dispatch(getRoaster({ departmentId: selectedDepartment?.id, anyDateOfWeek: new Date() }));
  }, [dispatch, selectedDepartment]);

  return (
    <Page title="Weekly Roster">
      {/* <Container> */}
      <DndProvider backend={HTML5Backend}>
        <RoasterHeaders />
        <RoasterCardBody />
      </DndProvider>
      {/* </Container> */}
    </Page>
  );
}

export default RoasterPageIndex;
