import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, TableCell, Tooltip } from '@mui/material';
import QuickSummaryDifferenceUserWeeklyData from './QuickSummaryDifferenceUserWeeklyData';

QuickSummaryDifferenceTableCellWithAvatar.propTypes = {
  user: PropTypes.object,
};

function QuickSummaryDifferenceTableCellWithAvatar({ user, mainIndex }) {
  return (
    <TableCell style={{ position: 'relative', minWidth: '100px', width: '120px' }}>
      <Box
        sx={{
          display: 'flex',
          gap: '0.5rem',
          flexDirection: 'row',
          alignItems: 'center',
          marginRight: '10px',
        }}
      >
        {user?.thumbnailURL ? (
          <Avatar
            src={user.thumbnailURL}
            style={{
              height: '30px',
              width: '30px',
              borderRadius: '100px',
              marginRight: '2px',
            }}
          />
        ) : (
          <Avatar alt="" src="" style={{ height: '30px', width: '30px', marginRight: '2px' }} />
        )}
        <Tooltip title={`${user?.firstName} ${user?.middleName ?? ''} ${user?.lastName}`} placement="top">
          <p className="quickSummaryUserNames">
            {user?.firstName.split(' ')[0].length > 8
              ? `${user?.firstName.split(' ')[0].slice(0, 8)}..`
              : user?.firstName.split(' ')[0]}
          </p>
        </Tooltip>
        <QuickSummaryDifferenceUserWeeklyData mainIndex={mainIndex} />
      </Box>
    </TableCell>
  );
}

export default QuickSummaryDifferenceTableCellWithAvatar;
