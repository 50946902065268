import Page from 'components/Page';
import React from 'react';
import StoreCashDrawerBody from 'sections/@dashboard/store/StoreCashDrawerBody';
import StoreCashDrawerHeader from 'sections/@dashboard/store/StoreCashDrawerHeader';

export default function StoreCashDrawer() {
  return (
    <Page title="Store: cash drawer">
      <StoreCashDrawerHeader />
      <StoreCashDrawerBody />
    </Page>
  );
}
