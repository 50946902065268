import { Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { yupResolver } from '@hookform/resolvers/yup';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import * as Yup from 'yup';
import React, { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getMenusDefaultCount, saveMenusDefaultCount } from 'redux/slices/menuEngineeringSlice';
import { successToastTime } from 'config';
import { toast } from 'react-toastify';
import FoodMenuDefaultCountIndivisualRow from './FoodMenuDefaultCountIndivisualRow';

const schemas = Yup.object().shape({
  sizeGroupOptionGroups: Yup.array().of(
    Yup.object().shape({
      defaultCount: Yup.number()
        // .positive()
        .typeError('')
        .transform((curr, orig) => (orig === '' ? 0 : curr)),
    })
  ),
});

function FoodMenuDefaultCount({ menu }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const { defaultCountLoading, foodMenuDefaultCount, postLoading } = useSelector((state) => state.menuEngineering);

  const defaultValues = {
    sizeGroupOptionGroups: [],
  };

  const methods = useForm({ defaultValues, resolver: yupResolver(schemas), mode: 'onChange' });

  const { reset, handleSubmit, control } = methods;

  const { fields } = useFieldArray({
    control,
    name: 'sizeGroupOptionGroups',
    keyName: 'fieldId',
  });
  console.log(methods.watch());

  const DefaultCountSubmitHandler = (data) => {
    console.log('🚀 => DefaultCountSubmitHandler => data=>', data);

    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        toast.success('Default Count Update', { autoClose: successToastTime });
        reset({ sizeGroupOptionGroups: foodMenuDefaultCount });
      }
    };
    const finalData = {
      menuId: menu?.id,
      departmentId,
      sizeGroupOptionGroups: data?.sizeGroupOptionGroups?.map((item) => ({
        id: item?.id,
        defaultCount: item?.defaultCount ? item?.defaultCount : 0,
        optionGroupId: item?.optionGroupId,
      })),
    };
    dispatch(saveMenusDefaultCount({ finalData, sizeGroupId: menu?.sizeGroupId, isSuccess: isSuccessful }));
  };

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getMenusDefaultCount({ departmentId, menuId: menu?.id, sizeGroupId: menu?.sizeGroupId }));
  }, [departmentId, dispatch, menu?.id, menu?.sizeGroupId]);

  useEffect(() => {
    if (!foodMenuDefaultCount?.length) return;
    reset({ sizeGroupOptionGroups: foodMenuDefaultCount });
  }, [foodMenuDefaultCount, reset]);

  const isChanged = methods.formState.isDirty;
  console.log(methods.formState.errors);

  return (
    <>
      {defaultCountLoading ? (
        <CustomLoadingScreen height="20vh" />
      ) : (
        <FormProvider {...methods}>
          <TableContainer style={{ maxWidth: '500px' }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '300px' }}>Option Name</TableCell>
                  <TableCell align="center">No. of Default</TableCell>
                  <TableCell align="center">action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {fields?.map((item, index) => (
                  <FoodMenuDefaultCountIndivisualRow menu={menu} key={index} item={item} index={index} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack alignItems="flex-start" marginTop={1}>
            {fields?.length !== 0 && (
              <Button
                variant="contained"
                color="secondary"
                disabled={!isChanged}
                onClick={handleSubmit(DefaultCountSubmitHandler)}
              >
                {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : 'Save'}
              </Button>
            )}
          </Stack>
        </FormProvider>
      )}
    </>
  );
}

FoodMenuDefaultCount.propTypes = {
  menu: PropTypes.object,
};
export default FoodMenuDefaultCount;
