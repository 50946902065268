import { Box, Card, MenuItem, Tooltip } from '@mui/material';
import Iconify from 'components/Iconify';
import { TableMoreMenu } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteUserNote } from 'redux/slices/userNoteSlice';

function IndividualUserNoteCard({ note, index, mainIndex, userInfo, onUpdate, remove }) {
  const departmentId = useGetDepartmentId();
  const [openMenu, setOpenMenuActions] = useState(null);
  const dispatch = useDispatch();

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const onEdit = () => {
    onUpdate({ note, index, mainIndex, userInfo });
  };

  const onDelete = () => {
    if (!note?.id) {
      remove(index);
    } else {
      const isSuccessful = (isSuccess) => {
        if (isSuccess) {
          remove(index);
        }
      };
      dispatch(deleteUserNote({ id: note?.id, departmentId, isSuccess: isSuccessful }));
    }
  };

  return (
    <Card
      style={{
        height: '50px',
        width: '130px',
        padding: '0.1rem',
        margin: '0rem',
        borderRadius: '3px',
        backgroundColor: 'rgba(209, 217, 224, .5)',
      }}
    >
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <p style={{ width: '83%' }}>
          {/* {note?.note} */}
          {note?.note?.length > 20 ? <Tooltip title={note?.note}>{note?.note?.slice(0, 20)}</Tooltip> : note?.note}
        </p>
        <p style={{ width: '17%' }}>
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    onEdit();
                  }}
                >
                  <Iconify icon={'eva:edit-fill'} />
                  Edit
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleCloseMenu();
                    onDelete();
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Iconify icon={'eva:trash-2-outline'} />
                  Delete
                </MenuItem>
              </>
            }
          />
        </p>
      </Box>

      {/* </Box> */}
    </Card>
  );
}

export default IndividualUserNoteCard;
