import { Button, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Iconify from 'components/Iconify';
import Page from 'components/Page';
import Scrollbar from 'components/Scrollbar';
import { TableHeadCustom } from 'components/table';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteDeliveryZone, getDeliveryZone } from 'redux/slices/posConfigSlice';
import UpdateDeliveryZone from './UpdateDeliveryZone';

const TABLE_HEAD = [
  { id: 1, label: 'S.N', align: 'left' },
  { id: 2, label: 'Name', align: 'left' },
  { id: 3, label: 'Price', align: 'center' },
  { id: 4, label: 'Actions', align: 'center' },
];

function PosDeliveryZoneIndex() {
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, id: null });

  const dispatch = useDispatch();
  const { loading, deliveryZoneData } = useSelector((state) => state.posConfig);
  useEffect(() => {
    dispatch(getDeliveryZone());
  }, [dispatch]);

  const HandleDeleteDeliveryZone = () => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        setOpenDeleteModal({ id: null, status: false });
      }
    };
    dispatch(deleteDeliveryZone({ id: openDeleteModal?.id, isSuccess: isSuccessful }));
  };
  return (
    <>
      <Page title="Pos config: Delivery Zone">
        <Scrollbar>
          <TableContainer>
            <Table stickyHeader size="small">
              <TableHeadCustom headLabel={TABLE_HEAD} />
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={4}>
                      <CustomLoadingScreen height="30vh" />
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {deliveryZoneData?.map((row, index) => (
                      <TableRow hover key={row.id}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell align="center">{row?.price}</TableCell>
                        <TableCell align="center">
                          <Button
                            style={{ color: '#4caf50', marginLeft: '10px', marginRight: '10px' }}
                            variant="text"
                            startIcon={<Iconify icon="material-symbols:edit-outline" />}
                            onClick={() => {
                              setOpenEdit(true);
                              setSelectedRow(row);
                            }}
                          >
                            Edit
                          </Button>
                          <Button
                            variant="text"
                            color="error"
                            startIcon={<Iconify icon="mdi:trash-can" />}
                            onClick={() => setOpenDeleteModal({ status: true, id: row?.id })}
                          >
                            Delete
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Page>
      {openDeleteModal?.status && (
        <CustomDeleteDialog
          open={openDeleteModal?.status}
          handleClose={() => setOpenDeleteModal({ status: false, id: null })}
          onAccept={HandleDeleteDeliveryZone}
        />
      )}
      {openEdit && (
        <UpdateDeliveryZone open={openEdit} handleClose={() => setOpenEdit(false)} updateData={selectedRow} />
      )}
    </>
  );
}

export default PosDeliveryZoneIndex;
