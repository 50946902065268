import { Button, Grid, Stack } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Iconify from 'components/Iconify';
import { FormProvider, RHFTextField } from 'components/hook-form';
import { useDrop } from 'react-dnd';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createMenuSizeGroup, deleteIndividualMenuSize } from 'redux/slices/menuERSizeAndOptionSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import SizeFileds from './SizeFileds';

const schema = Yup.object().shape({
  name: Yup.string().required(''),
  sizes: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(''),
    })
  ),
});

function MenuSizeForm({ handleClose, selectedRow, isEdit }) {
  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, id: null });
  const { postLoading, individualMenuSize } = useSelector((state) => state.menuERSizeAndOption);
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  const defaultValues = {
    name: '',
    sizes: [],
  };

  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onChange' });

  const { handleSubmit, control, reset } = methods;

  const HandleDeleteSingleSize = () => {
    dispatch(deleteIndividualMenuSize({ sizeId: openDeleteModal?.id, departmentId, sizeGroupId: selectedRow?.id }));
    setOpenDeleteModal({ status: false, id: null });
  };

  const SizeSubmitHandler = (data) => {
    const finalData = { ...data, departmentId, id: selectedRow?.id };

    dispatch(createMenuSizeGroup({ finalData, handleClose, isEdit }));
  };

  useEffect(() => {
    if (!selectedRow) return;
    if (!individualMenuSize) return;
    reset({ name: selectedRow?.name, sizes: individualMenuSize });
  }, [individualMenuSize, reset, selectedRow]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(SizeSubmitHandler)}>
        <Stack my="1rem" gap="1rem">
          <RHFTextField name="name" label="Name" />
          <SizeFileds setOpenDeleteModal={setOpenDeleteModal} />
        </Stack>

        <Stack alignItems="flex-end" marginTop={1}>
          <Button variant="contained" type="submit" color="secondary">
            {postLoading ? <CustomLoadingScreen circularWidth={'20px'} color="secondary" /> : 'Submit'}
          </Button>
        </Stack>
      </FormProvider>
      {openDeleteModal.status && (
        <CustomDeleteDialog open={openDeleteModal} handleClose={setOpenDeleteModal} onAccept={HandleDeleteSingleSize} />
      )}
    </>
  );
}

export default MenuSizeForm;
