import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import UserWorkReportWeekWiseTableRow from './UserWorkReportWeekWiseTableRow';
import UserWorkReportWeekWiseSummaryCell from './UserWorkReportWeekWiseSummaryCell';

UserWorkReportDetailsTableRow.propTypes = {
  weekWiseClockReportIndex: PropTypes.number,
  weekWiseClockReport: PropTypes.array,
};

function UserWorkReportDetailsTableRow({ weekWiseClockReportIndex, weekWiseClockReport }) {
  return (
    <TableRow
      hover
      key={weekWiseClockReportIndex}
      style={{
        borderBottom: '1px solid black',
        // backgroundColor: weekWiseClockReportIndex % 2 === 0 ? '#cae6e1b0' : 'rgba(231, 211, 211, 0.5)',
      }}
    >
      <TableCell>
        <TableRow>
          <TableCell colSpan={4} style={{ margin: 0, padding: 0 }}>
            {weekWiseClockReport?.map((timeReport, timeIndex) => (
              <UserWorkReportWeekWiseTableRow
                timeReport={timeReport}
                key={timeIndex}
                timeIndex={timeIndex}
                weeklyArray={weekWiseClockReport}
              />
            ))}
          </TableCell>
        </TableRow>
      </TableCell>
      <UserWorkReportWeekWiseSummaryCell weeklyArray={weekWiseClockReport} />
    </TableRow>
  );
}

export default UserWorkReportDetailsTableRow;
