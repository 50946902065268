import { Divider, Stack, Typography } from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import FoodRecipeFormCalculatedSize from './FoodRecipeFormCalculatedSize';

function FoodRecipeFormCalculated({ sizes, menuName }) {
  const { control } = useFormContext();

  const watchRecipe = useWatch({
    control,
    name: 'receipeDTOs',
  });
  return (
    <Stack mb="1rem" flexDirection={{ xs: 'column', md: 'row' }} gap="1rem">
      {watchRecipe?.map((recipe, index) => {
        const size = sizes?.find((size) => size?.id === recipe?.sizeId);

        return <FoodRecipeFormCalculatedSize index={index} key={index} size={size} menuName={menuName} />;
      })}
    </Stack>
  );
}

export default FoodRecipeFormCalculated;
