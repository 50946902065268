import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Scrollbar from 'components/Scrollbar';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { FormProvider, RHFTextField } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  deleteBindedOption,
  deleteBindedSize,
  updateExtraPriceForSizeOption,
} from 'redux/slices/menuERSizeAndOptionSlice';
import Iconify from 'components/Iconify';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';

MenuSizeOptionPriceEditForm.propTypes = {
  handleClose: PropTypes.func,
  selectedRow: PropTypes.object,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function MenuSizeOptionPriceEditForm({ handleClose, selectedRow }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, optionId: null });
  const [openSizeDelete, setOpenSizeDelete] = useState({ status: false, sizeGroupOptionGroupId: null });
  const { postLoading } = useSelector((state) => state.menuEngineering);
  const { sizeOptionDetails } = useSelector((state) => state.menuERSizeAndOption);

  const schema = Yup.object().shape({
    sizes: Yup.array().of(
      Yup.object().shape({
        options: Yup.array().of(
          Yup.object().shape({
            extraPrice: Yup.number()
              .transform((curr, orig) => (orig === '' ? 0 : curr))
              .typeError(''),
          })
        ),
      })
    ),
  });

  const defaultValues = {
    sizes: [],
  };
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { handleSubmit, control, reset, register } = methods;

  const OptionPriceSubmitHandler = (data) => {
    const finalData = {
      ...data,
      sizeGroupOptionGroupId: selectedRow?.id,
      sizeGroupId: selectedRow?.sizeGroupId,
      optionGroupId: selectedRow?.optionGroupId,
      departmentId,
    };
    dispatch(updateExtraPriceForSizeOption({ finalData, handleClose }));
  };

  const HandleOptionDelete = () => {
    dispatch(
      deleteBindedOption({
        departmentId,
        optionId: openDeleteModal?.optionId,
        sizeGroupOptionGroupId: selectedRow?.id,
        sizeGroupId: selectedRow.sizeGroupId,
        optionGroupId: selectedRow.optionGroupId,
      })
    );

    setOpenDeleteModal({ optionId: null, status: false });
  };

  const HandleSizeDelete = () => {
    dispatch(
      deleteBindedSize({
        departmentId,
        sizeId: watchDelete,
        sizeGroupOptionGroupId: selectedRow?.id,
        sizeGroupId: selectedRow?.sizeGroupId,
        optionGroupId: selectedRow?.optionGroupId,
      })
    );
    setOpenSizeDelete({ status: false, sizeGroupOptionGroupId: null });
  };

  useEffect(() => {
    if (!sizeOptionDetails) return;
    reset({ sizes: sizeOptionDetails?.sizes });
  }, [reset, sizeOptionDetails]);

  const watchDelete = methods.watch('deleteSize');

  return (
    <>
      <Modal
        open={openSizeDelete?.status}
        onClose={() => setOpenSizeDelete({ status: false, sizeGroupOptionGroupId: null })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ marginBottom: '10px' }}>
            Delete Size Price
          </Typography>
          <Controller
            control={control}
            name={`deleteSize`}
            render={({ field: { onChange, ...field } }) => (
              <Autocomplete
                label="Select Ingredient"
                disableClearable
                onChange={(event, data, reason, details) => {
                  onChange(data?.id);
                }}
                getOptionLabel={(option) => option?.name}
                isOptionEqualToValue={(option, value) => option?.id === value}
                {...field}
                options={sizeOptionDetails?.sizes || []}
                renderInput={(params) => <TextField {...params} label="Select Size" style={{ width: '15rem' }} />}
              />
            )}
          />
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to delete price of selected size?
          </Typography>
          <Stack alignItems={'flex-end'}>
            <Stack direction={'row'} spacing={1}>
              <Button
                variant="contained"
                color="success"
                style={{ marginRight: '5px' }}
                onClick={HandleSizeDelete}
                disabled={!watchDelete}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setOpenSizeDelete({ status: false, sizeGroupOptionGroupId: null })}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
      <FormProvider methods={methods} onSubmit={handleSubmit(OptionPriceSubmitHandler)}>
        <Stack spacing={3}>
          <Stack spacing={2}>
            <Scrollbar>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ width: '140px' }}>Options</TableCell>
                      {sizeOptionDetails?.sizes?.map((item, index) => (
                        <TableCell key={index} align="center">
                          {item?.name}
                        </TableCell>
                      ))}
                      <TableCell>action</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {sizeOptionDetails?.options?.map((option, optionIndex) => (
                      <TableRow key={optionIndex} hover>
                        <TableCell>
                          {option?.name?.length > 12 ? (
                            <Tooltip title={option?.name} placement="top">
                              <Typography noWrap>
                                {option?.name.length > 12 ? `${option?.name.slice(0, 12)}..` : option?.name}
                              </Typography>
                            </Tooltip>
                          ) : (
                            option?.name
                          )}
                        </TableCell>
                        {sizeOptionDetails?.sizes?.map((item, index) => (
                          <TableCell key={index} align="center" style={{ padding: '0' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                              <div style={{ margin: '5px' }}>
                                <input
                                  type="hidden"
                                  {...register(`sizes.${index}.options.${optionIndex}.extraPrice`)}
                                  value={0}
                                />
                                <Controller
                                  name={`sizes.${index}.options.${optionIndex}.extraPrice`}
                                  control={control}
                                  render={({ field, fieldState: { error } }) => (
                                    <TextField
                                      {...field}
                                      fullWidth
                                      value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                                      InputProps={{
                                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                      }}
                                      id="filled-basic"
                                      variant={'standard'}
                                      error={!!error}
                                      helperText={error?.message}
                                      sx={{ width: '60px' }}
                                    />
                                  )}
                                />
                              </div>
                              <div style={{ margin: '5px' }}>
                                <input
                                  type="hidden"
                                  {...register(`sizes.${index}.options.${optionIndex}.qtyUsed`)}
                                  value={0}
                                />
                                <Controller
                                  name={`sizes.${index}.options.${optionIndex}.qtyUsed`}
                                  control={control}
                                  render={({ field, fieldState: { error } }) => (
                                    <TextField
                                      {...field}
                                      fullWidth
                                      value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                                      InputProps={{
                                        endAdornment: <InputAdornment position="end">gm</InputAdornment>,
                                      }}
                                      id="filled-basic"
                                      variant={'standard'}
                                      error={!!error}
                                      helperText={error?.message}
                                      sx={{ width: '60px' }}
                                    />
                                  )}
                                />
                              </div>
                            </div>
                          </TableCell>
                        ))}
                        <TableCell>
                          <Button
                            variant="text"
                            color="error"
                            onClick={() => {
                              setOpenDeleteModal({
                                status: true,
                                optionId: option?.id,
                              });
                            }}
                          >
                            <Iconify icon="mdi:delete" height={20} width={20} />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  {/* )} */}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Stack>

          <Stack alignItems={'flex-end'}>
            <Stack display={'flex'} flexDirection={'row'}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={postLoading}
                style={{ marginRight: '20px' }}
              >
                {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : 'Update'}
              </Button>
              <Button
                variant="text"
                color="error"
                onClick={() => {
                  setOpenSizeDelete({ status: true, sizeGroupOptionGroupId: selectedRow?.id });
                }}
                startIcon={<Iconify icon="mdi:delete" height={20} width={20} />}
              >
                Size Price
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </FormProvider>

      {openDeleteModal?.status && (
        <CustomDeleteDialog open={openDeleteModal} handleClose={setOpenDeleteModal} onAccept={HandleOptionDelete} />
      )}
    </>
  );
}

export default MenuSizeOptionPriceEditForm;
