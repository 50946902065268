import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function RecipeSummaryIngredientDetails({ ingredient, index }) {
  const { foodMenuRecipeSummary } = useSelector((state) => state.menuEngineering);

  const findCOGS = (size) => {
    const unitPrice = ingredient?.unitPrice;
    const unitWeight = ingredient?.unitWeight;
    const qtyUsed = size?.qtyUsed;

    if (!unitPrice || !unitWeight || !qtyUsed) return 0;
    return ((unitPrice / unitWeight) * (qtyUsed / 1000)).toFixed(2);
  };
  const findDetails = ({ size, option }) =>
    // if (!option?.ingredient) {
    //   return `${size?.qtyUsed ?? 0} gm / $ 0`;
    // }
    `${size?.qtyUsed ?? 0} gm / $ ${findCOGS(option?.menuSizes?.find((s) => s?.id === size?.id))}`;
  return (
    <TableRow hover key={ingredient?.id} style={{ backgroundColor: `rgba(0,255,0,0.1)`, color: 'white' }}>
      <TableCell align="left">{foodMenuRecipeSummary?.menuSizeOptions?.menuOptions?.length + index + 1}.</TableCell>
      <TableCell>{ingredient?.name}</TableCell>
      {/* {foodMenuRecipeSummary?.menuSizeIngredient?.menuSizes?.map((size) => (
        <TableCell align="center" key={size?.id}>
          {findDetails({ size, ingredient })}
        </TableCell>
      ))} */}
      {ingredient?.menuSizes?.map((size) => (
        <TableCell align="center" key={size?.id}>
          {size?.qtyUsed} gm / $ {findCOGS(size)}
        </TableCell>
      ))}
    </TableRow>
  );
}

RecipeSummaryIngredientDetails.propTypes = {
  ingredient: PropTypes.object,
  index: PropTypes.number,
};

export default RecipeSummaryIngredientDetails;
