import React, { useEffect, useState } from 'react';
import Page from 'components/Page';
import { PATH_DASHBOARD } from 'routes/paths';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { useSelector, useDispatch } from 'react-redux';
import { getApprovedReports } from 'redux/slices/timeSlice';
import moment from 'moment';
import { getStoreDate } from 'redux/slices/shiftRequestSlice';
import {
  Avatar,
  Box,
  Card,
  Divider,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { TableHeadCustom, TableNoData } from 'components/table';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';

const TABLE_HEAD = [
  { id: 'name', label: 'User Name', align: 'left' },
  // { id: 'status', label: 'Status', align: 'left' },
  { id: 'totalWork', label: 'Work Time', align: 'center' },
  { id: 'totalBreak', label: 'Break Time', align: 'center' },
  { id: 'actualPay', label: 'Actual Pay', align: 'center' },
  { id: 'approvedBy', label: 'Approved By', align: 'left' },
  { id: 'approvedDate', label: 'Approved At', align: 'left' },
];

export default function ApprovedReport() {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState();
  const [pageNo, setPageNo] = useState(1);

  //   getting store date
  const { storeDate } = useSelector((state) => state.ShiftRequest);

  const { approvedReports, loading: approvedReportsLoading } = useSelector((state) => state.time);

  const { selectedDepartment } = useSelector((state) => state.userDepartment);

  const selectedDateChange = (date) => {
    setSelectedDate(moment(date).format('YYYY-MM-DD'));
  };
  useEffect(() => {
    dispatch(getStoreDate());
  }, [dispatch]);

  useEffect(() => {
    if (selectedDepartment) {
      dispatch(getApprovedReports({ selectedDate, departmentId: selectedDepartment.id }));
    }
  }, [storeDate, dispatch, selectedDate, selectedDepartment]);

  useEffect(() => {
    if (storeDate) {
      setSelectedDate(moment(storeDate).format('YYYY-MM-DD'));
    }
  }, [storeDate, dispatch]);

  return (
    <Page title="Approved Time Reports">
      <HeaderBreadcrumbs
        heading="Approve Time Reports"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'Time Store' },
          { name: 'Approved Time Clock Reports' },
        ]}
      />
      <Card>
        <Typography variant="h4" sx={{ py: 1, px: 3 }}>
          Approved Time Reports <Divider />
        </Typography>
        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
          <DatePicker
            label="Date"
            value={selectedDate}
            onChange={selectedDateChange}
            renderInput={(params) => <TextField {...params} error={false} />}
          />
        </Stack>
        <Table>
          <TableHeadCustom headLabel={TABLE_HEAD} />
          <TableBody>
            {approvedReportsLoading ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <CustomLoadingScreen height="20vh" />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {approvedReports?.timeReports?.map((report, index) => (
                  <TableRow hover key={index}>
                    <TableCell sx={{ display: 'flex' }}>
                      {report?.userThumbnailURL ? (
                        <Avatar
                          src={report.userThumbnailURL}
                          style={{
                            height: '30px',
                            width: '30px',
                            borderRadius: '100px',
                            marginRight: '20px',
                          }}
                        />
                      ) : (
                        <Avatar alt="" src="" style={{ height: '30px', width: '30px', marginRight: '20px' }} />
                      )}{' '}
                      <Typography style={{ marginTop: '5px' }} variant="subtitle2" noWrap>
                        {report.userFirstName} {report.userLastName}
                      </Typography>
                    </TableCell>
                    {/* <TableCell>{report?.paymentStatus}</TableCell> */}
                    <TableCell style={{ textAlign: 'center' }}>{`${Math.floor(report?.totalWorkMins / 60)} hr. ${
                      report?.totalWorkMins % 60
                    } min`}</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{report?.totalBreakMins} min</TableCell>
                    <TableCell style={{ textAlign: 'center' }}>{report?.actualPay}</TableCell>
                    <TableCell sx={{ display: 'flex' }}>
                      {report?.approverThumbnailURL ? (
                        <Avatar
                          src={report.approverThumbnailURL}
                          style={{
                            height: '30px',
                            width: '30px',
                            borderRadius: '100px',
                            marginRight: '20px',
                          }}
                        />
                      ) : (
                        <Avatar alt="" src="" style={{ height: '30px', width: '30px', marginRight: '20px' }} />
                      )}{' '}
                      <Typography style={{ marginTop: '5px' }} variant="subtitle2" noWrap>
                        {report.approverFirstName} {report.approverLastName}
                      </Typography>
                    </TableCell>
                    <TableCell>{moment(report.approvalDateTime).format('MMM DD, hh:mm A')}</TableCell>
                  </TableRow>
                ))}
                <TableNoData isNotFound={approvedReports?.timeReports?.length <= 0} />
              </>
            )}
          </TableBody>
        </Table>
        <Box
          sx={{ position: 'relative', marginTop: '10px' }}
          justifyContent={'end'}
          alignContent="center"
          display={'flex'}
        >
          <Pagination
            count={approvedReports?.paginationInfo?.totalPages}
            page={pageNo}
            onChange={(e, value) => setPageNo(value)}
          />
        </Box>
      </Card>
    </Page>
  );
}
