import Page from 'components/Page';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { getEstimatedTimeData } from 'redux/slices/storeConfigSlice';
import EstimatedTimeBody from './EstimatedTimeBody';

function EstimatedTimeIndex() {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getEstimatedTimeData({ departmentId }));
  }, [dispatch, departmentId]);

  return (
    <Page title="Estimated Time">
      <EstimatedTimeBody />
    </Page>
  );
}

export default EstimatedTimeIndex;
