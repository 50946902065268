import { Container } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Page from 'components/Page';
import React from 'react';
import { PATH_DASHBOARD } from 'routes/paths';

import FeatureCard from 'sections/@dashboard/departments/FeatureCard';

export default function DepartmentRoleFeatures() {
  return (
    <>
      <Page title="Roles & Features">
        {/* <Container> */}
        <HeaderBreadcrumbs
          heading="Roles & Features"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Department' },
            // { name: 'Features' }
          ]}
        />
        {/* <RoleFeatureCard /> */}
        <FeatureCard />
        {/* </Container> */}
      </Page>
    </>
  );
}
