import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { RHFTextField } from 'components/hook-form';
import Iconify from 'components/Iconify';
import { TableHeadCustom } from 'components/table';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteOnlineSalesPlatform, getOnlineSalesPlatform } from 'redux/slices/storeSlice';
import SalesPlatformCreateForm from './SalesPlatformCreateForm';

const TABLE_HEAD = [
  { id: 'id', label: 'SN', align: 'center' },
  { id: 'name', label: 'Platform Name', align: 'left' },
  { id: 'commissionPercent', label: 'Commission', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function SalesPlatformList() {
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const deleteModalHandleClose = () => setOpenDeleteModal(false);
  const [platformId, setPlatformId] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const handleUpdateClose = () => setOpenUpdateModal(false);
  const [updateData, setUpdateData] = useState();

  const { onlineSalesPlatform, loading: onlineSalesPlatformLoading } = useSelector((state) => state.store);
  const { selectedDepartment } = useSelector((state) => state.userDepartment);

  const handleDelete = (id) => {
    setOpenDeleteModal(true);
    setPlatformId(id);
  };

  const deleteOnlinePlatform = () => {
    const finalData = { orderPlatformId: platformId, departmentId: selectedDepartment.id };
    dispatch(deleteOnlineSalesPlatform(finalData));
    deleteModalHandleClose();
  };

  const handleEdit = (data) => {
    setUpdateData(data);
    setOpenUpdateModal(true);
  };

  return (
    <Box style={{ marginTop: '2.5rem' }}>
      {/* Delete Modal */}
      <Modal
        open={openDeleteModal}
        //   onClose={deleteModalHandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to delete this platform?
          </Typography>
          <Typography id="modal-modal-description" align="center" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="success"
              style={{ marginRight: '5px' }}
              onClick={() => deleteOnlinePlatform()}
            >
              Delete
            </Button>
            <Button variant="outlined" color="error" onClick={deleteModalHandleClose}>
              Cancel
            </Button>
          </Typography>
        </Box>
      </Modal>

      <Typography variant="h6" gutterBottom>
        Online Sales Platform List
        <Divider />
      </Typography>
      <Table size="small">
        <TableHeadCustom headLabel={TABLE_HEAD} />
        <TableBody>
          {onlineSalesPlatformLoading ? (
            <TableRow>
              <TableCell colSpan={4}>
                <CustomLoadingScreen height="20vh" />
              </TableCell>
            </TableRow>
          ) : (
            onlineSalesPlatform?.onlinePlatforms?.map((elm, index) => (
              <TableRow hover key={index}>
                <TableCell align="center">{index + 1}</TableCell>
                <TableCell>{elm?.platformName}</TableCell>
                <TableCell align="center">{elm?.commissionPercent}%</TableCell>
                <TableCell align="center">
                  <Button onClick={() => handleEdit(elm)}>
                    <Iconify icon={'eva:edit-2-outline'} width={20} height={20} />
                  </Button>
                  <Button variant="text" color="error" onClick={() => handleDelete(elm?.id)}>
                    <Iconify icon={'eva:trash-2-outline'} width={20} height={20} />
                  </Button>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
      </Table>
      <Box
        sx={{ position: 'relative', marginTop: '20px' }}
        justifyContent={'end'}
        alignContent="center"
        display={'flex'}
      >
        <Pagination
          count={onlineSalesPlatform?.paginationInfo?.totalPages}
          page={pageNo}
          onChange={(e, value) => setPageNo(value)}
        />
      </Box>
      {openUpdateModal && (
        <SalesPlatformCreateForm open={openUpdateModal} setOpen={setOpenUpdateModal} updateData={updateData} edit />
      )}
    </Box>
  );
}
