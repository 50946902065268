import { Button, Card, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Iconify from 'components/Iconify';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChecklistCategory } from 'redux/slices/storeDocumentSlice';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Scrollbar from 'components/Scrollbar';
import ChecklistCategoriesForm from './ChecklistCategoriesForm';
import ChecklistIndividualItem from './ChecklistIndividualItem';

function ChecklistCategories() {
  const [openAdd, setOpenAdd] = useState(false);
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const { checklistCategories, loading } = useSelector((state) => state.storeDocument);

  // useEffect(() => {
  //   if (!departmentId) return;
  //   dispatch(getChecklistCategory({ departmentId }));
  // }, [departmentId, dispatch]);

  return (
    <>
      <Stack>
        {loading ? (
          <CustomLoadingScreen height="20vh" />
        ) : (
          <Card style={{ marginTop: '10px', width: '480px' }}>
            <Scrollbar>
              <TableContainer style={{ maxWidth: '480px' }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>S.N.</TableCell>
                      <TableCell style={{ width: '300px' }}>Title</TableCell>
                      <TableCell style={{ width: '100px' }} align="left">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
                <TableBody>
                  {checklistCategories?.map((item, index) => (
                    <ChecklistIndividualItem key={item.id} item={item} index={index} />
                  ))}
                </TableBody>
              </TableContainer>
            </Scrollbar>

            <Stack direction={'row'} justifyContent={'flex-start'}>
              <Button variant={'contained'} size="small" style={{ margin: '10px' }} onClick={() => setOpenAdd(true)}>
                <Iconify icon={'eva:plus-fill'} />
                Add Category
              </Button>
            </Stack>
          </Card>
        )}
      </Stack>
      {openAdd && <ChecklistCategoriesForm setOpen={setOpenAdd} open={openAdd} />}
    </>
  );
}

export default ChecklistCategories;
