import { Autocomplete, Stack, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useSelector } from 'react-redux';
import FoodMenuDrawer from './FoodMenuDrawer';

function FoodMenuSearch() {
  const [open, setOpen] = useState(false);
  const { control } = useFormContext();
  const [indexList, setIndexList] = useState(null);

  const { foodMenus } = useSelector((state) => state.menuEngineering);

  const watchData = useWatch({
    control,
    name: 'data',
  });

  const filterAllMenusWithCategories = watchData?.map((obj) => obj?.menus).flat() || [];

  const onChangeSearchHandler = (event, value, reason, details) => {
    const findCategoryIndex = foodMenus?.findIndex((category) => category?.id === details?.option.categoryId);
    const findMenuIndex = foodMenus?.[findCategoryIndex]?.menus?.findIndex((menu) => menu?.id === details?.option.id);

    setIndexList({ indexList: { mainIndex: findCategoryIndex, menuIndex: findMenuIndex }, menu: details?.option });
  };

  return (
    <>
      <Autocomplete
        name="searchMenu"
        options={filterAllMenusWithCategories}
        getOptionLabel={(option) => option?.name}
        includeInputInList={false}
        onChange={(event, value, reason, details) => {
          if (reason === 'clear') return;
          setOpen(true);
          onChangeSearchHandler(event, value, reason, details);
        }}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderInput={(params) => <TextField {...params} label="Search Menus" value="" />}
        fullWidth
        renderOption={(props, option) => (
          <Stack
            component="li"
            flexDirection={{ xs: 'column', md: 'row' }}
            alignItems="flex-start"
            width="100%"
            {...props}
          >
            <Stack>{option?.name}</Stack>
            <Stack direction={'row'} ml="auto" gap="1rem">
              {option?.sizes?.map((size, index) => (
                <Stack
                  gap="1rem"
                  key={index}
                  bgcolor={'rgba(145, 158, 171, 0.12)'}
                  direction={'row'}
                  p={0.5}
                  borderRadius={'10px'}
                >
                  <Typography component={'p'}>{size?.name}</Typography>
                  <Typography component={'p'} bgcolor={'white'} p={0.5}>
                    $ {size?.additionalPrice ? size?.additionalPrice : 0}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        )}
      />

      {open && <FoodMenuDrawer open={open} setOpen={setOpen} indexList={indexList?.indexList} menu={indexList?.menu} />}
    </>
  );
}

export default FoodMenuSearch;
