import { Box } from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import FoodMenuFilter from './FoodMenuFilter';
import FoodMenuBody from './FoodMenuBody';

const MenuPriceAndSizeSchema = Yup.object().shape({
  data: Yup.array().of(
    Yup.object().shape({
      menus: Yup.array().of(
        Yup.object().shape({
          sizes: Yup.array().of(
            Yup.object().shape({
              price: Yup.number()
                .required('')
                .typeError('')
                .moreThan(-1, '')
                .nullable('')
                .transform((curr, orig) => (orig === '' ? 0 : curr)),
            })
          ),
        })
      ),
    })
  ),
});

function FoodMenuIndex() {
  const defaultValues = { data: [] };

  const methods = useForm({ defaultValues, resolver: yupResolver(MenuPriceAndSizeSchema), mode: 'onBlur' });

  const { reset, control, handleSubmit } = methods;
  return (
    <Box>
      <FormProvider {...methods}>
        <FoodMenuFilter />
        <FoodMenuBody />
      </FormProvider>
    </Box>
  );
}

export default FoodMenuIndex;
