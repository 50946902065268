import PropTypes from 'prop-types';
import { Box, Button, Card, Grid, Modal, Stack, Typography } from '@mui/material';
import Iconify from 'components/Iconify';
import { TableNoData } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { fCurrency } from 'utils/formatNumber';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteRunningCost } from 'redux/slices/operationalSlice';
// import Typography from 'theme/overrides/Typography';
import AdditionalRunningCost from './AdditionalRunningCost';
import AddNewRunningCostCategory from './AddNewRunningCostCategory';
import RunningCostAddEditForm from './RunningCostAddEditForm';

RunningCostCard.propTypes = {
  choosenDate: PropTypes.string,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  textAlign: 'center',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function RunningCostCard({ choosenDate }) {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openAddCategory, setOpenAddCategory] = useState(false);
  const [totalAdditionalRunningCost, setTotalAdditionalRunningCost] = useState(0);

  const { runningCost, loading: runningCostLoading } = useSelector((state) => state.operational);
  const departmentId = useGetDepartmentId();
  const { timeDT } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const handleDelete = () => {
    const finalData = {
      id: runningCost?.id,
      departmentId,
      // storeId: storeDetail?.id,
      month: moment(timeDT).format('MM'),
      year: moment(timeDT).format('YYYY'),
    };
    dispatch(deleteRunningCost(finalData));
  };

  const findTotalRunningCost = (runningCost) => {
    if (!runningCost) return 0;
    let totalRunningCost = 0;
    if (runningCost?.asicsFee) totalRunningCost += runningCost?.asicsFee;
    if (runningCost?.bankCharge) totalRunningCost += runningCost?.bankCharge;
    if (runningCost?.chemicals) totalRunningCost += runningCost?.chemicals;
    if (runningCost?.crockery) totalRunningCost += runningCost?.crockery;
    if (runningCost?.decorations) totalRunningCost += runningCost?.decorations;
    if (runningCost?.donations) totalRunningCost += runningCost?.donations;
    if (runningCost?.eftRentalFee) totalRunningCost += runningCost?.eftRentalFee;
    if (runningCost?.fireEquipment) totalRunningCost += runningCost?.fireEquipment;
    if (runningCost?.fuel) totalRunningCost += runningCost?.fuel;
    if (runningCost?.lateFeesPaid) totalRunningCost += runningCost?.lateFeesPaid;
    if (runningCost?.lighting) totalRunningCost += runningCost?.lighting;
    if (runningCost?.maintenance) totalRunningCost += runningCost?.maintenance;
    if (runningCost?.marketingCalendar) totalRunningCost += runningCost?.marketingCalendar;
    if (runningCost?.miscellaneous) totalRunningCost += runningCost?.miscellaneous;
    if (runningCost?.pestControls) totalRunningCost += runningCost?.pestControls;
    if (runningCost?.pettyCost) totalRunningCost += runningCost?.pettyCost;
    if (runningCost?.plantEquipment) totalRunningCost += runningCost?.plantEquipment;
    if (runningCost?.printingStationary) totalRunningCost += runningCost?.printingStationary;
    if (runningCost?.security) totalRunningCost += runningCost?.security;
    if (runningCost?.uniforms) totalRunningCost += runningCost?.uniforms;
    if (runningCost?.vehiclesMaintenance) totalRunningCost += runningCost?.vehiclesMaintenance;
    if (runningCost?.vehiclesRego) totalRunningCost += runningCost?.vehiclesRego;

    return totalRunningCost;
  };

  const totalRunningCost = findTotalRunningCost(runningCost);

  return (
    <>
      <Modal open={openDeleteModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to delete ?
          </Typography>
          <Typography id="modal-modal-description" align="center" sx={{ mt: 2 }}>
            <Button variant="contained" color="success" style={{ marginRight: '5px' }} onClick={() => handleDelete()}>
              Delete
            </Button>
            <Button variant="outlined" color="error" onClick={() => setOpenDeleteModal(false)}>
              Cancel
            </Button>
          </Typography>
        </Box>
      </Modal>
      <Card sx={{ p: 2 }}>
        {!runningCost?.id ? (
          <Stack spacing={2} direction="row" sx={{ mt: 2 }} justifyContent="center">
            <TableNoData isNotFound={!runningCost?.id} />
          </Stack>
        ) : (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Marketing-Calendar: &nbsp;&nbsp; </b>
                  {runningCost?.marketingCalendar}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Bank-Charge: &nbsp;&nbsp;</b>
                  {runningCost?.bankCharge}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>EFT-Rental-Fee: &nbsp;&nbsp;</b>
                  {runningCost?.eftRentalFee}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Donations: &nbsp;&nbsp;</b>
                  {runningCost?.donations}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>asics-Fee: &nbsp;&nbsp;</b>
                  {runningCost?.asicsFee}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Late-Fees-Paid: &nbsp;&nbsp;</b>
                  {runningCost?.lateFeesPaid}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Plant-Equipment: &nbsp;&nbsp;</b>
                  {runningCost?.plantEquipment}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Printing-Stationary: &nbsp;&nbsp;</b>
                  {runningCost?.printingStationary}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Security: &nbsp;&nbsp;</b>
                  {runningCost?.security}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Uniforms: &nbsp;&nbsp;</b>
                  {runningCost?.uniforms}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Crockery: &nbsp;&nbsp;</b>
                  {runningCost?.crockery}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Chemicals: &nbsp;&nbsp;</b>
                  {runningCost?.chemicals}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Lighting: &nbsp;&nbsp;</b>
                  {runningCost?.lighting}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Fire-Equipment: &nbsp;&nbsp;</b>
                  {runningCost?.fireEquipment}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Pest-Controls: &nbsp;&nbsp;</b>
                  {runningCost?.pestControls}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Decorations: &nbsp;&nbsp;</b>
                  {runningCost?.decorations}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Maintenance: &nbsp;&nbsp;</b>
                  {runningCost?.maintenance}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Petty-Cost: &nbsp;&nbsp;</b>
                  {runningCost?.pettyCost}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Fuel: &nbsp;&nbsp;</b>
                  {runningCost?.fuel}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Vehicles-Rego: &nbsp;&nbsp;</b>
                  {runningCost?.vehiclesRego}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Vehicles-Maintenance: &nbsp;&nbsp;</b>
                  {runningCost?.vehiclesMaintenance}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <b>Miscellaneous: &nbsp;&nbsp;</b>
                  {runningCost?.miscellaneous}
                </div>
              </Grid>
              <Grid item xs={12}>
                <AdditionalRunningCost
                  runningCost={runningCost}
                  setTotalAdditionalRunningCost={setTotalAdditionalRunningCost}
                />
              </Grid>
              <Grid item xs={12}>
                <Stack alignItems={'flex-end'} spacing={3}>
                  <div>
                    <Button variant="text" disabled>
                      Total = {fCurrency(totalRunningCost)} + {fCurrency(totalAdditionalRunningCost)}
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ marginRight: '10px' }}
                      startIcon={<Iconify icon="eva:plus-fill" />}
                      onClick={() => setOpenAddCategory(true)}
                    >
                      New Category
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      style={{ marginRight: '10px' }}
                      startIcon={<Iconify icon="material-symbols:edit" />}
                      onClick={() => setOpenEdit(true)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="text"
                      style={{ color: 'red' }}
                      startIcon={<Iconify icon="material-symbols:delete" />}
                      onClick={() => setOpenDeleteModal(true)}
                    >
                      Delete
                    </Button>
                  </div>
                </Stack>
              </Grid>
            </Grid>
          </>
        )}
      </Card>
      {openEdit && (
        <RunningCostAddEditForm
          edit
          open={openEdit}
          setOpen={setOpenEdit}
          updateData={runningCost}
          choosenDate={choosenDate}
          // adiitionalCost={additionalRunningCost}
        />
      )}
      {openAddCategory && (
        <AddNewRunningCostCategory open={openAddCategory} setOpen={setOpenAddCategory} runningCost={runningCost} />
      )}
      {/* )} */}
    </>
  );
}
