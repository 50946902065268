import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { TableHeadCustom } from 'components/table';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFirstAidLog } from 'redux/slices/storeDocumentSlice';
import FirstAidTableRow from './FirstAidTableRow';

const headLabel = [
  { id: 'sn', label: 'S.N.' },
  { id: 'details', label: 'Details' },
  { id: 'isAvailable', label: 'Available' },
  { id: 'quantity', label: 'Quantity' },
  { id: 'comment', label: 'Comment' },
  { id: 'actions', label: 'Actions', align: 'right' },
];
function FirstAidLogs() {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const { loading, firstAidLogs } = useSelector((state) => state.storeDocument);

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getFirstAidLog({ departmentId }));
  }, [departmentId, dispatch]);
  return (
    <Table size="small">
      {/* <TableHeadCustom headLabel={headLabel} /> */}
      <TableHead>
        <TableRow>
          <TableCell>
            <b>S.N.</b>
          </TableCell>
          <TableCell>
            <b>Name</b>
          </TableCell>
          <TableCell>
            <b>Available</b>
          </TableCell>

          <TableCell>
            <b>Qty</b>
          </TableCell>

          <TableCell>
            <b>Comment</b>
          </TableCell>
          <TableCell align="right">
            <b>Actions</b>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <TableRow>
            <TableCell colSpan={headLabel.length}>
              <CustomLoadingScreen height="30vh" />
            </TableCell>
          </TableRow>
        ) : (
          firstAidLogs?.map((item, index) => <FirstAidTableRow key={index} row={item} index={index} />)
        )}
      </TableBody>
    </Table>
  );
}

export default FirstAidLogs;
