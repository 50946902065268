import { Box } from '@mui/material';
import React from 'react';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import DeliveryZoneForm from './DeliveryZoneForm';

function DeliveryZoneCreate({ open, handleClose }) {
  return (
    <CustomOutletModal open={open} zIndex="1600" title="Add New Delivery Zone">
      {/* <Box style={{ width: '400px' }}> */}
      <DeliveryZoneForm handleClose={handleClose} />
      {/* </Box> */}
    </CustomOutletModal>
  );
}

export default DeliveryZoneCreate;
