import sum from 'lodash/sum';
import PropTypes from 'prop-types';

import { useCallback, useEffect, useState } from 'react';
// form
import { useFormContext, useFieldArray, useWatch } from 'react-hook-form';
// @mui
import { Box, Stack, Button, Divider, Typography, InputAdornment, MenuItem, Tooltip } from '@mui/material';
// utils
import { deleteInvoiceItems } from 'redux/slices/InvoiceSlice';

import { useDispatch } from 'redux/store';
import { useSelector } from 'react-redux';
import { DialogAnimate } from 'components/animate';
import { useParams } from 'react-router-dom';
import { Icon } from '@iconify/react';

import { fCurrency } from '../../../../utils/formatNumber';
// components
import Iconify from '../../../../components/Iconify';
import { RHFSelect, RHFTextField } from '../../../../components/hook-form';
import InvoiceItemsPackSize from './InvoiceItemsPackSize';

const WEIGHT_TYPE = [
  { id: 1, name: 'KG' },
  { id: 4, name: 'PCS' },
];

const UOM_TYPE = ['BAG', 'BTL', 'CTN', 'EACH', 'JAR', 'PKT', 'ROLL', 'TIN', 'TUB', 'TRAY', 'OTHER'];

InvoiceNewEditDetails.propTypes = {
  isEdit: PropTypes.bool,
};
// ----------------------------------------------------------------------
export default function InvoiceNewEditDetails({ isEdit }) {
  const [returnQuality, setReturnQuality] = useState([]);
  const [reRender, setReRender] = useState(false);

  const [deleteItemInfo, setDeleteItemInfo] = useState({
    isModalOpen: false,
    itemId: null,
    index: null,
    item: null,
    returnQuantity: false,
  });
  const dispatch = useDispatch();

  const {
    control,
    setValue,
    register,
    unregister,
    formState: { errors },
  } = useFormContext();
  const { selectedDepartment } = useSelector((state) => state.userDepartment);

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'invoiceItems',
    keyName: 'fieldID',
  });

  const watchInvoiceItems = useWatch({
    control,
    name: `invoiceItems`,
  });

  const totalOnRow =
    watchInvoiceItems?.map((item) => item.suppliedQty * item.unitPrice + Number(item.gst) + Number(item.levy)) || [];

  const totalPrice = sum(totalOnRow).toFixed(2);

  const handleAdd = () => {
    append({
      externalUID: '',
      brand: '',
      productDesc: '',
      uom: '',
      weight: '',
      weightType: '',
      suppliedQty: 0,
      unitPrice: 0,
      gst: 0,
      amount: 0,
      levy: 0,
      packSize: 0,
    });
  };

  const handleRemove = (itemId, index, item = {}, returnQuantity = false) => {
    if (isEdit && itemId) {
      // dispatch(selectUpdateInvoiceItemsId({ id: { itemId, returnQuantity }, itemIndex: index }));
      setDeleteItemInfo({ isModalOpen: true, itemId, index, item, returnQuantity });
    } else {
      remove(index);
      setReRender(true);
    }
  };

  const ReturnQuantityManager = (item, index) => {
    if (isEdit && item?.returnQuantity && item?.returnQuantity?.id) {
      handleRemove(item?.returnQuantity?.id, index, item, true);
      return;
    }

    if (returnQuality.includes(index)) {
      setReturnQuality((prev) => prev.filter((obj) => obj !== index));
      unregister(`invoiceItems[${index}].returnQuantity`);
      return;
    }
    // adding return quality index
    setReturnQuality((prev) => [...prev, index]);
  };

  const deleteDialogBoxClose = () => {
    setDeleteItemInfo({ isModalOpen: false, itemId: null, index: null, returnQuantity: false });
    // dispatch(unSelectUpdateInvoiceItemsId());
  };
  const { id: invoiceId } = useParams();

  const deleteInvoiceItemsHandler = () => {
    const data = {
      departmentId: selectedDepartment?.id,
      id: deleteItemInfo?.itemId,
    };

    const isSuccess = (isSucceed) => {
      if (isSucceed) {
        // if item has returnQuantity reload the page
        if (deleteItemInfo?.returnQuantity) {
          // dispatch(getInvoiceDetails({ departmentId: selectedDepartment?.id, invoiceId }));
          update(deleteItemInfo?.index, { ...deleteItemInfo?.item, returnQuantity: null });
          deleteDialogBoxClose();
          return;
        }
        remove(deleteItemInfo?.index);
        setReRender(true);
        deleteDialogBoxClose();
      }
    };
    dispatch(deleteInvoiceItems({ data, isSuccess }));
  };

  const getTotal = useCallback(() => {
    const totalPrices = sum(
      watchInvoiceItems?.map(
        (item) => Number(item.suppliedQty) * Number(item.unitPrice) + Number(item.gst) + Number(item.levy)
      )
    ).toFixed(2);
    setValue(`totalInclGst`, totalPrices);
  }, [setValue, watchInvoiceItems]);

  const getTotalGst = useCallback(() => {
    const totalGSTs = watchInvoiceItems?.reduce((acc, current) => Number(current?.gst) + acc, 0).toFixed(2);
    setValue(`totalGst`, totalGSTs);
  }, [setValue, watchInvoiceItems]);

  const validateFloat = (value) => {
    const decimalPattern = /^\d+(\.\d{1,2})?$/;

    if (decimalPattern.test(Number(value))) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!reRender) return;
    getTotal();
    getTotalGst();
    // setReRender(false);
  }, [reRender, getTotal, getTotalGst]);

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ color: 'text.disabled', mb: 3 }}>
        Details:
      </Typography>

      <Stack spacing={3}>
        {fields.map((item, index) => (
          <Stack key={item?.fieldID} alignItems="flex-end" spacing={1.5}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
              <RHFTextField
                size="small"
                name={`invoiceItems[${index}].externalUID`}
                label="External UID"
                InputLabelProps={{ shrink: true }}
                sx={{ maxWidth: { md: 90 } }}
              />

              <input type="hidden" {...register(`invoiceItems[${index}].status`)} value="APPROVED" />

              <RHFTextField
                size="small"
                name={`invoiceItems[${index}].brand`}
                label="Brand"
                InputLabelProps={{ shrink: true }}
                sx={{ maxWidth: { md: 80 } }}
              />

              <RHFTextField
                size="small"
                name={`invoiceItems[${index}].productDesc`}
                label="Description"
                InputLabelProps={{ shrink: true }}
                sx={{ minWidth: { md: 250 } }}
              />

              {/* For UOM */}
              <RHFSelect
                name={`invoiceItems[${index}].uom`}
                size="small"
                label="UOM"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                sx={{ maxWidth: { md: 80 } }}
              >
                {UOM_TYPE.map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                    // onClick={() => handleSelectService(index, option.name)}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option}
                  </MenuItem>
                ))}
              </RHFSelect>

              {/* for weight type */}

              <RHFSelect
                name={`invoiceItems[${index}].weightType`}
                size="small"
                label="W Type"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                sx={{ maxWidth: { md: 70 } }}
              >
                {WEIGHT_TYPE.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.name}
                    // onClick={() => handleSelectService(index, option.name)}
                    sx={{
                      mx: 1,
                      my: 0.5,
                      borderRadius: 0.75,
                      typography: 'body2',
                      textTransform: 'capitalize',
                    }}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </RHFSelect>

              <RHFTextField
                size="small"
                name={`invoiceItems[${index}].weight`}
                onChange={(event) => {
                  if (Number.isNaN(Number(event.target.value))) return;
                  const validated = validateFloat(event.target.value);
                  if (validated) {
                    setValue(`invoiceItems[${index}].weight`, event.target.value);
                  }
                }}
                label="Unit Wt"
                InputLabelProps={{ shrink: true }}
                // type="number"
                sx={{ maxWidth: { md: 70 } }}
              />

              <RHFTextField
                size="small"
                name={`invoiceItems[${index}].suppliedQty`}
                label="Qty"
                placeholder="0"
                onChange={(event) => {
                  if (Number.isNaN(Number(event.target.value))) return;
                  setValue(`invoiceItems[${index}].suppliedQty`, event.target.value);
                  getTotal();
                }}
                InputLabelProps={{ shrink: true }}
                sx={{ maxWidth: { md: 60 } }}
              />

              <RHFTextField
                size="small"
                name={`invoiceItems[${index}].unitPrice`}
                label="Price $"
                placeholder="0"
                onChange={(event) => {
                  if (Number.isNaN(Number(event.target.value))) return;
                  const validated = validateFloat(event.target.value);
                  if (validated) {
                    setValue(`invoiceItems[${index}].unitPrice`, event.target.value);
                    getTotal();
                  }
                }}
                InputLabelProps={{ shrink: true }}
                sx={{ maxWidth: { md: 80 } }}
              />

              <RHFTextField
                size="small"
                name={`invoiceItems[${index}].gst`}
                InputLabelProps={{ shrink: true }}
                label="GST $"
                placeholder="0"
                onChange={(event) => {
                  if (Number.isNaN(Number(event.target.value))) return;
                  const validated = validateFloat(event.target.value);
                  if (validated) {
                    setValue(`invoiceItems[${index}].gst`, event.target.value);
                    getTotal();
                    getTotalGst();
                  }
                }}
                sx={{ maxWidth: { md: 80 } }}
              />

              <RHFTextField
                size="small"
                name={`invoiceItems[${index}].levy`}
                InputLabelProps={{ shrink: true }}
                label="Levy $"
                placeholder="0"
                onChange={(event) => {
                  if (Number.isNaN(Number(event.target.value))) return;
                  // const unitPrice = Number(event.target.value).toFixed(2);
                  const validated = validateFloat(event.target.value);
                  if (validated) {
                    setValue(`invoiceItems[${index}].levy`, event.target.value);
                    getTotal();
                  }
                }}
                sx={{ maxWidth: { md: 80 } }}
              />

              <RHFTextField
                disabled
                size="small"
                name={`invoiceItems[${index}].amount`}
                InputLabelProps={{ shrink: true }}
                label="Total $"
                placeholder="0"
                value={Number((totalOnRow[index] || 0).toFixed(2))}
                sx={{ maxWidth: { md: 90 } }}
              />
            </Stack>

            <Box>
              <RHFTextField
                size="small"
                name={`invoiceItems[${index}].packSize`}
                // onChange={(event) => handleChangeReturnedQuantity(event, index)}
                label="Pack Size"
                InputLabelProps={{ shrink: true }}
                sx={{ maxWidth: { md: 120 }, marginRight: 1 }}
              />
              {/* <InvoiceItemsPackSize index={index} /> */}
              {(returnQuality.includes(index) || item?.returnQuantity) && (
                <>
                  <RHFTextField
                    size="small"
                    disabled={!!(`invoiceItems[${index}].suppliedQty` > 0)}
                    name={`invoiceItems[${index}].returnQuantity.suppliedQty`}
                    // onChange={(event) => handleChangeReturnedQuantity(event, index)}
                    label="Return Quantity"
                    InputLabelProps={{ shrink: true }}
                    sx={{ maxWidth: { md: 120 } }}
                  />
                </>
              )}

              <Button
                size="small"
                variant="contained"
                color={returnQuality.includes(index) || item?.returnQuantity ? 'error' : 'secondary'}
                // startIcon={<Iconify icon="eva:delete-2-outline" />}
                onClick={() => ReturnQuantityManager(item, index)}
                sx={{ marginRight: '1rem', marginLeft: '1rem' }}
              >
                {returnQuality.includes(index) || item.returnQuantity ? 'Remove Return' : 'Return'}
              </Button>

              <Button
                size="small"
                color="error"
                startIcon={<Iconify icon="eva:trash-2-outline" />}
                onClick={() => handleRemove(item.id, index)}
              >
                Remove
              </Button>
            </Box>
          </Stack>
        ))}
      </Stack>

      <Divider sx={{ my: 3, borderStyle: 'dashed' }} />

      <Stack
        spacing={2}
        direction={{ xs: 'column-reverse', md: 'row' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
      >
        <Button size="small" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleAdd} sx={{ flexShrink: 0 }}>
          Add new detail
        </Button>
      </Stack>

      <Stack direction="row" justifyContent="flex-end">
        <Typography>Subtotal :</Typography>
        <Typography sx={{ textAlign: 'right', width: 120 }}>{fCurrency(totalPrice)}</Typography>
      </Stack>

      <Stack spacing={2} sx={{ mt: 3 }}>
        <Stack direction="row" justifyContent="flex-end" gap="1rem">
          <Typography variant="h6">Total GST :</Typography>
          <RHFTextField
            size="small"
            name={`totalGst`}
            placeholder="0"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            sx={{ maxWidth: { md: 140 } }}
          />
        </Stack>

        <Stack direction="row" justifyContent="flex-end" gap="1rem">
          <Typography variant="h6">Total price :</Typography>
          <RHFTextField
            size="small"
            name={`totalInclGst`}
            placeholder="0"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            sx={{ maxWidth: { md: 140 } }}
          />
        </Stack>
      </Stack>

      {deleteItemInfo?.isModalOpen && (
        <DialogAnimate open={deleteItemInfo?.isModalOpen} onClose={deleteDialogBoxClose}>
          <Box className="deleteObjectDialogBox">
            <h2>Are you sure you want to Delete ?</h2>
            <Box className="deleteObjectDialogBoxBody">
              <p style={{ paddingBottom: '2rem' }}>All Unsaved Changes will get lost</p>
              <Button color="error" onClick={() => deleteInvoiceItemsHandler()}>
                Yes
              </Button>
              <Button color="secondary" variant="contained" onClick={deleteDialogBoxClose}>
                No
              </Button>
            </Box>
          </Box>
        </DialogAnimate>
      )}
    </Box>
  );
}
