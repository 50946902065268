// hooks
import { useSelector } from 'react-redux';
import useAuth from '../hooks/useAuth';
// utils
import createAvatar from '../utils/createAvatar';
//
import Avatar from './Avatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ img }) {
  const { user } = useSelector((state) => state.auth);
  return (
    <Avatar
      src={user?.userDT0?.imgURL}
      // alt={user?.displayName}
      // color={user?.photoURL ? 'default' : createAvatar(user?.displayName).color}
      // {...other}
    >
      {/* {createAvatar(user?.displayName).name} */}
    </Avatar>
  );
}
