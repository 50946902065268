import { Box, Card, Tab, Tabs } from '@mui/material';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { changeFoodMenuCurrentTab } from 'redux/slices/FoodMenuSlice';
import FoodMenuIndex from './FoodMenu';
// import FoodMenuSales from '../sales/MenuSales/FoodMenuSales';
import FoodRecipeIndex from './FoodRecipe';
import FoodRecipeSummary from './FoodRecipeSummary';
import FoodMenuReportIndex from './FoodMenuReport';

function FoodMenuAndRecipeBody() {
  const { currentTab } = useSelector((state) => state.foodMenus);
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    dispatch(changeFoodMenuCurrentTab(newValue));
  };
  return (
    <Card>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingLeft: '0.6rem' }}>
        <Tabs value={currentTab} onChange={handleChange} aria-label="Stock and Order Tabs">
          <Tab label="Menu" id="0" />
          <Tab label="Recipe" id="1" />
          <Tab label="Recipe Summary" id="2" />
          <Tab label="Menu Report" id="3" />
        </Tabs>
      </Box>

      {currentTab === 0 && <FoodMenuIndex />}

      {currentTab === 1 && <FoodRecipeIndex />}k
      {currentTab === 2 && <FoodRecipeSummary />}
      {currentTab === 3 && <FoodMenuReportIndex />}
    </Card>
  );
}

export default FoodMenuAndRecipeBody;
