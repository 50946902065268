import React, { useEffect } from 'react';
import RoasterFilter from 'sections/@dashboard/roaster/RoasterFilter';
import { useDispatch, useSelector } from 'redux/store';
import { getBreaks } from 'redux/slices/storeSlice';
import { getUserRole } from 'redux/slices/UserRolesSlice';

import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { Card } from '@mui/material';

import RoasterCardBodyTable from './RoasterCardBodyTable';

function RoasterCardBody() {
  const { loading: userRoleLoading } = useSelector((state) => state.userRole);
  const { loading: breakLoading } = useSelector((state) => state.store);
  const { selectedDepartment } = useSelector((state) => state.userDepartment);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!Object.keys(selectedDepartment).length) return;
    dispatch(getUserRole());
    dispatch(getBreaks());
  }, [dispatch, selectedDepartment]);
  console.log('running.....');
  return (
    <Card>
      {userRoleLoading && breakLoading ? (
        <CustomLoadingScreen height={'50vh'} />
      ) : (
        <>
          <RoasterFilter />
          <RoasterCardBodyTable />
        </>
      )}
    </Card>
  );
}

export default RoasterCardBody;
