import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

export default function SalesAdditionalInfoSection() {
  const { paymentType } = useSelector((state) => state.sales);

  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: 'paymentTypeSales',
    keyName: 'fieldID',
  });

  const findLabel = (id) => paymentType?.find((item) => item?.id === id)?.title;
  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" sx={{ color: 'text.disabled', mb: 1 }}>
        Payment Types:
      </Typography>
      <Grid container spacing={2}>
        {fields?.map((item, index) => (
          <Grid item xs={12} sm={4} md={2} xl={1.5} key={index}>
            <RHFTextField
              name={`paymentTypeSales[${index}].amount`}
              label={item?.title ? item?.title : findLabel(item?.paymentTypeId)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />
          </Grid>
        ))}
        <Grid item xs={12} sm={4} md={2} xl={1.5}>
          <RHFTextField
            name="pcEft"
            label="PC EFT"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Grid>

        {/* <Grid item xs={12} sm={4} md={2} xl={1.5}>
          <RHFTextField
            name="storeOnline"
            label="Store Online"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2} xl={1.5}>
          <RHFTextField
            name="voucher"
            label="Voucher"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2} xl={1.5}>
          <RHFTextField
            name="other"
            label="Other"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2} xl={1.5}>
          <RHFTextField
            color="warning"
            name="totalUnpaid"
            label="Unpaid"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2} xl={1.5}>
          <RHFTextField
            name="totalOnlineOrder"
            label="Total Online Order"
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </Grid> */}
      </Grid>
    </Box>
  );
}
