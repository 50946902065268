import { Box, Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PATH_DASHBOARD } from 'routes/paths';
import MenuSizeCreate from './sizeGroup/MenuSizeCreate';
import MenuOptionCreate from './optionGroup/MenuOptionCreate';
import MenuSizeOptionBinding from './sizeGroupOptionGroup/MenuSizeOptionBinding';

function MenuEngineeringHeader() {
  const { currentTab } = useSelector((state) => state.menuERSizeAndOption);
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const setOpenHandler = () => {
    setOpen(true);
  };
  return (
    <>
      <HeaderBreadcrumbs
        heading="Size and Option Group"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          { name: 'size and option', href: PATH_DASHBOARD.food.categories },
          //  { name: 'List' },
        ]}
        action={
          <Box className="invoiceListHeadingButtons">
            {currentTab === 0 && (
              <Button variant="contained" startIcon={<Iconify icon={'eva:plus-fill'} />} onClick={setOpenHandler}>
                Size Group
              </Button>
            )}
            {currentTab === 1 && (
              <Button variant="contained" startIcon={<Iconify icon={'eva:plus-fill'} />} onClick={setOpenHandler}>
                Option Group
              </Button>
            )}
            {currentTab === 2 && (
              <Button
                variant="contained"
                color="warning"
                startIcon={<Iconify icon={'gg:reorder'} />}
                type="submit"
                form="sizeOptionReOrder"
                id="reOrderSizeOption"
              >
                Reorder
              </Button>
            )}
            {currentTab === 2 && (
              <Button variant="contained" startIcon={<Iconify icon={'eva:plus-fill'} />} onClick={setOpenHandler}>
                Size Option Binding
              </Button>
            )}

            {open && currentTab === 0 && <MenuSizeCreate open={open} handleClose={handleClose} />}
            {open && currentTab === 1 && <MenuOptionCreate open={open} handleClose={handleClose} />}
            {open && currentTab === 2 && <MenuSizeOptionBinding open={open} handleClose={handleClose} />}
          </Box>
        }
      />
    </>
  );
}

export default MenuEngineeringHeader;
