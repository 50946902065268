import { Box } from '@mui/material';
import React from 'react';
import FoodMenuReportFilter from 'sections/@dashboard/foodMenuAndRecipe/FoodMenuReport/FoodMenuReportFilter';
import FoodMenuReportBody from './FoodMenuReportBody';

function FoodMenuReportIndex() {
  return (
    <Box>
      <FoodMenuReportFilter />
      <FoodMenuReportBody />
    </Box>
  );
}

export default FoodMenuReportIndex;
