import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import React from 'react';
import StreetAddForm from './StreetAddForm';

function EditStreet({ open, handleClose, updateData, suburbId }) {
  return (
    <CustomOutletModal open={open} setOpen={handleClose} zIndex="1600" title="Update Street" width="30rem">
      <StreetAddForm handleClose={handleClose} suburbId={suburbId} updateData={updateData} isEdit />
    </CustomOutletModal>
  );
}

export default EditStreet;
