import { Button, Grid, Stack } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Iconify from 'components/Iconify';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  createFoodMenuProcess,
  deleteSingleMenuProcessField,
  getFoodMenuProcess,
} from 'redux/slices/menuEngineeringSlice';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import FoodMenuProcessIndividualField from './FoodMenuProcessIndividualField';

const schema = Yup.object().shape({
  data: Yup.array().of(
    Yup.object().shape({
      note: Yup.string().required(''),
    })
  ),
});

function FoodMenuProcessIndex({ menu }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, id: null });
  const { foodMenuProcess, foodMenuProcessLoading, postLoading } = useSelector((state) => state.menuEngineering);

  const defaultValues = { data: [] };

  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onChange' });
  const { control, reset, handleSubmit } = methods;

  useEffect(() => {
    if (!foodMenuProcess) return;
    reset({ data: [...foodMenuProcess] });
  }, [foodMenuProcess, reset]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'data',
    keyName: 'menuFieldId',
  });

  // eslint-disable-next-line consistent-return
  const MenuProcessSubmitHandler = (data) => {
    const fileArray = [];
    const noteArray = [];
    data?.data?.forEach((el) => {
      if (!el?.id) {
        fileArray.push(el?.imageUrl);
        noteArray.push(el?.note);
      }
    });

    if (fileArray.length && noteArray.length) {
      if (fileArray.length !== noteArray.length) return toast.error('Please add image and note for each field');
      const formData = new FormData();
      if (Object.keys(fileArray).length) {
        fileArray.forEach((file) => {
          formData.append('file', file);
        });
        formData.append(
          'menu',
          new Blob([JSON.stringify({ notes: noteArray, departmentId, menuId: menu?.id })], { type: 'application/json' })
        );
        const isSuccessful = (isSuccess) => {
          if (isSuccess) {
            dispatch(getFoodMenuProcess({ departmentId, menuId: menu?.id }));
          }
        };
        dispatch(createFoodMenuProcess({ formData, isSuccess: isSuccessful }));
      }
    }
  };
  const AddNewImageField = () => {
    append({ note: '' });
  };

  const RemoveImageField = (el, index) => {
    if (!el?.id) {
      remove(index);
    } else {
      setOpenDeleteModal({ status: true, id: el?.id });
    }
  };

  const deleteSingleMenuProcess = () => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        dispatch(getFoodMenuProcess({ departmentId, menuId: menu?.id }));
        setOpenDeleteModal({ status: false, id: null });
      }
    };
    dispatch(deleteSingleMenuProcessField({ departmentId, id: openDeleteModal?.id, isSuccess: isSuccessful }));
  };

  useEffect(() => {
    dispatch(getFoodMenuProcess({ menuId: menu?.id, departmentId }));
  }, [departmentId, dispatch, menu]);

  //   console.log(methods.watch());

  const findIsNewData = (data) => {
    let isNewData = false;
    data?.forEach((el) => {
      if (!el?.id) {
        isNewData = true;
      }
    });
    return isNewData;
  };

  return (
    <>
      <Stack my="1rem">
        {foodMenuProcessLoading ? (
          <CustomLoadingScreen height="20vh" />
        ) : (
          <FormProvider {...methods}>
            {/* <Stack spacing={2} direction={'column'}>
              {fields.map((el, index) => (
                <FoodMenuProcessIndividualField
                  key={index}
                  el={el}
                  index={index}
                  RemoveImageField={RemoveImageField}
                  menu={menu}
                />
              ))}
            </Stack> */}
            <Grid container spacing={1}>
              {fields.map((el, index) => (
                <FoodMenuProcessIndividualField
                  key={index}
                  el={el}
                  index={index}
                  RemoveImageField={RemoveImageField}
                  menu={menu}
                />
              ))}
            </Grid>
            <Stack alignItems="flex-end" marginTop={3}>
              <Button
                variant="contained"
                color="warning"
                startIcon={<Iconify icon="material-symbols:add" />}
                onClick={AddNewImageField}
              >
                New
              </Button>
            </Stack>
            <Stack alignItems="flex-start" marginTop={1}>
              {fields?.length !== 0 && (
                <Button
                  variant="contained"
                  type="submit"
                  color="secondary"
                  disabled={!findIsNewData(fields)}
                  onClick={handleSubmit(MenuProcessSubmitHandler)}
                >
                  {postLoading ? <CustomLoadingScreen circularWidth={'20px'} color="secondary" /> : 'Submit'}
                </Button>
              )}
            </Stack>
          </FormProvider>
        )}
      </Stack>

      {openDeleteModal?.status && (
        <CustomDeleteDialog
          open={openDeleteModal?.status}
          handleClose={() => setOpenDeleteModal({ status: false })}
          onAccept={deleteSingleMenuProcess}
        />
      )}
    </>
  );
}

FoodMenuProcessIndex.propTypes = {
  menu: PropTypes.object,
};
export default FoodMenuProcessIndex;
