import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, Divider, Stack } from '@mui/material';
import { Icon } from '@iconify/react';
import Iconify from 'components/Iconify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
};

// for closing the modal
// create a function named handleClose with setOpen(false) and pass it to onClose
//

// eslint-disable-next-line react/prop-types
export default function CustomOutletModal({
  open = false,
  onClose,
  title = '',
  subtitle = '',
  children,
  width = '40rem',
  height = 'auto',
  zIndex = '1100',
  headerIcon = '',
  iconColor = '',
  iconWidth = '',
  iconHeight = '',
  useDivider = false,
  useCloseIcon = false,
  ...other
}) {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ zIndex: `${zIndex}`, background: 'white' }}
      className="customOutletModalClass"
      {...other}
    >
      <Box sx={style} style={{ width: `${width}`, height: `${height}`, overflowY: 'auto' }}>
        <Stack alignItems="center" direction="row" justifyContent={'space-between'}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ display: 'flex', flexDirection: 'row' }}
          >
            <Box style={{ marginTop: '2px' }}>
              <Iconify
                icon={headerIcon}
                color={iconColor}
                width={iconWidth}
                height={iconHeight}
                style={{ marginRight: '5px' }}
              />
            </Box>
            {title}
          </Typography>
          {useCloseIcon && (
            <Box>
              <Icon
                icon="material-symbols:close"
                width="30"
                style={{ color: 'red', cursor: 'pointer' }}
                onClick={onClose}
              />
            </Box>
          )}
        </Stack>
        {useDivider && <Divider />}

        <Typography id="modal-modal-title" variant="h6" component="h2">
          {subtitle}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 1 }}>
          {children}
        </Typography>
      </Box>
    </Modal>
  );
}
