export const getDrawerName = (cashDrawer, storeCashDrawer) => {
  let drawerName = 'N/A';
  storeCashDrawer.forEach((drawer) => {
    if (cashDrawer.storeCashDrawerId === drawer.id) {
      drawerName = drawer.title;
    }
  });
  return drawerName;
};

export const getPlatformName = (platform, onlinePlatforms) => {
  let platformName = 'N/A';
  onlinePlatforms.forEach((onlinePlatform) => {
    if (platform.onlinePlatformId === onlinePlatform.id) {
      // eslint-disable-next-line prefer-destructuring
      platformName = onlinePlatform.platformName;
    }
  });
  return platformName;
};

export const findDeliveryUserName = (deliveryEntry, users) => {
  let userName = 'N/A';
  users.forEach((user) => {
    if (deliveryEntry.userId === user.id) {
      userName = `${user.firstName} ${user.lastName}`;
    }
  });
  return userName;
};

export const findFloatAmount = (cashDrawer, storeCashDrawer) => {
  if (!storeCashDrawer) return;
  let floatAmount = 0;
  storeCashDrawer.forEach((drawer) => {
    if (cashDrawer.storeCashDrawerId === drawer.id && cashDrawer?.tillAmount > 0) {
      floatAmount = drawer?.floatAmt;
    }
  });
  // eslint-disable-next-line consistent-return
  return floatAmount;
};
