import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, Stack, Tooltip } from '@mui/material';
import Iconify from 'components/Iconify';

QuickSummaryDifferenceIndividualCard.propTypes = {
  data: PropTypes.object,
  userInfo: PropTypes.object,
};

function QuickSummaryDifferenceIndividualCard({ data }) {
  // const cardColor = userInfo?.roles?.find((obj) => obj.id === userInfo?.roleIds[0])?.colorCode;

  return (
    <Card
      style={{
        borderRadius: '4px',
        // backgroundColor: `${cardColor}` }}
        backgroundColor: `black`,
      }}
    >
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <Box style={{ width: '50%', color: 'white', borderRight: '1px solid grey' }}>
          <Tooltip title="Time Info">
            <Stack direction={'column'} className="quickSummaryTableCell">
              <Box style={{ display: 'inline-flex', marginTop: '2px' }}>
                <p style={{ display: 'inline-flex', fontWeight: '600', marginLeft: '1px', color: 'white' }}>
                  F:{' '}
                  {data?.totalRosterTime
                    ? `${Math.floor(Math.abs(data?.totalRosterTime) / 60)}H ${
                        Math.ceil(Math.abs(data?.totalRosterTime)) % 60
                      }M`
                    : `0H 0M`}
                </p>
              </Box>
              <Box style={{ display: 'inline-flex', marginTop: '2px' }}>
                <Box>
                  A:{' '}
                  <p style={{ display: 'inline-flex', fontWeight: '600', marginLeft: '1px', color: 'white' }}>
                    {data?.totalClockedTime
                      ? `${Math.floor(Math.abs(data?.totalClockedTime) / 60)}H ${
                          Math.ceil(Math.abs(data?.totalClockedTime)) % 60
                        }M`
                      : `0H 0M`}
                  </p>
                </Box>
              </Box>
              <Box style={{ display: 'inline-flex', marginTop: '2px' }}>
                <Box>
                  D:{' '}
                  <p
                    style={{
                      display: 'inline-flex',
                      fontWeight: '600',
                      marginLeft: '1px',
                      color: `${data?.timeDifference > 0 ? 'white' : 'rgb(240, 131, 131)'}`,
                    }}
                  >
                    {data?.timeDifference
                      ? `${Math.floor(Math.abs(data?.timeDifference) / 60)}H ${
                          Math.ceil(Math.abs(data?.timeDifference)) % 60
                        }M`
                      : `0H 0M`}
                  </p>
                </Box>
              </Box>
            </Stack>
          </Tooltip>
        </Box>
        <Box style={{ width: '50%' }}>
          <Tooltip title="Payment Info">
            <Stack direction={'column'} className="quickSummaryTableCell">
              <Box style={{ display: 'inline-flex', marginTop: '2px' }}>
                <p style={{ display: 'inline-flex', fontWeight: '600', marginLeft: '1px', color: 'white' }}>
                  F: ${data?.predictedPay ?? 0}
                </p>
              </Box>
              <Box style={{ display: 'inline-flex', marginTop: '2px' }}>
                <Box>
                  <p style={{ display: 'inline-flex', fontWeight: '600', marginLeft: '1px', color: 'white' }}>
                    A: $ {data?.actualPay ?? 0}
                  </p>
                </Box>
              </Box>
              <Box style={{ display: 'inline-flex', marginTop: '2px' }}>
                <Box>
                  <p
                    style={{
                      display: 'inline-flex',
                      fontWeight: '600',
                      marginLeft: '1px',
                      color: `${data?.payDifference > 0 ? 'white' : 'rgb(240, 131, 131)'}`,
                    }}
                  >
                    D: $ {Math.abs(data?.payDifference) ?? 0}
                  </p>
                </Box>
              </Box>
            </Stack>
          </Tooltip>
        </Box>
      </Box>
    </Card>
  );
}

export default QuickSummaryDifferenceIndividualCard;
