import { TableBody, TableRow } from '@mui/material';
import { TableNoData } from 'components/table';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import QuickSummaryClockInTableCellWithAvatar from './QuickSummaryClockInTableCellWithAvatar';
import QuickSummaryClockInIndividualTableRow from './QuickSummaryClockInIndividualTableRow';

function QuickSummaryClockInTableBody() {
  const { control } = useFormContext();
  const { clockInDetailsLoading } = useSelector((state) => state.quickSummary);

  const { fields } = useFieldArray({
    name: `users`,
    keyName: 'usersID',
    control,
  });

  return (
    <>
      {clockInDetailsLoading ? (
        ''
      ) : (
        <>
          {fields?.length > 0 ? (
            <>
              {fields?.map((user, mainIndex) => (
                <TableRow key={mainIndex}>
                  <QuickSummaryClockInTableCellWithAvatar user={user} mainIndex={mainIndex} />
                  <QuickSummaryClockInIndividualTableRow user={user} mainIndex={mainIndex} />
                </TableRow>
              ))}
            </>
          ) : (
            <TableNoData isNotFound={fields?.length <= 0} />
          )}
          {/* </TableBody> */}
        </>
      )}
    </>
  );
}

export default QuickSummaryClockInTableBody;
