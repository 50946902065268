import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Stack } from '@mui/material';
import { getFoodMenuSizePrice, updateFoodMenuSizePrice } from 'redux/slices/menuEngineeringSlice';
import { yupResolver } from '@hookform/resolvers/yup';
import FoodMenuSizeGeneralPriceSection from './FoodMenuSizeGeneralPriceSection';

const schema = Yup.object().shape({
  menuSizes: Yup.array().of(
    Yup.object().shape({
      additionalPrice: Yup.number()
        .transform((curr, orig) => (orig === '' ? 0 : curr))
        .typeError(''),
    })
  ),
});

function FoodMenuSizePriceGeneralSection({ menu }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const { postLoading, foodMenuSizePrices } = useSelector((state) => state.menuEngineering);

  const defaultValues = {};

  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onChange' });

  const { handleSubmit, reset } = methods;

  const GeneralPriceSubmitHandler = (data) => {
    const finalData = { ...data, departmentId, id: menu?.id };
    dispatch(updateFoodMenuSizePrice({ finalData }));
  };
  useEffect(() => {
    if (!menu || !departmentId) return;
    dispatch(getFoodMenuSizePrice({ menuId: menu.id, departmentId }));
  }, [departmentId, dispatch, menu]);

  useEffect(() => {
    if (!foodMenuSizePrices?.length) return;
    reset({
      menuSizes: foodMenuSizePrices?.map((size) => ({
        sizeId: size?.sizeId,
        name: size?.sizeName,
        isPublished: size?.isPublished,
        additionalPrice: size?.additionalPrice ?? 0,
        additionalPriceDelivery: size?.additionalPriceDelivery ?? 0,
        additionalPricePickup: size?.additionalPricePickup ?? 0,
        additionalPriceTable: size?.additionalPriceTable ?? 0,
      })),
    });
  }, [foodMenuSizePrices, menu, reset]);

  useEffect(() => {
    if (foodMenuSizePrices?.length) return;
    reset({
      menuSizes: menu?.sizes?.map((size) => ({
        sizeId: size?.id,
        name: size?.name,
        isPublished: size?.isPublished,
        additionalPrice: size?.additionalPrice ?? 0,
        additionalPriceDelivery: size?.additionalPriceDelivery ?? 0,
        additionalPricePickup: size?.additionalPricePickup ?? 0,
        additionalPriceTable: size?.additionalPriceTable ?? 0,
      })),
    });
  }, [foodMenuSizePrices, menu, reset]);

  const isFormDirty = methods.formState.isDirty;
  return (
    <>
      <FormProvider {...methods}>
        <FoodMenuSizeGeneralPriceSection />
        <Stack alignItems="center" gap="1rem" justifyContent={'flex-end'} direction={'row'} mt={1}>
          <Button
            variant="contained"
            onClick={handleSubmit(GeneralPriceSubmitHandler)}
            disabled={postLoading || !isFormDirty}
          >
            Save
          </Button>
        </Stack>
      </FormProvider>
    </>
  );
}

export default FoodMenuSizePriceGeneralSection;
