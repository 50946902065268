import moment from 'moment';

// time
export function convertDateintoTimeString(date, formats = 'HH:mm:ss') {
  return moment(date).format(formats);
}

export function convertTimeStringIntoDate(timestring) {
  return moment(timestring, 'hh:mm:ss').toDate();
}

// Change date from one format to another...
export function convertStringTimetoTime(time, timeFormat = 'HH:mm:ss', toFormatTime = 'HH:mm:ss') {
  return moment(time, timeFormat).format(toFormatTime);
}

// date

// date params should be in date format...
export function convertDatetoString(date, formatDate = 'YYYY-MM-DD') {
  return moment(date).format(formatDate);
}

// Change date from one format to another...
export function convertStringDatetoDate(date, dateFormat = 'YYYY-MM-DD', toFormatDate = 'YYYY-MM-DD') {
  return moment(date, dateFormat).format(toFormatDate);
}
