import {
  Box,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { RHFTextField } from 'components/hook-form';
import { Controller, useFormContext } from 'react-hook-form';

const typeOption = [
  {
    value: 'AVERAGE',
    label: 'Average',
  },
  {
    value: 'LOWEST',
    label: 'Lowest',
  },
  {
    value: 'HIGHEST',
    label: 'Highest',
  },
];

function SingleSizeGroupTable({ sizeGroup, index }) {
  const { control } = useFormContext();
  const halfHalfTypeChange = (e) => {};
  return (
    <TableContainer sx={{ minWidth: 800 }}>
      <Table stickyHeader size="small">
        <TableHead>
          <TableRow>
            <TableCell>Size Group / Sizes</TableCell>
            {sizeGroup?.sizes?.map((size, sizeIndex) => (
              <TableCell align="center" key={sizeIndex}>
                {size?.name}
              </TableCell>
            ))}
            <TableCell align="center">Price Choosen As</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow hover>
            <TableCell>{sizeGroup?.name}</TableCell>
            {sizeGroup?.sizes?.map((size, sizeIndex) => (
              <TableCell key={sizeIndex} align="center">
                <RHFTextField
                  name={`data.${index}.sizes.${sizeIndex}.additionalPrice`}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                  label="Ad. Price"
                  size="small"
                  sx={{ width: '7rem' }}
                />
              </TableCell>
            ))}
            <TableCell align="center">
              <Stack alignItems={'center'}>
                <Controller
                  name={`data.${index}.halfHalfType`}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <RadioGroup {...field} row>
                      {typeOption.map((option) => (
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio />}
                          label={option.label}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </Stack>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default SingleSizeGroupTable;
