import { TableCell } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import QuickSummaryClockInIndividualCard from './QuickSummaryClockInIndividualCard';

QuickSummaryClockInIndividualTableCell.propTypes = {
  clockReportKey: PropTypes.string,
  mainIndex: PropTypes.number,
  userInfo: PropTypes.object,
};

function QuickSummaryClockInIndividualTableCell({ clockReportKey, mainIndex, userInfo }) {
  const { control } = useFormContext();
  const watch = useWatch({ control, name: `users.${mainIndex}.clockReportsInDates.${clockReportKey}` });

  return (
    <>
      {!watch?.length ? (
        <TableCell />
      ) : (
        <>
          <TableCell style={{ padding: '5px' }}>
            {watch?.map((clockReport, index) => (
              <QuickSummaryClockInIndividualCard
                key={index}
                data={clockReport}
                mainIndex={mainIndex}
                clockReportKey={clockReportKey}
                userInfo={userInfo}
              />
            ))}
          </TableCell>
        </>
      )}
    </>
  );
}

export default QuickSummaryClockInIndividualTableCell;
