import { Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Scrollbar from 'components/Scrollbar';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateMenuTopinsQty } from 'redux/slices/menuEngineeringSlice';
import FoodMenuToppinsWithQtyRow from './FoodMenuToppinsWithQtyRow';
import FoodMenuTopinsWithQtySummaryRow from './FoodMenuTopinsWithQtySummaryRow';

function FoodMenuTopinsWithQtyForm({ menu }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const { menuTopinsWithQty, postLoading } = useSelector((state) => state.menuEngineering);

  const schema = Yup.object().shape({
    menuOptions: Yup.array().of(
      Yup.object().shape({
        menuSizes: Yup.array().of(
          Yup.object().shape({
            qtyUsed: Yup.number()
              .typeError('')
              .transform((curr, orig) => (orig === '' ? 0 : curr)),
          })
        ),
      })
    ),
  });
  const defaultValues = {
    menuOptions: [],
  };

  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onChange' });

  const { reset, handleSubmit, control, formState } = methods;

  const MenuTopinsQtySubmitHandler = (data) => {
    const finalData = { ...data, departmentId };
    dispatch(updateMenuTopinsQty({ finalData, menuId: menu?.id }));
  };

  const { fields } = useFieldArray({
    control,
    name: 'menuOptions',
  });

  useEffect(() => {
    if (menuTopinsWithQty?.menuOptions?.length <= 0) return;
    reset({ menuOptions: menuTopinsWithQty?.menuOptions });
  }, [menuTopinsWithQty, reset]);

  const isChanged = formState.isDirty;

  return (
    <>
      <FormProvider {...methods}>
        <Stack spacing={2}>
          <Stack spacing={2}>
            <Scrollbar>
              <TableContainer>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow hover>
                      <TableCell>Toppins / Ingredients</TableCell>
                      {menuTopinsWithQty?.menuSizes?.map((size, index) => (
                        <TableCell key={index} align="center">
                          <div>
                            {size.sizeName} <br />
                            (qty / cost)
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fields?.map((option, optionIndex) => (
                      <FoodMenuToppinsWithQtyRow key={optionIndex} option={option} optionIndex={optionIndex} />
                    ))}
                    {fields?.length ? (
                      <TableRow hover>
                        <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
                        {menuTopinsWithQty?.menuSizes?.map((size, index) => (
                          <FoodMenuTopinsWithQtySummaryRow key={index} menuSize={size} index={index} />
                        ))}
                      </TableRow>
                    ) : (
                      ''
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Stack>
          <Stack alignItems={'flex-end'}>
            <Button
              variant="contained"
              color="primary"
              disabled={!isChanged}
              onClick={handleSubmit(MenuTopinsQtySubmitHandler)}
            >
              {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : 'Save'}
            </Button>
          </Stack>
        </Stack>
      </FormProvider>
    </>
  );
}

FoodMenuTopinsWithQtyForm.propTypes = {
  menu: PropTypes.object,
};

export default FoodMenuTopinsWithQtyForm;
