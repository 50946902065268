import Page from 'components/Page';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getStore } from 'redux/slices/storeSlice';
import { getAllUserss } from 'redux/slices/userSlice';
import CheckListBody from 'sections/@dashboard/checklist/CheckListBody';
import CheckListHeader from 'sections/@dashboard/checklist/CheckListHeader';

function ChecklistIndex() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getStore());
    dispatch(getAllUserss({}));
  }, [dispatch]);
  return (
    <Page title="Checklist">
      <CheckListHeader />
      <CheckListBody />
    </Page>
  );
}

export default ChecklistIndex;
