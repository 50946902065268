import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

export default function RoasterZones() {
  const { loading: roasterLoading } = useSelector((state) => state.roaster);
  const { zones, loading: zoneLoading } = useSelector((state) => state.store);
  const isEmpty = zones?.id === undefined;

  const { weeklyPercentage } = useSelector((state) => state.roaster);
  const lowerColor = weeklyPercentage <= zones?.low + 1;
  const excellentColor = weeklyPercentage <= zones?.excellent + 1 && weeklyPercentage > zones?.low;
  const healthyColor = weeklyPercentage <= zones?.healthy + 1 && weeklyPercentage > zones?.excellent;
  const dangerColor = weeklyPercentage >= zones?.healthy;

  // eslint-disable-next-line consistent-return
  const color = () => {
    if (lowerColor) return 'rgb(189 232 255)';
    if (excellentColor) return '#00FE7E';
    if (healthyColor) return '#008B00';
    if (dangerColor) return '#FF0033';
  };

  // eslint-disable-next-line consistent-return
  const fontColor = () => {
    if (lowerColor || excellentColor) return 'black';
    if (healthyColor || dangerColor) return 'white';
  };

  // eslint-disable-next-line consistent-return
  const findLeftPosition = (percentage) => {
    if (percentage === 0) return `2`;
    if (percentage < 1) return `${percentage - 1}%`;
    if (percentage <= zones?.low + 1) return `${percentage - 2.1}%`;
    if (percentage > zones?.low + 1 && percentage <= zones?.excellent + 1) return `${percentage - 2.3}%`;
    if (percentage > zones?.excellent + 1 && percentage <= zones?.healthy + 1) return `${percentage - 2.3}%`;
    if (percentage > zones?.healthy + 1 && percentage < 100) return `${percentage - 2.6}%`;
    // if (percentage >= 100) return 660;
  };
  return (
    <>
      {!roasterLoading && !zoneLoading && (
        <Box className="roasterZonesDataTest">
          <Box style={{ display: 'flex', flexGrow: 1 }}>
            {/* zones color pallette for roaster */}
            {!isEmpty && (
              <>
                <div
                  style={{
                    backgroundColor: '#BDE8FF',
                    width: `${zones?.low}%`,
                    height: '15px',
                    borderRadius: '7px 0 0 7px',
                  }}
                />
                <div style={{ height: 15, width: `${zones?.excellent - zones?.low}%`, backgroundColor: '#00FE7E' }} />
                <div
                  style={{ height: 15, width: `${zones?.healthy - zones?.excellent}%`, backgroundColor: '#008B00' }}
                />
                <div
                  style={{
                    height: 15,
                    width: `${100 - zones?.danger}%`,
                    backgroundColor: '#FF0033',
                    borderRadius: '0 7px 7px 0',
                  }}
                />
              </>
            )}
          </Box>

          {/* current position and current value of zones details */}
          {!isEmpty && (
            <Box
              style={{
                height: 30,
                width: 30,
                position: 'absolute',
                top: -30,
                left: findLeftPosition(weeklyPercentage),
                backgroundColor: color(),
                borderRadius: '50%',
              }}
            >
              <Tooltip title={`Weekly Average Sales Percentage: ${weeklyPercentage}%`} placement="top">
                <span
                  style={{
                    position: 'absolute',
                    top: 7,
                    left: 2,
                    textAlign: 'center',
                    height: 22,
                    width: 22,
                    fontWeight: '600',
                    fontSize: '0.65rem',
                    color: fontColor(),
                    cursor: 'pointer',
                  }}
                >
                  {weeklyPercentage}
                </span>
              </Tooltip>
            </Box>
          )}

          {/* zones details presentation */}
          {!isEmpty && (
            <Box style={{ marginTop: '5px', display: 'flex', borderRadius: '3px', justifyContent: 'center' }}>
              <div
                role="presentation"
                style={{
                  width: '15px',
                  height: '15px',
                  borderRadius: '100%',
                  backgroundColor: 'rgb(189 232 255)',
                }}
              />
              <p style={{ fontSize: '10px' }}>{`Low: 0 - ${zones?.low}%`}</p>
              <div
                role="presentation"
                style={{
                  width: '15px',
                  height: '15px',
                  borderRadius: '100%',
                  backgroundColor: '#00FE7E',
                  marginLeft: '20px',
                }}
              />
              <p style={{ fontSize: '10px' }}>{`Excellent: ${zones?.low + 1}% - ${zones?.excellent}%`}</p>
              <div
                role="presentation"
                style={{
                  width: '15px',
                  height: '15px',
                  borderRadius: '100%',
                  backgroundColor: '#008B00',
                  marginLeft: '20px',
                }}
              />
              <p style={{ fontSize: '10px' }}>{`Healthy: ${zones?.excellent + 1}% - ${zones?.healthy}%`}</p>
              <div
                role="presentation"
                style={{
                  width: '15px',
                  height: '15px',
                  borderRadius: '100%',
                  backgroundColor: '#FF0033',
                  marginLeft: '20px',
                }}
              />
              <p style={{ fontSize: '10px' }}>{`Danger: ${zones?.danger}% - 100%`}</p>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}
