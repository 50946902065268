import { Box, Button } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import React from 'react';
import { useSelector } from 'react-redux';

function UserNoteHeader() {
  const { postLoading } = useSelector((state) => state.userNote);
  return (
    <HeaderBreadcrumbs
      heading="User Notes"
      links={[
        // avoid page crash
        <Button
          key={1}
          variant="contained"
          color="primary"
          // startIcon={<Iconify icon={'mdi:eye'} />}
          // onClick={() => setOpenEventsView(true)}
          // className="roasterViewEventButton"
        >
          Events
        </Button>,
      ]}
      action={
        <Box className="invoiceListHeadingButtons">
          <Button
            variant="contained"
            color="primary"
            startIcon={<Iconify icon={'carbon:license-draft'} />}
            type="reset"
            form="userNotePublishForm"
            id="userNotePublish"
            disabled={postLoading}
          >
            {postLoading ? <CustomLoadingScreen circularWidth="20px" /> : 'Save'}
          </Button>
        </Box>
      }
    />
  );
}

export default UserNoteHeader;
