import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import * as yup from 'yup';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { RHFTextField } from 'components/hook-form';
import Iconify from 'components/Iconify';
import { saveEstimatedTimeData } from 'redux/slices/storeConfigSlice';

const schema = yup.object().shape({});

function EstimatedTimeBody() {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const { loading, estimatedTime } = useSelector((state) => state.storeConfig);

  const defaultValues = {};
  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onChange' });

  const { reset, control, getValues, setValue } = methods;

  useEffect(() => {
    if (!Object.keys(estimatedTime).length) return;
    reset({ data: estimatedTime });
  }, [reset, estimatedTime]);

  const EstimatedTimePublishHandler = (data) => {
    const finalData = {
      id: data?.data?.id,
      shopDeliveryMins: Number(data?.data?.shopDeliveryMins),
      shopPickupMins: Number(data?.data?.shopPickupMins),
      shopTableMins: Number(data?.data?.shopTableMins),
      webDeliveryMins: Number(data?.data?.webDeliveryMins),
      webPickupMins: Number(data?.data?.webPickupMins),
      webTableMins: Number(data?.data?.webTableMins),
      departmentId,
    };
    dispatch(saveEstimatedTimeData({ departmentId, finalData }));
  };

  //   Web handler
  const handleWebPickupMinus = () => {
    const mins = getValues('data.webPickupMins');
    if (mins === 0) return;
    if (mins <= 5) {
      setValue('data.webPickupMins', 0);
    } else {
      setValue('data.webPickupMins', Number(mins) - 5);
    }
  };
  const handleWebPickupPlus = () => {
    const mins = getValues('data.webPickupMins');
    setValue('data.webPickupMins', Number(mins) + 5);
  };

  const handleWebDeliveryMinus = () => {
    const mins = getValues('data.webDeliveryMins');
    if (mins === 0) return;
    if (mins <= 5) {
      setValue('data.webDeliveryMins', 0);
    } else {
      setValue('data.webDeliveryMins', Number(mins) - 5);
    }
  };
  const handleWebDeliveryPlus = () => {
    const mins = getValues('data.webDeliveryMins');
    setValue('data.webDeliveryMins', Number(mins) + 5);
  };

  const handleWebTableMinus = () => {
    const mins = getValues('data.webTableMins');
    if (mins === 0) return;
    if (mins <= 5) {
      setValue('data.webTableMins', 0);
    } else {
      setValue('data.webTableMins', Number(mins) - 5);
    }
  };
  const handleWebTablePlus = () => {
    const mins = getValues('data.webTableMins');
    setValue('data.webTableMins', Number(mins) + 5);
  };

  //   shop handler
  const handleShopPickupMinus = () => {
    const mins = getValues('data.shopPickupMins');
    if (mins === 0) return;
    if (mins <= 5) {
      setValue('data.shopPickupMins', 0);
    } else {
      setValue('data.shopPickupMins', Number(mins) - 5);
    }
  };
  const handleShopPickupPlus = () => {
    const mins = getValues('data.shopPickupMins');
    setValue('data.shopPickupMins', Number(mins) + 5);
  };

  const handleShopDeliveryMinus = () => {
    const mins = getValues('data.shopDeliveryMins');
    if (mins === 0) return;
    if (mins <= 5) {
      setValue('data.shopDeliveryMins', 0);
    } else {
      setValue('data.shopDeliveryMins', Number(mins) - 5);
    }
  };
  const handleShopDeliveryPlus = () => {
    const mins = getValues('data.shopDeliveryMins');
    setValue('data.shopDeliveryMins', Number(mins) + 5);
  };

  const handleShopTableMinus = () => {
    const mins = getValues('data.shopTableMins');
    if (mins === 0) return;
    if (mins <= 5) {
      setValue('data.shopTableMins', 0);
    } else {
      setValue('data.shopTableMins', Number(mins) - 5);
    }
  };
  const handleShopTablePlus = () => {
    const mins = getValues('data.shopTableMins');
    setValue('data.shopTableMins', Number(mins) + 5);
  };

  return (
    <>
      {loading ? (
        <CustomLoadingScreen height="40vh" />
      ) : (
        <FormProvider {...methods}>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell align="center">Pick Up</TableCell>
                  <TableCell align="center">Delivery</TableCell>
                  <TableCell align="center">Table</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Web</TableCell>
                  <TableCell align="center">
                    <Stack direction="row" justifyContent={'center'}>
                      <Button onClick={handleWebPickupMinus} variant="text" size="small" sx={{ mr: 1 }}>
                        <Iconify icon="ic:sharp-minus" />
                      </Button>
                      <Controller
                        name={'data.webPickupMins'}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            size="small"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">min</InputAdornment>,
                            }}
                            placeholder="0"
                            sx={{ width: '7rem' }}
                            fullWidth
                            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                      <Button onClick={handleWebPickupPlus} variant="text" size="small" sx={{ ml: 1 }}>
                        <Iconify icon="ic:sharp-add" />
                      </Button>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="row" justifyContent={'center'}>
                      <Button onClick={handleWebDeliveryMinus} variant="text" size="small" sx={{ mr: 1 }}>
                        <Iconify icon="ic:sharp-minus" />
                      </Button>
                      <Controller
                        name={'data.webDeliveryMins'}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            size="small"
                            placeholder="0"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">min</InputAdornment>,
                            }}
                            sx={{ width: '7rem' }}
                            fullWidth
                            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                      <Button onClick={handleWebDeliveryPlus} variant="text" size="small" sx={{ ml: 1 }}>
                        <Iconify icon="ic:sharp-add" />
                      </Button>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="row" justifyContent={'center'}>
                      <Button onClick={handleWebTableMinus} variant="text" size="small" sx={{ mr: 1 }}>
                        <Iconify icon="ic:sharp-minus" />
                      </Button>
                      <Controller
                        name={'data.webTableMins'}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            size="small"
                            placeholder="0"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">min</InputAdornment>,
                            }}
                            sx={{ width: '7rem' }}
                            fullWidth
                            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                      <Button onClick={handleWebTablePlus} variant="text" size="small" sx={{ ml: 1 }}>
                        <Iconify icon="ic:sharp-add" />
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Shop</TableCell>
                  <TableCell align="center">
                    <Stack direction="row" justifyContent={'center'}>
                      <Button onClick={handleShopPickupMinus} variant="text" size="small" sx={{ mr: 1 }}>
                        <Iconify icon="ic:sharp-minus" />
                      </Button>
                      <Controller
                        name={'data.shopPickupMins'}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            size="small"
                            placeholder="0"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">min</InputAdornment>,
                            }}
                            sx={{ width: '7rem' }}
                            fullWidth
                            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                      <Button onClick={handleShopPickupPlus} variant="text" size="small" sx={{ ml: 1 }}>
                        <Iconify icon="ic:sharp-add" />
                      </Button>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="row" justifyContent={'center'}>
                      <Button onClick={handleShopDeliveryMinus} variant="text" size="small" sx={{ mr: 1 }}>
                        <Iconify icon="ic:sharp-minus" />
                      </Button>
                      <Controller
                        name={'data.shopDeliveryMins'}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            size="small"
                            placeholder="0"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">min</InputAdornment>,
                            }}
                            sx={{ width: '7rem' }}
                            fullWidth
                            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                      <Button onClick={handleShopDeliveryPlus} variant="text" size="small" sx={{ ml: 1 }}>
                        <Iconify icon="ic:sharp-add" />
                      </Button>
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <Stack direction="row" justifyContent={'center'}>
                      <Button onClick={handleShopTableMinus} variant="text" size="small" sx={{ mr: 1 }}>
                        <Iconify icon="ic:sharp-minus" />
                      </Button>
                      <Controller
                        name={'data.shopTableMins'}
                        control={control}
                        render={({ field, fieldState: { error } }) => (
                          <TextField
                            {...field}
                            size="small"
                            placeholder="0"
                            InputProps={{
                              endAdornment: <InputAdornment position="end">min</InputAdornment>,
                            }}
                            sx={{ width: '7rem' }}
                            fullWidth
                            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
                            error={!!error}
                            helperText={error?.message}
                          />
                        )}
                      />
                      <Button onClick={handleShopTablePlus} variant="text" size="small" sx={{ ml: 1 }}>
                        <Iconify icon="ic:sharp-add" />
                      </Button>
                    </Stack>
                  </TableCell>
                </TableRow>
              </TableBody>
              {/* <EstimatedTimeTableBody /> */}
            </Table>
          </TableContainer>
          <Stack direction="row" justifyContent={'flex-end'} sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={methods.handleSubmit(EstimatedTimePublishHandler)}>
              Save
            </Button>
          </Stack>
        </FormProvider>
      )}
    </>
  );
}

export default EstimatedTimeBody;
