import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIngredientListWithPrice, getRecipeIngredientListWithPrice } from 'redux/slices/ingredientSlice';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { getFoodMenuSizes } from 'redux/slices/menuEngineeringSlice';
import FoodMenuComboForm from './FoodMenuComboForm';

function FoodMenuComboEdit({ open, setOpen, item, parent, menu }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  // const menuSizes = item?.comboItemSizes;
  // const ingredientId = item?.comboItemSizes[0]?.ingredientId;
  // const isRecipe = item?.comboItemSizes[0]?.ingredient?.type === 'MIXED';
  // const ingredientObject = item?.comboItemSizes[0]?.ingredient;
  // const itemId = item?.id;

  // useEffect(() => {
  //   if (!departmentId) return;
  //   dispatch(getIngredientList({ departmentId }));
  //   dispatch(getRecipeIngredientList({ departmentId }));
  // }, [departmentId, dispatch]);

  // useEffect(() => {
  //   if (!departmentId || !menu) return;
  //   dispatch(getIngredientListWithPrice({ departmentId }));
  //   dispatch(getRecipeIngredientListWithPrice({ departmentId }));
  //   // dispatch(getFoodMenuSizes({ departmentId, menuId: menu?.id }));
  // }, [departmentId, dispatch, menu]);

  return (
    <CustomOutletModal
      open={open}
      onClose={() => setOpen({ status: false, data: null, parent: null })}
      title={`Update ${item?.itemName} of ${parent?.title}`}
      zIndex="1600"
    >
      <FoodMenuComboForm isEdit setOpen={setOpen} menu={menu} parent={parent} item={item} />
    </CustomOutletModal>
  );
}

export default FoodMenuComboEdit;
