import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMenuTopinsQty } from 'redux/slices/menuEngineeringSlice';
import FoodMenuTopinsWithQtyForm from './FoodMenuTopinsWithQtyForm';

function FoodMenuTopinsQty({ menu }) {
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const { menuTopinsWithQtyLoading } = useSelector((state) => state.menuEngineering);

  useEffect(() => {
    if (!departmentId) return;
    if (!menu) return;
    dispatch(getMenuTopinsQty({ menuId: menu?.id, departmentId }));
  }, [departmentId, dispatch, menu]);
  return (
    <>{menuTopinsWithQtyLoading ? <CustomLoadingScreen height="20vh" /> : <FoodMenuTopinsWithQtyForm menu={menu} />}</>
  );
}

FoodMenuTopinsQty.propTypes = {
  menu: PropTypes.object,
};

export default FoodMenuTopinsQty;
