import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Stack,
  TextField,
} from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import { FormProvider, RHFCheckbox } from 'components/hook-form';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { sendAgreementDocument } from 'redux/slices/userSlice';

function HrCreateEmail({ open, setOpen }) {
  const defaultValues = {
    data: {
      SOP: true,
      OHNS: true,
      HR: true,
    },
    SOP: null,
    OHNS: null,
    HR: null,
  };
  const methods = useForm({ defaultValues });
  const { control, setValue, watch, reset } = methods;
  const { allUsers, loading, postLoading, agreementList } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const watchSOP = watch('SOP');

  const watchOHNS = watch('OHNS');
  const watchHR = watch('HR');

  const SendEmailToUserHandler = (data) => {
    if (data?.SOP === null && data?.OHNS === null && data?.HR === null)
      return toast.error('Please Select Atleast One Document');
    if (!data?.userId) return toast.error('Please Select A User');

    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        reset('userId');
      }
    };
    if (data?.SOP === 'SOP') {
      const finalData = { userId: data?.userId, type: 'SOP' };

      dispatch(sendAgreementDocument({ finalData, isSuccess: isSuccessful }));
    }
    if (data?.OHNS === 'OHNS') {
      const finalData = { userId: data?.userId, type: 'OHNS' };
      dispatch(sendAgreementDocument({ finalData, isSuccess: isSuccessful }));
    }
    if (data?.HR === 'HR') {
      const finalData = { userId: data?.userId, type: 'HR' };
      dispatch(sendAgreementDocument({ finalData, isSuccess: isSuccessful }));
    }
    setOpen(false);
  };
  const watchUser = watch('userId');
  const watchData = watch('data');
  const onChangeDocs = (event, item) => {
    setValue(`data`, { [item]: event.target.checked });
    if (!event.target.checked) return setValue(item, null);
    setValue(item, item);
  };

  return (
    <CustomOutletModal open={open} zIndex={1600} title={'Send Documents '}>
      <FormProvider methods={methods} onSubmit={methods.handleSubmit(SendEmailToUserHandler)}>
        {loading ? (
          <CustomLoadingScreen height="10vh" />
        ) : (
          <Stack my="1rem" gap="1rem">
            <Controller
              control={control}
              name="userId"
              render={({ field: { onChange, ...field } }) => (
                <Autocomplete
                  disableClearable
                  onChange={(event, data, reason, details) => {
                    setValue('userId', data?.id);
                  }}
                  getOptionLabel={(option) =>
                    `${option.firstName} ${option.lastName} ${
                      option.email ? `(${option.email})` : '(No Email Provided)'
                    }`
                  }
                  {...field}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={allUsers?.users || []}
                  renderInput={(params) => <TextField {...params} label="Select User*" />}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <img width="20" src={option.thumbnailURL} alt="" />
                      {option.firstName} {option.lastName} + {option.email}
                    </Box>
                  )}
                />
              )}
            />
            {watchUser && (
              <>
                <FormControl component="fieldset" variant="standard">
                  <FormLabel component="legend">Document List</FormLabel>
                  <FormGroup>
                    {Object.keys(agreementList)?.map((item, index) => (
                      <FormControlLabel
                        key={index}
                        label={item}
                        control={
                          <RHFCheckbox
                            checked={watchData.item}
                            name={`data.${item}`}
                            onChange={(event) => onChangeDocs(event, item)}
                          />
                        }
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </>
            )}
          </Stack>
        )}

        <Stack alignItems="flex-end" justifyContent={'flex-end'} mt="1rem" direction="row" gap="1rem">
          <Button variant="contained" color="secondary" type="submit" disabled={!watchHR && !watchOHNS && !watchSOP}>
            Send
          </Button>
          <Button variant="contained" onClick={() => setOpen(false)} color="error">
            Cancel
          </Button>
        </Stack>
      </FormProvider>
    </CustomOutletModal>
  );
}

HrCreateEmail.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default HrCreateEmail;
