import { Button, Grid, Stack } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import Iconify from 'components/Iconify';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { FormProvider, RHFTextField } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useState, useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

import { createMenuOptionGroup, deleteIndividualOption } from 'redux/slices/menuERSizeAndOptionSlice';
import MenuOptionFormIndividualItem from './MenuOptionFormIndividualItem';

const schema = Yup.object().shape({
  name: Yup.string().required(''),
  options: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required(''),
    })
  ),
});
function MenuOptionForm({ handleClose, selectedRow, isEdit }) {
  const { postLoading, individualMenuOption } = useSelector((state) => state.menuERSizeAndOption);

  const { loading } = useSelector((state) => state.ingredients);

  const [openDeleteModal, setOpenDeleteModal] = useState({ status: false, id: null });

  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const defaultValues = {
    name: '',
    options: [],
  };

  const methods = useForm({ defaultValues, resolver: yupResolver(schema), mode: 'onChange' });

  const { handleSubmit, control, reset } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options',
    keyName: 'optionsId',
  });

  const AddNewOptionTitle = () => {
    append({ name: '', isRecipe: false });
  };

  const RemoveOptionTitle = (el, index) => {
    if (!el?.id) {
      remove(index);
    } else {
      setOpenDeleteModal({ status: true, id: el?.id });
    }
  };

  const handleDeleteSingleOption = () => {
    dispatch(deleteIndividualOption({ departmentId, optionGroupId: selectedRow?.id, optionId: openDeleteModal?.id }));
    setOpenDeleteModal({ id: null, status: false });
  };

  const OptionSubmitHandler = (data) => {
    if (!isEdit) {
      const finalData = {
        ...data,
        departmentId,
        options: data?.options?.map((el) => ({ ...el, ingredientId: el?.ingredientId?.id })),
      };
      console.log(finalData);
      dispatch(createMenuOptionGroup({ finalData, handleClose }));
    } else {
      const finalData = {
        name: data?.name,
        departmentId,
        id: selectedRow?.id,
        options: data?.options?.map((el) => ({
          id: el?.id,
          ingredientId: el?.ingredientId?.id,
          isRecipe: el?.isRecipe,
          name: el?.name,
        })),
      };
      console.log(finalData);
      dispatch(createMenuOptionGroup({ finalData, isEdit }));
    }
  };

  useEffect(() => {
    if (!selectedRow) return;
    reset({ name: selectedRow?.name });
    if (!individualMenuOption?.length) return;
    const menuOptions = individualMenuOption?.map((item) => ({
      ...item,
      ingredientId: item?.ingredient,
      isRecipe: item?.ingredient ? item?.ingredient?.type !== 'SINGLE' : false,
    }));
    reset({ name: selectedRow?.name, options: menuOptions });
  }, [individualMenuOption, reset, selectedRow]);

  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(OptionSubmitHandler)}>
        {loading ? (
          <CustomLoadingScreen height="20vh" />
        ) : (
          <Stack my="1rem" gap="1rem">
            <RHFTextField name="name" label="Name" />
            <Grid container spacing={2}>
              {fields?.map((el, index) => (
                <MenuOptionFormIndividualItem key={index} el={el} index={index} RemoveOptionTitle={RemoveOptionTitle} />
              ))}
            </Grid>
          </Stack>
        )}
        <Stack alignItems="flex-end" marginTop={1}>
          <Button
            variant="contained"
            color="secondary"
            style={{ backgroundColor: '#4caf50' }}
            startIcon={<Iconify icon="material-symbols:add" />}
            onClick={AddNewOptionTitle}
          >
            Add Option
          </Button>
        </Stack>
        {isEdit && (
          <Stack alignItems="flex-start" marginTop={1}>
            <Button variant="contained" type="submit" color="secondary">
              {postLoading ? <CustomLoadingScreen circularWidth={'20px'} color="secondary" /> : 'Submit'}
            </Button>
          </Stack>
        )}
        {!isEdit && (
          <Stack alignItems="flex-start" marginTop={1}>
            {fields?.length !== 0 && (
              <Button variant="contained" type="submit" color="secondary">
                {postLoading ? <CustomLoadingScreen circularWidth={'20px'} color="secondary" /> : 'Submit'}
              </Button>
            )}
          </Stack>
        )}
      </FormProvider>
      {openDeleteModal.status && (
        <CustomDeleteDialog
          open={openDeleteModal}
          handleClose={setOpenDeleteModal}
          onAccept={handleDeleteSingleOption}
        />
      )}
    </>
  );
}

MenuOptionForm.propTypes = {
  handleClose: PropTypes.func,
  selectedRow: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default MenuOptionForm;
