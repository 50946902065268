import { Button, Card, FormLabel, MenuItem, Stack, Typography } from '@mui/material';
import CustomSelectField from 'components/CustomComponents/CustomSelectField';
import { FormProvider, RHFCheckbox, RHFTextField, RHFUploadAvatar } from 'components/hook-form';
import React, { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'redux/store';

const INGREDIENT_TYPES = [
  'SEAFOOD',
  'MEAT',
  'VEGETABLES',
  'DAIRY_OR_MILK',
  'HERBS',
  'FRUITS',
  'CEREALS_AND_PULSES',
  'SUGAR_AND_SUGAR_PRODUCTS',
  'OTHERS',
];

const NewIngredientsSchema = Yup.object().shape({
  name: Yup.string().required(),
  storageInstruction: Yup.string(),
  isAllergic: Yup.boolean(),
  shelfLife: Yup.number()
    .required('')
    .nullable()
    .positive('')
    .typeError('')
    .transform((current, original) => (original === '' || original === 0 ? null : current)),
  type: Yup.string().required('').min(1, ''),
});

function IngredientsAddForm({ onSubmit, isEdit = false, currentData = {}, cancelIngredientForm = false }) {
  const { postLoading } = useSelector((state) => state.ingredients);

  const defaultValues = useMemo(
    () => ({
      id: currentData?.id || null,
      name: currentData?.name || '',
      storageInstruction: currentData?.storageInstruction || '',
      isAllergic: currentData?.isAllergic || false,
      shelfLife: currentData?.shelfLife || '',
      ingredientsAdded: [],
      ingredientsRemoved: [],
      type: currentData?.type || '',
    }),
    [currentData]
  );

  const methods = useForm({ defaultValues, resolver: yupResolver(NewIngredientsSchema) });
  const { handleSubmit, setValue } = methods;

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'file',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }} justifyContent={'center'} mb={2}>
            <Card sx={{ p: 1 }}>
              <RHFUploadAvatar
                name="file"
                onDrop={handleDrop}
                isEditImage={isEdit ? { isEdit, imgUrl: currentData?.imgURL } : null}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >
                    Upload Ingredients Image ( Allowed *.jpeg, *.jpg, *.png, *.gif )
                    <br />
                  </Typography>
                }
              />
            </Card>
            <Card sx={{ p: 1 }}>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                <RHFTextField
                  name={`name`}
                  label="Name"
                  InputLabelProps={{ shrink: true }}
                  sx={{ maxWidth: { md: 200 } }}
                />
                <RHFTextField
                  name={`shelfLife`}
                  label="Shelf life In Days"
                  InputLabelProps={{ shrink: true }}
                  sx={{ maxWidth: { md: 200 } }}
                />
                <CustomSelectField name="type" label="Type" sx={{ width: '8rem' }}>
                  {INGREDIENT_TYPES?.map((option) => (
                    <MenuItem
                      key={option}
                      value={option}
                      // onClick={() => handleSelectService(index, option.name)}
                      sx={{
                        mx: 1,
                        my: 0.5,
                        borderRadius: 0.75,
                        typography: 'body2',
                        textTransform: 'capitalize',
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </CustomSelectField>
              </Stack>
              <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
                <Stack ml="1rem" direction="row" alignItems={'center'} gap="1rem">
                  <FormLabel>Is Allergic</FormLabel>
                  <RHFCheckbox name="isAllergic" />
                </Stack>
              </Stack>
              <Stack>
                <RHFTextField
                  name={`storageInstruction`}
                  label="Storage Instruction"
                  InputLabelProps={{ shrink: true }}
                  multiline
                  minRows={2}
                />
              </Stack>
              <Stack justifyContent="flex-end" direction="row" gap="1rem" style={{ marginTop: '10px' }}>
                <Button variant="contained" type="submit" disabled={postLoading}>
                  {isEdit ? 'Update' : 'Create'}
                </Button>

                {cancelIngredientForm && (
                  <Button variant="contained" onClick={() => cancelIngredientForm(false)} color="error">
                    Cancel
                  </Button>
                )}
              </Stack>
            </Card>
          </Stack>
        </Stack>
      </Card>
      {/* <Stack direction="row" spacing={2.5} mt="2rem">
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }} justifyContent={'center'} mb={2}>
          <RHFUploadAvatar
            name="file"
            onDrop={handleDrop}
            isEditImage={isEdit ? { isEdit, imgUrl: currentData?.imgURL } : null}
            helperText={
              <Typography
                variant="caption"
                sx={{
                  mt: 2,
                  mx: 'auto',
                  display: 'block',
                  textAlign: 'center',
                  color: 'text.secondary',
                }}
              >
                Upload Ingredients Image ( Allowed *.jpeg, *.jpg, *.png, *.gif )
                <br />
              </Typography>
            }
          />
        </Stack>

        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
          <RHFTextField name={`name`} label="Name" InputLabelProps={{ shrink: true }} sx={{ maxWidth: { md: 200 } }} />
          <RHFTextField
            name={`shelfLife`}
            label="Shelf life"
            InputLabelProps={{ shrink: true }}
            sx={{ maxWidth: { md: 100 } }}
          />
          <CustomSelectField name="type" label="Type" sx={{ width: '10rem' }}>
            {INGREDIENT_TYPES?.map((option) => (
              <MenuItem
                key={option}
                value={option}
                // onClick={() => handleSelectService(index, option.name)}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option}
              </MenuItem>
            ))}
          </CustomSelectField>
        </Stack>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }}>
          <Stack ml="1rem" direction="row" alignItems={'center'} gap="1rem">
            <FormLabel>Is Allergic</FormLabel>
            <RHFCheckbox name="isAllergic" />
          </Stack>
        </Stack>
        <Stack>
          <RHFTextField
            name={`storageInstruction`}
            label="Storage Instruction"
            InputLabelProps={{ shrink: true }}
            multiline
            minRows={2}
          />
        </Stack>

        <Stack alignItems="flex-end" direction="row" gap="1rem">
          <Button variant="contained" type="submit" disabled={postLoading}>
            {isEdit ? 'Update' : 'Create'}
          </Button>

          {cancelIngredientForm && (
            <Button variant="contained" onClick={() => cancelIngredientForm(false)} color="error">
              Cancel
            </Button>
          )}
        </Stack>
      </Stack> */}
    </FormProvider>
  );
}

export default IngredientsAddForm;
