import { Box, Stack, Typography } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FoodZonesAddEditForm from 'sections/@dashboard/store/FoodZonesAddEditForm';
import FoodZonesPieChart from 'sections/@dashboard/store/FoodZonesPieChart';
import ZonesAddEditForm from 'sections/@dashboard/store/ZonesAddEditForm';
import ZonesPieChart from 'sections/@dashboard/store/ZonesPieChart';

export default function ZonesCardBody() {
  const [openAddForm, setOpenAddForm] = useState(false);
  const [openAddFoodZonesForm, setOpenAddFoodZonesForm] = useState(false);
  const [updateData, setUpdateData] = useState();
  const [updateFoodZonesData, setUpdateFoodZonesData] = useState();
  const [openFormModal, setOpenFormModal] = useState(false);
  const [openFoodZonesFormModal, setOpenFoodZonesFormModal] = useState(false);
  const { zones, loading: zonesLoading } = useSelector((state) => state.store);

  const { foodCostZones, loading: foodCostZoneLoading } = useSelector((state) => state.foodMenus);

  const isEmpty = zones === null;
  const isFoodCostEmpty = foodCostZones === null;

  const low = zones?.low || 0;
  console.log('🚀 => ZonesCardBody => low=>', low);

  const excellent = zones?.excellent - zones?.low || 0;
  console.log('🚀 => ZonesCardBody => excellent=>', excellent);

  const healthy = zones?.healthy - zones?.excellent || 0;
  console.log('🚀 => ZonesCardBody => healthy=>', healthy);

  const danger = 100 - (low + excellent + healthy);
  // 100 - zones?.healthy || 0;
  console.log('🚀 => ZonesCardBody => danger=>', danger);

  const foodCostHealthy = typeof foodCostZones === 'number' ? foodCostZones : 0;
  const foodCostDanger = typeof foodCostHealthy === 'number' ? 100 - foodCostZones : 0;

  const handleAddZone = () => {
    setOpenAddForm(true);
  };
  const handleEditZone = () => {
    setUpdateData(zones);
    setOpenFormModal(true);
  };
  const handleFoodCostZoneEdit = () => {
    setUpdateFoodZonesData(foodCostZones);
    setOpenFoodZonesFormModal(true);
  };
  const handleFoodCostAddZone = () => {
    setOpenAddFoodZonesForm(true);
  };
  return (
    <Stack>
      <Stack flexWrap="wrap" direction="row" gap="1rem" m="1rem" alignItems={'center'} justifyContent={'space-evenly'}>
        {!zonesLoading && (
          <Box>
            {!isEmpty && (
              <ZonesPieChart
                title="Labour Zone Allocation"
                chartData={[
                  { label: `Low`, value: low },
                  { label: `Excellent`, value: excellent },
                  { label: `Healthy`, value: healthy },
                  { label: `Danger`, value: danger },
                ]}
                chartColors={['#FFA500', '#00FF00', '#0000FF', '#FF0000']}
                onClick={() => handleEditZone()}
              />
            )}
            {isEmpty && (
              <ZonesPieChart
                isEmpty
                title="Labour Zone Allocation"
                chartData={[{ label: `Empty`, value: 100 }]}
                chartColors={['#000000']}
                onClick={() => handleAddZone()}
              />
            )}
          </Box>
        )}
        {!foodCostZoneLoading && (
          <Box>
            {!isFoodCostEmpty && (
              <FoodZonesPieChart
                title="Food Zone Allocation"
                chartData={[
                  { label: `Healthy`, value: foodCostHealthy },
                  { label: `Danger`, value: foodCostDanger },
                ]}
                chartColors={['#FFA500', '#FF0000']}
                onClick={() => handleFoodCostZoneEdit()}
              />
            )}
            {isFoodCostEmpty && (
              <FoodZonesPieChart
                isEmpty
                title="Food Zone Allocation"
                chartData={[{ label: `Empty`, value: 100 }]}
                chartColors={['#000000']}
                onClick={() => handleFoodCostAddZone()}
              />
            )}
          </Box>
        )}
      </Stack>
      <Typography variant="p" sx={{ textAlign: 'center' }} color="warning">
        Note: Click on the chart to edit the zones details
      </Typography>
      {openAddForm && <ZonesAddEditForm open={openAddForm} setOpen={setOpenAddForm} />}
      {openFormModal && (
        <ZonesAddEditForm open={openFormModal} setOpen={setOpenFormModal} updateData={updateData} edit />
      )}
      {openAddFoodZonesForm && <FoodZonesAddEditForm open={openAddFoodZonesForm} setOpen={setOpenAddFoodZonesForm} />}
      {openFoodZonesFormModal && (
        <FoodZonesAddEditForm
          open={openFoodZonesFormModal}
          setOpen={setOpenFoodZonesFormModal}
          updateData={updateFoodZonesData}
          edit
        />
      )}
    </Stack>
  );
}
