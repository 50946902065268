import { Box, Tab, Tabs } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import { useState, useEffect } from 'react';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { useDispatch } from 'react-redux';
import { getFoodMenuSizePrice } from 'redux/slices/menuEngineeringSlice';
import FoodMenuSizeExtraPriceSection from './extraPrice';
import FoodMenuSizePriceGeneralSection from './priceGeneral';

function FoodMenuSizeDrawer({ open, setOpen, menu, indexList }) {
  const [currentTab, setCurrentTab] = useState(0);
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <>
      <CustomDrawer
        open={open}
        setOpen={setOpen}
        title={`Sizes of ${menu?.name}`}
        PaperProps={{ sx: { width: '400px' } }}
        handleClose={handleClose}
        // headerIcon="carbon:gui-management"
        // iconColor="#1976d2"
        // iconWidth={30}
        // iconHeight={30}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={currentTab} onChange={handleChange} aria-label="Menu Edit" variant="scrollable">
            <Tab label="General" id="0" />
            <Tab label="Price" id="1" />
          </Tabs>
        </Box>
        {currentTab === 0 && <FoodMenuSizePriceGeneralSection menu={menu} />}
        {currentTab === 1 && <FoodMenuSizeExtraPriceSection menu={menu} indexList={indexList} />}
      </CustomDrawer>
    </>
  );
}

export default FoodMenuSizeDrawer;
