import { Box, Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import React from 'react';
import { PATH_DASHBOARD } from 'routes/paths';

function ReportHeaders() {
  return (
    <HeaderBreadcrumbs
      heading="Report"
      links={[
        { name: 'Dashboard', href: PATH_DASHBOARD.root },
        { name: 'Report', href: PATH_DASHBOARD.report.root },
        // { name: 'List' },
      ]}
    />
  );
}

export default ReportHeaders;
