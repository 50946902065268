import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { FormProvider } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getFoodMenu } from 'redux/slices/menuEngineeringSlice';
import PropTypes from 'prop-types';
import { updateRecommendedMenus } from 'redux/slices/mostLikedMenusSlice';
import ManageRecommendedMenusBody from './ManageRecommendedMenusBody';

ManageRecommendedMenus.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

function ManageRecommendedMenus({ open, setOpen }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const { foodMenus, foodMenuLoading } = useSelector((state) => state.menuEngineering);

  const { recommendedMenus, recommendedMenuCurrentTab } = useSelector((state) => state.mostLikedMenus);

  const defaultValues = {};
  const methods = useForm({ defaultValues });
  const { reset, handleSubmit } = methods;

  const RecommendedMenusSubmit = (data) => {
    const finalData = {
      addMenusIds: data?.addMenus?.length ? data?.addMenus?.map((item) => item?.id) : [],
      removedMenuIds: data?.removedMenu?.length ? data?.removedMenu?.map((item) => item?.id) : [],
      departmentId,
      categoryId: recommendedMenuCurrentTab,
    };
    dispatch(updateRecommendedMenus({ finalData, categoryId: recommendedMenuCurrentTab, departmentId, setOpen }));
  };

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getFoodMenu({ departmentId }));
  }, [dispatch, departmentId]);

  useEffect(() => {
    if (!foodMenus?.length) return;
    if (!recommendedMenus?.length) {
      reset({ data: foodMenus });
      return;
    }

    reset({ data: foodMenus, selectedMenus: recommendedMenus });
  }, [foodMenus, recommendedMenus, reset]);

  return (
    <CustomDrawer
      open={open}
      setOpen={setOpen}
      title={'Recommended Menus'}
      PaperProps={{ sx: { width: '50%' } }}
      handleClose={() => setOpen(false)}
      headerIcon="carbon:gui-management"
      iconColor="#1976d2"
      iconWidth={30}
      iconHeight={30}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(RecommendedMenusSubmit)}>
        {foodMenuLoading ? <CustomLoadingScreen height="20vh" /> : <ManageRecommendedMenusBody />}
      </FormProvider>
    </CustomDrawer>
  );
}

export default ManageRecommendedMenus;
