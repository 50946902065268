import { useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';
import useSettings from 'hooks/useSettings';
import Page from 'components/Page';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import { PATH_DASHBOARD } from 'routes/paths';
// routes
import Invoice from 'sections/@dashboard/invoice/details';
import { useEffect } from 'react';
import { getInvoiceDetails } from 'redux/slices/InvoiceSlice';
import { useSelector, useDispatch } from 'react-redux';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
// ----------------------------------------------------------------------

export default function InvoiceDetails() {
  const { themeStretch } = useSettings();

  const { id } = useParams();
  const dispatch = useDispatch();
  // const { selectedDepartment } = useSelector((state) => state.userDepartment);
  const departmentId = useGetDepartmentId();
  const { storeSuppliers } = useSelector((state) => state.store);

  const { invoiceDetails, loading } = useSelector((state) => state.invoice);

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getInvoiceDetails({ departmentId, invoiceId: Number(id) }));
  }, [departmentId, dispatch, id]);

  return (
    <Page title="Invoice: View">
      {/* <Container maxWidth={themeStretch ? false : 'lg'}> */}
      <HeaderBreadcrumbs
        heading="Invoice Details"
        links={[
          { name: 'Dashboard', href: PATH_DASHBOARD.root },
          {
            name: 'Invoices',
            href: PATH_DASHBOARD.invoice.root,
          },
          { name: `INV-${invoiceDetails?.invoiceNumber}` || '' },
        ]}
      />
      {loading ? (
        <CustomLoadingScreen width="100%" height="50vh" />
      ) : (
        <Invoice
          invoice={invoiceDetails}
          suppliers={storeSuppliers.find((obj) => obj.id === invoiceDetails?.supplierId)}
        />
      )}
      {/* </Container> */}
    </Page>
  );
}
