import * as Yup from 'yup';

export const NewUserSchema = Yup.object().shape({
  firstName: Yup.string().max(100).required('').nullable(),
  middleName: Yup.string().max(100).nullable(),
  lastName: Yup.string().max(100).required('').nullable(),
  email: Yup.string().email().required('').max(100).nullable().typeError(''),

  phone: Yup.string().required('').nullable().min(12, '').max(12, '').typeError(),
  dob: Yup.date().required('').typeError(''),
  gender: Yup.string().required('* Required'),

  accountName: Yup.string().required('').nullable().typeError(),
  accountNo: Yup.string().required('').nullable().max(20, '').min(6, '').matches(/^\d+$/, { message: '*Numbers' }),
  bankName: Yup.string().required('').nullable().typeError(''),
  bsb: Yup.string().required('').nullable().min(3, '').max(8, '').typeError(''),
  emergencyContactName: Yup.string().max(100).nullable(),
  emergencyContactNo: Yup.string()
    .nullable()
    .min(12, '')
    .max(12, '')
    .typeError()
    .transform((o, c) => (o === '' ? null : c)),

  hasSuperFundAccount: Yup.boolean(),

  superName: Yup.string()
    .nullable()
    .max(100, '')
    .typeError('')
    .when('hasSuperFundAccount', {
      is: true,
      then: (schema) => schema.required(''),
      otherwise: (schema) => schema.notRequired(),
    }),

  superNumber: Yup.string()
    .nullable()
    .typeError('')
    .when('hasSuperFundAccount', {
      is: true,
      then: (schema) => schema.required(''),
      otherwise: (schema) => schema.notRequired(),
    }),

  tfnStatus: Yup.string().required('').nullable().typeError(''),
  tfnNo: Yup.string()
    .notRequired()
    .min(11, '')
    .max(11, '')
    .nullable()
    .transform((o, c) => (o === '' ? null : c)),
  address: Yup.string().max(256).required(''),
  state: Yup.string().max(50).required(''),
  zipcode: Yup.string().max(10).required(''),
  suburb: Yup.string().required(''),
});

export const validatePayOnlySchema = Yup.object().shape({
  weekDayPay: Yup.number().moreThan(1, '').lessThan(999999).required('').typeError(''),
  saturdayPay: Yup.number().moreThan(1, '').lessThan(999999).required('').typeError(''),
  sundayPay: Yup.number().moreThan(1, '').lessThan(999999).required('').typeError(''),
  holidayPay: Yup.number().moreThan(1, '').lessThan(999999).required('').typeError(''),
  overtimePay: Yup.number().moreThan(1, '').lessThan(999999).required('').typeError(''),
  weeklyHrs: Yup.number().moreThan(0, '').lessThan(99).required('').typeError(''),
});

export const validatePaperworkSchema = Yup.object().shape({
  isInternationalStudent: Yup.boolean(),

  passport: Yup.mixed().when(['isInternationalStudent', 'passportURL'], {
    is: (isInternationalStudent, passportURL) => isInternationalStudent && !passportURL,
    then: (schema) => schema.required(''),
    otherwise: (schema) => schema.notRequired(),
  }),

  visa: Yup.mixed().when(['isInternationalStudent', 'visaURL'], {
    is: (isInternationalStudent, visaURL) => isInternationalStudent && !visaURL,
    then: (schema) => schema.required(''),
    otherwise: (schema) => schema.notRequired(),
  }),
  workingRights: Yup.mixed().when(['isInternationalStudent', 'workingRightsURL'], {
    is: (isInternationalStudent, visaURL) => isInternationalStudent && !visaURL,
    then: (schema) => schema.required(''),
    otherwise: (schema) => schema.notRequired(),
  }),

  isDeliveryDriver: Yup.boolean(),
  drivingLicense: Yup.mixed().when(['isDeliveryDriver', 'drivingLicenseURL'], {
    is: (isDeliveryDriver, drivingLicenseURL) => isDeliveryDriver && !drivingLicenseURL,
    then: (schema) => schema.required(''),
    otherwise: (schema) => schema.notRequired(),
  }),
  insurance: Yup.mixed().when(['isDeliveryDriver', 'insuranceURL'], {
    is: (isInternationalStudent, insuranceURL) => isInternationalStudent && !insuranceURL,
    then: (schema) => schema.required(''),
    otherwise: (schema) => schema.notRequired(),
  }),
  rezoPaperWork: Yup.mixed().when(['isDeliveryDriver', 'rezoPaperWorkURL'], {
    is: (isInternationalStudent, rezoPaperWorkURL) => isInternationalStudent && !rezoPaperWorkURL,
    then: (schema) => schema.required(''),
    otherwise: (schema) => schema.notRequired(),
  }),
});

export const NewStaffSchema = Yup.object().shape({
  firstName: Yup.string().max(100).required(''),
  middleName: Yup.string().max(100).nullable().typeError(''),
  lastName: Yup.string().max(100).required(''),
  // email: Yup.string().required('').email(' ').max(100),
  phone: Yup.string().required('').nullable().min(12, '').max(12, '').typeError(''),
  dob: Yup.date().required('').typeError(''),
  gender: Yup.string().required('* Required'),
  emergencyContactName: Yup.string().max(100).nullable(),
  emergencyContactNo: Yup.string()
    .nullable()
    .min(12, '')
    .max(12, '')
    .typeError()
    .transform((o, c) => (o === '' ? null : c)),
  address: Yup.string().max(256).required(''),
  state: Yup.string().max(50).required(''),
  zipcode: Yup.string().max(10).required(''),
  suburb: Yup.string().required(''),

  accountName: Yup.string().required('').nullable().typeError(),
  accountNo: Yup.string().required('').nullable().max(20, '').min(6, '').matches(/^\d+$/, { message: '*Numbers' }),
  bankName: Yup.string().required('').nullable().typeError(''),
  bsb: Yup.string().required('').nullable().min(3, '').max(8, '').typeError(''),
  tfnStatus: Yup.string().required('').nullable().typeError(''),
  tfnNo: Yup.string()
    .min(11, '')
    .max(11, '')
    .nullable()
    .transform((o, c) => (o === '' ? null : c))
    .when('tfnStatus', {
      is: (tfnStatus) => tfnStatus === 'HAS_TFN',
      then: (schema) => schema.required(''),
      otherwise: (schema) => schema.notRequired(''),
    }),
  hasSuperFundAccount: Yup.boolean(),

  superName: Yup.string()
    .nullable()
    .max(100, '')
    .typeError('')
    .when('hasSuperFundAccount', {
      is: true,
      then: (schema) => schema.required(''),
      otherwise: (schema) => schema.notRequired(),
    }),

  superNumber: Yup.string()
    .nullable()
    .typeError('')
    .when('hasSuperFundAccount', {
      is: true,
      then: (schema) => schema.required(''),
      otherwise: (schema) => schema.notRequired(),
    }),

  isInternationalStudent: Yup.boolean(),

  passport: Yup.mixed().when(['isInternationalStudent', 'passportURL'], {
    is: (isInternationalStudent, passportURL) => isInternationalStudent && !passportURL,
    then: (schema) => schema.required(''),
    otherwise: (schema) => schema.notRequired(),
  }),

  visa: Yup.mixed().when(['isInternationalStudent', 'workingRights'], {
    is: (isInternationalStudent, workingRights) => isInternationalStudent && !workingRights,
    then: (schema) => schema.required(''),
    otherwise: (schema) => schema.notRequired(),
  }),
  workingRights: Yup.mixed().when('isInternationalStudent', {
    is: true,
    then: (schema) => schema.required(''),
    otherwise: (schema) => schema.notRequired(),
  }),

  isDeliveryDriver: Yup.boolean(),
  drivingLicense: Yup.mixed().when(['isDeliveryDriver', 'drivingLicenseURL'], {
    is: (isDeliveryDriver, drivingLicenseURL) => isDeliveryDriver && !drivingLicenseURL,
    then: (schema) => schema.required(''),
    otherwise: (schema) => schema.notRequired(),
  }),
  insurance: Yup.mixed().when('isDeliveryDriver', {
    is: true,
    then: (schema) => schema.required(''),
    otherwise: (schema) => schema.notRequired(),
  }),
  rezoPaperWork: Yup.mixed().when('isDeliveryDriver', {
    is: true,
    then: (schema) => schema.required(''),
    otherwise: (schema) => schema.notRequired(),
  }),

  acceptTermsAndCondition: Yup.boolean().required(),
});
