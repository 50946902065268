import { Card, Stack } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import React from 'react';
import { useSelector } from 'react-redux';
import ReportPieChart from 'sections/@dashboard/report/ReportPieChart';
import QuickSummaryPieChart from 'sections/@dashboard/roaster/QuickSumamryPieChart';

function ReportCardBodyChart() {
  const { report, loading } = useSelector((state) => state.reports);

  const totalFixedCost = report?.totalFixedCost || 0;
  const totalRunningCost = report?.totalRunningCost || 0;
  const totalSales = report?.totalSales || 0;
  const totalLaborCosts = report?.totalLaborCosts || 0;
  const totalInvoiceCosts = report?.totalInvoiceCosts || 0;
  const totalOperationalCost = totalFixedCost + totalRunningCost;
  const totalProfit = totalSales - (totalFixedCost + totalRunningCost + totalInvoiceCosts + totalLaborCosts) || 0;

  const totalSalesColor = '#AAAAFF';
  const totalLaborColor = '#0000FF';
  const totalInvoiceColor = '#00BEFE';
  const totalFixedCostColor = '#A020F0';
  const totalRunningCostColor = '#FFA500';
  const totalProfitColor = '#00FF00';
  const totalOperationalColor = '#DB7093';

  return (
    <Stack m="2rem">
      {loading ? (
        <CustomLoadingScreen height="30vh" />
      ) : (
        <>
          <Stack gap="2rem" mx="5rem">
            <Stack flexDirection={'row'} justifyContent={'space-between'} gap="1rem" width="100%">
              <Card sx={{ width: '50%', padding: '0.6rem' }}>
                <ReportPieChart
                  title="Food Cost v/s Sales "
                  chartData={[
                    { label: `Food Cost`, value: totalInvoiceCosts },
                    { label: `Sales`, value: totalSales },
                  ]}
                  chartColors={[totalInvoiceColor, totalSalesColor]}
                  size={400}
                />
              </Card>
              <Card sx={{ width: '50%', padding: '0.6rem' }}>
                <ReportPieChart
                  title="Labor Cost v/s Sales "
                  chartData={[
                    { label: `Labor Cost`, value: totalLaborCosts },
                    { label: `Sales`, value: totalSales },
                  ]}
                  chartColors={[totalLaborColor, totalSalesColor]}
                  size={400}
                />
              </Card>
            </Stack>

            <Stack flexDirection={'row'} justifyContent={'space-between'} gap="1rem" width="100%">
              <Card sx={{ width: '50%', padding: '0.6rem' }}>
                <ReportPieChart
                  title="Operational v/s Sales "
                  chartData={[
                    { label: `Operational`, value: totalOperationalCost },
                    { label: `Sales`, value: totalSales },
                  ]}
                  chartColors={[totalOperationalColor, totalSalesColor]}
                  size={400}
                />
              </Card>
              <Card sx={{ width: '50%', padding: '0.6rem' }}>
                <ReportPieChart
                  title="Profit v/s Sales "
                  chartData={[
                    { label: `Profit`, value: totalProfit },
                    { label: `Sales`, value: totalSales },
                  ]}
                  chartColors={[totalProfitColor, totalSalesColor]}
                  size={400}
                />
              </Card>
            </Stack>
          </Stack>
          <Stack mt={'4rem'}>
            <ReportPieChart
              title="Total Information"
              chartData={[
                { label: `labor`, value: totalLaborCosts },
                { label: `Food`, value: totalInvoiceCosts },
                { label: `Operational`, value: totalOperationalCost },
                { label: `Profit`, value: totalProfit },
              ]}
              chartColors={[totalLaborColor, totalInvoiceColor, totalOperationalColor, totalProfitColor]}
              size={600}
            />
          </Stack>
        </>
      )}
    </Stack>
  );
}

export default ReportCardBodyChart;
