import { Button, TableCell, TableRow, Tooltip } from '@mui/material';
import Iconify from 'components/Iconify';
import React, { useState } from 'react';
import FoodMenuSizeGeneralPriceSingleSizeEdit from './FoodMenuSizeGeneralPriceSingleSizeEdit';

function FoodMenuSizeGeneralPriceSingleSize({ size, index }) {
  const [openEdit, setOpenEdit] = useState({ status: false, index: null });

  return (
    <>
      <TableRow>
        <TableCell>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>{size?.name}</div>
          </div>
          <div style={{ marginTop: '-6px', fontSize: '.7rem' }}>$ {size?.additionalPrice}</div>
        </TableCell>
        <TableCell>
          <Tooltip title={size?.isPublished ? 'Publised' : 'Not Published'} placement="right">
            <div style={{ marginTop: '-7px' }}>
              <Iconify
                icon={size?.isPublished ? 'flat-color-icons:ok' : 'nonicons:not-found-16'}
                width={size?.isPublished ? 16 : 15}
                height={size?.isPublished ? 16 : 15}
                style={{ marginTop: '10px', color: size?.isPublished ? 'green' : 'black' }}
              />
            </div>
          </Tooltip>
        </TableCell>
        <TableCell>
          <Button variant="contained" onClick={() => setOpenEdit({ status: true, index })}>
            Edit
          </Button>
          {/* <Button variant="text" color="error">
            <Iconify icon="entypo:cross" height={20} width={20} />
          </Button> */}
        </TableCell>
      </TableRow>
      {openEdit?.status && (
        <FoodMenuSizeGeneralPriceSingleSizeEdit
          //  handleClose={handleClose}
          setOpen={setOpenEdit}
          open={openEdit?.status}
          index={openEdit?.index}
        />
      )}
    </>
  );
}

export default FoodMenuSizeGeneralPriceSingleSize;
