import { Card, Stack } from '@mui/material';
import React from 'react';
import UserWorkReportBodyTable from 'sections/@dashboard/report/userWorkInfo/UserWorkReportBodyTable';
import UserWorkReportFilter from 'sections/@dashboard/report/userWorkInfo/UserWorkReportFilter';
import UserWorkReportFilterByName from 'sections/@dashboard/report/userWorkInfo/UserWorkReportFilterByName';

function UserWorkInfoBody() {
  const [list, setList] = React.useState([]);
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');

  return (
    <Card>
      <Stack direction="row" alignItems="center">
        <UserWorkReportFilterByName list={list} setList={setList} />
        <UserWorkReportFilter setStartDate={setStartDate} setEndDate={setEndDate} />
      </Stack>
      <UserWorkReportBodyTable list={list} startDate={startDate} endDate={endDate} />
    </Card>
  );
}

export default UserWorkInfoBody;
