import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import React from 'react';
import StreetAddForm from './StreetAddForm';

function CreateNewStreet({ open, handleClose, suburbId }) {
  return (
    <CustomOutletModal open={open} setOpen={handleClose} zIndex="1600" title="Add New Street" width="30rem">
      <StreetAddForm handleClose={handleClose} suburbId={suburbId} />
    </CustomOutletModal>
  );
}

export default CreateNewStreet;
