import CustomOutletModal from 'components/CustomComponents/CustomOutletModal';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { citiesInStore, getDeliveryZone } from 'redux/slices/posConfigSlice';
import SuburbAddForm from './SuburbAddForm';

function EditSuburb({ open, handleClose, updateData }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDeliveryZone());
    dispatch(citiesInStore());
  }, [dispatch]);
  return (
    <CustomOutletModal open={open} setOpen={handleClose} zIndex="1600" title="Edit Suburb">
      <SuburbAddForm handleClose={handleClose} updateData={updateData} isEdit />
    </CustomOutletModal>
  );
}

export default EditSuburb;
