import { Box, Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { findWeeklyTotalOfEachUser, findWeeklyTotalOfEachUsers } from 'utils/roasterFeatureUtils';

export default function RoasterUserWeeklyData({ mainIndex, totalPriceOfWeek }) {
  const [total, setTotal] = useState();
  const { control } = useFormContext();

  const values = useWatch({
    control,
    name: `users.${mainIndex}`,
  });

  useEffect(() => {
    if (!Object.keys(values)) return;
    const findTotal = findWeeklyTotalOfEachUser(values);

    setTotal(findTotal);
  }, [values, totalPriceOfWeek]);

  const findTotalWorkTime = (totalDate) => {
    const hours = Math.floor(totalDate / 1000 / 60 / 60);
    const minutes = Math.ceil((totalDate / 1000 / 60) % 60);
    if (minutes > 59) return `${hours + 1}H 0M`;
    return `${hours}H ${minutes}M`;
  };
  return (
    <Box
      className="roasterUserDetails"
      style={{ display: 'flex', flexDirection: 'column', fontSize: '0.6rem', position: 'absolute', right: '10px' }}
    >
      <Box>
        <div>
          <Tooltip title="weekly earnings" placement="right">
            <p>$ {total?.getTotalWeeklyCost ? total?.getTotalWeeklyCost.toFixed(1) : '0'}</p>
          </Tooltip>
          <Tooltip title="weekly hours" placement="right">
            <p>
              {findTotalWorkTime(total?.getTotalHours)}
              {/* {total?.getTotalHours
                ? `${Math.floor(total?.getTotalHours / 60 / 1000 / 60)}H ${
                    Math.ceil(total?.getTotalHours / 60 / 1000) % 60
                  }M`
                : '0H 0M'} */}
            </p>
          </Tooltip>
          <Tooltip title="earning percentage" placement="right">
            <p>
              %{' '}
              {total?.getTotalHours && total?.getTotalWeeklyCost
                ? ((total?.getTotalWeeklyCost.toFixed(1) / totalPriceOfWeek) * 100).toFixed(2)
                : '0.00'}{' '}
            </p>
          </Tooltip>
        </div>
      </Box>
    </Box>
  );
}
