import {
  Autocomplete,
  Button,
  Card,
  // Stack,
  // Table,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';
// import CustomMultipleAutoCompleteField from 'components/CustomComponents/CustomAutoCompleteField';
import Iconify from 'components/Iconify';
import { RHFTextField } from 'components/hook-form';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getMenusOfSelectedSize, getSizesInCategories } from 'redux/slices/dealSlice';
import AddMenusToDealCategory from './AddMenusToDealCategory';

DealCategoryIndividualDeal.propTypes = {
  el: PropTypes.object,
  index: PropTypes.number,
  RemoveSingleDeal: PropTypes.func,
};

function DealCategoryIndividualDeal({ el, index, RemoveSingleDeal }) {
  const [openAddMenus, setOpenAddMenus] = useState(false);
  const { foodCategories } = useSelector((state) => state.menuEngineering);
  const { sizesListInCategory } = useSelector((state) => state.deal);

  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const { watch, control, setValue } = useFormContext();

  const watchCategoryId = watch(`dealCategories.${index}.categoryId`);
  const watchSizeId = watch(`dealCategories.${index}.sizeId`);

  useEffect(() => {
    if (!watchCategoryId) return;
    if (!departmentId) return;
    dispatch(getSizesInCategories({ departmentId, categoryId: watchCategoryId?.id }));
  }, [departmentId, dispatch, watchCategoryId]);

  const watchMenus = useWatch({
    name: `dealCategories.${index}.menusOfCategories`,
    control,
  });

  const handleAddNewMenuClicked = () => {
    setOpenAddMenus(true);
    dispatch(getMenusOfSelectedSize({ departmentId, sizeId: watchSizeId?.id, categoryId: watchCategoryId?.id }));
  };

  // const sizeOnChangeHandler = (event, data, reason, details) => {};

  return (
    <Card sx={{ p: 1 }}>
      <TableContainer sx={{ p: 1 }}>
        <TableRow>
          <TableCell style={{ margin: '3px', padding: '3px', maxWidth: '120px' }}>
            <RHFTextField name={`dealCategories.${index}.qty`} label="Qty" />
          </TableCell>
          <TableCell style={{ margin: '3px', padding: '3px' }}>
            <Controller
              control={control}
              name={`dealCategories.${index}.categoryId`}
              render={({ field: { onChange, ...field } }) => (
                <Autocomplete
                  label="Select Category*"
                  // multiple
                  disableClearable
                  onChange={(event, data) => {
                    onChange(data);
                    setValue(`dealCategories.${index}.sizeId`, null);
                    setValue(`dealCategories.${index}.addedMenus`, []);
                  }}
                  getOptionLabel={(option) => option?.name}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  {...field}
                  options={foodCategories || []}
                  renderInput={(params) => <TextField {...params} label="Select Category*" sx={{ width: '11rem' }} />}
                />
              )}
            />
            {/* <CustomMultipleAutoCompleteField
              name={`dealCategories.${index}.categoryId`}
              label="Select Category*"
              options={foodCategories}
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ width: '11rem' }}
            /> */}
          </TableCell>
          <TableCell style={{ margin: '3px', padding: '3px' }}>
            <Controller
              control={control}
              name={`dealCategories.${index}.sizeId`}
              render={({ field: { onChange, ...field } }) => (
                <Autocomplete
                  disabled={!watchCategoryId}
                  label="Select Size*"
                  // multiple
                  disableClearable
                  onChange={(event, data) => {
                    onChange(data);
                    setValue(`dealCategories.${index}.addedMenus`, []);

                    // sizeOnChangeHandler(event, data, reason, details);
                  }}
                  getOptionLabel={(option) => option?.name}
                  isOptionEqualToValue={(option, value) => option?.id === value?.id}
                  {...field}
                  options={sizesListInCategory || []}
                  renderInput={(params) => <TextField {...params} label="Select Size*" sx={{ width: '11rem' }} />}
                />
              )}
            />
            {/* <CustomMultipleAutoCompleteField
              name={`dealCategories.${index}.sizeId`}
              label="Select Size*"
              options={sizesListInCategory}
              disabled={!watchCategoryId}
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ width: '11rem' }}
            /> */}
          </TableCell>

          <TableCell style={{ margin: '3px', padding: '3px' }}>
            <Button
              color="primary"
              variant="text"
              startIcon={<Iconify icon="material-symbols:add" />}
              onClick={handleAddNewMenuClicked}
              disabled={!watchSizeId}
            >
              Menus
            </Button>
          </TableCell>
          <TableCell>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Iconify
                icon="solar:trash-bin-minimalistic-2-bold"
                height={20}
                width={20}
                style={{ color: 'red', cursor: 'pointer' }}
                onClick={() => RemoveSingleDeal(el, index)}
              />
            </div>
          </TableCell>
          <TableCell colSpan={3} style={{ width: '500px' }} />
        </TableRow>
        <TableRow>
          <TableCell style={{ margin: '3px', padding: '3px', width: '100px' }} colSpan={8}>
            {watchMenus?.map((menu, index) => (
              <span key={index} style={{ marginRight: '3px' }}>
                {menu}
                {watchMenus?.length - 1 !== index && ', '}
              </span>
            ))}
          </TableCell>
        </TableRow>
      </TableContainer>

      {openAddMenus && (
        <AddMenusToDealCategory open={openAddMenus} handleClose={() => setOpenAddMenus(false)} index={index} />
      )}
    </Card>
  );
}

export default DealCategoryIndividualDeal;
