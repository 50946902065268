import { Box, Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import React from 'react';
import { PATH_DASHBOARD } from 'routes/paths';
import EftAddForm from 'sections/@dashboard/sales/payType/PayTypeAddForm';

function PayTypeHeader() {
  const [openAddEft, setOpenAddEft] = React.useState(false);
  return (
    <HeaderBreadcrumbs
      heading="Pay Types"
      links={[
        { name: 'Dashboard', href: PATH_DASHBOARD.root },
        { name: 'Store' },
        // { name: 'Cash-Drawer' }
      ]}
      action={
        <Box className="invoiceListHeadingButtons">
          <Button
            variant="contained"
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            onClick={() => {
              setOpenAddEft(true);
            }}
          >
            Payment Type
          </Button>

          {openAddEft && <EftAddForm open={openAddEft} setOpen={setOpenAddEft} edit={false} />}
        </Box>
      }
    />
  );
}

export default PayTypeHeader;
