import { Box, Button } from '@mui/material';
import { DialogAnimate } from 'components/animate';
import React from 'react';

// openDialogBox is a bolean, deleteDialogBoxClose is a function and onAccept is a function

function CustomDeleteDialog({ open, handleClose, onAccept, title = 'Are You sure you want to Delete ?' }) {
  return (
    <DialogAnimate open={open} onClose={handleClose}>
      <Box className="deleteObjectDialogBox">
        <h2>{title}</h2>
        <Box className="deleteObjectDialogBoxBody">
          <Button color="error" onClick={onAccept}>
            Yes
          </Button>
          <Button color="secondary" variant="contained" onClick={handleClose}>
            No
          </Button>
        </Box>
      </Box>
    </DialogAnimate>
  );
}

export default CustomDeleteDialog;
