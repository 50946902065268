import Page from 'components/Page';
import React from 'react';
import ReceipeHeader from './ReceipeHeader';
import ReceipeBody from './ReceipeBody';

function RecipeIndex() {
  return (
    <Page title="Recipe">
      <ReceipeHeader />
      <ReceipeBody />
    </Page>
  );
}

export default RecipeIndex;
