import { Box, Button, Divider, Grid, InputAdornment, Stack, Typography } from '@mui/material';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import { RHFTextField } from 'components/hook-form';
import Iconify from 'components/Iconify';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { sum } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { deleteSalesPayout } from 'redux/slices/salesSlice';
import { fCurrency } from 'utils/formatNumber';

export default function SalesPayoutSection() {
  const { control, setValue } = useFormContext();
  const [deletePayout, setDeletePayout] = useState({ status: false, id: null, index: null });
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'payouts',
    keyName: 'fieldID',
  });

  const handleAddItem = () => {
    append({
      title: '',
      amount: 0,
    });
  };
  const handleRemoveItem = (index, item) => {
    if (item?.id) {
      setDeletePayout({ status: true, id: item?.id, index });
    } else {
      remove(index);
    }
    console.log(index);
    console.log(item);
    // remove(index);
  };

  const handleDeletePayout = () => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        remove(deletePayout?.index);
      }
    };
    dispatch(deleteSalesPayout({ id: deletePayout?.id, departmentId, isSuccess: isSuccessful }));
    setDeletePayout({ status: false, id: null });
  };
  const values = useWatch({
    control,
    name: 'payouts',
  });

  const totalOnRow = values?.map((item) => Number(item.amount));
  const totalPayout = sum(totalOnRow);
  useEffect(() => {
    setValue('totalPayouts', totalPayout);
  }, [setValue, totalPayout]);
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ color: 'text.disabled', mb: 1 }}>
        Payouts Details:
      </Typography>
      <Grid container spacing={2}>
        {fields.map((item, index) => (
          <Grid item xs={12} md={6} key={item?.fieldID} alignItems="flex-start" spacing={1.5}>
            <Grid container spacing={2}>
              <Grid item xs={7}>
                <RHFTextField name={`payouts[${index}].title`} label="Title" size="small" />
              </Grid>
              <Grid item xs={3}>
                <RHFTextField
                  name={`payouts[${index}].amount`}
                  label="Amount"
                  size="small"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <Button
                  size="small"
                  variant="text"
                  color="error"
                  startIcon={<Iconify icon="eva:trash-2-fill" />}
                  onClick={() => handleRemoveItem(index, item)}
                />
              </Grid>

              {/* </Button> */}
            </Grid>
            {/* <Box alignSelf="flex-end"></Box> */}
          </Grid>
        ))}
      </Grid>

      <Divider sx={{ my: 3, borderStyle: 'dashed' }} />
      <Stack spacing={2} direction={{ xs: 'column', md: 'row' }} alignItems={{ xs: 'flex-start', md: 'center' }}>
        <Button
          size="small"
          variant="contained"
          color="secondary"
          startIcon={<Iconify icon="eva:plus-fill" />}
          onClick={handleAddItem}
        >
          Add Payouts
        </Button>
        {totalPayout > 0 && (
          <>
            <Button size="small" disabled>
              Total Payouts: {fCurrency(totalPayout)}
            </Button>
          </>
        )}

        {deletePayout.status && (
          <CustomDeleteDialog
            open={deletePayout.status}
            handleClose={() => setDeletePayout({ status: false, id: null })}
            onAccept={handleDeletePayout}
          />
        )}
      </Stack>
    </Box>
  );
}
