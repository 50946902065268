import React, { useState } from 'react';
import { Icon } from '@iconify/react';
import { Avatar, MenuItem, Stack, TableCell, TableRow, Tooltip } from '@mui/material';
import Iconify from 'components/Iconify';
import { TableMoreMenu } from 'components/table';
import CustomDeleteDialog from 'components/CustomComponents/CustomDeleteDialog';
import { dispatch } from 'redux/store';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { clearIngredientDetails, deleteIngredients } from 'redux/slices/ingredientSlice';
import IngredientEdit from 'pages/dashboard/Ingredients/IngredientEdit';
// import IngredientMangeStockDrawer from './IngredientMangeStockDrawer';
import IngredientManageDrawer from './IngredientManageDrawer';

function IngredientTableRow({ row, index }) {
  let category = row?.type ? row.type.split('_') : [];
  if (category.length > 0) {
    category = category.join(' ');
  } else {
    category = '';
  }
  let description = row?.productDescription ? row.productDescription : '';
  if (description.length > 30) {
    description = `${description.slice(0, 30)}...`;
  }
  const [openMenu, setOpenMenuActions] = useState(null);
  const [updateData, setUpdateData] = useState(null);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState({ ingredientId: null, status: false });
  const [openManageStockDialog, setOpenManageStockDialog] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const departmentId = useGetDepartmentId();

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  const handleDeleteDialog = () => {
    setOpenDeleteDialog({ ingredientId: null, status: false });
  };

  // const handleManageStockClose = () => {
  //   dispatch(clearIngredientDetails());
  // };

  // const onEditRow = (stock) => {
  //   setOpenUpdateDialog(true);
  //   setUpdateData(stock);
  // };

  const onDeleteRow = (ingredientId) => {
    setOpenDeleteDialog({ ingredientId, status: true });
  };

  const onDeleteIngredientAccept = () => {
    dispatch(deleteIngredients({ departmentId, ingredientId: openDeleteDialog?.ingredientId }));
    handleDeleteDialog();
  };

  const onManageStockRow = (row) => {
    setOpenManageStockDialog(true);
    setSelectedRow(row);
  };
  return (
    <>
      <TableRow hover>
        <TableCell> {index + 1}</TableCell>
        <TableCell>{category}</TableCell>
        <TableCell> {row?.externalUID}</TableCell>
        <TableCell>
          {description.length > 30 ? (
            <Tooltip title={`${row?.productDescription} `}>
              <p
                className="roasterUserDetails"
                style={{ cursor: 'pointer', display: 'felx', flexWrap: 'nowrap', overflow: 'hidden' }}
              >
                {description}
              </p>
            </Tooltip>
          ) : (
            description
          )}
        </TableCell>
        <TableCell> {row?.uom}</TableCell>
        <TableCell> {row?.weight} (kg)</TableCell>
        <TableCell>$ {row?.unitPrice}</TableCell>
        <TableCell> {row?.gst}</TableCell>
        <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            onOpen={handleOpenMenu}
            onClose={handleCloseMenu}
            actions={
              <>
                <MenuItem
                  onClick={() => {
                    onManageStockRow(row?.id);
                    handleCloseMenu();
                  }}
                >
                  {/* <Iconify icon={'eva:edit-fill'} /> */}
                  <Iconify icon="healthicons:rdt-result-out-stock" />
                  Manage
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    onDeleteRow(row?.id);
                    handleCloseMenu();
                  }}
                  sx={{ color: 'error.main' }}
                >
                  <Iconify icon={'eva:trash-2-outline'} />
                  Delete
                </MenuItem>
              </>
            }
          />
        </TableCell>
      </TableRow>
      {openDeleteDialog?.status && (
        <CustomDeleteDialog
          open={openDeleteDialog}
          handleClose={handleDeleteDialog}
          onAccept={onDeleteIngredientAccept}
        />
      )}

      {openUpdateDialog && (
        <IngredientEdit open={openUpdateDialog} setOpen={setOpenUpdateDialog} currentData={updateData} />
      )}

      {openManageStockDialog && (
        // <IngredientMangeStockDrawer
        //   open={openManageStockDialog}
        //   setOpen={setOpenManageStockDialog}
        //   currentData={selectedRow}
        //   handleClose={handleManageStockClose}
        // />

        <IngredientManageDrawer
          open={openManageStockDialog}
          setOpen={setOpenManageStockDialog}
          currentData={selectedRow}
          isUpdate
        />
      )}
    </>
  );
}

export default IngredientTableRow;
