import PropTypes from 'prop-types';
import { Avatar, Box, Button, Menu, MenuItem, TableCell, TableRow, useTheme } from '@mui/material';
import Iconify from 'components/Iconify';
import Label from 'components/Label';
import moment from 'moment';
import React, { useState } from 'react';
import { convertStringTimetoTime } from 'utils/customDateAndTimeFormat';

TimeDurationClockTableBody.propTypes = {
  elm: PropTypes.object,
  setOpenDrawer: PropTypes.func,
  setDrawerUserObj: PropTypes.func,
};

export default function TimeDurationClockTableBody({ elm, setOpenDrawer, setDrawerUserObj }) {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState();
  const handleClose = () => setAnchorEl(null);
  const [timeList, setTimeList] = useState([]);
  const open = Boolean(anchorEl);
  const [openMenu, setOpenMenuActions] = useState(null);

  let totalhrs = 0;
  let totalBreak = 0;
  //   let statusTimeClock;
  const userFlags = [];

  const handleClick = (event, arr) => {
    setAnchorEl(event.currentTarget);
    setTimeList(arr);
  };

  const formatTotalHours = () => {
    const hourMinSec = moment.duration(totalhrs);
    return hourMinSec > 0 ? ` ${hourMinSec.hours()} hr ${hourMinSec.minutes()} min` : 'N/A';
  };

  const formatTotalBreaks = () => {
    const hourMinSec = moment.duration(totalBreak);
    return `${hourMinSec.hours()} hr ${hourMinSec.minutes()} min`;
  };

  const handleEdit = (userObj) => {
    setOpenDrawer(true);
    setDrawerUserObj(userObj);
  };

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };

  let isApprovedCheck = true;
  elm?.timeclocks?.forEach((e) => {
    const InTime = moment(e.inDT, 'YYYY-MM-DD hh:mm:ss');
    const OutTime = moment(e.outDT, 'YYYY-MM-DD hh:mm:ss');
    const difference = moment.duration(OutTime.diff(InTime));
    totalhrs += difference;
    //  statusTimeClock = e?.status;

    if (e?.isApproved === false) {
      isApprovedCheck = false;
    }

    e?.flags?.forEach((f) => {
      if (!userFlags.includes(f.flagType)) {
        userFlags.push(f.flagType);
      }
    });

    e?.breaks?.forEach((b) => {
      const breakStart = moment(b.startTime, 'hh:mm:ss');
      const breakEnd = moment(b.endTime, 'hh:mm:ss');
      const add = moment.duration(breakEnd.diff(breakStart));
      totalBreak += add;
    });
  });
  return (
    //  return (
    <>
      <TableRow hover>
        <TableCell>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {elm?.thumbnailURL ? (
                <img
                  src={elm?.thumbnailURL}
                  alt=""
                  style={{
                    height: '40px',
                    width: '40px',
                    borderRadius: '100px',
                    marginRight: '30px',
                  }}
                />
              ) : (
                <Avatar
                  alt=""
                  src=""
                  sx={{ mr: 2 }}
                  style={{
                    height: '40px',
                    width: '40px',
                    borderRadius: '100px',
                    marginRight: '30px',
                  }}
                />
              )}
            </div>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', gap: '1rem' }}>
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                }}
                className={userFlags?.[0] || ''}
              />
              <p>{`${elm?.firstName} ${elm?.middleName} ${elm?.lastName}`}</p>
            </div>
          </div>
        </TableCell>
        <TableCell>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {convertStringTimetoTime(elm?.timeclocks[elm?.timeclocks.length - 1]?.inTime, 'HH:mm:ss', 'hh:mm A')}
            <div>
              <Avatar
                sx={{ width: 30, height: 30, marginLeft: '0.5rem', fontSize: '0.9rem' }}
                onMouseOver={(event) => handleClick(event, elm?.timeclocks)}
              >
                {elm?.timeclocks.length}
              </Avatar>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                MenuListProps={{ onMouseLeave: handleClose }}
              >
                {timeList.map((a) => (
                  <>
                    <MenuItem onClick={handleClose}>
                      <p style={{ fontSize: '14px' }}>
                        {convertStringTimetoTime(a.inTime, 'HH:mm:ss', 'hh:mm A')}
                        <Iconify icon={'gridicons:arrow-right'} width={20} height={20} style={{ paddingTop: '10px' }} />
                        {a.outTime ? convertStringTimetoTime(a.outTime, 'HH:mm:ss', 'hh:mm A') : 'N/A'}
                        <Box style={{ fontSize: '0.7rem' }}>{a.status}</Box>
                      </p>
                    </MenuItem>
                  </>
                ))}
              </Menu>
            </div>
          </div>
        </TableCell>
        <TableCell>
          {elm?.timeclocks[elm?.timeclocks.length - 1]?.outTime
            ? convertStringTimetoTime(elm?.timeclocks[elm?.timeclocks.length - 1]?.outTime, 'HH:mm:ss', 'hh:mm A')
            : 'N/A'}
        </TableCell>
        <TableCell>{formatTotalHours()}</TableCell>
        <TableCell>{formatTotalBreaks()}</TableCell>
        <TableCell>
          <Label variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'} sx={{ textTransform: 'capitalize' }}>
            {isApprovedCheck ? <Button disabled>Approved</Button> : <Button disabled>Pending</Button>}
          </Label>
        </TableCell>
        <TableCell align="right">
          <Button
            variant="contained"
            onClick={() => {
              handleEdit(elm);
              handleCloseMenu();
            }}
          >
            Edit
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}
