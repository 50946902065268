import React from 'react';
import { DndContext, DragOverlay, closestCenter } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import FoodMenusDetailsAccordion from './FoodMenusDetailsAccordion';

function FoodMenusDetails({ mainIndex }) {
  const { control } = useFormContext();
  const [active, setActive] = React.useState(null);

  const { fields, move } = useFieldArray({
    control,
    name: `data.${mainIndex}.menus`,
    keyName: 'menuId',
  });

  const handleDragEnd = (event) => {
    setActive(null);
    const { active, over } = event;
    if (!over) return;
    if (Number(active.id) !== Number(over.id)) {
      move(
        fields.findIndex((item) => item?.id === active.id),
        fields.findIndex((item) => item?.id === over.id)
      );
    }
  };

  const handleDragStart = (event) => {
    const { active } = event;
    setActive(active.id);
  };

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
      modifiers={[restrictToParentElement]}
    >
      <SortableContext items={fields} strategy={verticalListSortingStrategy}>
        <>
          {fields?.map((menu, index) => (
            <FoodMenusDetailsAccordion key={menu?.id} menu={menu} mainIndex={mainIndex} menuIndex={index} />
          ))}
        </>
      </SortableContext>
      {/* <DragOverlay>
        {active ? (
          <FoodMenusDetailsAccordion
            menu={fields.find((item) => Number(item.id) === Number(active))}
            mainIndex={mainIndex}
            menuIndex={fields.findIndex((item) => Number(item.id) === Number(active))}
            isOverlay
          />
        ) : null}
      </DragOverlay> */}
    </DndContext>
  );
}

export default FoodMenusDetails;
