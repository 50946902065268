import { Avatar, Divider, Grid, InputAdornment, Stack, TextField } from '@mui/material';
import CustomDrawer from 'components/CustomComponents/CustomDrawer';
import React, { useEffect } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import { getSizesInCategories } from 'redux/slices/dealSlice';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import RHFText from '../user/viewDetails/RHFText';
import ViewDealDetailsDealCategories from './ViewDealDetailsDealCategories';

function ViewDealDetails({ open, handleClose, deal }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  const { singleDeal, sizesListInCategory, singleDealLoading } = useSelector((state) => state.deal);

  const { foodCategories } = useSelector((state) => state.menuEngineering);

  const methods = useForm({});
  const { reset, control } = methods;

  const findItemSizeId = (item) => {
    dispatch(getSizesInCategories({ departmentId, categoryId: item?.categoryId }));
    const sizeId = sizesListInCategory?.find((el) => el?.id === item?.sizeId);

    return sizeId;
  };

  useEffect(() => {
    if (!singleDeal) {
      reset({
        id: deal?.id || '',
        title: deal?.title || '',
        description: deal?.description || '',
        sellingPrice: deal?.sellingPrice || 0,
        startDateTime: deal?.startDateTime || null,
        endDateTime: deal?.endDateTime || null,
        occuranceType: deal?.occuranceType || '',
        hasTimeLimit: deal?.hasTimeLimit || false,
        isPrivate: deal?.isPrivate || false,
      });
    } else {
      const resetDealCategories = singleDeal?.map((item) => ({
        dealId: item?.dealId,
        id: item?.id,
        qty: item?.qty,
        categoryId: foodCategories?.find((el) => el?.id === item?.categoryId),
        sizeId: findItemSizeId(item),
      }));
      reset({
        id: deal?.id || '',
        title: deal?.title || '',
        description: deal?.description || '',
        sellingPrice: deal?.sellingPrice || 0,
        startDateTime: deal?.startDateTime || null,
        endDateTime: deal?.endDateTime || null,
        occuranceType: deal?.occuranceType || '',
        hasTimeLimit: deal?.hasTimeLimit || false,
        isPrivate: deal?.isPrivate || false,
        dealCategories: resetDealCategories || [],
      });
    }
  }, [reset, singleDeal, deal, foodCategories]);

  console.log(methods.watch(), 'watch reset data');

  return (
    <CustomDrawer
      open={open}
      setOpen={handleClose}
      title="Deal Offer"
      PaperProps={{ sx: { width: '600px' } }}
      headerIcon="carbon:folder-details"
      iconColor="#1976d2"
      iconWidth={30}
      iconHeight={30}
    >
      {singleDealLoading ? (
        <CustomLoadingScreen height="20vh" />
      ) : (
        <FormProvider {...methods}>
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} sx={{ width: 1 }} justifyContent={'center'} mb={2}>
            <Avatar alt="Remy Sharp" src={deal?.imageUrl} sx={{ width: 200, height: 200 }} />
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <RHFText name="title" label="Title*" />
            </Grid>
            <Grid item xs={4}>
              <RHFText name="occuranceType" label="Occurance Type*" />
            </Grid>
          </Grid>

          <Stack marginTop={'10px'}>
            <RHFText name="description" label="Description*" multiline minRows={2} />
          </Stack>
          <Grid container spacing={1} marginTop={'5px'}>
            <Grid item xs={12} sm={2}>
              <RHFText
                name="sellingPrice"
                label="Selling Price*"
                InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Controller
                name="startDateTime"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <MobileDateTimePicker
                      {...field}
                      disabled
                      //   disablePast
                      label="Start date"
                      inputFormat="dd/MMM/yyyy hh:mm a"
                      DialogProps={{ sx: { zIndex: 10000 } }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </div>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={5}>
              <Controller
                name="endDateTime"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <MobileDateTimePicker
                      {...field}
                      disabled
                      label="End date"
                      inputFormat="dd/MMM/yyyy hh:mm a"
                      DialogProps={{ sx: { zIndex: 10000 } }}
                      renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                  </div>
                )}
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} textAlign="center">
            Deal Categories
          </Divider>
          <ViewDealDetailsDealCategories />
        </FormProvider>
      )}
    </CustomDrawer>
  );
}

export default ViewDealDetails;
