import { Box, Button, MenuItem, Modal, TableCell, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Label from 'components/Label';
import { TableMoreMenu } from 'components/table';
import Iconify from 'components/Iconify';
import { DeleteFirstAidLog } from 'redux/slices/storeDocumentSlice';
import { useDispatch } from 'react-redux';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import FirstAidEditDrawer from './FirstAidEditDrawer';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function FirstAidTableRow({ row, index, selected }) {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();
  const [openMenu, setOpenMenuActions] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };
  const handleEditClick = (row) => {
    setOpenEdit(true);
    handleCloseMenu();
  };
  return (
    <>
      <Modal
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you sure want to Delete this data?
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, ml: 8 }}>
            <Button
              variant="contained"
              color="success"
              sx={{ mr: 4 }}
              onClick={() => {
                dispatch(DeleteFirstAidLog({ id: row?.id, departmentId }));
                setOpenDelete(false);
              }}
            >
              Yes
            </Button>
            <Button variant="outlined" color="error" onClick={() => setOpenDelete(false)}>
              No
            </Button>
          </Typography>
        </Box>
      </Modal>
      <TableRow hover selected={selected}>
        <TableCell>{index + 1}</TableCell>
        <TableCell>{row?.itemDetails}</TableCell>
        <TableCell>
          <Label variant="ghost" color={row?.isAvailable ? 'success' : 'error'}>
            {row?.isAvailable ? 'Available' : 'Not Available'}
          </Label>
        </TableCell>
        <TableCell>{row?.quantity}</TableCell>
        <TableCell style={{ width: '300px' }}>{row?.comment}</TableCell>
        <TableCell align="right">
          <TableMoreMenu
            open={openMenu}
            onOpen={(event) => setOpenMenuActions(event.currentTarget)}
            onClose={() => setOpenMenuActions(null)}
            className="tablemore"
            actions={
              <>
                <MenuItem
                  onClick={() => {
                    handleEditClick(row);
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon="eva:edit-2-outline" width={20} height={20} />
                  Edit
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setOpenDelete(true);
                    handleCloseMenu();
                  }}
                >
                  <Iconify icon="eva:trash-2-outline" width={20} height={20} />
                  Delete
                </MenuItem>
              </>
            }
          />
        </TableCell>
      </TableRow>
      {openEdit && <FirstAidEditDrawer open={openEdit} setOpen={setOpenEdit} data={row} />}
    </>
  );
}

FirstAidTableRow.propTypes = {
  row: PropTypes.object,
  index: PropTypes.number,
  selected: PropTypes.bool,
};

export default FirstAidTableRow;
