import { Container } from '@mui/material';
import Page from 'components/Page';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getFoodZones } from 'redux/slices/FoodMenuSlice';
import { getZones } from 'redux/slices/storeSlice';
import ZonesCardBody from './ZonesCardBody';
import ZonesHeader from './ZonesHeader';

export default function Zones() {
  const dispatch = useDispatch();
  const departmentId = useGetDepartmentId();

  useEffect(() => {
    if (!departmentId) return;
    dispatch(getZones({ departmentId }));
    dispatch(getFoodZones({ departmentId }));
  }, [departmentId, dispatch]);
  return (
    <Page title="Report">
      {/* <Container> */}
      <ZonesHeader />
      <ZonesCardBody />
      {/* </Container> */}
    </Page>
  );
}
