import Page from 'components/Page';
import React from 'react';
import UserWorkInfoHeader from './UserWorkInfoHeader';
import UserWorkInfoBody from './UserWorkInfoBody';

function UserWorkInfo() {
  return (
    <Page title="User Work Report">
      <UserWorkInfoHeader />
      <UserWorkInfoBody />
    </Page>
  );
}

export default UserWorkInfo;
