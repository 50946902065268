import Page from 'components/Page';
import React from 'react';
import QuickSummaryHeader from './QuickSummaryHeader';
import QuickSummaryBody from './QuickSummaryBody';

function QuickSummaryIndex() {
  return (
    <>
      <Page title="Quick Summary">
        <QuickSummaryHeader />
        <QuickSummaryBody />
      </Page>
    </>
  );
}

export default QuickSummaryIndex;
