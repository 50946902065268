import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Modal,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import { TableHeadCustom, TableNoData } from 'components/table';
import moment from 'moment';
import { deleteLeaveRequest, getApprovedLeaveRequest } from 'redux/slices/leaveRequestSlice';
import { useDispatch, useSelector } from 'react-redux';
// import { getDepartOfStore } from 'redux/slices/storeSlice';
// import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import Iconify from 'components/Iconify';

// TABLE HEADING
const TABLE_HEAD = [
  { id: 'name', label: 'Name', align: 'left' },
  { id: 'date', label: 'Date', align: 'left' },
  { id: 'phone', label: 'Phone', align: 'left' },
  { id: 'status', label: 'Status', align: 'left' },
  { id: 'approvedBy', label: 'Approved By', align: 'left' },
  { id: 'action', label: 'Action', align: 'center' },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  textAlign: 'center',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function LeaveApproveTable() {
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState(null);
  const departmentId = useGetDepartmentId();
  const dispatch = useDispatch();
  // getting approved leave request
  const { approvedLeaveRequest, loading: approvedRequestLoading } = useSelector((state) => state.LeaveRequest);

  // getting department from store

  const date = new Date();

  const [fromDate, setFromDate] = useState(moment(date).format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(moment(fromDate).add(15, 'days').format('YYYY-MM-DD'));
  const [pageNo, setPageNo] = useState(1);

  // DATE PICKER
  const fromDateChange = (date) => {
    setFromDate(moment(date).format('YYYY-MM-DD'));
  };
  const toDateChange = (date) => {
    setToDate(moment(date).format('YYYY-MM-DD'));
  };

  const handleDelete = () => {
    const isSuccessful = (isSuccess) => {
      if (isSuccess) {
        dispatch(
          getApprovedLeaveRequest({
            departmentId,
            startDate: moment(fromDate).format('YYYY-MM-DD'),
            endDate: moment(toDate).format('YYYY-MM-DD'),
            status: 'APPROVED',
          })
        );
        setOpenDeleteModal(false);
      }
    };
    dispatch(deleteLeaveRequest({ id: deleteData?.id, departmentId, isSuccess: isSuccessful }));
  };

  useEffect(() => {
    if (!fromDate) return;
    if (!toDate) return;
    if (!departmentId) return;
    if (fromDate && toDate) {
      const diffDays = moment(toDate).diff(moment(fromDate), 'days');
      if (fromDate > toDate) {
        toast.error('From date should be less than to date');
      } else if (diffDays > 90) {
        toast.error('From date and to date should be within 90 days');
      } else {
        dispatch(
          getApprovedLeaveRequest({
            departmentId,
            startDate: moment(fromDate).format('YYYY-MM-DD'),
            endDate: moment(toDate).format('YYYY-MM-DD'),
            status: 'APPROVED',
          })
        );
      }
    }
  }, [fromDate, toDate, departmentId, dispatch]);
  return (
    <>
      <Modal
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Do you want to delete this data?
          </Typography>
          <Typography id="modal-modal-description" align="center" sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="success"
              style={{ marginRight: '5px' }}
              onClick={() => {
                handleDelete();
              }}
            >
              Delete
            </Button>
            <Button variant="outlined" color="error" onClick={() => setOpenDeleteModal(false)}>
              Cancel
            </Button>
          </Typography>
        </Box>
      </Modal>
      <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }} sx={{ py: 2.5, px: 3 }}>
        {/* FromDate */}
        <DatePicker
          label="From Date"
          inputFormat="dd/MMM/yyyy"
          value={fromDate}
          onChange={fromDateChange}
          renderInput={(params) => <TextField {...params} error={false} />}
        />

        {/* ToDate */}
        <DatePicker
          disablePast
          inputFormat="dd/MMM/yyyy"
          label="To Date"
          value={toDate}
          onChange={toDateChange}
          renderInput={(params) => <TextField {...params} error={false} />}
        />
      </Stack>
      <Table size="small">
        <TableHeadCustom headLabel={TABLE_HEAD} />
        <TableBody>
          {approvedRequestLoading ? (
            <TableRow>
              <TableCell colSpan={5}>
                <CustomLoadingScreen height="20vh" />
              </TableCell>
            </TableRow>
          ) : (
            approvedLeaveRequest?.leaveRequestList?.map((request) => (
              <TableRow hover key={request.id}>
                <TableCell sx={{ display: 'flex' }}>
                  {request.requestor.thumbnailURL ? (
                    <Avatar
                      src={request.requestor.thumbnailURL}
                      style={{
                        height: '30px',
                        width: '30px',
                        borderRadius: '100px',
                        marginRight: '30px',
                      }}
                    />
                  ) : (
                    <Avatar
                      alt=""
                      src=""
                      style={{ height: '30px', width: '30px', borderRadius: '100px', marginRight: '30px' }}
                    />
                  )}
                  <Typography style={{ marginTop: '5px' }} variant="subtitle2" noWrap>
                    {request.requestor.firstName} {request.requestor.lastName}
                  </Typography>
                </TableCell>
                <TableCell>{moment(request.date).format('MMM, DD')}</TableCell>
                <TableCell>{request.requestor.phone}</TableCell>
                <TableCell>{request.status}</TableCell>
                <TableCell sx={{ display: 'flex' }}>
                  {request.approver.thumbnailURL ? (
                    <Avatar
                      src={request.approver.thumbnailURL}
                      style={{
                        height: '30px',
                        width: '30px',
                        borderRadius: '100px',
                        marginRight: '30px',
                      }}
                    />
                  ) : (
                    <Avatar alt="" src="" style={{ height: '30px', width: '30px', marginRight: '30px' }} />
                  )}
                  <Typography style={{ marginTop: '5px' }} variant="subtitle2" noWrap>
                    {request.approver.firstName} {request.approver.lastName}
                  </Typography>
                </TableCell>
                <TableCell
                  align="center"
                  onClick={() => {
                    setDeleteData(request);
                    setOpenDeleteModal(true);
                  }}
                >
                  <Button variant="text" color="error">
                    <Iconify icon="mdi:delete" />
                  </Button>
                </TableCell>
              </TableRow>
            ))
          )}
        </TableBody>
        {approvedLeaveRequest?.leaveRequestList?.length === 0 && (
          <TableNoData isNotFound={approvedLeaveRequest?.leaveRequestList} />
        )}
      </Table>
      <Box
        sx={{ position: 'relative', marginTop: '10px' }}
        justifyContent={'end'}
        alignContent="center"
        display={'flex'}
      >
        <Pagination
          count={approvedLeaveRequest?.paginationInfo?.totalPages}
          page={pageNo}
          onChange={(e, value) => setPageNo(value)}
        />
      </Box>
    </>
  );
}
