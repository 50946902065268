import { Box, Button } from '@mui/material';
import HeaderBreadcrumbs from 'components/HeaderBreadcrumbs';
import Iconify from 'components/Iconify';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { PATH_DASHBOARD } from 'routes/paths';
import DeliveryZoneCreate from './deliveryZone/DeliveryZoneCreate';
import SuburbCreate from './suburb/SuburbCreate';

function PosConfigHeader() {
  const { currentTab } = useSelector((state) => state.posConfig);
  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const setOpenHandler = () => {
    setOpen(true);
  };

  return (
    <>
      <HeaderBreadcrumbs
        heading="POS config"
        links={[
          // avoid page crash
          <Button key={1} variant="contained" color="primary">
            Events
          </Button>,
        ]}
        action={
          <Box className="invoiceListHeadingButtons">
            {currentTab === 0 && (
              <Button variant="contained" startIcon={<Iconify icon={'eva:plus-fill'} />} onClick={setOpenHandler}>
                Delivery Zone
              </Button>
            )}
            {currentTab === 1 && (
              <Button variant="contained" startIcon={<Iconify icon={'eva:plus-fill'} />} onClick={setOpenHandler}>
                Suburb
              </Button>
            )}
            {/* {currentTab === 2 && (
              <Button variant="contained" startIcon={<Iconify icon={'eva:plus-fill'} />} onClick={setOpenHandler}>
                Street
              </Button>
            )} */}
          </Box>
        }
      />
      {open && currentTab === 0 && <DeliveryZoneCreate open={open} handleClose={handleClose} />}
      {open && currentTab === 1 && <SuburbCreate open={open} handleClose={handleClose} />}
    </>
  );
}

export default PosConfigHeader;
