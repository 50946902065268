import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

UserWorkReportWeekWiseTableRow.propTypes = {
  timeReport: PropTypes.object,
  timeIndex: PropTypes.number,
};

function UserWorkReportWeekWiseTableRow({ timeReport, timeIndex }) {
  const findTotalPayOfDay = (timeClocks) => {
    let totalPay = 0;
    // eslint-disable-next-line array-callback-return
    timeClocks?.data?.map((item) => {
      totalPay += item?.pay ?? 0;
    });
    return Number(totalPay).toFixed(2);
  };

  const findTotalOfDay = (timeClocks) => {
    let totalTime = 0;
    // eslint-disable-next-line array-callback-return
    timeClocks?.data?.map((item) => {
      const diff = moment(item?.clockOutTime).diff(moment(item?.clockInTime), 'minutes');
      totalTime += diff;
    });
    return Number(totalTime / 60).toFixed(2);
  };

  const findBackgroundColor = (timeClocks) => {
    // let backgroundColor = '#ADD8E6';
    let backgroundColor = '#FFFFFF';
    if (timeClocks?.isHoliday) {
      // backgroundColor = 'rgba(216, 191, 216, 1)';
      // backgroundColor = '#FAFAD2';
      // backgroundColor = '#FFA07A';
      backgroundColor = '#F08080';
    } else {
      if (moment(timeClocks?.reportDate).format('ddd') === 'Sun') {
        // backgroundColor = '#90EE90';
        backgroundColor = '#FFFFE0';
      }
      if (moment(timeClocks?.reportDate).format('ddd') === 'Sat') {
        // backgroundColor = '#FFFFE0';
        backgroundColor = '#D8BFD8';
      }
    }

    return backgroundColor;
  };
  return (
    <TableRow
      key={timeIndex}
      hover
      style={{
        borderBottom: '1px solid rgb(128,128,128)',
        backgroundColor: findBackgroundColor(timeReport),
      }}
    >
      <TableCell style={{ width: '160px' }}>{moment(timeReport?.reportDate).format('ddd, DD MMM YYYY')}</TableCell>
      <TableCell style={{ width: '290px' }}>
        {timeReport?.data?.map((el, index) => (
          <TableRow key={index} hover>
            <TableCell align="center">{moment(el?.clockInTime).format('hh:mm:ss A')}</TableCell>
            {/* <TableCell align="center">-</TableCell> */}
            <TableCell align="center">{moment(el?.clockOutTime).format('hh:mm:ss A')}</TableCell>
          </TableRow>
        ))}
      </TableCell>
      <TableCell style={{ width: '100px' }}>{findTotalOfDay(timeReport)} Hrs.</TableCell>
      <TableCell style={{ width: '100px' }}>$ {findTotalPayOfDay(timeReport)}</TableCell>
    </TableRow>
  );
}

export default UserWorkReportWeekWiseTableRow;
