import { Card, Divider, Stack, Typography } from '@mui/material';
import CustomLoadingScreen from 'components/CustomComponents/CustomLoadingScreen';
import React from 'react';
import { useSelector } from 'react-redux';
import InsightsPieChart from './InsightsPieChart';

function InsightReportBodyChart() {
  const { insights, loading } = useSelector((state) => state.reports);

  const cogsInsights = insights?.cogsInsights || 0;
  const orderInsights = insights?.orderInsights || 0;
  const revenue = insights?.totalSales || 0;
  const isCogsEmpty = cogsInsights === 0;
  const isOrderEmpty = orderInsights === 0;
  const cogsPercent = ((cogsInsights / revenue) * 100).toFixed(2);
  const orderPercent = ((orderInsights / revenue) * 100).toFixed(2);

  const stockInsights = insights?.stockInsights || 0;
  const stockInsightsPar = insights?.stockInsightsPar || 0;
  // const stockInsights = 5000;
  // const stockInsightsPar = 4200;

  const isStockEmpty = stockInsights === 0;
  const isStockParEmpty = stockInsightsPar === 0;

  const cogsInsightsColor = '#AAAAFF';
  const orderInsightsColor = '#0000FF';

  const stockDiff = stockInsightsPar - stockInsights;
  const isDiffNegative = stockDiff < 0;

  const stockPercent = (((stockInsightsPar - stockInsights) / stockInsights) * 100).toFixed(2);

  let parValue = 0;
  let stockValue = 0;
  let stockDiffValue = 0;

  if (isDiffNegative) {
    stockValue = 50;
    parValue = 50 - Math.abs(stockPercent);
    stockDiffValue = Math.abs(stockPercent);
  } else {
    parValue = 50;
    stockValue = 50 - Math.abs(stockPercent);
    stockDiffValue = Math.abs(stockPercent);
  }

  return (
    <Stack m="2rem">
      {loading ? (
        <CustomLoadingScreen height="30vh" />
      ) : (
        <Stack gap="2rem" mx="5rem">
          <Stack flexDirection={'row'} justifyContent={'center'} width="100%">
            <Card sx={{ width: '100%', padding: '0.6rem' }}>
              <Stack flexDirection={'row'} justifyContent={'center'} flexWrap={'wrap'} gap="1rem" width="100%">
                {isCogsEmpty ? (
                  <>
                    <InsightsPieChart
                      isEmpty
                      title="Cogs Report"
                      chartData={[{ label: `Empty`, value: 1 }]}
                      chartColors={['#000000']}
                      size={300}
                    />
                  </>
                ) : (
                  <>
                    <InsightsPieChart
                      title="Cogs Report"
                      chartData={[
                        { label: `COGS Insight`, value: cogsInsights, percent: Number(cogsPercent) },
                        { label: `Revenue`, value: revenue, percent: 100 - Number(cogsPercent) },
                      ]}
                      chartColors={[cogsInsightsColor, '#00BEFE']}
                      size={300}
                    />
                  </>
                )}
                {isOrderEmpty ? (
                  <InsightsPieChart
                    isEmpty
                    title="Order Report"
                    chartData={[{ label: `Empty`, value: 100 }]}
                    chartColors={['#000000']}
                    size={300}
                  />
                ) : (
                  <>
                    <InsightsPieChart
                      title="Order Report"
                      chartData={[
                        { label: `Order Insight`, value: orderInsights, percent: Number(orderPercent) },
                        { label: `Revenue `, value: revenue, percent: 100 - Number(orderPercent) },
                      ]}
                      chartColors={[orderInsightsColor, '#00BEFE']}
                      size={300}
                    />
                  </>
                )}
              </Stack>
              <Divider sx={{ my: '2rem', borderStyle: 'dashed' }} />
              <Stack flexDirection={'row'} justifyContent={'center'} flexWrap={'wrap'} gap="1rem" width="100%">
                {isStockParEmpty ||
                  (isStockEmpty && (
                    <>
                      <InsightsPieChart
                        isEmpty
                        title="Stock Report"
                        chartData={[{ label: `Empty`, value: 1 }]}
                        chartColors={['#000000']}
                        size={300}
                      />
                    </>
                  ))}
                {!isStockParEmpty && !isStockEmpty && isDiffNegative && (
                  <InsightsPieChart
                    isStock
                    title="Stock Over Order Report"
                    chartData={[
                      { label: `Insights Stock`, value: stockValue, percent: stockInsights },
                      {
                        label: `Par Stock  `,
                        value: parValue,
                        percent: stockInsightsPar,
                      },
                      {
                        label: 'Difference Stock',
                        value: stockDiffValue,
                        percent: stockDiff,
                      },
                    ]}
                    chartColors={[cogsInsightsColor, '#00BEFE', '#FF0000']}
                    size={300}
                  />
                )}
                {!isStockParEmpty && !isStockEmpty && !isDiffNegative && (
                  <InsightsPieChart
                    isStock
                    title="Stock Under Order Report"
                    chartData={[
                      { label: `Insights Stock`, value: stockValue, percent: stockInsights },
                      {
                        label: `Par Stock  `,
                        value: parValue,
                        percent: stockInsightsPar,
                      },
                      {
                        label: 'Difference Stock',
                        value: stockDiffValue,
                        percent: stockDiff,
                      },
                    ]}
                    chartColors={[cogsInsightsColor, '#00BEFE', '#000000']}
                    size={300}
                  />
                )}
              </Stack>
            </Card>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
}

export default InsightReportBodyChart;
