import { Stack, TextField } from '@mui/material';
import useGetDepartmentId from 'hooks/useGetDepartmentId';
import React, { useEffect, useState } from 'react';
import { getIngredientList, getSearchIngredientList } from 'redux/slices/ingredientSlice';
import { dispatch } from 'redux/store';

function IngredientFilter() {
  const departmentId = useGetDepartmentId();
  const [first, setFirst] = useState(null);

  const OnChangeTextField = (event) => {
    setFirst(event);
  };

  // useEffect(() => {
  //   if (!departmentId) return;
  //   dispatch(getIngredientList({ departmentId, pageNo: 1 }));
  // }, [departmentId]);

  useEffect(() => {
    if (!departmentId) return;
    const promise = dispatch(getSearchIngredientList({ departmentId, searchText: first }));
    return () => promise.abort();
  }, [first, departmentId]);

  return (
    <Stack m={2} alignItems="center" flexDirection="row" justifyContent="space-between">
      <TextField
        name="searchTxt"
        sx={{ width: 'min(30rem,70%)' }}
        label="Search Ingredients"
        onChange={(event) => OnChangeTextField(event.target.value)}
      />
    </Stack>
  );
}

export default IngredientFilter;
